import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    infoPage: {
      firstItemNumber: 0,
      itemCount: 0,
      items: 0,
      itemsTotal: 0,
      pageNumber: 0,
      pageSize: 10,
      pagesTotal: 0,
    },
    loadPage: true,
  },
};

export const PRIORITY_IMPORT_DESTINATION_IMPORT = 'priorityImportAndImportDestination';

export const PRIORITY_IMPORT_DESTINATION_IMPORT_ACTIONS_NAME = {
  getInfoPage: `${PRIORITY_IMPORT_DESTINATION_IMPORT}/getInfoPage`,
  loadFile: `${PRIORITY_IMPORT_DESTINATION_IMPORT}/loadFile`,
};

export const PRIORITY_IMPORT_DESTINATION_IMPORT_MUTATIONS_NAME = {
  setInfoPage: `${PRIORITY_IMPORT_DESTINATION_IMPORT}/setInfoPage`,
  addInfoPage: `${PRIORITY_IMPORT_DESTINATION_IMPORT}/addInfoPage`,
  changeStateLoadPage: `${PRIORITY_IMPORT_DESTINATION_IMPORT}/changeStateLoadPage`,
};

export const PRIORITY_IMPORT_DESTINATION_IMPORT_GETTERS_NAME = {
  getPageInfo: `${PRIORITY_IMPORT_DESTINATION_IMPORT}/getPageInfo`,
  getStateLoadPage: `${PRIORITY_IMPORT_DESTINATION_IMPORT}/getStateLoadPage`,
};
