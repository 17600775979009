<template>
  <div class="container-page">
    <div class="input-search">
      <SearchInputComponent :term="request.term" class="mt-2" />
    </div>
    <section class="search-page">
      <div v-if="page.items" class="search__results">
        <div v-if="page.items.length">
          <div class="search__results-top">
            <p>
              Найдено: <strong>{{ page.items.length }}</strong>
            </p>
          </div>
          <ul class="search__results-list">
            <li v-for="item in page.items" class="search__results-item" :key="item.title">
              <IconComponent :name="getIcon(item)" />
              <router-link :to="getLink(item)" class="ml-2">{{ item.title }}</router-link>
            </li>
          </ul>
        </div>
        <div v-else>
          <h4>По вашему запросу ничего не найдено.</h4>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import SearchInputComponent from '../../common/components/HomePage/searchInputComponent';
  import Constants from '../../common/constants.js';
  import IconComponent from '../../common/ui/IconComponent';
  import Api from './api/index.js';
  export default {
    name: 'Search',
    components: { SearchInputComponent, IconComponent },
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: 10,
          term: this.$route.params.term,
        },
        page: {},
      };
    },
    created() {
      this.loadPage();
    },
    methods: {
      loadPage() {
        Api.search(this.request).then((response) => {
          this.page = response.data;
        });
      },
      getIcon(item) {
        switch (item.entityType) {
          case Constants.entityType.employee:
            return 'user';
          case Constants.entityType.news:
            return 'news';
        }
      },
      getLink(item) {
        switch (item.entityType) {
          case Constants.entityType.employee:
            return '/contacts';
          case Constants.entityType.news:
            return `/news/${item.categoryId}/${item.id}`;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .search-page {
    margin-top: 26px;
    display: flex;
    align-items: flex-start;
  }
  .search__results {
    max-width: 100%;
    width: 100%;
    padding: 27px 17px 44px 42px;
    background: #ffffff;

    &-title {
      margin-bottom: 28px;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 29px;
      color: #212125;
    }

    &-top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 27px;
      margin-bottom: 20px;
      border-bottom: 1px dashed #212125;

      p,
      span {
        margin: 0;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        color: #212125;
        text-align: justify;
      }
    }

    &-list {
      padding: 0;
    }

    &-item {
      display: flex;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px dashed #212125;

      .fas {
        margin-left: auto;
        color: #c3c3c8;
      }

      h4 {
        width: 50%;
        margin: 0 28px 0 0;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        color: #212125;
      }

      p {
        word-break: break-word;
        margin: 0 0 0 0;
        width: 50%;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 130%;
        color: #212125;
      }
    }
  }
</style>
