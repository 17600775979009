<template>
  <div class="line-graph-payments">
    <apexchart
      class="line-graph-payments__apexchart"
      ref="apexchart"
      type="line"
      height="500"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
  import numberFormatter from '@/common/mixins/numberFormatter';

  export default {
    name: 'GroupGraphPayments',
    props: {
      seriesProps: {
        type: Array,
        default: () => [],
      },
      categories: {
        type: Array,
        default: () => [],
      },
      strokeWidth: {
        type: Array,
        default: () => [],
      },
      groupGraph: {
        type: Boolean,
        default: false,
      },
      tooltipCategories: {
        type: Array,
        default: () => [],
      },
    },
    mixins: [numberFormatter],
    data() {
      return {
        series: [],
        chartOptions: {},
      };
    },
    created() {
      this.chartOptions = {
        chart: {
          stacked: true,
          redrawOnParentResize: false,
          type: 'line',
          height: 500,
          dropShadow: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          zoom: {
            enabled: false,
          },
        },
        grid: {
          borderColor: '#E7EFF4',
        },
        stroke: {
          curve: 'straight',
          width: 4,
        },
        xaxis: {
          categories: this.categories,
          tickAmount: 4,
          labels: {
            rotate: 0,
            style: {
              colors: '#989898',
              fontSize: '15px',
              fontFamily: 'Fira Sans',
              letterSpacing: '-0.3px',
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          min: this.getMinValue(this.seriesProps),
          forceNiceScale: true,
          decimalsInFloat: 0,
          labels: {
            style: {
              colors: '#989898',
              fontSize: '15px',
              fontFamily: 'Fira Sans',
              letterSpacing: '-0.3px',
            },
            formatter: (value) => {
              return this.numberFormatter(value, 'ru-RU', 2);
            },
          },
        },
        noData: {
          text: 'Данных нет',
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex }) => {
            const category = this.tooltipCategories[dataPointIndex];
            const value = this.numberFormatter(series[seriesIndex][dataPointIndex], 'ru-RU', 1);
            const valueNumber = value.replace(/\s+/g, '');
            return `<div class="custom-tooltip-line-graph">
              ${category}  ${dataPointIndex}; ${Number.isInteger(+valueNumber) ? value + ',0' : value}
            </div>`;
          },
        },
        markers: {
          strokeColors: '#73EA48',
          strokeWidth: 4,
          hover: {
            size: 6,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
            rangeBarGroupRows: true,
            horizontal: false,
          },
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
      };

      this.series = this.seriesProps;
    },
    mounted() {
      setTimeout(() => {
        this.$refs.apexchart.updateOptions({
          stroke: { width: this.strokeWidth },
        });

        if (this.groupGraph) {
          this.$refs.apexchart.updateOptions({
            tooltip: {
              custom: undefined,
              shared: true,
              intersect: false,
              hideEmptySeries: false,
              y: {
                formatter: function (y) {
                  if (typeof y !== 'undefined') {
                    return y.toFixed(2);
                  }
                  return y;
                },
              },
            },
          });
        }
      });
    },
    methods: {
      getMinValue(series) {
        const result = series.reduce((acc, item) => {
          if (item.type !== 'line') {
            const minValue = Math.min(...item.data);
            return minValue < 0 ? acc + minValue : acc;
          } else {
            const minLineValue = Math.min(...item.data);
            return minLineValue <= acc ? minLineValue : acc;
          }
        }, 0);
        return result;
      },
    },
    watch: {
      seriesProps: {
        handler(val) {
          this.$refs.apexchart.updateSeries(val);
        },
        deep: true,
      },
      groupGraph(val) {
        if (val) {
          this.$refs.apexchart.updateOptions({
            tooltip: { custom: undefined },
          });
        }
      },
      strokeWidth: {
        handler(val) {
          this.$refs.apexchart.updateOptions({
            stroke: { width: val },
          });
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss">
  .line-graph-payments {
    @media (max-width: $mobile) {
      overflow: auto hidden;
      margin-bottom: 10px;

      &__apexchart {
        min-width: 1160px;
      }
    }
  }

  .custom-tooltip-line-graph {
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid $grey;
    background: $light-grey;
    font: 15px / 16px 'Fira Sans';
    letter-spacing: -0.3px;
  }
</style>
