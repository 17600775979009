<template>
  <div class="reference">
    <ul class="docs__list d-flex justify-content-between">
      <li class="docs__item" @click="active = 1">
        <IconComponent class="mr-2" name="docsIcon" />
        <p :class="{ 'text-bold': active === 1 }">Политическая обстановка</p>
      </li>
      <li class="docs__item" @click="active = 2">
        <IconComponent class="mr-2" name="docsIcon" />
        <p :class="{ 'text-bold': active === 2 }">
          Членство страны в региональных блоках, международных организациях/объединениях
        </p>
      </li>
      <li class="docs__item" @click="active = 3">
        <IconComponent class="mr-2" name="docsIcon" />
        <p :class="{ 'text-bold': active === 3 }">Зоны свободной торговли</p>
      </li>
    </ul>
    <div class="content mt-5">
      <div v-if="active === 1">
        <div v-if="country" class="html-text" v-html="country.politicalSituation"></div>
      </div>
      <div v-if="active === 2">
        <ul v-if="membership" class="membership-list">
          <li v-for="item in membership" :key="item.id">
            <!-- <div :style="{ 'background-image': `url(${item.picture})` }">
              <a :href="item.url" target="_blank">{{ item. title }}</a>
            </div> -->
            <div>
              <a :href="item.url" target="_blank">{{ item.title }}</a>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="active === 3">
        <Agreements v-bind:country-id="countryId" :show-title="false" :readonly="true" />
      </div>
    </div>
  </div>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent';

  import Agreements from '../../../freeTradeAgreement/views/Agreements';
  import CountryApi from '../../../nsi/api/country';
  export default {
    name: 'reference',
    components: { Agreements, IconComponent },
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        active: 3,
        country: null,
        membership: null,
      };
    },
    created() {
      if (this.countryId) {
        this.loadData();
      }
    },
    methods: {
      loadData() {
        CountryApi.find(this.countryId).then((response) => {
          this.country = response.data;
        });
        CountryApi.getMembership(this.countryId).then((response) => {
          this.membership = response.data;
        });
      },
    },
    watch: {
      countryId: function () {
        this.loadData();
      },
    },
  };
</script>

<style scoped lang="scss">
  .docs__list {
    align-items: center;
  }
  h2 {
    margin-bottom: 24px;
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
  }
  .docs__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    a {
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
  }
  .membership-list {
    li {
      div {
        // background-repeat: no-repeat;
        // background-position: left center;
        // background-size: 80px auto;
        // padding: 26px 0 0 80px;
        // height: 80px;
        padding: 5px 0;
      }
    }
  }
</style>

<style>
  .html-text * {
    font-style: inherit;
  }
  .html-text em {
    font-style: italic;
  }
  .html-text h1 {
    margin: 0.67em 0;
  }
  .html-text p {
    margin: 1em 0;
  }
</style>
