<template>
  <div class="statisticContainer">
    <h3 class="statisticContainer__title">{{ title }}</h3>
    <slot />
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
    },
  };
</script>

<style lang="scss" scoped>
  .statisticContainer {
    &__title {
      font: $fira-24-M;
    }
  }
</style>
