import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/news/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/news/find/${id}`);
  },
  update: function (data) {
    if (data.id > 0) {
      return axios.put(`/api/news/${data.id}`, data);
    } else {
      return axios.post('/api/news', data);
    }
  },
  delete: function (typeId, id) {
    return axios.delete(`/api/news/${typeId}/${id}`);
  },
  exportWord: function (request) {
    return Utils.downloadFile('/api/news/exportWord', 'News.docx', request);
  },
  sendApproval: function (id) {
    return axios.put(`/api/news/sendToApproval?id=${id}`);
  },
  approveNews: function (id) {
    return axios.put(`/api/news/approveNews?id=${id}`);
  },
  refuseNews: function (data) {
    return axios.put(`/api/news/refuseNews`, data);
  },
};
