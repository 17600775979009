import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/auditPage/search', { params: request });
  },
  visitPage: function (request) {
    return axios.post('/api/auditPage/visitPage', request);
  },
  async deleteAll(request) {
    return await axios.delete('api/auditPage/deleteAll', { params: request });
  },
  async exportExcel(request) {
    return Utils.downloadFile('/api/auditPage/exportExcel', 'VisitPageLog.xlsx', request);
  },
};
