import { checkPermission } from '@/common/utils/security';

const permissionCache = new Map();

// Директива используется для проверки доступа к элементам шаблона
export const permission = {
  async created(el, binding, vnode) {
    if (!binding.value) return;
    vnode.el.hidden = true;
    const permissionId = parseInt(binding.value);

    if (!permissionCache.has(permissionId)) {
      permissionCache.set(permissionId, false);
      const response = await checkPermission(permissionId);
      permissionCache.set(permissionId, !response.data);
    }
    vnode.el.hidden = permissionCache.get(permissionId);
  },
};
