<template>
  <div class="btn" :class="burgerClassName" @click="toggle">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
  export default {
    name: 'burgerMenu',
    props: {
      isActive: {
        type: Boolean,
        required: true,
        default: false,
      },
    },
    computed: {
      burgerClassName() {
        return this.isActive ? 'active' : 'not-active';
      },
    },
    methods: {
      toggle() {
        // this.isActive = !this.isActive;
        this.$emit('toggle');
      },
    },
  };
</script>

<style scoped lang="scss">
  .btn {
    position: absolute;
    left: 24px;
    top: 32px;
    width: 32px;
    padding: 4px 0;
    border: none;
    cursor: pointer;

    @media (max-width: 767px) {
      top: 20px;
      left: 16px;
    }

    &.active {
      position: relative;
      left: 230px;
      top: 10px;
      z-index: 1111;
    }
  }

  span {
    display: block;
    width: 100%;
    border-radius: 3px;
    height: 4px;
    background: linear-gradient(270deg, #00b2aa 0%, #0078c8 100%);
    transition: all 0.3s;
    position: relative;
  }

  span + span {
    margin-top: 6px;
  }
  .active span + span {
    margin-top: 18px;
  }
  .active span:nth-child(1) {
    animation: ease 0.7s top forwards;
  }
  .active span {
    background: #fff;
  }
  .not-active span:nth-child(1) {
    animation: ease 0.7s top-2 forwards;
  }

  .active span:nth-child(2) {
    animation: ease 0.7s scaled forwards;
  }

  .not-active span:nth-child(2) {
    animation: ease 0.7s scaled-2 forwards;
  }

  .active span:nth-child(3) {
    animation: ease 0.7s bottom forwards;
  }

  .not-active span:nth-child(3) {
    animation: ease 0.7s bottom-2 forwards;
  }

  @keyframes top {
    0% {
      top: 0;
      transform: rotate(0);
    }
    50% {
      top: 22px;
      transform: rotate(0);
    }
    100% {
      top: 22px;
      transform: rotate(45deg);
    }
  }

  @keyframes top-2 {
    0% {
      top: 22px;
      transform: rotate(45deg);
    }
    50% {
      top: 22px;
      transform: rotate(0deg);
    }
    100% {
      top: 0;
      transform: rotate(0deg);
    }
  }

  @keyframes bottom {
    0% {
      bottom: 0;
      transform: rotate(0);
    }
    50% {
      bottom: 22px;
      transform: rotate(0);
    }
    100% {
      bottom: 22px;
      transform: rotate(135deg);
    }
  }

  @keyframes bottom-2 {
    0% {
      bottom: 22px;
      transform: rotate(135deg);
    }
    50% {
      bottom: 22px;
      transform: rotate(0);
    }
    100% {
      bottom: 0;
      transform: rotate(0);
    }
  }

  @keyframes scaled {
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes scaled-2 {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
</style>
