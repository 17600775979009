<template>
  <LineChart :chart-data="data" :options="options" css-classes="chart-container" />
</template>

<script>
  import { computed, ref } from 'vue';
  import { LineChart } from 'vue-chart-3';
  import { Chart, registerables, Tooltip } from 'chart.js';
  Chart.register(...registerables, Tooltip);

  export default {
    name: 'lineChartDouble',
    components: {
      LineChart,
    },
    props: {
      colorDatalabels: {
        type: String,
        default: '#787f8f',
      },
      chartData: {
        type: Array,
      },
      chartDataSecond: {
        type: Array,
      },
      labels: {
        type: Array,
      },
      formatLabel: {
        type: String,
        default: '',
      },
      tension: {
        type: Number,
        default: 0,
      },
      backgroundColor: {
        type: String,
        default: 'transparent',
      },
      backgroundColorTwo: {
        type: String,
        default: 'transparent',
      },
      pointColor: {
        type: String,
        default: '#0093BA',
      },
      pointColorTwo: {
        type: String,
        default: '#0093BA',
      },
      borderColor: {
        type: String,
        default: '#0093BA',
      },
      borderColorTwo: {
        type: String,
        default: '#0093BA',
      },
      fill: {
        type: Boolean,
        default: false,
      },
      displayDataLabels: {
        type: Boolean,
        default: true,
      },
    },
    setup(props) {
      const dataValues = ref([props.chartData]);
      const dataValuesSecond = ref([props.chartDataSecond]);
      const dataDisplayDataLabels = ref([props.displayDataLabels]);
      const data = computed(() => ({
        labels: props.labels,
        datasets: [
          {
            label: '',
            data: dataValues.value[0],
            barPercentage: 0.4,
            borderColor: props.borderColor,
            backgroundColor: props.backgroundColor,
            pointBackgroundColor: props.pointColor,
            fill: false,
            tension: props.tension,
          },
          {
            label: '',
            data: dataValuesSecond.value[0],
            barPercentage: 0.4,
            borderColor: props.borderColorTwo,
            backgroundColor: props.backgroundColorTwo,
            pointBackgroundColor: props.pointColorTwo,
            fill: props.fill,
            tension: props.tension,
          },
        ],
      }));
      const options = ref({
        locale: 'ru-RU',
        scales: {
          y: {
            type: 'linear',
            ticks: {
              callback: (value) => {
                return value + props.formatLabel;
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: dataDisplayDataLabels,
            clamp: true,
            align: 'top',
            anchor: 'top',
            color: props.colorDatalabels,
            textAlign: 'center',
            font: {
              fontSize: '14px',
            },
            formatter: function (value) {
              return value;
            },
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.formattedValue + ` ${props.formatLabel}`;
              },
            },
          },
          title: {
            text: 'Line',
          },
        },
      });
      return {
        data,
        options,
      };
    },
  };
</script>
