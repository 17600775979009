<template>
  <div></div>
</template>

<script>
  import userManager from '@/modules/auth/user-manager';

  import store from '@/store/index';

  export default {
    name: 'Logout',
    created() {
      userManager.clearStaleState();
      store.dispatch('auth/oidc/logout');
    },
  };
</script>
