<template>
  <NsiTable :pageTitle="pageTitle" :apiRoute="apiRoute" :cardName="cardName" :canEdit="canEdit" />
</template>

<script>
  import NsiTable from '../components/NsiTable';

  export default {
    name: 'InvestigationTypes',
    components: {
      NsiTable,
    },
    data() {
      return {
        pageTitle: 'Типы расследования',
        canEdit: true,
        apiRoute: 'nsiinvestigationtype',
        cardName: 'InvestigationTypeCard',
      };
    },
    created() {},
    methods: {},
  };
</script>
