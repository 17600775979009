<template>
  <div>
    <apexchart :height="height" class="chartBar" type="line" :options="chartOptions" :series="chartSeries"></apexchart>
  </div>
</template>

<script>
  import chartConstants from '@/components/ChartComponents/chartConstants.js';

  export default {
    props: {
      xData: {
        type: Array,
        default: () => [],
      },
      columnYData: {
        type: Array,
        default: () => [],
      },
      columnYName: {
        type: String,
        default: 'Количество выявленных барьеров',
      },
      lineYData: {
        type: Array,
        default: () => [],
      },
      lineYName: {
        type: String,
        default: 'Сумма ущерба, млрд долл. США',
      },
      dataLabelsText: {
        type: String,
        default: 'шт',
      },

      lineYTooltipPrefix: {
        type: String,
      },

      colors: {
        type: Array,
        default: () => ['#17A1FA', '#73EA48'],
      },

      enableSecondYAxis: {
        default: false,
        type: Boolean,
      },

      marker: {
        type: Object,
        default: () => ({
          size: 6,
          colors: [chartConstants.whiteColor],
          strokeColors: '#73EA48',
        }),
      },

      stroke: {
        type: Object,
        default: () => ({
          width: [0, 4],
        }),
      },

      sizeXYLabel: {
        type: Number,
        default: 15,
      },

      sizeDataLabel: {
        type: Number,
        default: 20,
      },

      offsetYDataLabel: {
        type: Number,
        default: -17,
      },

      height: {
        type: Number,
      },

      tickAmount: {
        type: Number,
        default: undefined,
      },

      stepSize: {
        type: Number,
        default: undefined,
      },

      tickAmountSecond: {
        type: Number,
        default: undefined,
      },

      stepSizeSecond: {
        type: Number,
        default: undefined,
      },

      formatYLabelFunction: {
        type: Function,
        default: null,
      },
    },
    data() {
      return {
        chartOptions: {
          chart: {
            fontFamily: 'Fira Sans',
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
          },
        },
        chartSeries: [],
      };
    },
    mounted() {
      this.createChart();
    },
    watch: {
      xData: function () {
        this.createChart();
      },
      columnYData: function () {
        this.createChart();
      },
      lineYData: function () {
        this.createChart();
      },
    },
    methods: {
      createChart() {
        const vue = this;
        this.chartOptions = {
          tooltip: {
            y: {
              formatter: (value, data) => {
                if (data.seriesIndex && vue.lineYTooltipPrefix) {
                  return `${value} ${vue.lineYTooltipPrefix}`;
                } else {
                  return value;
                }
              },
            },
          },
          chart: {
            fontFamily: 'Fira Sans',
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            offsetY: 28,
            itemMargin: {
              // horizontal: 5,
              vertical: 28,
            },
          },
          colors: this.colors,
          labels: this.xData,
          xaxis: {
            labels: {
              style: {
                fontSize: `${vue.sizeXYLabel}px`,
                lineHeight: '16px',
                letterSpacing: '-0.3px',
                colors: '#989898',
              },
            },
            offsetY: 16,
          },

          yaxis: [
            {
              labels: {
                formatter: (value) => {
                  if (value !== undefined) {
                    if (vue.formatYLabelFunction) return vue.formatYLabelFunction(value);
                    return Number.isInteger(value) ? value : value.toFixed(1);
                  } else {
                    return undefined;
                  }
                },
                style: {
                  fontSize: `${vue.sizeXYLabel}px`,
                  lineHeight: '16px',
                  colors: '#989898',
                },
              },
              tickAmount: this.tickAmount,
              stepSize: this.stepSize,
            },
            {
              opposite: true,
              show: this.enableSecondYAxis,
              tickAmount: this.tickAmountSecond,
              stepSize: this.stepSizeSecond,
              labels: {
                formatter: (value) => {
                  if (value !== undefined) {
                    if (vue.formatYLabelFunction) return vue.formatYLabelFunction(value);
                    return Number.isInteger(value) ? value : value.toFixed(1);
                  } else {
                    return undefined;
                  }
                },
                style: {
                  fontSize: `${vue.sizeXYLabel}px`,
                  lineHeight: '16px',
                  colors: '#989898',
                },
              },
            },
          ],
          noData: {
            text: 'Данные отсутствуют',
            verticalAlign: 'top',
            style: {
              color: '#231F20',
              fontSize: '32px',
              lineHeight: '40px',
            },
          },
          responsive: [
            {
              breakpoint: 768,
              options: {
                chart: {
                  width: 768,
                },
              },
            },
          ],
          stroke: this.stroke,
          markers: this.marker,
          dataLabels: {
            enabled: true,
            enabledOnSeries: [0],
            offsetY: vue.offsetYDataLabel,
            formatter: function (val, config) {
              if (vue.formatYLabelFunction && config.seriesIndex === 0) return vue.formatYLabelFunction(val);
              return `${val} ${vue.dataLabelsText}`;
            },
            style: {
              fontSize: `${vue.sizeDataLabel}px`,
              fontFamily: 'Fira Sans',
              colors: ['#304758'],
            },
            background: {
              enabled: false,
            },
          },
        };

        this.chartSeries = [
          {
            name: this.columnYName,
            type: 'column',
            data: this.columnYData,
          },
          {
            name: this.lineYName,
            type: 'line',
            data: this.lineYData,
          },
        ];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chartBar {
    width: 700px;
    height: 300px;
    @media (max-width: $laptop) {
      width: auto;
    }
  }
</style>
