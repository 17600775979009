<!-- !В компоненте используется миксин componentInput, где имеются общие методы -->
<template>
  <div class="w-100" :class="`input-group-wrapper ${modClass}`">
    <input v-if="type === 'checkbox'" type="checkbox" class="form-check-input" v-model="value" :id="id" />
    <label :for="id" v-if="label && type === 'checkbox'" class="form-check-label form-label">{{ label }}</label>

    <label v-if="label && type !== 'checkbox'" class="form-label">{{ label }}</label>
    <Select2
      v-if="type === 'select2'"
      :options="select2Options"
      :settings="select2Settings"
      v-model="value"
      v-on:select="select2OnSelect"
      :placeholder="placeholder || 'Не указано'"
      :disabled="disabledInternal"
      :id="id"
      class="select2"
    />

    <DateComponent
      v-else-if="type === 'date' && dateFormatProps !== 'y'"
      v-model="value"
      :clearable="clearable"
      :label="labelForDate"
      :modClass="modClass"
      :placeholder="placeholderForDate"
      :disabled="disabled"
      :dateIcon="dateIcon"
      :id="id"
      :lowerLimit="lowerLimit"
      :upperLimit="upperLimit"
      :minimumViewDate="minimumViewDate"
      :dateFormatProps="dateFormatProps"
    />
    <!-- TODO: Продублировал компонент для года с целью сокращения времени -->
    <DateYearComponent
      v-else-if="type === 'date' && dateFormatProps === 'y'"
      v-model="value"
      :clearable="clearable"
      :label="labelForDate"
      :modClass="modClass"
      :placeholder="placeholderForDate"
      :disabled="disabled"
      :dateIcon="dateIcon"
      :id="id"
      :lowerLimit="lowerLimit"
      :upperLimit="upperLimit"
      :minimumViewDate="minimumViewDate"
      :dateFormatProps="dateFormatProps"
    />

    <div v-else-if="type !== 'checkbox'" class="clearable">
      <div class="flex-container-label-left">
        <label :for="id" v-if="labelLeft" class="form-label" :class="{}">{{ labelLeft }}</label>
        <input
          :id="id"
          :class="modClass + (type == 'month' ? '' : 'clearable-input')"
          :disabled="disabledInternal"
          v-model="value"
          :type="type === 'number' || type === 'integer' ? 'number' : type"
          @paste="onPaste"
          :placeholder="placeholderInternal"
          :step="type === 'number' || type === 'integer' ? step : null"
          :onkeydown="
            (event) => {
              if (typeInner === 'integer' && (event.keyCode == 190 || event.keyCode == 188 || event.keyCode == 110)) {
                event.preventDefault();
              }
            }
          "
          ref="inputRef"
        />
        <div
          :class="
            type == 'month'
              ? 'clear-button-month'
              : type == 'time'
                ? 'clear-button-time'
                : `clear-button ${clearButtonModClass}`
          "
          v-if="value && value.length"
          @click="clearValue"
        >
          ×
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Inputmask from 'inputmask';
  import { debounce } from 'lodash';

  import DateComponent from '@/common/components/dateComponent.vue';
  import componentInput from '@/common/mixins/componentInput';

  import DateYearComponent from './dateYearComponent.vue';

  export default {
    name: 'FilterInput',
    mixins: [componentInput],
    components: { DateComponent, DateYearComponent },
    emits: ['update:modelValue', 'select2OnSelect', 'valueChangedDebounced'],
    props: {
      disabled: {
        type: Boolean,
      },
      id: String,
      modelValue: [String, Number, Object, Boolean],
      label: {
        type: String,
        default: '',
      },
      labelLeft: {
        type: String,
      },
      placeholder: {
        type: [String, Number],
        default: '',
      },
      modClass: {
        type: String,
        default: '',
        // возможно, в проекте имеются другие моды
        validator: (value) => ['redesigned', 'small'].includes(value),
      },
      step: {
        type: Number,
        default: 1,
      },
      inputMask: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        validator: (value) =>
          [
            'phone',
            'phoneCity',
            'passportSerie',
            'passportNum',
            'birthCertNum',
            'snils',
            'passportDep',
            'time',
            'number',
            'select2',
            'checkbox',
            'index',
            'month',
            'date',
            'integer',
            'year',
          ].includes(value),
      },
      select2Options: {
        type: Array,
        default: () => [],
      },
      select2Settings: {
        type: Object,
        default: () => {},
      },
      // используется в миксине componentInput
      // eslint-disable-next-line
      additionalData: {
        type: Object,
        default: () => {},
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: true,
      },
      // пропсы, связанные с датой
      placeholderForDate: {
        type: String,
      },
      dateIcon: {
        type: Boolean,
        default: false,
      },
      labelForDate: {
        type: String,
      },
      lowerLimit: {
        type: Date,
        default: new Date(1900, 0, 1),
      },
      upperLimit: {
        type: Date,
        default: new Date(2099, 0, 1),
      },
      minimumViewDate: {
        type: String,
        validator: (v) => ['day', 'month', 'year'].includes(v),
      },
      dateFormatProps: {
        type: String,
      },
      clearButtonModClass: {
        type: String,
        default: '',
        validator: (v) => ['small'].includes(v),
      },
    },
    watch: {
      disabled: function (newValue) {
        this.disabledInternal = newValue;
      },
    },
    data() {
      return {
        placeholderInternal: this.placeholder,
        maskInternal: '',
        disabledInternal: this.disabled,
      };
    },
    computed: {
      typeInner() {
        return this.type;
      },
      value: {
        get() {
          if (this.type === 'select2' && this.modelValue && !Array.isArray(this.modelValue))
            return this.modelValue.toString();
          return this.modelValue;
        },
        set(value) {
          this.$emit('update:modelValue', value);
          this.debounceChangeValue(value, this.multiple);
        },
      },
    },
    mounted() {
      this.fillInputParams();
      var mask = this.maskInternal || this.inputMask;
      if (mask !== '') {
        var im = new Inputmask(mask);
        //im.mask($('.form-control'));
        im.mask($(this.$refs.inputRef));
      }
    },
    methods: {
      clearValue() {
        this.value = null;
      },
      debounceChangeValue: debounce(function (value) {
        this.$emit('valueChangedDebounced', value);
      }, 500),
    },
  };
</script>

<style lang="scss">
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    float: initial !important;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__clear {
    position: absolute;
    right: 7px;
    top: 78%;
    transform: translateY(-50%);
  }
</style>

<style lang="scss" scoped>
  @import '../../assets/theme/default/colors';
  .clear-button {
    margin-left: -10px;
    margin-top: 5px;
    cursor: pointer;

    &.small {
      margin-left: -28px;
      margin-top: -1px;
      color: black;
      font: $fira-12-B;
      font-weight: bold;
    }
  }
  .clear-button-time {
    margin-left: -13px;
    margin-top: 7px;
    cursor: default;
  }
  .clear-button-month {
    margin-left: -25px;
    margin-top: 5px;
    cursor: default;
  }
  .clearable {
    display: flex;
    width: 100%;
  }
  .input-group-wrapper {
    align-items: flex-end;
    input:not([type='checkbox']):not([type='time']) {
      min-height: calc(1.25rem + 10px) !important;
      width: 100%;
      outline: none;
      font-weight: normal;
      font-size: 14px;
      line-height: 10px;
      letter-spacing: 0.15px;
      padding: 0px 2px;
    }
    input[type='month'] {
      line-height: 14px;
    }
    .clearable-input {
      padding: 0px 15px 0px 2px;
    }

    &:not(.range &) {
      &:not(&:first-child) {
        margin-top: 5px;
      }
    }
    &.small {
      flex-wrap: wrap;
      display: flex;

      input:not([type='checkbox']):not([type='time']),
      textarea {
        border-radius: 4px;
        padding: 8px;
        padding-right: 16px;
        font: $fira-12;
        min-width: 100%;

        &:focus {
          border-color: $grey;
        }

        &:disabled {
          background-color: #f3f4f6;
          border-color: #f3f4f6;
        }

        &::placeholder {
          font: $fira-12;
        }
      }

      .select2::v-deep {
        min-width: 90px;
        width: 100%;
        .select2-container {
          .select2-selection__rendered {
            padding: 0 24px 0 8px;
            font: $fira-12;
          }
        }

        .select2-selection {
          display: flex;
          align-items: center;
          height: 32px;

          &__arrow {
            height: 12px;
            width: 12px !important;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'%3E %3Cpath d='M3 4.5L6 7.5L9 4.5' stroke='%23989898' stroke-width='2' stroke-linecap='square'/%3E %3C/svg%3E");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            right: 8px;

            b {
              display: none;
            }
          }
        }
      }
    }

    &.date {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    .horizontally-date & {
      margin-top: 0 !important;
    }
  }
  .form-label {
    font-weight: 400 !important;
    font-size: 16px;
  }

  .select2-container {
    min-width: 10em !important;
  }

  .select2::v-deep {
    outline: none;
    .select2-container {
      width: 100% !important;
      max-width: 100% !important;
      border: 1px solid #d6e4ec;
      font-size: 14px;
      letter-spacing: 0.15px;
      transition: 0.3s;
      border-radius: 4px !important;
      min-height: calc(1.25rem + 6px) !important;
      background-color: #fbfbfb;
      outline: none;
      .select2-selection__rendered {
        outline: none;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #343c44;
        margin: 0px 0px 0px 0px !important;
        //padding: 12px 12px 0px 6px;
        li {
          margin-left: 0px !important;
        }
      }
    }
    .select2-container--default .select2-selection .select2-selection__arrow {
      outline: none;
      top: 50% !important;
      transform: translateY(-50%) !important;
      //width: 15px;
      width: 10px;
    }
    .select2-container--default .select2-selection {
      min-height: calc(1.25rem + 4px) !important;
      line-height: 10px;
      border: 0px;
      border-radius: 4px !important;
      padding: 0px 0px;
    }

    .select2-container--default .select2-selection--multiple {
      min-height: calc(1.25rem + 4px) !important;
      line-height: 10px;
      border: 0px;
      border-radius: 4px !important;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice {
      background-color: #d6e4ec;
      border: 0px solid #343c44;
      border-radius: 4px;
      cursor: default;
      float: left;
      padding: 5px;
      color: #343c44;
    }
    .select2-container--default .select2-selection--single,
    .select2-container--default .select2-selection--multiple {
      background-color: #fbfbfb;
    }

    // место под крестик и стрелку
    .select2-container--default .select2-selection--multiple .select2-selection__rendered {
      padding-right: 40px;
    }

    .select2-selection--multiple:after {
      right: 6px;
    }
    //.select2-container--default .select2-selection--single .select2-selection__clear {
    //  cursor: pointer;
    //  float: right;
    //  font: 700 12px/16px 'Fira Sans';
    //  font-weight: bold;
    //  padding: 0px 2px;
    //}
  }

  .flex-container-label-left {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 12px;

    .form-label {
      font: $fira-16;
      margin: 0;
    }
  }

  .redesigned {
    font: $fira-14-20;
    .form-label {
      font: $fira-16;
      margin-bottom: 16px;
    }

    .select2::v-deep {
      .select2-selection--multiple::after {
        right: 10px;
      }

      // стрелка раскрытия инпута
      .select2-container--default .select2-selection .select2-selection__arrow {
        right: 10px;
      }
      .select2-container--default,
      .select2-container--single .select2-selection {
        padding: 9px 14px; // паддинки внутри инпута
        font: $fira-14-20;
        border-radius: 4px;
        border: 1px solid $grey;
        background: $light-grey;

        // поле ввода инпута
        .select2-selection__rendered {
          padding: 0;
          max-width: 220px;
          font: $fira-14-20;
          height: 27px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      // выбранный элемент инпута
      .select2-container--default .select2-selection--multiple .select2-selection__choice {
        padding: 0;
        border-radius: 0;
        border: none;
        background: none;
        margin: 0;
        margin-right: 20px;
        position: relative;
      }

      // Крестик на выбранном селекте
      .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
        position: absolute;
        right: -16px;
        top: 0px;
        font-size: 20px;
      }

      // ширина поля ввода внутри инпута
      .select2-container--default
        .select2-selection
        .select2-selection__rendered
        .select2-search
        .select2-search__field {
        max-width: 220px !important;
      }
    }

    // инпут с датой
    .form-date {
      &__container {
        display: flex;
        gap: 12px;
        align-items: center;
      }
    }
  }

  .input-group-wrapper.redesigned {
    // инпут типа single
    input:not([type='checkbox']):not([type='time']) {
      padding: 9px 14px;
      font-size: 14px;
      letter-spacing: 0.15px;
      transition: 0.3s;
      border-radius: 4px !important;
      min-height: calc(1.25rem + 6px) !important;
      background-color: #fbfbfb;
      outline: none;
      height: 40px;
    }

    // крестик внутри инпута
    .clear-button {
      margin-left: -32px;
      margin-top: 0;
      cursor: pointer;
    }
  }

  //checkbox
  .input-group-wrapper.checkbox-green {
    .form-check-input {
      opacity: 0;
      margin: 0;
      width: 20px;
      height: 20px;
      z-index: 10;
    }

    .form-check-label {
      position: relative;
      font-family: 'Fira Sans';
      font-size: 16px;
      line-height: 20px;
      padding-left: 32px;
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        position: absolute;
        border-radius: 4px;
        border: 1px solid $green;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        z-index: 0;
      }

      &::after {
        opacity: 0;
        border: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3e%3crect width='20' height='20' rx='4' fill='url(%23paint0_linear_3530_38638)'/%3e%3cpath d='M15 5.8335L8.125 13.3335L5 9.92441' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cdefs%3e%3clinearGradient id='paint0_linear_3530_38638' x1='20' y1='10' x2='0' y2='10' gradientUnits='userSpaceOnUse'%3e%3cstop stop-color='%2301A39D'/%3e%3cstop offset='1' stop-color='%230078C8'/%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
        transition: 0.5s ease;
        z-index: 1;
      }
    }

    input:checked ~ .form-check-label {
      &::after {
        opacity: 1;
      }
    }
  }
</style>
