<template>
  <div class="apexchart-donut" :class="{ customDonutStyles: customStyles }">
    <apexchart type="donut" :options="chartOptions" :series="series"> </apexchart>
  </div>
</template>

<script>
  export default {
    name: 'ChartDonut',
    props: {
      seriesProp: Array,
      template: {
        default: 'default',
        type: String,
      },
      customStyles: {
        default: false,
        type: Boolean,
      },
      nn: Number,
      colors: Array,
      dataLabels: {
        type: Object,
        default: () => ({
          colors: ['#fff'],
          offset: 0,
          enebledShadow: true,
        }),
      },
      legend: {
        type: Object,
        default: () => ({
          show: false,
          position: 'bottom',
        }),
      },
    },
    watch: {
      nn: function (/*newValue*/) {
        console.log(' ------------ props');
        this.loadChart();
      },
      seriesProp: function () {
        this.loadChart();
      },
    },
    data() {
      return {
        // data for templates
        labelsArray: [],
        countArray: [],
        // Stock options not used
        chartOptions: {
          legend: {
            position: 'right',
            markers: {
              width: 20,
              height: 20,
              radius: 0,
              offsetY: 5,
            },
            offsetX: 100,
            offsetY: 200,
            itemMargin: {
              horizontal: 0,
              vertical: 8,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            //colors: ['#02E5DD'],
          },
          labels: ['Apples', 'Oranges', 'Berries', 'Grapes'],
          //colors: ['#02E5DD', '#01A39D', '#017A76', '#018F89', '#01524F', '#016662','#002927','#003D3B','#00BFB8'],
        },
      };
    },
    methods: {
      // templates
      createChart() {
        // Массив для категорий
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].name;
          this.labelsArray.push(item);
        }
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].count;
          this.countArray.push(item);
        }
        this.chartOptions = {
          legend: {
            position: this.legend.position,
            markers: {
              width: 20,
              height: 20,
              radius: 0,
              offsetY: 5,
            },
            offsetX: 100,
            offsetY: 0,
            itemMargin: {
              horizontal: 0,
              vertical: 8,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            colors: undefined,
          },
          labels: this.labelsArray,
          colors: [
            '#00ddff',
            '#06b7e3',
            '#0a6bb1',
            '#0382d7',
            '#0068e0',
            '#004ee0',
            '#0657ed',
            '#21adf9',
            '#21eef0',
            '#24afd0',
            '#00fee1',
            '#01fdf9',
            '#99eeee',
            '#90ffff',
            '#34ead9',
            '#55ddf9',
            '#99eeff',
            '#eeeeee',
            '#3fffef',
            '#3EFFFF',
            '#4431ee',
            '#af99ee',
            '#afee99',
            '#afeeee',
            '#afff99',
            '#99ffff',
          ],
          noData: {
            text: 'Загрузка...',
          },
          tooltip: {
            theme: 'dark',
            fillSeriesColor: false,
            y: {
              formatter: function (value) {
                value += ' ед.';
                return value;
              },
            },
          },
          responsive: [
            {
              breakpoint: 1130,
              options: {
                legend: {
                  offsetX: 0,
                  offsetY: 100,
                },
              },
            },
            {
              breakpoint: 768,
              options: {
                legend: {
                  position: this.legend.position,
                  offsetX: 0,
                  offsetY: 0,
                  markers: {
                    offsetY: 0,
                  },
                  itemMargin: {
                    horizontal: 24,
                    vertical: 0,
                  },
                },
              },
            },
            {
              breakpoint: 475,
              options: {
                legend: {
                  horizontalAlign: 'left',
                  markers: {
                    offsetY: 6,
                  },
                  itemMargin: {
                    horizontal: 8,
                    vertical: 4,
                  },
                },
              },
            },
          ],
          chart: {
            width: '100%',
          },
        };
        this.series = this.countArray;
      },
      // уже не совсем сток
      createStockChart() {
        this.labelsArray = [];
        this.countArray = [];
        // Массив для категорий
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].name;
          this.labelsArray.push(item);
        }
        for (let i = 0; i < this.seriesProp.length; i++) {
          let item = this.seriesProp[i].count;
          this.countArray.push(item);
        }
        this.chartOptions = {
          dataLabels: {
            enabled: true,
            textAnchor: 'start',
            // remove percent
            formatter: function (val, opts) {
              return opts.w.config.series[opts.seriesIndex];
            },
            style: {
              colors: this.dataLabels.colors,
            },
            dropShadow: {
              enabled: this.dataLabels.enebledShadow,
            },
          },
          plotOptions: {
            pie: {
              offsetY: 10,
              dataLabels: {
                offset: this.dataLabels.offset,
              },
            },
          },
          stroke: {
            show: true,
            colors: undefined,
          },
          labels: this.labelsArray,
          noData: {
            text: 'Загрузка...',
          },
          tooltip: {
            theme: 'dark',
            fillSeriesColor: false,
          },
          legend: {
            show: this.legend.show,
            position: this.legend.position,
          },
          colors: this.colors,
        };
        this.series = this.countArray;
      },

      // loading templates
      loadChart() {
        if (this.template == 'default') {
          this.createChart();
        } else if (this.template == 'stock') {
          this.createStockChart();
        }
      },
    },
    created() {
      this.loadChart();
    },
  };
</script>

<style lang="scss">
  .customDonutStyles {
    .apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series,
    .apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
      width: 20%;
    }
  }
  @media (max-width: 425px) {
    .customDonutStyles {
      .apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series,
      .apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
        width: 45%;
      }
    }
  }
  @media (max-width: 375px) {
    .customDonutStyles {
      .apexcharts-legend.apx-legend-position-bottom .apexcharts-legend-series,
      .apexcharts-legend.apx-legend-position-top .apexcharts-legend-series {
        width: 100%;
      }
    }
  }
</style>
