<template>
  <div class="container-page">
    <div class="messenger" :class="{ 'is-left-column-shown': showSideBar }">
      <div class="messenger__form">
        <div class="messenger__row">
          <div class="messenger__dialog-search-wrapper d-flex flex-column" v-on:scroll="scrollDialogs">
            <div class="d-flex justify-content-between align-items-center messenger__dialog-search">
              <input
                class="messenger__search d-block"
                type="text"
                placeholder="Поиск"
                v-model="searchText"
                @keypress="startSearchByEnter($event)"
              />
              <IconComponent class="mr-2" name="Union" @click="startSearch" />
              <IconComponent
                class="clear"
                v-if="searchText != null && searchText != ''"
                @click="clearSearch"
                name="clear_cross_transparent"
              />
            </div>
            <div class="messenger__dialog-list scrollable-content">
              <div
                v-for="dialog in dialogs"
                :key="dialog.id"
                class="messenger__dialog-item d-flex justify-content-between"
                v-bind:class="{ active: currentDialogDetail && currentDialogDetail.id === dialog.id }"
              >
                <div
                  v-bind:style="
                    dialog.abonent && dialog.abonent.picture && dialog.abonent.picture.length > 0
                      ? ''
                      : { background: setGroupIcon(dialog.chatTypeId).bgColor }
                  "
                  class="messenger__user-icon"
                >
                  <img
                    :src="
                      dialog.abonent && dialog.abonent.picture && dialog.abonent.picture.length > 0
                        ? dialog.abonent.picture
                        : require('@/assets/images/svg/' + setGroupIcon(dialog.chatTypeId).src)
                    "
                    alt=""
                    v-if="dialog.chatTypeId > 0"
                    class="messenger__user-icon"
                  />
                </div>
                <div class="info-about">
                  <div>
                    <div
                      @click.prevent="onChatChanged(dialog)"
                      class="d-flex flex-column messenger__dialog-name-mess-wrapp"
                    >
                      <div @click="showSideBar = !showSideBar" class="d-flex justify-content-between mb-6 topic-block">
                        <b class="messenger__user-name">{{ dialog.title }}</b>
                        <div class="flex-column messenger__date-popup-btn">
                          <span>{{ $momentFormat(dialog.lastMessageDate, 'HH:mm') }}</span>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div @click="showSideBar = !showSideBar" class="messenger__last-mess">
                          <span class="mr-6" v-if="dialog.lastMessageAuthor != null">
                            {{ dialog.lastMessageAuthor }}:
                          </span>
                          <span class="lastMessage">
                            {{
                              dialog.lastMessageText
                                ? (dialog.chatTypeId === 4 ? dialog.lastMessageAuthor + ': ' : '') +
                                  dialog.lastMessageText
                                : 'Здесь пока нет сообщений'
                            }}
                          </span>
                        </div>
                        <div class="aling-items-center flex-column">
                          <div class="mb-2" v-if="dialog.settings && dialog.settings.muted">
                            <i class="fas fa-bell-slash"></i>
                          </div>
                          <div class="mb-2" v-if="dialog.settings && dialog.settings.sticky">
                            <IconComponent name="thumbtack" class="mx-1" />
                          </div>
                          <div
                            class="align-items-baseline"
                            @click.prevent="
                              () => {
                                currentDialog = dialog;
                                if (dialog.id == currentDialog.id) showDialogActions = !showDialogActions;
                              }
                            "
                            v-if="dialog.abonent"
                          >
                            <span v-if="item && item.entityStatus" class="items-preview__opacity-symbols">{{
                              item.entityStatus.title
                            }}</span>
                            <IconComponent name="dotsicon" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="dialog.unreadMessageCount" class="messenger__dialog-unread-msg-cnt">
                      {{ dialog.unreadMessageCount }}
                    </div>
                  </div>
                </div>
                <ul v-if="showDialogActions && dialog.id == currentDialog.id" class="messenger__dial-popup-1">
                  <li @click="employeeModal = true" v-if="dialog.chatTypeId == 1 && dialog.abonent">
                    <router-link class="messenger-menu-link btn shadow-none btn-default" to="#"
                      >Данные контакта</router-link
                    >
                  </li>
                  <li v-if="dialog.id > 0" @click="muteChat(dialog)">
                    <router-link class="messenger-menu-link btn shadow-none btn-default" to="#">Без звука</router-link>
                  </li>
                  <li v-if="dialog.id > 0" @click="clearChat(dialog)">
                    <router-link class="messenger-menu-link btn shadow-none btn-default" to="#"
                      >Очистить чат</router-link
                    >
                  </li>
                  <li v-if="dialog.id > 0" @click="pinChat(dialog)">
                    <router-link class="messenger-menu-link btn shadow-none btn-default" to="#">
                      {{ dialog.settings && dialog.settings.sticky ? 'Открепить' : 'Закрепить' }}
                    </router-link>
                  </li>
                </ul>
                <ModalComponent
                  @close="employeeModal = false"
                  v-if="employeeModal && currentDialog.abonent"
                  v-model="employeeModal"
                >
                  <div class="border-bottom pb-2 mb-2">
                    ФИО:
                    <span>
                      {{ currentDialog.abonent.fullName }}
                    </span>
                  </div>
                  <div v-if="currentDialog.abonent.organization" class="border-bottom pb-2 mb-2">
                    Организация:
                    <span>
                      {{ currentDialog.abonent.organization.name }}
                    </span>
                  </div>
                  <div v-if="currentDialog.abonent.department" class="border-bottom pb-2 mb-2">
                    Подразделение:
                    <span>
                      {{ currentDialog.abonent.department.name }}
                    </span>
                  </div>
                  <div v-if="currentDialog.abonent.position" class="border-bottom pb-2 mb-2">
                    Должность:
                    <span>
                      {{ currentDialog.abonent.position }}
                    </span>
                  </div>
                  <div v-if="currentDialog.abonent.email" class="border-bottom pb-2 mb-2">
                    Email:
                    <span>
                      {{ currentDialog.abonent.user.email }}
                    </span>
                  </div>
                </ModalComponent>
              </div>
            </div>
          </div>
          <div class="messenger__cur-dialog-area" id="column-center">
            <div class="d-flex justify-content-between messenger__cur-dialog-title-area">
              <div class="d-flex">
                <IconComponent
                  @click="showSideBar = !showSideBar"
                  :class="{ rotate: showSideBar }"
                  class="mr-6 sidebar-close-btn"
                  name="arrow_back"
                />
                <div class="d-block">
                  <div class="d-block flex-column align-items-start">
                    <span class="mr-6" v-if="currentDialogDetail && currentDialogDetail.title">{{
                      currentDialogDetail.title
                    }}</span>
                    <span
                      v-if="
                        currentDialogDetail && currentDialogDetail.abonent && currentDialogDetail.abonent.department
                      "
                    >
                      {{ currentDialogDetail.abonent.department.name }}
                    </span>
                  </div>
                  <span
                    v-if="currentDialogDetail && currentDialogListType === 1"
                    class="d-block messenger__opponent-last-activitiy left"
                  >
                    Был(а) в {{ $momentFormat(currentDialogDetail.lastMessageDate, 'DD.MM.YYYY HH:mm') }}
                  </span>
                </div>
              </div>
              <button
                @click="
                  () => {
                    currentDialogDetail.isModal = !currentDialogDetail.isModal;
                    isMessActions = false;
                  }
                "
                class="messenger__actions-btn dis-btn-css"
                type="button"
              >
                <IconComponent name="dotsicon" />
              </button>
            </div>
            <template v-if="messages">
              <div class="messenger__messages scrollable-content" v-on:scroll="scrollMessages">
                <button
                  @click="addMembers"
                  v-if="currentDialogDetail && currentDialogDetail.chatTypeId == 2"
                  class="messenger__add-employers"
                >
                  <span> + </span>
                  Добавить участников
                </button>
                <MessengerModal
                  @cancel="() => (isConfigChatPopup = false)"
                  v-if="isConfigChatPopup"
                  :employersList="employersList"
                  :employerStatuses="employerStatuses"
                  :isRoom="isCreateRoomPopup"
                  :isGroup="isCreateGroupPopup"
                  v-on:doAction="executeChatAction"
                />
                <div v-for="(gr, key, gridx) in messages" :key="gr.key">
                  <span class="d-block mt-2 mb-2 messenger__opponent-last-activitiy">{{ getDateText(key) }}</span>
                  <div
                    @click="closeModal"
                    class="messenger__message-wrapp d-flex align-items-baseline"
                    :class="{
                      'ml-auto justify-content-end my-mess': message.isMyMessage,
                      'mr-auto justify-content-start': !message.isMyMessage,
                    }"
                    v-for="(message, idx) in gr"
                    :key="message.id"
                  >
                    <template v-if="message.isMyMessage">
                      <img
                        class="mr-2"
                        src="../../assets/images/svg/favorite_icon.svg"
                        alt="картинка не загрузилась"
                        v-if="false"
                      />
                      <div
                        class="d-flex flex-column"
                        :id="
                          idx == gr.length - 1 && gridx == Object.keys(messages).length - 1
                            ? 'lastMsgDiv'
                            : 'msgDiv_' + gridx + '_' + idx
                        "
                      >
                        <div class="messenger__message-date">
                          {{ $momentFormat(message.created, 'HH:mm') }}
                        </div>
                        <p
                          :class="{
                            'messenger__my-mess': message.isMyMessage,
                            'messenger__not-my-mess': !message.isMyMessage,
                          }"
                        >
                          {{ message.text }}
                          <template v-if="message.files">
                            <div v-for="file in message.files" :key="file.id">
                              <a href="#" @click.prevent="downloadDoc(file)" class="file">{{ file.originalName }}</a>
                            </div>
                          </template>
                        </p>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="d-flex flex-column"
                        :id="
                          idx == gr.length - 1 && gridx == Object.keys(messages).length - 1
                            ? 'lastMsgDiv'
                            : 'msgDiv_' + gridx + '_' + idx
                        "
                      >
                        <div class="messenger__message-date">
                          {{ $momentFormat(message.created, 'HH:mm') }}
                        </div>
                        <p class="messenger__not-my-mess">
                          {{ message.text }}
                          <template v-if="message.files">
                            <div v-for="file in message.files" :key="file.id">
                              <a href="#" @click.prevent="downloadDoc(file)" class="file">{{ file.originalName }}</a>
                            </div>
                          </template>
                        </p>
                      </div>
                      <img
                        class="ml-5"
                        src="../../assets/images/svg/favorite_icon.svg"
                        alt="картинка не загрузилась"
                        v-if="false"
                      />
                    </template>
                  </div>
                </div>
              </div>
            </template>
            <div class="d-flex messenger__messages-inputs-area" v-if="currentDialogDetail">
              <div class="textarea-input">
                <textarea
                  class="dis-btn-css"
                  type="text"
                  multiple
                  placeholder="Cообщение"
                  v-on:keyup.enter="postMessage"
                  v-model="messageText"
                  id="text-message"
                ></textarea>
                <label class="upload-btn upload-btn--mess ml-auto" for="file">
                  <IconComponent name="file" />
                </label>
              </div>

              <IconComponent
                @click="postMessage"
                v-if="(messageText != null && messageText != '') || (files && files.length > 0)"
                class="ml-2"
                name="send"
              />
              <input
                class="d-none"
                id="file"
                type="file"
                multiple="multiple"
                ref="fileInput"
                v-on:change="uploadFile($event)"
              />
            </div>
            <div class="messenger__messages-files-area" v-if="currentDialogDetail">
              <div class="file-wrapper d-flex justify-content-between" v-for="(file, index) in files" :key="index">
                {{ file.originalName }}, {{ Math.ceil(file.fileSize / 1024) }} Кб
                <a href="javascript:" @click="removeFile(index)">
                  <IconComponent name="fileTimes" />
                </a>
              </div>
            </div>
            <div class="messenger__user-settings d-flex flex-column">
              <button
                @click="
                  () => {
                    isMessActions = !isMessActions;
                    currentDialogDetail.isModal = false;
                  }
                "
                class="messenger__actions-btn dis-btn-css"
                type="button"
              >
                <IconComponent name="dotsicon" />
              </button>
              <button @click="() => (isMuted = !isMuted)" class="messenger__muted-btn dis-btn-css" type="button">
                <IconComponent name="notif" />
              </button>
            </div>
            <ul v-if="isMessActions" class="messenger__actions-popup">
              <li @click="addChat(true)">
                <router-link class="messenger-menu-link btn shadow-none btn-default" to="#">Новая группа</router-link>
              </li>
              <li>
                <router-link
                  class="messenger-menu-link btn shadow-none btn-default"
                  :to="`/admin/users/view/${currentDialogDetail.employeeId}`"
                  target="_blank"
                >
                  Профиль
                </router-link>
              </li>
              <li>
                <router-link class="messenger-menu-link btn shadow-none btn-default" to="#">Избранное</router-link>
              </li>
              <li>
                <router-link class="messenger-menu-link btn shadow-none btn-default" to="/">Выйти</router-link>
              </li>
            </ul>
            <ul v-if="currentDialogDetail && currentDialogDetail.isModal" class="messenger__dial-popup">
              <li>
                <router-link
                  class="messenger-menu-link btn shadow-none btn-default"
                  :to="`/admin/users/view/${currentDialogDetail.employeeId}`"
                  target="_blank"
                >
                  Данные контакта
                </router-link>
              </li>
              <li>
                <router-link class="messenger-menu-link btn shadow-none btn-default" to="#">Без звука</router-link>
              </li>
              <li @click="clear(currentDialogDetail)">
                <router-link class="messenger-menu-link btn shadow-none btn-default" to="#">Очистить чат</router-link>
              </li>
              <li v-if="currentDialogDetail && currentDialogDetail.chatTypeId != 1">
                <router-link class="messenger-menu-link btn shadow-none btn-default" to="#">Удалить чат</router-link>
              </li>
              <li @click="pinChat(currentDialogDetail)">
                <router-link class="messenger-menu-link btn shadow-none btn-default" to="#">
                  {{ currentDialogDetail.settings && currentDialogDetail.settings.sticky ? 'Открепить' : 'Закрепить' }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  import FileApi from '@/common/api/file.js';
  import Constants from '@/common/constants.js';
  import UTILS from '@/common/utils';

  import MessengerModal from '@/components/modals/MessengerModal.vue';

  import Api from '@/modules/messenger/api/index';

  import IconComponent from '../../common/ui/IconComponent';
  import ModalComponent from '../../components/modals/ModalComponent';

  export default {
    name: 'Messenger',
    components: { ModalComponent, IconComponent, MessengerModal },
    props: {
      filter: {
        dialogType: {
          type: Number,
          default: 1,
        },
      },
      initialDialogId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        employeeModal: false,
        searchText: '',
        isConfigChatPopup: false,
        isCreateGroupPopup: false,
        isMessActions: false,
        showDialogActions: false,
        showSideBar: false,
        isMuted: false,
        employerStatuses: {},
        employersList: [],
        currentDialogListType: null,
        dialogRequest: {
          pageNumber: 1,
          pageSize: 5,
        },
        messageRequest: {
          offset: 0,
          limit: 10,
        },
        dialogs: {
          itemsTotal: 0,
          items: [],
        },
        foundList: [],
        isSearch: false,
        messages: {},
        currentDialogDetail: null,
        currentDialog: null,
        messageText: null,
        scrolling: false,
        currEmployeeId: this.$route.params.employeeid,
        files: [],
      };
    },
    created() {
      this.loadDialogs(null, this.initialDialogId);
      this.loadEmployers();
    },
    mounted() {
      $('#text-message').on('keyup keypress', function () {
        $(this).height(0);
        $(this).height(this.scrollHeight);
      });
    },
    methods: {
      downloadDoc(file) {
        var url = UTILS.getFileUrlGuid(file.guid);
        UTILS.downloadFile(url, file.originalName);
      },
      startSearch() {
        this.isSearch = true;
        this.loadDialogs(this.searchText);
      },
      startSearchByEnter(e) {
        if (e.keyCode === 13) {
          this.startSearch();
        }
      },
      clearSearch() {
        this.isSearch = false;
        this.searchText = '';
        this.loadDialogs();
      },
      getDateText(dt) {
        var date = new Date(dt);
        if (new Date().getDate() === date.getDate()) return 'Сегодня';
        if (new Date().getDate() - 1 === date.getDate()) return 'Вчера';
        if (new Date().getDate() - 2 === date.getDate()) return 'Позавчера';
        return moment(date).format('DD.MM.YYYY');
      },
      changeDialog(dialog) {
        if (this.currentDialogDetail && dialog && dialog.id !== this.currentDialogDetail.id) {
          // mark messages as read before we left the chat
          this.readMessages();
        }
        this.currentDialogDetail = dialog;
        this.messages = [];
        if (dialog) {
          this.currentDialogListType = this.currentDialogDetail.chatTypeId;
          this.currentDialogDetail.isModal = false;
          if (dialog.messages) {
            this.messages = Object.assign({}, dialog.messages);
          } else {
            this.loadMessages(dialog.id);
          }
          this.$nextTick(() => {
            // var lastMsgDiv = $("#lastMsgDiv")[0];
            // if (lastMsgDiv) {
            //   lastMsgDiv.scrollIntoView();
            // }
          });
        }
      },
      onChatChanged(dialog) {
        this.changeDialog(dialog);
      },
      setGroupIcon(id) {
        let settings = {
          src: '',
          bgColor: '',
        };
        switch (id) {
          case 1:
            settings.src = 'avatar.png';
            settings.bgColor = '#787F8F';
            break;
          case 2:
            settings.src = 'Union.svg';
            settings.bgColor = '#6FCF97';
            break;
          case 3:
            settings.src = 'favorite_icon.svg';
            settings.bgColor = '#6FCF97';
            break;
          case 4:
            settings.src = 'archiv_icon.svg';
            settings.bgColor = '#b0b5b0';
            break;
          case 5:
            settings.src = 'favorite_icon.svg';
            settings.bgColor = '#D3A52B';
            break;
          case 6:
            settings.src = 'support_icon.svg';
            settings.bgColor = '#E5E5E5';
            break;
        }
        return settings;
      },
      loadDialogs(searchText, currDlgId, noChange) {
        Api.loadDialogs(searchText).then((response) => {
          if (!searchText) {
            this.dialogs = response.data;
            if (this.dialogs.length > 0) {
              const currDlg = currDlgId ? this.dialogs.find((d) => d.id == currDlgId) : this.dialogs[0];
              if (!noChange) {
                this.changeDialog(currDlg);
              }
            }
          } else {
            this.dialogs = response.data;
            this.foundList = response.data;
            this.changeDialog(undefined);
          }
          this.updateUnreadCounters();
          this.openChatForEmployee();
        });
      },
      loadMessages(dialogId) {
        Api.loadMessages(dialogId).then((response) => {
          this.messages = response.data;
          this.currentDialogDetail.messages = Object.assign({}, this.messages);
          this.$nextTick(() => {
            // var lastMsgDiv = $("#lastMsgDiv")[0];
            // if (lastMsgDiv)
            //   lastMsgDiv.scrollIntoView();
          });
        });
        //this.currentDialogListType = dialog.chatTypeId
        //this.currentDialogDetail = this.dialogs.find((item)=> item.id === dialog.id)
      },
      loadEmployers() {
        Api.loadEmployers().then((response) => {
          this.employersList = response.data;
        });
      },
      pinChat(d) {
        d.showDialogActions = false;
        var data;
        if (d.settings) {
          data = d.settings;
          data.sticky = !d.settings.sticky;
        } else {
          data = {
            chatId: d.id,
            sticky: true,
          };
        }
        Api.updateChatSettings(data).then(() => {
          this.loadDialogs('', this.currentDialogDetail);
        });
      },
      muteChat(d) {
        d.showDialogActions = false;
        var data;
        if (d.settings) {
          data = d.settings;
          data.muted = !d.settings.muted;
        } else {
          data = {
            chatId: d.id,
            muted: true,
          };
        }
        Api.updateChatSettings(data).then(() => {
          this.loadDialogs('', this.currentDialogDetail);
        });
      },
      scrollDialogs(e) {
        const el = $(e.target);
        const top = el.scrollTop();
        if (e.target.scrollHeight - top === e.target.clientHeight) {
          if (!this.scrolling) {
            const count = this.dialogs.length;
            if (count < this.dialogs.itemsTotal) {
              this.scrolling = true;
              this.dialogRequest.pageNumber = this.dialogRequest.pageNumber + 1;
              const request = Object.assign(this.dialogRequest, this.filter);
              Api.loadDialogs(request)
                .then((response) => {
                  this.dialogs = this.dialogs.concat(response.data);
                  this.updateUnreadCounters();
                  this.scrolling = false;
                })
                .catch(() => {
                  this.scrolling = false;
                });
            }
          }
        }
      },
      scrollMessages(e) {
        const el = $(e.target);
        //if (e.target.scrollHeight - el.scrollTop <= e.target.clientHeight) {
        if (el.scrollTop() <= 50) {
          if (!this.scrolling) {
            const count = this.messageRequest.offset + this.messageRequest.limit;
            var obj = {};
            if (count < this.currentDialogDetail.messagesTotalCount) {
              this.scrolling = true;
              this.messageRequest.offset = this.messageRequest.offset + this.messageRequest.limit;
              Api.loadMessages(this.currentDialogDetail.id, this.messageRequest)
                .then((response) => {
                  for (const [key, value] of Object.entries(response.data)) {
                    if (Object.keys(this.messages).includes(key)) {
                      this.messages[key] = value.concat(this.messages[key]);
                    } else {
                      obj[key] = value;
                    }
                  }
                  Object.keys(obj).length > 0;
                  this.messages = Object.assign(obj, this.messages);
                  //this.messages = this.messages.concat(response.data);
                  this.readMessages();
                  this.scrolling = false;
                  this.currentDialogDetail.messages = Object.assign([], this.messages);
                  this.dialogs.find((d) => d.id === this.currentDialogDetail.id).messages = Object.assign(
                    [],
                    this.messages,
                  );
                  el.scrollTop(50);
                })
                .catch(() => {
                  this.scrolling = false;
                });
            }
          }
        }
      },
      updateUnreadCounters() {
        // this.dialogs.forEach((dlg) => {
        //   Api.getUnreadMessageCount(dlg.id).then((response) => {
        //     dlg.unreadMessageCount = response.data;
        //   });
        // });
        var dialogIds = [];
        this.dialogs.forEach((x) => {
          dialogIds.push(x.id);
        });
        Api.getUnreadMessagesCount(dialogIds).then((response) => {
          Object.keys(response.data).forEach((key) => {
            const dlg = this.dialogs.find((d) => d.id === key);
            dlg.unreadMessageCount = response.data[key];
          });
        });

        this.$store.commit('messenger/updateUnread', 1);
      },
      updateUnreadCounter(dlgId) {
        Api.getUnreadMessageCount(dlgId).then((response) => {
          const dlg = this.dialogs.find((d) => d.id === dlgId);
          dlg.unreadMessageCount = response.data;
        });
        this.$store.commit('messenger/updateUnread', 2);
      },
      readMessages() {
        let msgIds = [];
        Object.values(this.messages).forEach((messages) => {
          messages.forEach((msg) => {
            if (msg.isUnread) {
              msgIds.push(msg.id);
            }
          });
        });
        if (msgIds.length) {
          const dlgId = this.currentDialogDetail.id;
          Api.readMessages(msgIds).then(() => {
            this.updateUnreadCounter(dlgId);
          });
        }
      },
      postMessage(e) {
        console.log(e);
        var ctrl = Object.assign({}, e.ctrlKey);
        if (!ctrl) return;
        const data = {
          toEmployeeId:
            this.currentDialogDetail.id < 0
              ? -this.currentDialogDetail.id
              : this.currentDialogDetail.id
                ? null
                : this.currentDialogDetail.abonentId,
          chatId: this.currentDialogDetail.id > 0 ? this.currentDialogDetail.id : 0,
          text: this.messageText,
          created: new Date(),
          files: this.files,
        };
        Api.postMessage(data)
          .then((res) => {
            this.messageText = null;
            this.searchText = '';
            this.files = [];

            if (this.currentDialogDetail.id < 0 && res.data.messageDto.chatId > 0) {
              //нужно подменить диалог
              const dialog = this.dialogs.find((d) => d.id === this.currentDialogDetail.id);
              dialog.id = res.data.messageDto.chatId;
              this.currentDialogDetail.id = res.data.messageDto.chatId;
            }

            this.loadDialogs('', res.data.messageDto.chatId, true);
            this.loadMessages(this.currentDialogDetail.id);
            console.log(res.data.result, '------------ message sent fcm response');
          })
          .catch(() => {
            Constants.alert.fire(
              'Ошибка',
              'При отправке сообщения произошла ошибка. Попробуйте отправить его позже или обратитесь в техническую поддержу.',
              'error',
            );
          });
      },
      uploadFile(e) {
        const files = e.target.files;
        if (this.files.length + files.length >= Constants.messengerFileLimit) {
          Constants.alert.fire(
            'Ошибка',
            `К сообщению можно прикрепить не более ${Constants.messengerFileLimit} файлов.`,
            'error',
          );
          return;
        }
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (file.size > Constants.messengerFileMaxSize) {
            Constants.alert.fire(
              'Ошибка',
              `К сообщению можно прикрепить файл размером не более ${Constants.messengerFileMaxSize / 1048576} МБ.`,
              'error',
            );
            return;
          }
          FileApi.upload(file).then((response) => {
            this.files.push(response.data);
          });
        }
        this.$refs.fileInput.value = null;
      },
      removeFile(index) {
        this.files.splice(index, 1);
      },
      openChatForEmployee() {
        if (!this.currEmployeeId) {
          return;
        }
        Api.findChatByEmployeeId(this.currEmployeeId)
          .then((response) => {
            const dlg = this.dialogs.find((d) => d.id === response.data.id);
            if (dlg) {
              this.changeDialog(dlg);
            }
            this.currEmployeeId = null;
          })
          .catch(() => {
            const dlg = this.dialogs.find((d) => d.id === -1 * this.currEmployeeId);
            if (dlg) {
              this.changeDialog(dlg);
            }
            this.currEmployeeId = null;
          });
      },
      addChat(isRoom) {
        this.isCreateRoomPopup = isRoom;
        this.isCreateGroupPopup = !isRoom;
        this.isConfigChatPopup = true;
      },
      addMembers() {
        this.isCreateRoomPopup = this.isCreateGroupPopup = false;
        this.isConfigChatPopup = !this.isConfigChatPopup;
      },
      executeChatAction(data) {
        switch (data.action) {
          case 'addMembers':
            this.addMembersToChat(data);
            break;
          default:
            this.createChat(data);
            break;
        }
      },
      createChat(data) {
        const members = this.getSelectedMembers(data);
        if (!members) return;
        let chatDto = {
          title: data.subject,
          chatTypeId: data.action === 'addGroup' ? Constants.chatType.group : Constants.chatType.room,
          entityStatusId: Constants.entityStatus.published,
          members: members,
        };
        Api.createChat(chatDto)
          .then((response) => {
            this.loadDialogs('', response.data.id);
            this.closeModal();
          })
          .catch((response) => {
            console.log(response);
            Constants.alert.fire(
              'Ошибка',
              'При создании чата произошла ошибка. Обратитесь в техническую поддержку.',
              'error',
            );
          });
      },
      addMembersToChat(data) {
        const members = this.getSelectedMembers(data);
        if (!members) return;
        const request = { memberIds: members.map((x) => x.id) };
        Api.addChatMembers(this.currentDialogDetail.id, request)
          .then(() => {
            Constants.alert.fire('Успех', 'Участники добавлены.', 'success');
          })
          .catch((response) => {
            console.log(response);
            Constants.alert.fire(
              'Ошибка',
              'При добавлении участника произошла ошибка. Обратитесь в техническую поддержку.',
              'error',
            );
          });
      },
      getSelectedMembers(data) {
        let members = [];
        Object.entries(data.employerStatuses).forEach((x) => {
          const [id, status] = x;
          if (status.isAdd) {
            members.push({
              id: id,
            });
          }
        });
        if (members.length) {
          return members;
        }
        Constants.alert.fire('Ошибка', 'Необходимо выбрать хотя бы одного участника.', 'error');
        return null;
      },
      closeModal() {
        this.isConfigChatPopup = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default//global/mixin.scss';
  @import '@/assets/theme/default//global/variables.scss';
  .file-wrapper {
    background-color: #fff;
    padding: 8px;
    margin: 8px 0px 8px auto;
    max-width: 30%;
    border-radius: 5px;
  }
  .messenger {
    background: $gray-light;
    //width: 100%;
    //height: 100%;
    &__row {
      display: flex;
      width: 100%;
    }
    &__message-date {
      font-size: 12px;
      color: #aaaaaa;
    }
    &-menu-link {
      font-size: 14px;
      text-align: left;
      width: 100%;
    }
    &__message-header-date {
      margin-left: auto;
    }
    svg:not(&__add-status-icon) {
      fill: #c5c5c9;
    }
    svg:hover:not(&__add-status-icon) {
      fill: #d3a52b;
    }
    &__add-employers {
      display: block;
      margin: 0 auto;
      padding: 10px 14px;
      font-size: 10px;
      text-transform: uppercase;
      cursor: pointer;
      border: none;
      background: white;
      text-align: center;
      span {
        margin-right: 12px;
      }
    }
    &__opponent-last-activitiy {
      text-align: center;
      font-size: 12px;
      opacity: 0.5;
      &.left {
        text-align: left;
      }
    }
    &__my-mess,
    &__not-my-mess {
      background: white;
      padding: 6px 0.5rem 0.375rem 0.625rem;
    }
    &__my-mess {
      border-radius: 8px 12px 0px 12px;
    }
    &__not-my-mess {
      border-radius: 12px 8px 12px 0px;
    }
    &__my-mess {
      text-align: end;
    }
    &__messages {
      padding: 10px 20px;
      padding-bottom: 10px;
    }
    &__message-wrapp {
      width: 100%;
      max-width: 372px;
      word-break: break-word;
      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
      img {
        position: relative;
        top: 22px;
      }
    }
    &__messages-inputs-area {
      margin-top: auto;
      padding: 5px 23px 0 23px;
      border-top: 5px solid linear-gradient(180deg, rgba(231, 231, 231, 0.4) 0%, #e7e7e7 100%);
      .textarea-input {
        position: relative;
        width: 100%;
        max-height: 6em;
      }
      textarea {
        width: 100%;
        //max-width: 800px;
        // min-height: 30px;
        height: auto;
        background-color: $white !important;
        padding-left: 16px;
        padding-right: 44px;
        border-radius: 4px;
        resize: none;
        &::placeholder {
          font-size: 14px;
        }
        &::-webkit-scrollbar {
          width: 0;
        }
      }
      .upload-btn {
        position: absolute;
        top: 16px;
        right: 12px;
        background: transparent;
      }
    }
    &__user-icon,
    &__support-icon {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      //padding-top: 3px;
      text-align: center;
    }
    &__support-icon {
      margin-right: 16px;
    }
    &__dial-popup {
      right: -10px;
      padding: 4px;
      width: 170px;
      top: 64px;
      content: '';
      position: absolute;
      background-color: $white;
    }
    &__dial-popup-1 {
      right: 0;
      padding: 4px;
      width: 170px;
      top: 70%;
      content: '';
      position: absolute;
      z-index: 1;
      // background-color: $white;
    }
    &__date-popup-btn {
      span {
        font-size: 12px;
        color: #212125;
        opacity: 0.5;
      }
      svg {
        stroke: $gray-l-light;
        margin-top: 28px;
      }
      svg:hover {
        cursor: pointer;
        stroke: #212125;
      }
    }
    &__dialog-item {
      position: relative;
      align-items: center;
      max-height: 150px;
      padding: 12px;
      border-bottom: 1px solid $gray-l-light;
    }
    .active {
      background-color: #f3f3f2;
    }
    &__dialog-item:hover {
      cursor: pointer;
    }
    &__dialog-name-mess-wrapp {
      flex-grow: 1;
      // margin: 0 8px 0 16px;
      b {
        font-weight: 500;
      }
      span {
        color: #212125;
        font-size: 14px;
        &.lastMessage {
          max-width: 200px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 11.25rem;
        }
      }
    }
    &__dialog-list {
      box-shadow: -5px 0px 5px -6px rgba(34, 60, 80, 0.38) inset;
    }
    &__dialog-search {
      height: 4rem;
      max-height: 4rem;
      padding: 15px;
      border-bottom: 1px solid $gray-l-light;
      svg {
        cursor: pointer;
      }
      .clear {
        opacity: 0.2;
      }
    }
    &__search {
      width: 80%;
      border: none;
      background-color: $white;
      &::placeholder {
        font-size: 14px;
      }
    }
    &__dialog-search-wrapper {
      min-width: 30rem;
      max-width: calc(1680px / 4);
      padding: 0;
      background-color: $white;
    }
    &__cur-dialog-area {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      // max-width: 1500px;
      padding: 0;
      // background:no-repeat url('../../assets/images/svg/mess-bg.svg') center bottom, linear-gradient(180deg, rgba(231, 231, 231, 0.4) 0%, #E7E7E7 100%);
      background: linear-gradient(180deg, rgba(231, 231, 231, 0.4) 0%, #e7e7e7 100%);
    }
    &__user-settings {
      position: absolute;
      top: 0;
      right: -50px;
      ul {
        @include setPopup();
      }
      button {
        position: relative;
        width: 40px;
        height: 40px;
        background-color: $white !important;
        border-radius: 50%;
        margin-bottom: 10px;
      }
      svg {
        fill: $gray-l-light;
      }
      svg:hover {
        cursor: pointer;
        fill: #d3a52b;
      }
      img {
        width: 48px;
        height: 48px;
        border: none;
        background: none;
      }
    }
    &__actions-popup {
      //@include setPopup(calc(100% + 45px), 5px);
      right: -10px;
      padding: 4px;
      width: 170px;
      content: '';
      position: absolute;
      // background: $white;
    }
    &__cur-dialog-title-area {
      justify-content: center;
      height: 4rem;
      max-height: 4rem;
      background-color: $white;
      padding: 13px 15px;
      border-left: 1px solid $gray-l-light;
      border-bottom: 1px solid $gray-l-light;
      border-radius: 0px 4px 0px 0px;
      // -webkit-box-shadow: 8px 0px 5px -4px rgba(34, 60, 80, 0.1) inset;
      // -moz-box-shadow: 8px 0px 5px -4px rgba(34, 60, 80, 0.1) inset;
      // box-shadow: 8px 0px 5px -4px rgba(34, 60, 80, 0.1) inset;
      // border-bottom: 5px solid #cfcfcf linear-gradient(180deg, rgba(231, 231, 231, 0.4) 0%, #E7E7E7 100%);
      // div {
      //   flex-grow: 1;
      // }
      span:first-of-type {
        font-size: 16px;
        line-height: 20px;
      }
      span {
        text-align: center;
        &:nth-child(2) {
          font-size: 13px;
          color: rgba(33, 33, 37, 0.5);
        }
      }
    }
    &__form {
      // margin-top: 70px;
      display: flex;
      // width: 60%;
      // height: 80%;
      //max-width: "70%";
      max-height: 79vh;
      //width: 100vw;
      //height: 40vw;
      -webkit-box-shadow: 0px 0px 19px 2px rgba(34, 60, 80, 0.13);
      -moz-box-shadow: 0px 0px 19px 2px rgba(34, 60, 80, 0.13);
      box-shadow: 0px 0px 19px 2px rgba(34, 60, 80, 0.13);
      // @include getCenter();
      // * {
      //   font-family: 'Roboto';
      //   font-style: normal;
      //   font-weight: normal;
      // }
      a {
        text-decoration: none;
        color: #212125;
      }
      a.file {
        color: #007bff;
        font-size: 0.8rem;
      }
      li:hover {
        cursor: pointer;
        color: #d3a52b;
      }
      & .row {
        width: 100%;
        //max-height: 652px;
        flex-wrap: nowrap;
        margin: 0;
      }
      input:first-of-type {
        width: 100%;
      }
    }
    .topic-block {
      overflow: hidden;
      max-height: 125px;
    }
    .messenger__dialog-unread-msg-cnt {
      border-radius: 50%;
      background-color: #d3a52b;
      width: 18px;
      height: 18px;
      color: $white;
      font-weight: bold;
      font-size: 9pt;
      text-align: center;
      align-items: center;
      margin-left: 4px;
    }
    .messenger__last-mess {
      display: flex;
      opacity: 0.5;
    }
    .messenger__messages-files-area {
      padding: 5px 55px 5px 23px;
      div {
        display: block;
        width: 100%;
      }
    }
  }
  .scrollable-content {
    overflow-y: auto;
    scrollbar-width: thin;
    &.messenger__dialog-list {
      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }
  .info-about {
    width: 85%;
  }
  .my-mess {
    .messenger__message-date {
      text-align: right;
    }
  }
  .sidebar-close-btn {
    transition: 0.5s;
    &.rotate {
      transform: rotate(181deg);
    }
  }
  @media only screen and (min-width: 1040px) {
    .sidebar-close-btn {
      display: none;
    }
  }
  @media only screen and (max-width: 1039px) {
    .messenger__form {
      position: relative;
      overflow: hidden;
    }
    .messenger.is-left-column-shown #column-center {
      transform: translate3d(30rem, 0, 0);
      transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    }
    #column-center {
      position: absolute;
      top: 0px;
      height: 100%;
      transform: translateZ(0);
      transition: 0.3s;
      z-index: 2;
    }
    .messenger__cur-dialog-area {
      background: linear-gradient(180deg, #c9c6c6 0%, #c9c6c6 100%);
    }
  }
  @media only screen and (max-width: 600px) {
    .messenger.is-left-column-shown #column-center {
      transform: translate3d(50rem, 0, 0);
    }
    .messenger__dialog-search-wrapper {
      width: 100%;
      min-width: initial;
      max-width: initial;
    }
    .messenger__dialog-name-mess-wrapp span.lastMessage {
      max-width: 180px;
    }
  }
  @media only screen and (max-width: 500px) {
    .messenger__dialog-name-mess-wrapp span.lastMessage {
      max-width: 180px;
    }
    .messenger__last-mess {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      span.lastMessage {
        max-width: initial;
        overflow: initial;
        white-space: initial;
        text-overflow: initial;
        width: auto;
      }
    }
    .info-about {
      width: 80%;
    }
    .messenger__dialog-name-mess-wrapp {
      .topic-block {
        flex-wrap: wrap;
        margin-bottom: 0px;
      }
      .messenger__user-name {
        width: 80%;
      }
    }
  }
  @media only screen and (max-width: 390px) {
    .info-about {
      width: 75%;
    }
    .messenger__dialog-item {
      max-height: 140px;
    }
    .messenger__cur-dialog-title-area {
      height: auto;
      max-height: initial;
    }
  }
</style>
