<template>
  <div class="country-block-container">
    <div class="country-block-title" id="membership">
      Членство страны в региональных блоках, международных организациях/объединениях
    </div>
    <ul class="country-block-list d-flex flex-wrap" v-if="membership.length">
      <li
        class="country-block-list-item d-flex align-items-center"
        v-for="item in membership"
        :key="item.key"
        @click="onMembershipClick(item)"
      >
        <div class="country-block-list-item-image" :style="{ 'background-image': `url(${item.picture})` }"></div>
        <div class="country-block-list-item-text">{{ item.title }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
  import API_Country from '@/modules/nsi/api/country';

  export default {
    name: 'CountryBlockControl',
    components: {},
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        membership: [],
      };
    },
    created() {
      this.loadCountryMembership();
    },
    methods: {
      loadCountryMembership() {
        this.membership = [];
        if (this.countryId)
          API_Country.getMembership(this.countryId).then((response) => {
            this.membership = response.data;
          });
      },
      onMembershipClick(item) {
        window.open(item.url, '_blank');
      },
    },
    watch: {
      countryId: function () {
        this.loadCountryMembership();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .country-block {
    &-container {
      margin-top: 40px;
    }
    &-title {
      font-weight: 500;
      font-size: 22px;
      line-height: 24px;
      color: #282b30;
    }
    &-list {
      margin-top: 40px;
      &-item {
        cursor: pointer;
        padding: 4px;
        &:not(:last-child) {
          margin-right: 60px;
        }
        &:hover {
          box-shadow: 0 0 17px rgba(55, 55, 55, 0.1);
        }
        &-image {
          width: 75px;
          height: 75px;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
        }
        &-text {
          font-weight: 500;
          font-size: 22px;
          line-height: 24px;
          color: #282b30;
        }
      }
    }
  }
</style>
