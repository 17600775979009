<template>
  <div class="internal-markets">
    <h3 class="mb-5">Препятствия на внутреннем рынке ЕАЭС</h3>
    <InternalMarketMap />
    <h3 class="mb-3 mt-5">Таблица препятствий на внутреннем рынке ЕАЭС</h3>
    <div class="total mb-5">
      <span><strong>Всего препятсивий - 59</strong></span>
      <span>Барьеры - 59</span>
      <span>Ограничения - 59</span>
    </div>
    <div class="table-container js-table-arrow">
      <table class="stockTable">
        <thead>
          <th>Акт</th>
          <th>Барьеры, ед</th>
          <th>Изъятия, ед</th>
          <th>Ограничения, ед</th>
        </thead>
        <tbody>
          <tr>
            <td>Армения</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
          </tr>
          <tr>
            <td>Беларусь</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
          </tr>
          <tr>
            <td>Казахстан</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
          </tr>
          <tr>
            <td>Киргизия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import InternalMarketMap from '@/modules/countryАnalytics/components/integrationIndicators/InternalMarketMap';
  export default {
    name: 'InternalMarkets',
    components: { InternalMarketMap },
  };
</script>

<style scoped lang="scss">
  .total {
    max-width: 390px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #333333;
    }
  }
  h3 {
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
  }
  .table-custom {
    border-collapse: separate;
    border-spacing: var(--border-width);
    th {
      padding: 13px 11px;
      background-color: #ffffff;
    }
    th,
    td {
      outline: var(--border);
      padding: 8px;
      text-align: center;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #000000;
    }
    th:first-child,
    td:first-child {
      padding: 8px 14px 8px 8px;
      text-align: left;
    }
    tr:nth-child(odd) {
      background-color: #e5f5ff;
    }
    th {
      border-bottom: 1px solid #01a39d !important;
    }
  }
  .table-custom-two {
    thead {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 111;
    }
  }
</style>
