<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Роли</p>
      <div class="mb-16">
        <ButtonStock class="mr-2" title="Создать" @click="editCard(0, false)" v-if="canAdd" />
        <ButtonStock title="Создать группу ролей" @click="editCard(0, true)" v-if="canAdd" />
      </div>
      <div class="mb-4">
        <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
      </div>
      <div class="roles__table table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th>Название</th>
              <th>Статус</th>
              <th v-if="canEdit"></th>
            </tr>
            <tr>
              <th class="filter">
                <FilterInput v-model="filter.title" placeholder="Найти..." v-on:valueChangedDebounced="changeFilter" />
              </th>
              <th class="filter">
                <FilterActiveInput
                  v-model="filter.isActive"
                  placeholder="(Все)"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th v-if="canEdit"></th>
            </tr>
          </thead>
          <tbody v-if="page.items?.length && !isLoading">
            <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item.id, item.isGroupRole)">
              <td>
                <span v-if="item.isGroupRole">Группа - </span>
                <span v-if="item.parentId && item.parentId > 0">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {{ item.title }}
              </td>
              <td>
                {{ item.isActive ? 'действ.' : 'не действ.' }}
              </td>
              <td v-if="canEdit">
                <IconComponent @click="editCard(item.id, item.isGroupRole)" name="mode" />
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="isLoading">
            <tr>
              <td class="text-center" colspan="5">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="5">Нет данных.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="page.pagesTotal > 1">
        <Pagination
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import FilterActiveInput from '../../../common/components/FilterActiveInput';
  import FilterInput from '../../../common/components/FilterInput';
  import API from '../api/roles';

  export default {
    name: 'Roles',
    components: {
      FilterActiveInput,
      FilterInput,
      IconComponent,
      ButtonStock,
      DefaultFilter,
    },
    mixins: [security, filtermanager],
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          name: null,
          isActive: null,
        },
        page: {},

        canAdd: false,
        canEdit: false,
        canDelete: false,
        isLoading: false,
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.RoleView).then((res) => {
        if (res.data) {
          this.loadFilter().then((response) => {
            let filter = response;
            if (filter) {
              this.filter = filter;
            }
            this.loadPage();
          });
        } else {
          this.notAccess();
        }
      });
      this.checkPermission(Constants.Permissions.RoleCreate).then((response) => {
        this.canAdd = response.data;
      });
      this.checkPermission(Constants.Permissions.RoleEdit).then((response) => {
        this.canEdit = response.data;
      });
      this.checkPermission(Constants.Permissions.RoleDelete).then((response) => {
        this.canDelete = response.data;
      });
    },
    methods: {
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(append) {
        this.isLoading = !append;
        const request = Object.assign(this.filter, this.request);
        API.search(request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              //добавляем к существующему
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      editCard(id, isGroup) {
        this.$router.push({ name: isGroup ? 'RoleGroupCard' : 'RoleCard', params: { id: id, action: 'edit' } });
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    color: #01a39d;
  }
  .roles__table {
    table {
      thead {
        th {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #3b4d3e;
          padding: 16px;
          border-bottom: 1px solid #787f8f;
          &:not(:last-child) {
            border-right: 1px solid #787f8f !important;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid #787f8f;
        }
        td {
          padding: 16px;
          font-size: 14px;
          line-height: 150%;
          color: #3b4d3e;
          &:not(:last-child) {
            border-right: 1px solid #787f8f;
          }
        }
      }
    }
  }
</style>
