<template>
  <li class="indicators__item" :class="{ active: active }">
    <h3 class="indicators__title mb-16">{{ title }}</h3>
    <div class="indicators__wrapper">
      <div class="indicators__inner">
        <i v-if="percent < '0'" class="fas fa-solid mr-1 text-red fa-arrow-down"></i>
        <i v-if="percent > '0'" class="fas fa-solid mr-1 text-green fa-arrow-up"></i>
        <strong>{{ count }}</strong>
        <span>млрд $</span>
      </div>
      <div class="indicators__percent" :style="{ backgroundColor: percent > '0' ? '#54DE00' : '#FF5A5A' }">
        {{ percent }}%
      </div>
    </div>
    <button class="indicators__control btn-default">
      <IconComponent name="chartIcon" />
      Больше информации
    </button>
  </li>
</template>

<script>
  import IconComponent from '../../../common/ui/IconComponent';
  export default {
    name: 'indicatorItem',
    components: {
      IconComponent,
    },
    props: {
      active: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
      count: {
        type: [String, Number],
        default: null,
      },
      percent: {
        type: [String, Number],
        default: null,
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../assets/theme/default/colors';
  @import '../../../assets/theme/default/global/mixin';
  .indicators {
    &__item {
      cursor: pointer;
      max-width: 150px;
      width: 100%;
      &:hover,
      &.active {
        .indicators__wrapper {
          transition: 0.2s ease-in-out;
          background: #ffffff;
          box-shadow: 0px 0px 17px rgba(1, 120, 200, 0.5);
          border-radius: 5px;
        }
      }
    }
    &__control {
      max-width: 100px;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 8px;
      line-height: 113.9%;
      text-align: center;
      color: #787f8f;
      display: flex;
      align-items: center;
      margin: -8px auto auto auto;
      padding: 8px 16px;
      border: none;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      .icon {
        margin-right: 8px;
      }
    }
    &__title {
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 113.9%;
      text-align: center;
      color: #333333;
    }
    &__percent {
      max-width: 68px;
      width: 100%;
      text-align: center;
      padding: 4px;
      margin: 0 auto;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #333333;
      background-color: $red;
      box-shadow: 0px 7px 17px rgba(255, 90, 90, 0.11);
      border-radius: 5px;
    }
    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__wrapper {
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      strong {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        color: #000000;
        white-space: nowrap;
        line-height: 46px;
      }
      span {
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 11px;
        color: #333333;
        margin-left: 6px;
      }
    }
  }
</style>
