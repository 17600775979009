import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

import runtimeConfig from '@/common/runtime-config';

const connection = new HubConnectionBuilder()
  .withUrl(runtimeConfig.apiBaseUrl + '/message-hub', {
    accessTokenFactory: () => localStorage.getItem('token'),
  })
  .configureLogging(LogLevel.Information)
  .build();

if ('Notification' in window) {
  if (Notification && Notification.permission !== 'granted') {
    console.log('Request notification permissions');
    Notification.requestPermission();
  }
} else {
  // API not supported
}

export default connection;
