import { authAxios } from '@/common/axios';
import { checkPermission } from '@/common/utils/security';

import Constants from '../constants';

export default {
  methods: {
    checkPermission(permId) {
      return checkPermission(permId);
    },
    checkPermissions(permIds) {
      if (this.$route.params.action === 'edit') {
        this.checkPermission(permIds[0]).then((response) => {
          if (!response.data) {
            Constants.alert.fire('Нет доступа', 'У Вас нет доступа к редактированию объекта этого класса.', 'error');
            this.isReadonly = true;
          }
        });
      } else if (this.$route.params.action === 'view') {
        this.checkPermission(permIds[1]).then((response) => {
          if (!response.data) {
            this.notAccess();
          }
        });
      }
    },
    checkPermissionAndRedirect(permId) {
      this.checkPermission(permId).then((response) => {
        if (!response.data) {
          this.notAccess();
        }
      });
    },
    checkAnyPermission(permIds) {
      return authAxios.get(`/api/permission/any?permissionId=${permIds.join(',')}`);
    },
    getAccessMatrix() {
      return authAxios.get(`/api/permission/accessmatrix`);
    },
    getAccessList(permIds) {
      return authAxios.get(`/api/permission/accesslist`, { params: { permIds: permIds } });
    },
    notAccess(message) {
      const mess = message ? message : 'У Вас нет доступа к просмотру объекта этого класса.';
      Constants.alert.fire('Нет доступа', mess, 'error');
      this.$router.push('/');
    },
  },
};
