<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка пользователя</p>
    </div>
    <div>
      <div class="row d-flex flex-column flex-md-row">
        <div class="col col-lg-4 pb-0">
          <PictureUpload v-on:pictureSelected="pictureSelected" :initialPhoto="item.picture" />
        </div>
        <div class="col col-lg-8">
          <div class="form-group required">
            <ComponentInput
              v-model="item.surname"
              label="Фамилия"
              required
              :maxLength="250"
              placeholder="Введите фамилию"
            />
          </div>
          <div class="form-group required">
            <ComponentInput v-model="item.name" label="Имя" required :maxLength="250" placeholder="Введите имя" />
          </div>
          <div class="form-group">
            <ComponentInput
              v-model="item.patronymic"
              label="Отчество"
              :maxLength="250"
              placeholder="Введите отчество"
            />
          </div>
        </div>
      </div>
      <div class="row d-flex flex-column flex-md-row" v-if="item.user?.userTypeId != 2">
        <div class="form-group col col-lg-12 required country-container">
          <template v-if="!isRoleSubjectRF">
            <ComponentInput
              label="Страна"
              :select2Options="countries"
              v-model="item.countryIds"
              :select2Settings="select2SettingsMultiple"
              type="select2"
              required
              :multiple="true"
            />
          </template>
          <template v-else>
            <ComponentInput
              label="Субъект РФ"
              :select2Options="getRegionsListWithId"
              v-model="item.regionRfIds"
              :select2Settings="select2Settings"
              type="select2"
              required
            />
          </template>
          <template v-if="!isRoleSubjectRF">
            <div class="form-group__link-container d-flex flex-column flex-md-row">
              <a href="javascript:" @click="selectAllCountries" class="mr-4 d-block">Выбрать все страны</a>
              <a href="javascript:" @click="removeAllCountries" class="d-block">Убрать всё</a>
            </div>
          </template>
        </div>
      </div>
      <div class="row d-flex flex-column flex-md-row" v-if="item.user?.userTypeId != 2">
        <div class="form-group col col-lg-6 required">
          <ComponentInput
            label="Роль"
            :select2Options="roles"
            v-model="item.roleId"
            type="select2"
            required
            :select2Settings="select2Settings"
          />
        </div>
        <div class="form-group col col-lg-6 required">
          <ComponentInput
            label="Организация"
            :select2Options="organizations"
            v-model="item.organizationId"
            v-on:select2OnSelect="loadDepartments"
            :select2Settings="select2Settings"
            type="select2"
            required
          />
        </div>
      </div>
      <div class="row d-flex flex-column flex-md-row">
        <div class="form-group col col-lg-6" v-if="item.organizationId == 1">
          <ComponentInput
            label="Подразделение"
            :select2Options="departments"
            v-model="item.departmentId"
            v-on:select2OnSelect="loadDepartments"
            :select2Settings="select2Settings"
            type="select2"
          />
        </div>
        <div class="form-group col col-lg-6">
          <ComponentInput v-model="item.position" label="Должность" :maxLength="250" placeholder="Введите должность" />
        </div>
      </div>
      <div class="row d-flex flex-column flex-md-row">
        <div class="form-group col col-lg-6 required">
          <ComponentInput
            v-model="item.email"
            label="Адрес электронной почты"
            required
            :maxLength="250"
            placeholder="Введите email"
          />
        </div>
        <div class="form-group col col-lg-6 required" v-if="item.user">
          <ComponentInput
            label="Тип пользователя"
            :select2Options="userTypes"
            v-model="item.user.userTypeId"
            type="select2"
            required
            :select2Settings="select2Settings"
          />
        </div>
      </div>
      <div class="row d-flex flex-column flex-md-row">
        <div class="form-group col col-lg-6">
          <label class="form-label">Последний логин</label>
          <div class="form-control">{{ $momentFormat(item.user?.lastLoginDt, 'DD.MM.YYYY HH:mm') || '(нет)' }}</div>
        </div>
        <div class="form-group col col-lg-6">
          <label class="form-label">Последняя активность</label>
          <div class="form-control">{{ $momentFormat(item.user?.lastActivityDt, 'DD.MM.YYYY HH:mm') || '(нет)' }}</div>
        </div>
      </div>
      <div class="row mt-10">
        <div class="checkboxInput" v-if="item.user?.isDisabled && !item.user?.isDeleted">
          <input type="checkbox" checked id="chActive" v-model="item.user.isDisabled" />
          <label for="chActive"
            >Заблокирован
            <small>(учетная запись была деактивирована в связи с бездействием в течении 60 дней)</small></label
          >
        </div>
        <div class="checkboxInput" v-else-if="!item.user?.isDeleted">
          <input type="checkbox" v-model="item.isActive" id="chActive" />
          <label for="chActive">Запись активна</label>
        </div>
        <div
          class="checkboxInput"
          v-if="
            item.user && item.user?.isActive && !initialIsApprove && !item.user?.isDisabled && !item.user?.isDeleted
          "
        >
          <input type="checkbox" v-model="item.user.isApproved" id="chApprove" />
          <label for="chApprove">Подтвердить запись</label>
        </div>
      </div>
    </div>

    <div class="checkboxInput mb-2" v-if="item.user && item.user?.isDeleted">
      <ButtonStock class="mr-8" title="Восстановить" v-on:click="restoreCard" />
    </div>

    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import PictureUpload from '@/common/components/PictureUpload';
  import Constants from '@/common/constants';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API_DEP from '../api/department';
  import API_ORG from '../api/organization';
  import API_PERM from '../api/rolesPermissions';
  import API from '../api/users';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    name: 'UserCard',
    components: {
      ComponentInput,
      ButtonStock,
      PictureUpload,
    },
    data() {
      return {
        id: this.$route.params.id,
        item: {},
        organizations: [],
        departments: [],
        countries: [],
        roles: [],
        userTypes: [],
        select2Settings: Constants.select2Settings,
        select2SettingsMultiple: Constants.select2SettingsMultiple,
        initialIsApprove: false,
        initialIsDisabled: false,
      };
    },
    created() {
      this.loadCard();
      //this.loadOrganizations();
      this.loadCountries();
      this.fetchRegions();
      this.loadRoles();
      this.loadUserTypes();
    },
    methods: {
      ...mapActions({
        fetchRegions: DIRECTIVES_ACTIONS_NAME.getRegions,
      }),
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.item = response.data;
            this.item.roleId =
              this.item.user.roleIds && this.item.user.roleIds.length > 0 ? this.item.user.roleIds[0] : null;
            this.initialIsApprove = this.item.user?.isApproved;
            this.initialIsDisabled = this.item.user?.isDisabled;

            if (this.item.organizationId == 1) this.loadDepartments({ value: this.item.organizationId });
          });
        } else {
          this.item = {
            isActive: true,
            organizationId: 1,
            regionRfIds: [],
            user: {
              userTypeId: Constants.userType.internal,
            },
          };
        }
      },
      loadOrganizations() {
        const orgTypeId =
          this.item.user.userTypeId == Constants.userType.internal
            ? Constants.organizationType.foiv
            : Constants.organizationType.external;
        const request = { pageNumber: 1, pageSize: 0, typeIds: [orgTypeId] };
        API_ORG.search(request).then((response) => {
          if (response.data && response.data.items) {
            this.organizations = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
      },
      loadDepartments(data) {
        const request = { pageNumber: 1, pageSize: 0, filter: { OrganizationIds: [data.value.id] } };
        API_DEP.search(request).then((response) => {
          if (response.data && response.data.items) {
            this.departments = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
      },
      loadRoles() {
        API_PERM.loadRoles({ pageNumber: 1, pageSize: 0, isActive: true }).then((response) => {
          if (response.data && response.data.items) {
            this.roles = response.data.items
              .filter((x) => !x.isGroupRole)
              .map((o) => {
                return { text: o.title, id: o.id };
              });
          }
        });
      },
      loadCountries() {
        const request = { pageNumber: 1, pageSize: 0 };
        Utils.searchNsi('countryList', request).then((response) => {
          if (response.data) {
            this.countries = response.data;
          }
        });
      },
      loadUserTypes() {
        API.getUserTypes().then((response) => {
          if (response.data) {
            this.userTypes = response.data.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
      },
      pictureSelected(data) {
        this.item.picture = data;
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        const updateData = JSON.parse(JSON.stringify(this.item));
        if (updateData.roleId) updateData.user.roleIds = [updateData.roleId];

        if (typeof updateData.regionRfIds === 'string') {
          updateData.regionRfIds = [updateData.regionRfIds];
        }

        API.update(updateData)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (this.initialIsDisabled && this.initialIsDisabled != this.item.user.isDisabled) {
              API.unlock(this.item.user.id);
            }
            if (response && response.data.id) {
              this.$router.push({ name: 'UserCard', params: { id: response.data.id, action: 'edit' } });
            }
          })
          .catch((exception) => {
            console.error(exception.response);
            let errors = exception.response.data;
            if (errors.includes('DuplicateUserName'))
              Constants.alert.fire('Ошибка', 'Пользователь с таким e-mail уже зарегистрирован в системе', 'error');
            else if (errors.includes('InvalidEmail'))
              Constants.alert.fire('Ошибка', 'Некорректный e-mail. Проверьте правильность заполнения поля', 'error');
            else if (errors.startsWith('Указанный')) Constants.alert.fire('Ошибка', errors, 'error');
            else Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Users' });
      },
      selectAllCountries() {
        this.item.countryIds = this.countries.map((c) => {
          return c.id;
        });
      },
      removeAllCountries() {
        this.item.countryIds = [];
      },
      restoreCard() {
        API.undelete(this.item.id)
          .then(() => {
            Constants.alert.fire('Восстановление', 'Запись восстановлена.', 'success');
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Восстановление', 'Произошла ошибка', 'error');
          });
      },
    },
    computed: {
      ...mapGetters({
        getRegionsListWithId: DIRECTIVES_GETTERS_NAME.getRegionsListWithId,
      }),
      isRoleSubjectRF() {
        const isRoleSubjectRF = Number(this.item.roleId) === Constants.userRoleIds.subjectRF;
        return isRoleSubjectRF;
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
    watch: {
      'item.user.userTypeId': {
        handler: function () {
          this.loadOrganizations();
        },
        deep: true,
      },
      'item.roleId'(newValue, oldValue) {
        const isRoleSubjectRF = Number(newValue) === Constants.userRoleIds.subjectRF;
        if (isRoleSubjectRF) {
          this.item.countryIds = [String(Constants.countryRussiaId)];
        }
        if (Number(oldValue) === Constants.userRoleIds.subjectRF) {
          this.item.countryIds = null;
          this.item.regionRfIds = [];
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-label {
    font-weight: 400 !important;
    font-size: 14px;
    color: #231f20;
    line-height: 160%;
    margin-bottom: 0;
    margin-top: 4px;
  }
  .country-container::v-deep(.select2-container) {
    max-height: 200px;
    overflow-y: auto;
  }
</style>
