export default {
  getIsLoading(state) {
    return state.isLoading;
  },
  getIsLoadingMore(state) {
    return state.isLoadingMore;
  },
  getScenarios(state) {
    return state.tableData;
  },
  getScenariosTotalItem(state) {
    return state.tableData.itemsTotal;
  },
};
