<template>
  <ul class="d-flex mb-4 country-tag-list" v-if="countries">
    <li class="tag country-tag" v-for="item in countries" :key="item.id" @click="onTagClick(item.id)">
      {{ item.name }}
    </li>
  </ul>
</template>

<script>
  import Api from '@/modules/countryAnalyticsNew/api/api';

  export default {
    name: 'CountryTagControl',
    emits: ['tagClicked'],
    components: {},
    data() {
      return {
        countries: [],
      };
    },
    created() {
      Api.countriesForAnalytics().then((response) => {
        this.countries = response.data;
      });
    },
    methods: {
      onTagClick(countryId) {
        this.$emit('tagClicked', countryId);
      },
    },
    watch: {},
  };
</script>

<style lang="scss" scoped>
  .tag {
    box-sizing: border-box;
    gap: 4px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
  }

  .country-tag-list {
    margin-top: 25px;
  }

  .country-tag {
    padding: 8px 12px;
    background: #fbf9f9;
    border: 1px solid #d9d9d9;
    border-radius: 14px;
    font-size: 14px;
    line-height: 18px;
    color: #787f8f;
  }

  .country-tag:not(:last-child) {
    margin-right: 24px;
  }
</style>
