import { authAxios } from '@/common/axios';

import api from '@/modules/auth/api';

import userManager from '../user-manager';

export default {
  namespaced: true,
  state: {
    user: null,
    isExpired: false,
    profile: null,
  },

  mutations: {
    setUser(state, user) {
      state.user = user;
      if (user) {
        localStorage.setItem('token', user.access_token);
        authAxios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access_token;
        api.getProfileData().then((res) => {
          state.profile = res.data;
          localStorage.setItem('userProfileData', JSON.stringify(res.data));
        });
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('userProfileData');
        state.user = null;
        state.profile = null;
      }
    },
    setIsExpired(state, val) {
      state.isExpired = !!val;
    },
    setShowFilters(state, val) {
      if (state.profile) state.profile.showFilters = val;
    },
    logout(state) {
      localStorage.removeItem('userProfileData');
      state.user = null;
      state.profile = null;
      userManager.signoutRedirect({
        extraQueryParams: {
          //? Устранение уязвимости CWE-601
          //? вместо ссылки window.location.origin передается id. По id на бэке определяется ссылка, которая будет использоваться
          base_url: process.env.NODE_ENV === 'production' ? 'REDIRECT_ID_PROD' : 'REDIRECT_ID_DEV',
        },
      });
    },
  },
  actions: {
    loadUser(context) {
      return userManager.getUser().then(function (user) {
        if (user) {
          context.commit('setUser', user);
        } else {
          context.commit('setUser', null);
        }
      });
    },
    logout({ commit }) {
      localStorage.removeItem('token');
      commit('logout');
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return state.user != null; //&& !state.isExpired
    },
    getUserProfile: (state) => {
      return state.profile;
    },
  },
  modules: {},
};
