<template>
  <div>
    <div v-if="country">
      <div class="d-flex align-items-end">
        <span class="exchangeRate-text-title" id="currency-course">Валютный курс</span>
        <div class="analytics-source-text">Источник: Международный валютный фонд</div>
      </div>

      <div class="exchangeRate d-flex align-items-center justify-content-start" v-if="country.usdExchangeRate">
        <span class="exchangeRate-text">1 USD</span>
        <IconComponent class="exchangeRate-text" name="2arrow" />
        <span class="exchangeRate-text">{{ country.usdExchangeRate + ' ' + (country.currency ?? '') }}</span>
        <span v-if="country.usdExchangeRateDate" class="analytics-source-text">
          на {{ $momentFormat(country.usdExchangeRateDate, 'DD.MM.YYYY') }}
        </span>
      </div>
      <div class="exchangeRate d-flex align-items-center justify-content-start" v-else>
        <span class="exchangeRate-text">-</span>
      </div>
    </div>
    <div class="figures-key" id="monetary-policy">Денежно-кредитная политика</div>
    <ul class="indicators__list figures-key__list d-flex overflow-auto">
      <li class="figures-key__item indicators__item justify-content-between">
        <div class="d-flex flex-column">
          <div class="indicators__item-title">Инфляция</div>
          <div class="analytics-source-text">Источник: Trading Economics</div>
          <div class="analytics-source-text">
            По данным за
            <template v-if="currentInflation">{{ $momentFormat(currentInflation.period, 'MM.YYYY') }}</template>
            <template v-else>&mdash;</template>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div class="figures-key__card d-flex justify-content-between align-items-center">
            <template v-if="currentInflation && currentInflation.previous">
              <i
                v-if="currentInflation.previous >= currentInflation.rawValue"
                class="inflation-sign fas fa-solid mr-1 text-red fa-arrow-circle-down"
              ></i>
              <i
                v-if="currentInflation.previous < currentInflation.rawValue"
                class="inflation-sign fas fa-solid mr-1 text-green fa-arrow-circle-up"
              ></i>
            </template>
            <template v-else>
              <i class="fa"></i>
            </template>
            <strong
              v-if="currentInflation"
              :class="[
                { 'text-red': currentInflation.previous >= currentInflation.rawValue },
                { 'text-green': currentInflation.previous < currentInflation.rawValue },
              ]"
            >
              {{ currentInflation.value }}
            </strong>
            <strong v-else>&mdash;</strong>
            <p
              class="percent-sign"
              :class="[
                { 'text-red': currentInflation && currentInflation.previous >= currentInflation.rawValue },
                { 'text-green': currentInflation && currentInflation.previous < currentInflation.rawValue },
              ]"
            >
              %
            </p>
          </div>
          <div
            class="indicator-more-info"
            v-if="currentInflationMoreInfo"
            @click="currentInflationMoreInfo ? selectIndicators(1, 'Инфляция, %') : null"
          >
            {{ activeTab == 1 ? 'Скрыть информацию' : 'Больше информации' }}
          </div>
        </div>
      </li>
      <li class="figures-key__item indicators__item justify-content-btween">
        <div class="d-flex flex-column">
          <div class="indicators__item-title">Учетная ставка ЦБ</div>
          <div class="analytics-source-text">Источник: Международный валютный фонд</div>
          <div class="analytics-source-text">
            По данным за
            <template v-if="currentBankRate">{{ currentBankRate.year }}</template>
            <template v-else>&mdash;</template>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div class="figures-key__card d-flex justify-content-between align-items-center">
            <template v-if="currentBankRate && currentBankRate.previous">
              <i
                v-if="currentBankRate.previous < currentBankRate.rawValue"
                class="inflation-sign fas fa-solid mr-1 text-red fa-arrow-down"
              ></i>
              <i
                v-if="currentBankRate.previous > currentBankRate.rawValue"
                class="inflation-sign fas fa-solid mr-1 text-green fa-arrow-up"
              ></i>
            </template>
            <template v-else>
              <i class="fa"></i>
            </template>
            <strong
              v-if="currentBankRate"
              :class="[
                { 'text-red': currentBankRate.previous && currentBankRate.previous < currentBankRate.rawValue },
                { 'text-green': currentBankRate.previous && currentBankRate.previous > currentBankRate.rawValue },
              ]"
            >
              {{ currentBankRate.value }}</strong
            >
            <strong v-else>&mdash;</strong>
            <p
              class="percent-sign"
              :class="[
                {
                  'text-red':
                    currentBankRate && currentBankRate.previous && currentBankRate.previous < currentBankRate.rawValue,
                },
                {
                  'text-green':
                    currentBankRate && currentBankRate.previous && currentBankRate.previous > currentBankRate.rawValue,
                },
              ]"
            >
              %
            </p>
          </div>
          <div
            class="indicator-more-info"
            v-if="currentInflationMoreInfo"
            @click="currentInflationMoreInfo ? selectIndicators(2, 'Учетная ставка ЦБ, %') : null"
          >
            {{ activeTab == 2 ? 'Скрыть информацию' : 'Больше информации' }}
          </div>
        </div>
      </li>
    </ul>
    <div class="graph">
      <div class="graph__title">{{ titleTab }}</div>
      <template v-if="activeTab === 1">
        <template v-if="inflation?.length > 0">
          <ColumnChart
            :key="chartUpdater"
            format-label=" %"
            :chart-data="inflation.map((x) => x.value.toFixed(2))"
            :labels="inflation.map((x) => $momentFormat(x.period, 'MM.YYYY'))"
            :colors="inflation.map((x) => x.color)"
          />
        </template>
        <template v-else>
          <div class="text-center">Нет данных.</div>
        </template>
      </template>

      <template v-if="activeTab === 2">
        <template v-if="bankRates?.length > 0">
          <ColumnChart
            :key="chartUpdater"
            :colors="['#01A39D']"
            format-label=" %"
            :chart-data="bankRates.map((x) => x.value)"
            :labels="bankRates.map((x) => x.year)"
            :layoutPaddingTop="1 * 25"
          />
        </template>
        <template v-else>
          <div class="text-center">Нет данных.</div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent';

  import ColumnChart from '@/components/columnChart';

  import IndicatorApi from '@/modules/admin/api/indicatorVisibility';
  import Api from '@/modules/countryAnalyticsNew/api/api';
  import CountryApi from '@/modules/nsi/api/country';

  export default {
    name: 'MonetaryPolicy',
    components: { ColumnChart, IconComponent },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        activeTab: null,
        titleTab: '',
        inflation: [],
        chartUpdater: 1,
        country: null,
        currentInflationMoreInfo: true,
        rateMoreInfo: true,
        bankRates: [],
        currentBankRate: null,
      };
    },
    created() {
      if (this.countryId) {
        this.loadIndicatorVisibility();
        this.loadData();
      }
    },
    methods: {
      loadIndicatorVisibility() {
        IndicatorApi.search().then((response) => {
          this.currentInflationMoreInfo = response.data[8].moreInfoVisible;
          this.rateMoreInfo = response.data[9].moreInfoVisible;
        });
      },
      loadData() {
        const request = {
          countryId: this.countryId,
        };
        Api.searchInflation(request).then((response) => {
          console.log(response.data);
          this.inflation = response.data.items.map((x) => ({
            period: x.period,
            value: x.value,
            color: '#96d2e7',
          }));
          if (this.inflation.length) this.inflation[0].color = '#0093BA';
          this.chartUpdater++;
        });
        Api.searchBankRates(this.countryId).then((response) => {
          this.bankRates = response.data.map((x) => ({
            year: x.text,
            value: x.id,
          }));
          this.chartUpdater++;

          this.currentBankRate = null;
          if (this.bankRates.length) {
            let v = this.bankRates[this.bankRates.length - 1];
            this.currentBankRate = {
              year: v.year,
              rawValue: v.value,
              value: v.value.toFixed(2).replace('.', ','),
            };

            //ищем предыдущий год
            if (this.bankRates.length > 1) {
              let prev = this.bankRates.slice(-2);
              if (prev != null) {
                this.currentBankRate.previous = prev.value;
              }
            }
          }
        });
        CountryApi.find(this.countryId)
          .then((response) => {
            this.country = response.data;
          })
          .catch(() => {
            this.country = null;
          });
      },
      selectIndicators(id, text) {
        if (this.activeTab == id) {
          this.activeTab = null;
          this.titleTab = '';
        } else {
          this.activeTab = id;
          this.titleTab = text;
        }
      },
    },
    watch: {
      countryId: function () {
        this.loadData();
      },
    },
    computed: {
      currentInflation() {
        if (!this.inflation.length) return 0;

        let v = this.inflation[this.inflation.length - 1];
        let result = {
          period: v.period,
          rawValue: v.value,
          value: v.value.toFixed(2).replace('.', ','),
          previous: null,
        };

        //ищем предыдущий
        if (this.inflation.length >= 2) {
          let prev = this.inflation[this.inflation.length - 2];
          if (prev != null) {
            result.previous = prev.value;
          }
        }
        return result;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/colors';
  @import '@/modules/countryAnalyticsNew/styles/index';

  .exchangeRate-text,
  .exchangeRate-text-title,
  .figures-key,
  .graph__title {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: #282b30;
  }

  .exchangeRate {
    margin-top: 20px;
    gap: 0 15px;
  }

  .exchangeRate-text-title {
    margin-top: 60px;
    margin-right: 60px;
  }

  .figures-key__list {
    margin-top: 24px;
    gap: 0 50px;
  }

  .figures-key {
    margin-top: 45px;
  }

  .figures-key__title-key {
    white-space: nowrap;
    max-width: 100px;
  }

  .inflation-sign {
    margin-top: 10px;
  }

  .indicator-more-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #333333;
    cursor: pointer;
  }

  .graph {
    margin-top: 60px;
  }
</style>
