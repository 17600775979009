import { IMPORT_ANALYTICS_ACTIONS_NAME, IMPORT_ANALYTICS_MUTATIONS_NAME } from './directoryNames';

import links from '@/utils/links';
import request from '@/utils/request';

export async function getUnfriendlyCountriesSearch(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.importAnalytics.getUnfriendlyCountriesSearch,
      mutate: IMPORT_ANALYTICS_MUTATIONS_NAME.setUnfriendlyCountriesSearch,
      name: IMPORT_ANALYTICS_ACTIONS_NAME.getUnfriendlyCountriesSearch,
    },
    params,
  );
}

export async function loadUnfriendlyCountriesReport(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.importAnalytics.loadUnfriendlyCountriesReport,
      method: 'post',
      isFormData: true,
    },
    params,
  );
}
