<template>
  <div class="country-ranking">
    <h2>Бюджетная политика</h2>
    <ul class="indicators__list align-center" v-if="!isLoading">
      <template v-for="item in indicatorsList" :key="item.id">
        <li class="indicators__item" v-if="item.count != '-'">
          <p>{{ item.title }}</p>
          <div v-if="item.source" class="analytics-source-text">Источник: {{ item.source }}</div>
          <div v-if="item.source" class="analytics-source-text">
            <!-- <template v-if="item.id == 5">
              2 кв 2022
            </template>
            <template v-else>
              По данным за 2021 год
            </template> -->
            <template v-if="yearUpdate"> По данным за {{ yearUpdate }} год </template>
          </div>
          <strong>{{ isNaN(item.count) ? item.count : formatNumber(Number(item.count)) }}</strong>
          <!--        <div v-if="item.awards.length" class="award d-flex flex-column">-->
          <!--          <span v-for="(award, idx) in item.awards" :key="idx"-->
          <!--            >{{ award.count }} место - {{ award.country }}</span-->
          <!--          >-->
          <!--        </div>-->
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';

  import Api from '../api';

  export default {
    name: 'Budget',
    mixins: [text],
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        indicatorsList: [
          {
            id: 1,
            title: 'Баланс бюджета, % ВВП',
            source: 'Международный валютный фонд',
            count: 'Загрузка...',
            awards: [],
          },
          {
            id: 2,
            title: 'Государственные доходы, % ВВП',
            source: 'Международный валютный фонд',
            count: 'Загрузка...',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 3,
            title: 'Государственные расходы, % ВВП',
            source: 'Всемирный банк',
            count: 'Загрузка...',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 4,
            title: 'Государственный долг, % ВВП',
            source: 'Международный валютный фонд',
            count: 'Загрузка...',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 5,
            title: 'Общий кредит нефинансовому сектору, % ВВП',
            source: 'Банк международных расчетов',
            count: 'Загрузка...',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 6,
            title: 'Золотовалютные резервы, млрд $ США',
            source: 'Всемирный банк',
            count: 'Загрузка...',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 7,
            title: 'Золотовалютные резервы, объем в золоте, млрд $ США',
            source: 'gold.org',
            count: 'Загрузка...',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
        ],
        yearUpdate: null,
        isLoading: true,
      };
    },
    created() {
      this.loadPage();
    },
    methods: {
      loadPage() {
        this.isLoading = true;
        Api.getBudgetPolicy(this.countryId)
          .then((response) => {
            this.updateIndicators(response.data);
            this.isLoading = false;
          })
          .catch((error) => {
            console.error(error?.response?.data);
            this.isLoading = false;
          });
      },
      updateIndicators(data) {
        this.yearUpdate = data.year;
        const noData = '-';
        let ind = this.indicatorsList.find((x) => x.id === 1);
        ind.count = data.budgetBalance !== null ? data.budgetBalance.toFixed(2) : noData;
        ind = this.indicatorsList.find((x) => x.id === 2);
        ind.count = data.revenue !== null ? data.revenue.toFixed(2) : noData;
        ind = this.indicatorsList.find((x) => x.id === 3);
        ind.count = data.expenses !== null ? data.expenses.toFixed(2) : noData;
        ind = this.indicatorsList.find((x) => x.id === 4);
        ind.count = data.governmentDebt !== null ? data.governmentDebt.toFixed(2) : noData;
        ind = this.indicatorsList.find((x) => x.id === 5);
        ind.count = data.nonFinancialCredit !== null ? data.nonFinancialCredit.toFixed(2) : noData;
        ind = this.indicatorsList.find((x) => x.id === 6);
        ind.count = data.reserves !== null ? (data.reserves / 1000000000).toFixed(2) : noData;
        ind = this.indicatorsList.find((x) => x.id === 7);
        ind.count = data.reservesInGold !== null ? data.reservesInGold.toFixed(2) : noData;
      },
    },
    watch: {
      countryId: function () {
        this.loadPage();
      },
    },
  };
</script>

<style scoped lang="scss">
  .indicators {
    &__list {
      padding: 0 16px;
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 892px) {
        justify-content: space-between;
      }
      gap: 16px 12px;
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 180px;
      width: 100%;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border: 1px solid #06a0d8a9;
      border-radius: 5px;
      @media (max-width: 1042px) {
        max-width: calc((100% / 4) - 18px);
      }
      @media (max-width: 892px) {
        max-width: calc((100% / 3) - 18px);
      }
      @media (max-width: 546px) {
        max-width: calc((100% / 2) - 18px);
      }
      @media (max-width: 546px) {
        max-width: 100%;
      }
      p {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 113.9%;
        text-align: center;
        color: #333333;
      }
      strong {
        margin-top: 20px;
        margin-bottom: 9px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #333333;
      }
      span {
        margin-bottom: 4px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #333333;
      }
    }
  }
  h2 {
    margin-bottom: 20px;
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 113.9%;
    color: #000000;
  }

  .indicators__item .analytics-source-text {
    font-size: 10px;
    text-align: center;
  }
</style>
