import axios from 'axios';

export default {
  searchUser: function (request) {
    return axios.get('/api/newsSubscription/searchUser', { params: request });
  },
  changeActiveUser: function (id, active) {
    if (active) return axios.put(`/api/newsSubscription/approveUser?id=${id}`, {});
    else return axios.put(`/api/newsSubscription/refuseUser?id=${id}`, {});
  },
  sendSubscription: function (request) {
    return axios.get('/api/newsSubscription/sendSubscription', { params: request });
  },
  sendSelectiveSubscription: function (request) {
    return axios.get('/api/newsSubscription/sendSelectiveSubscription', { params: request });
  },
  createUser: function (request) {
    return axios.post('/api/newsSubscription/createUser', request);
  },
};
