<template>
  <NsiTable :pageTitle="pageTitle" :apiRoute="apiRoute" :cardName="cardName" :canEdit="canEdit" show-export="true" />
</template>

<script>
  import NsiTable from '../components/NsiTable';

  export default {
    name: 'Industries',
    components: {
      NsiTable,
    },
    data() {
      return {
        pageTitle: 'Отрасли',
        canEdit: true,
        apiRoute: 'nsiindustry',
        cardName: 'IndustryCard',
      };
    },
    created() {},
    methods: {},
  };
</script>
