<template>
  <div></div>
</template>

<script>
  import Constants from '@/common/constants';

  import userManager from '@/modules/auth/user-manager';
  //import runtimeConfig from '@/common/runtime-config';

  export default {
    name: 'ErrorPage',
    mounted() {
      Constants.alert
        .fire(
          'Сессия завершена',
          'Извините, но Ваша сессия завершена. Пожалуйста, войдите в систему заново.',
          'warning',
        )
        .then(() => {
          userManager
            .getUser()
            .then((user) => {
              if (user)
                userManager.signoutRedirect({
                  extraQueryParams: {
                    base_url: window.location.origin,
                  },
                });
              else this.$router.push('/');
            })
            .catch((e) => {
              console.log(e);
            });
        });
    },
  };
</script>
