export const COUNTRY_ANALYTICS = 'countryAnalytics';

export const COUNTRY_ANALYTICS_ACTIONS_NAME = {
  getLifeIndexSummary: `${COUNTRY_ANALYTICS}/getLifeIndexSummary`,
  getInvestmentInStock: `${COUNTRY_ANALYTICS}/getInvestmentInStock`,
  getInvestmentOutStock: `${COUNTRY_ANALYTICS}/getInvestmentOutStock`,
  getInvestmentInFlows: `${COUNTRY_ANALYTICS}/getInvestmentInFlows`,
  getInvestmentOutFlows: `${COUNTRY_ANALYTICS}/getInvestmentOutFlows`,
  getForeignTradeTurnoverWordBalance: `${COUNTRY_ANALYTICS}/getForeignTradeTurnoverWordBalance`,
  getForeignTradeTurnoverWordGdpValues: `${COUNTRY_ANALYTICS}/getForeignTradeTurnoverWordGdpValues`,
  getUnfriendlyCountryInfo: `${COUNTRY_ANALYTICS}/getUnfriendlyCountryInfo`,
  getTopImportIndustries: `${COUNTRY_ANALYTICS}/getTopImportIndustries`,
  getTopExportIndustries: `${COUNTRY_ANALYTICS}/getTopExportIndustries`,
  getTopImportCountries: `${COUNTRY_ANALYTICS}/getTopImportCountries`,
  getTopExportCountries: `${COUNTRY_ANALYTICS}/getTopExportCountries`,
  getIndicatorBudgetDeficit: `${COUNTRY_ANALYTICS}/getIndicatorBudgetDeficit`,
  getIndicatorStockMarketCapitalization: `${COUNTRY_ANALYTICS}/getIndicatorStockMarketCapitalization`,
  getIndicatorFinancialAccount: `${COUNTRY_ANALYTICS}/getIndicatorFinancialAccount`,
  getIndicatorGovernmentBondYields: `${COUNTRY_ANALYTICS}/getIndicatorGovernmentBondYields`,
  getYearListTurnover: `${COUNTRY_ANALYTICS}/getYearListTurnover`,
  getForeignTrade: `${COUNTRY_ANALYTICS}/getForeignTrade`,
  getMacroEconomicIndicatorValue: `${COUNTRY_ANALYTICS}/getMacroEconomicIndicatorValue`,
  getEconomicReviewSummary: `${COUNTRY_ANALYTICS}/getEconomicReviewSummary`,
  getIndicatorCountryRating: `${COUNTRY_ANALYTICS}/getIndicatorCountryRating`,
  getExportProductLast: `${COUNTRY_ANALYTICS}/getExportProductLast`,
  getFerRegionsTradeValue: `${COUNTRY_ANALYTICS}/getFerRegionsTradeValue`,
  getFerRegionsTradeCountryCount: `${COUNTRY_ANALYTICS}/getFerRegionsTradeCountryCount`,
  getQuartalList: `${COUNTRY_ANALYTICS}/getQuartalList`,
  getQuartalSummary: `${COUNTRY_ANALYTICS}/getQuartalSummary`,
};

export const COUNTRY_ANALYTICS_MUTATIONS_NAME = {
  setLifeIndexSummary: `setLifeIndexSummary`,
  setInvestmentInStock: `setInvestmentInStock`,
  setInvestmentOutStock: `setInvestmentOutStock`,
  setInvestmentInFlows: `setInvestmentInFlows`,
  setInvestmentOutFlows: `setInvestmentOutFlows`,
  setForeignTradeTurnoverWordBalance: 'setForeignTradeTurnoverWordBalance',
  setForeignTradeTurnoverWordGdpValues: `setForeignTradeTurnoverWordGdpValues`,
  setUnfriendlyCountryInfo: `setUnfriendlyCountryInfo`,
  setTopImportIndustries: `setTopImportIndustries`,
  setTopExportIndustries: `setTopExportIndustries`,
  setTopImportCountries: `setTopImportCountries`,
  setTopExportCountries: `setTopExportCountries`,
  setIndicatorBudgetDeficit: `setIndicatorBudgetDeficit`,
  setIndicatorStockMarketCapitalization: `setIndicatorStockMarketCapitalization`,
  setIndicatorFinancialAccount: `setIndicatorFinancialAccount`,
  setIndicatorGovernmentBondYields: `setIndicatorGovernmentBondYields`,
  setYearListTurnover: 'setYearListTurnover',
  setForeignTradeData: 'setForeignTradeData',
  setMacroEconomicIndicatorValue: 'setMacroEconomicIndicatorValue',
  setEconomicReviewSummary: 'setEconomicReviewSummary',
  setIndicatorCountryRating: 'setIndicatorCountryRating',
  setExportProductLast: 'setExportProductLast',
  setFerRegionsTradeValue: `setFerRegionsTradeValue`,
  setFerRegionsTradeCountryCount: 'setFerRegionsTradeCountryCount',
  setQuartalList: 'setQuartalList',
  setQuartalSummary: 'setQuartalSummary',
};

export const COUNTRY_ANALYTICS_GETTERS_NAME = {
  getSummaryLifeIndex: `${COUNTRY_ANALYTICS}/getSummaryLifeIndex`,
  getInStockInvestment: `${COUNTRY_ANALYTICS}/getInStockInvestment`,
  getOutStockInvestment: `${COUNTRY_ANALYTICS}/getOutStockInvestment`,
  getInFlowsInvestment: `${COUNTRY_ANALYTICS}/getInFlowsInvestment`,
  getOutFlowsInvestment: `${COUNTRY_ANALYTICS}/getOutFlowsInvestment`,
  getBalanceForeignTradeTurnoverWord: `${COUNTRY_ANALYTICS}/getBalanceForeignTradeTurnoverWord`,
  getGdpValuesForeignTradeTurnoverWord: `${COUNTRY_ANALYTICS}/getGdpValuesForeignTradeTurnoverWord`,
  getInfoAboutUnfriendlyCountry: `${COUNTRY_ANALYTICS}/getInfoAboutUnfriendlyCountry`,
  getIndustriesOfTopImport: `${COUNTRY_ANALYTICS}/getIndustriesOfTopImport`,
  getIndustriesOfTopExport: `${COUNTRY_ANALYTICS}/getIndustriesOfTopExport`,
  getCountriesOfTopImport: `${COUNTRY_ANALYTICS}/getCountriesOfTopImport`,
  getCountriesOfTopExport: `${COUNTRY_ANALYTICS}/getCountriesOfTopExport`,
  getBudgetDeficitIndicator: `${COUNTRY_ANALYTICS}/getBudgetDeficitIndicator`,
  getStockMarketCapitalizationIndicator: `${COUNTRY_ANALYTICS}/getStockMarketCapitalizationIndicator`,
  getFinancialAccountIndicator: `${COUNTRY_ANALYTICS}/getFinancialAccountIndicator`,
  getGovernmentBondYieldsIndicator: `${COUNTRY_ANALYTICS}/getGovernmentBondYieldsIndicator`,
  getTurnoverYearList: `${COUNTRY_ANALYTICS}/getTurnoverYearList`,
  getForeignTradeData: `${COUNTRY_ANALYTICS}/getForeignTradeData`,
  getValueOfMacroEconomicIndicator: `${COUNTRY_ANALYTICS}/getValueOfMacroEconomicIndicator`,
  getSummaryEconomicReview: `${COUNTRY_ANALYTICS}/getSummaryEconomicReview`,
  getCountryRatingIndicators: `${COUNTRY_ANALYTICS}/getCountryRatingIndicators`,
  getLoadingTopStatisticCountries: `${COUNTRY_ANALYTICS}/getLoadingTopStatisticCountries`,
  getLastExportProduct: `${COUNTRY_ANALYTICS}/getLastExportProduct`,
  getFerRegionsTradeValues: `${COUNTRY_ANALYTICS}/getFerRegionsTradeValues`,
  getFerRegionsTradeCountOfCountry: `${COUNTRY_ANALYTICS}/getFerRegionsTradeCountOfCountry`,
  getQuartals: `${COUNTRY_ANALYTICS}/getQuartals`,
  getSummaryQuartal: `${COUNTRY_ANALYTICS}/getSummaryQuartal`,
};
