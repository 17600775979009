<template>
  <div class="popup">
    <div class="popup__head popup__clickable-head d-flex align-items-center" @click="openMarketAccess">
      <div
        v-if="country?.picture"
        class="imagePreviewWrapper"
        :style="{ 'background-image': `url(${country?.picture})` }"
      ></div>
      <p class="font-weight-bold">{{ country?.nameFull }}</p>
    </div>
    <div class="popup__body">
      <template v-if="tradeInvestmentData && tradeInvestmentData.length">
        <ul class="popup__body_list">
          <li
            class="popup__body_list-item d-flex justify-content-between align-items-center"
            v-for="item in tradeInvestmentData"
            :key="item.id"
          >
            <p class="popup__body_list-item-title">{{ item.name }}</p>
            <ButtonStock class="d-block btn-small" title="Просмотр" v-on:click="openTradeInvestmentModal(item)" />
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="popup__body_no-data p-3">Нет данных.</div>
      </template>
    </div>
  </div>
</template>

<script>
  import ButtonStock from '@/components/buttons/ButtonStock';

  import API from '@/modules/countryAnalyticsNew/api/api';
  import API_Country from '@/modules/nsi/api/country';

  export default {
    name: 'TradeInvestmentPopup',
    components: { ButtonStock },
    emits: ['agreementDetailsClick', 'tradeInvestmentCountryClick'],
    props: {
      countryId: {
        type: Number,
        default: null,
      },
      sourceCountryId: {
        type: Number,
        default: null,
      },
      agreementTypeId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        country: null,
        tradeInvestmentData: [],
      };
    },
    created() {
      this.$watch(
        (vm) => [vm.agreementTypeId],
        () => {
          if (this.countryId) this.loadTradeInvestmentData();
        },
      );
    },
    mounted() {},
    beforeUnmount() {},
    methods: {
      loadCountry() {
        this.country = null;
        if (this.countryId)
          API_Country.findShort(this.countryId).then((response) => {
            this.country = response.data;
          });
      },
      loadTradeInvestmentData() {
        this.tradeInvestmentData = [];
        API.getAgreementsBySides(this.sourceCountryId, this.countryId, this.agreementTypeId).then((response) => {
          this.tradeInvestmentData = response.data;
        });
      },
      openTradeInvestmentModal(item) {
        this.$emit('agreementDetailsClick', item.id, this.countryId, this.country?.name);
      },
      openMarketAccess() {
        this.$emit('tradeInvestmentCountryClick', this.countryId);
      },
    },
    watch: {
      countryId: function () {
        this.loadCountry();
        if (this.countryId) this.loadTradeInvestmentData();
      },
    },
    computed: {},
  };
</script>

<style lang="scss" scoped>
  .popup {
    &__head {
      background: #1d87ee;
      p,
      span {
        margin: 10px 32px 10px 24px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        color: #ffffff;
      }
    }
    &__body {
      padding: 0;
      &_list {
        overflow: auto;
        max-height: 270px;
        &-item {
          list-style-type: none;
          &-title {
            min-width: 240px;
          }
        }
      }
    }
  }
  .imagePreviewWrapper {
    width: 40px;
    height: 40px;
    display: block;
    cursor: pointer;
    margin: 5px 10px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .popup__clickable-head {
    cursor: pointer;
  }
</style>
