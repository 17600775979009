<template>
  <div class="container-page">
    <div class="input-search">
      <SearchInputComponetn />
    </div>
    <section class="contacts">
      <ul class="contacts__cards-list">
        <ContactsCardComponents
          v-for="card in cardData"
          @click="clickCard"
          :title="card.title"
          :img="card.img"
          :index="card.id"
          :number="card.number"
          :key="card.id"
        />
      </ul>
      <div class="contacts__registry">
        <div>
          <h3 class="contacts__registry-title">Реестр действующих сотрудников</h3>
          <div class="contacts__registry-top">
            <p>Сотрудники: {{ dataRegistry.length }}</p>
            <span @click="sortName()">АЯ <i class="fas fa-arrow-down"></i></span>
          </div>
        </div>
        <ul class="contacts__registry-list">
          <li
            v-for="(item, idx) in dataRegistry"
            @click="showModalContact(idx)"
            :key="item.id"
            class="contacts__registry-item"
          >
            <h4>
              {{ item.surname }} {{ item.name }} {{ item.patronymic }}
              <i
                v-if="item.canSendMessage"
                class="fas fa-envelope send-message"
                title="Написать сообщение"
                @click="goToMessenger(item.id)"
              ></i>
              <ModalComponent v-if="modalContact && idxContact === idx" @close="closeModal">
                <div class="border-bottom pb-2 mb-2">
                  Мобильный телефон: <span>{{ item.mobilePhone ? item.mobilePhone : '-' }}</span>
                </div>
                <div class="border-bottom pb-2 mb-2">
                  Внутренний телефон: <span>{{ item.workPhone ? item.workPhone : '-' }}</span>
                </div>
                <div class="border-bottom pb-2 mb-2">
                  Email: <span>{{ item.email ? item.email : '-' }}</span>
                </div>
                <div class="border-bottom pb-2 mb-2">
                  Кабинет: <span>{{ item.office ? item.office : '-' }}</span>
                </div>
              </ModalComponent>
            </h4>
            <p>
              {{ item.department ? item.department.name : '' }} / {{ item.position ? item.position.title : ''
              }}{{ item.workPhone ? ', телефон: ' + item.workPhone : ''
              }}{{ item.internalPhone ? ', внут. ' + item.internalPhone : '' }}
            </p>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
  import SearchInputComponetn from '@/common/components/HomePage/searchInputComponent';

  import ModalComponent from '@/components/modals/ModalComponent';

  import Api from '../api/contacts.js';
  import ContactsCardComponents from '../components/constactsCardComponent';
  export default {
    components: { ModalComponent, SearchInputComponetn, ContactsCardComponents },
    data() {
      return {
        cardData: [
          { title: 'Министерство сельского хозяйства', id: 1, img: 'Emblem.png', number: '01' },
          { title: 'Росрыболовство', id: 3, img: 'Emblem1.png', number: '02' },
          { title: 'Россельхознадзор', id: 2, img: 'Emblem2.png', number: '03' },
          { title: 'РОИВ1', id: 4, number: '04' },
          { title: 'РОИВ2', id: 5, number: '05' },
          { title: 'РОИВ3', id: 6, number: '06' },
          { title: 'РОИВ4', id: 7, number: '07' },
        ],
        dataRegistry: [],
        modalContact: false,
        idxContact: null,
      };
    },
    created() {
      this.loadList(1);
    },
    methods: {
      sortName() {
        this.sort();
      },
      showModalContact(idx) {
        this.idxContact = idx;
        this.modalContact = true;
      },
      closeModal(idx) {
        this.idxContact = idx;
        this.modalContact = false;
      },
      loadList(orgId) {
        Api.search({ pageNumber: 1, pageSize: 1000, organizationIds: [orgId], isActive: true }).then((result) => {
          this.dataRegistry = result.data.items;
          if (this.dataRegistry) this.sort();
        });
      },
      clickCard(cardId) {
        //console.log(cardId, typeof cardId);
        if (typeof cardId == 'number') this.loadList(cardId);
      },
      goToMessenger(employeeId) {
        this.$router.push(`/messenger/${employeeId}`);
      },
    },
    computed: {
      sort() {
        let sorted;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        sorted = this.dataRegistry.sort((a, b) => a.surname.localeCompare(b.surname));
        return sorted;
      },
    },
  };
</script>

<style scoped lang="scss">
  .input-search {
    max-width: 35%;
    width: 100%;
    position: absolute;
    content: '';
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1111;
  }
  .contacts {
    margin-top: 26px;
    display: flex;
    align-items: flex-start;

    &__cards-list {
      justify-content: space-between;
      padding: 0 0 40px 0;
      max-width: 445px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }
  .contacts__registry {
    max-width: 100%;
    width: 100%;
    margin-left: 20px;
    padding: 27px 17px 44px 42px;
    background: #ffffff;
    &-title {
      margin-bottom: 28px;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 29px;
      color: #212125;
    }
    &-top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 27px;
      margin-bottom: 20px;
      border-bottom: 1px dashed #212125;
      p,
      span {
        margin: 0;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 22px;
        color: #212125;
        text-align: justify;
      }
    }
    &-list {
      padding: 0;
    }
    &-item {
      cursor: pointer;
      display: flex;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px dashed #212125;
      .fas {
        margin-left: auto;
        color: #c3c3c8;
      }
      h4 {
        width: 50%;
        margin: 0 28px 0 0;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        color: #212125;
      }
      p {
        word-break: break-word;
        margin: 0 0 0 0;
        width: 50%;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 130%;
        color: #212125;
      }
    }
    .send-message {
      cursor: pointer;
      margin-left: 8px;
    }
  }
</style>
