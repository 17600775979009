<template>
  <div class="bar-chart-tooltip">
    <h4 v-if="title">{{ title }}</h4>
    <template v-if="isExistDataSeries">
      <ul v-if="isShowLegend && legends.length" class="bar-chart-tooltip__legend">
        <li v-for="(legend, index) in legends" :key="index">
          <span class="bar-chart-tooltip__legend-point" :style="`background: ${legend.backgroundColor}`"></span>
          <span class="bar-chart-tooltip__legend-name">{{ legend.label }}</span>
        </li>
      </ul>
      <div class="bar-chart-tooltip__top">
        <div class="bar-chart-tooltip__categories">
          <div v-for="(item, index) in categories" :key="`categories-${index}`" class="bar-chart-tooltip__category">
            <span class="bar-chart-tooltip__category-text">{{ item }}</span>
            <Tooltip v-if="longString(item)" class="tooltip-component--mobile-big">
              <span>{{ item }}</span>
            </Tooltip>
          </div>
        </div>
        <BarChart
          :key="updateKey"
          :chart-data="seriesProp"
          :height="computedHeight"
          :options="options"
          css-classes="bar-chart-tooltip__graph"
        />
      </div>
    </template>
    <p class="bar-chart-tooltip__empty" v-else>{{ dataEmptyMessage }}</p>
  </div>
</template>

<script>
  import { BarChart } from 'vue-chart-3';

  import numberFormatter from '@/common/mixins/numberFormatter';
  import Tooltip from '@/common/ui/Tooltip.vue';

  export default {
    name: 'TopCountryStatisticChart',
    components: { BarChart, Tooltip },
    mixins: [numberFormatter],
    props: {
      title: {
        type: String,
        default: '',
      },
      seriesProp: {
        type: Object,
        default: () => {
          return {
            labels: [],
            datasets: [],
          };
        },
      },
      dataEmptyMessage: {
        type: String,
        default: 'Нет данных для показателя',
      },
      options: {
        type: Object,
        default: () => ({
          indexAxis: 'y',
          locale: 'ru-RU',
          clip: false,
          layout: {
            padding: {
              right: 70,
            },
          },
          plugins: {
            tooltip: { enabled: false },
            legend: {
              display: false,
            },
            datalabels: {
              clamp: true,
              align: 'end',
              offset: 0,
              anchor: 'end',
              color: '#231F20',
              family: 'Fira Sans',
              textAlign: 'end',
              font: {
                fontSize: 12,
              },
              formatter: (value) => {
                return value.toString().includes('.') ? value.toString().replace('.', ',') + '%' : value + ',0%';
              },
            },
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              grid: {
                display: false,
              },
            },
          },
        }),
      },
      categories: {
        type: Array,
        default: () => [],
      },
      itemHeight: {
        type: Number,
        default: 80,
      },
      isShowLegend: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        maxLengthLine: 38,
        legends: this.seriesProp?.datasets ? JSON.parse(JSON.stringify(this.seriesProp?.datasets)) : [],
        // используется для принудительного обновления компонента
        updateKey: 0,
      };
    },
    computed: {
      isExistDataSeries() {
        if (this.seriesProp?.datasets == null || this.seriesProp.datasets.length === 0) {
          return false;
        }
        const isData = this.seriesProp?.datasets.reduce((acc, el) => acc && el.data.length > 0, true);
        return isData;
      },
      computedHeight() {
        let dataLength = this.seriesProp.labels.length;
        dataLength = Math.max(1, dataLength);
        return dataLength * this.itemHeight;
      },
    },
    methods: {
      // при изменении свойства `height` `vue-chart-3` не пересчитывает DOM height, но меняет разрешение canvas
      // встроенный vue-метод $forceUpdate также не влияет на перерасчёт размеров
      forceUpdate() {
        this.updateKey = 1 - this.updateKey;
      },
      longString(item) {
        return item.length > 21;
      },
    },
    mounted() {
      if (window.innerWidth < 1023) {
        this.maxLengthLine = 29;
      }
    },
    watch: {
      seriesProp: {
        handler() {
          this.legends = JSON.parse(JSON.stringify(this.seriesProp?.datasets));
          this.forceUpdate();
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './styles/BarChartTooltip.scss';

  h4 {
    font: $fira-16-M;
    color: $base;
    margin-bottom: 20px;
  }

  .bar-chart-tooltip__graph {
    min-width: 400px !important;
  }
  .bar-chart-tooltip__legend {
    justify-content: left !important;
    margin-bottom: 20px !important;
  }
  .bar-chart-tooltip__categories {
    width: auto !important;
    max-width: 210px;
  }
  .bar-chart-tooltip__category {
    justify-content: flex-start !important;
    &-text {
      color: $base !important;
      font: $fira-16 !important;
    }
  }
</style>
