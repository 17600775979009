<template>
  <div></div>
</template>

<script>
  import refreshAuthorizationHeader from 'axios';

  import userManager from '@/modules/auth/user-manager';

  import store from '@/store';
  //import runtimeConfig from '@/common/runtime-config';

  export default {
    name: 'Callback',
    created() {
      const vm = this;
      userManager
        .signinRedirectCallback()
        .then(async function () {
          userManager
            .getUser()
            .then((user) => {
              localStorage.setItem('token', user.access_token);
              refreshAuthorizationHeader();
              store.dispatch('auth/oidc/loadUser').then(() => {
                const returnUrl = sessionStorage.getItem('login_return_url') || '/';
                vm.$router.push(returnUrl);
              });
            })
            .catch((error) => {
              console.error('userManager.getUser failed', error);
            });
        })
        .catch(function (e) {
          window.console.error(e);
          //window.location.href = runtimeConfig.oidc.post_logout_redirect_uri;
          //TODO think about it
        });
    },
  };
</script>
