<template>
  <GDialog :width="width" :scrollable="scrollable">
    <div
      class="modal__body"
      :class="[
        {
          'modal__body-p0': headType != 'default',
          'modal__body-redesigned': headType === 'redesigned',
          'modal__body-with-scroll': scrollable,
        },
      ]"
    >
      <!-- modal header -->
      <div :class="`modal__body-head-${headType} ${margin}`" class="modal__body-head">
        <!-- в компонент можно менять только этот тайтл -->
        <slot name="header" />
        <p v-html="title"></p>
        <IconComponent
          v-if="!themeLigth"
          @click="$emit('close')"
          :name="headType === 'redesigned' ? 'closeClean' : 'crossInCircle'"
        />
        <IconComponent v-else @click="$emit('close')" name="closeCross" />
      </div>
      <!-- modal body -->
      <div
        :class="{
          'modal__body-inner-wrap': bodyType === 'redesigned',
          'modal__body-inner': headType !== 'redesigned',
        }"
      >
        <!-- slot start -->
        <slot />
        <!-- slot end -->
      </div>
    </div>
  </GDialog>
</template>

<script>
  // @click="dialogState = true"
  import IconComponent from '@/common/ui/IconComponent.vue';

  export default {
    name: 'ModalComponent',
    components: { IconComponent },
    props: {
      title: {
        default: '',
        type: String,
      },
      headType: {
        default: 'default',
        type: String,
        // могут быть еще значения
        validator: (value) => ['default', 'redesigned'].includes(value),
      },
      bodyType: {
        default: 'default',
        type: String,
        validator: (value) => ['default', 'redesigned'].includes(value),
      },
      width: {
        type: Number,
        default: 610,
      },
      themeLigth: {
        type: Boolean,
        default: false,
      },
      // чтобы был визуальный скролл, передать true
      scrollable: {
        type: Boolean,
        default: false,
      },
      // классы, которые находятся в style, для переопределения отступа между заголовком и текстом
      margin: {
        type: String,
        validator: (value) => ['mb-8'].includes(value),
      },
    },
  };
</script>

<style lang="scss" scoped>
  .modal {
    &__body {
      padding: 16px;
      background: #ffffff;
      &-head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        p {
          font-family: 'Fira Sans', sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          color: #000000;
        }

        &-redesigned {
          p {
            font: $fira-24;
            font-weight: 500;
            text-transform: none;
          }
        }
        .icon {
          cursor: pointer;
        }
        &-blue {
          background-color: #0179c8;
          padding: 26px;
          p {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.15px;
            color: #ffffff;
          }
        }
        &-redesigned {
          font: $fira-24;
          font-weight: 500;
        }
        // Перечисление всех типов
        &:not(&-blue) .icon {
          color: #787f8f;
        }
        &-blue .icon {
          color: #ffffff;
        }
      }
      &-p0 {
        padding: 0px;
        .modal__body-inner {
          padding: 26px;
        }
      }

      &-redesigned {
        padding: 32px 40px;

        @media (max-width: $laptop) {
          height: 100%;
        }

        @media (max-width: $mobile) {
          padding: 16px;
        }
      }
      &-with-scroll {
        padding: 16px 6px 16px 16px;
        width: 100%;
        display: flex;
        flex-direction: column;
        .modal__body-inner {
          width: 100%;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          padding-right: 10px;
        }
      }
    }

    &--new & {
      &__body {
        padding: 0 40px 40px;
        max-height: 90dvh;
        overflow: auto;

        &-head {
          position: sticky;
          top: 0;
          background: #fff;
          z-index: 1;
          padding: 40px 0 4px;
          margin-bottom: 20px;

          p {
            font-weight: normal;
            text-transform: none;
            font: $fira-24-M;
            letter-spacing: 0;
          }
        }

        &-inner {
          overflow: hidden auto;
          padding-right: 10px;
          margin-right: -10px;

          &::-webkit-scrollbar {
            margin-left: -20px;
          }
        }

        &-inner-wrap {
          position: relative;
          z-index: 0;
        }
      }

      @media (max-width: $laptop) {
        &__body {
          padding: 0 14px 14px;
          max-height: 100dvh;

          &-head {
            margin-bottom: 12px;
            padding-top: 14px;

            p {
              font: $fira-20-M;
            }
          }
        }
      }
    }

    &--edit-subtask & {
      @media (max-width: $mobile-2) {
        &__body {
          &-head {
            p {
              font-size: 19px;
            }
          }
        }
      }
    }
  }
</style>
