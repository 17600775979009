import axios from 'axios';
import moment from 'moment';

import Utils from '@/common/utils';

import constants from '../../../common/constants';

export default {
  search: function (request, useCache) {
    let req = { params: request };
    if (!useCache)
      req.headers = {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      };
    return axios.get('/api/restrictionmeasure/search', req);
  },
  find: function (id) {
    return axios.get(`/api/restrictionmeasure/find/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/restrictionmeasure/${data.id}`, data);
    else return axios.post(`/api/restrictionmeasure`, data);
  },
  delete: function (id) {
    return axios.delete(`/api/restrictionmeasure/${id}`);
  },
  sendApproval: function (id) {
    return axios.put(`/api/restrictionmeasure/sendToApproval?id=${id}`);
  },
  approveMeasure: function (request) {
    return axios.put(`/api/restrictionmeasure/approveMeasure`, request);
  },
  refuseMeasure: function (data) {
    return axios.put(`/api/restrictionmeasure/refuseMeasure`, data);
  },
  exportExcel: function (request) {
    return Utils.downloadFile(
      '/api/restrictionmeasure/exportExcel',
      `analytics_report ${moment().format(constants.DATE_FORMATS['YYYY-MM-DD'])}.xlsx`,
      request,
      true,
    );
  },
  exportWord: function (request, fileName) {
    return Utils.downloadFile('/api/restrictionmeasure/exportWord', fileName, request);
  },
  getTemplate: function () {
    return Utils.downloadFile('/api/restrictionmeasure/template', 'RestrictionMeasureTemplate.xlsx');
  },
  uploadFile: function (file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`/api/restrictionmeasure/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};
