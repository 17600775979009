import axios from 'axios';

export default {
  search: function () {
    return axios.get('/api/indicatorVisibility/search');
  },
  update: function (items) {
    return axios.put(`/api/indicatorVisibility/updateIndicators`, items);
  },
};
