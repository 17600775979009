<template>
  <div id="container" class="map-container">
    <div id="mapContainerMap"></div>
  </div>
</template>

<script>
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';

  import worldMap from '@/common/custom.geo.json';

  export default {
    name: 'MapControl',
    data() {
      return {
        center: [40, 0],
        world: worldMap,
        itemsInner: this.items,
        mapDiv: null,
        geoJsonLayer: null,
      };
    },
    emits: ['onCountryHeaderClick'],
    props: {
      items: {
        type: Object,
        default: () => {},
      },
      countries: {
        type: Array,
        default: () => [],
      },
      colors: {
        type: Array,
        default: () => [],
      },
      mouseUpFunction: {
        type: Function,
        default: () => {},
      },
    },
    watch: {
      items: {
        handler: function (newValue) {
          this.itemsInner = newValue;
          this.restyleGeoJsonLayer();
        },
        deep: true,
      },
      countries: {
        handler: function () {
          this.restyleGeoJsonLayer();
        },
        deep: true,
      },
    },
    created() {},
    methods: {
      styleMap(feature) {
        let color = '#E3E3E3';
        var code = Number(feature.properties.iso_n3).toString();
        var itemsCount = this.itemsInner[code] ?? 0;
        feature.properties.countryStatus = itemsCount;
        var country = this.countries.find((x) => x.code == code);
        if (country) {
          feature.properties.rusName = country.fullName;
        }

        if (itemsCount != 0)
          color =
            this.colors.find((x) => x.minValue <= itemsCount && (x.maxValue == null || x.maxValue >= itemsCount))
              ?.color ?? '#E3E3E3';

        if (code == 304) {
          return {
            color: '#60a7ee',
            fillColor: 'white',
            weight: 1,
            opacity: 0.5,
          };
        }
        return { color: '#60a7ee', fillColor: color, weight: 1, opacity: 0.7 };
      },
      onEachFeature(feature, layer) {
        if (feature.properties && feature.properties.rusName) {
          layer.off('mouseup');
          layer.on('mouseup', () => {
            if (this.mouseUpFunction) {
              this.mouseUpFunction(layer);
            }
          });
          layer.on('mouseover', function () {
            this.unbindTooltip();
            this.bindTooltip(feature.properties.rusName ?? '').openTooltip();
          });
          layer.on('mouseout', function () {
            this.unbindTooltip();
          });
        }
      },
      setupLeafletMap: function () {
        let mapContainer = window.document.getElementById('mapContainerMap');
        while (mapContainer.firstChild) {
          mapContainer.removeChild(mapContainer.firstChild);
        }
        if (this.mapDiv) {
          this.mapDiv.remove();
          this.mapDiv = null;
        }
        this.mapDiv = L.map('mapContainerMap').setView(this.center, 1);
        L.tileLayer('', {
          maxZoom: 5,
          minZoom: 1.25,
        }).addTo(this.mapDiv);
        this.geoJsonLayer = L.geoJSON(this.world, {
          weight: 0.5,
          fillOpacity: 1,
        }).addTo(this.mapDiv);
        this.restyleGeoJsonLayer();
      },
      restyleGeoJsonLayer() {
        if (this.geoJsonLayer) {
          this.geoJsonLayer.eachLayer(this.restyleLayer);
        }
      },
      restyleLayer(layer) {
        layer.closePopup();
        let style = this.styleMap(layer.feature);
        layer.setStyle(style);
        this.onEachFeature(layer.feature, layer);
      },
    },
    mounted() {
      this.setupLeafletMap();
    },
  };
</script>

<style scoped lang="scss">
  .container {
    height: 500px;
  }
  #mapContainerMap {
    background-color: #ffffff;
    width: 100%;
    height: 650px;
  }
</style>

<style lang="scss">
  .leaflet-container a.leaflet-popup-close-button {
    right: 8px;
  }
  .leaflet-popup-content-wrapper {
    .leaflet-popup-content {
      min-width: 320px;
      max-height: 320px;
      overflow: auto;
    }
    border-radius: 24px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    .leaflet-popup-tip-container {
      display: none;
    }
    padding: 0;
    .popup {
      &__head {
        background: #1d87ee;
        p,
        span {
          margin: 10px 32px 10px 24px;
          font-family: 'Fira Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 160%;
          color: #ffffff;
        }
      }
      &__body {
        li {
          margin: 8px 0;
        }
        p {
          margin: 10px 24px;
          font-family: 'Fira Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 160%;
          color: #015caf;
        }
      }
    }
    .leaflet-popup-content {
      margin: 0;
    }
  }
  .map-container {
    margin-top: 45px;
    overflow: visible;
  }
</style>
