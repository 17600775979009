<template>
  <div class="container-page">
    <div class="nsi-page">
      <p class="nsi-page__title title-inner">Раздел НСИ</p>
      <div class="nsi-page__table table-container js-table-arrow">
        <table>
          <thead>
            <tr>
              <th>Идентификатор</th>
              <th>Наименование</th>
              <th>Дата обновления</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-select">
              <td>
                <select-component :options="code" placeholder="Поиск ..." />
              </td>
              <td>
                <select-component :options="name" placeholder="Введите наименование ..." />
              </td>
              <td>
                <select-component :options="date" placeholder="Поиск ..." />
              </td>
            </tr>
            <tr>
              <td>392 347 618 13</td>
              <td>Наименование</td>
              <td>09.02.2020</td>
            </tr>
            <tr>
              <td>392 347 618 13</td>
              <td>Наименование</td>
              <td>09.02.2020</td>
            </tr>
            <tr>
              <td>392 347 618 13</td>
              <td>Наименование</td>
              <td>09.02.2020</td>
            </tr>
            <tr>
              <td>392 347 618 13</td>
              <td>Наименование</td>
              <td>09.02.2020</td>
            </tr>
            <tr>
              <td>392 347 618 13</td>
              <td>Наименование</td>
              <td>09.02.2020</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  // Additional
  import selectComponent from '@/common/components/selectComponent.vue';

  export default {
    name: 'NsiList',
    components: {
      selectComponent,
    },
    methods: {},
    data() {
      return {
        code: [
          { text: '392 347 618 13', id: '1' },
          { text: '392 347 618 13', id: '2' },
          { text: '392 347 618 13', id: '3' },
        ],
        name: [
          { text: 'Наименование', id: '1' },
          { text: 'Наименование', id: '2' },
          { text: 'Наименование', id: '3' },
        ],
        date: [
          { text: '09.02.2020', id: '1' },
          { text: '09.02.2020', id: '2' },
          { text: '09.02.2020', id: '3' },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped>
  .nsi-page {
    padding-bottom: 80px;
    &__table {
      table {
        thead {
          th {
            padding: 20px 16px;
            font-weight: 700;
            font-size: 14px;
            line-height: 150%;
            color: #3b4d3e;
            border-bottom: 1px solid #787f8f;
            background: #cfe2df;
            &:nth-of-type(1) {
              width: 258px;
              border-right: 1px solid #787f8f;
            }
            &:nth-of-type(2) {
              width: 772px;
              border-right: 1px solid #787f8f;
            }
            &:nth-of-type(3) {
              width: 178px;
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid #787f8f;
            &:nth-of-type(1) {
              td {
                padding: 0px;
              }
              border-bottom: 10px solid #cfe2df;
            }
          }
          td {
            padding: 14px 16px;
            font-size: 14px;
            line-height: 150%;
            color: #3b4d3e;
            &:nth-of-type(1),
            &:nth-of-type(2) {
              border-right: 1px solid #787f8f;
            }
          }
        }
      }
    }
  }
</style>
