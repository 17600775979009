<template>
  <div class="container-page Eeclnvestigation-card">
    <p class="title-inner">Каталог товаров. Несырьевой неэнергетический экспорт</p>
    <div>
      <div class="stockTable mb-2" style="position: relative">
        <i
          class="fas fa-search catalog-icon"
          title="Найти в каталоге"
          @click="openTnvedCatalog(index, 'tnvedBeforeId')"
        ></i>

        <label>Коды ТН ВЭД</label>
      </div>

      <div class="table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <tbody>
            <tr v-for="id in tnvedIds" v-bind:key="id">
              <td>{{ products.find((x) => x.id == id)?.text ?? id }}</td>
              <td>
                <IconComponent @click="deleteElement(id)" name="delete" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />

      <ButtonStock class="float-right" title="Проверить на пересечения" @click="checkIntersect" />
    </div>
  </div>
  <section>
    <ModalComponent v-model="tnvedModal" title="Выбор товара" @close="closeTnvedCatalog">
      <CatalogSelector
        v-bind:selected-ids="productIds"
        route="nsitnved"
        :multiple="true"
        :select-leafs-only="false"
        @select="updateTnved"
        @close="closeTnvedCatalog"
      ></CatalogSelector>
    </ModalComponent>
  </section>
</template>

<script>
  import Constants from '@/common/constants';

  import IconComponent from '../../../common/ui/IconComponent';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import CatalogApi from '../../nsi/api/catalog';
  import CatalogSelector from '../../nsi/components/CatalogSelector';
  import Api from '../api/tnved';

  export default {
    name: 'TnvedNonEnergy',
    components: {
      ModalComponent,
      CatalogSelector,
      ButtonStock,
      IconComponent,
    },
    data() {
      return {
        tnvedIds: [],
        products: [],
        tnvedModal: false,
        productIds: [],
        currentTnvedIndex: -1,
        currentTnvedProp: '',

        firstInit: true,
      };
    },
    created() {
      this.loadCard();
    },
    methods: {
      loadCard() {
        Api.searchNonEnergy().then((response) => {
          this.tnvedIds = response.data.map((x) => x.id);
          this.products = response.data.map((x) => ({
            id: x.id,
            text: `${x.code} - ${x.name}`,
            code: x.code,
          }));
          this.firstInit = false;
        });
      },
      save() {
        if (this.tnvedIds.length <= 0) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        Api.updateNonEnergy(this.tnvedIds)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.loadCard();
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      openTnvedCatalog(index, prop) {
        this.currentTnvedIndex = index;
        this.currentTnvedProp = prop;
        this.productIds = this.tnvedIds ?? [];
        this.tnvedModal = true;
      },
      updateTnved(selectedIds) {
        this.tnvedIds = selectedIds;
        this.closeTnvedCatalog();
      },
      closeTnvedCatalog() {
        this.tnvedModal = false;
      },
      updateProducts(list) {
        list.forEach((item) => {
          if (!this.products.find((x) => x.id === item.id)) {
            this.products.push(item);
          }
        });
      },
      deleteElement(id) {
        const index = this.tnvedIds.indexOf(id);
        if (index > -1) {
          this.tnvedIds.splice(index, 1);
        }
      },

      close() {
        this.$router.push({ name: 'EecInvestigationRegistry' });
      },

      checkIntersect() {
        var arr = this.products.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
        for (var i = 1; i < arr.length; i++) {
          if (arr[i].code.startsWith(arr[i - 1].code)) {
            Constants.alert.fire(
              'Проверка',
              'Найдены пересекающиеся коды: ' + arr[i - 1].code + ', ' + arr[i].code,
              'error',
            );
            return false;
          }
        }
        Constants.alert.fire('Проверка', 'Пересечений кодов не найдено', 'success');
        return true;
      },
    },
    watch: {
      tnvedIds: {
        handler: function (value) {
          if (this.firstInit) return;
          if (!value?.length) return;

          var ids = [];
          value.forEach((x) => {
            if (!this.products.find((t) => t.id == x)) {
              ids.push(x);
            }
          });

          CatalogApi.getListByIds('nsitnved', ids).then((response) => {
            this.updateProducts(
              response.data.map((x) => ({
                id: x.id,
                text: `${x.code} - ${x.name}`,
                code: x.code,
              })),
            );
          });
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .col-tnved {
    width: 25%;
  }

  .col-rate {
    width: 10%;
  }

  .stockTable {
    .catalog-icon {
      top: 8px;
    }
  }
</style>

<style lang="scss">
  .select2-container--default {
    .select2-selection--multiple {
      .select2-selection__clear {
        margin-right: 18px !important;
        margin-top: -8px !important;
      }
    }
  }
</style>
