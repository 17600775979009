<template>
  <div class="mpk-statistics-container" v-if="countryId && mpkData && mpkData.length">
    <div class="title" id="mpk-statistics">Статистика по МПК</div>
    <ul class="mpk-list">
      <li class="mpk-item" v-for="item in mpkData" :key="item.id">
        <div class="mpk-item-data d-flex">
          <div class="col d-flex flex-column justify-content-between">
            <div class="mpk-item-text mpk-item-name">{{ item.name }}</div>
            <div class="mpk-item-text">{{ item.createdInfo ?? '-' }}</div>
          </div>
          <div class="col d-flex flex-column justify-content-between">
            <div class="mpk-item-text">
              Ближайшее мероприятие: {{ item.nextEventZased ? item.nextEventZased.eventTypeName : '-' }}
            </div>
            <div class="d-flex justify-content-start mpk-item-events">
              <div class="mpk-item-text">Заседаний: {{ item.eventCount }}</div>
              <div
                class="mpk-item-text mpk-item-text-additional"
                v-if="item.lastEventZased && item.lastEventZased.eventFirstDate"
              >
                Последнее - {{ $momentFormat(item.lastEventZased.eventFirstDate, 'DD MMMM YYYY') }}
              </div>
              <div
                class="mpk-item-text mpk-item-text-additional"
                v-if="item.lastEvent && item.lastEvent.eventFirstDate"
              >
                Встреча сопредседателей - {{ $momentFormat(item.lastEvent.eventFirstDate, 'DD MMMM YYYY') }}
              </div>
            </div>
          </div>
          <div class="d-flex col justify-content-between">
            <div class="mr-3">
              <div class="status-item" :class="{ 'status-item-green': item.status?.id == 1 }">
                {{ item.status?.name }}
              </div>
            </div>
            <div
              class="mpk-item-collapse-button"
              :class="{ 'mpk-item-collapse-button-expanded': item.expanded }"
              @click="item.expanded = !item.expanded"
            ></div>
          </div>
        </div>
        <div class="mpk-item-details" v-if="item.expanded">
          <div class="mpk-item-chairman-block">
            <div class="title">Сопредседатели МПК</div>
            <div class="d-flex mpk-item-chairman-block-list">
              <div class="mpk-item-chairman-block-item d-flex">
                <div
                  class="mpk-item-chairman-block-item-picture"
                  :class="{ 'mpk-item-chairman-block-item-picture-nophoto': !item.chairman?.picture }"
                  :style="{ 'background-image': item.chairman?.picture ? `url(${item.chairman?.picture})` : '' }"
                ></div>
                <div
                  class="mpk-item-chairman-block-item-flag"
                  :style="{ 'background-image': `url(${countryRussia?.picture})` }"
                ></div>
                <div class="chairman-data">
                  <div class="chairman-data-name">{{ item.chairman?.name ?? '-' }}</div>
                  <div class="chairman-data-position">{{ item.chairman?.position ?? '-' }}</div>
                </div>
              </div>
              <div class="mpk-item-chairman-block-item d-flex">
                <div
                  class="mpk-item-chairman-block-item-picture"
                  :class="{ 'mpk-item-chairman-block-item-picture-nophoto': !item.chairmanForeign?.picture }"
                  :style="{
                    'background-image': item.chairmanForeign?.picture ? `url(${item.chairmanForeign?.picture})` : '',
                  }"
                ></div>
                <div
                  class="mpk-item-chairman-block-item-flag"
                  :style="{ 'background-image': `url(${item.country?.picture})` }"
                ></div>
                <div class="chairman-data">
                  <div class="chairman-data-name">{{ item.chairmanForeign?.name ?? '-' }}</div>
                  <div class="chairman-data-position">{{ item.chairmanForeign?.position ?? '-' }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mpk-item-structure-block" v-if="item.childGroups && item.childGroups.length">
            <div class="title">Структура МПК</div>
            <ul class="mpk-item-structure-block-list d-flex flex-wrap">
              <li
                class="mpk-item-structure-block-list-item d-flex justify-content-between"
                v-for="child in item.childGroups"
                :key="child.id"
              >
                <div class="group-data-block d-flex flex-column justify-content-between">
                  <div class="group-data-block-title">{{ child.name }}</div>
                  <div class="group-data-block-text">
                    <template v-if="child.lastEventZased">
                      {{ $momentFormat(child.lastEventZased.eventFirstDate, 'DD MMMM YYYY')
                      }}{{ child.lastEventZased.place ? ', ' + child.lastEventZased.place : '' }}
                    </template>
                    <template v-else> Заседания не проводились </template>
                  </div>
                </div>
                <div>
                  <div class="status-item" :class="{ 'status-item-green': item.status?.id == 1 }">
                    {{ item.status?.name }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import Constants from '@/common/constants';

  import Api_Commission from '@/modules/mpk/api/commission';
  import Api_Country from '@/modules/nsi/api/country';

  export default {
    name: 'MpkStatistics',
    components: {},
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        mpkData: [],
        countryRussia: {},
      };
    },
    created() {
      this.loadRussia();
      this.loadData();
    },
    methods: {
      loadRussia() {
        Api_Country.find(Constants.countryRussiaId).then((response) => {
          this.countryRussia = response.data;
        });
      },
      loadData() {
        this.mpkData = [];
        if (this.countryId) {
          Api_Commission.getListByCountry(this.countryId).then((response) => {
            this.mpkData = response.data;
          });
        }
      },
    },
    watch: {
      countryId: function () {
        this.loadData();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: #282b30;
  }

  .mpk-statistics-container {
    margin-top: 40px;
  }

  .mpk-item {
    margin-top: 10px;
    &-data {
      background: #edf4f9;
      padding: 20px;
      .col:nth-child(1) {
        max-width: 33%;
        width: 100%;
      }
      .col:nth-child(2) {
        max-width: 50%;
        width: 100%;
      }
      .col:nth-child(3) {
        max-width: 17%;
        width: 100%;
      }
    }
    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: #333333;
      &-additional {
        color: rgba(51, 51, 51, 0.6);
      }
    }
    &-name {
      font-weight: 500;
    }
    &-events {
      gap: 0 20px;
    }
    &-collapse-button {
      outline: none;
      margin-left: -8px;
      margin-top: -2px;
      border-left: 3px solid black;
      border-bottom: 3px solid black;
      width: 14px;
      min-width: 14px;
      height: 14px;
      transform: rotate(315deg);
      transform-origin: center;
      transition: 0.3s;
      cursor: pointer;
      &-expanded {
        transform: rotate(135deg);
        margin-top: 5px;
      }
    }
    &-chairman-block {
      margin-top: 45px;
      &-list {
        margin-top: 15px;
        gap: 0 20px;
      }
      &-item {
        position: relative;
        max-width: calc(50% - 10px);
        &-picture {
          margin-top: 8px;
          margin-right: 25px;
          height: 48px;
          width: 48px;
          min-width: 48px;
          border-radius: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          &-nophoto {
            background-image: url(~@/assets/images/no_photo.png);
          }
        }
        &-flag {
          position: absolute;
          top: 0;
          left: 35px;
          height: 30px;
          width: 30px;
          border-radius: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
  }

  .chairman-data {
    margin-top: 8px;
    &-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: #282b30;
    }
    &-position {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }

  .mpk-item-structure-block {
    margin-top: 60px;

    &-list {
      margin-top: 15px;
      gap: 0 20px;
      &-item {
        background: #edf4f9;
        padding: 20px;
        max-width: calc(50% - 10px);
        width: 100%;
      }
    }
  }

  .group-data-block {
    min-height: 55px;
    &-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #231f20;
    }
    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: rgba(51, 51, 51, 0.6);
    }
  }

  .status-item {
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    &-green {
      background: #a0d7d4;
      color: #01877d;
    }
  }
</style>
