import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    tableHeaderSingle: [
      {
        id: 0,
        name: 'Год',
        visible: true,
        width: 138,
      },
      {
        id: 1,
        name: 'Орган',
        visible: true,
        width: 192,
      },
      {
        id: 2,
        name: 'Дата решения',
        visible: true,
        width: 202,
      },
      {
        id: 3,
        name: 'Номер решения',
        visible: true,
        width: 192,
      },
      {
        id: 4,
        name: 'Инициатор',
        visible: true,
        width: 232,
      },
      {
        id: 5,
        name: 'Товар',
        visible: true,
        width: 352,
      },
      {
        id: 6,
        name: 'Код ТН ВЭД ЕАЭС до',
        visible: true,
        width: 192,
      },
      {
        id: 7,
        name: 'Ставка таможенной пошлины (в % от таможенной стоимости, €/$ США) до',
        visible: true,
        width: 232,
      },
      {
        id: 8,
        name: 'Код ТН ВЭД ЕАЭС после',
        visible: true,
        width: 192,
      },
      {
        id: 9,
        name: 'Ставка таможенной пошлины (в % от таможенной стоимости, €/$ США) после',
        visible: true,
        width: 232,
      },
      {
        id: 10,
        name: 'Срок применения меры',
        visible: true,
        width: 226,
      },
      {
        id: 11,
        name: 'Комментарии',
        visible: true,
        width: 232,
      },
      {
        id: 12,
        name: 'Отрасль',
        visible: true,
        width: 192,
      },
      {
        id: 13,
        name: 'Продление',
        visible: true,
        width: 192,
      },
      {
        id: 14,
        name: 'Статус',
        visible: true,
        width: 192,
      },
      {
        id: 15,
        name: 'Направление перемещения',
        visible: true,
        width: 240,
      },
    ],
    tableHeaderSingleOdnostoron: [
      {
        id: 0,
        name: 'Год',
        visible: true,
        width: 138,
      },
      {
        id: 1,
        name: 'Орган',
        visible: true,
        width: 192,
      },
      {
        id: 2,
        name: 'Дата решения',
        visible: true,
        width: 202,
      },
      {
        id: 3,
        name: 'Номер решения',
        visible: true,
        width: 192,
      },
      {
        id: 5,
        name: 'Товар',
        visible: true,
        width: 352,
      },
      {
        id: 6,
        name: 'Код ТН ВЭД ЕАЭС до',
        visible: true,
        width: 192,
      },
      {
        id: 7,
        name: 'Ставка таможенной пошлины (в % от таможенной стоимости, €/$ США) до',
        visible: true,
        width: 232,
      },
      {
        id: 9,
        name: 'Ставка таможенной пошлины (в % от таможенной стоимости, €/$ США) после',
        visible: true,
        width: 232,
      },
      {
        id: 10,
        name: 'Срок применения меры',
        visible: true,
        width: 226,
      },
      {
        id: 11,
        name: 'Комментарии',
        visible: true,
        width: 232,
      },
      {
        id: 12,
        name: 'Отрасль',
        visible: true,
        width: 192,
      },
      {
        id: 13,
        name: 'Продление',
        visible: true,
        width: 192,
      },
      {
        id: 14,
        name: 'Статус',
        visible: true,
        width: 192,
      },
      {
        id: 15,
        name: 'Направление перемещения',
        visible: true,
        width: 240,
      },
    ],
  },
};
