<template>
  <div class="container-page">
    <ul class="tag-list d-flex flex-wrap mb-4">
      <li class="tag page-tag" v-for="(item, index) in navigationTags.filter((x) => !x.hidden)" :key="index">
        <a href="#" class="page-tag-text" v-scroll-to="{ el: item.id, duration: 0, easing: 'linear', lazy: false }">{{
          item.text
        }}</a>
      </li>
    </ul>
    <PageSelector class="mb-4" @pageChange="onPageChange" :pageData="pageData" :countries="countries" />
    <template v-if="pageData.typeId == 1">
      <CountryTagControl @tagClicked="onCountryTagClicked" />
      <WorldHotIndicator />
      <TradeEconomicIndicator />
      <MapContainer :countries="countries" @pageChangeRequest="onPageChangeRequest" @mapChanged="onMapChanged" />
    </template>
    <template v-else-if="pageData.typeId == 2">
      <TecRussia :country-id="pageData.countryId" />
      <EconomicsReview :country-id="pageData.countryId" />
    </template>
    <template v-else-if="pageData.typeId == 3">
      <PartnershipControl :countryId="pageData.countryId" @displayTypeChanged="onDisplayTypeChanged" />
    </template>
    <template v-else-if="pageData.typeId == 4">
      <FinancialPoliticsControl :countryId="pageData.countryId" />
    </template>
    <template v-else-if="pageData.typeId == 5">
      <MarketAccessControl :countryId="pageData.countryId" @indicatorChanged="onIndicatorChanged" />
    </template>
  </div>
</template>

<script>
  import Utils from '@/common/utils';

  import CountryTagControl from '@/modules/countryAnalyticsNew/components/CountryTagControl';
  import EconomicsReview from '@/modules/countryAnalyticsNew/components/EconomicsReview/EconomicsReview';
  import TecRussia from '@/modules/countryAnalyticsNew/components/EconomicsReview/TecRussia';
  import FinancialPoliticsControl from '@/modules/countryAnalyticsNew/components/FinancialPolitics/FinancialPoliticsControl';
  import MapContainer from '@/modules/countryAnalyticsNew/components/MapContainer';
  import MarketAccessControl from '@/modules/countryAnalyticsNew/components/MarketAccess/MarketAccessControl';
  import PageSelector from '@/modules/countryAnalyticsNew/components/PageSelector';
  import PartnershipControl from '@/modules/countryAnalyticsNew/components/Partnership/PartnershipControl';
  import TradeEconomicIndicator from '@/modules/countryAnalyticsNew/components/TradeEconomicIndicator';
  import WorldHotIndicator from '@/modules/countryAnalyticsNew/components/WorldHotIndicator';

  export default {
    name: 'countryAnalytics',
    components: {
      PageSelector,
      WorldHotIndicator,
      TradeEconomicIndicator,
      MapContainer,
      CountryTagControl,
      TecRussia,
      EconomicsReview,
      MarketAccessControl,
      FinancialPoliticsControl,
      PartnershipControl,
    },
    data() {
      return {
        countries: [],
        pageData: {
          typeId: this.$route.params['typeId'] ? this.$route.params['typeId'] : 1,
          countryId: this.$route.params['countryId'] ? this.$route.params['countryId'] : null,
        },
        popStateHandler: null,
        navigationTags: [],
        navigationTagOptions: {
          mapTag: 'Карта проектов',
          projectStatisticsHidden: true,
          indicatorTag:
            'Реестр ограничительных мер, применяемых третьими странами в отношении российских товаров, услуг и инвестиций',
        },
      };
    },
    created() {
      this.popStateHandler = function (event) {
        this.pageData.typeId = event.state?.typeId ?? 1;
        this.pageData.countryId = event.state?.countryId;
        history.replaceState(
          { typeId: this.pageData.typeId, countryId: this.pageData.countryId },
          '',
          window.location.origin + `#/CountryAnalytics/${this.pageData.typeId}/${this.pageData.countryId ?? ''}`,
        );
      };

      window.addEventListener('popstate', this.popStateHandler);

      if (this.pageData.typeId != 1 && !this.pageData.countryId) this.pageData.typeId = 1;
      history.replaceState(
        { typeId: this.pageData.typeId, countryId: this.pageData.countryId },
        '',
        window.location.origin + `#/CountryAnalytics/${this.pageData.typeId}/${this.pageData.countryId ?? ''}`,
      );
      this.loadCountries();
      this.generateNavigationTags();
    },
    beforeUnmount() {
      window.removeEventListener('popstate', this.popStateHandler);
    },
    methods: {
      loadCountries() {
        Utils.searchNsi('countryListWithData', {}).then((response) => {
          this.countries = response.data.map((c) => ({
            id: c.id,
            text: c.name,
            fullName: c.nameFull || c.name,
            code: c.code,
            groupId: c.countryGroupId,
          }));
        });
      },
      onPageChange(data) {
        let path = window.location.origin + `#/CountryAnalytics/${data.page}/${data.country ?? ''}`;
        if (history.state?.typeId != data.page || history.state?.countryId != data.country) {
          history.pushState({ typeId: data.page, countryId: data.country, path: path }, '', path);
          this.pageData = { typeId: data.page, countryId: data.country };
          this.generateNavigationTags();
        } else history.replaceState({ typeId: data.page, countryId: data.country, path: path }, '', path);
      },
      onCountryTagClicked(id) {
        history.pushState({ typeId: 2, countryId: id }, '', window.location.origin + `#/CountryAnalytics/2/${id}`);
        this.pageData.typeId = 2;
        this.pageData.countryId = id;
        this.generateNavigationTags();
      },
      onPageChangeRequest(data) {
        this.pageData.typeId = data.typeId;
        this.pageData.countryId = data.countryId;
        history.pushState(
          { typeId: this.pageData.typeId, countryId: this.pageData.countryId },
          '',
          window.location.origin + `#/CountryAnalytics/${this.pageData.typeId}/${this.pageData.countryId}`,
        );
        this.generateNavigationTags();
      },
      onMapChanged(map) {
        this.navigationTagOptions.mapTag = map.text;
        this.generateNavigationTags();
      },
      onDisplayTypeChanged(typeId) {
        this.navigationTagOptions.projectStatisticsHidden = typeId == 2;
        this.generateNavigationTags();
      },
      onIndicatorChanged(title) {
        this.navigationTagOptions.indicatorTag = title;
        this.generateNavigationTags();
      },
      generateNavigationTags() {
        this.navigationTags = [];
        switch (this.pageData.typeId.toString()) {
          case '1':
            this.navigationTags = [
              { id: '#world-hot-title', text: 'Горячие показатели торговли товарами России с миром' },
              { id: '#trade-economic-title', text: 'Горячие показатели торговли товарами России с миром' },
              { id: '#div-map-selector', text: this.navigationTagOptions.mapTag },
            ];
            break;
          case '2':
            this.navigationTags = [
              { id: '#tec-russia', text: 'Торгово-экономическое сотрудничество с Россией' },
              { id: '#tec-world', text: 'Торгово-экономическое сотрудничество с миром' },
              { id: '#econ-rev-indicators', text: 'Ключевые показатели' },
            ];
            break;
          case '3':
            this.navigationTags = [
              { id: '#project-statistics', text: 'Статистика по проектам' },
              {
                id: '#project-statistics-industries',
                text: 'Распределение проектов по отраслям',
                hidden: this.navigationTagOptions.projectStatisticsHidden,
              },
              {
                id: '#project-statistics-purposes',
                text: 'Распределение проектов по целям',
                hidden: this.navigationTagOptions.projectStatisticsHidden,
              },
              { id: '#mpk-statistics', text: 'Статистика по МПК' },
              { id: '#mpk-statistics', text: 'Сопредседатели' },
              { id: '#mpk-statistics', text: 'Структура МПК' },
            ];
            break;
          case '4':
            this.navigationTags = [
              { id: '#rating-positions', text: 'Позиции в мировых рейтингах' },
              { id: '#budget', text: 'Бюджетная политика' },
              { id: '#currency-course', text: 'Валютный курс' },
              { id: '#monetary-policy', text: 'Денежно-кредитная политика' },
              { id: '#financial-sector', text: 'Финансовый сектор' },
              { id: '#monetary-offer', text: 'Денежное предложение' },
            ];
            break;
          case '5':
            this.navigationTags = [
              { id: '#market-access-indicators', text: 'Ключевые показатели' },
              {
                id: '#market-access-data',
                text: this.navigationTagOptions.indicatorTag,
                hidden: this.navigationTagOptions.indicatorTag == '',
              },
              {
                id: '#membership',
                text: 'Членство страны в региональных блоках, международных организациях/объединениях',
              },
            ];
            break;
          default:
            break;
        }
      },
    },
    computed: {},
  };
</script>

<style lang="scss" scoped>
  .tag-list {
    gap: 10px 20px;
  }
  .tag {
    box-sizing: border-box;
    gap: 4px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
  }

  .page-tag {
    padding: 9px 5px;
    border: 1px solid #0093ba;
    border-radius: 4px;
    font-size: 12px;
    line-height: 4px;
    &-text {
      color: #0093ba;
    }
  }
</style>
