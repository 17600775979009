<template>
  <!-- Тестовая страница мероприятия -->
  <div class="container-page">
    <div class="">
      <!-- title -->
      <p class="title-inner">Матрица прав</p>
      <Table />
    </div>
  </div>
</template>

<script>
  import Table from '../components/TableRolesPermissions.vue';
  export default {
    name: 'Permissions',
    components: { Table },
  };
</script>

<style lang="scss">
  @import '@/assets/theme/default//global/variables.scss';
  @import '@/assets/theme/default//global/mixin.scss';

  .bold-text {
    font-weight: bold;
    color: $black-1;
    font-size: 12px;
    line-height: 15px;
  }
  .admin {
    padding: 16px 0;
  }
</style>
