<template>
  <!-- Тестовая страница для регистра мпк -->
  <div class="container-page">
    <p class="title-inner">Классификатор стран мира</p>
    <div class="mb-4">
      <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
    </div>
    <div class="table-container mb-16 js-table-arrow">
      <table class="stockTable">
        <thead>
          <tr>
            <th>Название</th>
            <th>Полное название</th>
            <th>Код</th>
            <th>Альфа-2</th>
            <th>Альфа-3</th>
            <th>Группа стран</th>
            <th>Название на англ.</th>
            <th>Территория</th>
            <th>Валюта</th>
            <th>Статус</th>
            <th></th>
          </tr>
          <tr>
            <th class="filter" colspan="2">
              <FilterInput v-model="filter.name" placeholder="Найти..." v-on:valueChangedDebounced="changeFilter" />
            </th>
            <th class="filter">
              <FilterInput v-model="filter.code" type="integer" v-on:valueChangedDebounced="changeFilter" />
            </th>
            <th class="filter">
              <FilterInput v-model="filter.codeAlpha2" v-on:valueChangedDebounced="changeFilter" />
            </th>
            <th class="filter">
              <FilterInput v-model="filter.codeAlpha3" v-on:valueChangedDebounced="changeFilter" />
            </th>
            <th class="filter">
              <FilterInput
                type="select2"
                :select2-options="countryGroupList"
                :select2-settings="select2Settings"
                v-model="filter.countryGroupId"
                placeholder="(Все)"
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th class="filter">
              <FilterInput v-model="filter.nameEng" v-on:valueChangedDebounced="changeFilter" />
            </th>
            <th class="filter">
              <FilterInput
                v-model="filter.territoryFrom"
                v-on:valueChangedDebounced="changeFilter"
                type="number"
                placeholder="От"
              />
              <FilterInput
                v-model="filter.territoryTo"
                v-on:valueChangedDebounced="changeFilter"
                type="number"
                placeholder="До"
              />
            </th>
            <th class="filter">
              <FilterInput v-model="filter.currency" v-on:valueChangedDebounced="changeFilter" />
            </th>
            <th class="filter">
              <FilterActiveInput
                v-model="filter.isActive"
                placeholder="(Все)"
                v-on:valueChangedDebounced="changeFilter"
              />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="page.items?.length && !isLoading">
          <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item.id)">
            <td class="d-flex align-items-center">
              <div
                v-if="item.picture"
                class="imagePreviewWrapper mr-8"
                :style="{ 'background-image': `url(${item.picture})` }"
              ></div>
              {{ item.name }}
            </td>
            <td>{{ item.nameFull }}</td>
            <td>{{ item.code }}</td>
            <td>{{ item.codeAlpha2 }}</td>
            <td>{{ item.codeAlpha3 }}</td>
            <td>{{ item.countryGroup?.name }}</td>
            <td>{{ item.nameEng }}</td>
            <td>{{ item.territory }}</td>
            <td>{{ item.currency }}</td>
            <td>{{ item.isActive ? 'действ.' : 'не действ.' }}</td>
            <td>
              <IconComponent @click="editCard(item.id)" :name="canEdit ? 'mode' : 'paper'" />
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="isLoading">
          <tr>
            <td class="text-center" colspan="9">Загрузка...</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="9">Нет данных.</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="page.pagesTotal > 1">
      <Pagination
        :page-count="page.pagesTotal"
        :current-page="page.pageNumber"
        @change="onPageChange($event, false)"
        @more="onPageChange($event, true)"
      />
    </div>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import API from '@/modules/nsi/api/country';

  import FilterActiveInput from '../../../common/components/FilterActiveInput';
  import FilterInput from '../../../common/components/FilterInput';

  export default {
    name: 'Countries',
    components: {
      FilterActiveInput,
      FilterInput,
      IconComponent,
      DefaultFilter,
    },
    mixins: [filtermanager, security],
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          includePicture: true,
        },
        filter: {
          name: null,
          nameEng: null,
          code: null,
          codeAlpha2: null,
          codeAlpha3: null,
          countryGroupId: null,
          isActive: null,
          territoryFrom: null,
          territoryTo: null,
          currency: null,
        },
        page: {},
        isLoading: false,
        canEdit: true,
        countryGroupList: [],
        select2Settings: Constants.select2Settings,
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.CountryEdit).then((response) => {
        console.log(response.data);
        this.canEdit = response.data;
      });

      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadDictionaries();
        this.loadPage(false);
      });
    },
    methods: {
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(append) {
        this.isLoading = !append;
        const request = Object.assign(this.filter, this.request);
        API.search(request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              //добавляем к существующему
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      loadDictionaries() {
        Utils.loadSelectOptions('CountiesGroupList', this.countryGroupList);
      },
      editCard(id) {
        this.$router.push({ name: 'CountryCard', params: { id: id, action: this.canEdit ? 'edit' : 'view' } });
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .imagePreviewWrapper {
    width: 50px;
    height: 25px;
    display: block;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
  }
</style>
