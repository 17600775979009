<template>
  <div class="pie-indicator">
    <IconComponent class="mb-2" :style="iconStyle" :name="icon" />
    <p class="mb-2">{{ description }}</p>
    <strong>
      {{ summaryValue == 0 ? '-' : formatNumber(summaryValue, 0) }}
      млн $ США
    </strong>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';
  import IconComponent from '@/common/ui/IconComponent';

  export default {
    name: 'InvestmentsValueVolume',
    components: { IconComponent },
    mixins: [text],
    props: {
      description: {
        type: String,
        default: '',
      },
      summaryValue: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: 'pie-chart',
      },
      iconStyle: {
        type: Object,
        default: () => {},
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pie-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4px;
    p {
      font: $fira-12;
      font-style: normal;
      line-height: 113.9%;
      text-align: center;
      color: #333333;
    }
    strong {
      font: $fira-12-B;
      font-style: normal;
      text-align: center;
      color: #333333;
    }
  }
</style>
