<template>
  <div :class="['icon', mod ? `icon--${mod}` : '']" />
</template>

<script>
  const cache = new Map();
  export default {
    name: 'IconComponent',
    props: {
      name: {
        type: String,
        required: true,
      },
      mod: {
        type: String,
        validator(value) {
          return ['right'].includes(value);
        },
      },
    },
    watch: {
      name: function () {
        this.load();
      },
    },
    mounted() {
      this.load();
    },
    methods: {
      async load() {
        if (!cache.has(this.name)) {
          try {
            const component = require(`@/assets/images/svg/${this.name}.svg`);
            cache.set(
              this.name,
              fetch(component).then((r) => r.text()),
            );
          } catch (e) {
            cache.delete(this.name);
          }
        }
        if (cache.has(this.name)) {
          this.$el.innerHTML = await cache.get(this.name);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &--right {
      margin: auto 0 auto 4px;
      width: 16px;
    }

    &--hide-rating {
      transform: scale(1, -1);

      .button-redesign--more & {
        width: 12px;
        height: 12px;
      }
    }
  }
</style>
