<template>
  <div class="switch-back" @click="switchHandler">
    <slot name="one" v-bind="{ textTrue, active }">
      <button class="one" :class="{ active: active }">{{ textTrue }}</button>
    </slot>
    <slot name="two" v-bind="{ textFalse, active }">
      <button class="two" :class="{ active: !active }">{{ textFalse }}</button>
    </slot>
  </div>
</template>

<script>
  export default {
    name: 'RedesignedSwitchComponent',
    props: {
      checked: {
        type: Boolean,
        default: true,
      },
      textTrue: {
        type: String,
        default: 'Товары',
      },
      textFalse: {
        type: String,
        default: 'Услуги',
      },
    },
    data() {
      return {
        active: this.checked,
      };
    },
    methods: {
      switchHandler() {
        this.active = !this.active;
        this.$emit('switchHandler', this.active);
      },
    },
  };
</script>

<style scoped lang="scss">
  .switch-back {
    display: inline-flex;
    background-color: $grey;
    padding: 2px;
    border-radius: 4px;
    flex-wrap: nowrap;
    height: 32px;

    &--big {
      height: auto;
      .one,
      .two {
        font: $fira-16;
        padding: 4px 16px;
      }
    }

    @media (max-width: $mobile) {
      &--big {
        width: 100%;

        .one,
        .two {
          width: 50%;
        }
      }
    }
  }
  .one,
  .two {
    color: $blue-dim;
    background-color: $grey;
    border: none;
    font-size: 16px;
    min-width: 88px;
    transition: all 0.3s ease;
  }
  .active {
    background-color: #fff;
    border-radius: 4px;
    color: $blue;
  }
</style>
