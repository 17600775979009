import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/scenario/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/scenario/find/${id}`);
  },
  update: function (data) {
    if (data.id > 0) {
      return axios.put(`/api/scenario/${data.id}`, data);
    } else {
      return axios.post('/api/scenario', data);
    }
  },
  delete: function (id) {
    return axios.delete(`/api/scenario/${id}`);
  },
  getElemList(route, request) {
    request = Object.assign({ pageNumber: 1, pageSize: 0 }, request);
    return axios.get(`/api/${route}/search`, { params: request });
  },
  async exportExcel(request) {
    return Utils.downloadFile('/api/scenario/exportExcel', 'Scenarios.xlsx', request);
  },
  async exportWord(request) {
    return Utils.downloadFile('/api/scenario/exportWord', 'Scenarios.docx', request);
  },
  clone: function (data) {
    return axios.post('/api/scenario/clone', data);
  },
};
