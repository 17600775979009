export function setMpkProjectDepartment(state, res) {
  const { data = {} } = res;
  state.mpkProjectDepartments = data?.items;
}

export function setMpkProjectMinRole(state, res) {
  const { data = [] } = res;
  state.mpkProjectMinRoles = data;
}

export function setMpkProjectList(state, res) {
  const { data = [] } = res;
  state.mpkListProjects = data;
}

export function setMpkProjectSupportMeasure(state, res) {
  const { data = [] } = res;
  state.mpkProjectSupportMeasures = data;
}

export function setMpkProjectCategoryList(state, res) {
  const { data = [] } = res;
  state.mpkProjectCategories = data;
}

export function setMpkProjectTypeEntry(state, res) {
  const { data = [] } = res;
  state.mpkProjectTypeEntries = data;
}

export function setMpkProjectSearchLight(state, res) {
  const { data } = res;
  state.lightSearchMpkProjects = data?.items;
}

export function setMpkProjectSearch(state, res) {
  const { data } = res;
  state.searchMpkProjects = data?.items;
}
