import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    currentBalance: [],
    financialBalance: [],
    legendCurrentBalance: [],
    legendFinancialBalance: [],
    infoGraphCurrentBalance: {
      seriesProps: [
        {
          data: [],
          color: '#231F20',
        },
      ],
      categories: [],
      tooltipCategories: [],
    },
    infoGraphFinancialBalance: {
      seriesProps: [
        {
          data: [],
          color: '#231F20',
        },
      ],
      categories: [],
      tooltipCategories: [],
    },
    infoReserveAssetBalance: {
      seriesProps: [
        {
          data: [],
          color: '#231F20',
        },
      ],
      categories: [],
      tooltipCategories: [],
    },
    infoPureErrorsAndOmissions: {
      seriesProps: [
        {
          data: [],
          color: '#231F20',
        },
      ],
      categories: [],
      tooltipCategories: [],
    },
    loadCurrentBalance: true,
    loadFinancialBalance: true,
    loadReserveAssetBalance: false,
    loadPureErrorsAndOmissions: false,
    yearList: [],
  },
};

export const COUNTRY_ANALYTICS_PAYMENTS = 'countryAnalyticsPayments';

export const COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME = {
  getCurrentBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getCurrentBalance`,
  getFinancialBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getFinancialBalance`,
  getInfoCurrentBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getInfoCurrentBalance`,
  getInfoFinancialBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getInfoFinancialBalance`,
  getInfoReserveAssetBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getInfoReserveAssetBalance`,
  getInfoPureErrorsAndOmissions: `${COUNTRY_ANALYTICS_PAYMENTS}/getInfoPureErrorsAndOmissions`,
  fetchYearList: `${COUNTRY_ANALYTICS_PAYMENTS}/fetchYearList`,
};

export const COUNTRY_ANALYTICS_PAYMENTS_MUTATIONS_NAME = {
  setCurrentBalanceList: 'setCurrentBalanceList',
  setFinancialBalanceList: 'setFinancialBalanceList',
  updateInfoCurrentBalance: 'updateInfoCurrentBalance',
  updateInfoFinancialBalance: 'updateInfoFinancialBalance',
  changeStateLoadCurrentBalance: 'changeStateLoadCurrentBalance',
  changeStateLoadFinancialBalance: 'changeStateLoadFinancialBalance',
  changeStateLoad: 'changeStateLoad',
  toggleHiddenGraph: 'toggleHiddenGraph',
  legendResetState: 'legendResetState',
  updateInfoReserveAssetBalance: 'updateInfoReserveAssetBalance',
  updateInfoPureErrorsAndOmissions: 'updateInfoPureErrorsAndOmissions',
  setYearList: 'setYearList',
};

export const COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME = {
  getListCurrentBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getListCurrentBalance`,
  getListFinancialBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getListFinancialBalance`,
  getLegendCurrentBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getLegendCurrentBalance`,
  getLegendFinancialBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getLegendFinancialBalance`,
  getInfoGraphCurrentBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getInfoGraphCurrentBalance`,
  getInfoGraphFinancialBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getInfoGraphFinancialBalance`,
  getInfoGraphReserveAssetBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getInfoGraphReserveAssetBalance`,
  getInfoGraphPureErrorsAndOmissions: `${COUNTRY_ANALYTICS_PAYMENTS}/getInfoGraphPureErrorsAndOmissions`,
  getLoadCurrentBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getLoadCurrentBalance`,
  getLoadFinancialBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getLoadFinancialBalance`,
  getLoadReserveAssetBalance: `${COUNTRY_ANALYTICS_PAYMENTS}/getLoadReserveAssetBalance`,
  getLoadPureErrorsAndOmissions: `${COUNTRY_ANALYTICS_PAYMENTS}/getLoadPureErrorsAndOmissions`,
  getYearList: `${COUNTRY_ANALYTICS_PAYMENTS}/getYearList`,
};
