<template>
  <div class="container-page">
    <p class="title-inner">
      Реестр ограничительных мер, применяемых третьими странами в отношении российских товаров, услуг и инвестиций
    </p>
    <Restriction v-bind:country-id="parseInt(countryId)" />
  </div>
</template>

<script>
  import Restriction from './components/Restriction.vue';

  export default {
    name: 'AccessConditions',
    components: { Restriction },
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
  };
</script>
