<template>
  <div class="justify-content-between">
    <div
      v-if="clearable && previewImage"
      class="clearable fa fa-window-close"
      title="Удалить"
      @click="$emit('pictureSelected', null)"
    ></div>
    <div
      v-if="previewImage"
      class="imagePreviewWrapper"
      :class="isRound ? 'rounded50' : ''"
      :style="{ 'background-image': `url(${previewImage})` }"
      @click="selectImage"
    ></div>
    <div v-else class="imagePreviewWrapper nophoto" @click="selectImage"></div>
    <input v-if="!readOnly" class="col" ref="fileInput" type="file" @input="pickFile" />
  </div>
</template>

<script>
  import Constants from '@/common/constants';

  export default {
    name: 'PictureUpload',
    data() {
      return {
        previewImage: null,
      };
    },
    props: {
      initialPhoto: {
        type: String,
        default: null,
      },
      fileMaxSize: {
        type: Number,
        default: 65536,
      },
      isRound: {
        type: Boolean,
        default: true,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      additionalData: {
        type: Object,
        default: null,
      },
    },
    emits: ['pictureSelected'],
    mounted() {
      if (this.initialPhoto) {
        this.previewImage = this.initialPhoto;
      } else {
        this.previewImage = null;
      }
    },
    watch: {
      initialPhoto(newValue) {
        this.previewImage = newValue;
      },
    },
    methods: {
      selectImage() {
        if (this.readOnly) return;
        this.$refs.fileInput.click();
      },
      pickFile() {
        let input = this.$refs.fileInput;
        let file = input.files;
        if (file && file[0]) {
          if (file[0].size > this.fileMaxSize) {
            Constants.alert.fire(
              'Прикрепление фото',
              `Размер файла не должен превышать ${(this.fileMaxSize / 1024).toFixed(0)} КБ.`,
              'error',
            );
            return;
          }
          let reader = new FileReader();
          reader.onload = (e) => {
            if (e.target.result.startsWith('data:image/')) {
              this.previewImage = e.target.result;
              this.$emit('pictureSelected', e.target.result, this.additionalData);
            } else {
              Constants.alert.fire('Прикрепление фото', 'Данный формат файла не поддерживается', 'error');
            }
            //   console.log(e, '--------picture');
            // this.previewImage = e.target.result;
            // this.$emit('pictureSelected', e.target.result)
          };
          reader.readAsDataURL(file[0]);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .nophoto {
    background-image: url(~@/assets/images/no_photo.png);
    color: black;
  }

  .imagePreviewWrapper {
    width: 150px;
    height: 150px;
    display: block;
    cursor: pointer;
    margin: 0 auto 30px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .clearable {
    float: right;
    cursor: pointer;
    margin-right: 10px;
    width: 10px;
    height: 10px;
  }
</style>
