<template>
  <NsiCard :pageTitle="pageTitle" :apiRoute="apiRoute" :cardName="cardName" :listName="listName" />
</template>

<script>
  import NsiCard from '../components/NsiCard';

  export default {
    name: 'MassMediaCategoryCard',
    components: {
      NsiCard,
    },
    data() {
      return {
        pageTitle: 'Анализ СМИ. Категории',
        apiRoute: 'massMediaCategory',
        cardName: 'MassMediaCategoryCard',
        listName: 'MassMediaCategory',
      };
    },
    created() {},
    methods: {},
  };
</script>
