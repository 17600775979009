import MassMediaCard from '../views/MassMediaCard';
import News from '../views/News';
import NewsCard from '../views/NewsCard';

export const routes = [
  {
    path: '/media',
    name: 'News',
    component: News,
  },
  {
    path: '/media/edit/:typeId/:id',
    name: 'NewsCard',
    component: NewsCard,
  },
  {
    path: '/massMedia/:action/:id',
    name: 'MassMediaCard',
    component: MassMediaCard,
  },
];
