<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Каталог товаров</p>
      <div class="mb-16">
        <ButtonStock class="mr-2" title="Создать" @click="editCard(0)" />
        <ButtonStock
          :disabled="importInProgress"
          :title="!importInProgress ? 'Импорт' : 'Загрузка...'"
          class="mr-2"
          @click="importFile()"
        />
        <ButtonStock class="mr-2" title="Экспорт" @click="exportExcel()" />
        <ButtonStock title="Несырьевой неэнергетический экспорт" @click="$router.push({ name: 'TnvedNonEnergy' })" />
      </div>
      <div class="mb-4">
        <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
      </div>
      <div v-if="page.items">
        <div class="table-container mb-16 js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th>Наименование</th>
                <th>Код</th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <th class="filter">
                  <FilterInput
                    v-model="filter.name"
                    placeholder="Найти по наименованию..."
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    v-model="filter.code"
                    placeholder="Найти по коду..."
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="page.items.length">
              <tr v-for="item in page.items" :key="item.id">
                <td
                  :class="{ textupper: item.level === 1, textbold: !item.isLeaf }"
                  :style="{ paddingLeft: item.level * 10 + 'px' }"
                >
                  <i v-if="!item.isLeaf" class="fas fa-folder"></i>
                  {{ item.name }}
                </td>
                <td>{{ item.code }}</td>
                <td>
                  <IconComponent @click="editCard(item.id)" name="mode" />
                </td>
                <td>
                  <IconComponent @click="deleteCard(item.id)" name="delete" />
                </td>
              </tr>
            </tbody>
            <tbody v-if="!page.items.length">
              <tr>
                <td class="text-center" colspan="4">Нет данных.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="page.pagesTotal > 1">
          <Pagination
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
      </div>
    </div>
  </div>
  <section>
    <ModalComponent v-model="importModal" title="Импорт файла" @close="closeModal">
      <ImportModal @doimport="doImport" accept-type=".csv" @close="closeModal" />
    </ModalComponent>
  </section>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';

  import ImportModal from '@/components/modals/ImportModal';

  import FilterInput from '../../../common/components/FilterInput';
  import IconComponent from '../../../common/ui/IconComponent';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import ModalComponent from '../../../components/modals/ModalComponent';
  import API from '../api/nsi';

  export default {
    name: 'Tnved',
    components: { FilterInput, IconComponent, ButtonStock, DefaultFilter, ImportModal, ModalComponent },
    mixins: [filtermanager],
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          name: null,
          code: null,
        },
        importModal: false,
        importInProgress: false,
      };
    },
    created() {
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadPage(false);
      });
    },
    methods: {
      loadPage(append) {
        const request = Object.assign(this.filter, this.request);
        API.search('nsitnved', request).then((response) => {
          if (!append) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
        });
      },
      editCard(id) {
        this.$router.push({ name: 'TnvedCard', params: { id: id, action: 'edit' } });
      },
      deleteCard(id) {
        console.log(id);
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete('nsitnved', id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      importFile() {
        this.importModal = true;
      },
      doImport(file) {
        this.closeModal();
        this.importInProgress = true;
        API.importFile('nsitnved', file)
          .then(() => {
            Constants.alert.fire('Импорт', 'Процесс импорта запущен. Ожидайте уведомление о результате.', 'success');
            this.loadPage();
            this.importInProgress = false;
          })
          .catch((error) => {
            console.log(error?.response?.data);
            Constants.alert.fire('Импорт', 'Ошибка при импорте данных.', 'error');
            this.importInProgress = false;
          });
      },
      closeModal() {
        this.importModal = false;
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
      exportExcel() {
        API.exportExcel('NsiTnved');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    color: #01a39d;
  }
  .textupper {
    text-transform: uppercase;
  }
  .textbold {
    font-weight: bold;
  }
</style>
