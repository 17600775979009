function fdRecurcive(formData, key, value) {
  // на данный момент используется только по условию value instanceof File
  // если бэк принимает данные подругому, можно изменить
  // но не забудте оповестить коллег!!!

  if (Array.isArray(value)) {
    value.forEach((val, index) => {
      fdRecurcive(formData, `${key}[${index}]`, val);
    });
  } else if (value instanceof File) {
    formData.append(key, value);
  } else if (value instanceof Object) {
    Object.entries(value).forEach(([k, val]) => fdRecurcive(formData, `${key}[${k}]`, val));
  } else {
    formData.append(key, value);
  }

  return formData;
}

export default function getFormData(params) {
  const formData = new FormData();

  for (const key in params) {
    const value = params[key];
    const name = key;

    fdRecurcive(formData, name, value);
  }

  return formData;
}
