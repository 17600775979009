export function setInfoPage(state, res) {
  state.infoPage = res.data;
  state.loadPage = false;
}

export function addInfoPage(state, res) {
  state.infoPage.firstItemNumber = res.data.firstItemNumber;
  state.infoPage.itemCount = res.data.itemCount;
  state.infoPage.itemsTotal = res.data.itemsTotal;
  state.infoPage.pageNumber = res.data.pageNumber;
  state.infoPage.pagesTotal = res.data.pagesTotal;
  state.infoPage.items = state.infoPage.items.concat(res.data.items);
  state.loadPage = false;
}

export function changeStateLoadPage(state, params) {
  state.loadPage = params;
}
