export function getCard(state) {
  return state.card;
}

export function getListOrganizations(state) {
  return state.listOrganizations;
}

export function getListDocuments(state) {
  return state.listDocuments;
}

export function getListLegalSources(state) {
  return state.listLegalSources;
}
