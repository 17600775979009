// eslint-disable-next-line no-undef
__webpack_nonce__ = window.__webpack_nonce__;

import { createApp } from 'vue';
import VueScrollTo from 'vue-scrollto';
import VueSmartWidget from 'vue-smart-widget';
import VueSnip from 'vue-snip';
import VueApexCharts from 'vue3-apexcharts';
import datepicker from 'vue3-datepicker';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import Select2 from 'vue3-select2-component';
import { QuillEditor } from '@vueup/vue-quill';
// https://floating-vue.starpad.dev/
import FloatingVue from 'floating-vue';
import { Tooltip, vTooltip } from 'floating-vue';
import { GDialog } from 'gitart-vue-dialog';
import moment from 'moment';
import VCalendar from 'v-calendar';
import 'jquery/dist/jquery.min';
import 'jquery-ui-dist/jquery-ui.min';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'admin-lte/dist/js/adminlte.min';
import 'admin-lte/dist/css/adminlte.min.css';
import 'floating-vue/dist/style.css';
import 'jquery-ui-dist/jquery-ui.min.css';
import 'select2/dist/js/i18n/ru';
import 'vue-smart-widget/lib/style.css';
import '../plugins/chart';
import 'gitart-vue-dialog/dist/style.css';
import 'v-calendar/dist/style.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';

import { configAxios } from '@/common/axios';
import EmptyListMessage from '@/common/components/EmptyListMessage';
import Pagination from '@/common/components/Pagination';
import directiveClickOutside from '@/common/directives/click-outside.js';
import { permission } from '@/common/directives/permission';

import App from './App.vue';
import router from './router';
import store from './store';
import tableArrow from '/plugins/table-arrow/index';
import tableArrowNew from '/plugins/table-arrow-new/index';

import './assets/theme/default/style.scss';

configAxios();
const app = createApp(App).use(store).use(router);
app.use(VueSmartWidget);
app.use(VueApexCharts);
app.use(VCalendar, {});
app.use(VueScrollTo);
//стрелочки на таблицах, {speed: 45, selector: '.table-container'}
// можно добавить опции скорость прокрутки и контейнер(стоит по умолчанию)
app.use(tableArrow, { speed: 45, selector: '.js-table-arrow' });
//стрелочки на таблицах, {speed: 45, selector: '.table-container-new'}
// можно добавить опции скорость прокрутки и контейнер(стоит по умолчанию)
app.use(tableArrowNew);
app.config.globalProperties.$momentFormat = (date, format) =>
  date != null ? moment(date).locale('ru').format(format) : '';
app.component('GDialog', GDialog);
app.component('EmptyListMessage', EmptyListMessage);
app.component('Pagination', Pagination);
app.component('Select2', Select2);
app.component('datepicker', datepicker);
app.component('apexchart', VueApexCharts);
app.component('VTooltip', Tooltip);
app.use(VueSnip);
app.component('QuillEditor', QuillEditor);
app.use(PerfectScrollbar);
app.use(FloatingVue);
app.mount('#app');

app.directive('click-outside', directiveClickOutside);
app.directive('tooltip', vTooltip);
app.directive('permission', permission);
