export function getMpkProjectDepartments(state) {
  return state.mpkProjectDepartments.map(({ id, name }) => ({ id, text: name }));
}

export function getMpkProjectMinRoles(state) {
  return state.mpkProjectMinRoles;
}

export function getMpkListProjects(state) {
  return state.mpkListProjects;
}

export function getMpkProjectSupportMeasures(state) {
  return state.mpkProjectSupportMeasures;
}

export function getMpkProjectCategories(state) {
  return state.mpkProjectCategories;
}

export function getMpkProjectTypeEntries(state) {
  return state.mpkProjectTypeEntries;
}

export function getLightSearchMpkProjects(state) {
  return state.lightSearchMpkProjects;
}

export function getSearchMpkProjects(state) {
  return state.searchMpkProjects;
}
