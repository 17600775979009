<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Организации</p>
      <div class="mb-16">
        <ButtonStock title="Создать" @click="editCard(0)" v-if="canEdit" />
        <ButtonStock
          v-if="$store.state.auth?.oidc?.profile.roleIds.includes(1)"
          :title="!importInProgress ? 'Обновить' : 'Загрузка...'"
          class="ml-2"
          :disabled="importInProgress"
          @click="refresh"
        />
      </div>
      <div class="mb-4">
        <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
      </div>
      <div class="table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>Название</th>
              <th>Полное название</th>
              <th>Тип</th>
              <th>Статус</th>
              <th>Дата создания</th>
              <th>Дата изменения</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th class="filter" colspan="2">
                <FilterInput v-model="filter.name" placeholder="Найти..." v-on:valueChangedDebounced="changeFilter" />
              </th>
              <th class="filter">
                <FilterInput
                  type="select2"
                  :select2-options="orgTypes"
                  :select2-settings="select2SettingsMulti"
                  v-model="filter.typeIds"
                  multiple
                  placeholder="(Все)"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter">
                <FilterActiveInput
                  v-model="filter.isActive"
                  placeholder="(Все)"
                  v-on:valueChangedDebounced="changeFilter"
                />
              </th>
              <th class="filter date-w-135">
                <FilterInput
                  type="date"
                  modClass="filter-small"
                  v-model="filter.createdStart"
                  v-on:valueChangedDebounced="changeFilter"
                  :upperLimit="upperLimitCreationStart"
                  dateIcon
                />
                <FilterInput
                  type="date"
                  modClass="filter-small"
                  v-model="filter.createdEnd"
                  v-on:valueChangedDebounced="changeFilter"
                  :upperLimit="upperLimitEnd"
                  :lowerLimit="lowerLimitCreationEnd"
                  dateIcon
                />
              </th>
              <th class="filter date-w-135">
                <FilterInput
                  type="date"
                  modClass="filter-small"
                  v-model="filter.modifiedStart"
                  v-on:valueChangedDebounced="changeFilter"
                  :upperLimit="upperLimitModifiedStart"
                  dateIcon
                />
                <FilterInput
                  type="date"
                  modClass="filter-small"
                  v-model="filter.modifiedEnd"
                  v-on:valueChangedDebounced="changeFilter"
                  :upperLimit="upperLimitEnd"
                  :lowerLimit="lowerLimitModifiedEnd"
                  dateIcon
                />
              </th>
            </tr>
          </thead>
          <tbody v-if="page.items?.length && !isLoading">
            <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item.id)">
              <td>
                <IconComponent @click="editCard(item.id)" name="mode" v-if="canEdit" />
              </td>
              <td>
                <IconComponent @click="deleteCard(item.id)" name="delete" />
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.nameFull }}</td>
              <td>{{ item.organizationType.name }}</td>
              <td>{{ item.isActive ? 'действ.' : 'не действ.' }}</td>
              <td class="table-sort__td column-date-creation">
                <p>
                  {{ $momentFormat(item?.created, DATE_FORMATS['DD.MM.YYYY']) }}
                  <br />
                  {{ $momentFormat(item?.created, DATE_FORMATS['HH:mm']) }}
                </p>
              </td>
              <td class="table-sort__td column-date-change">
                <p>
                  {{ $momentFormat(item?.modified, DATE_FORMATS['DD.MM.YYYY']) }}
                  <br />
                  {{ $momentFormat(item?.modified, DATE_FORMATS['HH:mm']) }}
                </p>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="isLoading">
            <tr>
              <td class="text-center" colspan="8">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="8">Нет данных.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="page.pagesTotal > 1">
        <Pagination
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import dateFormat from '@/modules/navigator/mixin/dateFormat.js';

  import FilterActiveInput from '../../../common/components/FilterActiveInput';
  import FilterInput from '../../../common/components/FilterInput';
  import API from '../api/organization';

  export default {
    name: 'Organizations',
    components: {
      FilterActiveInput,
      FilterInput,
      ButtonStock,
      IconComponent,
      DefaultFilter,
    },
    mixins: [filtermanager, security, dateFormat],
    data() {
      return {
        defaultTypeIds: this.isAdminSection()
          ? [
              Constants.organizationType.foiv,
              Constants.organizationType.external,
              Constants.organizationType.regionalOrInternational,
            ]
          : [Constants.organizationType.navigatorParticipant],
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
          typeIds: [],
        },
        filter: {
          name: null,
          typeIds: null,
          isActive: null,
          createdStart: '',
          createdEnd: '',
          modifiedStart: '',
          modifiedEnd: '',
        },
        page: {},
        isLoading: false,
        canEdit: false,
        orgTypes: [],
        select2SettingsMulti: Constants.select2SettingsMultiple,
        importInProgress: false,
        DATE_FORMATS: Constants.DATE_FORMATS,
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.OrgEdit).then((response) => {
        this.canEdit = response.data;
      });
      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter;
        }
        this.loadDictionaries();
        this.loadPage();
      });
    },
    methods: {
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(append) {
        this.isLoading = !append;
        let request = Object.assign({}, Object.assign(this.request, this.filter));
        if (!request.typeIds?.length) {
          request.typeIds = this.defaultTypeIds;
        }
        API.search(request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
                console.log('this.page', this.page);
              });
            } else if (response.data) {
              //добавляем к существующему
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      loadDictionaries() {
        API.getOrgTypes()
          .then((response) => {
            this.orgTypes = response.data
              .filter((x) => this.defaultTypeIds.includes(x.id))
              .map((x) => ({ text: x.name, id: x.id }));
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      editCard(id) {
        const action = this.canEdit ? 'edit' : 'view';
        const routeName = this.isAdminSection() ? 'OrganizationCard' : 'NavOrgCard';
        this.$router.push({ name: routeName, params: { id: id, action: action } });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(id)
              .then(() => {
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      refresh() {
        const options = {
          title: 'Обновление',
          text: 'Вы действительно хотите обновить организации данными от внешнего сервиса?',
          confirmButtonText: 'Обновить',
          cancelButtonText: 'Отмена',
          showCancelButton: true,
          icon: 'warning',
        };
        Constants.alert.fire(options).then((res) => {
          if (res.isConfirmed) {
            this.importInProgress = true;
            API.refresh()
              .then((response) => {
                this.importInProgress = false;
                this.loadPage();
                let message = `Обновление завершено.<br/>Обновлено записей: ${response.data.updatedCount}`;
                if (response.data.updatedCount === 0 && response.data.isNetworkError) {
                  message +=
                    '<br/><br/>Ни одной записи не было обновлено. Возможно, сервис ЕГРЮЛ недоступен.<br/>Подробности ошибок см. в логе сервиса.';
                }
                Constants.alert.fire('Обновление', message, response.data.updatedCount > 0 ? 'success' : 'warning');
              })
              .catch(() => {
                this.importInProgress = false;
                Constants.alert.fire('Обновление', 'Ошибка при обновлении организаций.', 'error');
              });
          }
        });
      },
      isAdminSection() {
        return this.$route.path.indexOf('admin') > -1;
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
  };
</script>

<style scoped>
  .stockTable {
    min-height: 65vh;
  }
</style>
