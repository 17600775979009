import axios from 'axios';

import { authAxios } from '@/common/axios';

export default {
  search: function (request) {
    return authAxios.get('/api/role/search', { params: request });
  },
  find: function (id) {
    return authAxios.get(`/api/role/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return authAxios.put(`/api/role/${data.id}`, data);
    else return authAxios.post(`/api/role/`, data);
  },
  delete: function (id) {
    return authAxios.delete(`/api/role/${id}`);
  },
  searchFilters: function (request) {
    return axios.get('/api/filter/search', { params: request });
  },
  deleteFilter: function (id) {
    return axios.delete(`/api/filter/delete?id=${id}`);
  },
};
