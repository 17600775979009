<template>
  <div class="payment-balance">
    <div class="payment-balance__source">Источник: МВФ</div>
    <PaymentIndicatorItem
      titleName="Счет текущих операций и компоненты"
      :countryName="getCountryName"
      :optionList="getListCurrentBalance"
      :yearList="getYearListOptions"
      :balanceProps="currentBalance"
      :oldBalanceProps="oldCurrentBalance"
      :load="getLoadCurrentBalance"
      :seriesProps="getInfoGraphCurrentBalance.seriesProps"
      :categories="getInfoGraphCurrentBalance.categories"
      :strokeWidth="getInfoGraphCurrentBalance.strokeWidth"
      :tooltipCategories="getInfoGraphCurrentBalance.tooltipCategories"
      :legend="getLegendCurrentBalance"
      @applyParameters="applyParametersCurrent"
      @onClickLegend="onClickLegendCurrent"
      @resetStateLegend="resetStateLegend('legendCurrentBalance')"
    />
    <PaymentIndicatorItem
      titleName="Финансовый счет и компоненты"
      :countryName="getCountryName"
      :optionList="getListFinancialBalance"
      :yearList="getYearListOptions"
      :balanceProps="financialBalance"
      :oldBalanceProps="oldFinancialBalance"
      :load="getLoadFinancialBalance"
      :seriesProps="getInfoGraphFinancialBalance.seriesProps"
      :categories="getInfoGraphFinancialBalance.categories"
      :strokeWidth="getInfoGraphFinancialBalance.strokeWidth"
      :tooltipCategories="getInfoGraphFinancialBalance.tooltipCategories"
      :legend="getLegendFinancialBalance"
      @applyParameters="applyParametersFinancial"
      @onClickLegend="onClickLegendFinancial"
      @resetStateLegend="resetStateLegend('legendFinancialBalance')"
    />
    <PaymentIndicatorItem
      titleName="Сальдо резервных активов"
      :countryName="getCountryName"
      :load="getLoadReserveAssetBalance"
      :yearList="getYearListOptions"
      :seriesProps="getInfoGraphReserveAssetBalance.seriesProps"
      :categories="getInfoGraphReserveAssetBalance.categories"
      :strokeWidth="getInfoGraphReserveAssetBalance.strokeWidth"
      :tooltipCategories="getInfoGraphReserveAssetBalance.tooltipCategories"
      @applyParameters="applyParametersReserveAssetBalance"
    />
    <PaymentIndicatorItem
      titleName="Чистые ошибки и пропуски"
      :countryName="getCountryName"
      :load="getLoadPureErrorsAndOmissions"
      :yearList="getYearListOptions"
      :seriesProps="getInfoGraphPureErrorsAndOmissions.seriesProps"
      :categories="getInfoGraphPureErrorsAndOmissions.categories"
      :strokeWidth="getInfoGraphPureErrorsAndOmissions.strokeWidth"
      :tooltipCategories="getInfoGraphPureErrorsAndOmissions.tooltipCategories"
      @applyParameters="applyParametersPureErrorsAndOmissions"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  import {
    COUNTRY_ANALYTICS_PAYMENTS,
    COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME,
    COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME,
    COUNTRY_ANALYTICS_PAYMENTS_MUTATIONS_NAME,
  } from '../../store/payments';
  import PaymentIndicatorItem from './PaymentIndicatorItem.vue';

  export default {
    name: 'PaymentBalance',
    components: {
      PaymentIndicatorItem,
    },
    props: {
      currentCountry: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        currentBalance: '',
        financialBalance: '',
        oldCurrentBalance: '',
        oldFinancialBalance: '',
        dateFilters: {
          start: '',
          end: '',
        },
      };
    },
    computed: {
      ...mapGetters({
        // current
        getListCurrentBalance: COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME.getListCurrentBalance,
        getInfoGraphCurrentBalance: COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME.getInfoGraphCurrentBalance,
        getLegendCurrentBalance: COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME.getLegendCurrentBalance,
        getLoadCurrentBalance: COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME.getLoadCurrentBalance,
        // financial
        getListFinancialBalance: COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME.getListFinancialBalance,
        getInfoGraphFinancialBalance: COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME.getInfoGraphFinancialBalance,
        getLegendFinancialBalance: COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME.getLegendFinancialBalance,
        getLoadFinancialBalance: COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME.getLoadFinancialBalance,
        // reserveAssetBalance
        getInfoGraphReserveAssetBalance: COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME.getInfoGraphReserveAssetBalance,
        getLoadReserveAssetBalance: COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME.getLoadReserveAssetBalance,
        // pureErrorsAndOmissions
        getInfoGraphPureErrorsAndOmissions: COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME.getInfoGraphPureErrorsAndOmissions,
        getLoadPureErrorsAndOmissions: COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME.getLoadPureErrorsAndOmissions,
        getYearList: COUNTRY_ANALYTICS_PAYMENTS_GETTERS_NAME.getYearList,
      }),

      getCountryName() {
        return this.currentCountry?.text || '';
      },
      getIdCountry() {
        return this.currentCountry?.id || 0;
      },

      getYearListOptions() {
        return this.getYearList.map((yearInfo) => {
          return {
            value: yearInfo.id,
            label: yearInfo.text,
            selected: yearInfo.selected,
          };
        });
      },
    },
    created() {
      this.loadBootstrapCurrentBalance();
      this.loadBootstrapFinancialBalance();
      this.loadGraphNoSearch();
      this.loadYearList();
    },
    methods: {
      ...mapActions({
        // current
        getCurrentBalance: COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME.getCurrentBalance,
        getInfoCurrentBalance: COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME.getInfoCurrentBalance,
        // financial
        getFinancialBalance: COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME.getFinancialBalance,
        getInfoFinancialBalance: COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME.getInfoFinancialBalance,
        // reserveAssetBalance
        getInfoReserveAssetBalance: COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME.getInfoReserveAssetBalance,
        // pureErrorsAndOmissions
        getInfoPureErrorsAndOmissions: COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME.getInfoPureErrorsAndOmissions,
        fetchYearList: COUNTRY_ANALYTICS_PAYMENTS_ACTIONS_NAME.fetchYearList,
      }),

      ...mapMutations({
        legendResetState: `${COUNTRY_ANALYTICS_PAYMENTS}/${COUNTRY_ANALYTICS_PAYMENTS_MUTATIONS_NAME.legendResetState}`,
        toggleHiddenGraph: `${COUNTRY_ANALYTICS_PAYMENTS}/${COUNTRY_ANALYTICS_PAYMENTS_MUTATIONS_NAME.toggleHiddenGraph}`,
        changeStateLoad: `${COUNTRY_ANALYTICS_PAYMENTS}/${COUNTRY_ANALYTICS_PAYMENTS_MUTATIONS_NAME.changeStateLoad}`,
      }),

      async loadBootstrapCurrentBalance() {
        let res;
        if (!this.getListCurrentBalance.length) {
          res = await this.getCurrentBalance();
        }

        if (!this.getListCurrentBalance.length && !res?.data) return;

        if (this.getListCurrentBalance.length) {
          const item = this.getListCurrentBalance.find((i) => i.default);
          this.currentBalance = item ? item.value : this.getListCurrentBalance[0].value;
          this.oldCurrentBalance = this.currentBalance;
        } else {
          this.currentBalance = '';
          this.oldCurrentBalance = '';
        }

        this.loadGraphCurrentBalance();
      },

      async loadBootstrapFinancialBalance() {
        let res;
        if (!this.getListFinancialBalance.length) {
          res = await this.getFinancialBalance();
        }

        if (!this.getListFinancialBalance.length && !res?.data) return;

        if (this.getListFinancialBalance.length) {
          const item = this.getListFinancialBalance.find((i) => i.default);
          this.financialBalance = item ? item.value : this.getListFinancialBalance[0].value;
          this.oldFinancialBalance = this.financialBalance;
        } else {
          this.financialBalance = '';
          this.oldFinancialBalance = '';
        }

        this.loadGraphFinancialBalance();
      },

      async loadGraphCurrentBalance() {
        try {
          this.changeStateLoad({
            name: 'loadCurrentBalance',
            stateParams: true,
          });

          await this.getInfoCurrentBalance({
            countryId: this.getIdCountry,
            indicatorIds: this.currentBalance,
            start: this.dateFilters.start,
            end: this.dateFilters.end,
          });

          this.oldCurrentBalance = this.currentBalance;
        } catch (err) {
          console.error(err);
        }
      },

      async loadGraphFinancialBalance() {
        try {
          this.changeStateLoad({
            name: 'loadFinancialBalance',
            stateParams: true,
          });
          await this.getInfoFinancialBalance({
            countryId: this.getIdCountry,
            indicatorIds: this.financialBalance,
            start: this.dateFilters.start,
            end: this.dateFilters.end,
          });
          this.oldFinancialBalance = this.financialBalance;
        } catch (err) {
          console.error(err);
        }
      },

      async loadGraphReserveAssetBalance() {
        try {
          this.changeStateLoad({
            name: 'loadReserveAssetBalance',
            stateParams: true,
          });
          await this.getInfoReserveAssetBalance({
            countryId: this.getIdCountry,
            start: this.dateFilters.start,
            end: this.dateFilters.end,
          });
        } catch (err) {
          console.error(err);
        }
      },

      async loadGraphPureErrorsAndOmissions() {
        try {
          this.changeStateLoad({
            name: 'loadPureErrorsAndOmissions',
            stateParams: true,
          });
          await this.getInfoPureErrorsAndOmissions({
            countryId: this.getIdCountry,
            start: this.dateFilters.start,
            end: this.dateFilters.end,
          });
        } catch (err) {
          console.error(err);
        }
      },

      loadGraphNoSearch() {
        this.loadGraphReserveAssetBalance();
        this.loadGraphPureErrorsAndOmissions();
      },

      loadYearList() {
        this.fetchYearList({
          countryId: this.getIdCountry,
        });
      },

      applyParametersCurrent({ balance, start, end }) {
        this.currentBalance = balance;
        this.dateFilters = { start, end };
        this.loadGraphCurrentBalance();
      },

      applyParametersFinancial({ balance, start, end }) {
        this.financialBalance = balance;
        this.dateFilters = { start, end };
        this.loadGraphFinancialBalance();
      },

      applyParametersReserveAssetBalance({ start, end }) {
        this.dateFilters = { start, end };
        this.loadGraphReserveAssetBalance();
      },

      applyParametersPureErrorsAndOmissions({ start, end }) {
        this.dateFilters = { start, end };
        this.loadGraphPureErrorsAndOmissions();
      },

      onClickLegendCurrent(item) {
        this.toggleHiddenGraph({
          type: 'legendCurrentBalance',
          item,
        });
      },

      onClickLegendFinancial(item) {
        this.toggleHiddenGraph({
          type: 'legendFinancialBalance',
          item,
        });
      },

      resetStateLegend(type) {
        this.legendResetState(type);
      },
    },
    watch: {
      getIdCountry() {
        this.loadGraphCurrentBalance();
        this.loadGraphFinancialBalance();
        this.loadGraphNoSearch();
        this.loadYearList();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .payment-balance {
    &__source {
      text-align: right;
      color: $light-grey-12;
      font: $fira-12;
    }
  }
</style>
