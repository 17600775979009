<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Настройка показателей</p>
      <div class="mb-16">
        <ButtonStock title="Сохранить" @click="updateIndicators()" :disabled="isSaving" />
      </div>
      <div class="table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th>Название индикатора</th>
              <th>Показывать больше информации</th>
            </tr>
          </thead>
          <tbody v-if="items?.length && !isLoading">
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.name }}</td>
              <td>
                <div class="checkboxInput">
                  <ComponentInput v-model="item.moreInfoVisible" type="checkbox" />
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="isLoading">
            <tr>
              <td class="text-center" colspan="5">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="5">Нет данных.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/indicatorVisibility';

  export default {
    name: 'IndicatorVisibility',
    components: {
      ButtonStock,
      ComponentInput,
    },
    mixins: [security],
    data() {
      return {
        items: [],

        isLoading: false,
        isSaving: false,
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.AdminAccess).then((response) => {
        if (response.data) {
          this.loadPage();
        } else {
          this.notAccess();
        }
      });
    },
    methods: {
      loadPage() {
        this.isLoading = true;
        API.search()
          .then((response) => {
            this.items = response.data;
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      updateIndicators() {
        this.isSaving = true;
        API.update(this.items).then(() => {
          this.isSaving = false;
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .checkboxInput::v-deep input[type='checkbox'] {
    position: relative !important;
    width: 20px !important;
    height: 20px !important;
    left: 0 !important;
    top: 0 !important;
    z-index: 0 !important;
    margin: 0 auto;
  }
</style>
