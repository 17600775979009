<template>
  <datepicker
    startingView="time"
    :locale="locale"
    :inputFormat="dateFormat"
    v-model="date"
    placeholder="дд.мм.гггг"
    :style="{ background: !disabled ? '#fff' : null }"
    :clearable="clearable && !disabled"
    :disabled="disabled"
    :required="required"
  />
  <div class="invalid-msg" v-if="v$.date.$error">Введите корректную дату</div>
  <div class="input-group-wrapper">
    <ComponentInput type="time" :disabled="disabled" v-model="time" id="time" />
  </div>
  <div class="invalid-msg" v-if="v$.time.$error">Введите корректное время</div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { requiredIf } from '@vuelidate/validators';
  import { ru } from 'date-fns/locale';
  import moment from 'moment';

  import ComponentInput from '@/common/components/ComponentInput';

  const timeValidator = (value) =>
    value === null ||
    value === undefined ||
    value === '' ||
    new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$', 'g').test(value);

  export default {
    props: {
      modelValue: [String, Date],
      clearable: {
        type: Boolean,
        default: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      ComponentInput,
    },
    created() {
      if (this.modelValue) {
        this.date = new Date(Date.parse(this.modelValue));
        this.time = moment(this.modelValue).format('HH:mm');
      }
      //this.date = this.modelValue ? new Date(Date.parse(this.modelValue)) : null;
    },
    data() {
      return {
        dateFormat: 'dd.MM.yyyy',
        date: null,
        time: null,
        locale: ru,
      };
    },
    methods: {
      getValueToReturn() {
        if (this.date == null) return null;

        return moment(this.date).format('YYYY-MM-DD') + 'T' + (this.time != null ? this.time : '00:00') + ':00';
      },
    },
    watch: {
      modelValue(newValue) {
        this.date = newValue ? new Date(Date.parse(newValue)) : null;
        this.time = newValue ? moment(newValue).format('HH:mm') : '00:00';
      },
      date() {
        this.$emit('update:modelValue', this.getValueToReturn());
      },
      time() {
        this.$emit('update:modelValue', this.getValueToReturn());
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        date: {
          required: requiredIf(function () {
            return this.required;
          }),
        },
        time: {
          required: requiredIf(function () {
            return this.required;
          }),
          timeValidator,
        },
      };
    },
  };
</script>

<style lang="scss">
  .v3dp__popout {
    right: 0;
    z-index: 11110 !important;
  }
  .v3dp__input_wrapper {
    position: relative;
    background: #ffffff;
    border: 1px solid rgba(195, 195, 200, 0.5);
    input {
      background: #fbfbfb !important;
      width: 100%;
      display: inline-block;
    }
    .v3dp__clearable {
      position: absolute;
      right: 10px;
      top: 6px;
      left: auto;
    }
  }
</style>
