<template>
  <NsiCard :pageTitle="pageTitle" :apiRoute="apiRoute" :cardName="cardName" :listName="listName" />
</template>

<script>
  import NsiCard from '../components/NsiCard';

  export default {
    name: 'NonTariffMeasureCard',
    components: {
      NsiCard,
    },
    data() {
      return {
        pageTitle: 'Карточка наименования нетарифной меры',
        apiRoute: 'NsiNonTariffMeasure',
        cardName: 'NonTariffMeasureCard',
        listName: 'NonTariffMeasure',
      };
    },
    created() {},
    methods: {},
  };
</script>
