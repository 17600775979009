import {
  COLORS_GRAPH_CURRENT_BALANCE,
  COLORS_GRAPH_FINANCIAL_BALANCE,
  COLORS_GRAPH_NO_SEARCH,
  ID_ALL_MEANINGS_BALANCE,
  ID_LINE_GRAPH_BALANCE,
} from '../../utils/constants';

export function setCurrentBalanceList(state, res) {
  const { data = {} } = res;
  state.currentBalance = data.map((x) => ({ label: x.name, value: x.id, default: x?.default }));
  const itemsLegend = data.filter((i) => +i.id !== ID_ALL_MEANINGS_BALANCE);

  state.legendCurrentBalance = itemsLegend.map((itemLegend) => {
    if (COLORS_GRAPH_CURRENT_BALANCE[itemLegend.id]) {
      itemLegend.hidden = false;
      itemLegend.color = COLORS_GRAPH_CURRENT_BALANCE[itemLegend.id]?.color || '';
    }
    return itemLegend;
  });
}

export function setFinancialBalanceList(state, res) {
  const { data = {} } = res;
  state.financialBalance = data.map((x) => ({ label: x.name, value: x.id, default: x?.default }));
  const itemsLegend = data.filter((i) => +i.id !== ID_ALL_MEANINGS_BALANCE);

  state.legendFinancialBalance = itemsLegend.map((itemLegend) => {
    if (COLORS_GRAPH_CURRENT_BALANCE[itemLegend.id]) {
      itemLegend.hidden = false;
      itemLegend.color = COLORS_GRAPH_FINANCIAL_BALANCE[itemLegend.id]?.color || '';
    }
    return itemLegend;
  });
}

function dataFormatConversion(data, arrayPrototype) {
  let dataGraph = [];
  const categoriesGraph = [];
  const strokeWidth = [];
  const operations = Object.keys(data);
  if (operations.length) {
    let values = [];
    for (let itemData in data) {
      const info = data[itemData];
      info.forEach((itemInfo) => {
        values.push(itemInfo.value);
        categoriesGraph.push(itemInfo.yearQuarterStr);
      });

      dataGraph.push({
        data: values,
        color: arrayPrototype[itemData]?.color,
        name: arrayPrototype[itemData]?.name,
        type: +itemData !== ID_LINE_GRAPH_BALANCE ? 'column' : 'line',
      });

      strokeWidth.push(+itemData !== ID_LINE_GRAPH_BALANCE ? 0 : 4);

      values = [];
    }
  }

  const categoriesGraphArr = [...new Set(categoriesGraph)];

  // const newCategoriesGraph = categoriesGraphArr.map((i, index) => {
  //   if (index % 4) i = ' ';
  //   return i;
  // });

  return {
    dataGraph,
    categoriesGraph: categoriesGraphArr,
    categoriesTooltip: categoriesGraphArr,
    strokeWidth,
  };
}

export function updateInfoCurrentBalance(state, res) {
  const { data = {} } = res;
  const dataFormatting = dataFormatConversion(data, COLORS_GRAPH_CURRENT_BALANCE);
  state.infoGraphCurrentBalance.seriesProps = dataFormatting.dataGraph;
  state.infoGraphCurrentBalance.categories = dataFormatting.categoriesGraph;
  state.infoGraphCurrentBalance.strokeWidth = dataFormatting.strokeWidth;
  state.loadCurrentBalance = false;
  state.infoGraphCurrentBalance.tooltipCategories = dataFormatting.categoriesTooltip;
}

export function updateInfoFinancialBalance(state, res) {
  const { data = {} } = res;
  const dataFormatting = dataFormatConversion(data, COLORS_GRAPH_FINANCIAL_BALANCE);
  state.infoGraphFinancialBalance.seriesProps = dataFormatting.dataGraph;
  state.infoGraphFinancialBalance.categories = dataFormatting.categoriesGraph;
  state.infoGraphFinancialBalance.strokeWidth = dataFormatting.strokeWidth;
  state.loadFinancialBalance = false;
  state.infoGraphFinancialBalance.tooltipCategories = dataFormatting.categoriesTooltip;
}

export function changeStateLoad(state, params) {
  const { name, stateParams } = params;
  state[name] = stateParams;
}

export function toggleHiddenGraph(state, parameter) {
  const { type, item } = parameter;
  state[type].forEach((i) => {
    if (i.id === item.id) i.hidden = !i.hidden;
  });
}

export function legendResetState(state, type) {
  state[type].forEach((i) => {
    if (i.hidden) i.hidden = false;
  });
}

export function updateInfoReserveAssetBalance(state, res) {
  const { data = {} } = res;
  const dataFormatting = dataFormatConversion({ 5: data }, COLORS_GRAPH_NO_SEARCH);
  state.infoReserveAssetBalance.seriesProps = dataFormatting.dataGraph;
  state.infoReserveAssetBalance.categories = dataFormatting.categoriesGraph;
  state.infoReserveAssetBalance.strokeWidth = dataFormatting.strokeWidth;
  state.loadReserveAssetBalance = false;
  state.infoReserveAssetBalance.tooltipCategories = dataFormatting.categoriesTooltip;
}
export function updateInfoPureErrorsAndOmissions(state, res) {
  const { data = {} } = res;
  const dataFormatting = dataFormatConversion({ 5: data }, COLORS_GRAPH_NO_SEARCH);
  state.infoPureErrorsAndOmissions.seriesProps = dataFormatting.dataGraph;
  state.infoPureErrorsAndOmissions.categories = dataFormatting.categoriesGraph;
  state.infoPureErrorsAndOmissions.strokeWidth = dataFormatting.strokeWidth;
  state.loadPureErrorsAndOmissions = false;
  state.infoPureErrorsAndOmissions.tooltipCategories = dataFormatting.categoriesTooltip;
}
export function setYearList(state, res) {
  const { data = [] } = res;
  state.yearList = data;
}
