<template>
  <div class="default-filter" v-if="$store.state.auth?.oidc?.profile?.roleIds.includes(1) && showFilters">
    <div class="button-container d-flex">
      <ButtonComponent @click="saveFilter()" mod="gradient-bg" class="button-redesign--mpk-commissions"
        >Сохранить фильтр</ButtonComponent
      >
      <ButtonComponent @click="loadFilter()" mod="gradient-bg" class="button-redesign--mpk-commissions"
        >Загрузить фильтр</ButtonComponent
      >
    </div>

    <ModalComponent title="Выберите роль" @close="dialogRole = false" v-model="dialogRole">
      <div class="form__body">
        <div class="form__body-inputBlock mb-3 pb-3">
          <p>Роль</p>
          <ComponentInput
            placeholder="Укажите роль"
            :select2Settings="select2SettingsNoClear"
            :select2Options="roles"
            v-model="roleId"
            type="select2"
          />
        </div>
        <div class="form__body-inputBlock">
          <ButtonStock title="Выбрать" @click="selectUserRole" />
          <ButtonStock class="ml-2" @click="dialogRole = false" btnType="whiteGreenBordered" title="Закрыть" />
        </div>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import Api_Roles from '@/modules/admin/api/roles';

  export default {
    name: 'DefaultFilter',
    components: { ButtonStock, ModalComponent, ComponentInput, ButtonComponent },
    emits: ['loadFilter'],
    props: {
      filterValue: {
        type: Object,
        default: null,
      },
    },
    mixins: [filtermanager],
    data() {
      return {
        filter: {},
        roles: [],
        dialogRole: false,
        isSaving: false,
        roleId: 1,
        select2SettingsNoClear: {
          language: Constants.langRu,
          allowClear: false,
        },
        showFilters: false,
      };
    },
    created() {
      if (this.filterValue) this.filter = this.filterValue;
      this.loadDictionaries();
      this.showFilters = this.$store.state.auth?.oidc?.profile?.showFilters;
    },
    methods: {
      saveFilter() {
        this.isSaving = true;
        this.dialogRole = true;
      },
      loadFilter() {
        this.isSaving = false;
        this.dialogRole = true;
      },
      loadDictionaries() {
        Api_Roles.search({ pageNumber: 1, pageSize: 0 }).then((response) => {
          this.roles = response.data.items.map((x) => ({ id: x.id, text: x.title }));
        });
      },
      selectUserRole() {
        this.dialogRole = false;

        if (this.isSaving) {
          let currentFilterItem = {
            roleId: this.roleId,
            route: this.$route.path,
            value: JSON.stringify(this.filter),
          };

          this.updateFilterValue(currentFilterItem)
            .then(() => {
              Constants.alert.fire('Редактирование фильтра', `Фильтр успешно сохранен`, 'success');
            })
            .catch(() => {
              Constants.alert.fire('Сохранение фильтра', Constants.commonError, 'error');
            });
        } else {
          this.getFilterValue(this.roleId, this.$route.path, false).then((response) => {
            let currentFilter = response.data;
            if (currentFilter) this.$emit('loadFilter', JSON.parse(currentFilter.value));
            else Constants.alert.fire('Загрузка фильтра', 'Фильтр для этой роли не найден', 'error');
          });
        }
      },
    },
    watch: {
      filterValue: {
        handler: function (val) {
          if (val) this.filter = val;
          else this.filter = {};
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .button-container {
    flex-wrap: wrap;
  }
  .default-filter {
    @media (max-width: $mobile) {
      width: 100%;
    }
  }
</style>
