<template>
  <div class="header-block">
    <div class="container-page big container-page--no-indentation">
      <h2 class="title title--h2">{{ headerTitle }}</h2>

      <div class="header-block__choice">
        <div class="header-block__select">
          <div class="header-block__title-country">{{ headerSelectTitle }}</div>
          <SelectMultiComponent
            id="country-choice"
            v-model="country"
            :options="getOptionsCountry"
            :canClear="canClearSelect"
            placeholder="Выберите"
            classSelect="multiselect--empty-borders"
          />
        </div>
        <ButtonComponent mod="download-icon" @click="onOpenPresentationDialog">
          <IconComponent name="downloadWithArrow" />
          <span>Скачать презентацию</span>
        </ButtonComponent>
      </div>
    </div>

    <div class="container-page big container-page--no-indentation">
      <p v-if="isUnfriendlyCountry" class="header-block__unfriendly">Недружественное государство</p>
    </div>

    <div class="header-block__tabs-container">
      <PerfectScrollbar>
        <!-- <ul class="header-block__tabs">
          <li :class="['header-block__item-tab', { active: selectTypeTab === 3 }]" @click="switchTab(3)">
            {{ tabsName.NAME_TAB_ECONOMIC_OVERVIEW }}
          </li>
          <li :class="['header-block__item-tab', { active: selectTypeTab === 1 }]" @click="switchTab(1)">
            {{ tabsName.NAME_TAB_COUNTRY_INFORMATION }}
          </li>
          <li :class="['header-block__item-tab', { active: selectTypeTab === 2 }]" @click="switchTab(2)">
            {{ tabsName.NAME_TAB_TRADE_AND_ECONOMIC_COOPERATION }}
          </li>
        </ul> -->
        <ul class="header-block__tabs">
          <li
            v-for="item in headerTabs"
            v-permission="item.permissionId"
            :key="item.id"
            :class="['header-block__item-tab', { active: selectTypeTab === item.id }]"
            @click="switchTab(item.id)"
          >
            {{ item.text }}
          </li>
        </ul>
      </PerfectScrollbar>
    </div>

    <div v-if="getTabData.length" class="header-block__tabs-container">
      <PerfectScrollbar>
        <ul class="header-block__tabs">
          <li v-for="tab in getTabData" :key="tab.id">
            <ButtonComponent
              mod="grey"
              :class="['button-redesign--tabs-analytics', { active: activeTabId === tab.id }]"
              @click="switchTabLVLTwo(tab)"
            >
              {{ tab.text }}
            </ButtonComponent>
          </li>
        </ul>
      </PerfectScrollbar>
    </div>
  </div>
</template>

<script>
  import { PerfectScrollbar } from 'vue3-perfect-scrollbar';

  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import { tabsName } from '../utils/constants';

  export default {
    name: 'HeaderBlock',
    mixins: [security],
    components: {
      ButtonComponent,
      SelectMultiComponent,
      IconComponent,
      PerfectScrollbar,
    },
    props: {
      headerTitle: {
        type: String,
        default: 'Аналитика по стране',
      },
      headerSelectTitle: {
        type: String,
        default: 'Страна:',
      },
      selectOptions: {
        type: Object,
        default: () => {},
      },
      countryExternal: {
        type: [String, Number],
        default: '',
      },
      headerTabs: {
        type: Array,
        default: () => [
          { text: tabsName.NAME_TAB_ECONOMIC_OVERVIEW, id: 3 },
          { text: tabsName.NAME_TAB_COUNTRY_INFORMATION, id: 1 },
          { text: tabsName.NAME_TAB_TRADE_AND_ECONOMIC_COOPERATION, id: 2 },
          {
            text: tabsName.NAME_TAB_REFERENCE_INFORMATION,
            id: 4,
            permissionId: Constants.Permissions.CountryAnalyticsReferenceInfo,
          },
        ],
      },
      tabData: {
        type: Array,
        default: () => [],
      },
      tabDataTwo: {
        type: Array,
        default: () => [],
      },
      tabDataFour: {
        type: Array,
        default: () => [],
      },
      isUnfriendlyCountry: {
        type: Boolean,
        default: false,
      },
      activeTabId: {
        type: [String, Number],
        default: '',
      },
      selectTypeTab: {
        type: [String, Number],
        default: '',
      },
      canClearSelect: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        country: this.countryExternal,
        // tabsName: tabsName,
      };
    },
    computed: {
      getOptionsCountry() {
        return this.selectOptions.map((i) => ({ value: i.id, label: i.text }));
      },

      getTabData() {
        if (this.selectTypeTab === 1) {
          return this.tabData.filter((d) => !d.hidden && !d.veryHidden);
        }

        if (this.selectTypeTab === 2) {
          return this.tabDataTwo.filter((d) => !d.hidden);
        }

        if (this.selectTypeTab === 4) {
          return this.tabDataFour.filter((d) => !d.hidden);
        }

        return [];
      },
    },
    watch: {
      countryExternal() {
        this.country = this.countryExternal;
      },

      country() {
        this.$emit('changeCountry', this.country);
      },
    },
    methods: {
      onOpenPresentationDialog() {
        this.$emit('onOpenPresentationDialog');
      },

      switchTab(id) {
        this.$emit('switchTab', id);
      },

      switchTabLVLTwo(tab) {
        this.$emit('switchTabLVLTwo', tab);
      },
    },
  };
</script>

<style lang="scss">
  @import '@/assets/theme/default/scroll.scss';
</style>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';

  $pad: calc((100% - 1280px) / 2);

  .header-block {
    margin-bottom: 42px;

    &__title-country {
      flex: 0 0 auto;
      font: $fira-32-M;
      color: $dark-grey-1;
    }

    &__select,
    &__choice {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &__select {
      &,
      .multiselect {
        flex: 1 1 0%;
      }
    }

    &__choice {
      margin-bottom: 24px;
    }

    &__tabs {
      display: flex;
      padding: 0 $pad 10px $pad;

      -ms-text-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      li {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }

      &-container {
        width: 100%;
        margin-bottom: 24px;
      }
    }

    &__item-tab {
      font: 16px / 20px 'Fira Sans';
      letter-spacing: -0.32px;
      color: $dark-grey-1;
      margin-right: 16px;
      transition: color 0.3s ease;
      cursor: pointer;
      white-space: nowrap;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: $green;
        text-decoration: underline;
        pointer-events: none;
      }

      &:hover {
        color: $green;
      }
    }

    &__unfriendly {
      font: $fira-16-M;
      color: $red;
      margin-bottom: 24px;
    }

    @media (max-width: 1320px) {
      &__tabs {
        padding: 0 20px 10px 20px;
      }
    }

    @media (max-width: $tablet) {
      &__tabs {
        padding: 0 32px 10px 32px;
      }
    }

    @media (max-width: $laptop) {
      &__choice,
      &__select {
        gap: 24px;
      }

      &__choice {
        flex-direction: column;
        align-items: flex-start;
      }

      &__select {
        align-self: stretch;
      }

      &__tabs {
        padding: 0 14px 10px 14px;
      }
    }

    @media (max-width: $mobile) {
      margin-bottom: 24px;

      &__select {
        flex-direction: column;
        align-items: stretch;
      }
    }
  }
</style>
