<template>
  <div
    class="International-trade internationalTradeRussiaEconRev mb-32"
    :class="{ notForTecRussiaTab: notForTecRussiaTab }"
  >
    <div class="International-trade__switchblock d-flex justify-content-between mb-22">
      <p class="economicsTypeTitle mb-0" id="tec-russia">Торгово-экономическое сотрудничество с Россией</p>
      <div class="d-flex align-items-baseline gap-4 mr-42">
        <Select2 class="select2" :options="yearList" v-model="year" placeholder="Период" @select="yearChanged" />
        <p class="fs14 mr-6 gray">Источник: {{ isProductMode ? 'ФТС России' : 'Банк России' }}</p>
        <SwitchComponent @switchHandler="switchHand($event)" />
      </div>
    </div>

    <template v-if="!currentSummary">
      <p>Загрузка данных...</p>
    </template>

    <template v-if="currentSummary">
      <ul class="indicators-new__list d-flex flex-wrap align-items-end mb-3">
        <li
          class="indicators-new__item"
          @click="turnoverMoreInfo ? selectTab(1, isProductMode ? 'Товарооборот' : 'Оборот услуг') : null"
          :class="[{ active: tabId === 1 }, { 'more-info-hidden': !turnoverMoreInfo }]"
        >
          <div class="indicators-new__wrapper flex-column">
            <p class="indicators-new__title">
              {{ isProductMode ? 'Товарооборот' : 'Оборот услуг' }}
            </p>
            <div>
              <div class="d-flex align-items-center">
                <strong v-if="currentSummary" class="indicators-new__count">
                  {{
                    formatNumber(currentSummary.totalValue / divider, 0) == '0'
                      ? '-'
                      : formatNumber(currentSummary.totalValue / divider, 0)
                  }}
                </strong>
                <span class="d-block ml-4 currency">{{ dividerLabel }} $</span>
              </div>
              <div class="d-flex mt-2 fs12">
                <div>
                  <i
                    v-if="
                      currentSummary?.totalValuePreviousPercent != null && currentSummary.totalValuePreviousPercent < 0
                    "
                    class="fas fa-solid mr-1 text-red fa-arrow-circle-down"
                  ></i>
                  <i
                    v-else-if="currentSummary?.totalValuePreviousPercent != null"
                    class="fas fa-solid mr-1 text-green fa-arrow-circle-up"
                  ></i>
                </div>
                <span
                  v-if="
                    currentSummary?.totalValuePreviousPercent != null &&
                    formatNumber(currentSummary.totalValue / divider) != '0,00'
                  "
                  :class="currentSummary.totalValuePreviousPercent > 0 ? 'text-green' : 'text-red'"
                >
                  {{ formatNumber(currentSummary?.totalValuePreviousPercent, 1) }}%
                  <span class="text-black" style="display: none">г/г</span>
                </span>
              </div>
            </div>
          </div>
        </li>
        <li
          class="indicators-new__item"
          @click="importMoreInfo ? selectTab(3, 'Импорт') : null"
          :class="[{ active: tabId === 3 }, { 'more-info-hidden': !importMoreInfo }]"
        >
          <div class="indicators-new__wrapper flex-column">
            <p class="indicators-new__title">Импорт</p>
            <div>
              <div class="d-flex align-items-center">
                <strong v-if="currentSummary" class="indicators-new__count">
                  {{
                    formatNumber(currentSummary.importValue / divider, 0) == '0'
                      ? '-'
                      : formatNumber(currentSummary.importValue / divider, 0)
                  }}
                </strong>
                <span class="d-block ml-4 currency">{{ dividerLabel }} $</span>
              </div>
              <div class="d-flex mt-2 fs12">
                <div>
                  <i
                    v-if="
                      currentSummary?.importValuePreviousPercent != null &&
                      currentSummary.importValuePreviousPercent < 0
                    "
                    class="fas fa-solid mr-1 text-red fa-arrow-circle-down"
                  ></i>
                  <i
                    v-else-if="currentSummary?.importValuePreviousPercent != null"
                    class="fas fa-solid mr-1 text-green fa-arrow-circle-up"
                  ></i>
                </div>
                <span
                  v-if="
                    currentSummary?.importValuePreviousPercent != null &&
                    formatNumber(currentSummary.importValue / divider) != '0,00'
                  "
                  :class="currentSummary.importValuePreviousPercent > 0 ? 'text-green' : 'text-red'"
                  >{{ formatNumber(currentSummary?.importValuePreviousPercent, 1) }}%
                  <span class="text-black" style="display: none">г/г</span></span
                >
              </div>
            </div>
          </div>
        </li>
        <li
          class="indicators-new__item"
          @click="exportMoreInfo ? selectTab(2, 'Экспорт') : null"
          :class="[{ active: tabId === 2 }, { 'more-info-hidden': !exportMoreInfo }]"
        >
          <div class="indicators-new__wrapper flex-column">
            <p class="indicators-new__title">Экспорт</p>
            <div>
              <div class="d-flex align-items-center">
                <strong v-if="currentSummary" class="indicators-new__count">
                  {{
                    formatNumber(currentSummary.exportValue / divider, 0) == '0'
                      ? '-'
                      : formatNumber(currentSummary.exportValue / divider, 0)
                  }}
                </strong>
                <span class="d-block ml-4 currency">{{ dividerLabel }} $</span>
              </div>
              <div class="d-flex mt-2 fs12">
                <div>
                  <i
                    v-if="
                      currentSummary?.exportValuePreviousPercent != null &&
                      currentSummary.exportValuePreviousPercent < 0
                    "
                    class="fas fa-solid mr-1 text-red fa-arrow-circle-down"
                  ></i>
                  <i
                    v-else-if="currentSummary?.exportValuePreviousPercent != null"
                    class="fas fa-solid mr-1 text-green fa-arrow-circle-up"
                  ></i>
                </div>
                <span
                  v-if="
                    currentSummary?.exportValuePreviousPercent != null &&
                    formatNumber(currentSummary.importValue / divider) != '0,00'
                  "
                  :class="currentSummary.exportValuePreviousPercent > 0 ? 'text-green' : 'text-red'"
                  >{{ formatNumber(currentSummary?.exportValuePreviousPercent, 1) }}%
                  <span class="text-black" style="display: none">г/г</span></span
                >
              </div>
            </div>
          </div>
        </li>
        <li
          v-if="isProductMode === true"
          class="indicators-new__item"
          @click="exportNonEnergyMoreInfo ? selectTab(4, 'Несырьевой') : exportNonEnergyMoreInfo"
          :class="[{ active: tabId === 4 }, { 'more-info-hidden': !exportNonEnergyMoreInfo }]"
        >
          <div class="indicators-new__wrapper flex-column">
            <p class="indicators-new__title">ННЭ</p>
            <div>
              <div class="d-flex align-items-center">
                <strong v-if="currentSummary" class="indicators-new__count">
                  {{
                    formatNumber(currentSummary.exportNonEnergyValue / divider, 0) == '0'
                      ? '-'
                      : formatNumber(currentSummary.exportNonEnergyValue / divider, 0)
                  }}
                </strong>
                <span class="d-block ml-4 currency">{{ dividerLabel }} $</span>
              </div>
              <div class="d-flex mt-2 fs12">
                <div>
                  <i
                    v-if="
                      currentSummary?.exportNonEnergyValuePreviousPercent != null &&
                      currentSummary.exportNonEnergyValuePreviousPercent < 0
                    "
                    class="fas fa-solid mr-1 text-red fa-arrow-circle-down"
                  ></i>
                  <i
                    v-else-if="currentSummary?.exportNonEnergyValuePreviousPercent != null"
                    class="fas fa-solid mr-1 text-green fa-arrow-circle-up"
                  ></i>
                </div>
                <span
                  v-if="
                    currentSummary?.exportNonEnergyValuePreviousPercent != null &&
                    formatNumber(currentSummary.exportNonEnergyValue / divider) != '0,00'
                  "
                  :class="currentSummary.exportNonEnergyValuePreviousPercent > 0 ? 'text-green' : 'text-red'"
                  >{{ formatNumber(currentSummary?.exportNonEnergyValuePreviousPercent, 1) }}%
                  <span class="text-black" style="display: none">г/г</span></span
                >
              </div>
            </div>
          </div>
        </li>
        <li
          class="indicators-new__item"
          @click="balanceMoreInfo ? selectTab(5, 'Сальдо') : balanceMoreInfo"
          :class="[{ active: tabId === 5 }, { 'more-info-hidden': !balanceMoreInfo }]"
        >
          <div class="indicators-new__wrapper flex-column">
            <p class="indicators-new__title">Сальдо</p>
            <div>
              <div class="d-flex align-items-center">
                <strong v-if="currentSummary" class="indicators-new__count">
                  {{
                    formatNumber(currentSummary.balance / divider, 0) == '0' ||
                    formatNumber(currentSummary.balance / divider, 0) == '-0'
                      ? '-'
                      : formatNumber(currentSummary.balance / divider, 0)
                  }}
                </strong>
                <span class="d-block ml-4 currency">{{ dividerLabel }} $</span>
              </div>
              <div class="d-flex mt-2 fs12">
                <div>
                  <i
                    v-if="currentSummary?.balancePreviousPercent != null && currentSummary.balancePreviousPercent < 0"
                    class="fas fa-solid mr-1 text-red fa-arrow-circle-down"
                  ></i>
                  <i
                    v-else-if="currentSummary?.balancePreviousPercent != null"
                    class="fas fa-solid mr-1 text-green fa-arrow-circle-up"
                  ></i>
                </div>
                <span
                  v-if="
                    currentSummary?.balancePreviousPercent != null &&
                    formatNumber(currentSummary.balance / divider) != '0,00'
                  "
                  :class="currentSummary.balancePreviousPercent > 0 ? 'text-green' : 'text-red'"
                  >{{ formatNumber(currentSummary?.balancePreviousPercent, 1) }}%
                  <span class="text-black" style="display: none">г/г</span></span
                >
              </div>
            </div>
          </div>
        </li>
      </ul>
    </template>

    <template v-if="tabId && graphByYear">
      <div class="d-flex justify-content-between my-5">
        <SwitchComponent
          :checked="switchRows.tradingGraph"
          class="customSwitcherInter"
          textTrue="Диаграмма"
          textFalse="Таблица"
          @switchHandler="switchRows.tradingGraph = !switchRows.tradingGraph"
        />
        <h2>
          <template v-if="isProductMode">Торговля товарами </template>
          <template v-else>Торговля услугами</template>
          , {{ dividerLabel }} $ США
        </h2>
      </div>
      <div v-if="switchRows.tradingGraph && (tabId === 1 || tabId === 2 || tabId === 3 || tabId === 5)">
        <LineChartFour
          format-label=" млн $"
          :chart-data="graphByYear.data[0]"
          :chart-data-second="graphByYear.data[1]"
          :chart-data-three="graphByYear.data[2]"
          :chart-data-four="graphByYear.data[3]"
          background-color-three="#2F84EB"
          background-color-four="#31BC00"
          point-color-three="#2F84EB"
          point-color-four="#31BC00"
          background-color="#01A39D"
          point-color="#01A39D"
          background-color-two="#787F8F"
          point-color-two="#787F8F"
          border-color="#01A39D"
          border-color-two="#787F8F"
          border-color-three="#2F84EB"
          border-color-four="#31BC00"
          :labels="graphByYear.years"
          :displayDataLabels="false"
        />

        <div class="d-flex align-items-center mt-4">
          <div class="indicator-round d-flex align-items-center mr-4">
            <span class="rounded-circle" style="background-color: #01a39d"></span>
            <p>{{ isProductMode ? 'Товарооборот' : 'Оборот услуг' }}</p>
          </div>
          <div class="indicator-round d-flex align-items-center mr-4">
            <span class="rounded-circle"></span>
            <p>Экспорт</p>
          </div>
          <div class="indicator-round d-flex align-items-center mr-4">
            <span class="rounded-circle"></span>
            <p>Импорт</p>
          </div>
          <div class="indicator-round d-flex align-items-center">
            <span class="rounded-circle"></span>
            <p>Сальдо</p>
          </div>
        </div>
      </div>
      <div v-if="switchRows.tradingGraph && tabId === 4">
        <LineChartDouble
          labelAlignY="end"
          labelAnchorY="end"
          :enableTooltip="false"
          format-label=" млн $"
          :chart-data="graphByYear.data[4]"
          background-color="#01A39D"
          point-color="#01A39D"
          background-color-two="#787F8F"
          point-color-two="#787F8F"
          border-color="#01A39D"
          border-color-two="#787F8F"
          :labels="graphByYear.years"
          :displayDataLabels="false"
        />
        <div class="d-flex align-items-center mt-4">
          <div class="indicator-round d-flex align-items-center mr-4">
            <span class="rounded-circle"></span>
            <p>Абсолютное значение</p>
          </div>
          <!-- <div class="indicator-round d-flex align-items-center mr-4">
                      <span class="rounded-circle"></span>
                      <p>Прошедшее значение</p>
                    </div> -->
        </div>
      </div>
      <div class="table-container js-table-arrow" v-if="!switchRows.tradingGraph">
        <table class="stockTable">
          <thead>
            <tr>
              <th style="font-size: 14px">Год</th>
              <th style="font-size: 14px">
                {{ isProductMode ? 'Товарооборот' : 'Оборот услуг' }}
              </th>
              <th style="font-size: 14px">Экспорт</th>
              <th style="font-size: 14px">Импорт</th>
              <th style="font-size: 14px">Сальдо</th>
              <th v-if="isProductMode" style="font-size: 14px">ННЭ</th>
            </tr>
          </thead>
          <tbody v-if="tableData">
            <tr v-for="(item, index) in tableData" :key="index">
              <td class="font-weight-bold">{{ item.year }}</td>
              <td class="font-weight-bold">
                {{ formatNumber(item.total / 1000000) }}
              </td>
              <td class="font-weight-bold">
                {{ formatNumber(item.export / 1000000) }}
              </td>
              <td class="font-weight-bold">
                {{ formatNumber(item.import / 1000000) }}
              </td>
              <td class="font-weight-bold">
                {{ formatNumber(item.balance / 1000000) }}
              </td>
              <td v-if="isProductMode" class="font-weight-bold">
                {{ formatNumber(item.nonEnergy / 1000000) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <template v-if="isProductMode">
      <!-- Товарная структура экспорта -->
      <div v-if="tabId && tabId !== 4 && tabId != 3 && currentSummary && currentSummary.topExportCode2?.length > 0">
        <div class="d-flex justify-content-between my-5">
          <SwitchComponent
            class="customSwitcherInter"
            textTrue="Диаграмма"
            textFalse="Таблица"
            @switchHandler="switchRows.tovarnayaStructureExport = !switchRows.tovarnayaStructureExport"
          />
          <h2>Товарная структура экспорта</h2>
        </div>
        <!-- Диаграмма -->
        <template v-if="switchRows.tovarnayaStructureExport">
          <!-- <h2 class="my-5">Товарная структура экспорта</h2> -->
          <template v-if="currentSummary">
            <ColumnChartDouble
              labelAlignY="end"
              labelAnchorY="end"
              :enableTooltip="false"
              indexAxis="y"
              barPercentage="1"
              :format-label="null"
              color-first="#04b1e0"
              color-second="#00C6A2"
              label-first="Текущий период, млн $"
              label-second="Прошедший период, млн $"
              height="600px"
              :chart-data="currentSummary.topExportCode2.map((item) => (item.currentValue / divider).toFixed(0))"
              :chart-data-two="currentSummary.topExportCode2.map((item) => (item.previousValue / divider).toFixed(0))"
              :labels="currentSummary.topExportCode2.map((item) => makeChartLabel(item.title, 90))"
            />
          </template>
        </template>
        <!-- Таблица -->
        <template v-else>
          <!-- <h2 class="my-5" v-if="currentSummary">
                      Товарная структура экспорта (таблица)
                    </h2> -->
          <div class="table-container mt-5 js-table-arrow">
            <table class="stockTable">
              <thead>
                <tr>
                  <th style="font-size: 14px" rowspan="2">Наименование</th>
                  <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">
                    Экспорт
                  </th>
                </tr>
                <tr>
                  <th style="text-align: center">Текущий период, тыс $</th>
                  <th>Прошедший период, тыс $</th>
                </tr>
              </thead>
              <tbody v-if="currentSummary">
                <tr style="background-color: #ade0ff">
                  <td class="font-weight-bold">Всего</td>
                  <td class="font-weight-bold">
                    {{ formatNumber(currentSummary.exportCode2TotalCurrent / 1000) }}
                  </td>
                  <td class="font-weight-bold">
                    {{ formatNumber(currentSummary.exportCode2TotalPrevious / 1000) }}
                  </td>
                </tr>
                <tr v-for="item in currentSummary.topExportCode2" :key="item.id">
                  <td class="font-weight-bold">{{ item.title }}</td>
                  <td class="font-weight-bold">
                    {{ formatNumber(item.currentValue / 1000) }}
                  </td>
                  <td class="font-weight-bold">
                    {{ formatNumber(item.previousValue / 1000) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
      <!-- Товарная структура экспорта -->
      <div v-if="tabId === 4 && currentSummary && currentSummary.topExportNonEnergyCode2?.length > 0">
        <div class="d-flex justify-content-between my-5">
          <SwitchComponent
            class="customSwitcherInter"
            textTrue="Диаграмма"
            textFalse="Таблица"
            @switchHandler="switchRows.tovarnayaStructureExportSecond = !switchRows.tovarnayaStructureExportSecond"
          />
          <h2>Товарная структура экспорта</h2>
        </div>
        <!-- Диаграмма -->
        <template v-if="switchRows.tovarnayaStructureExportSecond">
          <!-- <h2 class="my-5">Товарная структура экспорта</h2> -->
          <template v-if="currentSummary">
            <ColumnChartDouble
              labelAlignY="end"
              labelAnchorY="end"
              :enableTooltip="false"
              indexAxis="y"
              barPercentage="1"
              :format-label="null"
              color-first="#04b1e0"
              color-second="#00C6A2"
              label-first="Текущий период"
              label-second="Прошедший период"
              :chart-data="
                currentSummary.topExportNonEnergyCode2.map((item) => (item.currentValue / divider).toFixed(0))
              "
              :chart-data-two="
                currentSummary.topExportNonEnergyCode2.map((item) => (item.previousValue / divider).toFixed(0))
              "
              :labels="currentSummary.topExportNonEnergyCode2.map((item) => makeChartLabel(item.title, 90))"
            />
          </template>
        </template>
        <!-- Таблица -->
        <template v-else>
          <!-- <h2 class="my-5" v-if="currentSummary">
                      Товарная структура экспорта (таблица)
                    </h2> -->
          <div class="table-container mt-5 js-table-arrow">
            <table class="stockTable">
              <thead>
                <tr>
                  <th style="font-size: 14px" rowspan="2">Наименование</th>
                  <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">
                    Импорт
                  </th>
                </tr>
                <tr>
                  <th style="text-align: center">Текущий период</th>
                  <th>Прошедший период</th>
                </tr>
              </thead>
              <tbody v-if="currentSummary">
                <tr style="background-color: #ade0ff">
                  <td class="font-weight-bold">Всего</td>
                  <td class="font-weight-bold">
                    {{ formatNumber(currentSummary.exportNonEnergyCode2TotalCurrent / 1000) }}
                  </td>
                  <td class="font-weight-bold">
                    {{ formatNumber(currentSummary.exportNonEnergyCode2TotalPrevious / 1000) }}
                  </td>
                </tr>
                <tr v-for="item in currentSummary.topExportNonEnergyCode2" :key="item.id">
                  <td class="font-weight-bold">{{ item.title }}</td>
                  <td class="font-weight-bold">
                    {{ formatNumber(item.currentValue / 1000) }}
                  </td>
                  <td class="font-weight-bold">
                    {{ formatNumber(item.previousValue / 1000) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
      <!-- Товарная структура импорта -->
      <div v-if="tabId && tabId !== 4 && tabId != 2 && currentSummary && currentSummary.topImportCode2?.length > 0">
        <div class="d-flex justify-content-between my-5">
          <SwitchComponent
            class="customSwitcherInter"
            textTrue="Диаграмма"
            textFalse="Таблица"
            @switchHandler="switchRows.tovarnayaStructureImport = !switchRows.tovarnayaStructureImport"
          />
          <h2>Товарная структура импорта</h2>
        </div>
        <!-- Диаграмма -->
        <template v-if="switchRows.tovarnayaStructureImport">
          <!-- <h2 class="my-5">Товарная структура импорта</h2> -->
          <template v-if="currentSummary">
            <ColumnChartDouble
              labelAlignY="end"
              labelAnchorY="end"
              :enableTooltip="false"
              indexAxis="y"
              barPercentage="1"
              :format-label="null"
              color-first="#04b1e0"
              color-second="#00C6A2"
              label-first="Текущий период, млн $"
              label-second="Прошедший период, млн $"
              :chart-data="currentSummary.topImportCode2.map((item) => (item.currentValue / divider).toFixed(0))"
              :chart-data-two="currentSummary.topImportCode2.map((item) => (item.previousValue / divider).toFixed(0))"
              :labels="currentSummary.topImportCode2.map((item) => makeChartLabel(item.title, 90))"
            />
          </template>
        </template>
        <!-- Таблица -->
        <template v-else>
          <!-- <h2 class="my-5" v-if="currentSummary">
                      Товарная структура импорта (таблица)
                    </h2> -->
          <div class="table-container mt-5 js-table-arrow">
            <table class="stockTable">
              <thead>
                <tr>
                  <th style="font-size: 14px" rowspan="2">Наименование</th>
                  <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">
                    Импорт
                  </th>
                </tr>
                <tr>
                  <th style="text-align: center">Текущий период, тыс $</th>
                  <th>Прошедший период, тыс $</th>
                </tr>
              </thead>
              <tbody v-if="currentSummary">
                <tr style="background-color: #ade0ff">
                  <td class="font-weight-bold">Всего</td>
                  <td class="font-weight-bold">
                    {{ formatNumber(currentSummary.importCode2TotalCurrent / 1000) }}
                  </td>
                  <td class="font-weight-bold">
                    {{ formatNumber(currentSummary.importCode2TotalPrevious / 1000) }}
                  </td>
                </tr>
                <tr v-for="item in currentSummary.topImportCode2" :key="item.id">
                  <td class="font-weight-bold">{{ item.title }}</td>
                  <td class="font-weight-bold">
                    {{ formatNumber(item.currentValue / 1000) }}
                  </td>
                  <td class="font-weight-bold">
                    {{ formatNumber(item.previousValue / 1000) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
      <!-- ТОП-5 ТОВАРОВ экспорта импорт -->
      <template v-if="tabId && tabId !== 4">
        <!-- ТОП-5 ТОВАРОВ экспорта -->
        <template v-if="currentSummary && currentSummary.topExportGroups?.length > 0 && tabId != 3">
          <div class="d-flex justify-content-between my-5">
            <SwitchComponent
              class="customSwitcherInter"
              textTrue="Диаграмма"
              textFalse="Таблица"
              @switchHandler="switchRows.topTovariExport = !switchRows.topTovariExport"
            />
            <h2>Топ-5 товаров экспорта</h2>
          </div>
          <!-- Диаграмма -->
          <div v-if="switchRows.topTovariExport">
            <!-- <h2 class="my-5">ТОП-5 ТОВАРОВ экспорта</h2> -->
            <ColumnChartDouble
              labelAlignY="end"
              labelAnchorY="end"
              :enableTooltip="false"
              indexAxis="y"
              barPercentage="1"
              :format-label="null"
              label-first="Текущий период тыс $"
              label-second="Прошедший период тыс $"
              color-first="#04b1e0"
              color-second="#00C6A2"
              :chart-data="currentSummary.topExportGroups.map((item) => (item.currentValue / 1000).toFixed(2))"
              :chart-data-two="currentSummary.topExportGroups.map((item) => (item.previousValue / 1000).toFixed(2))"
              :labels="currentSummary.topExportGroups.map((item) => makeChartLabel(item.title, 90))"
            />
          </div>
          <!-- Таблица -->
          <div v-else class="mt-5">
            <!-- <h2 class="my-5" v-if="currentSummary">
                          ТОП-5 ТОВАРОВ (таблица)
                        </h2> -->
            <div class="table-container mt-5 js-table-arrow">
              <table class="stockTable">
                <thead>
                  <tr>
                    <th style="font-size: 14px" rowspan="2">Наименование</th>
                    <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">
                      Экспорт
                    </th>
                  </tr>
                  <tr>
                    <th style="text-align: center">Текущий период тыс $</th>
                    <th>Прошедший период тыс $</th>
                  </tr>
                </thead>
                <tbody v-if="currentSummary">
                  <tr style="background-color: #ade0ff">
                    <td class="font-weight-bold">Всего</td>
                    <td class="font-weight-bold">
                      {{ formatNumber(currentSummary.exportGroupsTotalCurrent / 1000) }}
                    </td>
                    <td class="font-weight-bold">
                      {{ formatNumber(currentSummary.exportGroupsTotalPrevious / 1000) }}
                    </td>
                  </tr>
                  <tr v-for="item in currentSummary.topExportGroups" :key="item.id">
                    <td class="font-weight-bold">{{ item.title }}</td>
                    <td class="font-weight-bold">
                      {{ formatNumber(item.currentValue / 1000) }}
                    </td>
                    <td class="font-weight-bold">
                      {{ formatNumber(item.previousValue / 1000) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
        <!-- ТОП-5 ТОВАРОВ импорта -->
        <template v-if="currentSummary && currentSummary.topImportGroups?.length > 0 && tabId != 2">
          <div class="d-flex justify-content-between my-5">
            <SwitchComponent
              class="customSwitcherInter"
              textTrue="Диаграмма"
              textFalse="Таблица"
              @switchHandler="switchRows.topTovariImport = !switchRows.topTovariImport"
            />
            <h2>Топ-5 товаров импорта</h2>
          </div>
          <!-- Диаграмма -->
          <div v-if="switchRows.topTovariImport">
            <!-- <h2 class="my-5">ТОП-5 ТОВАРОВ импорта</h2> -->
            <ColumnChartDouble
              labelAlignY="end"
              labelAnchorY="end"
              :enableTooltip="false"
              indexAxis="y"
              barPercentage="1"
              :format-label="null"
              label-first="Текущий период тыс $"
              label-second="Прошедший период тыс $"
              color-first="#04b1e0"
              color-second="#00C6A2"
              :chart-data="currentSummary.topImportGroups.map((item) => (item.currentValue / divider).toFixed(0))"
              :chart-data-two="currentSummary.topImportGroups.map((item) => (item.previousValue / divider).toFixed(0))"
              :labels="currentSummary.topImportGroups.map((item) => makeChartLabel(item.title, 90))"
            />
          </div>
          <!-- Таблица -->
          <div v-else class="mt-5">
            <!-- <h2 class="my-5" v-if="currentSummary">
                          ТОП-5 ТОВАРОВ (таблица)
                        </h2> -->
            <div class="table-container mt-5 js-table-arrow">
              <table class="stockTable">
                <thead>
                  <tr>
                    <th style="font-size: 14px" rowspan="2">Наименование</th>
                    <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">
                      Импорт
                    </th>
                  </tr>
                  <tr>
                    <th style="text-align: center">Текущий период тыс $</th>
                    <th>Прошедший период тыс $</th>
                  </tr>
                </thead>
                <tbody v-if="currentSummary">
                  <tr style="background-color: #ade0ff">
                    <td class="font-weight-bold">Всего</td>
                    <td class="font-weight-bold">
                      {{ formatNumber(currentSummary.importGroupsTotalCurrent / 1000) }}
                    </td>
                    <td class="font-weight-bold">
                      {{ formatNumber(currentSummary.importGroupsTotalPrevious / 1000) }}
                    </td>
                  </tr>
                  <tr v-for="item in currentSummary.topImportGroups" :key="item.id">
                    <td class="font-weight-bold">{{ item.title }}</td>
                    <td class="font-weight-bold">
                      {{ formatNumber(item.currentValue / 1000) }}
                    </td>
                    <td class="font-weight-bold">
                      {{ formatNumber(item.previousValue / 1000) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </template>
      <!-- ТОП-5 ТОВАРОВ экспорта снова -->
      <template v-if="tabId === 4 && currentSummary && currentSummary.topExportNonEnergyGroups?.length > 0">
        <div class="d-flex justify-content-between my-5">
          <SwitchComponent
            class="customSwitcherInter"
            textTrue="Диаграмма"
            textFalse="Таблица"
            @switchHandler="switchRows.topTovariExportSecond = !switchRows.topTovariExportSecond"
          />
          <h2>Топ-5 товаров экспорта</h2>
        </div>
        <div v-if="switchRows.topTovariExportSecond">
          <!-- <h2 class="my-5">ТОП-5 ТОВАРОВ экспорта</h2> -->
          <ColumnChartDouble
            labelAlignY="end"
            labelAnchorY="end"
            :enableTooltip="false"
            indexAxis="y"
            barPercentage="1"
            :format-label="null"
            label-first="Текущий период"
            label-second="Прошедший период"
            color-first="#04b1e0"
            color-second="#00C6A2"
            :chart-data="currentSummary.topExportNonEnergyGroups.map((item) => (item.currentValue / 1000).toFixed(2))"
            :chart-data-two="
              currentSummary.topExportNonEnergyGroups.map((item) => (item.previousValue / 1000).toFixed(2))
            "
            :labels="currentSummary.topExportNonEnergyGroups.map((item) => makeChartLabel(item.title, 90))"
          />
        </div>
        <div v-else class="mt-5">
          <!-- <h2 class="my-5" v-if="currentSummary">
                      ТОП-5 ТОВАРОВ (таблица)
                    </h2> -->
          <div class="table-container mt-5 js-table-arrow">
            <table class="stockTable">
              <thead>
                <tr>
                  <th style="font-size: 14px" rowspan="2">Наименование</th>
                  <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">
                    Экспорт
                  </th>
                </tr>
                <tr>
                  <th style="text-align: center">Текущий период</th>
                  <th>Прошедший период</th>
                </tr>
              </thead>
              <tbody v-if="currentSummary">
                <tr style="background-color: #ade0ff">
                  <td class="font-weight-bold">Всего</td>
                  <td class="font-weight-bold">
                    {{ formatNumber(currentSummary.exportNonEnergyGroupsTotalCurrent / 1000) }}
                  </td>
                  <td class="font-weight-bold">
                    {{ formatNumber(currentSummary.exportNonEnergyGroupsTotalPrevious / 1000) }}
                  </td>
                </tr>
                <tr v-for="item in currentSummary.topExportNonEnergyGroups" :key="item.id">
                  <td class="font-weight-bold">{{ item.title }}</td>
                  <td class="font-weight-bold">
                    {{ formatNumber(item.currentValue / 1000) }}
                  </td>
                  <td class="font-weight-bold">
                    {{ formatNumber(item.previousValue / 1000) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </template>

    <div class="mt-5" v-if="!isProductMode && currentSummary && currentSummary.topImportGroups?.length > 0">
      <h2>Туризм</h2>
      <ColumnChartDouble
        label-first="Выезд российских граждан в страну, тыс чел"
        label-second="Въезд иностранных граждан в Российскую Федерацию, тыс чел"
        format-label=" тыс чел"
        :chart-data="currentSummary.topImportGroups.map((item) => item.previousValue.toFixed(0))"
        :chart-data-two="currentSummary.topImportGroups.map((item) => item.currentValue.toFixed(0))"
        :labels="currentSummary.topImportGroups.map((item) => item.title)"
      />
    </div>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';

  import SwitchComponent from '@/components/buttons/switchComponent';
  import ColumnChartDouble from '@/components/columnChartDouble';
  import LineChartDouble from '@/components/lineChartDouble';
  import LineChartFour from '@/components/lineChartFour';

  import IndicatorApi from '@/modules/admin/api/indicatorVisibility';
  import Api from '@/modules/countryAnalyticsNew/api/api';

  export default {
    name: 'InternationalTradeRussiaEconRev',
    mixins: [text],
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    components: {
      ColumnChartDouble,
      LineChartFour,
      LineChartDouble,
      SwitchComponent,
    },
    data() {
      return {
        year: new Date().getFullYear() - 1,
        isProductMode: true,
        tabId: null,
        label: null,
        yearList: [],

        graphByYear: null, //Торговля товарами/услугами, млн $ США (график по годам)
        tableData: null,
        summary: null,

        turnoverMoreInfo: true,
        exportMoreInfo: true,
        importMoreInfo: true,
        exportNonEnergyMoreInfo: true,
        balanceMoreInfo: true,

        divider: 1000000,
        dividerLabel: 'млн',

        switchRows: {
          tovarnayaStructureExport: true,
          tovarnayaStructureExportSecond: true,
          tovarnayaStructureImport: true,
          topTovariExport: true,
          topTovariImport: true,
          topTovariExportSecond: true,
          tradingGraph: false,
        },
      };
    },

    computed: {
      currentSummary() {
        return this.summary?.summary;
      },
    },
    created() {
      this.loadIndicatorVisibility();
      this.loadYearList(true);
      //this.loadData();
    },
    methods: {
      loadIndicatorVisibility() {
        IndicatorApi.search().then((response) => {
          this.turnoverMoreInfo = response.data[13].moreInfoVisible;
          this.importMoreInfo = response.data[14].moreInfoVisible;
          this.exportMoreInfo = response.data[15].moreInfoVisible;
          this.exportNonEnergyMoreInfo = response.data[16].moreInfoVisible;
          this.balanceMoreInfo = response.data[17].moreInfoVisible;
        });
      },
      loadYearList(thenLoadData) {
        this.summary = null;
        this.graphByYear = null;
        Api.getYearList(true, this.isProductMode).then((response) => {
          this.yearList = response.data;
          this.year = this.yearList.slice(-1)[0].id;
          if (thenLoadData) this.loadData();
        });
      },
      loadData() {
        this.summary = null;
        this.graphByYear = null;
        Api.getForeignTradeForOne(true, this.isProductMode, this.year, this.countryId).then((response) => {
          this.summary = response.data;

          this.graphByYear = {
            years: this.summary.summary.valuesByYears.map((x) => x.year),
            data: [
              this.summary.summary.valuesByYears.map((x) => (x.totalValue / 1000000).toFixed(1)),
              this.summary.summary.valuesByYears.map((x) => (x.exportValue / 1000000).toFixed(1)),
              this.summary.summary.valuesByYears.map((x) => (x.importValue / 1000000).toFixed(1)),
              this.summary.summary.valuesByYears.map((x) => (x.balance / 1000000).toFixed(1)),
              this.summary.summary.valuesByYears.map((x) => (x.exportNonEnergyValue / 1000000).toFixed(1)),
            ],
          };

          this.tableData = this.summary.summary.valuesByYears.map((x) => {
            return {
              year: x.year,
              total: x.totalValue,
              export: x.exportValue,
              import: x.importValue,
              balance: x.balance,
              nonEnergy: x.exportNonEnergyValue,
            };
          });

          this.checkCountryId(this.countryId, this.year);
          this.$forceUpdate();
        });
      },
      switchHand(e) {
        this.isProductMode = e;
        this.divider = e ? 1000000 : 1000;
        this.loadYearList(true);
        this.$forceUpdate();
      },
      selectTab(id, label) {
        if (id != this.tabId) {
          this.tabId = id;
          this.label = label;
        } else {
          // при повторном клике - сворачивание
          this.tabId = null;
          this.label = null;
        }
      },
      yearChanged() {
        this.$emit('yearChanged', this.year);
        this.loadData();
      },
      checkCountryId(country, year) {
        console.log(country, year);
        return;
        // console.log(year == 2022)
        // if(year == 2022){
        //   // Китай
        //   if(country == 108){
        //   // Товарооборот
        //     this.currentSummary.totalValue = 113261700000;
        //   // Импорт
        //     this.currentSummary.importValue = 51367100000;
        //   // Экспорт
        //     this.currentSummary.exportValue = 61894600000;
        //   // Несырьевой неэнергетический экспорт
        //     this.currentSummary.exportNonEnergyValue = 9251300000;
        //   // Сальдо возможно убрать
        //     this.currentSummary.balance = 10527500000;
        //   }
        //   // Турция
        //   else if(country == 44){
        //     // Товарооборот
        //       this.currentSummary.totalValue = 38505200000;
        //     // Импорт
        //       this.currentSummary.importValue = 4534601000;
        //     // Экспорт
        //       this.currentSummary.exportValue = 33970500000;
        //     // Несырьевой неэнергетический экспорт
        //       this.currentSummary.exportNonEnergyValue = 9681900000;
        //     // Сальдо возможно убрать
        //       this.currentSummary.balance = 29435900000;
        //   }
        //   // Сербия
        //   else if(country == 249){
        //     // Товарооборот
        //       this.currentSummary.totalValue = 1673300000;
        //     // Импорт
        //       this.currentSummary.importValue = 663600000;
        //     // Экспорт
        //       this.currentSummary.exportValue = 1009700000;
        //     // Несырьевой неэнергетический экспорт
        //       this.currentSummary.exportNonEnergyValue = 354700000;
        //     // Сальдо возможно убрать
        //       this.currentSummary.balance = 346100000;
        //   }
        // }
      },
    },
    watch: {
      countryId: function () {
        this.loadYearList(true);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import './src/assets/theme/default/colors';

  .International-trade {
    &__switchblock {
      flex-wrap: wrap;
      align-items: baseline;
      @media screen and (max-width: 499px) {
        display: block !important;
      }
    }
  }

  .table-custom {
    border-collapse: separate;
    border-spacing: var(--border-width);

    th {
      padding: 13px 11px;
      background-color: #ffffff;
    }

    th,
    td {
      outline: var(--border);
      padding: 8px;
      text-align: center;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #000000;
    }

    th:first-child,
    td:first-child {
      padding: 8px 14px 8px 8px;
      text-align: left;
    }

    tr:nth-child(odd) {
      background-color: #e5f5ff;
    }

    th {
      border-bottom: 1px solid #01a39d !important;
    }
  }

  .table-custom-two {
    thead {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 111;
    }
  }

  .indicator-round {
    span {
      background-color: #018bbf;
      width: 14px;
      height: 14px;
    }

    p {
      margin-left: 13px;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }

  .indicator-round:nth-child(2) {
    p {
      margin-left: 13px;
    }

    span {
      background-color: #787f8f;
    }
  }

  .indicator-round:nth-child(3) {
    span {
      background-color: #2f84eb;
    }
  }

  .indicator-round:nth-child(4) {
    span {
      background-color: #31bc00;
    }
  }

  .indicators-two {
    display: flex;
    align-content: center;
    justify-content: space-between;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 104px;
      width: 100%;

      p {
        margin-bottom: 20px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 113.9%;
        text-align: center;
        color: #333333;
      }

      span {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 8px;
        line-height: 113.9%;
        text-align: center;
        color: #333333;
      }

      strong {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #333333;
        white-space: nowrap;
      }
    }
  }

  h2 {
    margin-bottom: 20px;
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
  }

  .indicators {
    $offset: 15px;

    &__list {
      padding: 0 $offset;
      overflow: auto;
      gap: $offset;
      padding: 12px 4px;
    }

    &__itemCustom {
      cursor: pointer;
      max-width: 180px;
      width: 100%;

      .indicators__wrapperCustom {
        padding: 15px 10px;
      }

      &:hover,
      &.active {
        .indicators__wrapperCustom {
          background: #ffffff;
          box-shadow: 0px 0px 17px rgba(1, 120, 200, 0.5);
          border-radius: 5px;
        }
      }
    }

    &__wrapperCustom {
      transition: 0.2s ease-in-out;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 10px;
    }
  }

  .select2::v-deep {
    outline: none;
    background-color: #ffffff;
    // Контейнер ширина
    .select2-container {
      outline: none;
      width: 100% !important;
      max-width: initial !important;
      border-radius: 40px;
      // Текст
      .select2-selection__rendered {
        outline: none;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: $color1;
        margin-top: 0 !important;
      }
    }

    // Контейнер высота
    .select2-container--default .select2-selection--single {
      outline: none;
      height: auto !important;
      border: none;
      background-color: transparent !important;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
      outline: none;
      top: 50% !important;
      width: 10px;
      right: 14px;
      transform: translateY(-50%) !important;
    }

    // Плейсхолдер
    .select2-container--default .select2-selection--single .select2-selection__placeholder {
      outline: none;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #989898;
    }

    /* Модификация стрелки */
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      outline: none;
      border-color: unset;
      border-style: unset;
      border-width: unset;
      width: 10px;
      height: 10px;
      border-radius: 0;
      border-left: 3px solid $color1 !important;
      border-bottom: 3px solid $color1 !important;
      transform: rotate(315deg);
      transition: 0.3s;
      mask-image: unset;
    }

    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
      transform: rotate(135deg);
      margin-top: -3px !important;
    }
  }

  .select2-container--open .select2-dropdown--below {
    border: 1px solid #01a39d;
  }

  .select2-container--default .select2-results > .select2-results__options {
    li {
      border-top: 1px solid #01a39d !important;
    }

    li:hover {
      background-color: #01a39d;
    }
  }

  .more-info-hidden {
    padding-bottom: 26px;
    pointer-events: none;
  }

  .indicators__itemCustom {
    min-width: 150px;
  }
</style>
