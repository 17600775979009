import { DATA_EMPTY_MESSAGE, QuartalsObject } from '../../utils/constants';

export function getSummaryLifeIndex(state) {
  return state.summaryLifeIndex;
}

export function getInStockInvestment(state) {
  return state.inStockInvestment;
}

export function getOutStockInvestment(state) {
  return state.outStockInvestment;
}

export function getInFlowsInvestment(state) {
  return state.inFlowsInvestment;
}

export function getOutFlowsInvestment(state) {
  return state.outFlowsInvestment;
}

export function getBalanceForeignTradeTurnoverWord(state) {
  return state.balanceForeignTradeTurnoverWord === DATA_EMPTY_MESSAGE ? {} : state.balanceForeignTradeTurnoverWord;
}

export function getGdpValuesForeignTradeTurnoverWord(state) {
  return state.gdpValuesForeignTradeTurnoverWord;
}

export function getInfoAboutUnfriendlyCountry(state) {
  return state.infoAboutUnfriendlyCountry;
}

export function getIndustriesOfTopImport(state) {
  return state.industriesOfTopImport === DATA_EMPTY_MESSAGE ? [] : state.industriesOfTopImport;
}

export function getIndustriesOfTopExport(state) {
  return state.industriesOfTopExport === DATA_EMPTY_MESSAGE ? [] : state.industriesOfTopExport;
}

export function getCountriesOfTopImport(state) {
  return state.countriesOfTopImport === DATA_EMPTY_MESSAGE ? [] : state.countriesOfTopImport;
}

export function getCountriesOfTopExport(state) {
  return state.countriesOfTopExport === DATA_EMPTY_MESSAGE ? [] : state.countriesOfTopExport;
}

export function getBudgetDeficitIndicator(state) {
  return state.budgetDeficitIndicator;
}

export function getStockMarketCapitalizationIndicator(state) {
  return state.stockMarketCapitalizationIndicator;
}

export function getFinancialAccountIndicator(state) {
  return state.financialAccountIndicator;
}

export function getGovernmentBondYieldsIndicator(state) {
  return state.governmentBondYieldsIndicator;
}

export function getTurnoverYearList(state) {
  return state.turnoverYears.filter((x) => x.id > 2016);
}

export function getForeignTradeData(state) {
  return state.foreignTradeData;
}

export function getValueOfMacroEconomicIndicator(state) {
  return state.valueOfMacroEconomicIndicator;
}

export function getSummaryEconomicReview(state) {
  return state.summaryEconomicReview;
}

export function getCountryRatingIndicators(state) {
  return state.countryRatingIndicators;
}

export function getLoadingTopStatisticCountries(state) {
  return (
    state.gdpValuesForeignTradeTurnoverWord != null &&
    state.industriesOfTopImport != null &&
    state.industriesOfTopExport != null &&
    state.countriesOfTopImport != null &&
    state.countriesOfTopExport != null
  );
}

export function getLastExportProduct(state) {
  return state.lastExportProduct;
}

export function getFerRegionsTradeValues(state) {
  return state.ferRegionsTradeValues;
}

export function getFerRegionsTradeCountOfCountry(state) {
  return state.ferRegionsTradeCountOfCountry;
}

export function getQuartals(state) {
  return state.quartals.map(({ id }) => ({ value: id, label: QuartalsObject[id].value }));
}

export function getSummaryQuartal(state) {
  return state.summaryQuartal;
}
