<template>
  <div class="messenger__add-employers-popup d-flex flex-column">
    <div class="d-flex justify-content-between">
      <h6 class="messenger__add-employers-title">{{ title }}</h6>
      <!--      <span v-if="isAddMembersPopup" class="messenger__add-count">1/100</span>-->
    </div>
    <div class="d-flex messenger__input-area">
      <input
        class="messenger__search d-block mt-2 mb-2"
        type="text"
        v-model="subject"
        :placeholder="isAddMembersPopup ? 'Поиск' : 'Название чата'"
      />
      <IconComponent class="ml-auto" v-if="isAddMembersPopup" @click="startSearch" name="Union" />
    </div>
    <ul v-if="employersList.length">
      <li
        v-for="chatMember in employersList"
        :key="chatMember.id"
        class="messenger__dialog-item d-flex justify-content-between"
      >
        <div class="d-flex flex-column">
          <span
            @click="setMemberAddedState(innerEmployerStatuses[chatMember.id])"
            class="messenger__employer-name mb-2"
            >{{ chatMember.fullName }}</span
          >
          <span class="messenger__employer-position">{{ chatMember.position.title }}</span>
        </div>
        <IconComponent
          v-if="
            innerEmployerStatuses && innerEmployerStatuses[chatMember.id] && innerEmployerStatuses[chatMember.id].isAdd
          "
          name="status"
        />
      </li>
    </ul>
    <div class="messenger__btn-group d-flex justify-content-between">
      <button
        @click="cancel"
        class="dis-btn-css messenger__add-employers-btn messenger__add-employers-btn--cancel"
        type="button"
      >
        Отмена
      </button>
      <button
        class="dis-btn-css messenger__add-employers-btn messenger__add-employers-btn--add"
        type="button"
        @click="doAction"
      >
        {{ isAddMembersPopup ? 'Добавить' : 'Создать' }}
      </button>
    </div>
  </div>
</template>

<script>
  /* import { mapActions, mapGetters } from "vuex"; */
  import IconComponent from '../../common/ui/IconComponent';

  export default {
    name: 'MessengerModal',
    components: { IconComponent },
    props: {
      isRoom: {
        default: false,
        type: Boolean,
      },
      isGroup: {
        default: false,
        type: Boolean,
      },
      employersList: {
        default: () => [],
        type: Array,
      },
      employerStatuses: {
        default: () => {},
        type: Object,
      },
    },
    data() {
      return {
        title: this.isGroup ? 'Новая группа' : this.isRoom ? 'Новая комната' : 'Добавить участника',
        isAddMembersPopup: !this.isRoom && !this.isGroup,
        subject: '',
      };
    },
    computed: {
      innerEmployerStatuses: {
        get() {
          return this.employerStatuses;
        },
        set() {
          this.innerEmployerStatuses = this.employerStatuses;
        },
      },
    },
    created() {
      /*   console.log(this.$store); */
      this.disableEmployersPreAddedStatuses();
    },
    /*     watch() {console.log(this.innerEmployerStatuses)}, */
    methods: {
      setMemberAddedState(arg) {
        arg.isAdd = !arg.isAdd;
      },
      disableEmployersPreAddedStatuses() {
        this.employersList.forEach((item) => {
          let id = item.id;
          this.innerEmployerStatuses[id] = {
            isAdd: false,
          };
        });
      },
      doAction() {
        this.$emit('doAction', {
          action: this.isAddMembersPopup ? 'addMembers' : this.isGroup ? 'addGroup' : 'addRoom',
          subject: this.subject,
          employersList: this.employersList,
          employerStatuses: this.innerEmployerStatuses,
        });
      },
      cancel() {
        this.disableEmployersPreAddedStatuses();
        this.$emit('cancel');
      },
      startSearch() {},
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default//global/mixin.scss';
  @import '@/assets/theme/default//global/variables.scss';

  .messenger {
    li {
      cursor: pointer;
    }

    &__input-area {
      border-bottom: 1px solid $gray-l-light;
    }

    &__add-employers-title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      color: $black-1;
    }

    &__add-employers-btn {
      width: 100%;
      max-width: 150px;
      min-height: 32px;
      text-transform: uppercase;
      cursor: pointer;

      &--cancel {
        border: 1px solid #212125 !important;
      }

      &--add {
        background-color: #212125 !important;
        color: $white !important;
      }
    }

    &__dialog-item {
      align-items: center;
      max-height: 75px;
      padding: 15px;
      border-bottom: 1px solid $gray-l-light;
    }

    &__search {
      width: 80%;
      border: none;
      background-color: $white;

      &::placeholder {
        font-size: 14px;
      }
    }

    &__add-employers-popup {
      width: 60% !important;
      max-width: 359px;
      height: 454px;
      padding: 24px 16px 23px 23px;
      @include setPopup();
      left: 120px;
      top: 75px;

      ul {
        overflow-y: auto;
        scrollbar-width: thin;
        margin: 0;
        padding: 0;
        //Временно
        height: 307px;
        margin-bottom: 8px;

        li {
          display: flex !important;
          align-items: flex-start;
          padding-left: 0 !important;
          padding-right: 0 !important;

          span:first-child {
            font-size: 15px;
          }

          span:last-child {
            font-size: 12px;
            color: rgba(33, 33, 37, 0.5);
          }
        }
      }
    }

    &__add-count {
      font-size: 12px;
      color: rgba(33, 33, 37, 0.5);
    }
  }
</style>
