<template>
  <div class="partnership-container" v-if="countryId">
    <div class="title project-tag-title" id="project-statistics">Статистика по проектам</div>
    <ProjectTagControl :data="projectSummary" @tagClicked="onProjectTagClicked" :showTotals="false" />
    <ProjectStatistics :countryId="countryId" :statusId="projectStatusId" @displayTypeChanged="onDisplayTypeChanged" />
    <MpkStatistics :countryId="countryId" />
  </div>
</template>

<script>
  import Api from '@/modules/countryAnalyticsNew/api/api';
  import ProjectTagControl from '@/modules/countryAnalyticsNew/components/nested/ProjectTagControl';
  import MpkStatistics from '@/modules/countryAnalyticsNew/components/Partnership/nested/MpkStatistics';
  import ProjectStatistics from '@/modules/countryAnalyticsNew/components/Partnership/nested/ProjectStatistics';

  export default {
    name: 'PartnershipControl',
    components: { ProjectTagControl, ProjectStatistics, MpkStatistics },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    emits: ['displayTypeChanged'],
    data() {
      return {
        projectSummary: {},
        projectStatusId: 0,
      };
    },
    created() {
      this.loadProjectSummary();
    },
    methods: {
      onProjectTagClicked(item) {
        this.projectStatusId = item.statusId;
      },
      loadProjectSummary() {
        let params = { pageNumber: 1, pageSize: 0 };
        if (this.countryId) params.countryIds = [this.countryId];

        Api.projectSummaryByCountry(params).then((response) => {
          this.projectStatusId = 0;
          this.projectSummary = response.data;
        });
      },
      onDisplayTypeChanged(data) {
        this.$emit('displayTypeChanged', data);
      },
    },
    watch: {
      countryId: function () {
        this.loadProjectSummary();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    color: #282b30;
  }
  .project-tag-title {
    margin-top: 35px;
  }
</style>
