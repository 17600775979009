<template>
  <div class="restrictionStatisticCard" @click="onClick">
    <p class="restrictionStatisticCard__title">
      {{ title }}
    </p>
    <div class="restrictionStatisticCard__subtitle">
      <div class="restrictionStatisticCard__total">
        <div :class="`restrictionStatisticCard__arrow ${getDynamicArrowClass}`"></div>
        <p class="restrictionStatisticCard__quantity">{{ quantity }} шт.</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M9 18L15 12L9 6" stroke="#231F20" stroke-width="2" stroke-linecap="square" />
        </svg>
      </div>
      <div class="restrictionStatisticCard__damageSum" v-if="damageSum">{{ damageSum }} млрд $</div>
    </div>
  </div>
</template>

<script>
  export default {
    emits: ['onClick'],
    props: {
      title: {
        type: String,
        default: '',
      },
      quantity: {
        type: Number,
        default: 0,
      },
      damageSum: {
        type: [Number, String],
        default: 0,
      },
      dynamic: {
        type: Number,
        default: 0,
      },
      positiveArrow: {
        type: String,
        default: 'up',
        validator: (value) => ['down', 'up'].includes(value),
      },
    },
    methods: {
      onClick() {
        this.$emit('onClick');
      },
    },
    computed: {
      getDynamicArrowClass() {
        if (this.dynamic > 0 && this.positiveArrow === 'down') {
          return 'positive-arrow-down';
        }
        if (this.dynamic > 0 && this.positiveArrow === 'up') {
          return 'positive-arrow-up';
        }
        if (this.dynamic < 0 && this.positiveArrow === 'down') {
          return 'negative-arrow-up';
        }
        if (this.dynamic < 0 && this.positiveArrow === 'up') {
          return 'negative-arrow-down';
        }
        return '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .restrictionStatisticCard {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 200px;
    width: 100%;
    cursor: pointer;

    @media (max-width: $laptop) {
      max-width: 400px;
    }

    &__title {
      font: $fira-16;
    }

    &__subtitle {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 8px;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0px 0px 16px 0px rgba(55, 55, 55, 0.08);
    }

    &__total {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__arrow {
      width: 20px;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;

      &.positive-arrow-down {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M10 4V15' stroke='%2328A745' stroke-width='2' stroke-linecap='square' stroke-linejoin='round'/%3E%3Cpath d='M15.8327 10L9.99935 15.8333L4.16602 10' stroke='%2328A745' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E");
      }

      &.negative-arrow-up {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M9.99935 15.8333V4.83333M15.8327 9.83333L9.99935 4L4.16602 9.83333' stroke='%23DC3545' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E");
      }
      &.positive-arrow-up {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M9.99935 15.8333V4.83333M15.8327 9.83333L9.99935 4L4.16602 9.83333' stroke='%2328A745' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E");
      }

      &.negative-arrow-down {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M10 4V15' stroke='%23DC3545' stroke-width='2' stroke-linecap='square' stroke-linejoin='round'/%3E%3Cpath d='M15.8327 10L9.99935 15.8333L4.16602 10' stroke='%23DC3545' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E");
      }
    }

    &__quantity {
      font: $fira-32-B;
      justify-self: start;
      flex-grow: 1;
    }

    &__damageSum {
      font: $fira-12;
      line-height: 12px;
      padding-left: 28px;
    }
  }
</style>
