<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Публикация страниц</p>
      <div class="table-container mb-16 js-table-arrow" v-if="items && items.length > 0">
        <table class="stockTable">
          <thead>
            <tr>
              <th>Наименование</th>
              <th>Ссылка</th>
              <th>Статус</th>
            </tr>
          </thead>
          <tbody v-for="item in items" :key="item.id">
            <tr>
              <td :class="item.parentId ? 'subitem' : ''">
                <input
                  type="text"
                  class="border-bottom"
                  v-model="item.name"
                  maxlength="250"
                  placeholder="Введите название"
                />
              </td>
              <td>{{ item.link }}</td>
              <td>
                <div class="checkboxInput">
                  <input
                    type="checkbox"
                    v-model="item.isActive"
                    :id="'chActive' + item.id"
                    @change="changeActive(item)"
                  />
                  <label :for="'chActive' + item.id">Отображать на сайте</label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <div class="tabs-inner_container card">
          <div class="main-info card-info">
            <p>Не найдено меню.</p>
          </div>
        </div>
      </div>

      <div class="card-row btns" v-if="items && items.length > 0">
        <ButtonStock btnType="secondary" title="Отменить изменения" v-on:click="cancel" />
        <ButtonStock title="Сохранить изменения" v-on:click="save" />
      </div>
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/openClientMenu';

  export default {
    name: 'OpenClientMenu',
    components: {
      ButtonStock,
    },
    mixins: [security],
    data() {
      return {
        items: [],
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.AdminAccess).then((response) => {
        if (response.data) {
          this.loadMenu();
        } else {
          this.notAccess();
        }
      });
    },
    methods: {
      changeActive(item) {
        if (!item.parentId && !item.isActive) {
          console.log(item);
          this.items.forEach((w) => {
            if (w.parentId == item.id) w.isActive = false;
          });
        }
      },
      loadMenu() {
        API.search().then((response) => {
          this.items = response.data;
        });
      },
      cancel() {
        this.loadMenu();
      },
      save() {
        var itemsToSave = [];
        this.items.forEach((w) => {
          itemsToSave.push({
            id: w.id,
            isActive: w.isActive,
            name: w.name,
          });
        });

        API.save(itemsToSave)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && response.data) {
              this.items = response.data;
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .btns button {
    margin-right: 1rem;
  }
  .table-container table {
    min-width: auto;
    width: 100%;
  }
  .table-container table.stockTable .subitem {
    padding-left: 3rem;
  }
  input.border-bottom {
    border-width: 0 0 1px 0;
    width: 100%;
    min-width: 450px;
    font-size: 12px;
  }
</style>
