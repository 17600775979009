<template>
  <div>
    <div class="table-container analysisTable mb-16 js-table-arrow">
      <SwitchComponent
        class="massMedia_toggler mb-16"
        textTrue="Внутренние"
        textFalse="Внешние"
        @switchHandler="changeMediaType($event)"
      />

      <DefaultFilter :filterValue="currentFilter" @loadFilter="onLoadFilter" />

      <table class="stockTable">
        <thead>
          <tr>
            <th>Категория</th>
            <th>Товар</th>
            <th>Страна</th>
            <th class="col-4">{{ isInternal ? 'Анонс' : 'Текст новости' }}</th>
            <th class="col-3">Ссылка</th>
            <th v-if="canEdit && isInternal"></th>
          </tr>
          <tr>
            <th class="filter table__massmedia-filter">
              <FilterInput
                v-if="isInternal"
                :select2Options="categoryList"
                placeholder="Выберите..."
                type="select2"
                v-model="filter.categoryId"
                :select2Settings="select2Settings"
                v-on:valueChangedDebounced="changeFilter(index)"
              />
              <FilterInput
                v-else
                :select2Options="categoryListExternal"
                placeholder="Выберите..."
                type="select2"
                v-model="filterExternal.relation"
                :select2Settings="select2Settings"
                v-on:valueChangedDebounced="changeFilter(index)"
              />
            </th>
            <th class="filter table__massmedia-filter">
              <FilterInput
                v-if="isInternal"
                :select2Options="smtkList"
                placeholder="Выберите..."
                type="select2"
                v-model="filter.smtkId"
                :select2Settings="select2Settings"
                v-on:valueChangedDebounced="changeFilter(index)"
              />
              <FilterInput
                v-else
                :select2Options="smtkListExternal"
                placeholder="Выберите..."
                type="select2"
                v-model="filterExternal.product_branch"
                :select2Settings="select2Settings"
                v-on:valueChangedDebounced="changeFilter(index)"
              />
            </th>
            <th class="filter">
              <FilterInput
                v-if="isInternal"
                :select2Options="countryList"
                placeholder="Выберите..."
                type="select2"
                v-model="filter.countryId"
                :select2Settings="select2Settings"
                v-on:valueChangedDebounced="changeFilter(index)"
              />
              <FilterInput
                v-else
                :select2Options="countryListExternal"
                placeholder="Выберите..."
                type="select2"
                v-model="filterExternal.country"
                :select2Settings="select2Settings"
                v-on:valueChangedDebounced="changeFilter(index)"
              />
            </th>
            <th class="filter">
              <FilterInput
                v-if="isInternal"
                placeholder="Анонс"
                v-model="filter.brief"
                v-on:valueChangedDebounced="changeFilter(index)"
              />
            </th>
            <th class="filter">
              <FilterInput
                v-if="isInternal"
                placeholder="Ссылка"
                v-model="filter.url"
                v-on:valueChangedDebounced="changeFilter(index)"
              />
            </th>
            <th class="filter" v-if="canEdit && isInternal"></th>
          </tr>
        </thead>
        <tbody v-if="isInternal && page?.items?.length && !isLoading">
          <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item.id)">
            <td>{{ item.categoryNames }}</td>
            <td>{{ item.smtkNames }}</td>
            <td>{{ item.countryNames }}</td>
            <td>{{ item.brief }}</td>
            <td>
              <a :href="item.url" target="_blank">{{ item.url }}</a>
            </td>
            <td v-if="canEdit && isInternal">
              <IconComponent @click="deleteCard(item.id)" name="delete" />
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="!isInternal && pageExternal?.results?.length && !isLoading">
          <tr v-for="item in pageExternal.results" :key="item.id" @dblclick="viewCard(item)">
            <td>{{ item.classes }}</td>
            <td>{{ item.itc_codes }}</td>
            <td>{{ item.locations }}</td>
            <td class="table__massmedia_title-column">{{ item.title }}</td>
            <td>
              <a class="table__massmedia_link" :href="item.url" target="_blank">{{ item.url }}</a>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="isLoading">
          <tr>
            <td class="text-center" :colspan="canEdit ? 6 : 5">Загрузка...</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" :colspan="canEdit ? 6 : 5">Нет данных.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="isInternal && page.pagesTotal > 1">
      <Pagination
        :page-count="page.pagesTotal"
        :current-page="page.pageNumber"
        @change="onPageChange($event, false)"
        @more="onPageChange($event, true)"
      />
    </div>
    <div v-if="!isInternal && pageExternal.count > 1">
      <Pagination
        :page-count="pageExternal.count"
        :current-page="pageNumberExternal"
        @change="onPageChange($event, false)"
        @more="onPageChange($event, true)"
      />
    </div>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils';

  import SwitchComponent from '@/components/buttons/switchComponent';

  import IconComponent from '../../../common/ui/IconComponent';
  import Api from '../api/massmedia';

  export default {
    name: 'MassMedia',
    props: {
      nn: Number,
    },
    components: {
      FilterInput,
      IconComponent,
      SwitchComponent,
      DefaultFilter,
    },
    watch: {
      nn: function () {
        this.init();
      },
    },
    mixins: [security, filtermanager],
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        page: {},

        filter: {
          url: '',
          brief: '',
          countryId: null,
          categoryId: null,
          smtkId: null,
        },

        countryList: [],
        categoryList: [],
        smtkList: [],
        select2Settings: Constants.select2Settings,
        isLoading: false,
        isLoad: false,
        canEdit: false,
        isInternal: true,
        countryListExternal: [],
        categoryListExternal: [],
        smtkListExternal: [],

        pageNumberExternal: 1,
        pageExternal: {},
        filterExternal: {
          country: '',
          product_branch: '',
          relation: '',
        },
      };
    },
    created() {
      this.loadFilter('massMedia').then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter.filter;
          this.filterExternal = filter.filterExternal;
        }
      });
    },
    methods: {
      init() {
        if (!this.isLoad) {
          this.checkPermission(Constants.Permissions.MassMediaEdit).then((response) => {
            this.canEdit = response.data;
          });
        }
        this.loadDictionaries();
        this.loadPage(false);
      },
      loadPage(append) {
        if (this.isInternal == true) {
          this.isLoading = true;
          const request = Object.assign(this.filter, this.request);
          Api.search(request)
            .then((response) => {
              if (!append) {
                this.page = response.data;
              } else if (response.data) {
                this.page.pageNumber = response.data.pageNumber;
                this.page.items = this.page.items.concat(response.data.items);
              }
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              console.error(error?.response?.data);
            });
        } else this.loadPageExternal(append);
      },
      loadDictionaries() {
        if (!this.isLoad) {
          Utils.loadSelectOptions('countryList', this.countryList);
          Utils.loadSelectOptions('massMediaCategory', this.categoryList);
          Utils.loadSelectOptions('massMediaSmtk', this.smtkList);
          this.loadDictionariesExternal();
          this.isLoad = true;
        }
      },
      editCard(id) {
        this.$router.push({ name: 'MassMediaCard', params: { id: id, action: this.canEdit ? 'edit' : 'view' } });
      },
      onPageChange(pageNumber, addToExist) {
        if (this.isInternal) this.request.pageNumber = pageNumber;
        else this.pageNumberExternal = pageNumber;

        this.loadPage(addToExist);
      },
      changeFilter() {
        this.pageNumberExternal = 1;
        this.request.pageNumber = 1;

        this.saveFilter(this.currentFilter, 'massMedia');

        this.loadPage(false);
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            Api.delete(id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      changeMediaType(e) {
        this.isInternal = e;
        this.request.pageNumber = 1;
        this.loadPage();
      },
      loadPageExternal(append) {
        this.isLoading = true;
        const request = Object.assign(this.filterExternal, { page: this.pageNumberExternal });
        Api.searchExternal(request)
          .then((response) => {
            if (!append) {
              this.pageExternal = response.data;
            } else if (response.data) {
              this.pageExternal.results = this.pageExternal.results.concat(response.data.results);
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error?.response?.data);
          });
      },
      loadDictionariesExternal() {
        Api.getCountries().then((response) => {
          this.countryListExternal = response.data;
        });
        Api.getProductBranches().then((response) => {
          this.smtkListExternal = response.data;
        });
        Api.getCategories().then((response) => {
          this.categoryListExternal = [];
          for (var key in response.data) {
            this.categoryListExternal.push(key);
          }
        });
      },
      viewCard(item) {
        this.$router.push({
          name: 'MassMediaCard',
          params: { action: 'view', id: 'external', item: JSON.stringify(item) },
        });
      },
      onLoadFilter(item) {
        if (item) {
          this.filter = item.filter;
          this.filterExternal = item.filterExternal;
        }
        this.changeFilter();
      },
    },
    computed: {
      currentFilter() {
        let filter = {
          filter: this.filter,
          filterExternal: this.filterExternal,
        };
        return filter;
      },
    },
  };
</script>

<style lang="scss">
  .massMedia_toggler .one {
    left: -85px;
  }

  .massMedia_toggler .two {
    right: -70px;
  }

  .massMedia_toggler {
    margin-left: 100px !important;
    margin-top: 10px;
  }

  .table__massmedia {
    &-filter {
      max-width: 230px;
    }
    &_link {
      word-break: break-all;
      display: inline-block;
    }
  }
</style>
