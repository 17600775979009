export const MPK_PROJECT = 'mpkProject';

export const MPK_PROJECT_ACTIONS_NAME = {
  getMpkProjectDepartment: `${MPK_PROJECT}/getMpkProjectDepartment`,
  getMpkProjectMinRole: `${MPK_PROJECT}/getMpkProjectMinRole`,
  getMpkProjectList: `${MPK_PROJECT}/getMpkProjectList`,
  getMpkProjectSupportMeasure: `${MPK_PROJECT}/getMpkProjectSupportMeasure`,
  getMpkProjectCategoryList: `${MPK_PROJECT}/getMpkProjectCategoryList`,
  getMpkProjectTypeEntry: `${MPK_PROJECT}/getMpkProjectTypeEntry`,
  getMpkProjectSearchLight: `${MPK_PROJECT}/getMpkProjectSearchLight`,
  getMpkProjectSearch: `${MPK_PROJECT}/getMpkProjectSearch`,
};

export const MPK_PROJECT_MUTATIONS_NAME = {
  setMpkProjectDepartment: 'setMpkProjectDepartment',
  setMpkProjectMinRole: 'setMpkProjectMinRole',
  setMpkProjectList: 'setMpkProjectList',
  setMpkProjectSupportMeasure: 'setMpkProjectSupportMeasure',
  setMpkProjectCategoryList: 'setMpkProjectCategoryList',
  setMpkProjectTypeEntry: 'setMpkProjectTypeEntry',
  setMpkProjectSearchLight: 'setMpkProjectSearchLight',
  setMpkProjectSearch: 'setMpkProjectSearch',
};

export const MPK_PROJECT_GETTERS_NAME = {
  getMpkProjectDepartments: `${MPK_PROJECT}/getMpkProjectDepartments`,
  getMpkProjectMinRoles: `${MPK_PROJECT}/getMpkProjectMinRoles`,
  getMpkListProjects: `${MPK_PROJECT}/getMpkListProjects`,
  getMpkProjectSupportMeasures: `${MPK_PROJECT}/getMpkProjectSupportMeasures`,
  getMpkProjectCategories: `${MPK_PROJECT}/getMpkProjectCategories`,
  getMpkProjectTypeEntries: `${MPK_PROJECT}/getMpkProjectTypeEntries`,
  getLightSearchMpkProjects: `${MPK_PROJECT}/getLightSearchMpkProjects`,
  getSearchMpkProjects: `${MPK_PROJECT}/getSearchMpkProjects`,
};
