<template>
  <div class="barrierDiagramByYear">
    <h4 class="barrierDiagramByYear__title">{{ title }}</h4>
    <div class="barrierDiagramByYear__diagram-container">
      <ChartContainer>
        <!-- Здесь диаграмма -->
        <slot />
      </ChartContainer>
      <div class="barrierDiagramByYear__cards">
        <h5 class="barrierDiagramByYear__barrier-quantity">Количество барьеров</h5>
        <div class="barrierDiagramByYear__cards-container">
          <QuantityBarrierCard
            v-for="item in barrierData"
            :quantity="item.barrierQuantity"
            :interval="`${item.year} год`"
            :key="item.year"
          />
          <QuantityBarrierCard v-if="!barrierData.length" :quantity="0" interval="весь период" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ChartContainer from '@/components/ChartComponents/ChartContainer.vue';

  import QuantityBarrierCard from './QuantityBarrierCard.vue';

  export default {
    components: { QuantityBarrierCard, ChartContainer },
    props: {
      title: String,
      barrierData: {
        type: Array,
        default: () => [],
      },
    },
  };
</script>

<style lang="scss" scoped>
  .barrierDiagramByYear {
    &__title {
      font: $fira-20;
      font-weight: 500;
      margin-bottom: 24px;
    }

    &__diagram-container {
      display: flex;
      gap: 16px;

      @media (max-width: $tablet) {
        flex-direction: column;
      }
    }

    &__cards {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__barrier-quantity {
      font: $fira-20;
      letter-spacing: -0.4px;
    }

    &__cards-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
      max-width: 848px;

      @media (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
</style>
