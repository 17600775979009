<template>
  <a v-if="type === 'link'" :href="link" :class="['button-redesign', mod ? `button-redesign--${mod}` : '']">
    <slot />
  </a>
  <button v-else :class="['button-redesign', mod ? `button-redesign--${mod}` : '']" :disabled="disabled">
    <slot />
  </button>
</template>

<script>
  export default {
    name: 'ButtonComponent',
    props: {
      type: {
        type: String,
        default: '',
      },
      link: {
        type: String,
        default: '/',
      },
      mod: {
        type: String,
        validator(value) {
          return [
            'transparent-bg',
            'gradient-bg',
            'border-ellipse',
            'border',
            'more',
            'download-icon',
            'grey',
            'blue-color',
            'border-grey',
            'only-blue-text',
          ].includes(value);
        },
      },
      disabled: {
        type: Boolean,
      },
    },
  };
</script>

<style lang="scss">
  @import './src/assets/theme/default/colors';

  .button-redesign {
    // общие стили кнопок
    display: inline-block;
    padding: 12px 16px;
    border-radius: 4px;
    background: $light-grey-6;
    outline: none;
    transition: all 0.3s ease;
    color: $blue;
    border: none;
    font: 500 15px / 16px 'Fira Sans';
    letter-spacing: -0.3px;
    text-align: center;

    span,
    p {
      color: inherit;
      font: inherit;
    }

    &:hover,
    &:active {
      background: $light-blue;
    }

    &--transparent-bg {
      background: transparent;

      &:hover,
      &:active {
        background: transparent;
        text-decoration: underline;
      }
    }

    &--md {
      padding: 16px 12px;
      font: $fira-16;
    }

    &--gradient-bg {
      background: transparent;
      color: #fff;
      position: relative;
      z-index: 1;

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 4px;
        background: linear-gradient(90deg, #0078c8 0%, #01a39d 100%);
        z-index: -1;
      }

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        border-radius: 4px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
          linear-gradient(90deg, #0078c8 0%, #01a39d 100%);
        opacity: 0;
        transition: 0.5s;
        z-index: -1;
      }

      &:hover,
      &:active {
        background: transparent;
        &::after {
          opacity: 1;
        }
      }
    }

    &--border-ellipse {
      padding: 8px 12px;
      border-radius: 12px;
      border: 1px solid $grey;
      background: $light-grey;
      margin-right: 8px;
      font: 14px / 20px 'Fira Sans';
      color: #000;
      letter-spacing: 0;
      white-space: nowrap;
      transition: 0.3s;

      &:last-child {
        margin-right: 0;
      }

      .number {
        font: 14px / 20px 'Fira Sans';
        color: $blue;
        transition: all 0.3s ease;
      }

      &:hover,
      &:active,
      &.active {
        background: $blue;
        color: #fff;
        .number {
          color: #fff;
        }
      }
    }

    &--border {
      background: transparent;
      border: 1px solid $color13;
      transition: 0.3s;

      &:hover,
      &:active {
        background: transparent;
      }
    }

    &--more {
      padding: 0;
      background: transparent;
      display: flex;
      align-items: center;
      font: 15px / 20px 'Fira Sans';
      color: $green;
      transition: 0.3s;

      .icon {
        width: 16px;
        height: 16px;
        margin-left: 4px;
        stroke: $green;

        @media (min-width: $tablet) {
          .exportPdf & {
            margin-bottom: -15px;
          }
        }
      }

      &:hover,
      &:active {
        background: transparent;
        opacity: 0.5;
      }
    }

    &--download-icon {
      background: transparent;
      display: flex;
      align-items: center;
      border: 1px solid $green;
      border-radius: 4px;
      gap: 8px;
      color: $green;
      justify-content: center;
      transition: 0.3s;

      span {
        font: 500 15px / 16px 'Fira Sans';
        color: $green;
      }
    }

    &--download-icon-fill {
      display: flex;
      justify-content: center;
      gap: 5px;

      .icon {
        width: 16px;
        height: 16px;
      }
    }

    &--grey {
      background: $grey-2;
      color: #fff;
      border-radius: 0;
      font: $fira-12;
      padding: 12px 20px;
      letter-spacing: 0;
      transition: 0.3s;

      &.active,
      &:hover {
        background: $blue-3;
      }
    }

    &--blue-color {
      color: $blue;
      background-color: $light-grey-6;
      transition: 0.3s;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid $blue;
        background-color: $light-grey-6;
      }
    }

    &:disabled,
    &.disabled {
      pointer-events: none;
      cursor: none;
      background: $grey;
      color: $base;

      &::after,
      &::before {
        content: none;
      }
    }

    &--border-grey {
      background: transparent;
      border: 1px solid $light-grey-11;
      color: $base;
      font-family: 'Fira Sans';
    }

    &--only-blue-text {
      all: unset;
      font: $fira-16-M;
      color: $blue;

      @media (max-width: $mobile) {
        font: $fira-14-M;
      }

      &:hover {
        background: none;
        color: $blue-4;
      }
    }

    // уникальные стили кнопок

    &--mpk-commissions {
      &:last-child {
        margin-left: 12px;
      }
    }

    &--map-apply {
      margin-right: 8px;
    }

    &--filter {
      background: transparent;
      text-transform: uppercase;
      font: 700 15px / 30px 'Fira Sans';
      color: $green;
      display: flex;
      align-items: center;
      padding: 4px 0px;

      .icon {
        width: 20px;
        height: 20px;
        margin-left: 4px;
        path {
          stroke: $green;
        }
      }

      &:hover,
      &:active {
        background: transparent;
        color: $green;
        opacity: 0.5;
      }
    }

    &--switch {
      border-radius: 3px;
      border: none;
      background: $grey;
      padding: 8px 16px;

      &:hover,
      &:active {
        background: $grey;
      }

      span {
        font: inherit;
        color: inherit;
      }

      &.selected {
        background: #fff;
      }
    }

    &--show-more {
      display: flex;
      flex-direction: row;
      align-content: center;
      background-color: transparent;
      font-size: 15px;
      line-height: 20px;
      padding: 0;
      color: $color8;
      transition: color ease 0.3s;

      &:hover,
      &:active {
        background: transparent;
        color: #007b75;
      }
    }

    &--commission-form {
      width: 100%;
      text-align: center;
    }

    &--cross-cutting-taskfilter {
      &:not(:first-child) {
        margin-left: 8px;
      }

      &.right {
        margin-left: auto;
        margin-right: 0;
      }
    }

    &--tabs-analytics {
      white-space: nowrap;
    }

    @media (min-width: $tablet) {
      .exportPdf & {
        &.button-redesign--gradient-bg::before,
        &.button-redesign--gradient-bg::after {
          margin-top: 14px;
        }
      }
    }

    @media (max-width: $mobile) {
      &--mpk-commissions {
        width: 100%;
        text-align: center;
        &:last-child {
          margin: 8px 0 0;
        }
      }

      &--my-task {
        order: 2;
        margin-right: 16px;
      }

      &--create-task {
        order: 3;
      }

      &--mobile-full {
        width: 100%;
      }

      &--commission-form-edit {
        width: 100%;
        margin: 0;
        &:last-child {
          margin-top: 8px;
        }
      }

      &--cross-cutting-taskfilter {
        width: 100%;
        &:not(:first-child) {
          margin-left: 0;
          margin-top: 8px;
        }

        &.right {
          margin-left: 0;
        }
      }

      &--download-icon {
        width: 100%;
      }

      &--popup-measure {
        flex: 1 1;
      }
    }
  }
</style>
