<template>
  <!-- Тестовая страница -->
  <div class="container-page">
    <div class="list-of-users">
      <!-- title -->
      <p class="list-of-users__title title-inner">Пользователи системы</p>
      <!-- search -->
      <div class="list-of-users__search mb-16">
        <div class="list-of-users__search-container">
          <div class="list-of-users__search-inputBlock">
            <p>Поиск</p>
            <input
              class="list-of-users__search-inputBlock__input"
              placeholder="ФИО"
              type="text"
              v-model="searchName"
              v-on:keyup.enter.prevent="search(true)"
            />
            <IconComponent name="search2" @click="search(true)" />
          </div>

          <div class="list-of-users__search-inputBlock">
            <p>Сортировать</p>
            <select-component
              :options="sort"
              placeholder="По-умолчанию"
              v-model="filter.sortOrder"
              v-on:select2OnSelect="search(false)"
            />
          </div>
        </div>
        <div class="checkboxInput">
          <input type="checkbox" v-model="filter.includeDeleted" id="chIncludeDeleted" @change="search(false)" />
          <label for="chIncludeDeleted">Показывать удаленных пользователей</label>
        </div>
      </div>
      <div class="mb-16">
        <ButtonStock title="Создать" @click="editCard(0)" />
      </div>
      <!-- table -->
      <div v-if="page.items">
        <div class="list-of-users__table table-container js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Роль</th>
                <th>Описание роли</th>
                <th>ФИО</th>
                <th>Дата регистрации</th>
                <th>Организация</th>
                <th>Активность</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in page.items" :key="item.id" @dblclick="editCard(item.id)">
                <td>{{ item.id }}</td>
                <td>
                  <template v-if="$store.state.auth?.oidc?.profile?.employee?.id == item.id">
                    {{ item.roleNames && item.roleNames.length > 0 ? item.roleNames[0] : '' }}
                  </template>
                  <template v-else>
                    <a href="javascript:" @click.prevent="changeRole(item)">{{
                      item.roleNames && item.roleNames.length > 0 ? item.roleNames[0] : ''
                    }}</a>
                  </template>
                </td>
                <td>{{ item.roleDescriptions && item.roleDescriptions.length > 0 ? item.roleDescriptions[0] : '' }}</td>
                <td>{{ item.fullName }}</td>
                <td>{{ $momentFormat(item.created, 'DD.MM.YYYY HH:mm') }}</td>
                <td>{{ item.organization ? item.organization.name : '' }}</td>
                <td>
                  <div class="table-statusBlock">
                    <template v-if="item.user?.isDisabled">
                      <p>
                        Заблокирован
                        <small>(учетная запись была деактивирована в связи с бездействием в течении 60 дней)</small>
                      </p>
                      <IconComponent class="cursorInitial" name="banSigh" />
                    </template>
                    <template v-else-if="item.user?.isDeleted">
                      <p>Пользователь удален</p>
                      <IconComponent class="cursorInitial" name="banSigh" />
                    </template>
                    <template v-else-if="item.user?.isActive && !item.user?.isApproved">
                      <p>Ожидает подтверждения</p>
                      <IconComponent class="cursorInitial" name="banSigh" />
                    </template>
                    <template v-else>
                      <p>{{ item.isActive ? 'Активен' : 'Заблокирован' }}</p>
                      <IconComponent class="cursorInitial" :name="item.isActive ? 'goodSigh' : 'banSigh'" />
                    </template>
                  </div>
                </td>
                <td>
                  <IconComponent @click="editCard(item.id)" name="mode" />
                </td>
                <td>
                  <IconComponent v-if="item.user.isDeleted" @click="restoreCard(item.id)" name="refreshSigh" />
                  <IconComponent v-else @click="deleteCard(item.id)" name="delete" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="page.pagesTotal > 1">
          <Pagination
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
        <section>
          <ModalComponent title="Изменение роли" @close="dialogRole = false" v-model="dialogRole">
            <div class="form__body">
              <div class="form__body-inputBlock mb-3 pb-3" v-if="selectedEmployee">
                <p>Роль</p>
                <ComponentInput
                  placeholder="Укажите роль"
                  :select2Settings="select2Settings"
                  :select2Options="roles"
                  v-model="selectedEmployee.roleId"
                  type="select2"
                  required
                />
              </div>
              <div class="form__body-inputBlock">
                <ButtonStock title="Сохранить" @click="saveUserRole" />
                <ButtonStock class="ml-2" @click="dialogRole = false" btnType="whiteGreenBordered" title="Закрыть" />
              </div>
            </div>
          </ModalComponent>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  // Additional
  import ComponentInput from '@/common/components/ComponentInput';
  import selectComponent from '@/common/components/selectComponent.vue';
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import RoleApi from '../api/rolesPermissions';
  import API from '../api/users';

  export default {
    name: 'Users',
    components: {
      IconComponent,
      ButtonStock,
      selectComponent,
      ModalComponent,
      ComponentInput,
    },
    mounted() {
      this.loadDictionaries();
      this.loadPage();
    },
    methods: {
      loadDictionaries() {
        RoleApi.loadRoles({ pageNumber: 1, pageSize: 0, isActive: true }).then((response) => {
          if (response.data && response.data.items) {
            this.roles = response.data.items
              .filter((x) => !x.isGroupRole)
              .map((o) => {
                return { text: o.title, id: o.id };
              });
          }
        });
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(addToExist) {
        const request = Object.assign(this.filter, this.request);
        API.search(request).then((response) => {
          if (!addToExist) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
              console.log(response.data);
            });
          } else if (response.data) {
            //добавляем к существующему
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
        });
      },
      editCard(id) {
        this.$router.push({ name: 'UserCard', params: { id: id, action: 'edit' } });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(id)
              .then(() => {
                Constants.alert.fire('Удаление', 'Запись удалена.', 'success');
                this.loadPage();
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : Constants.commonError,
                  'error',
                );
              });
          }
        });
      },
      restoreCard(id) {
        API.undelete(id)
          .then(() => {
            Constants.alert.fire('Восстановление', 'Запись восстановлена.', 'success');
            this.loadPage();
          })
          .catch(() => {
            Constants.alert.fire('Восстановление', 'Произошла ошибка', 'error');
          });
      },
      search(isNameChange) {
        if (isNameChange) this.filter.fullName = this.searchName;
        this.request.pageNumber = 1;
        this.loadPage(false);
      },
      changeRole(item) {
        this.selectedEmployee = item;
        this.selectedEmployee.roleId =
          this.selectedEmployee.user.roleIds && this.selectedEmployee.user.roleIds.length > 0
            ? this.selectedEmployee.user.roleIds[0]
            : null;
        this.dialogRole = true;
      },
      saveUserRole() {
        if (!this.selectedEmployee.roleId || this.selectedEmployee.roleId == 0) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения поля', 'error');
          return;
        }

        if (this.selectedEmployee.roleId) this.selectedEmployee.user.roleIds = [this.selectedEmployee.roleId];

        API.updateUser(this.selectedEmployee.user)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.dialogRole = false;
            this.$nextTick(() => {
              this.selectedEmployee = null;
              this.request.pageNumber = 1;
              this.loadPage(false);
            });
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
            this.dialogRole = false;
          });
      },
    },
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        sort: [
          { text: 'По-умолчанию', id: '1' },
          { text: 'По алфавиту', id: '2' },
          { text: 'По активности', id: '3' },
        ],
        searchName: '',
        filter: {
          fullName: '',
          sortOrder: '1',
          includeDeleted: false,
        },
        roles: [],
        selectedEmployee: null,
        dialogRole: false,
        select2Settings: Constants.select2Settings,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    color: #01a39d;
  }

  .list-of-users {
    padding-bottom: 80px;
    &__search {
      border-bottom: 1px dashed #d7d7d7;
      &-container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin-bottom: 8px;
      }
      &-inputBlock {
        position: relative;
        &:nth-of-type(1) {
          margin-right: 16px;
          max-width: 735px;
          width: 100%;
          input {
            width: 100%;
            padding: 8px 21px;
            border-radius: 5px;
          }
          .icon {
            position: absolute;
            bottom: 12px;
            right: 12px;
          }
        }
        &:nth-of-type(2) {
          max-width: 426px;
          width: 100%;
        }
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.15px;
          color: #343c44;
          margin-bottom: 8px;
        }
        &__input {
          line-height: 24px;
        }
      }
    }
    &__table {
      table {
        thead {
          th {
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            color: #3b4d3e;
            padding: 16px;
            &:nth-of-type(1) {
              width: 177px;
            }
            &:nth-of-type(2) {
              width: 347px;
            }
            &:nth-of-type(3) {
              width: 173px;
            }
            &:nth-of-type(4) {
              width: 216px;
            }
            &:nth-of-type(5) {
              width: 291px;
            }
            &:not(:last-child) {
              border-right: 1px solid #787f8f;
            }
            &:nth-of-type(7),
            &:nth-of-type(8) {
              border-right: none;
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid #787f8f;
          }
          td {
            padding: 16px;
            font-size: 14px;
            line-height: 150%;
            color: #3b4d3e;
            &:not(:last-child) {
              border-right: 1px solid #787f8f;
            }
            &:nth-of-type(7),
            &:nth-of-type(8) {
              border-right: none;
            }
          }
        }
        .table-statusBlock {
          display: flex;
          p {
            margin-right: auto;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
</style>
