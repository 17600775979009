import RestrictionMeasureApi from '@/modules/foreignMarket/api/restrictmeasures';

export default {
  state: {
    // селект продуктов
    restrictionProductSelect: [],
    // селект услуг
    restrictionServicesSelect: [],
    // данные по продукту/меры
    restrictionProductMeasure: [],
    // данные по продукту/угрозы
    restrictionProductThreat: [],
    // данные по услуги/меры
    restrictionServicesMeasure: [],
    // данные по услуги/угрозы
    restrictionServicesThreat: [],
    // полный список
    restrictionFullList: [],
  },
  getters: {
    getRestrictionProductsSelect(state) {
      return state.restrictionProductSelect;
    },
    getRestrictionServicesSelect(state) {
      return state.restrictionServicesSelect;
    },
    getRestrictionProductMeasure(state) {
      return state.restrictionProductMeasure;
    },
    getRestrictionProductThreat(state) {
      return state.restrictionProductThreat;
    },
    getRestrictionServicesMeasure(state) {
      return state.restrictionServicesMeasure;
    },
    getRestrictionServicesThreat(state) {
      return state.restrictionServicesThreat;
    },
    getRestrictionFullList(state) {
      return state.restrictionFullList;
    },
  },
  mutations: {
    updateRestrictionProducts(state, restrictionProductsData) {
      let threats = [],
        measures = [];
      restrictionProductsData.forEach((item) => {
        if (item.barrierCategory) {
          if (item.barrierCategory.name === 'Мера') {
            measures.push(item);
          } else if (item.barrierCategory?.name === 'Угроза') {
            threats.push(item);
          }
        }
      });

      // запись селекта продуктов
      state.restrictionProductSelect = [];
      restrictionProductsData
        .filter((x) => x.tnveds.length > 0)
        .forEach((x) => {
          let items = x.tnveds.map((item) => ({
            text: item.code + ' - ' + item.name,
            id: item.id,
            code: item.code,
            searchText: item.name,
          }));
          state.restrictionProductSelect = state.restrictionProductSelect.concat(items);
        });

      state.restrictionProductSelect = state.restrictionProductSelect.sort((a, b) => {
        return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
      });

      const key = 'id';
      state.restrictionProductSelect = [
        ...new Map(state.restrictionProductSelect.map((item) => [item[key], item])).values(),
      ];

      state.restrictionProductMeasure = measures;
      state.restrictionProductThreat = threats;
    },
    updateRestrictionServices(state, restrictionServicesData) {
      let threats = [],
        measures = [];
      restrictionServicesData.forEach((item) => {
        if (item.barrierCategory) {
          if (item.barrierCategory.name === 'Запрет') {
            measures.push(item);
          } else if (item.barrierCategory.name === 'Ограничение') {
            threats.push(item);
          }
        }
      });
      // запись селекта услуг
      state.restrictionServicesSelect = [];
      restrictionServicesData
        .filter((x) => x.okveds.length > 0)
        .forEach((x) => {
          let items = x.okveds.map((item) => ({
            text: item.code + ' - ' + item.name,
            id: item.id,
            code: item.code,
            searchText: item.name,
          }));
          state.restrictionServicesSelect = state.restrictionServicesSelect.concat(items);
        });

      state.restrictionServicesSelect = state.restrictionServicesSelect.sort((a, b) => {
        return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
      });
      const key = 'id';
      state.restrictionServicesSelect = [
        ...new Map(state.restrictionServicesSelect.map((item) => [item[key], item])).values(),
      ];

      state.restrictionServicesMeasure = measures;
      state.restrictionServicesThreat = threats;
    },
    updateFullList(state, fullList) {
      state.restrictionFullList = fullList;
    },
  },
  actions: {
    downloadRestrictionActive(ctx, request = { isActive: true }) {
      RestrictionMeasureApi.search(request).then((response) => {
        let restrictionProductsData = [],
          restrictionServicesData = [],
          restrictionFullList = [];
        response.data.items.forEach((item) => {
          restrictionFullList.push(item);
          if (item.tradeItemTypeId == 2) {
            restrictionServicesData.push(item);
          } else if (item.tradeItemTypeId == 1) {
            restrictionProductsData.push(item);
          }
        });

        ctx.commit('updateRestrictionProducts', restrictionProductsData);
        ctx.commit('updateRestrictionServices', restrictionServicesData);
        ctx.commit('updateFullList', restrictionFullList);
      });
    },
  },
};
