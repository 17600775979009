import { INVESTMENT_AGREEMENT_ACTIONS_NAME, INVESTMENT_AGREEMENT_MUTATIONS_NAME } from './nameModule';

import links from '@/utils/links';
import request from '@/utils/request';

export async function getCountriesAgreements(context, params) {
  const paramsRequestLists = context.state.paramsRequestLists;
  return await request.call(
    this,
    context,
    {
      url: links.directives.countries,
      mutate: INVESTMENT_AGREEMENT_MUTATIONS_NAME.updateCountriesAgreements,
      name: INVESTMENT_AGREEMENT_ACTIONS_NAME.getCountriesAgreements,
      requestAuth: true,
    },
    {
      ...paramsRequestLists,
      ...params,
    },
  );
}

export async function getCountriesGroupAgreements(context, params) {
  const paramsRequestLists = context.state.paramsRequestLists;
  return await request.call(
    this,
    context,
    {
      url: links.directives.groupsCountries,
      mutate: INVESTMENT_AGREEMENT_MUTATIONS_NAME.updateCountriesGroupAgreements,
      name: INVESTMENT_AGREEMENT_ACTIONS_NAME.getCountriesGroupAgreements,
      requestAuth: true,
    },
    {
      ...paramsRequestLists,
      ...params,
    },
  );
}

export async function getListAgreements(context, params) {
  let nameMutate = INVESTMENT_AGREEMENT_MUTATIONS_NAME.setAgreementsPage;
  if (params?.join) {
    nameMutate = INVESTMENT_AGREEMENT_MUTATIONS_NAME.joinAgreementsPage;
  }
  return await request.call(
    this,
    context,
    {
      url: links.investmentAgreement.getList,
      mutate: nameMutate,
      name: INVESTMENT_AGREEMENT_ACTIONS_NAME.getListAgreements,
    },
    params.data,
  );
}

export async function findCardAgreements(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.investmentAgreement.find,
      name: INVESTMENT_AGREEMENT_ACTIONS_NAME.findCardAgreements,
      pathParams: ['id'],
    },
    {
      ...params,
    },
  );
}

export async function createAgreements(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.investmentAgreement.CRUD,
      name: INVESTMENT_AGREEMENT_ACTIONS_NAME.create,
      method: 'post',
    },
    params,
  );
}

export async function updateAgreements(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.investmentAgreement.CRUD,
      name: INVESTMENT_AGREEMENT_ACTIONS_NAME.update,
      method: 'put',
      pathParams: ['id'],
      deleteParamsInPath: false,
    },
    params,
  );
}

export async function deleteAgreements(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.investmentAgreement.CRUD,
      name: INVESTMENT_AGREEMENT_ACTIONS_NAME.delete,
      pathParams: ['id'],
      method: 'delete',
    },
    params,
  );
}
