import { IMPLEMENTATION_ACTIONS_NAME, IMPLEMENTATION_MUTATIONS_NAME } from './name';

import links from '@/utils/links';
import request from '@/utils/request';

export async function fetchEventsHistory(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.implementation.getEventsHistory,
      mutate: IMPLEMENTATION_MUTATIONS_NAME.setEventsHistory,
      name: IMPLEMENTATION_ACTIONS_NAME.fetchEventsHistory,
    },
    params,
  );
}

export async function fetchMoreEventsHistory(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.implementation.getEventsHistory,
      mutate: IMPLEMENTATION_MUTATIONS_NAME.addEventsHistory,
      name: IMPLEMENTATION_ACTIONS_NAME.fetchMoreEventsHistory,
    },
    params,
  );
}

export async function fetchAgreementsHistory(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.implementation.getAgreementsHistory,
      mutate: IMPLEMENTATION_MUTATIONS_NAME.setAgreementsHistory,
      name: IMPLEMENTATION_ACTIONS_NAME.fetchAgreementsHistory,
    },
    params,
  );
}

export async function fetchMoreAgreementsHistory(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.implementation.getAgreementsHistory,
      mutate: IMPLEMENTATION_MUTATIONS_NAME.addAgreementsHistory,
      name: IMPLEMENTATION_ACTIONS_NAME.fetchMoreAgreementsHistory,
    },
    params,
  );
}
