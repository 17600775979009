<template>
  <!-- ref может использоваться в родителе -->
  <BarChart :height="400" :chart-data="data" :options="options" ref="Ref" css-classes="chart-container" />
</template>

<script>
  import { computed, defineComponent, ref } from 'vue';
  import { BarChart } from 'vue-chart-3';
  import { Chart, registerables, Tooltip } from 'chart.js';
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  Chart.register(...registerables, Tooltip, ChartDataLabels);

  export default defineComponent({
    name: 'columnChart',
    components: {
      BarChart,
    },
    props: {
      chartData: {
        type: Array,
      },
      barPercentage: {
        type: Number,
        default: 0.4,
      },
      layoutPaddingTop: {
        type: Number,
        default: 25,
      },
      labels: {
        type: Array,
      },
      indexAxis: {
        type: String,
        default: 'x',
      },
      colors: {
        type: [Array, String],
        default: '#0093BA',
      },
      formatLabel: {
        type: String,
        default: '',
      },
      formatLabelFunction: {
        type: Function,
        default: null,
      },
    },

    setup(props) {
      const dataValues = ref([props.chartData]);
      const data = computed(() => ({
        labels: props.labels,
        datasets: [
          {
            label: '',
            data: dataValues.value[0],
            backgroundColor: props.colors,
            barPercentage: props.barPercentage,
          },
        ],
      }));

      const options = ref({
        showTooltips: true,
        layout: {
          padding: {
            top: props.layoutPaddingTop,
          },
        },
        indexAxis: props.indexAxis,
        locale: 'ru-RU',
        scales: {
          y: {
            ticks: {
              callback: props.formatLabelFunction
                ? props.formatLabelFunction
                : (value) => {
                    return value.toString().replace('.', ',') + props.formatLabel;
                  },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            clamp: true,
            align: 'top',
            offset: -10,
            anchor: 'end',
            color: '#787f8f',
            textAlign: 'center',
            font: {
              fontSize: 12,
              lineHeight: 2,
            },
            formatter: function (value) {
              return value != undefined && value != null ? value.toString().replace('.', ',') : '';
            },
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.formattedValue + ` ${props.formatLabel}`;
              },
            },
          },
          title: {
            text: 'Bar',
          },
        },
      });

      return {
        data,
        options,
      };
    },
  });
</script>
