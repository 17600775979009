import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/requestinitiativettr/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/requestinitiativettr/find/${id}`);
  },
  changeStatus: function (data) {
    return axios.patch(`/api/requestinitiativettr/changestatus/${data.id}`, data);
  },
  changeStatusMulti: function (data) {
    return axios.patch(`/api/requestinitiativettr/changestatusmulti`, data);
  },
  addComment: function (id, data) {
    return axios.post(`/api/requestinitiativettr/${id}/comment/add`, data);
  },
  addTimeline: function (id, data) {
    return axios.post(`/api/requestinitiativettr/${id}/timeline/add`, data);
  },
  updateTimeline: function (id, data) {
    return axios.put(`/api/requestinitiativettr/${id}/timeline/${data.id}`, data);
  },
  deleteTimeline: function (id, timelineId) {
    return axios.delete(`/api/requestinitiativettr/${id}/timeline/${timelineId}`, {});
  },
  exportRegistry: function (request) {
    return Utils.downloadFile('/api/requestinitiativettr/export', '', request);
  },
  create: function (request) {
    return axios.post('/api/requestinitiativettr', request);
  },
  update: function (request) {
    return axios.put('/api/requestinitiativettr', request);
  },
  getRandomResponsibleEmployee: function () {
    return axios.get('/api/requestinitiativettr/randomzamdirectoraemployee');
  },
  getResponsibleEmployees: function () {
    return axios.get('/api/requestinitiativettr/employeemerttrdepartmentlist');
  },
};
