<template>
  <div>
    <apexchart type="bar" :options="chartOptions" :series="chartSeries"></apexchart>
  </div>
</template>

<script>
  import chartConstants from './chartConstants';

  export default {
    props: {
      xData: {
        type: Array,
        default: () => chartConstants.defaultXData,
      },
      yData: {
        type: Array,
        default: () => chartConstants.defaultYData,
      },
      // Отображение значений по оси y слева
      showYAxisLabel: {
        type: Boolean,
        default: true,
      },
      // Отображение чисел над столбцами
      showDataLabels: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        chartOptions: {
          chart: {
            fontFamily: 'Fira Sans',
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
          },
          xaxis: {
            categories: [], // Замените данными года
            labels: {
              style: {
                fontSize: '15px',
                lineHeight: '16px',
                letterSpacing: '-0.3px',
                colors: '#989898',
              },
            },
            offsetY: 16,
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: false,
                color: '#FF5733', // Цвет оси Y для количества
              },
              labels: {
                style: {
                  fontSize: '15px',
                  lineHeigth: '16px',
                  colors: '#989898',
                },
              },
            },
          ],
        },
        chartSeries: [
          {
            name: 'Количество выявленных барьеров',
            type: 'bar', // Тип столбца
            data: [], // Замените данными количество
          },
        ],
      };
    },
    mounted() {
      this.createChart();
    },
    watch: {
      xData: function () {
        this.createChart();
      },
      yData: function () {
        this.createChart();
      },
    },
    methods: {
      createChart() {
        this.chartOptions = {
          chart: {
            fontFamily: 'Fira Sans',
            width: '100%',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
            parentHeightOffset: 15,
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            offsetY: 20,
            height: 45,
            markers: {
              radius: 12,
            },
          },
          labels: this.xData,
          xaxis: {
            labels: {
              style: {
                fontSize: '15px',
                lineHeight: '16px',
                letterSpacing: '-0.3px',
                colors: '#989898',
              },
              // rotate: -45,
              // rotateAlways: true,
            },
            offsetY: 16,
          },
          dataLabels: {
            enabled: this.showDataLabels,
            offsetY: -20,
            style: {
              fontSize: '12px',
              lineHeigth: '16px',
              colors: ['#231F20'],
            },
          },
          noData: {
            text: 'Данные отсутствуют',
            verticalAlign: 'top',
            style: {
              color: '#231F20',
              fontSize: '32px',
              lineHeight: '40px',
            },
          },
          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: false,
                color: '#FF5733', // Цвет оси Y для количества
              },
              labels: {
                show: this.showYAxisLabel, // отображать значения оси Y
                style: {
                  fontSize: '15px',
                  lineHeigth: '16px',
                  colors: '#989898',
                },
              },
            },
          ],
          responsive: [
            {
              breakpoint: 768,
              options: {
                chart: {
                  width: 768,
                },
              },
            },
          ],
          stroke: {
            show: true,
            width: 3,
            colors: ['transparent'],
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: 'top',
              },
            },
          },
        };
        this.chartSeries = this.yData;
        // this.chartSeries = [
        //   {
        //     name: 'Количество выявленных барьеров',
        //     type: 'bar', // Тип столбца
        //     data: this.yData,
        //   },
        // ];
      },
    },
  };
</script>
