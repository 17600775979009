<template>
  <div class="quantityBarrierCard">
    <p class="quantityBarrierCard__quantity">{{ quantity }} шт.</p>
    <p class="quantityBarrierCard__interval">за {{ interval }}</p>
  </div>
</template>

<script>
  export default {
    props: {
      quantity: Number,
      interval: String,
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/assets/theme/default/global/mixin.scss';

  .quantityBarrierCard {
    padding: 8px 12px;

    @include borderAndBgCard();
    max-width: 200px;
    min-width: 200px;
    @media (max-width: $tablet) {
      width: 100%;
      max-width: 100%;
    }

    &__quantity {
      font: $fira-32;
      line-height: 32px;
      letter-spacing: -0.64px;
    }

    &__interval {
      font: $fira-12;
      letter-spacing: -0.24px;
    }
  }
</style>
