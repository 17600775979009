<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Настройка текста уведомлений</p>
      <div class="table-container mb-16 js-table-arrow">
        <table class="stockTable">
          <thead>
            <tr>
              <th>Вид объекта</th>
              <th>Категория уведомления</th>
              <th>Заголовок</th>
              <th>Текст уведомления</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="page.items?.length && !isLoading">
            <tr v-for="item in page.items" :key="item.id" @dblclick="editDescription(item)">
              <td>{{ item.catalog?.name }}</td>
              <td>{{ item.category?.name }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.message }}</td>
              <td>
                <IconComponent @click="editDescription(item)" name="mode" />
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="isLoading">
            <tr>
              <td class="text-center" colspan="5">Загрузка...</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center" colspan="5">Нет данных.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="page.pagesTotal > 1">
        <Pagination
          :page-count="page.pagesTotal"
          :current-page="page.pageNumber"
          @change="onPageChange($event, false)"
          @more="onPageChange($event, true)"
        />
      </div>
    </div>

    <section>
      <ModalComponent title="Настройка текста уведомления" @close="dialogEdit = false" v-model="dialogEdit">
        <div class="form__body">
          <div class="form__body-inputBlock mb-3 pb-3" v-if="selectedDescription">
            <p>Заголовок</p>
            <ComponentInput placeholder="Заголовок" v-model="selectedDescription.title" required />
          </div>
          <div class="form__body-inputBlock mb-3 pb-3" v-if="selectedDescription">
            <p>Текст уведомления</p>
            <ComponentInput placeholder="Текст уведомления" v-model="selectedDescription.message" required />
          </div>
          <div class="form__body-inputBlock">
            <ButtonStock title="Сохранить" @click="saveDescription" />
            <ButtonStock class="ml-2" @click="dialogEdit = false" btnType="whiteGreenBordered" title="Закрыть" />
          </div>
        </div>
      </ModalComponent>
    </section>
  </div>
</template>

<script>
  import API from '@/common/api/notification';
  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  export default {
    name: 'NotificationSettings',
    components: {
      IconComponent,
      ButtonStock,
      ComponentInput,
      ModalComponent,
    },
    mixins: [security],
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        page: {},
        isLoading: false,
        dialogEdit: false,
        selectedDescription: null,
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.NotificationTextEdit).then((response) => {
        if (response.data) {
          this.loadPage(false);
        } else {
          this.notAccess();
        }
      });
      this.checkPermission(Constants.Permissions.RoleCreate).then((response) => {
        this.canAdd = response.data;
      });
      this.checkPermission(Constants.Permissions.RoleEdit).then((response) => {
        this.canEdit = response.data;
      });
      this.checkPermission(Constants.Permissions.RoleDelete).then((response) => {
        this.canDelete = response.data;
      });
    },
    methods: {
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(append) {
        this.isLoading = !append;
        API.getDescriptions(this.request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              //добавляем к существующему
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      editDescription(item) {
        this.selectedDescription = item;
        this.dialogEdit = true;
      },
      saveDescription() {
        if (!this.selectedDescription || !this.selectedDescription.title || !this.selectedDescription.message) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения поля', 'error');
          return;
        }
        API.updateDescription(this.selectedDescription)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.dialogEdit = false;
            this.$nextTick(() => {
              this.selectedDescription = null;
              this.request.pageNumber = 1;
              this.loadPage(false);
            });
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
            this.dialogEdit = false;
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .icon {
    color: #01a39d;
  }
</style>
