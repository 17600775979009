import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    countriesAgreements: [],
    countriesGroupAgreements: [],
    paramsRequestLists: {
      pageNumber: 1,
      pageSize: 0,
      isActive: true,
    },
    agreementsPage: {
      firstItemNumber: 1,
      itemCount: 0,
      items: [],
      itemsTotal: 0,
      pageNumber: 1,
      pageSize: 0,
      pagesTotal: 1,
    },
  },
};
