export default {
  mounted(el, binding) {
    setTimeout(() => {
      updateControls(el, binding);
    }, 100);
  },
  updated(el, binding) {
    setTimeout(() => {
      updateControls(el, binding);
    }, 100);
  },
};

function updateControls(el, binding) {
  if (!binding.value) return;
  const tags = {
    readonly: ['input:text,number,date,search', 'textarea'],
    disabled: ['input:checkbox,radio,password,text', 'select', 'button'],
  };
  for (const [key, value] of Object.entries(tags)) {
    value.forEach((tagName) => {
      const arr = tagName.split(':');
      const types = arr.length > 1 ? arr[1].split(',') : null;
      const nodes = el.getElementsByTagName(arr[0]);
      nodes.forEach((node) => {
        if (node.getAttribute('data-ignore-disable')) {
          return;
        }
        if (types) {
          if (types.indexOf(node.type) > -1) {
            node.setAttribute(key, true);
            const cl = node.parentNode.querySelector('.v3dp__clearable');
            if (cl) {
              cl.remove();
            }
            if (node.parentNode.className === 'v3dp__input_wrapper') {
              node.setAttribute('disabled', true);
            }
          }
        } else {
          node.setAttribute(key, true);
          if (tagName === 'select') {
            const arrow = node.parentNode.querySelector('.select2-selection__arrow');
            if (arrow) {
              arrow.remove();
            }
          }
        }
      });
    });
  }
}
