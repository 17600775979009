<template>
  <Select2
    class="select2"
    style="width: 100%"
    @select="select2OnSelect"
    v-on:clear="select2OnClear"
    v-on:unselecting="select2OnClear"
    :settings="settings"
  />
</template>

<script>
  import 'select2';

  export default {
    name: 'selectComponent',
    emits: ['select2OnSelect', 'select2OnClear'],
    props: {
      settings: {
        type: Object,
        default: null,
      },
    },
    methods: {
      select2OnSelect(o) {
        this.$emit('select2OnSelect', { value: o, additional: this.additionalData });
      },
      select2OnClear() {
        this.$emit('select2OnClear');
      },
    },
  };
</script>

<style lang="scss">
  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    outline: none;
    border-color: #343c44 !important;
    border-width: 0px 0px 3px 3px !important;
    width: 10px !important;
    height: 10px !important;
    border-radius: 1px;
    transform: rotate(315deg);
    transition: 0.3s;
    mask-image: unset !important;
    margin-left: -6px !important;
    margin-top: -8px !important;
  }
  .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    transform: rotate(135deg);
  }
  .select2.error {
    .select2-container {
      border-color: red;
    }
  }
</style>

<style scoped lang="scss">
  // Select2
  .select2::v-deep {
    outline: none;
    // Контейнер ширина
    .select2-container {
      outline: none;
      width: 100% !important;
      max-width: 100% !important;
      border-radius: 40px;
      // Текст
      .select2-selection__rendered {
        outline: none;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 40px;
        color: #343c44;
      }
    }
    // Контейнер высота
    .select2-container--default .select2-selection--single {
      outline: none;
      height: calc(2.25rem + 6px) !important;
      border: 1px solid #d6e4ec;
      background-color: #fbfbfb;
      border-radius: 4px !important;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
      outline: none;
      top: 50% !important;
      height: 18px;
      width: 10px;
      right: 14px;
      transform: translateY(-50%) !important;
    }
    // Плейсхолдер
    .select2-container--default .select2-selection--single .select2-selection__placeholder {
      outline: none;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #989898;
    }
  }
</style>
