export default {
  namespaced: true,
  state: {
    chats: null,
    unreadMessages: 0,
  },
  getters: {
    chats: (state) => {
      return state.chats;
    },
  },
  mutations: {
    notechats(state, chats) {
      state.chats = chats;
    },
    updateUnread(state, unreadMessages) {
      state.unreadMessages = unreadMessages;
    },
  },
  actions: {
    noteChatsHistory({ commit }, chats) {
      commit('notechats', chats);
    },
  },
  modules: {},
};
