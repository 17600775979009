import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    mpkProjectDepartments: [],
    mpkProjectMinRoles: [],
    mpkListProjects: [],
    mpkProjectSupportMeasures: [],
    mpkProjectCategories: [],
    mpkProjectTypeEntries: [],
    lightSearchMpkProjects: [],
  },
};
