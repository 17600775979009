import axios from 'axios';

import { authAxios } from '@/common/axios';
import Utils from '@/common/utils';

export default {
  getExportProductAll: function (year) {
    return axios.get(`api/exportProduct/summaryForAll?year=${year}`, {});
  },
  getExportProductLast: function (countryId) {
    return axios.get(`api/exportProduct/summaryLast/${countryId}`, {});
  },
  getYearList: function (isRussia, isProductMode) {
    return axios.get(`api/foreigntrade/yearList`, { params: { isRussia: isRussia, isProductMode: isProductMode } });
  },
  getTradeEconomicRelRussia: function (year) {
    return axios.get(`api/tradeeconomicrelrussia/summary/${year}`, {});
  },
  projectSummaryByCountry: function (request) {
    return axios.get('/api/mpkProject/projectSummaryByCountry', { params: request });
  },
  getAnalyticsSummaryByCountries: function () {
    return axios.get(`api/exportProduct/totalValuesByCountries`, {});
  },
  countriesForAnalytics: function () {
    return authAxios.get('/api/country/countriesForAnalytics', {});
  },
  getProductsListWithExportPotential: async (request) => {
    return axios.get('/api/exportpotential/getProductListDynamic', { params: request });
  },
  getExportPotentialsByCountry: function (tnvedCode) {
    return axios.get(`/api/exportpotential/ExportPotentialsByCountry/${tnvedCode}`, {});
  },
  getSummaryForCountry(countryId, tnvedCode) {
    return axios.get(`/api/exportpotential/summaryForCountry`, {
      params: { countryId: countryId, tnvedCode: tnvedCode },
    });
  },
  getYearListTurnover: function (countryId) {
    return axios.get(`api/foreignTradeTurnoverWorld/yearList?countryId=${countryId}`, {});
  },
  getForeignTradeData: function (year, countryId) {
    return axios.get(`api/foreigntrade/summary/${year}/${countryId}`, {});
  },
  getForeignTradeForOne: function (isRussia, isProductMode, year, countryId) {
    return axios.get(
      `api/foreigntrade/summaryForOne/${year}/${countryId}?isRussia=${isRussia}&isProductMode=${isProductMode}`,
      {},
    );
  },
  searchMacro: function (request) {
    return axios.get('api/macroeconomicindicatorcountryvalue/search', {
      params: request,
    });
  },
  getInvestementYearList: function (countryId) {
    return axios.get(`api/investment/yearList`, { params: { countryId: countryId } });
  },
  getInvestmentSummary: function (countryId, year) {
    return axios.get(`api/investment/summary`, { params: { id: countryId, year: year } });
  },
  getAgreementSummaryByCountry: function (countryId, typeId) {
    return axios.get('/api/freeTradeAgreement/summaryByCountry', {
      params: { countryId: countryId, agreementType: typeId },
    });
  },
  getAgreements: function (countryId, typeId) {
    return axios.get('/api/freeTradeAgreement/listGroupedByCountryCode', {
      params: { countryId: countryId, agreementType: typeId },
    });
  },
  getAgreementsBySides: function (countryId, toCountryId, typeId) {
    return axios.get('/api/freeTradeAgreement/agreementsBySides', {
      params: { countryId: countryId, toCountryId: toCountryId, agreementType: typeId },
    });
  },
  getAgreement: function (id) {
    return axios.get('/api/freeTradeAgreement/find', { params: { id: id } });
  },
  createPresentation: function (request) {
    return Utils.downloadFile('/api/countryReport/createReport', 'CountryReport.pptx', request);
  },
  getCountryRatings: function (countryId) {
    return axios.get(`api/countryrating/summary/${countryId}`, {});
  },
  getBudgetPolicy: function (countryId) {
    return axios.get(`/api/budgetpolicy/summary/${countryId}`, {});
  },
  searchBankRates: function (countryId) {
    return axios.get(`api/bankrate/summary/${countryId}`, {});
  },
  searchInflation: function (request) {
    return axios.get(`api/inflation/search`, { params: request });
  },
  getFinancialSector: function (countryId) {
    return axios.get(`api/financialsector/getbycountryid/${countryId}`, {});
  },
};
