<template>
  <div class="doughnut-container" :class="{ 'full-width': fullWidth, 'barrier-type': barrierType }">
    <h5 class="doughnut-container__title" v-if="title">{{ title }}</h5>
    <slot />
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      fullWidth: Boolean,
      barrierType: Boolean,
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/assets/theme/default/global/mixin.scss';

  .doughnut-container {
    width: 416px;
    height: 100%;
    padding: 32px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: $laptop) {
      width: 100%;
      padding: 24px;
    }

    @media (max-width: $mobile) {
      padding: 16px;
    }

    @include borderAndBgCard();

    &__title {
      font: $fira-20;
      letter-spacing: -0.4px;
      margin: 0;
      margin-bottom: 48px;
    }

    &.full-width {
      width: 100%;
    }

    &.barrier-type {
      padding: 48px 40px;
      max-width: 1024px;
      width: 100%;

      @media (max-width: $laptop) {
        padding: 24px;
      }

      @media (max-width: $mobile) {
        padding: 16px;
      }
    }
  }
</style>
