import { SINGLE_TTR_REGISTRY_ACTIONS_NAME } from './name';

import links from '@/utils/links';
import request from '@/utils/request';

export async function changeParametersMulti(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.measure.singleTtrRegistry.changeParametersMulti,
      name: SINGLE_TTR_REGISTRY_ACTIONS_NAME.changeParametersMulti,
      method: 'put',
    },
    params,
  );
}

export async function findAuditLog(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.measure.singleTtrRegistry.findAuditLog,
      name: SINGLE_TTR_REGISTRY_ACTIONS_NAME.findAuditLog,
    },
    params,
  );
}

export async function update(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.measure.singleTtrRegistry.CRUD,
      name: SINGLE_TTR_REGISTRY_ACTIONS_NAME.update,
      method: 'put',
      pathParams: ['id'],
      deleteParamsInPath: false,
    },
    params,
  );
}
