<template>
  <VTooltip
    class="tooltip-component"
    :popperClass="[
      'tooltip-component__content',
      modContent ? `tooltip-component__content--${modContent}` : '',
      `tooltip-component__content_${theme}`,
      { 'tooltip-component__content_dense': dense },
    ]"
    :placement="getPosition"
    :triggers="triggers"
    :distance="getDistance"
    :skidding="getSkidding"
    ref="tooltip"
    v-click-outside="closeTooltip"
  >
    <span v-if="$slots.content" @mousemove="setCoordinates">
      <slot name="content"></slot>
    </span>
    <slot v-else name="triggerContent">
      <IconComponent class="tooltip-component__icon" :name="nameIcon" />
    </slot>
    <template #popper>
      <slot></slot>
    </template>
  </VTooltip>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent.vue';

  export default {
    name: 'Tooltip',
    components: { IconComponent },
    props: {
      nameIcon: {
        type: String,
        default: 'tooltip',
      },
      modContent: {
        type: String,
        validator(value) {
          return ['', 'width-360', 'width-378', 'width-600', 'width-780', 'width-407'].includes(value);
        },
      },
      // полный список см. https://floating-vue.starpad.dev/api/#placement
      position: {
        type: String,
      },
      triggers: {
        type: Array,
        default: () => {
          return ['hover', 'focus', 'click', 'touch'];
        },
      },
      distance: {
        type: Number,
        default: 5,
      },
      skidding: {
        type: Number,
        default: 5,
      },
      theme: {
        type: String,
        default: 'light',
        validator(value) {
          return ['light', 'black'].includes(value);
        },
      },
      isMoving: {
        type: Boolean,
        default: false,
      },
      dense: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        coordinates: {
          distance: null,
          skidding: null,
        },
      };
    },
    computed: {
      getDistance() {
        return this.coordinates.distance ?? this.distance;
      },
      getSkidding() {
        return this.coordinates.skidding ?? this.skidding;
      },
      getPosition() {
        return this.isMoving ? 'right-start' : this.position;
      },
    },
    methods: {
      closeTooltip() {
        this.$refs.tooltip.hide();
      },
      setCoordinates(e) {
        if (!this.isMoving) return;

        const target = e.target;
        const targetCoordinates = target.getBoundingClientRect();

        this.coordinates.distance = e.offsetX - targetCoordinates.width + 15;
        this.coordinates.skidding = e.offsetY;
      },
    },
  };
</script>

<style lang="scss">
  .tooltip-component {
    position: relative;
    display: inline-block;
    z-index: 10;

    &_black {
      background: $blue-pre-dark;
    }

    &.v-popper--shown {
      svg {
        rect {
          stroke: $blue;
          fill: $blue;
        }

        path {
          fill: #fff;
        }
      }
    }

    &__icon {
      width: 24px;
      height: 24px;

      svg {
        width: 100%;
      }

      &:hover,
      &.active {
        svg {
          rect {
            stroke: $blue;
            fill: $blue;
          }

          path {
            fill: #fff;
          }
        }
      }
    }

    &__content {
      max-width: 100%;

      @media (min-width: $mobile) {
        padding-inline: 16px;
      }

      .v-popper__inner {
        overscroll-behavior: none;
        width: 281px;
        max-width: 100%;
        max-height: 50vh;
        padding: 16px 24px;
        color: $base;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);

        p,
        div {
          font: $fira-14;
        }

        & > div {
          max-height: 100%;
        }
      }

      &_black {
        .v-popper__inner {
          background: $blue-pre-dark;
          color: $white;
        }
      }

      &_dense {
        .v-popper__inner {
          padding: 12px;
        }
      }
    }

    &__content--width-250 .v-popper__inner {
      width: 250px;
    }

    &__content--width-360 .v-popper__inner {
      width: 360px;
    }

    &__content--width-378 .v-popper__inner {
      width: 378px;
    }

    &__content--width-407 .v-popper__inner {
      width: 407px;
    }

    &__content--width-600 .v-popper__inner {
      width: 600px;
    }

    &__content--width-780 .v-popper__inner {
      width: 780px;
    }

    &--investment {
      transform: translateY(6px);
      -webkit-transform: translateY(6px);

      @media (min-width: $tablet) {
        .exportPdf & {
          opacity: 0;
        }
      }
    }

    @media (max-width: $laptop) {
      &__icon {
        width: 16px;
        height: 16px;
      }

      &--mobile-big & {
        &__icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
</style>
