import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    loadPage: false,
    dataCardEvent: {},
    listOfEvents: {
      firstItemNumber: 1,
      itemCount: 1,
      items: [],
      itemsTotal: 1,
      pageNumber: 1,
      pageSize: 10,
      pagesTotal: 1,
    },
  },
};
