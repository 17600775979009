<template>
  <ModalComponent
    :title="title"
    @close="updateShowing(false)"
    :model-value="isShowing"
    @update:modelValue="updateShowing"
    headType="redesigned"
    bodyType="redesigned"
  >
    <form class="custom-form">
      <div class="custom-form__block">
        <InputBlock v-if="!countryId" title="Страна" required>
          <SelectSingleComponentCheckbox
            v-model="localeFile.countryId"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="getCountryList"
            required
          />
        </InputBlock>
        <InputBlock title="Краткое наименование файла" required>
          <ComponentInput v-model="localeFile.fileName" placeholder="Введите" mod="rectangular" required />
        </InputBlock>
        <InputBlock required>
          <FileMultiUploadRedesigned
            :entity="file"
            @uploaded="fileUploaded"
            @deleteFile="removeFile"
            show-empty
            required
            is-once
            :valid-extensions="validFilesExtension"
          />
        </InputBlock>
        <div class="custom-form__button-container custom-form__button-container_mobile">
          <ButtonComponent @click="addFile" :disabled="isLoading" mod="gradient-bg" type="submit">{{
            eventName
          }}</ButtonComponent>
        </div>
      </div>
    </form>
  </ModalComponent>

  <ModalInfo
    v-model="successModal.isOpen"
    @closeModal="successModal.isOpen = false"
    :titleModal="successModal.title"
    :textModal="successModal.text"
    :modalIconName="successModal.iconName"
  >
  </ModalInfo>

  <ModalInfo
    v-model="errorModal.isOpen"
    @closeModal="errorModal.isOpen = false"
    :titleModal="errorModal.title"
    :modalIconName="errorModal.iconName"
  >
  </ModalInfo>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import FileMultiUploadRedesigned from '@/common/components/redesigned/FileMultiUploadRedesigned.vue';
  import InputBlock from '@/common/components/redesigned/InputBlock.vue';
  import ModalInfo from '@/common/components/redesigned/ModalInfo.vue';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';

  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import { VALID_REFERENCE_FILE_EXTENSIONS } from '@/modules/countryАnalytics/utils/constants';

  import API from '../../api/referenceInfo';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  const initialState = {
    name: '',
    fileName: null,
  };

  export default {
    name: 'ReferenceInfoAddition',
    components: {
      ModalInfo,
      SelectSingleComponentCheckbox,
      FileMultiUploadRedesigned,
      InputBlock,
      ButtonComponent,
      ComponentInput,
      ModalComponent,
    },
    emits: ['update:isShowing', 'add'],
    props: {
      isShowing: {
        type: Boolean,
        default: false,
      },
      countryId: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        localeFile: JSON.parse(JSON.stringify(initialState)),
        file: null,
        isLoading: false,
        successModal: {
          isOpen: false,
          title: 'Сохранение',
          text: 'Данные успешно сохранены',
          iconName: 'acceptIcon',
        },
        errorModal: {
          isOpen: false,
          title: 'Ошибка',
          iconName: 'errorIcon',
        },
        validFilesExtension: VALID_REFERENCE_FILE_EXTENSIONS,
      };
    },
    computed: {
      ...mapGetters({
        getCountryList: DIRECTIVES_GETTERS_NAME.getCountriesSelect,
      }),
      title() {
        return this.countryId ? 'Добавление файла' : 'Файл по стране';
      },
      eventName() {
        return this.countryId ? 'Добавить' : 'Загрузить';
      },
    },
    created() {
      if (!this.countryId) {
        this.fetchCountries();
      }
    },
    methods: {
      ...mapActions({
        fetchCountries: DIRECTIVES_ACTIONS_NAME.countriesGet,
      }),
      updateShowing(isShowing) {
        this.$emit('update:isShowing', isShowing);
      },
      async addFile() {
        try {
          this.isLoading = true;
          this.v$.$touch();

          if (this.v$.$invalid) {
            return;
          }

          const countryId = this.countryId ? this.countryId : this.localeFile.countryId;
          const response = await API.createFile({
            fileName: this.localeFile.fileName,
            countryId,
            status: 'visible',
            fileId: this.file.id,
          });

          this.localeFile = response.data;
          this.$emit('add', this.localeFile);
          this.reset();
          this.successModal.isOpen = true;
        } catch (err) {
          console.error(err);
          this.updateShowing(false);
          this.errorModal.isOpen = true;
        } finally {
          this.isLoading = false;
        }
      },
      fileUploaded(e) {
        this.file = e.data;
      },
      removeFile() {
        this.file = null;
      },
      reset() {
        this.localeFile = JSON.parse(JSON.stringify(initialState));
        this.file = null;
        this.v$.$reset();
      },
    },
    setup: () => ({ v$: useVuelidate() }),
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/custom-form.scss';
</style>
