import moment from 'moment';

export function getDefaultState() {
  return {
    initiative: {
      organizationInfo: '',
      applicant: '',
      address: '',
      phone: '',
      applicationSubmissionDate: moment().format('YYYY-MM-DD'),
      applicantGeneralInformation: '',
      measureTypeId: null,
      nsiTradingOperationId: null,
      userId: null,
      tovar: null,
      purpose: '',
      statusId: null,
      notes: '',
      proposedMeasureJustificationDto: {
        rfProductionVolumeForThreeYears: '',
        quotaVolume: '',
        consumptionVolumeForThreeYears: '',
        productDescription: '',
        foreignCompetingCompanies: '',
        rfProductionFacilitiesForThreeYears: '',
        workLoadLevelForThreeYears: '',
        consumptionShareForThreeYears: '',
        rfMarketPriceForThreeYears: '',
        productionUnitAverageCost: '',
        productionProfitabilityForThreeYears: '',
        tariffQuotaEfficiency: '',
        mainConsumers: '',
        applicantCommentOnCompensation: '',
      },
      requestFiles: [],
      dataFiles: [],
      rates: [],
      comments: [],
      timeline: [],
      responsibleEmployeeId: null,
      responsibleEmployee: null,
      employee: {
        fullName: '',
        organization: {
          nameFull: '',
          phone: '',
          address: '',
        },
      },
    },
    tableHeaderInitiative: [
      {
        id: 0,
        name: 'Заявитель',
        visible: true,
        width: 226,
      },
      {
        id: 1,
        name: 'Организация',
        visible: true,
        width: 226,
      },
      {
        id: 2,
        name: 'Адрес',
        visible: true,
        width: 226,
      },
      {
        id: 3,
        name: 'Телефон',
        visible: true,
        width: 226,
      },
      {
        id: 4,
        name: 'Директор',
        visible: true,
        width: 226,
      },
      {
        id: 5,
        name: 'Дата регистрации ЮЛ',
        visible: true,
        width: 226,
      },
      {
        id: 6,
        name: 'Место регистрации ЮЛ',
        visible: true,
        width: 226,
      },
      {
        id: 7,
        name: 'Уставный фонд',
        visible: true,
        width: 226,
      },
      {
        id: 8,
        name: 'Дата подачи заявления',
        visible: true,
        width: 226,
      },
      {
        id: 9,
        name: 'Группа товаров',
        visible: true,
        width: 226,
      },
      {
        id: 10,
        name: 'Код ТН ВЭД',
        visible: true,
        width: 226,
      },
      {
        id: 11,
        name: 'Тип меры',
        visible: true,
        width: 226,
      },
      {
        id: 12,
        name: 'Описание',
        visible: true,
        width: 226,
      },
      {
        id: 13,
        name: 'Статус',
        visible: true,
        width: 226,
      },
      {
        id: 14,
        name: 'Общая информация о Заявителе',
        visible: true,
        width: 226,
      },
      {
        id: 15,
        name: 'Направление перемещения',
        visible: true,
        width: 226,
      },
      {
        id: 16,
        name: 'Предлагаемая детализация ТН ВЭД ЕАЭС',
        visible: true,
        width: 226,
      },
      {
        id: 17,
        name: 'Цель',
        visible: true,
        width: 226,
      },
      {
        id: 18,
        name: 'Ответственный сотрудник',
        visible: true,
        width: 226,
      },
      {
        id: 19,
        name: 'Примечания',
        visible: true,
        width: 226,
      },
      {
        id: 20,
        name: 'Данные об организации',
        visible: true,
        width: 226,
      },
    ],
  };
}
