import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    summaryLifeIndex: null,
    inStockInvestment: null,
    outStockInvestment: null,
    inFlowsInvestment: null,
    outFlowsInvestment: null,
    balanceForeignTradeTurnoverWord: null,
    gdpValuesForeignTradeTurnoverWord: null,
    infoAboutUnfriendlyCountry: null,
    industriesOfTopImport: null,
    industriesOfTopExport: null,
    countriesOfTopImport: null,
    countriesOfTopExport: null,
    budgetDeficitIndicator: null,
    stockMarketCapitalizationIndicator: null,
    financialAccountIndicator: null,
    governmentBondYieldsIndicator: null,
    turnoverYears: [],
    foreignTradeData: null,
    valueOfMacroEconomicIndicator: null,
    summaryEconomicReview: null,
    countryRatingIndicators: [],
    lastExportProduct: null,
    ferRegionsTradeValues: [],
    ferRegionsTradeCountOfCountry: null,
    quartals: [],
    summaryQuartal: null,
  },
};
