import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    eventsHistoryData: {
      firstItemNumber: 1,
      itemCount: 1,
      items: [],
      itemsTotal: 1,
      pageNumber: 1,
      pageSize: 5,
      pagesTotal: 1,
    },
    agreementsHistoryData: {
      firstItemNumber: 1,
      itemCount: 1,
      items: [],
      itemsTotal: 1,
      pageNumber: 1,
      pageSize: 5,
      pagesTotal: 1,
    },
  },
};
