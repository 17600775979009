import axios from 'axios';

export default {
  getDictionaryList: function () {
    return axios.get('/api/nsi/dictionaries', {});
  },
  getDictionary: function (name) {
    return axios.get(`/api/nsi/dictionaries/${name}`, {});
  },
  getItems: function (request) {
    return axios.get(`/api/nsi/items`, { params: request });
  },
  getItem: function (request) {
    return axios.get(`/api/nsi/items/${request.name}/${request.id}`, {});
  },
  create: function (name, data) {
    return axios.post(`/api/nsi/items/${name}`, data);
  },
  update: function (name, data) {
    return axios.put(`/api/nsi/items/${name}/${data.id}`, data);
  },
  delete: function (name, id) {
    return axios.delete(`/api/nsi/items/${name}/${id}`);
  },
};
