<template>
  <div class="container-page container-category">
    <div class="col-12">
      <div class="form-group">
        <ComponentInput
          v-model="filter.barrierCategoryId"
          :select2Options="barrierCategories"
          label="Категория барьера"
          placeholder="Выберите категорию барьера"
          :select2Settings="select2Settings"
          type="select2"
        />
      </div>
    </div>
  </div>
  <NsiTable
    :pageTitle="pageTitle"
    :apiRoute="apiRoute"
    :cardName="cardName"
    :canEdit="canEdit"
    :additionalFilter="filter"
  />
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';

  import API from '../api/nsi';
  import NsiTable from '../components/NsiTable';

  export default {
    name: 'MeasuresKinds',
    components: {
      NsiTable,
      ComponentInput,
    },
    data() {
      return {
        pageTitle: 'Виды мер',
        canEdit: true,
        apiRoute: 'nsimeasurekind',
        cardName: 'MeasureKindCard',
        filter: {
          barrierCategoryId: 1,
        },
        select2Settings: Constants.select2Settings,
        barrierCategories: [],
      };
    },
    created() {
      API.search('nsibarriercategory', this.defaultRequest)
        .then((response) => {
          this.barrierCategories = response.data.items.map((x) => {
            return { text: x.name, id: x.id };
          });
        })
        .catch((error) => {
          console.error(error?.response?.data);
        });
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  .container-category {
    padding-bottom: 0 !important;
  }
</style>
