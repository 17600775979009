<template>
  <div
    class="International-trade internationalTradeRussiaEconRev mb-32"
    :class="{ notForTecRussiaTab: notForTecRussiaTab }"
  >
    <div class="International-trade__switchblock d-flex justify-content-between mb-22">
      <p class="economicsTypeTitle mb-0">Торгово-экономическое сотрудничество с Россией</p>
      <RedesignedSwitchComponent @switchHandler="switchHand($event)" />
      <div class="economics-period">
        <p class="fs14 gray">Источник: {{ isProductMode ? 'ФТС России' : 'Банк России' }}</p>
        <div class="economics-period__select">
          <SelectMultiComponent
            id="period"
            v-model="year"
            :options="yearList"
            classSelect="multiselect--green multiselect--hidden-clear"
            @select="yearChanged"
          />
        </div>

        <div v-if="!isProductMode" class="economics-period__select">
          <SelectMultiComponent
            id="quartal"
            v-model="quartal"
            :options="quartals"
            placeholder="Квартал"
            classSelect="multiselect--green"
            @select="loadDataQuartal"
          />
        </div>
      </div>
    </div>

    <template v-if="!currentSummary">
      <p>Загрузка данных...</p>
    </template>

    <template v-if="currentSummary">
      <TradeIndicatorsList class="indicators__list" justifyContent="start">
        <TradeIndicator
          :name="isProductMode ? 'Товарооборот' : 'Оборот услуг'"
          :divider="divider"
          unit="млн $"
          :currentValue="currentSummary[indicatorValue.TOTAL_VALUE]"
          :previousPercent="currentSummary[indicatorPreviousPercentValue.TOTAL_VALUE]"
          @click="
            resolveSelectTab({
              isCondition: turnoverMoreInfo,
              id: 1,
              label: isProductMode ? 'Товарооборот' : 'Оборот услуг',
            })
          "
          :isSelected="tabId === 1"
        />
        <TradeIndicator
          name="Экспорт"
          :divider="divider"
          unit="млн $"
          :currentValue="currentSummary[indicatorValue.EXPORT_VALUE]"
          :previousPercent="currentSummary[indicatorPreviousPercentValue.EXPORT_VALUE]"
          @click="
            resolveSelectTab({
              isCondition: exportMoreInfo,
              id: 2,
              label: 'Экспорт',
            })
          "
          :isSelected="tabId === 2"
        />
        <TradeIndicator
          name="Импорт"
          :divider="divider"
          unit="млн $"
          :currentValue="currentSummary[indicatorValue.IMPORT_VALUE]"
          :previousPercent="currentSummary[indicatorPreviousPercentValue.IMPORT_VALUE]"
          @click="
            resolveSelectTab({
              isCondition: importMoreInfo,
              id: 3,
              label: 'Импорт',
            })
          "
          :isSelected="tabId === 3"
        />
        <TradeIndicator
          v-if="isProductMode === true"
          name="ННЭ"
          :divider="divider"
          unit="млн $"
          :currentValue="currentSummary[indicatorValue.EXPORT_NON_ENERGY_VALUE]"
          :previousPercent="currentSummary[indicatorPreviousPercentValue.EXPORT_NON_ENERGY_VALUE]"
          @click="
            resolveSelectTab({
              isCondition: exportNonEnergyMoreInfo,
              id: 4,
              label: 'Несырьевой',
            })
          "
          :isSelected="tabId === 4"
        />
        <TradeIndicator
          name="Сальдо"
          :divider="divider"
          unit="млн $"
          :currentValue="currentSummary[indicatorValue.BALANCE]"
          :previousPercent="currentSummary[indicatorPreviousPercentValue.BALANCE]"
          :previousValue="currentSummary[indicatorPreviousValue.BALANCE]"
          class="indicators__itemCustom"
          @click="
            resolveSelectTab({
              isCondition: balanceMoreInfo,
              id: 5,
              label: 'Сальдо',
            })
          "
          :isSelected="tabId === 5"
        />
      </TradeIndicatorsList>
    </template>

    <template v-if="tabId && graphByYear">
      <div class="switch-wrapper my-5">
        <RedesignedSwitchComponent
          :checked="switchRows.tradingGraph"
          textTrue="Диаграмма"
          textFalse="Таблица"
          @switchHandler="switchRows.tradingGraph = !switchRows.tradingGraph"
        />
        <h2>
          <template v-if="isProductMode">Торговля товарами</template><template v-else>Торговля услугами</template>,
          {{ dividerLabel }} $ США
        </h2>
      </div>
      <div v-if="switchRows.tradingGraph && (tabId === 1 || tabId === 2 || tabId === 3 || tabId === 5)">
        <LineChartFour
          v-if="!quartal"
          format-label=" млн $"
          :chart-data="graphByYear.data[0]"
          :chart-data-second="graphByYear.data[1]"
          :chart-data-three="graphByYear.data[2]"
          :chart-data-four="graphByYear.data[3]"
          background-color-three="#2F84EB"
          background-color-four="#31BC00"
          point-color-three="#2F84EB"
          point-color-four="#31BC00"
          background-color="#01A39D"
          point-color="#01A39D"
          background-color-two="#787F8F"
          point-color-two="#787F8F"
          border-color="#01A39D"
          border-color-two="#787F8F"
          border-color-three="#2F84EB"
          border-color-four="#31BC00"
          :labels="graphByYear.years"
          :displayDataLabels="false"
        />
        <LineChartFour
          v-else
          format-label=""
          :chart-data="series[0]?.data"
          :chart-data-second="series[1]?.data"
          :chart-data-three="series[2]?.data"
          :chart-data-four="series[3]?.data"
          background-color-three="#2F84EB"
          background-color-four="#31BC00"
          point-color-three="#2F84EB"
          point-color-four="#31BC00"
          background-color="#01A39D"
          point-color="#01A39D"
          background-color-two="#787F8F"
          point-color-two="#787F8F"
          border-color="#01A39D"
          border-color-two="#787F8F"
          border-color-three="#2F84EB"
          border-color-four="#31BC00"
          :labels="categories"
          :displayDataLabels="false"
          :key="graphKey"
        />
        <div class="d-flex align-items-center mt-4">
          <div class="indicator-round d-flex align-items-center mr-4">
            <span class="rounded-circle" style="background-color: #01a39d"></span>
            <p>{{ isProductMode ? 'Товарооборот' : 'Оборот услуг' }}</p>
          </div>
          <div class="indicator-round d-flex align-items-center mr-4">
            <span class="rounded-circle"></span>
            <p>Экспорт</p>
          </div>
          <div class="indicator-round d-flex align-items-center mr-4">
            <span class="rounded-circle"></span>
            <p>Импорт</p>
          </div>
          <div class="indicator-round d-flex align-items-center">
            <span class="rounded-circle"></span>
            <p>Сальдо</p>
          </div>
        </div>
      </div>
      <div v-if="switchRows.tradingGraph && tabId === 4">
        <LineChartDouble
          labelAlignY="end"
          labelAnchorY="end"
          :enableTooltip="false"
          format-label=" млн $"
          :chart-data="graphByYear.data[4]"
          background-color="#01A39D"
          point-color="#01A39D"
          background-color-two="#787F8F"
          point-color-two="#787F8F"
          border-color="#01A39D"
          border-color-two="#787F8F"
          :labels="graphByYear.years"
          :displayDataLabels="false"
        />
        <div class="d-flex align-items-center mt-4">
          <div class="indicator-round d-flex align-items-center mr-4">
            <span class="rounded-circle"></span>
            <p>Абсолютное значение</p>
          </div>
        </div>
      </div>
      <div class="table-container js-table-arrow" v-if="!switchRows.tradingGraph">
        <table class="stockTable">
          <thead>
            <tr>
              <th style="font-size: 14px">Год</th>
              <th style="font-size: 14px">
                {{ isProductMode ? 'Товарооборот' : 'Оборот услуг' }}
              </th>
              <th style="font-size: 14px">Экспорт</th>
              <th style="font-size: 14px">Импорт</th>
              <th style="font-size: 14px">Сальдо</th>
              <th v-if="isProductMode" style="font-size: 14px">ННЭ</th>
            </tr>
          </thead>
          <tbody v-if="tableData">
            <tr v-for="(item, index) in tableData" :key="index">
              <td class="font-weight-bold">{{ item.year }}</td>
              <td class="font-weight-bold">
                {{ formatNumber(item.total / (isProductMode ? million : 1000)) }}
              </td>
              <td class="font-weight-bold">
                {{ formatNumber(item.export / (isProductMode ? million : 1000)) }}
              </td>
              <td class="font-weight-bold">
                {{ formatNumber(item.import / (isProductMode ? million : 1000)) }}
              </td>
              <td class="font-weight-bold">
                {{ formatNumber(item.balance / (isProductMode ? million : 1000)) }}
              </td>
              <td v-if="isProductMode" class="font-weight-bold">
                {{ formatNumber(item.nonEnergy / (isProductMode ? million : 1000)) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <template v-if="isProductMode">
      <!-- Товарная структура экспорта -->
      <div v-if="tabId && tabId !== 4 && tabId != 3 && currentSummary && currentSummary.topExportCode2?.length > 0">
        <div class="switch-wrapper my-5">
          <RedesignedSwitchComponent
            textTrue="Диаграмма"
            textFalse="Таблица"
            @switchHandler="switchRows.tovarnayaStructureExport = !switchRows.tovarnayaStructureExport"
          />
          <h2>Товарная структура экспорта</h2>
        </div>
        <!-- Диаграмма -->
        <template v-if="switchRows.tovarnayaStructureExport">
          <!-- <h2 class="my-5">Товарная структура экспорта</h2> -->
          <template v-if="currentSummary">
            <ColumnChartDouble
              labelAlignY="end"
              labelAnchorY="end"
              :enableTooltip="false"
              indexAxis="y"
              barPercentage="1"
              :format-label="null"
              color-first="#04b1e0"
              color-second="#00C6A2"
              label-first="Текущий период, млн $"
              label-second="Прошедший период, млн $"
              height="600px"
              :chart-data="currentSummary.topExportCode2.map((item) => (item.currentValue / divider).toFixed(0))"
              :chart-data-two="currentSummary.topExportCode2.map((item) => (item.previousValue / divider).toFixed(0))"
              :labels="currentSummary.topExportCode2.map((item) => makeChartLabel(item.title, 70))"
            />
          </template>
        </template>
        <!-- Таблица -->
        <template v-else>
          <!-- <h2 class="my-5" v-if="currentSummary">
            Товарная структура экспорта (таблица)
          </h2> -->
          <InternationalTradeExportTable
            name="Экспорт"
            :currentSummary="currentSummary"
            dividerLabel="тыс $"
            exportGroups="exportCode2TotalCurrent"
            exportGroupsPrevious="exportCode2TotalPrevious"
            topExportGroups="topExportCode2"
          />
        </template>
      </div>
      <!-- Товарная структура экспорта -->
      <div v-if="tabId === 4 && currentSummary && currentSummary.topExportNonEnergyCode2?.length > 0">
        <div class="switch-wrapper my-5">
          <RedesignedSwitchComponent
            textTrue="Диаграмма"
            textFalse="Таблица"
            @switchHandler="switchRows.tovarnayaStructureExportSecond = !switchRows.tovarnayaStructureExportSecond"
          />
          <h2>Товарная структура экспорта</h2>
        </div>
        <!-- Диаграмма -->
        <template v-if="switchRows.tovarnayaStructureExportSecond">
          <!-- <h2 class="my-5">Товарная структура экспорта</h2> -->
          <template v-if="currentSummary">
            <ColumnChartDouble
              labelAlignY="end"
              labelAnchorY="end"
              :enableTooltip="false"
              indexAxis="y"
              barPercentage="1"
              :format-label="null"
              color-first="#04b1e0"
              color-second="#00C6A2"
              label-first="Текущий период"
              label-second="Прошедший период"
              :chart-data="
                currentSummary.topExportNonEnergyCode2.map((item) => (item.currentValue / divider).toFixed(0))
              "
              :chart-data-two="
                currentSummary.topExportNonEnergyCode2.map((item) => (item.previousValue / divider).toFixed(0))
              "
              :labels="currentSummary.topExportNonEnergyCode2.map((item) => makeChartLabel(item.title, 70))"
            />
          </template>
        </template>
        <!-- Таблица -->
        <template v-else>
          <!-- <h2 class="my-5" v-if="currentSummary">
            Товарная структура экспорта (таблица)
          </h2> -->
          <InternationalTradeExportTable
            name="Импорт"
            :currentSummary="currentSummary"
            dividerLabel=""
            exportGroups="exportNonEnergyCode2TotalCurrent"
            exportGroupsPrevious="exportNonEnergyCode2TotalPrevious"
            topExportGroups="topExportNonEnergyCode2"
          />
        </template>
      </div>
      <!-- Товарная структура импорта -->
      <div v-if="tabId && tabId !== 4 && tabId != 2 && currentSummary && currentSummary.topImportCode2?.length > 0">
        <div class="switch-wrapper my-5">
          <RedesignedSwitchComponent
            textTrue="Диаграмма"
            textFalse="Таблица"
            @switchHandler="switchRows.tovarnayaStructureImport = !switchRows.tovarnayaStructureImport"
          />
          <h2>Товарная структура импорта</h2>
        </div>
        <!-- Диаграмма -->
        <template v-if="switchRows.tovarnayaStructureImport">
          <!-- <h2 class="my-5">Товарная структура импорта</h2> -->
          <template v-if="currentSummary">
            <ColumnChartDouble
              labelAlignY="end"
              labelAnchorY="end"
              :enableTooltip="false"
              indexAxis="y"
              barPercentage="1"
              :format-label="null"
              color-first="#04b1e0"
              color-second="#00C6A2"
              label-first="Текущий период, млн $"
              label-second="Прошедший период, млн $"
              :chart-data="currentSummary.topImportCode2.map((item) => (item.currentValue / divider).toFixed(0))"
              :chart-data-two="currentSummary.topImportCode2.map((item) => (item.previousValue / divider).toFixed(0))"
              :labels="currentSummary.topImportCode2.map((item) => makeChartLabel(item.title, 70))"
            />
          </template>
        </template>
        <!-- Таблица -->
        <template v-else>
          <!-- <h2 class="my-5" v-if="currentSummary">
            Товарная структура импорта (таблица)
          </h2> -->
          <InternationalTradeExportTable
            name="Импорт"
            :currentSummary="currentSummary"
            dividerLabel="тыс $"
            exportGroups="importCode2TotalCurrent"
            exportGroupsPrevious="importCode2TotalPrevious"
            topExportGroups="topImportCode2"
          />
        </template>
      </div>
      <!-- ТОП-5 ТОВАРОВ экспорта импорт -->
      <template v-if="tabId && tabId !== 4">
        <!-- ТОП-5 ТОВАРОВ экспорта -->
        <template v-if="currentSummary && currentSummary.topExportGroups?.length > 0 && tabId != 3">
          <div class="switch-wrapper my-5">
            <RedesignedSwitchComponent
              textTrue="Диаграмма"
              textFalse="Таблица"
              @switchHandler="switchRows.topTovariExport = !switchRows.topTovariExport"
            />
            <h2>Топ-5 товаров экспорта</h2>
          </div>
          <!-- Диаграмма -->
          <div v-if="switchRows.topTovariExport">
            <!-- <h2 class="my-5">ТОП-5 ТОВАРОВ экспорта</h2> -->
            <ColumnChartDouble
              labelAlignY="end"
              labelAnchorY="end"
              :enableTooltip="false"
              indexAxis="y"
              barPercentage="1"
              :format-label="null"
              label-first="Текущий период тыс $"
              label-second="Прошедший период тыс $"
              color-first="#04b1e0"
              color-second="#00C6A2"
              :chart-data="currentSummary.topExportGroups.map((item) => (item.currentValue / 1000).toFixed(2))"
              :chart-data-two="currentSummary.topExportGroups.map((item) => (item.previousValue / 1000).toFixed(2))"
              :labels="currentSummary.topExportGroups.map((item) => makeChartLabel(item.title, 70))"
            />
          </div>
          <!-- Таблица -->
          <div v-else class="mt-5">
            <!-- <h2 class="my-5" v-if="currentSummary">
              ТОП-5 ТОВАРОВ (таблица)
            </h2> -->
            <InternationalTradeExportTable
              name="Экспорт"
              :currentSummary="currentSummary"
              dividerLabel="тыс $"
              exportGroups="exportGroupsTotalCurrent"
              exportGroupsPrevious="exportGroupsTotalPrevious"
              topExportGroups="topExportGroups"
            />
          </div>
        </template>
        <!-- ТОП-5 ТОВАРОВ импорта -->
        <template v-if="currentSummary && currentSummary.topImportGroups?.length > 0 && tabId != 2">
          <div class="switch-wrapper my-5">
            <RedesignedSwitchComponent
              textTrue="Диаграмма"
              textFalse="Таблица"
              @switchHandler="switchRows.topTovariImport = !switchRows.topTovariImport"
            />
            <h2>Топ-5 товаров импорта</h2>
          </div>
          <!-- Диаграмма -->
          <div v-if="switchRows.topTovariImport">
            <!-- <h2 class="my-5">ТОП-5 ТОВАРОВ импорта</h2> -->
            <ColumnChartDouble
              labelAlignY="end"
              labelAnchorY="end"
              :enableTooltip="false"
              indexAxis="y"
              barPercentage="1"
              :format-label="null"
              label-first="Текущий период тыс $"
              label-second="Прошедший период тыс $"
              color-first="#04b1e0"
              color-second="#00C6A2"
              :chart-data="currentSummary.topImportGroups.map((item) => (item.currentValue / divider).toFixed(0))"
              :chart-data-two="currentSummary.topImportGroups.map((item) => (item.previousValue / divider).toFixed(0))"
              :labels="currentSummary.topImportGroups.map((item) => makeChartLabel(item.title, 70))"
            />
          </div>
          <!-- Таблица -->
          <div v-else class="mt-5">
            <!-- <h2 class="my-5" v-if="currentSummary">
              ТОП-5 ТОВАРОВ (таблица)
            </h2> -->
            <InternationalTradeExportTable
              name="Импорт"
              :currentSummary="currentSummary"
              dividerLabel="тыс $"
              exportGroups="importGroupsTotalCurrent"
              exportGroupsPrevious="importGroupsTotalPrevious"
              topExportGroups="topImportGroups"
            />
          </div>
        </template>
      </template>
      <!-- ТОП-5 ТОВАРОВ экспорта снова -->
      <template v-if="tabId === 4 && currentSummary && currentSummary.topExportNonEnergyGroups?.length > 0">
        <div class="switch-wrapper my-5">
          <RedesignedSwitchComponent
            textTrue="Диаграмма"
            textFalse="Таблица"
            @switchHandler="switchRows.topTovariExportSecond = !switchRows.topTovariExportSecond"
          />
          <h2>Топ-5 товаров экспорта</h2>
        </div>
        <div v-if="switchRows.topTovariExportSecond">
          <!-- <h2 class="my-5">ТОП-5 ТОВАРОВ экспорта</h2> -->
          <ColumnChartDouble
            labelAlignY="end"
            labelAnchorY="end"
            :enableTooltip="false"
            indexAxis="y"
            barPercentage="1"
            :format-label="null"
            label-first="Текущий период"
            label-second="Прошедший период"
            color-first="#04b1e0"
            color-second="#00C6A2"
            :chart-data="currentSummary.topExportNonEnergyGroups.map((item) => (item.currentValue / 1000).toFixed(2))"
            :chart-data-two="
              currentSummary.topExportNonEnergyGroups.map((item) => (item.previousValue / 1000).toFixed(2))
            "
            :labels="currentSummary.topExportNonEnergyGroups.map((item) => makeChartLabel(item.title, 70))"
          />
        </div>
        <div v-else class="mt-5">
          <!-- <h2 class="my-5" v-if="currentSummary">
            ТОП-5 ТОВАРОВ (таблица)
          </h2> -->
          <InternationalTradeExportTable
            name="Экспорт"
            :currentSummary="currentSummary"
            dividerLabel=""
            exportGroups="exportNonEnergyGroupsTotalCurrent"
            exportGroupsPrevious="exportNonEnergyGroupsTotalPrevious"
            topExportGroups="topExportNonEnergyGroups"
          />
        </div>
      </template>
    </template>

    <div class="mt-5" v-if="!isProductMode && currentSummary && currentSummary.topImportGroups?.length > 0">
      <h2>Туризм</h2>
      <ColumnChartDouble
        label-first="Выезд российских граждан в страну, тыс чел"
        label-second="Въезд иностранных граждан в Российскую Федерацию, тыс чел"
        format-label=" тыс чел"
        :isShowCustomLegend="true"
        :chart-data="currentSummary.topImportGroups.map((item) => item.previousValue.toFixed(0))"
        :chart-data-two="currentSummary.topImportGroups.map((item) => item.currentValue.toFixed(0))"
        :labels="currentSummary.topImportGroups.map((item) => item.title)"
      />
    </div>
  </div>
</template>

<script>
  import RedesignedSwitchComponent from '@/common/components/redesigned/RedesignedSwitchComponent.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';
  import text from '@/common/mixins/text';

  import ColumnChartDouble from '@/components/columnChartDouble';
  import LineChartDouble from '@/components/lineChartDouble';
  import LineChartFour from '@/components/lineChartFour';

  import IndicatorApi from '@/modules/admin/api/indicatorVisibility';
  import Api from '@/modules/countryАnalytics/api';

  import { DIVIDER_LABEL, IndicatorPreviousValue } from '../../utils/constants';
  import TradeIndicator from './indicators/TradeIndicator.vue';
  import TradeIndicatorsList from './indicators/TradeIndicatorsList.vue';
  import InternationalTradeExportTable from './InternationalTradeExportTable.vue';
  import InternationalTradeMixin from './mixin/InternationalTradeMixin';

  export default {
    name: 'InternationalTradeRussiaEconRev',
    mixins: [text, InternationalTradeMixin],
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    components: {
      LineChartDouble,
      LineChartFour,
      ColumnChartDouble,
      RedesignedSwitchComponent,
      InternationalTradeExportTable,
      TradeIndicator,
      TradeIndicatorsList,
      SelectMultiComponent,
    },
    data() {
      return {
        isProductMode: true,
        tabId: null,
        label: null,
        tableData: null,

        turnoverMoreInfo: true,
        exportMoreInfo: true,
        importMoreInfo: true,
        exportNonEnergyMoreInfo: true,
        balanceMoreInfo: true,

        divider: Constants.MILLION,
        dividerLabel: DIVIDER_LABEL,
        indicatorValue: Constants.indicator.value,
        indicatorPreviousPercentValue: Constants.indicator.previousPercentValue,
        indicatorPreviousValue: IndicatorPreviousValue,
        million: Constants.MILLION,

        switchRows: {
          tovarnayaStructureExport: true,
          tovarnayaStructureExportSecond: true,
          tovarnayaStructureImport: true,
          topTovariExport: true,
          topTovariImport: true,
          topTovariExportSecond: true,
          tradingGraph: false,
        },
      };
    },

    computed: {
      currentSummary() {
        return this.summary?.summary;
      },
    },
    created() {
      this.loadIndicatorVisibility();
      this.loadYearList(true, true);
      //this.loadData();
    },
    methods: {
      loadIndicatorVisibility() {
        IndicatorApi.search().then((response) => {
          this.turnoverMoreInfo = response.data[13].moreInfoVisible;
          this.importMoreInfo = response.data[14].moreInfoVisible;
          this.exportMoreInfo = response.data[15].moreInfoVisible;
          this.exportNonEnergyMoreInfo = response.data[16].moreInfoVisible;
          this.balanceMoreInfo = response.data[17].moreInfoVisible;
        });
      },
      yearChanged() {
        this.$emit('yearChanged', this.year);
        this.loadData();
        if (!this.isProductMode) {
          this.loadQuartals();
        }
      },
      loadData() {
        this.summary = null;
        this.graphByYear = null;
        Api.getForeignTradeForOne(true, this.isProductMode, this.year, this.countryId).then((response) => {
          this.summary = response.data;

          this.graphByYear = {
            years: this.summary.summary.valuesByYears.map((x) => x.year),
            data: [
              this.getValuesByYears(this.indicatorValue.TOTAL_VALUE, this.divider),
              this.getValuesByYears(this.indicatorValue.EXPORT_VALUE, this.divider),
              this.getValuesByYears(this.indicatorValue.IMPORT_VALUE, this.divider),
              this.getValuesByYears(this.indicatorValue.BALANCE, this.divider),
              this.getValuesByYears(this.indicatorValue.EXPORT_NON_ENERGY_VALUE, this.divider),
            ],
          };

          this.tableData = this.summary.summary.valuesByYears.map((x) => {
            return {
              year: x.year,
              total: x.totalValue,
              export: x.exportValue,
              import: x.importValue,
              balance: x.balance,
              nonEnergy: x.exportNonEnergyValue,
            };
          });

          this.$forceUpdate();
        });
      },
      switchHand(e) {
        this.isProductMode = e;
        const isQuartal = this.quartal ? 1 : Constants.THOUSAND;

        this.divider = e ? this.million : isQuartal;
        this.loadYearList(true, true);
        this.$forceUpdate();
      },
      /**@public
       * Используется в миксине InternationalTradeMixin
       */
      selectTab(id, label) {
        if (id != this.tabId) {
          this.tabId = id;
          this.label = label;
        } else {
          // при повторном клике - сворачивание
          this.tabId = null;
          this.label = null;
        }
      },
    },
    watch: {
      countryId: function () {
        this.loadYearList(true, true);
        this.clearGraph();
        if (!this.isProductMode) {
          this.loadQuartals();
        }
      },
      year: function () {
        if (!this.isProductMode) {
          this.loadQuartals();
        }
      },
      quartal(val) {
        if (val == null) {
          this.divider = this.isProductMode ? Constants.MILLION : Constants.THOUSAND;
          this.loadData();
        } else {
          this.divider = 1;
        }
      },
      isProductMode(val) {
        if (val) {
          this.quartal = null;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import './src/assets/theme/default/colors';
  @import './styles/InternationalTradeRussiaEconRev.scss';
  @import '../../styles/countryAnalyticsMultiselect.scss';
</style>
