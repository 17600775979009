<template>
  <div class="tecRussia-container">
    <InternationalTradeRussiaEconRev :notForTecRussiaTab="true" :country-id="countryId" @yearChanged="onYearChanged" />

    <!-- инвестиции -->
    <div class="mb-59">
      <div v-if="visibleIndicatorList.length > 0" class="economicsTypeBlock">
        <p class="economicsTypeBlock-title">Инвестиции</p>
        <ul class="indicators__list align-center mt-8">
          <li v-for="item in visibleIndicatorList" :key="item.id" class="indicators__itemCustom">
            <p class="text-align-left fs14 fw600 mb-6">{{ item.title }}</p>
            <template v-if="item.value">
              <div class="d-flex align-items-center">
                <strong class="fs22 fw700 mr-8 text-nowrap">{{ item.value == '0,00' ? '-' : item.value }} </strong>
                <div>
                  <div class="fs12">млн $</div>
                  <div v-if="item.period" class="analytics-source-text fs10">Период: {{ item.period }}</div>
                </div>
              </div>
              <div class="analytics-source-text" v-if="item.place">
                <template v-if="item.value != '0,00'"> {{ item.place }} место </template>
              </div>
              <div
                class="analytics-source-text"
                v-if="item.percent"
                :class="[{ 'text-green': item.percent >= 0 }, { 'text-red': item.percent < 0 }]"
              >
                {{ item.percent.toFixed(2) }}%
              </div>
            </template>
            <template v-else-if="item.place">
              <strong>{{ item.place }} место</strong>
            </template>
          </li>
        </ul>
      </div>
    </div>
    <!-- ограничения -->
    <div class="mb-59">
      <div v-if="unfriendlyCountryInfoList.length > 0" class="economicsTypeBlock">
        <div class="economicsTypeBlock-title">
          Ограничения
          <Tooltip v-if="isEuropeanUnionCountry" :triggers="['click']">
            Приведена информация об ограничениях, введенных Европейским союзом
          </Tooltip>
        </div>
        <ul class="indicators__list align-center mt-8">
          <li v-for="item in unfriendlyCountryInfoList" :key="item.id" class="indicators__itemCustom">
            <p class="text-align-left fs14 fw600 mb-6">{{ item.title }}</p>
            <template v-if="item.value">
              <div class="d-flex align-items-center">
                <strong class="fs22 fw700 mr-8 text-nowrap">{{ item.value }} </strong>
                <div>
                  <div v-if="item.measure" class="fs12">млн $</div>
                </div>
              </div>
            </template>
          </li>
        </ul>
      </div>
    </div>
    <div class="globalTrade-container">
      <InternationalTradeEconRev :country-id="countryId" :notForTecRussiaTab="true" />
      <InvestmentsEconRev :country-id="countryId" :notForTecRussiaTab="true" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { isEmpty } from 'lodash';

  import text from '@/common/mixins/text';
  import Tooltip from '@/common/ui/Tooltip.vue';

  import { COUNTRY_ANALYTICS_ACTIONS_NAME, COUNTRY_ANALYTICS_GETTERS_NAME } from '../../store/analytics/directoryNames';
  import InternationalTradeEconRev from '../InternationalTrade/InternationalTradeEconRev.vue';
  import InternationalTradeRussiaEconRev from '../InternationalTrade/InternationalTradeRussiaEconRev.vue';
  import InvestmentsEconRev from '../investments/investmentsEconRev';

  export default {
    name: 'TecRussia',
    mixins: [text],
    components: {
      InternationalTradeRussiaEconRev,
      InternationalTradeEconRev,
      InvestmentsEconRev,
      Tooltip,
    },
    props: {
      countryId: {
        type: String,
        default: '',
      },
      unfriendlyCountryInfo: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        year: new Date().getFullYear() - 1,
        loading: false,
      };
    },
    computed: {
      ...mapGetters({
        getInStockInvestment: COUNTRY_ANALYTICS_GETTERS_NAME.getInStockInvestment,
        getOutStockInvestment: COUNTRY_ANALYTICS_GETTERS_NAME.getOutStockInvestment,
        getInFlowsInvestment: COUNTRY_ANALYTICS_GETTERS_NAME.getInFlowsInvestment,
        getOutFlowsInvestment: COUNTRY_ANALYTICS_GETTERS_NAME.getOutFlowsInvestment,
      }),

      visibleIndicatorList() {
        return [
          {
            id: 8,
            title: 'Входящие ПИИ, запас',
            period: `${this.getInStockInvestment?.yearPeriod} год`,
            value: this.getInStockInvestment?.value ? this.formatNumber(this.getInStockInvestment.value, 0) : '',
          },
          {
            id: 9,
            title: 'Исходящие ПИИ, запас',
            period: `${this.getOutStockInvestment?.yearPeriod} год`,
            value: this.getOutStockInvestment?.value ? this.formatNumber(this.getOutStockInvestment?.value, 0) : '',
          },
          {
            id: 10,
            title: 'Входящие ПИИ, поток',
            period: `${this.getInFlowsInvestment?.yearPeriod} год`,
            value: this.getInFlowsInvestment?.value ? this.formatNumber(this.getInFlowsInvestment.value, 0) : '',
          },
          {
            id: 11,
            title: 'Исходящие ПИИ, поток',
            period: `${this.getOutFlowsInvestment?.yearPeriod} год`,
            value: this.getOutFlowsInvestment?.value ? this.formatNumber(this.getOutFlowsInvestment.value, 0) : '',
          },
        ].filter((x) => x.value !== '');
      },

      unfriendlyCountryInfoList() {
        if (isEmpty(this.unfriendlyCountryInfo)) {
          return [];
        }
        return [
          {
            id: 12,
            title: 'Общее количество ограничений',
            value: this.unfriendlyCountryInfo?.numberOfRestrictions
              ? this.formatNumber(this.unfriendlyCountryInfo?.numberOfRestrictions, 0)
              : '0',
            measure: false,
          },
          {
            id: 13,
            title: 'Стоимость экспорта под ограничениями',
            value: this.unfriendlyCountryInfo?.exportValueUnderRestrictions
              ? this.formatNumber(this.unfriendlyCountryInfo?.exportValueUnderRestrictions, 1)
              : '0,0',
            measure: true,
          },
          {
            id: 14,
            title: 'Стоимость импорта под ограничениями',
            value: this.unfriendlyCountryInfo?.importValueUnderRestrictions
              ? this.formatNumber(this.unfriendlyCountryInfo?.importValueUnderRestrictions, 1)
              : '0,0',
            measure: true,
          },
        ];
      },

      isEuropeanUnionCountry() {
        return this.unfriendlyCountryInfo?.country?.countryGroupId === 10;
      },
    },
    watch: {
      countryId: function () {
        this.loadData();
      },
    },
    created() {
      this.loadData();
    },
    methods: {
      ...mapActions({
        getInvestmentInStock: COUNTRY_ANALYTICS_ACTIONS_NAME.getInvestmentInStock,
        getInvestmentOutStock: COUNTRY_ANALYTICS_ACTIONS_NAME.getInvestmentOutStock,
        getInvestmentInFlows: COUNTRY_ANALYTICS_ACTIONS_NAME.getInvestmentInFlows,
        getInvestmentOutFlows: COUNTRY_ANALYTICS_ACTIONS_NAME.getInvestmentOutFlows,
      }),

      loadData() {
        this.loadInvestments();
      },

      onYearChanged(year) {
        this.year = year;
      },

      async loadInvestments() {
        this.loading = true;
        try {
          await Promise.all([
            this.getInvestmentInStock({ countryId: this.countryId }),
            this.getInvestmentOutStock({ countryId: this.countryId }),
            this.getInvestmentInFlows({ countryId: this.countryId }),
            this.getInvestmentOutFlows({ countryId: this.countryId }),
          ]);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .indicators {
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 36px 120px;
      li {
        max-width: 160px;
      }

      @media (max-width: 1150px) {
        gap: 36px;
        justify-content: center !important;
      }
      @media (max-width: 892px) {
        justify-content: space-between !important;
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 180px;
      max-width: 180px;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      border: 1px solid #06a0d8a9;
      p {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 113.9%;
        text-align: center;
        color: #333333;
      }
      strong {
        margin-top: 20px;
        margin-bottom: 9px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #333333;
      }
      span {
        margin-bottom: 4px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #333333;
      }
    }
  }
  h2 {
    margin-bottom: 20px;
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 113.9%;
    color: #000000;
  }

  .indicators__item .analytics-source-text {
    font-size: 10px;
    text-align: center;
  }

  .indicator-round {
    span {
      background-color: #01a39d;
      width: 14px;
      height: 14px;
    }
    p {
      margin-left: 13px;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
  }
  .indicator-round:nth-child(2) {
    span {
      background-color: #787f8f;
    }
  }
  .indicator-round:nth-child(3) {
    span {
      background-color: #2f84eb;
    }
  }
  .indicator-round:nth-child(4) {
    span {
      background-color: #31bc00;
    }
  }
</style>

<style lang="scss">
  .tecRussia-container {
    .notForTecRussiaTab {
      h2,
      .indicators__item .btn-default {
        display: none !important;
      }
      .indicators__wrapper {
        margin-bottom: 17px;
      }
    }
  }
  .globalTrade-container {
    .investments {
      .indicators__list {
        margin-top: 8px !important;
        gap: 32px 100px;
        justify-content: space-between;
      }
      h3 {
        display: none !important;
      }
    }
    .notForTecRussiaTab .indicators-two {
      display: none !important;
    }
  }
  .indicators__itemCustom {
    min-width: 150px;
  }
</style>
