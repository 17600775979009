<template>
  <div class="popup">
    <div class="popup__head d-flex align-items-center">
      <div
        v-if="country?.picture"
        class="imagePreviewWrapper"
        :style="{ 'background-image': `url(${country?.picture})` }"
      ></div>
      <p class="font-weight-bold">{{ country?.nameFull }}</p>
    </div>
    <div class="popup__body p-3">
      <template v-if="countryId != countryRussiaId">
        <div class="popup__body_details">
          <div>
            <p class="my-1 mx-0">Торговля страны с Россией</p>
            <p class="my-1 mx-0">
              Период: <span>{{ analyticsData.year }}</span> год
            </p>
            <p class="my-1 mx-0">
              Товарооборот: <span>{{ formatNumber(analyticsData.totalValue / 1000000) }}</span> млн $
            </p>
            <p class="my-1 mx-0">
              Экспорт товаров: <span>{{ formatNumber(analyticsData.exportValue / 1000000) }}</span> млн $
            </p>
            <p class="my-1 mx-0">
              Несырьевой неэнергетический<br />экспорт:
              <span>{{ formatNumber(analyticsData.exportNonEnergyValue / 1000000) }}</span> млн $
            </p>
            <p class="my-1 mx-0">
              Импорт товаров: <span>{{ formatNumber(analyticsData.importValue / 1000000) }}</span> млн $
            </p>
            <p class="my-1 mx-0">
              Сальдо: <span>{{ formatNumber(analyticsData.balance / 1000000) }}</span> млн $
            </p>
          </div>
        </div>
      </template>
      <ButtonStock class="btn-small mt-2" title="Просмотр" v-on:click="openEconomicsReview" />
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import text from '@/common/mixins/text';

  import ButtonStock from '@/components/buttons/ButtonStock';

  import API from '@/modules/countryAnalyticsNew/api/api';
  import API_Country from '@/modules/nsi/api/country';

  export default {
    name: 'AnalyticsPopup',
    components: { ButtonStock },
    emits: ['detailsClick'],
    mixins: [text],
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        country: null,
        countryRussiaId: Constants.countryRussiaId,
        analyticsData: {},
      };
    },
    created() {},
    mounted() {},
    beforeUnmount() {},
    methods: {
      loadCountry() {
        this.country = null;
        if (this.countryId)
          API_Country.findShort(this.countryId).then((response) => {
            this.country = response.data;
          });
      },
      loadAnalyticsData() {
        API.getExportProductLast(this.countryId).then((response) => {
          this.analyticsData = response.data;
        });
      },
      openEconomicsReview() {
        this.$emit('detailsClick', this.countryId);
      },
    },
    watch: {
      countryId: function () {
        this.loadCountry();
        if (this.countryId) this.loadAnalyticsData();
      },
    },
    computed: {},
  };
</script>

<style lang="scss" scoped>
  .popup {
    &__head {
      background: #1d87ee;
      p,
      span {
        margin: 10px 32px 10px 24px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 160%;
        color: #ffffff;
      }
    }
  }
  .imagePreviewWrapper {
    width: 40px;
    height: 40px;
    display: block;
    cursor: pointer;
    margin: 5px 10px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
</style>
