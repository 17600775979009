<template>
  <div class="modal__body-wrapper">
    <div class="mt-2">
      <div class="form-control-input-wrapper">
        <input v-model="request.term" placeholder="Найти..." class="form-control" v-on:input="search" />
      </div>
      <template v-if="page.items">
        <div class="table-container customTable mb-16 js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th></th>
                <th>Код</th>
                <th>Наименование</th>
              </tr>
            </thead>
            <tbody v-if="page.items.length">
              <tr v-for="item in page.items" :key="item.id">
                <td>
                  <template v-if="!returnCodes">
                    <input
                      type="checkbox"
                      v-model="selection"
                      :disabled="isCheckDisabled(item)"
                      :value="item.id"
                      @click="checkClick"
                    />
                  </template>
                  <template v-else>
                    <input
                      type="checkbox"
                      v-model="selection"
                      :disabled="isCheckDisabled(item)"
                      :value="item.code"
                      @click="checkClick"
                    />
                  </template>
                </td>
                <td>{{ item.code }}</td>
                <td
                  :class="{ textupper: item.level === 1, textbold: !item.isLeaf }"
                  :style="{ paddingLeft: item.level * 10 + 'px' }"
                >
                  <i v-if="!item.isLeaf" class="fas fa-folder"></i>
                  {{ item.name }}
                </td>
              </tr>
            </tbody>
            <tbody v-if="!page.items.length">
              <tr>
                <td class="text-center" colspan="4">Нет данных.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal__body-pagination" v-if="page.pagesTotal > 1">
          <Pagination
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
        <div class="modal__body-footer">
          <div>
            <ButtonStock class="mr-8" title="Выбрать" v-on:click="select" />
            <ButtonStock title="Отмена" v-on:click="close" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock';

  import API from '../api/nsi';

  export default {
    name: 'CatalogSelector',
    components: { ButtonStock },
    props: {
      route: {
        type: String,
        required: true,
      },
      multiple: {
        type: Boolean,
        default: true,
      },
      selectGroupsOnly: {
        type: Boolean,
        default: false,
      },
      selectLeafsOnly: {
        type: Boolean,
        default: false,
      },
      returnCodes: {
        type: Boolean,
        default: false,
      },
      selectedIds: Object,
    },
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: 8,
          term: '',
        },
        selection: this.selectedIds ?? [],
        timerId: null,
      };
    },
    watch: {
      route: function () {
        this.selection = [];
        this.loadPage();
      },
      selectedIds: function () {
        this.selection = this.selectedIds;
      },
    },
    created() {
      this.loadPage();
    },
    mounted() {
      let wrapper = document.querySelector('.form-control-input-wrapper');
      wrapper.addEventListener(
        'select',
        function (event) {
          event.stopImmediatePropagation();
        },
        true,
      );
    },
    methods: {
      loadPage(append) {
        API.search(this.route, this.request).then((response) => {
          if (!append) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
        });
      },
      search() {
        if (this.timerId) {
          clearTimeout(this.timerId);
          this.timerId = null;
        }
        this.timerId = setTimeout(() => {
          this.request.pageNumber = 1;
          this.loadPage();
        }, 1000);
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      select() {
        if (!this.selection?.length) {
          Constants.alert.fire('Выбор', 'Вы не выбрали ни одного элемента.', 'error');
          return;
        }
        this.$emit('select', this.selection);
      },
      checkClick(e) {
        if (!this.multiple) {
          const id = this.selection.find((x) => x !== e.target.value);
          if (id) {
            this.selection.splice(this.selection.indexOf(id), 1);
            this.selection.push(e.target.value);
          }
          //this.selection = [e.target.value];
        }
      },
      isCheckDisabled(item) {
        return (this.selectGroupsOnly && item.isLeaf) || (this.selectLeafsOnly && !item.isLeaf);
      },
      close() {
        this.$emit('close');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .textupper {
    text-transform: uppercase;
  }

  .textbold {
    font-weight: bold;
  }

  .table-container.customTable table.stockTable thead th {
    &:nth-of-type(1) {
      width: 50px;
    }
    &:nth-of-type(2) {
      width: 120px;
    }
  }
</style>
