<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка типа расследования</p>
    </div>

    <tabs>
      <tab name="Основная информация" :selected="true">
        <div>
          <div class="row">
            <div class="col-12">
              <div class="form-group required">
                <ComponentInput
                  v-model="item.name"
                  label="Тип расследования"
                  required
                  maxLength="250"
                  placeholder="Введите название типа расследования"
                />
              </div>
            </div>
          </div>
          <div class="row mt-10">
            <div class="checkboxInput">
              <input type="checkbox" v-model="item.isActive" id="chActive" />
              <label for="chActive">Запись активна</label>
            </div>
          </div>
        </div>

        <div>
          <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
          <ButtonStock title="Отмена" v-on:click="close" />
        </div>
      </tab>
      <tab name="История изменений" v-if="id > 0">
        <div class="table-container mb-16 js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th>Поле</th>
                <th>Значение до</th>
                <th>Значение после</th>
                <th>Пользователь</th>
                <th>Дата обновления</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in historyItems" :key="item.id">
                <td>{{ item.fieldName }}</td>
                <td>{{ item.valueBefore }}</td>
                <td>{{ item.valueAfter }}</td>
                <td>{{ item.createdByName }}</td>
                <td>{{ $momentFormat(item.created, 'DD.MM.YYYY HH:mm') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </tab>
      <tab name="Список связанных сущностей" v-if="id > 0">
        <div class="table-container mb-16 js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th>Товар</th>
                <th>Тип расследования</th>
                <th>Коды ТН ВЭД ЕАЭС</th>
                <th>Страна-экспортер</th>
                <th>Дата начала расследования</th>
                <th>Дата завершения расследования</th>
                <th>Решение Коллегии ЕЭК</th>
                <th>Компании-заявители</th>
                <th>Страна базирования компаний-заявителей</th>
                <th>Компании, поддерживающие заявление</th>
                <th>Страна базирования компаний, поддерживающих заявление</th>
                <th>Статус</th>
              </tr>
            </thead>
            <tbody v-if="page.items?.length && !isLoading">
              <tr v-for="item in page.items" :key="item.id">
                <td>{{ item.tnvedGroup.name }}</td>
                <td>{{ item.investigationType.name }}</td>
                <td>{{ item.tnveds?.map((x) => x.code)?.join(', ') }}</td>
                <td>{{ item.countryExporters?.map((x) => x.name)?.join(', ') }}</td>
                <td>{{ $momentFormat(item.startDate, 'DD.MM.YYYY') }}</td>
                <td>{{ $momentFormat(item.finishDate, 'DD.MM.YYYY') }}</td>
                <td>{{ item.decision }}</td>
                <td>{{ item.companyApplicants }}</td>
                <td>{{ item.countryApplicants?.map((x) => x.name)?.join(', ') }}</td>
                <td>{{ item.companySupportives }}</td>
                <td>{{ item.countrySupportives?.map((x) => x.name)?.join(', ') }}</td>
                <td>{{ item.isActive ? 'действ.' : 'не действ.' }}</td>
              </tr>
            </tbody>
            <tbody v-else-if="isLoading">
              <tr>
                <td class="text-center" colspan="12">Загрузка...</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" colspan="12">Нет данных.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="page.pagesTotal > 1">
          <Pagination
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API_CUSTOM from '../api/investigationType';
  import API from '../api/nsi';

  export default {
    name: 'InvestigationTypeCard',
    components: {
      ComponentInput,
      ButtonStock,
      tabs,
      tab,
    },
    data() {
      return {
        id: this.$route.params.id,
        item: {},
        apiRoute: 'nsiinvestigationtype',
        listName: 'InvestigationTypes',

        historyItems: [],
        page: {},
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        isLoading: false,
      };
    },
    created() {
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          API.find(this.apiRoute, this.id).then((response) => {
            this.item = response.data;
          });

          this.loadHistory();
          this.loadRelated(false);
        } else {
          this.item = {
            isActive: true,
          };
        }
      },
      loadHistory() {
        API_CUSTOM.loadHistory(this.id).then((response) => {
          this.historyItems = response.data;
        });
      },
      loadRelated(append) {
        this.isLoading = !append;
        var params = Object.assign({ id: this.id }, this.request);
        API_CUSTOM.loadRelated(params)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadRelated(addToExist);
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения поля', 'error');
          return;
        }

        API.update(this.apiRoute, this.item)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (this.item.id) {
              this.loadCard();
            } else {
              this.close();
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: this.listName });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
  };
</script>

<style lang="scss" scoped></style>
