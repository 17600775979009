<template>
  <ul class="summary-info-list">
    <li class="summary-info-list__item" v-for="(item, idx) in list" :key="idx">
      {{ item.label }} <span>{{ item.value }}</span>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'SummaryInfoList',
    props: {
      list: {
        type: Array,
        default: () => [],
      },
    },
  };
</script>

<style scoped lang="scss">
  .summary-info-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 20px;

    @media (max-width: $mobile) {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }

    &__item {
      font: $fira-20-M;
      display: flex;
      gap: 12px;

      @media (max-width: $mobile) {
        font: $fira-16-M;
        gap: 8px;
      }

      & span {
        background: linear-gradient(90deg, #0078c8 0%, #01a39d 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        display: inline-block;
      }
    }
  }
</style>
