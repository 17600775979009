import Constants from '@/common/constants';

export const ActiveTabLabel = {
  CREDIT_POLICY: 'Денежно - кредитная политика',
  COMMON_PROJECTS: 'Совместные проекты',
  FINANCE: 'Финансовый сектор',
  INTERNATIONAL_TRADE: 'Внешняя торговля',
  INTEGRATION_INDICATORS: 'Интеграционные показатели',
  HEALTHCARE: 'Здравоохранение',
  MACROECONOMIC: 'Макроэкономика',
  INVESTMENTS: 'Инвестиции',
  ADDITIONAL_INFORMATION: 'Дополнительная информация',
  RATING: 'Рейтинги стран',
  LIFE_INDEX: 'Индекс лучшей жизни',
  FISCAL_POLICY: 'Бюджетная политика',
  GREEN_ECONOMY: 'Зеленая экономика',
  INSTITUTIONAL_COOPERATION: 'Институциональные формы сотрудничества',
  ACCESS_CONDITIONS: 'Условия доступа',
  ECONOMIC_OVERVIEW: 'Обзор экономики',
  PAYMENT_BALANCE: 'Платежный баланс',
  CONNECTIONS_WITH_REGIONS: 'Связи с регионами РФ',
  REFERENCE_INFORMATION_LIST: 'Список файлов',
  REFERENCE_INFORMATION_ARCHIVE: 'Архив',
  REFERENCE_INFORMATION_HISTORY: 'История изменений',
};

export const ListOfSummonsCountries = [
  { name: 'Китай', id: 108 },
  { name: 'Индия', id: 162 },
  { name: 'Турция', id: 44 },
  { name: 'Сербия', id: 249 },
  { name: 'Иран', id: 8 },
  { name: 'Вьетнам', id: 22 },
  { name: 'Беларусь', id: 99 },
  { name: 'Армения', id: 81 },
  { name: 'Узбекистан', id: 60 },
  // { name: "Казахстан", id: 169 },
];

export const DataTabs = [
  { id: 1, text: ActiveTabLabel.MACROECONOMIC },
  { id: 2, text: ActiveTabLabel.INTEGRATION_INDICATORS, hidden: true },
  { id: 3, text: ActiveTabLabel.RATING },
  { id: 96, text: ActiveTabLabel.LIFE_INDEX, hidden: true, veryHidden: true },
  { id: 4, text: ActiveTabLabel.FISCAL_POLICY },
  { id: 5, text: ActiveTabLabel.CREDIT_POLICY },
  { id: 6, text: ActiveTabLabel.FINANCE },
  { id: 7, text: ActiveTabLabel.INTERNATIONAL_TRADE },
  { id: 13, text: ActiveTabLabel.PAYMENT_BALANCE },
  { id: 9, text: ActiveTabLabel.INVESTMENTS },
  { id: 8, text: ActiveTabLabel.CONNECTIONS_WITH_REGIONS },
  { id: 10, text: ActiveTabLabel.GREEN_ECONOMY },
  { id: 11, text: ActiveTabLabel.HEALTHCARE, hidden: true },
  { id: 12, text: ActiveTabLabel.ADDITIONAL_INFORMATION },
];

export const SecondDataTabs = [
  { id: 1, text: ActiveTabLabel.INTERNATIONAL_TRADE },
  { id: 2, text: ActiveTabLabel.ACCESS_CONDITIONS },
  { id: 33, text: ActiveTabLabel.INVESTMENTS },
  { id: 4, text: ActiveTabLabel.COMMON_PROJECTS },
  { id: 5, text: ActiveTabLabel.INSTITUTIONAL_COOPERATION },
];

export const ThirdDataTabs = [
  { id: 1, text: ActiveTabLabel.ECONOMIC_OVERVIEW },
  { id: 2, text: 'ТЭС с Россией' },
];

export const FourthDataTabs = [
  {
    id: 1,
    text: ActiveTabLabel.REFERENCE_INFORMATION_LIST,
    permissionId: Constants.Permissions.CountryAnalyticsReferenceInfoListFiles,
  },
  {
    id: 2,
    text: ActiveTabLabel.REFERENCE_INFORMATION_ARCHIVE,
    permissionId: Constants.Permissions.CountryAnalyticsReferenceInfoArchive,
  },
  {
    id: 3,
    text: ActiveTabLabel.REFERENCE_INFORMATION_HISTORY,
    permissionId: Constants.Permissions.CountryAnalyticsReferenceInfoHistory,
  },
];

export const MapTabs = [
  { id: 1, text: 'Аналитическая карта мира' },
  { id: 2, text: 'Карта проектов' },
  { id: 3, text: 'Карта перспективности страновых рынков' },
  { id: 4, text: 'Тепловая карта соглашений' },
  { id: 5, text: 'Статистика торговых ограничений' },
];

export const IndicatorPreviousValue = {
  BALANCE: 'balancePreviousValue',
};

export const DataItems = [
  {
    name: 'Топливо-энергетические товары',
    id: 1,
    count: 20,
    countTwo: 34,
  },
  {
    name: 'Оборудование и транспортные средства',
    id: 2,
    count: 10,
    countTwo: 34,
  },
  { name: 'Металлы, изделия из них', id: 3, count: 10, countTwo: 34 },
  {
    name: 'Продукция химической промышленности',
    id: 4,
    count: 60,
    countTwo: 34,
  },
  {
    name: 'Древесина и цел.-бумажн. изделия',
    id: 5,
    count: 30,
    countTwo: 34,
  },
  { name: 'Другие товары', id: 6, count: 20, countTwo: 34 },
];

export const SecondDataItems = [
  {
    name: 'Зерна, Овощи, Фрукты',
    id: 1,
    count: 20,
    countTwo: 34,
  },
  {
    name: 'Химикаты, удобрения',
    id: 2,
    count: 10,
    countTwo: 34,
  },
  {
    name: 'обработанный камень, чугун и сталь',
    id: 3,
    count: 10,
    countTwo: 34,
  },
  {
    name: 'Цветные и драгоценные металлы',
    id: 4,
    count: 60,
    countTwo: 34,
  },
  {
    name: 'Фармацевтические товары',
    id: 5,
    count: 30,
    countTwo: 34,
  },
  {
    name: 'Готовые продукты невысокой сложности',
    id: 6,
    count: 20,
    countTwo: 34,
  },
  {
    name: 'Бытовая химия',
    id: 7,
    count: 10,
    countTwo: 32,
  },
  {
    name: 'Одежда и обувь',
    id: 8,
    count: 10,
    countTwo: 32,
  },
];

export const InternationalYears = [
  { id: 2018, text: '2018 год' },
  { id: 2019, text: '2019 год' },
  { id: 2020, text: '2020 год' },
];

export const DIVIDER_LABEL = 'млн';
export const DIVIDER_LABEL_MILLARD = 'млрд';

export const tabsName = {
  NAME_TAB_ECONOMIC_OVERVIEW: ActiveTabLabel.ECONOMIC_OVERVIEW,
  NAME_TAB_COUNTRY_INFORMATION: 'Информация о стране',
  NAME_TAB_TRADE_AND_ECONOMIC_COOPERATION: 'Торгово-экономическое сотрудничество с Россией',
  NAME_TAB_REFERENCE_INFORMATION: 'Справочная информация',
};

export const SELECT_TYPE_TAB = 3;

export const COLOR_PRIORITY_IMPORT = {
  1: {
    color: '#0178C8',
    id: 1,
  },
  2: {
    color: '#D1D5DB',
    id: 2,
  },
};

export const COLOR_DESTINATION_IMPORT = {
  1: {
    color: '#0178C8',
    id: 1,
  },
  2: {
    color: '#0AB1E0',
    id: 2,
  },
  3: {
    color: '#D1D5DB',
    id: 3,
  },
};

export const TABS_ID_KEY_TRADING_INDICATORS = {
  TRADE_TURNOVER: 1,
  EXPORT: 2,
  IMPORT: 3,
  NON_RAW_MATERIALS: 4,
  BALANCE: 5,
};

export const ID_LINE_GRAPH_BALANCE = 5;
export const ID_ALL_MEANINGS_BALANCE = 6;

export const COLORS_GRAPH_CURRENT_BALANCE = {
  1: {
    id: 1,
    name: 'Сальдо торговли товарами',
    color: '#0070C0',
  },
  2: {
    id: 2,
    name: 'Сальдо торговли услугами',
    color: '#0AB1E0',
  },
  3: {
    id: 3,
    name: 'Сальдо баланса первичных доходов',
    color: '#83DDDB',
  },
  4: {
    id: 4,
    name: 'Сальдо баланса вторичных доходов',
    color: '#8FC2E4',
  },
  5: {
    id: 5,
    name: 'Сальдо счета текущих операций',
    color: '#040086',
  },
};

export const COLORS_GRAPH_FINANCIAL_BALANCE = {
  1: {
    id: 1,
    name: 'Сальдо прямых инвестиций',
    color: '#0070C0',
  },
  2: {
    id: 2,
    name: 'Сальдо портфельных инвестиций',
    color: '#0AB1E0',
  },
  3: {
    id: 3,
    name: 'Сальдо производных финансовых инструментов',
    color: '#83DDDB',
  },
  4: {
    id: 4,
    name: 'Сальдо прочих инвестиций',
    color: '#8FC2E4',
  },
  5: {
    id: 5,
    name: 'Сальдо финансового счета',
    color: '#040086',
  },
};

export const COLORS_GRAPH_NO_SEARCH = {
  5: {
    color: '#040086',
  },
};

export const DATA_EMPTY_MESSAGE = 'Нет данных для показателя';
export const GdpOption = {
  indexAxis: 'y',
  locale: 'ru-RU',
  clip: false,
  layout: {
    padding: {
      right: 70,
    },
  },
  plugins: {
    tooltip: { enabled: false },
    legend: {
      display: false,
    },
    datalabels: {
      clamp: true,
      align: 'end',
      offset: 0,
      anchor: 'end',
      color: '#231F20',
      family: 'Fira Sans',
      textAlign: 'end',
      font: {
        fontSize: 12,
      },
      formatter: (value) => {
        return value.toString().includes('.') ? value.toString().replace('.', ',') : value + ',0';
      },
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 0,
        },
      },
    },
  },
};

export const MoodyInvestRatings = [
  'Aaa',
  'Aa1',
  'Aa2',
  'Aa3',
  'A1',
  'A2',
  'A3',
  'Baa1',
  'Baa2',
  'Baa3',
  'P-1',
  'P-2',
  'P-3',
];
export const SnPInvestRatings = [
  'AAA',
  'AA+',
  'AA',
  'AA-',
  'A+',
  'A',
  'A-',
  'BBB+',
  'BBB',
  'BBB-',
  'A-1+',
  'A-1',
  'A-2',
  'A-3',
];

export const DbrsInvestRatings = [
  'AAA',
  'AA+',
  'AA',
  'AA-',
  'A+',
  'A',
  'A-',
  'BBB+',
  'BBB',
  'BBB-',
  'F1+',
  'F1',
  'F2',
  'F3',
];

export const CONNECTIONS_WITH_REGIONS_CHART_LIMIT = 10;

export const REFERENCE_FILE_STATUS = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
};

export const VALID_REFERENCE_FILE_EXTENSIONS = ['xls', 'xlsx', 'docx', 'pdf', 'pptx'];

export const QuartalsObject = {
  1: {
    quartal: 1,
    value: 'янв-мар',
  },
  2: {
    quartal: 2,
    value: 'янв-июн',
  },
  3: {
    quartal: 3,
    value: 'янв-сен',
  },
  4: {
    quartal: 4,
    value: 'янв-дек',
  },
};
