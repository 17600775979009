<template>
  <Multiselect
    v-model="value"
    :value="modelValue"
    mode="single"
    :class="getMultiselectClass"
    :track-by="trackBy"
    inputType="text"
    :placeholder="placeholder"
    :label="labelOptions"
    :hideSelected="false"
    :valueProp="valueProp"
    :close-on-select="true"
    :searchable="true"
    :options="options"
    noResultsText="Ничего не найдено."
    noOptionsText="Список пуст."
    @select="onSelect"
    @clear="onClear"
    :disabled="disabled"
    :canClear="canClear"
    :canDeselect="false"
    :style="style"
    :resolve-on-load="resolveOnLoad"
    :delay="delay"
    :min-chars="minChars"
    ref="select"
    @close="closeDropDown"
  >
    <template v-slot:placeholder>
      <div class="multiselect-placeholder">{{ placeholder }}</div>
    </template>
    <template v-slot:single="{ value }">
      <div class="multiselect-multiple-label">
        {{ value.text }}
      </div>
    </template>
    <template v-slot:option="{ option }">
      <div class="input-group-wrapper form-label">
        {{ option.text }}
      </div>
    </template>
  </Multiselect>
  <div class="invalid-msg" v-if="v$.value.$error">Поле обязательно для заполнения</div>
</template>

<script>
  import Multiselect from '@vueform/multiselect';
  import { useVuelidate } from '@vuelidate/core';
  import { helpers } from '@vuelidate/validators';

  const requiredValidator = (requiredInternal, scope, type) =>
    helpers.withParams({ type: 'requiredValidator' }, (value) => {
      if (!requiredInternal) return true;
      let res = false;

      if (typeof value === 'string') {
        res = value && value.trim().length > 0 && value !== 'null';
      } else if (type === 'number') {
        res = !isNaN(value);
      } else {
        res = value;
      }

      res = !!res;
      return {
        $valid: res,
        extraParams: { scope: scope },
      };
    });

  export default {
    components: { Multiselect },
    name: 'SelectSingleComponentCheckbox',
    emits: ['update:modelValue', 'changeSelect'],
    props: {
      options: {
        type: Array,
        required: true,
        default: () => [],
      },
      modelValue: [String, Number, Object, Boolean],
      placeholder: {
        type: [String, Number],
      },
      // Поле, которое будте использоваться для поиска. Поле объекта опций
      trackBy: {
        type: String,
        required: true,
      },
      // Поле, которое будет добовляться в v-model. Поле объекта опций
      valueProp: {
        type: String,
        default: 'id',
        required: true,
      },
      // Поле, которое будет отображаться в списке селектов. Поле объекта опций
      labelOptions: {
        type: String,
        required: true,
      },
      required: {
        type: Boolean,
        default: false,
      },
      valueType: {
        type: String,
        default: 'number',
        validator: (value) => ['number', 'string'].includes(value),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      canClear: {
        type: Boolean,
        default: true,
      },
      style: {
        type: Object,
        default: () => {},
      },
      mod: {
        type: String,
        validator(value) {
          return ['filter-small', 'base'].includes(value);
        },
      },
      error: {
        type: Boolean,
        default: false,
      },
      // for async options
      resolveOnLoad: {
        type: Boolean,
        default: true,
      },
      minChars: {
        type: Number,
        default: 0,
      },
      // for async options spinner
      delay: {
        type: Number,
        default: -1,
      },
    },
    data() {
      return {
        requiredInternal: this.required,
      };
    },
    setup: () => ({ v$: useVuelidate() }),
    methods: {
      onSelect(value, option) {
        this.$emit('changeSelect', { value: option });
      },
      onClear() {
        this.$emit('changeSelect');
      },

      closeDropDown() {
        if (this.$refs.select) {
          this.$refs.select.blur();
        }
      },
    },
    computed: {
      value: {
        get() {
          if (this.valueType === 'number') {
            return this.modelValue ?? null;
          } else {
            return this.modelValue ?? '';
          }
        },
        set(value) {
          const newValue = this.valueType === 'number' && value === null ? value : String(value);
          this.$emit('update:modelValue', newValue);
        },
      },
      getMultiselectClass() {
        return [
          'input-group-wrapper',
          'multiselect-checkbox',
          'multiselect-checkbox--single',
          { error: this.v$.value.$error || this.error },
          this.mod ? `multiselect-checkbox--${this.mod}` : '',
        ];
      },
    },
    watch: {
      required: function (newValue) {
        this.requiredInternal = newValue;
      },
    },
    validations() {
      return {
        value: {
          requiredValidator: requiredValidator(this.requiredInternal, '', this.valueProp),
        },
      };
    },
  };
</script>
