<template>
  <label class="switch" :class="className">
    <input type="checkbox" :checked="!active" @change="switchHandler()" />
    <span class="slider"></span>
    <span class="one" :class="{ active: active }">{{ textTrue }}</span>
    <span class="two" :class="{ active: !active }">{{ textFalse }}</span>
  </label>
</template>

<script>
  export default {
    name: 'switchComponent',
    props: {
      checked: {
        type: Boolean,
        default: true,
      },
      className: {
        type: String,
        default: '',
      },
      textTrue: {
        type: String,
        default: 'Товары',
      },
      textFalse: {
        type: String,
        default: 'Услуги',
      },
    },
    data() {
      return {
        active: this.checked,
      };
    },
    methods: {
      switchHandler() {
        this.active = !this.active;
        this.$emit('switchHandler', this.active);
      },
    },
  };
</script>

<style scoped lang="scss">
  .one,
  .two {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #96929c;
  }
  .one.active {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    // font-family: Roboto, Arial, sans-serif;
    // background-color: #2aa5a0;
    font-style: normal;
    // font-weight: bold;
    font-size: 14px;
    color: #0093ba;
    // line-height: 14px;
    // background: linear-gradient(270deg, #00b2aa 0%, #0078c8 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
  .two.active {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    // font-family: Roboto, Arial, sans-serif;
    // background-color: #2aa5a0;
    // font-style: normal;
    // font-weight: bold;
    font-size: 14px;
    color: #0093ba;
    // line-height: 14px;
    // background: linear-gradient(270deg, #00b2aa 0%, #0078c8 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }
  .one {
    left: -55px;
  }
  .two {
    right: -55px;
  }
  .switch {
    margin-left: 60px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 48px;
    height: 26px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(270deg, #00b2aa 0%, #0078c8 100%);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border: 1px solid #00b2aa;
    border-radius: 24px;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    top: 1.5px;
    left: 2px;
    bottom: 4px;
    background: #ffffff;
    border-radius: 24px;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: white;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px white;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  @media (max-width: 767px) {
    .switch {
      .one,
      .two {
        font-size: 12px;
      }
    }
  }

  .switch.big {
    margin-left: 170px;
    .one {
      left: -170px;
    }
    .two {
      right: -185px;
    }
  }
</style>
