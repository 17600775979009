import Swal from 'sweetalert2';

const langRu = {
  errorLoading: function () {
    return 'Невозможно загрузить результаты';
  },
  loadingMore: function () {
    return 'Загрузка данных…';
  },
  noResults: function () {
    return 'Совпадений не найдено';
  },
  searching: function () {
    return 'Поиск…';
  },
  removeAllItems: function () {
    return 'Удалить все элементы';
  },
  inputTooShort: function () {
    return 'Введите больше символов для начала поиска';
  },
};

var select2SettingsMixin = {
  language: langRu,
  allowClear: true,
  matcher: function (params, data) {
    if (!params.term) return data;
    if (data.text.toLowerCase().startsWith(params.term.toLowerCase())) return data;
    else return null;
  },
  minimumResultsForSearch: 5,
};

export default {
  langRu,
  emptySelectOptionText: '-- не выбрано --',
  validatorMessageEmpty: 'Введите данные',
  pageSize: 10,
  messengerFileLimit: 5,
  messengerFileMaxSize: 10485760,
  confirmOptions: {
    delete: {
      title: 'Удаление',
      text: 'Вы действительно хотите удалить эту запись?',
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Отмена',
      showCancelButton: true,
      icon: 'warning',
    },
    changeStatus: {
      title: 'Изменение статуса',
      text: 'Изменить статус этой записи?',
      confirmButtonText: 'Изменить',
      cancelButtonText: 'Отмена',
      showCancelButton: true,
      icon: 'warning',
    },
    decline: {
      title: 'Отклонение участника',
      text: 'Вы действительно хотите отклонить этого участника?',
      confirmButtonText: 'Отклонить',
      cancelButtonText: 'Отмена',
      showCancelButton: true,
      icon: 'warning',
    },
  },
  countryRussiaId: 235,
  countryRussiaName: 'РОССИЯ',
  countryKNR: 108,
  countryGroupEAEC: 7,
  mpkAssignemntStatusDoneId: 5,
  eapAssignemntStatusDoneId: 4,
  errorTitle: 'Ошибка',
  validationError: 'Проверьте правильность заполнения полей.',
  commonError: 'Произошла ошибка. Обратитесь в техническую поддержку.',
  commonSuccess: 'Данные успешно сохранены.',
  errorDownloadFile: 'Формат файла некорректный',
  select2Settings: select2SettingsMixin,
  select2SettingsSearchAll: {
    language: langRu,
    allowClear: true,
  },
  select2SettingsMultiple: Object.assign({ multiple: true, closeOnSelect: false }, select2SettingsMixin),
  select2SettingsNoClear: {
    language: langRu,
    allowClear: false,
  },
  select2SettingsNoSearch: {
    language: langRu,
    allowClear: true,
    minimumResultsForSearch: Infinity,
  },
  yesNoList: [
    {
      id: null,
      text: 'Не выбрано',
    },
    {
      id: true,
      text: 'Да',
    },
    {
      id: false,
      text: 'Нет',
    },
  ],
  chatType: {
    private: 1,
    group: 2,
    room: 3,
    techSupport: 4,
  },
  entityStatus: {
    draft: 1,
    published: 2,
    archive: 3,
    onApproval: 4,
  },
  entityType: {
    employee: 8,
    news: 11,
  },
  organizationType: {
    foiv: 1,
    navigatorParticipant: 2,
    external: 3,
    regionalOrInternational: 4,
  },
  tradingOperation: {
    export: 1,
    import: 2,
  },
  userType: {
    internal: 1,
    external: 2,
  },
  tradeItemType: {
    tnved: 1,
    okved: 2,
  },
  proposalRequestType: {
    participantsPlenarySession: 1,
    participantsNarrowCircle: 2,
    suggestionsForAgenda: 3,
    suggestionsForProtocol: 4,
  },
  eventRequestStatus: {
    new: 1,
    answerExpected: 2,
    answerReceived: 3,
    overdue: 4,
  },
  moderationtStatus: {
    new: 1,
    review: 2,
    accepted: 3,
    rejected: 4,
  },
  fileType: {
    photo: 1,
    video: 2,
    other: 3,
  },
  newsType: {
    mpkNews: 1,
    review: 2,
    measureInfo: 3,
  },
  organizationMinekId: 1,

  alert: Swal.mixin({
    //customClass: {
    //  confirmButton: 'btn btn-success',
    //  cancelButton: 'btn btn-danger'
    //},
    //buttonsStyling: false
  }),
  removeFromArr(arr) {
    var what,
      a = arguments,
      L = a.length,
      ax;
    while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  },

  Permissions: {
    AdminAccess: 5,

    RoleCreate: 6,
    RoleView: 7,
    RoleEdit: 8,
    RoleDelete: 9,
    UserNotificationEdit: 140,
    NotificationTextEdit: 141,
    MpkProjectsAccess: 152,
    MpkProjectEdit: 153,
    MpkProjectFoivReview: 155,
    MpkProjectCreateEap: 1531,

    MpkCommissionAccess: 160,
    MpkCommissionEdit: 161,
    AccountCochairAccess: 162,
    MpkBarrierQuestionsEdit: 106,

    MpkPodgotovkaAccess: 17,
    MpkEventsAccess: 170,
    MpkEventsEdit: 171,
    MpkEventsTimelineEdit: 1710,
    MpkTaskAccess: 172,
    MpkTaskEdit: 173,
    MpkLibraryAccess: 174,
    MpkLibraryEdit: 175,
    MpkRequestsAccess: 176,
    MpkRequestsEdit: 177,
    MpkAssignmentAccess: 178,
    MpkAssignmentEdit: 179,
    MpkAssignmentViewAllFoiv: 1790,

    EapTaskAccess: 180,
    EapTaskEdit: 181,
    EapTaskViewAllStatus: 1810,
    EapAssignmentAccess: 182,
    EapAssignmentEdit: 183,

    NewsAccess: 21,
    NewsPublish: 2114,
    NewsSubscriptionUserAccess: 2140,
    NewsSubscriptionUserEdit: 2141,
    NewsSubscriptionSend: 2142,
    MassMediaAccess: 2150,
    MassMediaEdit: 2151,

    NsiAccess: 23,
    NsiEdit: 230,
    OrgEdit: 34,
    CountryEdit: 354,

    ScenarioCreate: 401,
    ScenarioView: 402,
    ScenarioEdit: 403,
    ScenarioDelete: 404,

    MeasureSingleTtrCreate: 471,
    MeasureSingleTtriew: 472,
    MeasureSingleTtrEdit: 473,
    MeasureSingleTtrDelete: 474,

    RequestInitiativeTtrCreateAdmin: 494,

    RestrictionMeasureAccess: 500,
    RestrictionMeasureCreate: 501,
    RestrictionMeasureView: 502,
    RestrictionMeasureEdit: 503,
    RestrictionMeasureDelete: 504,
    RestrictionMeasureApprove: 505,

    AnalyticsImportView: 550,

    LifeIndexImport: 635,
    StriIndexImport: 665,
    BankRateImport: 670,

    MpkEventRequestCreate: 701,
    MpkEventRequestEdit: 703,
    MpkEventRequestChangeStatus: 704,
    MpkEventRequestAnswerProposalCreate: 706,
    MpkEventRequestAnswerProposalEdit: 708,
    MpkEventRequestAnswerProposalChangeStatus: 709,

    FreeTradeAgreement: 800,

    WidgetOnMainForAllCountry: 810,

    CountryAnalyticsMacroEcon: 1901,
    CountryAnalyticsIntegrationIndicators: 1902,
    CountryAnalyticsCountryRating: 1903,
    CountryAnalyticsLifeIndex: 1904,
    CountryAnalyticsBudgetPolicy: 1905,
    CountryAnalyticsMonetaryPolicy: 1906,
    CountryAnalyticsFinancialSector: 1907,
    CountryAnalyticsForeignTrade: 1908,
    CountryAnalyticsInvestments: 1909,
    CountryAnalyticsGreenEconomics: 1910,
    CountryAnalyticsHealth: 1911,
    CountryAnalyticsAdditionalInfo: 1912,
    CountryAnalyticsRussiaForeignTrade: 1913,
    CountryAnalyticsRussiaAccess: 1914,
    CountryAnalyticsRussiaInvestments: 1915,
    CountryAnalyticsRussiaJointProjects: 1916,
    CountryAnalyticsRussiaInsitutionalForms: 1917,
    CountryAnalyticsEconomicsReview: 1918,
    CountryAnalyticsEconomicsRussia: 1919,
    CountryAnalyticsReferenceInfo: 3000,
    CountryAnalyticsReferenceInfoListFiles: 3100,
    CountryAnalyticsReferenceInfoHiddenListFiles: 3101,
    CountryAnalyticsReferenceInfoChangingName: 3102,
    CountryAnalyticsReferenceInfoMovingToArchive: 3103,
    CountryAnalyticsReferenceInfoAdditionFile: 3104,
    CountryAnalyticsReferenceInfoChangingVisibility: 3105,
    CountryAnalyticsReferenceInfoArchive: 3200,
    CountryAnalyticsReferenceInfoReadArchive: 3201,
    CountryAnalyticsReferenceInfoRecoveryArchive: 3202,
    CountryAnalyticsReferenceInfoRemovingArchive: 3203,
    CountryAnalyticsReferenceInfoHistory: 3300,
    CountryAnalyticsReferenceInfoReadHistory: 3301,

    AccessCrossCuttingTask: 102,
    AccessToCreatingCrossCuttingTask: 1021,
    AccessToEditingSubtaskCrossCuttingTask: 1022,
    AccessToCreatingRequestCrossCuttingTask: 1023,

    AccessEditableResponsibleEmployee: 1400,
  },

  userRoleNames: {
    // роль сотрудника загранаппарата
    foreignEmployeeRole: 'EmployeeZa',
    // роль Администратор модуля ВЭС
    moduleAdministratorVES: 'Администратор модуля ВЭС',
    // роль субьект РФ
    subjectRF: 'Субъект РФ',
  },

  userRoleIds: {
    // id роли "субъект РФ"
    subjectRF: 1410,
  },

  monthList: [
    { id: 1, text: 'Январь' },
    { id: 2, text: 'Февраль' },
    { id: 3, text: 'Март' },
    { id: 4, text: 'Апрель' },
    { id: 5, text: 'Май' },
    { id: 6, text: 'Июнь' },
    { id: 7, text: 'Июль' },
    { id: 8, text: 'Август' },
    { id: 9, text: 'Сентябрь' },
    { id: 10, text: 'Октябрь' },
    { id: 11, text: 'Ноябрь' },
    { id: 12, text: 'Декабрь' },
  ],
  monthListSimple: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  monthListGenitive: [
    'январе',
    'феврале',
    'марте',
    'апреле',
    'мае',
    'июне',
    'июле',
    'августе',
    'сентябре',
    'октябре',
    'ноябре',
    'декабре',
  ],
  finSectorNumbering: {
    0: '',
    1: 'тыс.',
    2: 'млн.',
    3: 'млрд.',
    4: 'трлн.',
    5: 'квдлн.',
  },
  CrosscuttingSubtaskStatus: {
    NOT_COMPLETED: 1,
    WORK: 2,
    COMPLETED: 3,
  },
  DATE_FORMATS: {
    YYYY: 'YYYY', // 2023
    DD_MMMM: 'DD MMMM', // 23 Января
    'MM.YYYY': 'MM.YYYY', // 10.2023
    'MMM, YYYY': 'MMM, YYYY', // Янв, 2023
    MMMM_YYYY: 'MMMM YYYY', // Января (или Январь) 2023
    'DD.MM.YYYY': 'DD.MM.YYYY', // 23.10.2023
    'YYYY-MM-DD': 'YYYY-MM-DD', // 2023-10-23
    DD_MMMM_YYYY: 'DD MMMM YYYY', // 23 Января 2023
    YYYY_MM_DD_SLASH: 'YYYY/MM/DD', // 2023/12/23
    'DD.MM.YYYY HH:mm': 'DD.MM.YYYY HH:mm', // 23.12.2023 01:10
    'DD.MM.yyyy HH:mm:ss': 'DD.MM.yyyy HH:mm:ss', // 23.12.2023 01:10:21
    'HH:mm': 'HH:mm', // 23:12
  },

  MILLION: 1000000,
  THOUSAND: 1000,

  MIN_INT_32: -2147483648,

  MODAL_INFO_ICON: {
    ACCEPT: 'acceptIcon',
    ERROR: 'errorIcon',
  },

  statusOptions: [
    {
      id: 1,
      text: 'Действующий',
    },
    {
      id: 2,
      text: 'Не действующий',
    },
  ],

  statusOptionsIDs: {
    active: '1',
    notActive: '2',
  },

  paramsFullListDefault: {
    pageNumber: 1,
    pageSize: 0,
    isActive: true,
  },

  governmentTypes: [
    { id: 1, text: 'СССР' },
    { id: 2, text: 'РФ' },
  ],

  sort: {
    ascending: 'ascending',
    descending: 'descending',
    default: 'default',
  },

  sortOrder: {
    ascending: 0,
    descending: 1,
  },

  optionsDescendingAscending: [
    {
      id: 1,
      text: 'По убыванию',
    },
    {
      id: 2,
      text: 'По возрастанию',
    },
  ],

  dateLowerLimit: new Date(1900, 0, 1),
  dateUpperLimit: new Date(2099, 0, 1),

  indicator: {
    placeInMarket: {
      EXPORT: 'placeInRussianExports',
      IMPORT: 'placeInRussianImports',
      TRADE_TURNOVER: 'placeInRussianTradeTurnover',
      EXPORT_NON_ENERGY_VALUE: 'placeInRussianExportNonEnergy',
      BALANCE: 'placeInRussianBalance',
    },
    previousPercentValue: {
      TOTAL_VALUE: 'totalValuePreviousPercent',
      IMPORT_VALUE: 'importValuePreviousPercent',
      EXPORT_VALUE: 'exportValuePreviousPercent',
      EXPORT_NON_ENERGY_VALUE: 'exportNonEnergyValuePreviousPercent',
      BALANCE: 'balancePreviousPercent',
    },
    previousValue: {
      BALANCE: 'balancePreviousValue',
    },
    value: {
      TOTAL_VALUE: 'totalValue',
      IMPORT_VALUE: 'importValue',
      EXPORT_VALUE: 'exportValue',
      EXPORT_NON_ENERGY_VALUE: 'exportNonEnergyValue',
      BALANCE: 'balance',
    },
  },
};
