export const routes = [
  {
    path: '/monitoring-cros-cutting-tasks',
    name: 'monitoring-cros-cutting-tasks',
    component: () => import('@/modules/crossCuttingTask/pages/PageMonitoringCrossCuttingTasks'),
  },
  {
    path: '/cross-cutting-task/:id',
    name: 'cross-cutting-task',
    component: () => import('@/modules/crossCuttingTask/pages/PageCrossCuttingTask'),
  },
  {
    path: '/cross-cutting-task/:id/:task',
    name: 'cross-cutting-task-card',
    component: () => import('@/modules/crossCuttingTask/pages/PageTaskCard'),
  },
];
