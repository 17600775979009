<template>
  <div class="reference-info-table">
    <div class="table-sort table-sort_dense table-sort_non-hover">
      <div class="table-sort__head">
        <ReferenceInfoTableHeader :country-id="countryId" @filter="(filters) => $emit('filter', filters)" />
      </div>
      <div v-if="files.length" class="table-sort__body">
        <template v-for="(file, index) in files" :key="file.id">
          <div
            v-if="getIsShowingFile(file)"
            class="table-sort__row hover"
            :class="[{ 'table-sort__row_hided': getIsHiddenStatus(file) }]"
          >
            <div class="table-sort__td column-icon">
              <div class="wrap-icon">
                <slot name="fileEvents" :file="file" :index="index" :hidden="getIsHiddenStatus(file)"></slot>
                <IconComponent
                  v-if="isDeleteEventShowing"
                  class="icon--full-svg delete"
                  @click="deleteFile(file, index)"
                  name="delete-not-fill"
                />
              </div>
            </div>
            <div class="table-sort__td column-number_small">
              <p>{{ file.id }}</p>
            </div>
            <div class="table-sort__td column-short-name">
              <p>{{ file.fileName }}</p>
            </div>
            <div class="table-sort__td column-addition-date">
              <p>{{ getPreparedDate(file.createDate) }}</p>
            </div>
            <div class="table-sort__td column-document" @click="downloadFile(file)">
              <p>{{ file.originalFileName }}</p>
            </div>
          </div>
          <div v-if="files.length < 4" class="table-sort__row stub"></div>
          <div v-if="files.length < 3" class="table-sort__row stub"></div>
          <div v-if="files.length < 2" class="table-sort__row stub"></div>
        </template>
      </div>
      <div v-else-if="!loading" class="table-sort__body">
        <div class="table-sort__row stub">
          <p class="table-sort__td stub">По вашему запросу файлы не найдены</p>
        </div>
        <div class="table-sort__row stub"></div>
        <div class="table-sort__row stub"></div>
        <div class="table-sort__row stub"></div>
      </div>
      <div v-if="loading" class="reference-info-table__loader">
        <Loader />
      </div>
    </div>

    <div v-if="getIsPaginationShowing" class="reference-info-table__navigation">
      <ShowMoreButton classButton="text-start" is-forever-visible @onClick="$emit('showMore')"
        >Показать еще +</ShowMoreButton
      >
    </div>
  </div>
</template>

<script>
  import ShowMoreButton from '@/common/components/redesigned/ShowMoreButton.vue';
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import Loader from '@/components/Loader.vue';

  import { REFERENCE_FILE_STATUS } from '../../../utils/constants';
  import ReferenceInfoTableHeader from '../referenceInfoTable/ReferenceInfoTableHeader.vue';

  export default {
    name: 'ReferenceInfoTable',
    components: { IconComponent, ShowMoreButton, ReferenceInfoTableHeader, Loader },
    emits: ['filter', 'update:files', 'showMore', 'delete'],
    props: {
      files: {
        type: Array,
        default: () => [],
      },
      loading: {
        type: Boolean,
        default: false,
      },
      isHiddenFilesShowing: {
        type: Boolean,
        default: false,
      },
      isDeleteEventShowing: {
        type: Boolean,
        default: false,
      },
      countryId: {
        type: Number,
        default: 0,
      },
      pagination: {
        type: Object,
        default: () => {
          return {
            limit: 0,
            offset: 0,
            total: 0,
          };
        },
      },
    },
    computed: {
      getIsPaginationShowing() {
        return this.pagination.offset < this.pagination.total;
      },
    },
    methods: {
      async downloadFile(file) {
        try {
          await Utils.downloadFile(`/api/file/download?id=${file.fileId}`, file.originalFileName, null, true);
        } catch (err) {
          console.error(err);
        }
      },
      getIsHiddenStatus(file) {
        return file.status === REFERENCE_FILE_STATUS.HIDDEN;
      },
      getIsShowingFile(file) {
        return !this.getIsHiddenStatus(file) || this.isHiddenFilesShowing;
      },
      getPreparedDate(date) {
        const dateObject = new Date(date);
        return `Загружен в ${Constants.monthListGenitive[dateObject.getMonth()]} ${dateObject.getFullYear()}`;
      },
      async deleteFile(file, fileIndex) {
        const options = {
          title: 'Удаление',
          text: `Вы действительно хотите удалить файл "${file.fileName}"?`,
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отмена',
          showCancelButton: true,
          icon: 'warning',
        };

        const answer = await Constants.alert.fire(options);

        if (answer.isConfirmed) {
          this.$emit('delete', file, fileIndex);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/assets/theme/default/table/table-sort.scss';

  .reference-info-table {
    &__navigation {
      display: flex;
      justify-content: center;
    }

    &__loader {
      display: flex;
      justify-content: center;
    }

    .table-sort {
      max-height: 910px;
      scrollbar-width: thin;

      &__row {
        &_hided {
          color: rgba(0, 0, 0, 0.3);

          &.hover {
            &:hover {
              color: rgba(0, 0, 0, 0.3);
            }
          }

          :deep(.icon:not(:first-child)) {
            opacity: 0.3;
          }
        }
      }

      .column-document {
        cursor: pointer;
        color: $blue;
      }

      :deep(.column-document) {
        min-width: 350px;
        max-width: 350px;
      }

      :deep(.column-short-name) {
        min-width: 366px;
        max-width: 366px;
      }

      :deep(.column-addition-date, .column-document) {
        min-width: 410px;
        max-width: 410px;
      }

      :deep(.column-number_small) {
        min-width: 19px;
        max-width: 19px;
      }
    }
  }
</style>
