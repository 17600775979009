<template>
  <Tree @nodeClick="onNodeClick" :use-checkbox="true" class="treeSelectSearch">
    <template #iconActive>
      <div class="icon">+</div>
    </template>
    <template #iconInactive>
      <div class="icon">-</div>
    </template>
    <template #childCount="{ count, checkedCount }">
      <div class="custom-child-count">
        {{ `${checkedCount}/${count}` }}
      </div>
    </template>
    <template #checkbox="{ node }">
      <!-- не нашла что бы где то использовался ref -->
      <!-- но на всякий оставлю -->
      <custom-checkbox
        class="customCheckbox"
        :id="node.id"
        :class="{ active: nodeActiveId == node.id }"
        @click="customCheckboxActions"
        ref="customCheckbox"
      />
    </template>
  </Tree>
</template>

<script>
  import Tree from 'vue3-tree';

  export default {
    name: 'TreeSelectSearch',
    components: {
      Tree,
    },
    props: {
      catchFiredItem: Function,
      nodeActiveId: {
        type: [Number, String],
        default: null,
      },
    },
    methods: {
      onNodeClick(node) {
        this.firedItem = node.label;
        this.catchFiredItem({
          firedItem: this.firedItem,
        });
      },
      customCheckboxActions(e) {
        this.isActiveItemId = e.target.id;
        this.$emit('getId', this.isActiveItemId);
        console.log(this.isActiveItemId);
      },
    },
    data() {
      return {
        firedItem: null,
        isActiveItemId: '',
      };
    },
  };
</script>

<style lang="scss">
  .treeSelectSearch {
    border-left: 2px solid #01a39d;
    overflow: hidden;
    .tree-row {
      position: relative;
      margin-bottom: 12px;
      &::before {
        position: absolute;
        content: ' ';
        top: 11px;
        left: -100%;
        width: 100vw;
        height: 2px;
        background-color: #01a39d;
      }
      &-txt {
        padding: 2px 0 2px 8px;
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.15px;
        color: #343c44;
        transition: 0.3s;
      }
      &-item {
        display: flex;
        align-items: center;
        width: 100%;
        background-color: white;
        position: relative;
        margin-bottom: 12px;
        cursor: pointer;
        .customCheckbox {
          position: absolute;
          width: 100%;
          height: 100%;
          right: 0px;
          z-index: 0;
          &.active {
            background-color: rgba(#01a39d, 0.2);
            color: #ffffff;
            border-radius: 5px;
          }
        }
        &-icon-wrapper {
          cursor: pointer;
          //padding-left: 8px;
          z-index: 10;
          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            border: 1.5px solid black;
            border-radius: 6px;
          }
        }
      }
    }
  }
</style>
