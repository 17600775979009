<template>
  <div>
    <div v-if="country && country.usdExchangeRate">
      <div class="d-flex justify-content-between">
        <span class="exchangeRate-text">Валютный курс</span>
        <div class="analytics-source-text">Источник: Международный валютный фонд</div>
      </div>

      <div class="exchangeRate d-flex align-items-center justify-content-between">
        <span class="exchangeRate-text">1 USD</span>
        <IconComponent class="exchangeRate-text" name="2arrow" />
        <span class="exchangeRate-text">{{ country.usdExchangeRate + ' ' + (country.currency ?? '') }}</span>
        <span v-if="country.usdExchangeRateDate" class="analytics-source-text" style="margin-bottom: 13px">
          на {{ $momentFormat(country.usdExchangeRateDate, 'DD.MM.YYYY') }}
        </span>
      </div>
    </div>
    <div class="figures-key">Ключевые показатели</div>
    <ul class="figures-key__list overflow-auto">
      <li
        @click="currentInflationMoreInfo ? selectIndicators(1, 'Инфляция, %') : null"
        :class="[{ active: activeTab === 1 }, { 'more-info-hidden': !currentInflationMoreInfo }]"
        class="figures-key__item"
      >
        <span class="figures-key__title">Инфляция</span>
        <!-- <span v-if="currentInflation" class="figures-key__title">{{ currentInflation.year }} г.</span> -->
        <span class="figures-key__title">
          Источник:
          <br />
          Trading Economics
          <br />
          По данным за <span v-if="currentInflation">{{ $momentFormat(currentInflation.period, 'MM.YYYY') }}</span
          ><span v-else>&mdash;</span>
        </span>
        <div class="figures-key__card d-flex justify-content-between align-items-center">
          <template v-if="currentInflation && currentInflation.previous">
            <i
              v-if="currentInflation.previous >= currentInflation.rawValue"
              class="fas fa-solid mr-1 text-red fa-arrow-down"
            ></i>
            <i
              v-if="currentInflation.previous < currentInflation.rawValue"
              class="fas fa-solid mr-1 text-green fa-arrow-up"
            ></i>
          </template>
          <template v-else>
            <i class="fa"></i>
          </template>
          <span v-if="currentInflation">{{ currentInflation.value }}</span>
          <span v-else>&mdash;</span>
          <p>%</p>
        </div>
        <button class="btn-default" v-if="currentInflationMoreInfo">
          <IconComponent name="chartIcon" />Больше информации
        </button>
      </li>
      <li
        @click="rateMoreInfo ? selectIndicators(2, 'Учетная ставка ЦБ, %') : null"
        class="figures-key__item"
        :class="[{ active: activeTab === 2 }, { 'more-info-hidden': !rateMoreInfo }]"
      >
        <span class="figures-key__title figures-key__title-key">Учетная ставка ЦБ</span>
        <span class="figures-key__title">
          Источник:
          <br />
          Международный валютный фонд
          <br />
          По данным на <span v-if="currentBankRate">{{ currentBankRate.year }}</span
          ><span v-else>&mdash;</span> год
        </span>
        <div class="figures-key__card d-flex justify-content-between align-items-center">
          <template v-if="currentBankRate && currentBankRate.previous">
            <i
              v-if="currentBankRate.previous < currentBankRate.rawValue"
              class="fas fa-solid mr-1 text-red fa-arrow-down"
            ></i>
            <i
              v-if="currentBankRate.previous > currentBankRate.rawValue"
              class="fas fa-solid mr-1 text-green fa-arrow-up"
            ></i>
          </template>
          <template v-else>
            <i class="fa"></i>
          </template>
          <span v-if="currentBankRate">{{ currentBankRate.value }}</span>
          <span v-else>&mdash;</span>
          <p>%</p>
        </div>
        <button class="btn-default" v-if="rateMoreInfo"><IconComponent name="chartIcon" />Больше информации</button>
      </li>
    </ul>
    <div class="graph">
      <div class="graph__title">{{ titleTab }}</div>
      <template v-if="activeTab === 1">
        <template v-if="inflation?.length > 0">
          <ColumnChart
            :key="chartUpdater"
            format-label=" %"
            :chart-data="inflation.map((x) => x.value.toFixed(2))"
            :labels="inflation.map((x) => $momentFormat(x.period, 'MM.YYYY'))"
            :colors="inflation.map((x) => x.color)"
          />
        </template>
        <template v-else>
          <div class="text-center">Нет данных.</div>
        </template>
      </template>

      <template v-if="activeTab === 2">
        <template v-if="bankRates?.length > 0">
          <ColumnChart
            :key="chartUpdater"
            :colors="['#01A39D']"
            format-label=" %"
            :chart-data="bankRates.map((x) => x.value)"
            :labels="bankRates.map((x) => x.year)"
            :layoutPaddingTop="1 * 25"
          />
        </template>
        <template v-else>
          <div class="text-center">Нет данных.</div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent';

  import ColumnChart from '@/components/columnChart';

  //import constants from '../../../common/constants';
  import IndicatorApi from '@/modules/admin/api/indicatorVisibility';
  import CountryApi from '@/modules/nsi/api/country';

  import Api from '../api';

  export default {
    name: 'monetaryPolicy',
    components: { ColumnChart, IconComponent },
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        activeTab: null,
        titleTab: '',
        inflation: [],
        chartUpdater: 1,
        country: null,
        currentInflationMoreInfo: true,
        rateMoreInfo: true,
        bankRates: [],
        currentBankRate: null,
      };
    },
    created() {
      if (this.countryId) {
        this.loadIndicatorVisibility();
        this.loadData();
      }
    },
    methods: {
      loadIndicatorVisibility() {
        IndicatorApi.search().then((response) => {
          this.currentInflationMoreInfo = response.data[8].moreInfoVisible;
          this.rateMoreInfo = response.data[9].moreInfoVisible;
        });
      },
      loadData() {
        const request = {
          countryId: this.countryId,
        };
        Api.searchInflation(request).then((response) => {
          this.inflation = response.data.items.map((x) => ({
            period: x.period,
            value: x.value,
            color: '#96d2e7',
          }));
          if (this.inflation.length) this.inflation[0].color = '#0093BA';
          this.chartUpdater++;
        });
        Api.searchBankRates(this.countryId).then((response) => {
          this.bankRates = response.data.map((x) => ({
            year: x.text,
            value: x.id,
          }));
          this.chartUpdater++;

          this.currentBankRate = null;
          if (this.bankRates.length) {
            let v = this.bankRates[this.bankRates.length - 1];
            this.currentBankRate = {
              year: v.year,
              rawValue: v.value,
              value: v.value.toFixed(2).replace('.', ','),
            };

            //ищем предыдущий год
            if (this.bankRates.length > 1) {
              let prev = this.bankRates.slice(-2);
              if (prev != null) {
                this.currentBankRate.previous = prev.value;
              }
            }
          }
        });
        CountryApi.find(this.countryId)
          .then((response) => {
            this.country = response.data;
          })
          .catch(() => {
            this.country = null;
          });
      },
      selectIndicators(id, text) {
        this.activeTab = id;
        this.titleTab = text;
      },
    },
    watch: {
      countryId: function () {
        this.loadData();
      },
    },
    computed: {
      currentInflation() {
        if (!this.inflation.length) return 0;

        let v = this.inflation[this.inflation.length - 1];
        let result = {
          period: v.period,
          rawValue: v.value,
          value: v.value.toFixed(2).replace('.', ','),
          previous: null,
        };

        //ищем предыдущий
        if (this.inflation.length >= 2) {
          let prev = this.inflation[this.inflation.length - 2];
          if (prev != null) {
            result.previous = prev.value;
          }
        }
        return result;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../index';
  .select2::v-deep {
    outline: none;
    // Контейнер ширина
    .select2-container {
      outline: none;
      width: 100% !important;
      max-width: 100% !important;
      border-radius: 40px;
      // Текст
      .select2-selection__rendered {
        outline: none;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: $color8;
      }
    }
    // Контейнер высота
    .select2-container--default .select2-selection--single {
      outline: none;
      height: auto !important;
      border: 1px solid transparent !important;
      background-color: transparent !important;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
      outline: none;
      top: 50% !important;
      width: 10px;
      right: 14px;
      transform: translateY(-50%) !important;
    }
    // Плейсхолдер
    .select2-container--default .select2-selection--single .select2-selection__placeholder {
      outline: none;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: #989898;
    }
    /* Модификация стрелки */
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      outline: none;
      border-color: unset;
      border-style: unset;
      border-width: unset;
      width: 10px;
      height: 10px;
      border-radius: 1px;
      border-left: 3px solid $color8 !important;
      border-bottom: 3px solid $color8 !important;
      transform: rotate(315deg);
      transition: 0.3s;
      mask-image: unset;
      margin-left: -6px;
      margin-top: -11.5px !important;
    }
    .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
      margin-top: -7.5px !important;
      transform: rotate(135deg);
    }
  }

  .figures-key__title-key {
    white-space: nowrap;
    max-width: 100px;
  }
  .more-info-hidden {
    padding-bottom: 26px;
    pointer-events: none;
  }
</style>
