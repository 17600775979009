<template>
  <div class="quantityBarrierQuarterCard">
    <h5 class="quantityBarrierQuarterCard__title">{{ title }}</h5>
    <ul class="quantityBarrierQuarterCard__list">
      <li class="quantityBarrierQuarterCard__item" v-for="item in barriers" :key="item.year">
        <p class="quantityBarrierQuarterCard__summa">{{ numberFormatter(item.damageSum) }} млрд $</p>
        <p class="quantityBarrierQuarterCard__quantity">
          {{ item.barrierQuantity }}
          {{ wordDeclension(item.barrierQuantity, ['барьер', 'барьера', 'барьеров']) }}
        </p>
        <p class="quantityBarrierQuarterCard__interval">{{ item.year }} год</p>
      </li>
    </ul>
  </div>
</template>

<script>
  import moment from 'moment';

  import numberFormatter from '@/common/mixins/numberFormatter';
  import wordDeclension from '@/common/mixins/wordDeclension';

  const defaultBarriers = [
    {
      year: moment().year(),
      barrierQuantity: 0,
      damageSum: 0,
    },
  ];

  export default {
    mixins: [numberFormatter, wordDeclension],
    props: {
      title: String,
      barrierData: {
        type: Array,
        default: () => [],
        required: true,
      },
    },
    data() {
      return {
        barriers: defaultBarriers,
      };
    },
    watch: {
      barrierData: {
        handler() {
          this.updateData();
        },
        deep: true,
      },
    },
    mounted() {
      this.updateData();
    },
    methods: {
      updateData() {
        this.barriers = [];
        if (this.barrierData.length > 0) {
          this.barrierData.forEach((item) => {
            const existingYear = this.barriers.find((barrier) => barrier.year === item.year);

            if (existingYear) {
              existingYear.barrierQuantity += item.barrierQuantity;
              existingYear.damageSum += item.damageSum;
            } else {
              this.barriers.push({
                year: item.year,
                barrierQuantity: item.barrierQuantity,
                damageSum: item.damageSum,
              });
            }
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '~@/assets/theme/default/global/mixin.scss';

  .quantityBarrierQuarterCard {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    @include borderAndBgCard();

    @media (max-width: $tablet) {
      max-width: 848px;
      width: 100%;
    }

    &__title {
      font: $fira-20-M;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &__item {
      display: grid;
      grid-template-columns: 120px 112px 72px;
      gap: 16px;
    }

    &__summa,
    &__quantity,
    &__interval {
      font: $fira-16;
      text-wrap: nowrap;
    }
  }
</style>
