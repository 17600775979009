export const routes = [
  {
    path: '/measure/singlettr',
    name: 'SingleTtrRegistry',
    component: () => import('../views/SingleTtrRegistry'),
  },
  {
    path: '/measure/singlettr-odnostoron',
    name: 'SingleTtrRegistryOdnostoron',
    component: () => import('../views/SingleTtrRegistryOdnostoron'),
  },
  {
    path: '/measure/singlettr/edit/:id',
    name: 'SingleTtrCard',
    component: () => import('../views/SingleTtrCard'),
  },
  {
    path: '/measure/singlettr-odnostoron/edit/:id',
    name: 'SingleTtrOdnostoronCard',
    component: () => import('../views/SingleTtrOdnostoronCard'),
  },
  {
    path: '/measure/unilateralntr',
    name: 'UnilateralNonTrRegistry',
    component: () => import('../views/UnilateralNonTrRegistry'),
    props: {
      isSecond: false,
      pageTitle: 'Перечень единых мер нетарифного регулирования, применяемых в одностороннем порядке',
    },
  },
  {
    path: '/measure/unilateralntr-odnostoron',
    name: 'UnilateralNonTrRegistryOdnostoron',
    component: () => import('../views/UnilateralNonTrRegistry'),
    props: {
      isSecond: true,
      pageTitle: 'Перечень односторонних мер нетарифного регулирования',
    },
  },
  {
    path: '/measure/unilateralntr/edit/:id',
    name: 'UnilateralNonTrCard',
    component: () => import('../views/UnilateralNonTrCard'),
    props: {
      isSecond: false,
    },
  },
  {
    path: '/measure/unilateralntr-odnostoron/edit/:id',
    name: 'UnilateralNonTrCardOdnostoron',
    component: () => import('../views/UnilateralNonTrCardOdnostoron'),
    props: {
      isSecond: true,
    },
  },
  {
    path: '/measure/eecinvestigations',
    name: 'EecInvestigationRegistry',
    component: () => import('../views/EecInvestigationRegistry'),
  },
  {
    path: '/measure/eecinvestigations/edit/:id',
    name: 'EecInvestigationCard',
    component: () => import('../views/EecInvestigationCard'),
  },
  {
    path: '/measure/reqsinitiativesttr',
    name: 'RequestInitiativeTtrRegistry',
    component: () => import('../views/RequestInitiativeTtrRegistry'),
  },
  {
    path: '/measure/reqsinitiativesttr/edit/:id',
    name: 'RequestInitiativeTtrCard',
    component: () => import('../views/RequestInitiativeTtrCard'),
  },
  {
    path: '/measure/reqsinitiativesttr/add',
    name: 'RequestInitiativeTtrCardAdd',
    component: () => import('../views/RequestInitiativeTtrCardAdd'),
  },
  {
    path: '/measure/marketdefence',
    name: 'MarketDefenceRegistry',
    component: () => import('../views/MarketDefenceRegistry'),
  },
  {
    path: '/measure/marketdefence/edit/:id',
    name: 'MarketDefenceCard',
    component: () => import('../views/MarketDefenceCard'),
  },
];
