<template>
  <div class="indicator-container" v-if="indicators.length">
    <div class="indicator-title" id="market-access-indicators">Ключевые показатели</div>
    <ul class="indicator-list">
      <li class="indicator-list-item" v-for="indicator in indicators" :key="indicator.id">
        <div class="indicator-list-item-title">{{ indicator.title }}</div>
        <div class="indicator-list-item-data">{{ indicator.source }}</div>
        <div class="indicator-list-item-data">{{ indicator.period }}</div>
        <div class="indicator-list-item-value">{{ indicator.value }}</div>
        <div class="indicator-list-item-details" @click="onDetailsClick(indicator.id)">
          {{ activeIndicatorId == indicator.id ? 'Скрыть информацию' : 'Больше информации' }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import Api from '@/modules/countryAnalyticsNew/api/marketAccess';

  export default {
    name: 'MarketAccessIndicators',
    components: {},
    emits: ['showMoreInfo'],
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        indicators: [],
        activeIndicatorId: 1,
        currentYear: new Date().getFullYear(),
      };
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.indicators = [];
        if (this.countryId)
          Api.getIndicatorValues(this.countryId).then((response) => {
            this.indicators = [
              {
                id: 1,
                title: 'Количество ограничений',
                source: 'Источник: Trading Economics',
                period: 'По данным на 12.' + (this.currentYear - 1),
                value: response.data.restrictMeasures,
              },
              {
                id: 2,
                title: 'Количество соглашений страны с РФ',
                source: 'Источник: МВФ',
                period: `По данным на ${this.currentYear} год`,
                value: response.data.agreementsWithRussia,
              },
              {
                id: 3,
                title: 'Количество соглашений страны с миром',
                source: 'Источник: МВФ',
                period: `По данным на ${this.currentYear} год`,
                value: response.data.agreementsGlobal,
              },
            ];
          });
      },
      onDetailsClick(id) {
        if (this.activeIndicatorId == id) this.activeIndicatorId = null;
        else this.activeIndicatorId = id;

        this.$emit('showMoreInfo', this.activeIndicatorId);
      },
    },
    watch: {
      countryId: function () {
        this.loadData();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .indicator {
    &-container {
      margin-top: 60px;
    }
    &-title {
      font-weight: 500;
      font-size: 22px;
      line-height: 24px;
      color: #282b30;
    }
    &-list {
      margin-top: 24px;
      display: flex;

      &-item {
        max-width: 345px;
        width: 100%;
        margin-right: 24px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #333333;

        &-title {
          font-weight: 600;
        }
        &-data {
          color: #aaaaaa;
        }
        &-value {
          font-weight: 700;
          font-size: 40px;
          line-height: 47px;
          margin: 10px 0;
        }
        &-details {
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
      &-item:last-child {
        margin-right: 0;
      }
    }
  }
</style>
