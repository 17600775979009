import { EVENTS_VES_ACTIONS_NAME, EVENTS_VES_MUTATIONS_NAME } from './name.js';

import links from '@/utils/links';
import request from '@/utils/request';

export async function searchEvents(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.events.search,
      name: EVENTS_VES_ACTIONS_NAME.searchEvents,
    },
    params,
  );
}

export async function getEventCard(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.ves.events.getEventCard,
      mutate: EVENTS_VES_MUTATIONS_NAME.setDataCardEvents,
      name: EVENTS_VES_ACTIONS_NAME.getEventCard,
    },
    params,
  );
}
