import axios from 'axios';

export default {
  getSummary: function (year, countryId) {
    return axios.get('api/economicsReview/summary', { params: { year: year, countryId: countryId } });
  },
  getSummaryRussia: function (year, countryId) {
    return axios.get('api/economicsReview/summaryRussia', { params: { year: year, countryId: countryId } });
  },
};
