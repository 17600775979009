const noValueColor = '#e3e3e3';
export default {
  projectColors: [
    {
      value: '0',
      minValue: 0,
      maxValue: 0,
      color: {
        0: noValueColor,
        1: noValueColor,
        2: noValueColor,
        3: noValueColor,
        4: noValueColor,
        5: noValueColor,
        6: noValueColor,
      },
    },
    {
      value: '1',
      minValue: 1,
      maxValue: 1,
      color: {
        0: '#00DCFF',
        1: '#00FF8C',
        2: '#97BEFF',
        3: '#FFEB8C',
        4: '#DCDCDC',
        5: '#FFA5AA',
        6: '#FFDC8C',
      },
    },
    {
      value: '2',
      minValue: 2,
      maxValue: 2,
      color: {
        0: '#00C9ED',
        1: '#00F47E',
        2: '#8DB2F7',
        3: '#FEE47E',
        4: '#D6D6D6',
        5: '#FA989E',
        6: '#FAD480',
      },
    },
    {
      value: '3',
      minValue: 3,
      maxValue: 3,
      color: {
        0: '#00B6DB',
        1: '#00E96F',
        2: '#83A5EF',
        3: '#FDDE70',
        4: '#CFCFCF',
        5: '#F48A92',
        6: '#F4CB74',
      },
    },
    {
      value: '4',
      minValue: 4,
      maxValue: 4,
      color: {
        0: '#00A4C9',
        1: '#00DF61',
        2: '#7999E7',
        3: '#FCD763',
        4: '#C9C9C9',
        5: '#EF7D86',
        6: '#EFC368',
      },
    },
    {
      value: '5',
      minValue: 5,
      maxValue: 5,
      color: {
        0: '#0091B6',
        1: '#00D452',
        2: '#6F8DDF',
        3: '#FBD055',
        4: '#C3C3C3',
        5: '#EA7079',
        6: '#E9BA5B',
      },
    },
    {
      value: '6-10',
      minValue: 6,
      maxValue: 10,
      color: {
        0: '#007EA4',
        1: '#00C944',
        2: '#6580D7',
        3: '#FACA47',
        4: '#BCBCBC',
        5: '#E4626D',
        6: '#E4B24F',
      },
    },
    {
      value: '>10',
      minValue: 10,
      maxValue: null,
      color: {
        0: '#006B92',
        1: '#00BE35',
        2: '#5B74CF',
        3: '#F9C339',
        4: '#B6B6B6',
        5: '#DF5561',
        6: '#DEA943',
      },
    },
  ],
  analyticsColors: [
    { value: 'до 1 000 млн. $', minValue: 1, maxValue: 1000000000, color: '#90E6FF' },
    { value: 'от 1 000 до 10 000 млн. $', minValue: 1000000000, maxValue: 10000000000, color: '#60BCFF' },
    { value: 'от 10 000 до 100 000 млн. $', minValue: 10000000000, maxValue: 100000000000, color: '#3192FF ' },
    { value: 'свыше 100 000 млн. $', minValue: 100000000000, maxValue: null, color: '#0167FF' },
  ],
  potentialColors: [
    { value: 'до 1 000 млн. $', minValue: 0, maxValue: 1000, color: '#90E6FF' },
    { value: 'от 1 000 до 10 000 млн. $', minValue: 1000, maxValue: 10000, color: '#60BCFF' },
    { value: 'от 10 000 до 100 000 млн. $', minValue: 10000, maxValue: 100000, color: '#3192FF ' },
    { value: 'свыше 100 000 млн. $', minValue: 100000, maxValue: null, color: '#0167FF' },
  ],
  tradeInvestmentColors: [
    { value: 'Выбранная страна', minValue: -1, maxValue: -1, color: '#7ED0FD' },
    { value: 'Страны с которыми есть соглашения у выбранной страны', minValue: 0, maxValue: null, color: '#00A5FF' },
  ],
};
