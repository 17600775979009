<template>
  <div class="fileUpload-container">
    <div class="fileUpload-bordered">
      <div v-if="!!description" class="mb-2">
        {{ description }}
      </div>
      <div class="inner-file head" v-if="!readonly">
        <label
          :style="{ cursor: readonly ? 'default' : 'pointer' }"
          class="input-file-btn btn-secondary fira-sans"
          for="file-input"
        >
          {{ label }}
          <input
            :style="{ cursor: readonly ? 'default' : 'pointer' }"
            type="file"
            multiple
            id="file-input"
            class="input-file"
            :accept="accept"
            v-on:change="fileChanged($event)"
          />
        </label>
        <div v-if="isEmptyValue && showEmpty" class="fileUpload__empty">Файлы не выбраны</div>
        <div v-if="v$.value.$error" class="invalid-msg">
          <div v-if="v$.value.required.$invalid" class="fileUpload__error">Загрузите файл</div>
        </div>
      </div>

      <div v-if="isOnce && value" class="inner-file">
        <template v-if="showPreview">
          <div class="file-preview">
            <a href="javascript:" @click.prevent="downloadFile(value)">
              <img :src="getFileUrl(value.id)" alt="" />
            </a>
          </div>
        </template>
        <p v-if="useCheckboxes">
          <input
            type="checkbox"
            v-model="value.checked"
            :disabled="readonly"
            class="mr-2"
            @click="itemChecked(value)"
          />
        </p>
        <p class="file-name fira-sans">{{ value.originalName }}</p>

        <div class="icons-container">
          <IconComponent @click.prevent="downloadFile(value)" name="download_transparent" />

          <IconComponent
            v-if="!iconCloseBtn && !readonly"
            title="Удалить"
            @click="$emit('deleteFile', value)"
            name="closeCross"
          />
        </div>
      </div>

      <div v-else-if="!isOnce && value.length" class="file-name-container">
        <template v-for="el in value" :key="el.id">
          <div class="inner-file">
            <template v-if="showPreview">
              <div class="file-preview">
                <a href="javascript:" @click.prevent="downloadFile(el)">
                  <img :src="getFileUrl(el.id)" alt="" />
                </a>
              </div>
            </template>
            <p v-if="useCheckboxes">
              <input type="checkbox" v-model="el.checked" :disabled="readonly" class="mr-2" @click="itemChecked(el)" />
            </p>
            <p class="file-name fira-sans">{{ el.originalName }}</p>

            <div class="icons-container">
              <IconComponent @click.prevent="downloadFile(el)" name="download_transparent" />

              <IconComponent
                v-if="!iconCloseBtn && !readonly"
                title="Удалить"
                @click="$emit('deleteFile', el)"
                name="closeCross"
              />
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="!isValidFileExtension" class="invalid-msg">Недопустимый формат файла</div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { requiredIf } from '@vuelidate/validators';

  import FileAPI from '@/common/api/file';
  import Constants from '@/common/constants';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  export default {
    components: { IconComponent },
    name: 'FileMultiUploadRedesigned',
    props: {
      label: {
        type: String,
        default: 'Выберите файл',
      },
      entity: Object,
      propName: String,
      readonly: Boolean,
      required: {
        type: Boolean,
        default: false,
      },
      iconCloseBtn: {
        type: Boolean,
        default: false,
      },
      accept: String,
      userData: [Object, String, Number],
      showPreview: {
        type: Boolean,
        default: false,
      },
      useCheckboxes: {
        type: Boolean,
        default: false,
      },
      description: String,
      showEmpty: {
        type: Boolean,
        default: false,
      },
      isOnce: {
        type: Boolean,
        default: false,
      },
      validExtensions: {
        type: Array,
        default: () => [],
      },
    },
    watch: {
      entity: {
        handler(newValue) {
          if (this.isOnce) {
            this.value = newValue;
          } else {
            this.value = newValue ? newValue[this.propName] : [];
          }
        },
        immediate: true,
      },
    },
    emits: ['uploaded', 'deleteFile', 'checked'],
    data() {
      return {
        value: [],
        isValidFileExtension: true,
      };
    },
    computed: {
      isEmptyValue() {
        return this.isOnce ? !this.value : this.value.length === 0;
      },
    },
    methods: {
      downloadFile(file) {
        FileAPI.download(file.id).then((response) => {
          const blob = new Blob([response.data], { type: file.contentType });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = file.originalName;
          link.click();
          URL.revokeObjectURL(link.href);
        });
      },
      fileChanged(e) {
        e.target.files.forEach((file) => {
          if (!this.isValidFile(file.name)) {
            this.isValidFileExtension = false;
            return;
          }

          this.isValidFileExtension = true;

          FileAPI.upload(file)
            .then((response) => {
              this.$emit('uploaded', {
                propName: this.propName,
                data: response.data,
                userData: this.userData,
              });
            })
            .catch((e) => {
              console.log(e);
              Constants.alert.fire('Ошибка', 'Не удалось загрузить файл.', 'error');
            });
        });
      },
      isValidFile(filename) {
        const matchResult = filename.match(/\.(\w+)$/);
        const extension = matchResult[1];
        return !this.validExtensions.length || this.validExtensions.includes(extension);
      },
      itemChecked(item) {
        this.$emit('checked', item);
      },
      getFileUrl(id) {
        return Utils.getFileUrl(id);
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        value: {
          required: requiredIf(function () {
            return this.required;
          }),
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/theme/default/colors';
  ::-webkit-file-upload-button {
    cursor: pointer;
  }
  input[type='file'] {
    cursor: pointer;
  }
  .input-file {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    border-radius: 6px;
    z-index: 2;
  }
  label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: 'Fira Sans';
    font-size: 15px;
    font-weight: 500 !important;
    line-height: 20px;
    z-index: 3;
    margin-bottom: 0;
    text-wrap: nowrap;
    justify-content: center;

    &:hover {
      opacity: 0.7;
    }
  }
  a:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  .fileUpload {
    &-bordered {
      border-radius: 8px;
      border: 1px solid rgba(45, 51, 69, 0.16);
      padding: 32px;
      display: flex;
      align-items: center;
      gap: 24px;
      background-color: #fbfbfb;

      @media (max-width: $laptop) {
        flex-direction: column;
      }
    }
    &-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      letter-spacing: 0.15px;
      width: 100%;
    }
    &__empty {
      text-align: center;
    }
    &__error {
      margin: -8px 0 0 0;
    }
  }

  .file-name-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }

  .inner-file {
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: $laptop-2) {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    &.head {
      .icon {
        margin-left: 6px;
      }
    }
    .icon {
      opacity: 0.7;
      color: #787f8f;
      margin-left: 14px;
    }
    .file-name {
      word-break: break-all;
    }
  }
  .icons-container {
    display: flex;
    margin-left: auto;
  }
  .file-preview {
    margin-right: 16px;
    img {
      width: 120px;
      height: auto;
    }
  }
</style>
