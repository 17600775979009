import axios from 'axios';

export default {
  search: function (request) {
    return axios.get('/api/freeTradeAgreement/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/freeTradeAgreement/find?id=${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/freeTradeAgreement/update?id=${data.id}`, data);
    else return axios.post(`/api/freeTradeAgreement/create`, data);
  },
  delete: function (id) {
    return axios.delete('/api/freeTradeAgreement/delete?id=' + id);
  },
};
