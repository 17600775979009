import { authAxios } from '@/common/axios';

export default {
  search: function (request) {
    return authAxios.get('/api/organization/search', { params: request });
  },
  find: function (id) {
    return authAxios.get(`/api/organization/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return authAxios.put(`/api/organization/${data.id}`, data);
    else return authAxios.post(`/api/organization/`, data);
  },
  delete: function (id) {
    return authAxios.delete('/api/organization/' + id);
  },
};
