import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    loadPage: false,
    loadMore: false,
    tableInfo: {
      firstItemNumber: 1,
      itemCount: 1,
      items: [],
      itemsTotal: 1,
      pageNumber: 1,
      pageSize: 0,
      pagesTotal: 1,
    },
  },
};

export const NAVIGATOR_PRODUCT_GROUPS = 'navigatorProductGroups';

export const NAVIGATOR_PRODUCT_GROUPS_ACTIONS_NAME = {
  fetchListGroups: `${NAVIGATOR_PRODUCT_GROUPS}/fetchListGroups`,
  fetchMoreListGroups: `${NAVIGATOR_PRODUCT_GROUPS}/fetchMoreListGroups`,
  deleteGroup: `${NAVIGATOR_PRODUCT_GROUPS}/deleteGroup`,
  createGroup: `${NAVIGATOR_PRODUCT_GROUPS}/createGroup`,
  findGroup: `${NAVIGATOR_PRODUCT_GROUPS}/findGroup`,
  updateGroup: `${NAVIGATOR_PRODUCT_GROUPS}/updateGroup`,
};

export const NAVIGATOR_PRODUCT_GROUPS_MUTATIONS_NAME = {
  setListGroups: 'setListGroups',
  addListGroups: 'addListGroups',
};

export const NAVIGATOR_PRODUCT_GROUPS_GETTERS_NAME = {
  getTotalGoods: `${NAVIGATOR_PRODUCT_GROUPS}/getTotalGoods`,
  getLoadPage: `${NAVIGATOR_PRODUCT_GROUPS}/getLoadPage`,
  getLoadMore: `${NAVIGATOR_PRODUCT_GROUPS}/getLoadMore`,
  getTableInfo: `${NAVIGATOR_PRODUCT_GROUPS}/getTableInfo`,
};
