import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state: {
    tableHeaderUnilateral: [
      {
        id: 0,
        name: 'Тип торговой операции',
        visible: true,
        width: 192,
      },
      {
        id: 1,
        name: 'Страна',
        visible: true,
        width: 192,
      },
      {
        id: 2,
        name: 'Наименование нетарифной меры',
        visible: true,
        width: 192,
      },
      {
        id: 3,
        name: 'Товар',
        visible: true,
        width: 192,
      },
      {
        id: 4,
        name: 'Код ТН ВЭД ЕАЭС',
        visible: true,
        width: 192,
      },
      {
        id: 5,
        name: 'Отрасль',
        visible: true,
        width: 192,
      },
      {
        id: 6,
        name: 'Дата действия',
        visible: true,
        width: 226,
      },
      {
        id: 7,
        name: 'Правовой акт',
        visible: true,
        width: 192,
      },
      {
        id: 8,
        name: 'Комментарий',
        visible: true,
        width: 232,
      },
      {
        id: 9,
        name: 'Страновой охват',
        visible: true,
        width: 192,
      },
      {
        id: 10,
        name: 'Действующие',
        visible: true,
        width: 192,
      },
    ],
  },
};
