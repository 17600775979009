<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка отрасли</p>
    </div>

    <div>
      <div class="row mb-10">
        <div class="mt-4 col-4">
          <PictureUpload
            v-on:pictureSelected="pictureSelected"
            :initialPhoto="item.picture"
            :isRound="false"
            :clearable="true"
          />
        </div>
        <div class="col-8">
          <div class="form-group required">
            <ComponentInput
              v-model="item.name"
              label="Наименование"
              required
              maxLength="250"
              placeholder="Введите наименование"
            />
          </div>
          <div class="checkboxInput">
            <input type="checkbox" v-model="item.isActive" id="chActive" />
            <label for="chActive">Запись активна</label>
          </div>
        </div>
      </div>
    </div>

    <div>
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import PictureUpload from '@/common/components/PictureUpload';
  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/nsi';

  export default {
    name: 'IndustryCard',
    components: {
      ComponentInput,
      ButtonStock,
      PictureUpload,
    },
    data() {
      return {
        id: this.$route.params.id,
        item: {},
        apiRoute: 'nsiindustry',
        cardName: 'IndustryCard',
        listName: 'Industries',
      };
    },
    created() {
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          API.find(this.apiRoute, this.id).then((response) => {
            this.item = response.data;
          });
        } else {
          this.item = {
            isActive: true,
          };
        }
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        API.update(this.apiRoute, this.item)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && response.data.id) {
              this.$router.push({ name: this.cardName, params: { id: response.data.id, action: 'edit' } });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: this.listName });
      },
      pictureSelected(data) {
        this.item.picture = data;
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
  };
</script>

<style lang="scss" scoped></style>
