<template>
  <div class="checkboxInput">
    <input type="checkbox" :checked="modelValue" @change="$emit('update:modelValue', !modelValue)" id="chActive" />
    <label for="chActive" class="custom-form__label">{{ text }}</label>
  </div>
</template>

<script>
  export default {
    name: 'CheckboxInput',
    emits: ['update:modelValue'],
    props: {
      text: {
        type: String,
        default: '',
      },
      modelValue: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style scoped></style>
