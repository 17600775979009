<template>
  <div :class="['popup-map', mod ? `popup-map--${mod}` : '']">
    <IconComponent class="popup-map__close" @click="close" name="closeCross" />
    <div class="popup-map__wrap">
      <div class="popup-map__header">
        <slot name="header"></slot>
      </div>
      <div class="popup-map__content">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent.vue';

  export default {
    name: 'MapPopup',
    components: { IconComponent },
    emits: ['closePopup'],
    props: {
      mod: {
        type: String,
        default: '',
      },
    },
    methods: {
      close() {
        this.$emit('closePopup');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .popup-map {
    padding: 32px 16px;
    width: 334px;
    min-height: 380px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 38px;
    z-index: 9999;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);

    &__close {
      position: absolute;
      top: 12px;
      right: 17px;
      width: 12px;
      height: 12px;
      color: $light-grey-9;
      transition: color 0.3s ease;

      &:hover {
        color: $blue-2;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
    }

    &__content {
      max-height: 228px;
      overflow: hidden auto;
    }

    &--regions {
      min-height: auto;
    }

    @media (min-width: $tablet) {
      .exportPdf & {
        box-shadow: none;
        border: 1px solid;
      }
    }

    @media (max-width: $laptop) {
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      transform: none;
      right: 0;
      border-radius: 0;
      box-shadow: none;
      padding: 24px 14px;
      min-height: auto;

      &__header {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-bottom: 24px;
      }

      &__content {
        max-height: none;
        overflow: auto;
      }

      &__close {
        top: 30px;
        right: 20px;
        width: 14px;
        height: 14px;
      }

      &__wrap {
        overflow: hidden auto;
        max-height: 100%;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
</style>
