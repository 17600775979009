<template>
  <div id="container">
    <div id="mapContainerSelectCountry"></div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';

  import Constants from '@/common/constants';
  import worldMap from '@/common/custom.geo.json';
  import text from '@/common/mixins/text';

  import { COUNTRY_ANALYTICS_ACTIONS_NAME, COUNTRY_ANALYTICS_GETTERS_NAME } from '../store/analytics/directoryNames';

  export default {
    name: 'mapSelectCountry',
    props: {
      countries: [],
      countryNames: [],
    },
    mixins: [text],
    data() {
      return {
        center: [40, 0],
        world: worldMap,
        map: null,
        bufferData: null,
        million: Constants.MILLION,
        isUnfriendlyCountry: false,
      };
    },
    computed: {
      ...mapGetters({
        getLastExportProduct: COUNTRY_ANALYTICS_GETTERS_NAME.getLastExportProduct,
      }),
    },
    methods: {
      ...mapActions({
        getUnfriendlyCountryInfo: COUNTRY_ANALYTICS_ACTIONS_NAME.getUnfriendlyCountryInfo,
        getExportProductLast: COUNTRY_ANALYTICS_ACTIONS_NAME.getExportProductLast,
      }),
      selectCountry(country) {
        this.$emit('selectMapCountry', {
          code: country,
          isUnfriendly: this.isUnfriendlyCountry,
        });
      },
      styleMap(feature) {
        const code = feature.properties.iso_n3;
        const color = '#E3E3E3';
        if (code == 304) {
          return {
            color: '#60a7ee',
            fillColor: 'white',
            weight: 1,
            opacity: 0.5,
          };
        }
        return { color: '#60a7ee', fillColor: color, weight: 1, opacity: 0.7 };
      },
      onEachFeature(feature, layer) {
        const self = this;
        if (feature.properties && feature.properties.name) {
          const id = feature.properties.iso_n3;

          layer.on('mouseup', async () => {
            const country = self.countries.find((c) => c.code === Number(feature.properties.iso_n3));

            const countryName =
              self.countryNames.find((c) => c.code === Number(feature.properties.iso_n3))?.nameFull ??
              feature?.properties?.name;

            layer.unbindPopup();
            if (country != null) {
              if (country.id != Constants.countryRussiaId) {
                layer.bindPopup(
                  `<div style="min-height: 280px" class="popup">
                    <div class="popup__head d-flex align-items-center justify-content-between">
                      <p class="font-weight-bold" id="popup_country_name${id}">${countryName}</p>
                    </div>
                    <div class="popup__body p-3" id="popup_existing_country">
                      <div id="unfriendly_country${id}" class="popup__restriction">
                        <p class="unfriendly">Недружественное государство</p>
                        <p>Число ограничений: <span id="unfriendly_country_restrictions${id}"></span></p>
                        <p>Экспорт в страну под ограничениями: <span id="unfriendly_country_export_restrictions${id}"></span>&nbsp;млн $</p>
                        <p>Импорт из страны под ограничениями: <span id="unfriendly_country_import_restrictions${id}"></span>&nbsp;млн $</p>
                      </div>
                      <div class="popup__country" id="popup_country${id}">
                          <p class="my-1 mx-0">Торговля страны с Россией</p>
                          <p class="my-1 mx-0">Период: <span id="popup_country_year${id}">...</span> год</p>
                          <p class="my-1 mx-0">Товарооборот: <span id="popup_country_totalValue${id}">...</span> млн $</p>
                          <p class="my-1 mx-0">Экспорт товаров: <span id="popup_country_exportValue${id}">...</span> млн $</p>
                          <p class="my-1 mx-0">Несырьевой неэнергетический<br/>экспорт: <span id="popup_country_exportNonEnergyValue${id}">...</span> млн $</p>
                          <p class="my-1 mx-0">Импорт товаров: <span id="popup_country_importValue${id}">...</span> млн $</p>
                          <p class="my-1 mx-0">Сальдо: <span id="popup_country_balance${id}">...</span> млн $</p>
                          <button class="btn-primary btnShowAnalitics">Просмотр</button>
                      </div>
                    </div>
                  </div>`,
                );
              } else {
                layer.bindPopup(
                  `<div style="min-height: 100px" class="popup">
                    <div class="popup__head d-flex align-items-center justify-content-between">
                      <p class="font-weight-bold" id="popup_country_name${id}">${countryName}</p>
                    </div>
                    <div class="popup__body p-3" id="popup_existing_country">
                      <button class="btn-primary btnShowAnalitics">Просмотр</button>
                    </div>
                  </div>`,
                );
              }
            } else {
              layer.bindPopup(
                `<div style="min-height: 100px" class="popup">
                  <div class="popup__head d-flex align-items-center justify-content-between">
                    <p class="font-weight-bold" id="popup_country_name${id}">${countryName}</p>
                  </div>
                  <div class="popup__body p-3" id="popup_null_country">
                    <div id="popup_country${id}" style="display:block;">
                      <p class="my-1 mx-0">У вас нет доступа к просмотру инфрормации по стране</p>
                    </div>
                  </div>
                </div>`,
              );
            }
            layer.openPopup();
            this.bufferData = feature.properties.iso_n3;

            if (country != null) {
              if (country.id != Constants.countryRussiaId) {
                const countryInfo = await this.fetchUnfriendlyCountryInfo(country.id);
                const hasOnlyEuropeanUnionCountry = this.isEuropeanUnionCountry(countryInfo?.country?.countryGroupId)
                  ? '(для всех стран ЕС)'
                  : '';
                setTimeout(() => {
                  window.document.getElementById(`unfriendly_country_restrictions${id}`).innerHTML =
                    countryInfo?.numberOfRestrictions
                      ? `${this.formatNumber(
                          countryInfo?.numberOfRestrictions,
                          0,
                          '&nbsp;',
                        )} ${hasOnlyEuropeanUnionCountry}`
                      : '';
                  window.document.getElementById(`unfriendly_country_export_restrictions${id}`).innerHTML =
                    countryInfo?.exportValueUnderRestrictions
                      ? this.formatNumber(countryInfo.exportValueUnderRestrictions, 1, '&nbsp;')
                      : '';
                  window.document.getElementById(`unfriendly_country_import_restrictions${id}`).innerHTML =
                    countryInfo?.importValueUnderRestrictions
                      ? this.formatNumber(countryInfo.importValueUnderRestrictions, 1, '&nbsp;')
                      : '';
                  window.document.getElementById(`unfriendly_country${id}`).style.display = this.isUnfriendlyCountry
                    ? 'block'
                    : 'none';
                }, 300);

                const exportProduct = await this.getExportProductLast({ id: country.id });

                if (exportProduct.success) {
                  setTimeout(() => {
                    window.document.getElementById(`popup_country_year${id}`).innerHTML =
                      this.getLastExportProduct.year;
                    window.document.getElementById(`popup_country_totalValue${id}`).innerHTML = this.formatNumber(
                      this.getLastExportProduct.totalValue / this.million,
                    );
                    window.document.getElementById(`popup_country_exportValue${id}`).innerHTML = this.formatNumber(
                      this.getLastExportProduct.exportValue / this.million,
                    );
                    window.document.getElementById(`popup_country_exportNonEnergyValue${id}`).innerHTML =
                      this.formatNumber(this.getLastExportProduct.exportNonEnergyValue / this.million);
                    window.document.getElementById(`popup_country_importValue${id}`).innerHTML = this.formatNumber(
                      this.getLastExportProduct.importValue / this.million,
                    );
                    window.document.getElementById(`popup_country_balance${id}`).innerHTML = this.formatNumber(
                      this.getLastExportProduct.balance / this.million,
                    );
                    window.document.getElementById(`popup_country${id}`).style.display = 'flex';
                  }, 300);
                }
              }
            }
          });
        }
        layer.on('mouseover', function () {
          this.setStyle({
            fillColor: '#0000ff',
          });
          this.unbindTooltip();
          const country = self.countries.find((c) => c.code === Number(feature.properties.iso_n3));
          if (country) this.bindTooltip(country?.fullName ?? '').openTooltip();
          // layer.openPopup();
        });
        layer.on('mouseout', function () {
          this.setStyle({
            fillColor: '#E3E3E3',
          });
          this.unbindTooltip();
        });
      },
      async fetchUnfriendlyCountryInfo(countryId) {
        this.isUnfriendlyCountry = false;
        const response = await this.getUnfriendlyCountryInfo({ id: countryId });
        if (response?.status === 200) {
          this.isUnfriendlyCountry = true;
        }
        return response?.data;
      },
      setupLeafletMap: function () {
        const mapContainer = window.document.getElementById('mapContainerSelectCountry');
        while (mapContainer.firstChild) {
          mapContainer.removeChild(mapContainer.firstChild);
        }
        if (this.map) {
          this.map.remove();
          this.map = null;
        }
        this.map = L.map('mapContainerSelectCountry').setView(this.center, 1.9);
        L.tileLayer('', {
          maxZoom: 5,
          minZoom: 1.4,
        }).addTo(this.map);
        L.geoJSON(this.world, {
          weight: 0.5,
          fillOpacity: 1,
          onEachFeature: this.onEachFeature,
          style: this.styleMap,
        }).addTo(this.map);
      },
      isEuropeanUnionCountry(countryGroupId) {
        return countryGroupId === 10;
      },
    },

    mounted() {
      this.setupLeafletMap();
      window.addEventListener('click', (e) => {
        if (e.target.classList.contains('btnShowAnalitics')) {
          this.map.closePopup();
          this.selectCountry(this.bufferData);
        }
      });
    },
  };
</script>

<style lang="scss">
  #mapContainerSelectCountry {
    background-color: #ffffff;
    width: 100%;
    height: 500px;

    .leaflet-interactive {
      outline: none;
    }
    .leaflet-popup-content {
      margin: 0;
    }
  }
  .leaflet-container a.leaflet-popup-close-button {
    right: 8px;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 24px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    .leaflet-popup-tip-container {
      display: none;
    }
    padding: 0;
    .popup {
      &__head {
        background: #1d87ee;
        p,
        span {
          margin: 10px 32px 10px 24px;
          font-family: 'Fira Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 160%;
          color: #ffffff;
        }
      }
      &__body {
        p {
          margin: 10px 24px;
          font-family: 'Fira Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 160%;
          color: #015caf;
        }
      }

      &__country {
        display: none;
        flex-direction: column;
      }
      &__restriction {
        margin: 10px 0;
        display: none;
        p,
        span {
          font-weight: 500 !important;
          color: $base !important;
          margin: 0 !important;
        }
        span {
          font-weight: 600 !important;
        }
        .unfriendly {
          color: $red !important;
          margin-bottom: 10px !important;
        }
      }
    }
    .leaflet-popup-content {
      margin: 0;
    }
  }
</style>
