<template>
  <Multiselect
    :id="selectId"
    :close-on-select="mode === 'single'"
    :clear-on-select="false"
    :searchable="true"
    :multiple-label="multiLabel"
    :options="optionsFilter"
    :mode="mode"
    :disabled="disabled"
    :preserve-search="mode !== 'single'"
    :hide-selected="false"
    :filterResults="true"
    noResultsText="Ничего не найдено."
    noOptionsText="Список пуст."
    :object="object"
    @search-change="setFilterOptions"
  />
</template>

<script>
  import Multiselect from '@vueform/multiselect';

  export default {
    components: {
      Multiselect,
    },
    name: 'SelectComponentExportPotentialMap',
    emits: ['search'],
    props: {
      selectId: String,
      isLoading: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Array,
        default: null,
        required: true,
      },
      mode: {
        required: false,
        default: 'single',
      },
      showSelectedCount: {
        type: Boolean,
        default: false,
      },
      object: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isLoadingInternal: false,
        optionsFilter: null,
      };
    },
    watch: {
      options: {
        handler: function () {
          this.loadOptions();
        },
        deep: true,
      },
      isLoading: function () {
        this.isLoadingInternal = !!this.isLoading;
        if (!this.isLoadingInternal) {
          // workaround of strange behaviour: showing "No results found" even if results are fetched.
          let el = document.querySelector(`#${this.selectId} .multiselect-caret`);
          el.click();
          setTimeout(() => {
            el = document.querySelector(`#${this.selectId}`);
            el.focus();
          }, 100);
        }
      },
    },
    mounted() {
      this.loadOptions();
    },
    methods: {
      setFilterOptions(query) {
        var term = query?.trim();
        if (term) {
          this.optionsFilter = this.options
            .filter((item) => item.code.startsWith(term) || item.searchText.toLowerCase().includes(term.toLowerCase()))
            .sort((a, b) => {
              return b.label.startsWith(query.toLowerCase()) - a.label.startsWith(query.toLowerCase());
            });
        } else {
          this.optionsFilter = this.options.filter((item) => item.level <= 3);
        }
      },
      loadOptions() {
        if (this.optionsFilter === null && this.options.length > 0) {
          this.optionsFilter = this.options.filter((item) => item.level <= 3);
        }
      },
      multiLabel(value) {
        if (this.showSelectedCount) {
          if (value?.length > 1) {
            return `Выбрано: ${value.length}`;
          }
        }
        let label = '';
        value.forEach((item, index) => {
          if (index) {
            label = label + `, ${item.label}`;
          } else {
            label = item.label;
          }
        });
        return label;
      },
    },
  };
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
  :root {
    // Modal
    --g-dialog-overlay-bg: linear-gradient(270deg, rgba(0, 178, 170, 0.5) 0%, rgba(0, 120, 200, 0.5) 100%);
    // Multiselect
    --ms-tag-bg: #d3dfe6;
    --ms-tag-font-weight: 400;
    --ms-tag-color: #231f20;
    --ms-tag-remove-radius: 50%;
    --ms-option-font-size: 14px;
    --ms-bg: #fbfbfb;
    --ms-caret-color: transparent;
  }
  .multiselect {
    font-size: 14px;
    box-sizing: initial;
  }
  .multiselect.error {
    border: 1px solid red;
  }
  .multiselect-clear {
    margin: 0px 15px 0 0;
    padding: 0;
  }
  .multiselect-tag-remove {
    background-color: #787f8f;
    padding: 2px;
    margin: 0 6px 0 10px;
  }
  .multiselect-tag-remove-icon {
    background-color: #d3dfe5;
  }
  .multiselect-tags-search {
    border: none !important;
  }
  .multiselect-placeholder {
    font-size: 14px;
    color: #989898;
    padding-left: 10px;
    padding-right: 10px;
  }
  .multiselect.is-active {
    box-shadow: none;
  }
  .multiselect-caret {
    outline: none;
    border-color: unset;
    border-style: unset;
    border-width: unset;
    width: 10px;
    height: 10px;
    border-radius: 1px;
    border-left: 3px solid #343c44;
    border-bottom: 3px solid #343c44;
    transform: rotate(315deg);
    margin-top: -6px;
    transition: 0.3s;
    mask-image: unset;
    cursor: pointer;
    &.is-open {
      transform: rotate(135deg);
      margin-top: 2px;
    }
  }
  .is-visible {
    .multiselect {
      flex-direction: column;
      .multiselect-dropdown {
        position: initial;
        display: block !important;
        transform: none;
        max-height: unset;
        width: 100%;
        border-bottom: none;
        border-left: none;
        border-right: none;
        min-height: 403px;
      }
      .multiselect-search {
        display: block !important;
        position: initial;
        margin-bottom: 1px;
        border: 1px solid transparent;
        &:focus {
          border: 1px solid #01a39d;
        }
      }
      .multiselect-clear {
        position: absolute;
        top: 12px;
        right: 6px;
      }
      .multiselect-caret {
        display: none;
      }
      .multiselect-placeholder,
      .multiselect-multiple-label {
        height: auto;
        left: 6px;
        top: 12px;
      }
    }
  }
  .custom-inMultiselect {
    .multiselect-group-label {
      background: #ffffff;
      border-top: 2px solid #d3dfe5;
      border-bottom: 2px solid #d3dfe5;
      padding: 4px 8px 4px 42px;
      &.is-selected {
        background: var(--ms-group-label-bg-selected, #059669);
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
      }
    }
    .multiselect-checkBox {
      min-height: 18px;
      min-width: 18px;
      border: 2px solid #00c6a2;
      border-radius: 2px;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      &.gradient {
        .multiselect-checkBox__arrow {
          border-left: 2px solid #ffffff;
          border-bottom: 2px solid #ffffff;
        }
      }
      &__arrow {
        display: none;
        height: 4px;
        width: 8px;
        border-left: 2px solid #00c6a2;
        border-bottom: 2px solid #00c6a2;
        transform: rotate(-50deg);
      }
    }
    .multiselect-text {
      width: 90%;
    }
    .multiselect-option.is-selected {
      background: none;
      color: #1f2429;
      .multiselect-checkBox {
        &.gradient {
          border: none;
          background: linear-gradient(270deg, #00b2aa 0%, #0078c8 100%);
        }
        &__arrow {
          display: block;
        }
      }
    }
    .multiselect-img {
      height: 61px;
      width: 61px;
      border-radius: 50%;
      margin-right: 8px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
</style>
