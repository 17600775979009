export function getListCountriesAgreements(state) {
  return state.countriesAgreements.map((x) => ({ value: x.id, label: x.name, code: x.code }));
}

export function getListCountriesGroupAgreements(state) {
  return state.countriesGroupAgreements.map((x) => ({ value: x.id, label: x.name, code: x.code }));
}

export function getListCountriesAgreementsSelect(state) {
  return state.countriesAgreements.map((x) => ({ id: x.id, text: x.name, code: x.code }));
}

export function getListCountriesGroupAgreementsSelect(state) {
  return state.countriesGroupAgreements.map((x) => ({ id: x.id, text: x.name, code: x.code }));
}

export function getAgreementsPage(state) {
  return state.agreementsPage;
}
