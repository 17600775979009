<template>
  <div class="custom-form__inputBlock">
    <div class="custom-form__head">
      <p v-if="title" class="custom-form__label" :class="{ 'custom-form__label_bold': bold }">
        {{ title }} <span v-if="required" class="custom-form__required">*</span>
      </p>
      <Tooltip v-if="tooltipText">
        <span>{{ tooltipText }}</span>
      </Tooltip>
    </div>
    <slot></slot>
  </div>
</template>

<script>
  import Tooltip from '@/common/ui/Tooltip.vue';

  export default {
    name: 'InputBlock',
    components: { Tooltip },
    props: {
      required: {
        type: Boolean,
        default: false,
      },
      bold: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
      tooltipText: {
        type: String,
        default: '',
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/custom-form.scss';
</style>
