<template>
  <!-- Тестовая страница -->
  <div class="container-page">
    <div class="list-of-users">
      <!-- title -->
      <p class="list-of-users__title title-inner">Список пользователей новостной рассылки</p>

      <div class="mb-16 d-flex flex-row">
        <ButtonStock
          v-if="canSend"
          :disabled="isSending"
          @click="dialogEdit = true"
          class="mb-6 mr-2"
          title="Сформировать рассылку"
        />
        <ButtonStock
          v-if="canSend"
          :disabled="isSending"
          @click="dialogSelectiveMail = true"
          class="mb-6 mr-2"
          title="Сформировать выборочную рассылку"
        />
        <ButtonStock @click="dialogCreateUser = true" class="mb-6" title="Добавить пользователя" />
      </div>
      <div class="mb-4">
        <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
      </div>

      <!-- table -->
      <div>
        <div class="list-of-users__table table-container js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th>Адрес почты</th>
                <th>Категория новости</th>
                <th>Дата подписки</th>
                <th>Активность</th>
              </tr>
              <tr>
                <th class="filter">
                  <FilterInput
                    v-model="filter.email"
                    placeholder="Найти..."
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    type="select2"
                    :select2-options="newsTypeList"
                    :select2-settings="select2Settings"
                    v-model="filter.newsTypeId"
                    placeholder="(Все)"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <div class="range">
                    <FilterInput
                      class="mr-2"
                      placeholder="от"
                      type="date"
                      v-model="filter.createdFrom"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                    <FilterInput
                      placeholder="до"
                      type="date"
                      v-model="filter.createdTo"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </div>
                </th>
                <th class="filter">
                  <FilterActiveInput
                    v-model="filter.isActive"
                    placeholder="(Все)"
                    v-on:valueChangedDebounced="changeFilter"
                    textTrue="Активен"
                    textFalse="Заблокирован"
                  />
                </th>
              </tr>
            </thead>
            <tbody v-if="page.items?.length && !isLoading">
              <tr v-for="item in page.items" :key="item.id">
                <td>{{ item.email }}</td>
                <td>{{ item.newsTypeName }}</td>
                <td>{{ $momentFormat(item.created, 'DD.MM.YYYY HH:mm') }}</td>
                <td class="cursor">
                  <div
                    class="table-statusBlock d-flex cursor-pointer"
                    @dblclick="changeActive(item)"
                    v-if="!item.updated"
                  >
                    <p class="mr-6">{{ item.isActive ? 'Активен' : 'Заблокирован' }}</p>
                    <IconComponent :name="item.isActive ? 'goodSigh' : 'banSigh'" />
                  </div>
                  <div v-else>Загрузка...</div>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="isLoading">
              <tr>
                <td class="text-center" colspan="4">Загрузка...</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" colspan="4">Нет данных.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="page.pagesTotal > 1">
          <Pagination
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
      </div>
    </div>

    <ModalComponent title="Сформировать рассылку" @close="dialogEdit = false" v-model="dialogEdit">
      <div>
        <div class="mb-32">
          <div class="mb-4 required">
            <ComponentInput
              v-model="card.newsTypeId"
              type="select2"
              :select2-options="newsTypeList"
              :select2-settings="select2Settings"
              label="Выберите тип рассылки"
              required
            />
          </div>
          <div class="required">
            <DateComponent v-model="card.date" label="Новости с даты" required />
          </div>
        </div>
        <ButtonStock @click="sendSubscription" title="Сформировать" :disabled="isSending" />
      </div>
    </ModalComponent>

    <ModalComponent
      title="Сформировать выборочную рассылку"
      @close="dialogSelectiveMail = false"
      v-model="dialogSelectiveMail"
    >
      <div>
        <div class="mb-32">
          <div class="mb-4 required">
            <ComponentInput
              v-model="selectMailingCard.newsTypeId"
              type="select2"
              :select2-options="newsTypeList"
              :select2-settings="select2Settings"
              label="Выберите тип рассылки"
              required
            />
          </div>
          <div class="mb-4 required">
            <ComponentInput
              v-model="selectMailingCard.newsIds"
              type="select2"
              :select2-options="selectiveMailNews"
              :select2-settings="select2SettingsMultiple"
              label="Выберите новости"
              required
            />
          </div>
          <div class="mb-4 required">
            <ComponentInput
              v-model="selectMailingCard.recipientIds"
              type="select2"
              :select2-options="selectiveMailRecipients"
              :select2-settings="select2SettingsMultiple"
              label="Выберите получателей"
              required
            />
          </div>
          <div class="mb-4 required d-block">
            <label class="form-label d-block">Текст сообщения</label>
            <textarea
              class="w-100"
              placeholder="Введите текст сообщения"
              v-model="selectMailingCard.messageText"
              required
            ></textarea>
          </div>
        </div>
        <ButtonStock @click="sendSelectiveSubscription" title="Сформировать" :disabled="isSending" />
      </div>
    </ModalComponent>

    <ModalComponent title="Добавить пользователя" @close="dialogCreateUser = false" v-model="dialogCreateUser">
      <div>
        <div class="mb-32">
          <div class="mb-4 required">
            <ComponentInput v-model="user.email" type="email" label="Введите e-mail" required />
          </div>
          <div class="mb-4 required">
            <ComponentInput
              v-model="user.newsTypeId"
              type="select2"
              :select2-options="newsTypeList"
              :select2-settings="select2Settings"
              label="Выберите тип рассылки"
              required
            />
          </div>
        </div>
        <ButtonStock @click="createUser" title="Добавить" />
      </div>
    </ModalComponent>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import DateComponent from '@/common/components/dateComponent';
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterActiveInput from '@/common/components/FilterActiveInput';
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import NewsApi from '@/modules/media/api/news';

  import NsiApi from '../../nsi/api/nsi';
  import API from '../api/usersOnNews';

  export default {
    name: 'ListOfUsersOnNews',
    components: {
      IconComponent,
      ButtonStock,
      ModalComponent,
      FilterInput,
      FilterActiveInput,
      ComponentInput,
      DateComponent,
      DefaultFilter,
    },
    mixins: [security, filtermanager],
    mounted() {},
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          newsTypeId: null,
          createdFrom: null,
          createdTo: null,
          isActive: null,
        },
        page: {},

        isLoading: false,
        dialogEdit: false,
        newsTypeList: [],
        select2Settings: Constants.select2Settings,
        select2SettingsMultiple: Constants.select2SettingsMultiple,

        canEdit: false,
        canSend: false,

        card: {
          newsTypeId: null,
          date: null,
        },
        user: {
          email: '',
          newsTypeId: null,
        },
        dialogCreateUser: false,
        isSending: false,

        dialogSelectiveMail: false,
        isSendingSelectiveMail: false,
        selectMailingCard: {
          newsTypeId: null,
          newsIds: [],
          recipientIds: [],
          messageText: '',
        },
        selectiveMailNews: [],
        selectiveMailRecipients: [],
      };
    },
    created() {
      this.checkPermission(Constants.Permissions.NewsSubscriptionUserAccess).then((response) => {
        if (response.data) {
          this.loadFilter().then((response) => {
            let filter = response;
            if (filter) {
              this.filter = filter;
            }
            this.loadDictionaries();
            this.loadPage();
          });
        } else {
          this.notAccess();
        }
      });

      this.checkPermission(Constants.Permissions.NewsSubscriptionUserEdit).then((response) => {
        this.canEdit = response.data;
      });
      this.checkPermission(Constants.Permissions.NewsSubscriptionSend).then((response) => {
        this.canSend = response.data;
      });
    },
    methods: {
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(append) {
        this.isLoading = !append;
        const request = Object.assign(this.filter, this.request);
        API.searchUser(request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
                console.log(this.page);
              });
            } else if (response.data) {
              //добавляем к существующему
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      loadDictionaries() {
        NsiApi.search('NsiNewsType', { pageNumber: 1, pageSize: 0, isActive: true }).then((response) => {
          this.newsTypeList = response.data.items.map((x) => ({ id: x.id, text: x.name }));
        });
      },
      changeActive(item) {
        if (this.canEdit && !item.updated) {
          item.updated = true;

          API.changeActiveUser(item.id, !item.isActive)
            .then((response) => {
              item.updated = false;
              item.isActive = response.data.isActive;
            })
            .catch(() => {
              item.updated = false;
            });
        }
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.loadPage(false);
      },

      sendSubscription() {
        this.v$.card.$touch();
        if (this.v$.card.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        this.isSending = true;
        API.sendSubscription(this.card)
          .then((response) => {
            console.log(response.data);
            var info = '';
            if (response.data?.news) info += '<div>Новостей: ' + response.data?.news + ' шт.</div>';
            if (response.data?.users) info += '<div>Подписчиков: ' + response.data?.users + ' шт.</div>';
            Constants.alert.fire('Рассылка', 'Рассылка успешно отправлена' + info, 'success');
            this.dialogEdit = false;
            this.isSending = false;
          })
          .catch((error) => {
            console.error(error?.response?.data);
            var mess = error?.response?.data ? error?.response?.data : Constants.commonError;
            console.log(mess);
            Constants.alert.fire('Рассылка', mess, 'error');
            this.dialogEdit = false;
            this.isSending = false;
          });
      },
      createUser() {
        this.v$.user.$touch();
        if (this.v$.user.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        } else {
          API.createUser(this.user)
            .then(() => {
              this.dialogCreateUser = false;
              this.$nextTick(() => {
                this.user = { email: '', newsTypeId: null };
                this.loadPage(false);
              });
            })
            .catch((error) => {
              Constants.alert.fire('Ошибка', error.response.data, 'error');
              this.dialogCreateUser = false;
            });
        }
      },
      loadSelectiveMailLists(id) {
        if (id) {
          NewsApi.search({
            pageNumber: 1,
            pageSize: 0,
            typeId: id,
            statusId: Constants.entityStatus.published,
            isActive: true,
          }).then((response) => {
            this.selectiveMailNews = response.data.items.map((x) => ({ id: x.id, text: x.name }));
            console.log(response.data);
          });
          API.searchUser({ pageNumber: 1, pageSize: 0, newsTypeId: id, isActive: true }).then((response) => {
            this.selectiveMailRecipients = response.data.items.map((x) => ({ id: x.id, text: x.email }));
          });
        }
      },
      sendSelectiveSubscription() {
        this.v$.selectMailingCard.$touch();
        if (this.v$.selectMailingCard.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        this.isSendingSelectiveMail = true;
        this.isSending = true;
        API.sendSelectiveSubscription(this.selectMailingCard)
          .then((response) => {
            var info = '';
            if (response.data?.news) info += '<div>Новостей: ' + response.data?.news + ' шт.</div>';
            if (response.data?.users) info += '<div>Подписчиков: ' + response.data?.users + ' шт.</div>';
            Constants.alert.fire('Рассылка', 'Рассылка успешно отправлена' + info, 'success');
            this.dialogSelectiveMail = false;
            this.isSending = false;
            this.selectMailingCard = { newsTypeId: null, newsIds: [], recipientIds: [], messageText: '' };
          })
          .catch((error) => {
            console.error(error?.response?.data);
            var mess = error?.response?.data ? error?.response?.data : Constants.commonError;
            console.log(mess);
            Constants.alert.fire('Рассылка', mess, 'error');
            this.dialogSelectiveMail = false;
            this.isSending = false;
          });
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        card: {},
        user: {},
        selectMailingCard: {},
      };
    },
    watch: {
      'selectMailingCard.newsTypeId': {
        handler: function (value) {
          this.selectMailingCard.newsIds = [];
          this.selectMailingCard.recipientIds = [];
          this.selectiveMailNews = [];
          this.selectiveMailRecipients = [];
          this.$nextTick(() => {
            this.loadSelectiveMailLists(value);
          });
        },
      },
    },
  };
</script>

<style lang="scss" scoped></style>
