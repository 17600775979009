<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Настройки системы</p>
    </div>
    <div v-if="items?.length && !isLoading">
      <div class="mb-16 d-flex flex-row justify-content-between setting_container" v-for="item in items" :key="item.id">
        <span class="setting_title">{{ item.name }}</span>
        <ComponentInput class="setting_input" type="number" v-model="item.value" />
      </div>
    </div>

    <div>
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="saveSettings" />
    </div>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/settings';

  export default {
    name: 'Settings',
    components: {
      ComponentInput,
      ButtonStock,
    },
    data() {
      return {
        items: [],
        isLoading: false,
      };
    },
    created() {
      this.loadSettings();
    },
    methods: {
      loadSettings() {
        this.isLoading = true;
        API.search().then((response) => {
          this.items = response.data;
          this.isLoading = false;
        });
      },
      saveSettings() {
        if (this.items.some((x) => !x.value)) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        API.saveSettings(this.items)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .setting_container::v-deep {
    align-items: center;
    .input-group-wrapper {
      min-width: 30%;
      max-width: 30%;
      margin: 4px 8px;
    }
    button {
      margin: 0 4px;
    }
    .setting_title {
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #343434;
    }
  }
</style>
