<template>
  <div class="statisticByBarriers">
    <div class="statisticByBarriers__select-container">
      <SelectSingleComponentCheckbox
        v-model="timeIntervalId"
        placeholder="Выберите"
        trackBy="text"
        valueProp="id"
        labelOptions="text"
        :options="timeIntervalOptions"
        :can-clear="false"
      />
    </div>

    <BarrierDiagramByYear
      v-if="timeIntervalId === 'year'"
      title="Выявленные барьеры"
      :barrierData="identifiedBarrierHistogramData.yearBars"
    >
      <Loader v-if="isLoading" />
      <ChartColumnAndLine
        v-else
        :xData="identifiedBarrierHistogramByYearXData"
        :columnYData="identifiedBarrierQuantityHistogramByYearYData"
        columnYName="Количество выявленных барьеров"
        :lineYData="identifiedBarrierDamageSumHistogramByYearYData"
        lineYName="Сумма ущерба, млрд долл. США"
      />
    </BarrierDiagramByYear>

    <BarrierDiagramByYear
      v-if="timeIntervalId === 'year'"
      title="Устраненные барьеры"
      :barrierData="removedBarrierHistogramData.yearBars"
    >
      <Loader v-if="isLoading" />
      <ChartColumnAndLine
        v-else
        :xData="removedBarrierHistogramByYearXData"
        :columnYData="removedBarrierQuantityHistogramByYearYData"
        columnYName="Количество устраненных барьеров"
        :lineYData="removedBarrierDamageSumHistogramByYearYData"
        lineYName="Устраненный ущерб, млрд долл. США"
      />
    </BarrierDiagramByYear>

    <BarrierDiagramByQuarter
      v-if="timeIntervalId === 'quarter'"
      title="Выявленные барьеры"
      :barrierData="identifiedBarrierHistogramData.quarterBars"
    >
      <template #columnChart>
        <Loader v-if="isLoading" />
        <ChartColumn v-else :xData="identifiedBarrierHistogramByQuarterXData" :yData="yDataIdentifiedBar" />
      </template>
      <template #lineChart>
        <Loader v-if="isLoading" />
        <ChartLine
          v-else
          :xData="identifiedBarrierHistogramByQuarterXData"
          :yData="identifiedBarrierDamageSumHistogramByQuarterYData"
        />
      </template>
    </BarrierDiagramByQuarter>

    <BarrierDiagramByQuarter
      v-if="timeIntervalId === 'quarter'"
      title="Устраненные барьеры"
      :barrierData="removedBarrierHistogramData.quarterBars"
    >
      <template #columnChart>
        <Loader v-if="isLoading" />
        <ChartColumn v-else :xData="removedBarrierHistogramByQuarterXData" :yData="yDataRemovedBar" />
      </template>
      <template #lineChart>
        <Loader v-if="isLoading" />
        <ChartLine
          v-else
          :xData="removedBarrierHistogramByQuarterXData"
          :yData="removedBarrierDamageSumHistogramByQuarterYData"
        />
      </template>
    </BarrierDiagramByQuarter>
  </div>
</template>

<script>
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import getRomanNumber from '@/common/mixins/getRomanNumber';
  import numberFormatter from '@/common/mixins/numberFormatter';

  import ChartColumn from '@/components/ChartComponents/ChartColumn.vue';
  import ChartLine from '@/components/ChartComponents/ChartLine.vue';
  import Loader from '@/components/Loader.vue';

  import analyticApi from '../../../api/analytics.js';
  import ChartColumnAndLine from '../../chart/ChartColumnAndLine.vue';
  import BarrierDiagramByQuarter from './BarrierDiagramByQuarter.vue';
  import BarrierDiagramByYear from './BarrierDiagramByYear.vue';

  const TIME_INTERVAL_ID = {
    year: 'year',
    quarter: 'quarter',
  };

  const TIME_INTERVAL_OPTIONS = [
    {
      text: 'По годам',
      id: TIME_INTERVAL_ID.year,
    },
    {
      text: 'По кварталам',
      id: TIME_INTERVAL_ID.quarter,
    },
  ];
  export default {
    components: {
      SelectSingleComponentCheckbox,
      BarrierDiagramByYear,
      BarrierDiagramByQuarter,
      ChartColumnAndLine,
      ChartColumn,
      ChartLine,
      Loader,
    },
    mixins: [getRomanNumber, numberFormatter],
    data() {
      return {
        timeIntervalOptions: TIME_INTERVAL_OPTIONS,
        timeIntervalId: TIME_INTERVAL_ID.year,
        isLoading: false,

        identifiedBarrierHistogramData: {},
        removedBarrierHistogramData: {},

        // данные для гистограммы по выявленным барьерам по годам
        identifiedBarrierHistogramByYearXData: [],
        identifiedBarrierQuantityHistogramByYearYData: [],
        identifiedBarrierDamageSumHistogramByYearYData: [],

        // данные для гистограммы по выявленным барьерам по кварталам
        identifiedBarrierHistogramByQuarterXData: [],
        identifiedBarrierQuantityHistogramByQuarterYData: [],
        identifiedBarrierDamageSumHistogramByQuarterYData: [],

        // данные для гистограммы по устраненным барьерам по годам
        removedBarrierHistogramByYearXData: [],
        removedBarrierQuantityHistogramByYearYData: [],
        removedBarrierDamageSumHistogramByYearYData: [],

        // данные для гистограммы по устраненным барьерам по кварталам
        removedBarrierHistogramByQuarterXData: [],
        removedBarrierQuantityHistogramByQuarterYData: [],
        removedBarrierDamageSumHistogramByQuarterYData: [],
      };
    },
    mounted() {
      this.isLoading = true;
      this.fetchIdentifiedBarrierHistogram();
      this.fetchRemovedBarrierHistogram();
    },
    methods: {
      async fetchIdentifiedBarrierHistogram() {
        try {
          const identifiedBarrierHistogramResolve = await analyticApi.getIdentifiedBarrierHistogram();

          if (!identifiedBarrierHistogramResolve.data) {
            throw new Error('Ошибка');
          }

          this.identifiedBarrierHistogramData = identifiedBarrierHistogramResolve.data;

          // массив данных по оси X по годам
          this.identifiedBarrierHistogramByYearXData = this.identifiedBarrierHistogramData.yearBars?.map(
            (item) => item.year,
          );
          // массив данных по оси X по кварталам
          this.identifiedBarrierHistogramByQuarterXData = this.getXDataByQuarter(
            this.identifiedBarrierHistogramData?.quarterBars,
          );

          // Массив данных количества барьеров по оси y по годам
          this.identifiedBarrierQuantityHistogramByYearYData = this.identifiedBarrierHistogramData.yearBars?.map(
            (item) => item.barrierQuantity,
          );
          // Массив данных количества барьеров по оси y по кварталам
          this.identifiedBarrierQuantityHistogramByQuarterYData = this.identifiedBarrierHistogramData.quarterBars?.map(
            (item) => item.barrierQuantity,
          );

          // Массив данных суммы ущерба по оси Y по годам
          this.identifiedBarrierDamageSumHistogramByYearYData = this.identifiedBarrierHistogramData.yearBars?.map(
            (item) => item.damageSum.toFixed(2),
          );
          // Массив данных суммы ущерба по оси Y по кварталам
          this.identifiedBarrierDamageSumHistogramByQuarterYData = this.identifiedBarrierHistogramData.quarterBars?.map(
            (item) => item.damageSum.toFixed(2),
          );
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading = false;
        }
      },
      async fetchRemovedBarrierHistogram() {
        try {
          const removedBarrierHistogramResolve = await analyticApi.getRemovedBarrierHistogram();

          if (!removedBarrierHistogramResolve.data) {
            throw new Error('Ошибка');
          }

          this.removedBarrierHistogramData = removedBarrierHistogramResolve.data;

          // массив данных по оси X по годам
          this.removedBarrierHistogramByYearXData = this.removedBarrierHistogramData.yearBars?.map((item) => item.year);

          // массив данных по оси X по кварталам
          this.removedBarrierHistogramByQuarterXData = this.getXDataByQuarter(
            this.removedBarrierHistogramData.quarterBars,
          );

          // Массив данных количества барьеров по оси Y по годам
          this.removedBarrierQuantityHistogramByYearYData = this.removedBarrierHistogramData.yearBars?.map(
            (item) => item.barrierQuantity,
          );

          // Массив данных количества барьеров по оси Y по кварталам
          this.removedBarrierQuantityHistogramByQuarterYData = this.removedBarrierHistogramData.quarterBars?.map(
            (item) => item.barrierQuantity,
          );

          // Массив данных суммы ущерба по оси Y по годам
          this.removedBarrierDamageSumHistogramByYearYData = this.removedBarrierHistogramData.yearBars.map((item) =>
            item.damageSum.toFixed(2),
          );
          // Массив данных суммы ущерба по оси Y по кварталам
          this.removedBarrierDamageSumHistogramByQuarterYData = this.removedBarrierHistogramData.quarterBars.map(
            (item) => item.damageSum.toFixed(2),
          );
        } catch (e) {
          console.error(e);
        } finally {
          this.isLoading = false;
        }
      },
      getXDataByQuarter(array) {
        return array.map((item) => `${this.getRomanNumber(item.quarter)} квартал ${item.year}`);
      },
    },
    computed: {
      yDataIdentifiedBar() {
        return [
          {
            name: 'Количество выявленных барьеров',
            type: 'bar', // Тип столбца
            data: this.identifiedBarrierQuantityHistogramByQuarterYData,
          },
        ];
      },
      yDataRemovedBar() {
        return [
          {
            name: 'Количество устраненных барьеров',
            type: 'bar', // Тип столбца
            data: this.removedBarrierQuantityHistogramByQuarterYData,
          },
        ];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .statisticByBarriers {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__select-container {
      margin-top: 24px;
      max-width: 416px;
    }
  }
</style>
