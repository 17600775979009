<template>
  <!-- Тестовая страница создание проект -->
  <div class="container-page">
    <div class="create-projects">
      <!-- title -->
      <p class="create-projects__title title-inner" v-if="!id">
        Торгово-инвестиционные соглашения – Создание нового соглашения
      </p>
      <p class="create-projects__title title-inner" v-else>
        Торгово-инвестиционные соглашения – {{ isReadonly ? 'просмотр' : 'редактирование' }} карточки соглашения
      </p>

      <div v-disable-all="isReadonly">
        <div class="row">
          <div class="form-group col col-lg-12">
            <ComponentInput required v-model="item.name" label="Название" />
          </div>
        </div>
        <div class="row">
          <div class="form-group col col-lg-6">
            <ComponentInput
              placeholder="Выберите тип..."
              :select2Options="types"
              v-model="item.typeId"
              label="Тип соглашения"
              :select2Settings="select2Settings"
              type="select2"
            />
          </div>
          <div class="form-group col col-lg-6">
            <ComponentInput
              placeholder="Выберите..."
              :select2Options="tradeItemTypes"
              v-model="item.tradeItemTypesIds"
              label="Охват"
              :select2Settings="select2SettingsMultiple"
              type="select2"
              :multiple="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group col col-lg-3">
            <label class="form-label">Текст соглашения и тарифные обязательства</label>
          </div>
          <div class="form-group col col-lg-9">
            <FileMultiUpload :entity="item" prop-name="mainFiles" @uploaded="filesUploaded" @deleteFile="removeFile" />
          </div>
        </div>
        <div class="row">
          <div class="form-group col col-lg-6">
            <ComponentInput v-model="item.signDt" label="Дата подписания" />
          </div>
          <div class="form-group col col-lg-6">
            <ComponentInput v-model="item.startDt" label="Дата вступления в силу" />
          </div>
        </div>
        <div class="row">
          <div class="form-group col col-lg-6">
            <ComponentInput
              placeholder="Выберите страны..."
              :select2Options="countries"
              v-model="item.sidesIds"
              label="Стороны"
              :select2Settings="select2SettingsMultiple"
              type="select2"
              :multiple="true"
            />
          </div>
          <div class="form-group col col-lg-6">
            <ComponentInput
              placeholder="Выберите страны..."
              :select2Options="countries"
              v-model="item.initialSignersIds"
              label="Первоначальные подписавшиеся"
              :select2Settings="select2SettingsMultiple"
              type="select2"
              :multiple="true"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col col-lg-2">
            <label class="form-label">Ответственное ведомство</label>
          </div>
          <div class="form-group col col-lg-9">
            <div v-for="(block, idx) in item.blocks" :key="idx" class="row">
              <div class="form-group col col-lg-4">
                <ComponentInput v-model="block.name" placeholder="Название ссылки" />
              </div>
              <div class="form-group col col-lg-7">
                <ComponentInput v-model="block.url" placeholder="Ссылка" />
              </div>
              <div class="form-group col col-lg-1" v-if="idx > 0">
                <IconComponent @click="deleteBlock(idx)" name="delete" />
              </div>
            </div>
          </div>
          <div class="form-group col col-lg-1">
            <ButtonStock @click="addBlock()" btnType="blue" title="Добавить" />
          </div>
        </div>

        <div class="row">
          <div class="form-group col col-lg-2">
            <label class="form-label"
              >Членство страны в региональных блоках, международных организациях/объединениях</label
            >
          </div>
          <div class="form-group col col-lg-9">
            <div class="row">
              <div class="form-group col col-lg-11">
                <ComponentInput v-model="item.responsibleDepartment" placeholder="Ответственное ведомство" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col col-lg-2">
            <label class="form-label">Источники</label>
          </div>
          <div class="form-group col col-lg-9">
            <div v-for="(info, idx) in item.additionalInfo" :key="idx" class="row">
              <div class="form-group col col-lg-4">
                <ComponentInput v-model="info.name" placeholder="Название ссылки" />
              </div>
              <div class="form-group col col-lg-7">
                <ComponentInput v-model="info.url" placeholder="Ссылка" />
              </div>
              <div class="form-group col col-lg-1" v-if="idx > 0">
                <IconComponent @click="deleteInfo(idx)" name="delete" />
              </div>
            </div>
          </div>
          <div class="form-group col col-lg-1">
            <ButtonStock @click="addInfo()" btnType="blue" title="Добавить" />
          </div>
        </div>

        <div class="row">
          <div class="form-group col col-lg-2">
            <label class="form-label">Документы</label>
          </div>
          <div class="form-group col col-lg-9">
            <div v-if="!item.documents || item.documents.length === 0">Документы отсутствуют</div>
            <div v-else>
              <div v-for="(doc, idx) in item.documents" :key="idx" class="row">
                <div class="form-group col col-lg-7">
                  <ComponentInput v-model="doc.name" placeholder="Название документа" required />
                </div>
                <div class="form-group col col-lg-4">
                  <FileUpload
                    width="100%"
                    :entity="doc"
                    @deleteFile="removeDocFile(idx)"
                    required
                    prop-name="file"
                    :entity-index="idx"
                    @uploaded="fileDocUploaded"
                  />
                </div>
                <div class="form-group col col-lg-1">
                  <IconComponent @click="deleteDoc(idx)" name="delete" />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col col-lg-1">
            <ButtonStock @click="addDoc()" btnType="blue" title="Добавить" />
          </div>
        </div>
        <!-- --------------------------- -->
        <div class="row">
          <div class="form-group col col-lg-2">
            <label class="form-label">Дополнительные сведения</label>
          </div>
          <div class="form-group col col-lg-9">
            <div v-for="(info, idx) in item.agreementAdditionalInfo" :key="idx" class="row">
              <div class="form-group col col-lg-4">
                <ComponentInput v-model="info.name" placeholder="Название ссылки" />
              </div>
              <div class="form-group col col-lg-7">
                <ComponentInput v-model="info.url" placeholder="Ссылка" />
              </div>
              <div class="form-group col col-lg-1" v-if="idx > 0">
                <IconComponent @click="deleteAdditionalInfo(idx)" name="delete" />
              </div>
            </div>
          </div>
          <div class="form-group col col-lg-1">
            <ButtonStock @click="addAdditionalInfo()" btnType="blue" title="Добавить" />
          </div>
        </div>

        <div class="row">
          <div class="form-group col col-lg-2">
            <label class="form-label">Дополнительная информация</label>
          </div>
          <div class="form-group col col-lg-9">
            <div v-if="!item.additionalDocuments || item.additionalDocuments.length === 0">Документы отсутствуют</div>
            <div v-else>
              <div v-for="(doc, idx) in item.additionalDocuments" :key="idx" class="row">
                <div class="form-group col col-lg-7">
                  <ComponentInput v-model="doc.name" placeholder="Название документа" required />
                </div>
                <div class="form-group col col-lg-4">
                  <FileUpload
                    width="100%"
                    :entity="doc"
                    @deleteFile="removeAdditionalDocFile(idx)"
                    required
                    prop-name="file"
                    :entity-index="idx"
                    @uploaded="fileAdditionalDocUploaded"
                  />
                </div>
                <div class="form-group col col-lg-1">
                  <IconComponent @click="deleteAdditionalDoc(idx)" name="delete" />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col col-lg-1">
            <ButtonStock @click="addAdditionalDoc()" btnType="blue" title="Добавить" />
          </div>
        </div>

        <!-- --------------------------- -->
        <div class="row">
          <div class="checkboxInput">
            <input type="checkbox" v-model="item.isActive" id="ch_isactive" />
            <label for="ch_isactive" class="form-check-label form-label">Запись активна</label>
          </div>
        </div>

        <div v-if="!isReadonly" class="create-projects__row">
          <ButtonStock title="Сохранить" v-on:click="save" />
          <ButtonStock title="Отменить" v-on:click="close" />
        </div>
        <div v-else class="create-projects__row">
          <ButtonStock title="Закрыть" data-ignore-disable="true" v-on:click="close" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // Additional
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import FileMultiUpload from '@/common/components/FileMultiUpload';
  import FileUpload from '@/common/components/FileUpload';
  import Constants from '@/common/constants';
  import disableAll from '@/common/directives/disable-all';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API_NSI from '@/modules/nsi/api/nsi';

  import API from '../api/index';

  export default {
    name: 'EditAgreement',
    components: {
      ButtonStock,
      ComponentInput,
      IconComponent,
      FileUpload,
      FileMultiUpload,
    },
    directives: {
      'disable-all': disableAll,
    },
    data() {
      return {
        id: this.$route.params.id,
        item: {},
        countries: [],
        types: [],
        tradeItemTypes: [],
        select2Settings: Constants.select2Settings,
        select2SettingsMultiple: Constants.select2SettingsMultiple,
        isReadonly: this.$route.params.action === 'view',
      };
    },
    mixins: [security],
    created() {
      this.checkPermissions([Constants.Permissions.FreeTradeAgreement]);
      this.loadCard();
      this.loadDictionaries();
    },
    computed: {},
    methods: {
      loadCard() {
        if (!this.id || this.id == 0) {
          this.item.isActive = true;
          this.item.blocks = [];
          this.item.blocks.push({ name: '', url: '' });
          this.item.additionalInfo = [];
          this.item.additionalInfo.push({ name: '', url: '' });
          this.item.agreementAdditionalInfo = [];
          this.item.agreementAdditionalInfo.push({ name: '', url: '' });
          this.item.mainFiles = [];
        } else {
          API.find(this.id).then((response) => {
            this.item = response.data;
            if (!this.item.blocks || this.item.blocks.length === 0) {
              this.item.blocks = [];
              this.item.blocks.push({ name: '', url: '' });
            }
            //else {
            //  this.item.blocks = JSON.parse(this.item.blocks);
            //}
            if (!this.item.additionalInfo || this.item.additionalInfo.length === 0) {
              this.item.additionalInfo = [];
              this.item.additionalInfo.push({ name: '', url: '' });
            }
            if (!this.item.agreementAdditionalInfo || this.item.agreementAdditionalInfo.length === 0) {
              this.item.agreementAdditionalInfo = [];
              this.item.agreementAdditionalInfo.push({ name: '', url: '' });
            }
            //else {
            //  this.item.additionalInfo = JSON.parse(this.item.additionalInfo);
            //}
          });
        }
      },
      loadDictionaries() {
        var request = { pageNumber: 1, pageSize: 0 };
        Utils.loadSelectOptions('countryList', this.countries);
        API_NSI.search('NsiFreeTradeAgreementType', request).then((response) => {
          if (response.data) {
            this.types = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
        API_NSI.search('NsiTradeItemType', request).then((response) => {
          if (response.data) {
            this.tradeItemTypes = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
      },
      addBlock() {
        this.item.blocks.unshift({ name: '', url: '' });
      },
      deleteBlock(idx) {
        this.item.blocks.splice(idx, 1);
      },
      addInfo() {
        this.item.additionalInfo.unshift({ name: '', url: '' });
      },
      deleteInfo(idx) {
        this.item.additionalInfo.splice(idx, 1);
      },
      addAdditionalInfo() {
        this.item.agreementAdditionalInfo.unshift({ name: '', url: '' });
      },
      deleteAdditionalInfo(idx) {
        this.item.agreementAdditionalInfo.splice(idx, 1);
      },
      fileDocUploaded(e) {
        this.item.documents[e.entityIndex].file = e.data;
        this.item.documents[e.entityIndex].fileId = e.data ? e.data.id : null;
      },
      removeDocFile(idx) {
        this.item.documents[idx].file = {};
        this.item.documents[idx].fileId = null;
      },
      addDoc() {
        if (!this.item.documents) this.item.documents = [];
        this.item.documents.unshift({ id: 0 });
      },
      deleteDoc(idx) {
        this.item.documents.splice(idx, 1);
      },
      fileAdditionalDocUploaded(e) {
        this.item.additionalDocuments[e.entityIndex].file = e.data;
        this.item.additionalDocuments[e.entityIndex].fileId = e.data ? e.data.id : null;
      },
      removeAdditionalDocFile(idx) {
        this.item.additionalDocuments[idx].file = {};
        this.item.additionalDocuments[idx].fileId = null;
      },
      addAdditionalDoc() {
        if (!this.item.additionalDocuments) this.item.additionalDocuments = [];
        this.item.additionalDocuments.unshift({ id: 0 });
      },
      deleteAdditionalDoc(idx) {
        this.item.additionalDocuments.splice(idx, 1);
      },
      filesUploaded(e) {
        this.item.mainFiles.push(e.data);
      },
      removeFile(e) {
        const file = this.item.mainFiles.find((x) => x.id === e.id);
        const index = this.item.mainFiles.indexOf(file);
        if (index > -1) {
          this.item.mainFiles.splice(index, 1);
        }
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        API.update(this.item)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && response.data.id) {
              //this.$router.push({ name: 'EditProjectCard', params: { id: response.data.id, action: 'edit' } })
              this.$router.push({ name: 'Agreements' });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Agreements' });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
  };
</script>

<script setup></script>

<style lang="scss" scoped>
  .title-inputBlock {
    width: 100%;
    margin-bottom: 14px;
  }
  .form-label {
    font-weight: 400 !important;
    font-size: 14px;
    color: #231f20;
    line-height: 160%;
  }
  .icon {
    color: #01a39d;
  }

  .create-projects {
    &__container {
      display: flex;
      flex-wrap: wrap;
      .title-hidden {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #c6c7c7;
        display: inline-block;
      }
      .btn-blue {
        transition: 0.3s;
        &.active {
          background: rgb(4, 218, 4);
        }
      }
    }
    &__row {
      display: flex;
      width: 100%;
      margin-bottom: 24px;
      .create-projects__inputBlock {
        display: flex;
      }
      &:last-of-type {
        justify-content: flex-end;
        button {
          margin-right: 20px;
          &:last-of-type {
            margin-right: 0px;
          }
        }
      }
      &:nth-of-type(1) {
        .create-projects__inputBlock {
          justify-content: space-between;
          width: 100%;
        }
      }
      &:nth-of-type(2) {
        justify-content: space-between;
        align-items: flex-start;
        .create-projects__inputBlock {
          flex-wrap: wrap;
          align-items: flex-end;
          justify-content: space-between;
          width: 49%;
          .create-projects__inputBlock-col {
            width: 49%;
            label {
              width: 100%;
            }
            .calendar-wrapper,
            .btn-blue {
              display: inline-block;
              width: 48%;
            }
            .btn-blue {
              margin-left: 4px;
              padding: 13px;
              text-align: center;
            }
          }
          &:nth-of-type(1) {
            height: 100%;
            .title-inputBlock {
              margin-bottom: auto;
            }
          }
        }
      }
      &:nth-of-type(3) {
        .create-projects__inputBlock {
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
          &-col {
            width: 100%;
            margin-bottom: 48px;
            &:nth-of-type(1),
            &:nth-of-type(2) {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              width: 49%;
              label {
                // max-width: 190px;
                width: 100%;
              }
            }
            &:nth-of-type(3) {
              display: flex;
              align-items: flex-start;
              input {
                &:nth-of-type(1) {
                  margin-left: 36px;
                  max-width: 98px;
                }
                &:nth-of-type(2) {
                  margin-left: 18px;
                }
              }
              .btn-blue {
                margin-left: 28px;
              }
            }
            &:nth-of-type(4),
            &:nth-of-type(5) {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              flex-wrap: wrap;
              width: 49%;
              input {
                margin-bottom: 16px;
              }
              label,
              textarea {
                width: 100%;
              }
              textarea {
                height: 80px;
              }
            }
          }
        }
      }
      &:nth-of-type(4) {
        .create-projects__inputBlock {
          flex-wrap: wrap;
          &-col {
            input {
              margin-right: 8px;
              margin-bottom: 24px;
            }
          }
        }
      }
    }

    &__inputBlock {
      .title-inputBlock {
        width: 100%;
        margin-bottom: 14px;
      }
      .select2,
      input,
      .title-hidden {
        width: 100%;
        max-width: 280px;
      }
      &-col {
        label {
          font-weight: 300 !important;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #000000;
          margin-bottom: 0px;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .create-projects__row {
      flex-wrap: wrap;
    }
    .create-projects__row:nth-of-type(2) .create-projects__inputBlock:nth-of-type(1) {
      height: auto;
    }
    .create-projects__row:nth-of-type(2) .create-projects__inputBlock {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    .create-projects__row:nth-of-type(3) .create-projects__inputBlock-col:nth-of-type(1),
    .create-projects__row:nth-of-type(3) .create-projects__inputBlock-col:nth-of-type(2),
    .create-projects__row:nth-of-type(3) .create-projects__inputBlock-col:nth-of-type(4),
    .create-projects__row:nth-of-type(3) .create-projects__inputBlock-col:nth-of-type(5) {
      width: 100%;
    }
    .create-projects__inputBlock .select2,
    .create-projects__inputBlock input,
    .create-projects__inputBlock .title-hidden {
      max-width: 340px;
    }
    .select2,
    input {
      margin-bottom: 16px;
    }
    .create-projects__row:nth-of-type(1) .create-projects__inputBlock {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 767px) {
    .create-projects__row:nth-of-type(3) .create-projects__inputBlock-col:nth-of-type(3) {
      flex-wrap: wrap;
    }
    .create-projects__inputBlock .select2,
    .create-projects__inputBlock input,
    .create-projects__inputBlock .title-hidden {
      max-width: unset;
    }
    .create-projects__row:nth-of-type(2) .create-projects__inputBlock .create-projects__inputBlock-col,
    .create-projects__row:nth-of-type(3) .create-projects__inputBlock-col:nth-of-type(1),
    .create-projects__row:nth-of-type(3) .create-projects__inputBlock-col:nth-of-type(2),
    .create-projects__row:nth-of-type(3) .create-projects__inputBlock-col:nth-of-type(4),
    .create-projects__row:nth-of-type(3) .create-projects__inputBlock-col:nth-of-type(5) {
      width: 100%;
    }
    .create-projects__row:nth-of-type(3) .create-projects__inputBlock-col:nth-of-type(3) input:nth-of-type(1),
    .create-projects__row:nth-of-type(3) .create-projects__inputBlock-col:nth-of-type(3) input:nth-of-type(2),
    .create-projects__row:nth-of-type(3) .create-projects__inputBlock-col:nth-of-type(3) .btn-blue {
      max-width: unset;
      margin-left: 0px;
    }
  }
</style>
