<template>
  <div class="d-flex mb-4 align-items-center justify-content-between">
    <div class="world-indicator-title" :id="titleId">{{ title }}</div>
    <div class="country-analytics-new__selector country-analytics-new__period-selector">
      <ComponentInput
        class="select2"
        :select2Options="years"
        placeholder="Период"
        type="select2"
        v-model="currentYear"
        :select2Settings="select2Settings"
      />
    </div>
    <div class="world-indicator-source-text d-flex align-items-center">{{ sourceText }}</div>
  </div>
  <ul class="world-indicator__list d-flex flex-wrap align-items-end mb-3">
    <li class="world-indicator__item mb-3" v-for="item in itemsInternal" :key="item.title">
      <p class="world-indicator__item-title">{{ item.title }}</p>
      <div class="world-indicator__wrapper align-items-end">
        <strong>{{ formatNumber(item.value / delimiter, 0) }}&nbsp;</strong
        ><span class="d-block">{{ unitCost }} $</span>
      </div>
      <div class="world-indicator__wrapper mt-2">
        <i
          v-if="item.previousPercent != null && item.previousPercent < 0"
          class="fas fa-solid mr-1 text-red fa-arrow-circle-down"
        ></i>
        <i v-else-if="item.previousPercent" class="fas fa-solid mr-1 text-green fa-arrow-circle-up"> </i>
        <span class="world-indicator__info" :class="item.previousPercent > 0 ? 'text-green' : 'text-red'"
          >{{ (item.previousPercent > 0 ? '+' : '') + formatNumber(item.previousPercent, 1) }}%</span
        >
      </div>
    </li>
  </ul>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';
  import text from '@/common/mixins/text';

  export default {
    name: 'WorldIndicator',
    mixins: [text],
    components: { ComponentInput },
    props: {
      title: {
        type: String,
        default: '',
      },
      titleId: {
        type: String,
        default: 'indicator-title-id',
      },
      sourceText: {
        type: String,
        default: '',
      },
      items: {
        type: Array,
        default: () => [],
      },
      years: {
        type: Array,
        default: () => [],
      },
      delimiter: {
        type: Number,
        default: 1000000,
      },
      unitCost: {
        type: String,
        default: 'млн',
      },
    },
    emits: ['yearChanged'],
    data() {
      return {
        select2Settings: Object.assign({ minimumResultsForSearch: -1 }, Constants.select2SettingsNoClear),
        itemsInternal: [],
        yearsInternal: [],
        currentYear: null,
      };
    },
    created() {},
    watch: {
      items: {
        handler: function () {
          this.itemsInternal = this.items;
        },
        deep: true,
      },
      years: {
        handler: function () {
          this.yearsInternal = this.years;
          if (this.yearsInternal.length) this.currentYear = this.yearsInternal.slice(-1)[0].id;
        },
        deep: true,
      },
      currentYear: function () {
        if (this.currentYear) this.$emit('yearChanged', this.currentYear);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/modules/countryAnalyticsNew/styles/index';
  .world-indicator-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: #282b30;
  }

  .world-indicator-source-text {
    font-size: 14px;
    line-height: 16px;
    color: #aaaaaa;
  }

  .world-indicator-title,
  .world-indicator-source-text {
    margin-top: 8px;
  }

  .country-analytics-new__period-selector::v-deep {
    min-width: 116px;

    .select2 .select2-container .select2-selection__rendered {
      font-weight: 400;
      height: 100%;
      overflow: visible;
    }

    .select2 .select2-container--default .select2-selection {
      min-height: 36px !important;
    }
  }

  .world-indicator {
    &__list {
      flex-wrap: wrap;
      min-width: 540px;
      padding: 0;
    }
    &__item {
      max-width: 20%;
      min-width: 150px;
      width: 100%;

      &-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 113.9%;
        color: #333333;
        margin-bottom: 12px;
      }
    }
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: start;
      padding: 0;
      strong {
        display: block;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 30px;
        text-align: center;
        color: #333333;
        white-space: nowrap;
      }
      span {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #333333;
      }
      .world-indicator__info {
        display: inline-block;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
</style>
