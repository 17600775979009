import axios from 'axios';

export default {
  search: function (request) {
    return axios.get('/api/massmedia/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/massmedia/find/${id}`);
  },
  update: function (data) {
    if (data.id > 0) {
      return axios.put(`/api/massmedia/${data.id}`, data);
    } else {
      return axios.post('/api/massmedia', data);
    }
  },
  delete: function (id) {
    return axios.delete(`/api/massmedia/${id}`);
  },
  import: function (file) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`/api/massmedia/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  searchExternal: function (request) {
    return axios.get('https://trade-news.vavt.ru/api/events', { params: request });
  },
  getCountries: function () {
    return axios.get('https://trade-news.vavt.ru/api/get_countries');
  },
  getProductBranches: function () {
    return axios.get('https://trade-news.vavt.ru/api/get_product_branches');
  },
  getCategories: function () {
    return axios.get('https://trade-news.vavt.ru/api/get_relation_countries');
  },
};
