export function setCountries(state, res) {
  const { data = {} } = res;
  state.countries = data?.items || [];
}

export function setGroupsCountries(state, res) {
  const { data = {} } = res;
  state.groupsCountries = data?.items || [];
}

export function setTypeOfTradingOperation(state, res) {
  const { data = {} } = res;
  state.typeOfTradingOperation = data?.items || [];
}

export function setTransport(state, res) {
  const { data = {} } = res;
  state.transport = data?.items || [];
}

export function setTypeImport(state, res) {
  const { data = [] } = res;
  state.typeImport = data;
}

export function setProcessType(state, res) {
  const { data = [] } = res;
  state.processType = data.items;
}

export function setProcedure(state, res) {
  const { data = [] } = res;
  state.procedure = data.items;
}

export function setOperations(state, res) {
  const { data = [] } = res;
  state.operations = data.items;
}

export function setNsiCountry(state, res) {
  const { data = [] } = res;
  state.nsiCountryList = data;
}

export function setNsiCountryWithData(state, res) {
  const { data = [] } = res;
  state.nsiCountryListWithData = data;
}

export function setMeasureOrganProtectedMarket(state, res) {
  const { data = {} } = res;
  state.nsiMeasureOrganProtectedMarket = data.items;
}

export function setIndustry(state, res) {
  const { data = {} } = res;
  state.nsiIndustry = data.items;
}

export function setMoveDirections(state, res) {
  const { data = [] } = res;
  state.moveDirections = data;
}

export function setCountryCoverages(state, res) {
  const { data = [] } = res;
  state.countryCoverages = data;
}

export function setMeasureTypes(state, res) {
  const { data = [] } = res;
  state.measureTypes = data;
}

export function setTtrStatus(state, res) {
  const { data = [] } = res;
  state.ttrStatus = data.items;
}

export function setInvestigationType(state, res) {
  const { data = [] } = res;
  state.investigationType = data.items;
}

export function setRegions(state, res) {
  const { data = [] } = res;
  state.regions = data.items;
}

export function setPartnerCountry(state, res) {
  const { data = [] } = res;
  state.partnerCountry = data.items;
}

export function setRegionsSearchId(state, res) {
  const { data = [] } = res;
  state.regionsSearchId = data.items;
}

export function setPartnerCountrySearchId(state, res) {
  const { data = [] } = res;
  state.partnerCountrySearchId = data.items;
}

export function setFederalDistricts(state, res) {
  const { data = [] } = res;
  state.federalDistrictList = data.items;
}
