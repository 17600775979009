<template>
  <div class="search-input">
    <input class="search-input" type="text" v-model="innerTerm" v-on:keypress="search" />
    <svg
      class="search-icon"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="search"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7815 5.32568C18.8928 8.43694 18.8916 13.4944 15.7641 16.6219C12.6366 19.7494 7.57907 19.7506 4.46782 16.6394C1.35656 13.5281 1.35779 8.47064 4.48528 5.34315C7.61277 2.21565 12.6703 2.21443 15.7815 5.32568ZM17.7433 17.4229C21.0742 13.4946 20.8934 7.60916 17.1957 3.91147C13.3001 0.0158706 6.97631 0.0236897 3.07107 3.92893C-0.834175 7.83418 -0.841994 14.158 3.0536 18.0536C6.75122 21.7512 12.6365 21.9321 16.5647 18.6014L19.5699 21.6065C19.8953 21.932 20.423 21.932 20.7484 21.6065C21.0738 21.2811 21.0738 20.7534 20.7484 20.428L17.7433 17.4229Z"
        fill="#EAEAEA"
      />
    </svg>
  </div>
</template>

<script>
  export default {
    name: 'searchInputComponent',
    props: {
      term: {
        type: String,
      },
    },
    data() {
      return {
        innerTerm: this.term,
      };
    },
    methods: {
      search(e) {
        if (e.type === 'click' || (e.type === 'keypress' && e.keyCode === 13)) {
          this.$router.push({ name: 'Search', params: { term: this.innerTerm } });
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .search-input {
    position: relative;
    z-index: 1110;
    background: #ffffff;
    border: 1px solid #eaeaea;
    .search-icon {
      position: absolute;
      content: '';
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      z-index: 2000;
    }
    input {
      padding: 12px;
      width: 100%;
      max-width: 100%;
    }
  }
</style>
