export function setStepCard(state, res) {
  const { data = {} } = res;
  state.card = data;
}

export function setOrganizationList(state, res) {
  const { data = {} } = res;
  state.listOrganizations = data.items.map((item) => ({ id: item.id, text: item.name }));
}

export function setDocumentList(state, res) {
  const { data = {} } = res;
  state.listDocuments = data.items.map((item) => ({ id: item.id, text: item.name }));
}

export function setLegalSourceList(state, res) {
  const { data = {} } = res;
  state.listLegalSources = data.items.map((item) => ({ id: item.id, text: item.name }));
}
