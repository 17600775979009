<template>
  <!-- Тестовая страница для задачи -->
  <div class="container-page">
    <p class="title-inner">Календарь задач</p>

    <div class="tasks">
      <!-- Кнопки -->
      <div class="btns">
        <!-- switcher -->
        <div class="switcher">
          <p class="mb-6">Вид:</p>
          <ButtonStock @click="changeView(true)" btnType="white" :class="{ selected: isBoardView }" title="Карточки" />
          <ButtonStock @click="changeView(false)" btnType="white" :class="{ selected: !isBoardView }" title="Таблица" />
        </div>

        <ButtonStock
          @click="
            dialogNn++;
            dialogState = true;
          "
          title="Создать задачу"
          v-if="canEdit"
        />
        <ButtonStock @click="exportExcel" title="Экспорт" class="btn-small" />
        <div class="default-filter-container">
          <DefaultFilter :filterValue="currentFilter" @loadFilter="onLoadFilter" />
        </div>
      </div>

      <!-- Вид таблицы -->
      <div class="grand-parent" v-if="!isBoardView">
        <div class="view-table table-container js-table-arrow">
          <table class="stockTable">
            <thead>
              <tr>
                <th></th>
                <th>№ п/п</th>
                <th>Срочность</th>
                <th>Статус</th>
                <th>Задача</th>
                <th>Отчет об исполнении</th>
                <th>Инициатор</th>
                <th>Исполнитель</th>
                <th>Контрольный срок</th>
              </tr>
              <tr>
                <th>
                  <IconComponent name="time" />
                </th>
                <th class="filter">
                  <FilterInput placeholder="№" v-model="filter.id" v-on:valueChangedDebounced="changeFilter" />
                </th>
                <th class="filter">
                  <FilterInput
                    :select2Options="taskUrgencyList"
                    placeholder="Срочность..."
                    type="select2"
                    v-model="filter.taskUrgencyId"
                    :select2Settings="select2Settings"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    :select2Options="taskStatusList"
                    placeholder="Статус..."
                    type="select2"
                    v-model="filter.taskStatusId"
                    :select2Settings="select2Settings"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    placeholder="Задача..."
                    v-model="filter.description"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    placeholder="Отчет..."
                    v-model="filter.comment"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    :select2Options="managerList"
                    placeholder="Инициатор..."
                    type="select2"
                    v-model="filter.managerId"
                    :select2Settings="select2Settings"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter">
                  <FilterInput
                    :select2Options="employeeList"
                    placeholder="Исполнитель..."
                    type="select2"
                    v-model="filter.executorId"
                    :select2Settings="select2Settings"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
                <th class="filter nowrap">
                  <FilterInput
                    class="mb-6"
                    placeholder="от"
                    type="date"
                    v-model="filter.deadlineFrom"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                  <FilterInput
                    placeholder="до"
                    type="date"
                    v-model="filter.deadlineTo"
                    v-on:valueChangedDebounced="changeFilter"
                  />
                </th>
              </tr>
            </thead>
            <tbody v-if="page.items?.length">
              <tr v-for="item in page.items" :key="item.id">
                <td>
                  <div class="d-flex">
                    <IconComponent @click="editCard(item.id)" class="mr-8" name="empty_pen" />
                    <IconComponent @click="deleteCard(item.id)" v-if="canDelete(item)" name="delete" />
                  </div>
                </td>
                <td>{{ item.id }}</td>
                <td>{{ item.taskUrgency.name }}</td>
                <td>{{ item.taskStatus.name }}</td>
                <td>
                  {{ item.name }}
                  <div v-snip="3">{{ item.description }}</div>
                </td>
                <td>
                  <div v-snip="3">{{ item.comment }}</div>
                </td>
                <td>
                  {{ item.managerFio }}
                </td>
                <td>
                  {{ item.executorFio }}
                  <div>{{ item.executorDopFio }}</div>
                </td>
                <td>{{ $momentFormat(item.deadline, 'DD MMMM YYYY HH:mm') }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="page.pagesTotal > 1">
            <Pagination
              :page-count="page.pagesTotal"
              :current-page="page.pageNumber"
              @change="onPageChange($event, false)"
              @more="onPageChange($event, true)"
            />
          </div>
        </div>
      </div>

      <!-- Вид карточек -->
      <div v-else-if="isBoardView" class="view-cards">
        <!-- filter -->
        <p
          @click="filterShow = !filterShow"
          class="title-inner textDecorUnderLine cursorPointer"
          :class="{ 'filter-have-value': filterNotEmpty }"
        >
          Фильтрация данных
        </p>

        <div v-if="filterShow" class="cards-filter">
          <!-- input block -->
          <!-- date -->
          <div class="cards-filter__input-container">
            <p class="title">Контрольный срок</p>
            <div class="cards-filter__input-block">
              <p class="prefix">c</p>
              <div class="date">
                <dateComponent :clearable="true" v-model="filterBoard.deadlineFrom" />
              </div>
              <IconComponent name="dateRange" />
            </div>
            <div class="cards-filter__input-block">
              <p class="prefix">по</p>
              <div class="date">
                <dateComponent :clearable="true" v-model="filterBoard.deadlineTo" />
              </div>
              <IconComponent name="dateRange" />
            </div>
          </div>

          <!-- multiselect -->
          <div class="cards-filter__input-container">
            <p>Приоритет</p>
            <ComponentInput
              placeholder="Выберите приоритет"
              :select2Settings="select2Settings"
              :select2Options="taskUrgencyList"
              v-model="filterBoard.taskUrgencyId"
              type="select2"
            />
          </div>

          <!-- multiselect -->
          <div class="cards-filter__input-container">
            <p>Исполнитель</p>
            <ComponentInput
              placeholder="Выберите исполнителя"
              :select2Settings="select2Settings"
              :select2Options="employeeList"
              v-model="filterBoard.executorId"
              type="select2"
            />
          </div>

          <!-- textarea -->
          <div class="cards-filter__input-container">
            <p>Описание</p>
            <ComponentInput placeholder="Введите описание" v-model="filterBoard.description" type="text" />
          </div>
          <div class="cards-filter__input-container checkboxInput">
            <ComponentInput
              id="hideStatus"
              label="Скрывать задачи в статусе 'Готова' старше 3 месяцев"
              v-model="filterBoard.isHideOld"
              type="checkbox"
            />
          </div>

          <div class="btns">
            <ButtonStock btnType="secondary" title="Сброс" @click="clearBoard" />
            <ButtonStock title="Искать" @click="searchBoard" />
          </div>
        </div>

        <!-- Cards -->
        <div class="cards-container">
          <div class="cards-container-item" v-for="status in taskStatusList.filter((x) => x.id > 0)" :key="status.id">
            <p class="title-inner">{{ status.text }}</p>
            <div class="tasks-container">
              <!-- task -->
              <div
                class="tasks-item"
                v-for="item in tasks.filter((x) => x.taskStatusId == status.id)"
                :key="item.id"
                :class="[
                  { red: item.taskUrgencyId == 1 },
                  { yellow: item.taskUrgencyId == 2 },
                  { green: item.taskUrgencyId == 3 },
                ]"
              >
                <p class="tasks-item__name">
                  <span @click="editCard(item.id)">Задача № {{ item.id }}</span>
                  <br />
                  <span>{{ item.name }}</span>
                </p>
                <p class="tasks-item__about">{{ item.description }}</p>
                <div class="tasks-item__date" v-if="item.deadline">
                  <IconComponent name="time" />
                  <p>Срок {{ $momentFormat(item.deadline, 'DD MMMM YYYY HH:mm') }}</p>
                </div>
                <div class="tasks-item__footer">
                  <div class="arrows">
                    <span class="arrow" v-for="span in 4 - item.taskUrgencyId" :key="span.key"> </span>
                  </div>
                  <div class="author">
                    <div>
                      <p class="name">{{ item.executorFio }}</p>
                      <p class="date">{{ $momentFormat(item.created, 'DD MMMM YYYY') }}</p>
                    </div>
                    <img :src="getExecutorPicture(item.executorPicture)" :alt="item.executorFio" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit modal -->
    <ModalComponent headType="blue" title="Создать задачу" @close="dialogState = false" v-model="dialogState">
      <IssueCardAdd v-bind:nn="dialogNn" :isEap="true" v-on:save="afterSaveCard" v-on:close="dialogState = false" />
    </ModalComponent>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent.vue';
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import API from '../api/eap';
  import IssueCardAdd from '../components/IssueCardAdd.vue';

  export default {
    name: 'IssuesEap',
    components: {
      ButtonStock,
      IconComponent,
      ModalComponent,
      dateComponent,
      FilterInput,
      ComponentInput,
      IssueCardAdd,
      DefaultFilter,
    },
    mixins: [security, filtermanager],
    data() {
      return {
        isBoardView: false,
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          id: '',
          description: '',
          deadlineFrom: null,
          deadlineTo: null,
          executorId: null,
          taskStatusId: null,
          taskUrgencyId: null,
          comment: '',
          managerId: null,
        },
        filterBoard: {
          deadlineFrom: null,
          deadlineTo: null,
          taskUrgencyId: null,
          executorId: null,
          description: '',
          isHideOld: true,
          comment: '',
          managerId: null,
        },
        filterNotEmpty: false,
        page: {},
        canEdit: false,

        select2Settings: Constants.select2Settings,
        taskTypeList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        taskStatusList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        taskUrgencyList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        employeeList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        managerList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],

        filterShow: false,
        tasks: [],

        loadBoardView: false,
        dialogState: false,
        dialogNn: 1,
      };
    },
    created() {
      this.checkPermissionAndRedirect(Constants.Permissions.EapTaskAccess);

      let profile = this.$store.state.auth?.oidc?.profile;
      if (profile == null) profile = JSON.parse(localStorage.getItem('userProfileData'));
      this.canEdit = profile?.employee?.id > 0;
      if (this.canEdit) {
        this.checkPermission(Constants.Permissions.EapTaskEdit).then((response) => {
          this.canEdit = response.data;
        });
      }

      this.loadFilter().then((response) => {
        let filter = response;
        if (filter) {
          this.filter = filter.filter;
          this.filterBoard = filter.filterBoard;
        }
        this.filterNotEmpty = this.isFilterNotEmpty(this.filterBoard, ['isHideOld', 'pageNumber']);

        this.loadPage(false);
        this.loadEmployee();
        this.loadManager();

        Utils.loadSelectOptions('taskTypeList', this.taskTypeList);
        //Utils.loadSelectOptions("taskStatusList", this.taskStatusList);

        this.checkPermission(Constants.Permissions.EapTaskViewAllStatus).then((response) => {
          const skipStatus = response.data ? 0 : 1;
          Utils.searchNsi('taskStatusList', {}).then((response) => {
            this.taskStatusList = response.data.slice(skipStatus);
          });
        });

        Utils.loadSelectOptions('taskUrgencyList', this.taskUrgencyList);
      });
    },
    methods: {
      canDelete(item) {
        const profile = Object.assign({}, this.$store.state.auth?.oidc?.profile);
        if (profile?.roleIds != null && profile?.roleIds.includes(1)) return true;
        return item.createdById == profile?.id;
      },
      changeView(isBoard) {
        this.isBoardView = isBoard;
        if (this.isBoardView && !this.loadBoardView) this.searchBoard();
      },
      searchBoard() {
        this.saveFilter(this.currentFilter);
        this.filterNotEmpty = this.isFilterNotEmpty(this.filterBoard, ['isHideOld', 'pageNumber']);
        const params = Object.assign(this.filterBoard, { pageNumber: 1, pageSize: 0 });
        API.search(params).then((response) => {
          this.loadBoardView = true;
          this.tasks = response.data.items;
        });
      },
      clearBoard() {
        this.filterBoard = {
          deadlineFrom: null,
          deadlineTo: null,
          taskUrgencyId: null,
          executorId: null,
          description: '',
          isHideOld: true,
          comment: '',
          managerId: null,
        };
        this.searchBoard();
      },
      loadEmployee() {
        API.getEmployeeList().then((response) => {
          if (response.data) {
            response.data.forEach((el) => {
              this.employeeList.push(el);
            });
          }
        });
      },
      loadManager() {
        API.getManagerList().then((response) => {
          if (response.data) {
            response.data.forEach((el) => {
              this.managerList.push(el);
            });
          }
        });
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(addToExist) {
        const params = Object.assign(this.filter, this.request);
        API.search(params).then((response) => {
          if (!addToExist) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            //добавляем к существующему
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
        });
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.currentFilter);
        this.loadPage(false);
      },
      exportExcel() {
        const params = Object.assign(this.filter, this.request);
        API.exportExcel(params);
      },
      afterSaveCard() {
        this.dialogState = false;
        this.loadPage(false);
        if (this.isBoardView) this.searchBoard();
        else this.loadBoardView = false;
      },
      editCard(id) {
        this.$router.push({ name: 'IssueEapCard', params: { id: id, action: this.canEdit ? 'edit' : 'view' } });
      },
      deleteCard(id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            API.delete(id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage();
                this.loadBoardView = false;
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена',
                  'error',
                );
              });
          }
        });
      },
      getExecutorPicture(pic) {
        return pic ? pic : require('@/assets/images/no_photo.png');
      },
      onLoadFilter(item) {
        this.filter = item.filter;
        this.filterBoard = item.filterBoard;
        this.changeFilter();
      },
    },
    computed: {
      currentFilter() {
        let filter = {
          filter: this.filter,
          filterBoard: this.filterBoard,
        };
        return filter;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .filter-have-value {
    color: red;
  }

  .tasks {
    .btns {
      display: flex;
      align-items: flex-end;
      margin-bottom: 42px;

      button {
        margin-right: 16px;
      }
    }

    &-container {
      max-height: 612px;
      overflow-y: auto;
      padding-right: 3px;
    }
  }

  .view {
    &-table {
      table {
        .icon {
          opacity: 0.5;
        }

        thead {
          th {
            padding: 12px !important;
            max-width: 155px;

            &:nth-of-type(1) {
              width: 60px;
            }

            input {
              width: 100%;
            }
          }
        }

        tbody {
          td {
            padding: 12px !important;

            &:nth-of-type(1) {
              height: 61.5px;
            }
          }
        }
      }
    }
  }

  .cards {
    &-filter {
      max-width: 980px;
      width: 100%;

      &__input {
        &-container {
          margin-bottom: 16px;

          &:nth-of-type(1) {
            display: flex;
            flex-wrap: wrap;

            .title {
              width: 100%;
              margin-bottom: 16px;
            }

            .cards-filter__input-block {
              position: relative;
              display: flex;
              align-items: flex-end;

              .date {
                max-width: 150px;
                width: 100%;
                margin-left: 8px;

                &:not(:last-of-type) {
                  margin-right: 8px;
                }
              }

              .icon {
                position: absolute;
                bottom: 12px;
                right: 12px;
              }
            }
          }

          textarea {
            width: 100%;
          }
        }
      }
    }

    &-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &-item {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
        max-width: 274px;
        width: 100%;
        background: rgba(232, 232, 232, 0.3);
        padding: 18px 10px 0px 10px;

        .title-inner {
          margin-bottom: 14px;
        }
      }

      .tasks-container {
        max-height: 612px;
        overflow-y: auto;
        padding-right: 3px;
      }

      .tasks-item {
        font-family: 'Fira Sans', sans-serif;
        padding: 12px 18px;
        height: 220px;
        width: 100%;
        border: 2px solid black;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);

        &:not(:last-of-type) {
          margin-bottom: 12px;
        }

        &__name {
          margin-bottom: 8px;

          span {
            font-weight: 700;
          }
        }

        &__about {
          margin-bottom: 10px;

          /* 3 точие */
          overflow: hidden;
          text-overflow: ellipsis;
          display: -moz-box;
          -moz-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          line-clamp: 4;
          box-orient: vertical;
        }

        &__date {
          display: flex;
          align-items: center;
          margin-bottom: 12px;

          .icon {
            color: #757575;
            margin-right: 8px;
          }
        }

        &__footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .arrows {
            .arrow {
              display: block;
              width: 10px;
              height: 10px;
              border: 3px solid;
              border-color: black;
              border-bottom: none;
              border-right: none;
              transform: rotate(45deg);
            }
          }

          .author {
            display: flex;
            font-size: 12px;

            .date {
              font-size: 10px;
            }

            img {
              margin-left: 8px;
              width: 34px;
              height: 34px;
              object-fit: cover;
              border-radius: 50%;
            }
          }
        }

        &__about,
        &__date {
          font-size: 12px;
        }

        &.red {
          border-color: #dd2a25;

          .tasks-item__footer .arrows .arrow {
            border-color: #dd2a25;
          }
        }

        &.yellow {
          border-color: #fcad30;

          .tasks-item__footer .arrows .arrow {
            border-color: #fcad30;
          }
        }

        &.green {
          border-color: #00c6a2;

          .tasks-item__footer .arrows .arrow {
            border-color: #00c6a2;
          }
        }
      }
    }
  }
  .default-filter-container::v-deep {
    .button-container {
      align-items: flex-end;
      margin-bottom: 0 !important;
    }
  }

  @media (max-width: 1240px) {
    .view-cards {
      overflow-x: auto;
    }

    .cards-container {
      min-width: 1180px;
    }
  }

  @media (max-width: 768px) {
    .view-cards {
      overflow-x: initial;
    }

    .cards-container {
      min-width: unset;
    }

    .cards-container-item {
      max-width: unset;
      margin-bottom: 46px;
    }
  }

  @media (max-width: 450px) {
    .tasks .btns {
      flex-wrap: wrap;
    }

    .switcher {
      width: 100%;

      button {
        margin-bottom: 8px;

        &:last-of-type {
          margin-right: 0px;
        }
      }
    }

    .cards-filter__input-container:nth-of-type(1) .cards-filter__input-block {
      margin-bottom: 8px;
    }
  }

  @media (max-width: 375px) {
    .cards-filter__input-container:nth-of-type(1) .cards-filter__input-block {
      .prefix {
        width: 18px;
      }
    }
  }
</style>

<style lang="scss">
  @media (max-width: 450px) {
    .default-filter-container {
      button {
        margin: 12px 0px;
      }
    }
  }
</style>
