export const routes = [
  {
    path: '/navigator/scenarios',
    name: 'Scenarios',
    component: () => import('../views/Scenarios'),
  },
  {
    path: '/navigator/scenarios/edit/:id',
    name: 'ScenarioCard',
    component: () => import('../views/ScenarioCard'),
  },
  {
    path: '/navigator/stages',
    name: 'Stages',
    component: () => import('../views/Stages'),
  },
  {
    path: '/navigator/stages/edit/:id',
    name: 'StageCard',
    component: () => import('../views/StageCard'),
  },
  {
    path: '/navigator/procedures',
    name: 'Procedures',
    component: () => import('../views/Procedures'),
  },
  {
    path: '/navigator/procedures/edit/:id',
    name: 'ProcedureCard',
    component: () => import('../views/ProcedureCard'),
  },
  {
    path: '/navigator/steps',
    name: 'Steps',
    component: () => import('../views/Steps'),
  },
  {
    path: '/navigator/steps/edit/:id',
    name: 'StepCard',
    component: () => import('../views/StepCard'),
  },
  {
    path: '/navigator/documents',
    name: 'NavigatorDocuments',
    component: () => import('../views/Documents'),
  },
  {
    path: '/navigator/documents/edit/:id',
    name: 'NavigatorDocumentCard',
    component: () => import('../views/DocumentCard'),
  },
  {
    path: '/navigator/legalsources',
    name: 'LegalSources',
    component: () => import('../views/LegalSources'),
  },
  {
    path: '/navigator/legalsources/edit/:id',
    name: 'LegalSourceCard',
    component: () => import('../views/LegalSourceCard'),
  },
  {
    path: '/navigator/organizations',
    name: 'NavOrgs',
    component: () => import('@/modules/admin/views/Organizations'),
  },
  {
    path: '/navigator/organizations/:action/:id',
    name: 'NavOrgCard',
    component: () => import('@/modules/admin/views/OrganizationCard'),
  },
  {
    path: '/navigator/registry',
    name: 'NavigatorRegistry',
    component: () => import('../views/Registry'),
  },
  {
    path: '/navigator/registry/:action/:id',
    name: 'NavigatorRegistryCard',
    component: () => import('../views/RegistryCard'),
  },
  {
    path: '/navigator/stepActions',
    name: 'StepActions',
    component: () => import('../views/StepActions'),
  },
  {
    path: '/navigator/product-groups',
    name: 'ProductGroups',
    component: () => import('../views/ProductGroups'),
  },
  {
    path: '/navigator/product-groups/:id/:type',
    name: 'ProductGroupsCard',
    component: () => import('../views/ProductGroupsCard'),
  },
];
