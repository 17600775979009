<template>
  <div v-if="summary" class="investments">
    <template v-if="!notForTecRussiaTab">
      <div class="d-flex align-items-center justify-content-end">
        <p class="mr-6">Источник: ЮНКТАД</p>
        <Multiselect
          class="select-country-analytic"
          label="text"
          :searchable="true"
          v-model="selectDate"
          valueProp="id"
          track-by="text"
          noResultsText="Ничего не найдено."
          noOptionsText="Список пуст."
          :options="dateOptions"
          :canClear="false"
          placeholder="Период"
          @select="yearChanged"
        />
      </div>
      <div class="d-flex align-items-center mb-3">
        <IconComponent name="award" />
        <h2 class="ml-2">
          Позиция в рейтинге стран мира по входящим ПИИ за год
          <span class="font-weight-normal">
            -
            {{ summary.inFlowsPlace ? summary.inFlowsPlace + ' место' : ' Нет данных.' }}
          </span>
        </h2>
      </div>
      <div class="d-flex align-items-center">
        <IconComponent name="award" />
        <h2 class="ml-2">
          Позиция в рейтинге стран мира по исходящим ПИИ за год
          <span class="font-weight-normal">
            -
            {{ summary.outFlowsPlace ? summary.outFlowsPlace + ' место' : ' Нет данных.' }}
          </span>
        </h2>
      </div>
    </template>
    <h3 class="my-5">Ключевые показатели</h3>
    <ul v-if="indicatorsListFilter?.length" class="indicators__list">
      <li v-for="item in indicatorsListFilter" :key="item.id" class="indicators__item">
        <p class="indicators__item-title">{{ item.title }}</p>
        <p class="indicators__item-period" v-if="item.period">на {{ item.period }} г.</p>
        <div class="d-block align-items-center">
          <strong class="d-block">
            {{ item.count == 0 ? '-' : item.count }}
          </strong>
          <span class="d-block">млн $ США</span>
        </div>
        <div class="award" v-if="item.awards">
          <p>1 место - {{ summary.inFlowsLeaderCountry }}</p>
          <p>
            {{ summary.inFlowsRussiaPlace }} место - Россия
            <span v-if="summary.inFlowsRussiaValue">({{ summary.inFlowsRussiaValue.toFixed(2) }})</span>
          </p>
        </div>
      </li>
    </ul>
    <div class="d-flex justify-content-between pie-indicators">
      <div>
        <h3 class="my-5 text-center">Слияния и поглощения</h3>
        <div class="d-flex pie-charts justify-content-between">
          <InvestmentsValueVolume
            description="Стоимостной объем международных СиП (продажи)"
            :summaryValue="summary?.maSales"
          />
          <InvestmentsValueVolume
            description="Стоимостной объем международных СиП (приобретения)"
            :summaryValue="summary.maPurchases"
            :iconStyle="{ transform: 'rotate(180deg)' }"
          />
        </div>
      </div>
      <div>
        <h3 class="my-5 text-center">Инвестиции в новые проекты</h3>
        <div class="d-flex pie-charts justify-content-between">
          <InvestmentsValueVolume
            description="Стоимостной объем инвестиций в новые проекты (из страны)"
            :summaryValue="summary.outProjects"
            icon="pie-chart2"
          />
          <InvestmentsValueVolume
            description="Стоимостной объем инвестиций в новые проекты (в страну)"
            :summaryValue="summary.inProjects"
            icon="pie-chart2"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Multiselect from '@vueform/multiselect';

  import text from '@/common/mixins/text';
  import IconComponent from '@/common/ui/IconComponent';

  import Api from '../../api';
  import InvestmentsValueVolume from './InvestmentsValueVolume.vue';

  export default {
    name: 'investments',
    components: { IconComponent, Multiselect, InvestmentsValueVolume },
    mixins: [text],
    props: {
      countryId: {
        type: String,
        default: '',
      },
      notForTecRussiaTab: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        summary: null,
        indicatorsList: [],
        dateOptions: [],
        selectDate: null,
      };
    },
    computed: {
      indicatorsListFilter() {
        return this.indicatorsList.filter((item) => item.count != '');
      },
    },
    created() {
      Api.getInvestementYearList(this.countryId).then((response) => {
        this.dateOptions = response.data;
        this.selectDate = this.dateOptions[0].id * 1;

        this.loadSummary();
      });
      // const year = (new Date()).getFullYear() - 2;
      // this.dateOptions.push({
      //   id: year,
      //   text: `${year} год`
      // });
      // this.selectDate = year;
    },
    methods: {
      loadSummary() {
        Api.getInvestmentSummary(this.countryId, this.selectDate).then((response) => {
          this.summary = response.data;

          this.indicatorsList = [
            {
              id: 1,
              title: 'Входящие ПИИ,\n' + 'потоки',
              count: this.formatNumber(this.summary.inFlows, 0),
              awards: true,
            },
            {
              id: 2,
              title: 'Исходящие ПИИ,\n' + 'потоки',
              count: this.formatNumber(this.summary.outFlows, 0),
            },
            {
              id: 3,
              title: 'Входящие ППИ,\n' + 'запас',
              count: this.formatNumber(this.summary.inStock, 0),
            },
            {
              id: 4,
              title: 'Исходящие ППИ,\n' + 'запас',
              count: this.formatNumber(this.summary.outStock, 0),
            },
            {
              id: 5,
              title: 'Международная\n' + 'инвестиционная позиция',
              count: this.formatNumber(this.summary.investmentPosition?.value, 0),
              period: this.summary.investmentPosition?.year,
            },
            // {
            //   id: 6,
            //   title: "Стоимостной объем международных СиП (продажи)",
            //   count: this.formatNumber(this.summary.maSales),
            // },
            // {
            //   id: 7,
            //   title: "Стоимостной объем международных СиП (приобретения)",
            //   count: this.formatNumber(this.summary.maPurchases)
            // },
            // {
            //   id: 8,
            //   title: "Стоимостной объем инвестиций в новые проекты (из страны)",
            //   count: this.formatNumber(this.summary.investmentPosition?.value)
            // },
            // {
            //   id: 9,
            //   title: "Стоимостной объем инвестиций в новые проекты (в страну)",
            //   count: this.formatNumber(this.summary.investmentPosition?.value)
            // },
          ];
        });
      },
    },
    watch: {
      countryId: function () {
        this.loadSummary();
      },
      selectDate: function () {
        this.loadSummary();
      },
    },
  };
</script>

<style scoped lang="scss">
  @import './src/assets/theme/default/colors';
  @import '../../styles/countryAnalyticsMultiselect.scss';
  .investments {
    .select-country-analytic {
      width: 150px !important;
      margin: 0;
    }
    .select-country-analytic.is-active {
      border-color: transparent !important;
    }
  }
  .pie-indicators {
    margin-top: 40px;
    h3 {
      font-family: 'Ubuntu', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #333333;
    }
  }
  .indicators__item-period {
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #333333;
  }
  .indicators {
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 32px 90px;
    }
    &__item {
      max-width: 150px;
      width: 100%;
      padding: 15px;
      background: $white;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      border: 1px solid #06a0d8a9;
      .award {
        margin-top: 30px;
        p {
          font-family: 'Fira Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          color: #333333;
        }
      }
      strong {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #333333;
        white-space: nowrap;
      }
      span {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #333333;
      }
      &-title {
        text-align: center;
        margin-bottom: 24px;
        font: $fira-12;
        font-style: normal;
        line-height: 113.9%;
        color: #333333;
      }
    }
  }
  h2 {
    font: $fira-14-B;
    font-style: normal;
    color: $base;
  }
  h3 {
    font-family: 'Ubuntu', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #333333;
  }

  @media (max-width: $mobile) {
    .indicators__item {
      max-width: 100%;
      text-align: center;
      div {
        justify-content: center;
      }
    }

    .pie-charts {
      flex-direction: column;
    }
  }
</style>
