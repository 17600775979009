import constants from '@/common/constants';

// import { TRADING_OPERATION_ID } from '../../modules/navigator/utils/constants';

export function getCountriesSelect(state) {
  return state.countries.map((x) => ({ text: x.name, id: x.id }));
}

export function getCountriesMultiSelect(state) {
  return state.countries.map((x) => ({ label: x.name, value: x.id }));
}

export function getGroupsCountriesMultiSelect(state) {
  return state.groupsCountries.map((x) => ({ label: x.name, value: x.id }));
}

export function getCountryNoRussia(state) {
  return state.countries.map((x) => ({ text: x.name, id: x.id })).filter((i) => i.id != constants.countryRussiaId);
}

export function getTypeOfTradingOperationList(state) {
  return state.typeOfTradingOperation.map((x) => ({ text: x.name, id: x.id }));
  // .filter((i) => i.id !== Number(TRADING_OPERATION_ID.transit));
}

export function getTypeOfTradingOperationMultiSelect(state) {
  return state.typeOfTradingOperation.map((x) => ({ label: x.name, value: x.id }));
  // .filter((i) => i.value !== Number(TRADING_OPERATION_ID.transit));
}

export function getTransportList(state) {
  return state.transport.map((x) => ({ text: x.name, id: x.id }));
}

export function getTypeImportSelect(state) {
  return state.typeImport;
}

export function getTypeImportMultiSelect(state) {
  return state.typeImport.map((x) => ({ label: x.text, value: x.id }));
}

export function getTypeProcess(state) {
  return state.processType.map((x) => ({ label: x.name, value: x.id }));
}

export function getProcedureList(state) {
  return state.procedure.map((x) => ({ label: x.name, value: x.id }));
}

export function getOperationsList(state) {
  return state.operations.map((x) => ({ label: x.name, value: x.id }));
}

export function getNsiCountryList(state) {
  return state.nsiCountryList;
}

export function getNsiCountryListWithData(state) {
  return state.nsiCountryListWithData;
}

export function getNSIMeasureOrganProtectedMarketList(state) {
  return state.nsiMeasureOrganProtectedMarket.map((x) => ({ label: x.name, value: x.id }));
}

export function getNSIIndustryList(state) {
  return state.nsiIndustry.map((x) => ({ label: x.name, value: x.id }));
}

export function getNSIMoveDirectionsList(state) {
  return state.moveDirections.map((x) => ({ label: x.text, value: x.id }));
}

export function getNSICountryCoveragesList(state) {
  return state.countryCoverages.map((x) => ({ label: x.text, value: x.id }));
}

export function getNSIMeasureTypesList(state) {
  return state.measureTypes.map((x) => ({ label: x.text, value: x.id }));
}

export function getTtrStatusGroupOptions(state) {
  const keyGetter = (x) => ({
    id: x.tradingOperationId,
    name: x.tradingOperation.name,
  });
  const groupArray = [];
  state.ttrStatus.forEach((item) => {
    const key = keyGetter(item);
    if (key.id > 0) {
      let group = groupArray.find((x) => x.idName === key.id);
      if (!group) {
        group = { idName: key.id, label: key.name, options: [] };
        groupArray.push(group);
      }
      const child = { value: item.id, label: item.name };
      group.options.push(child);
    } else if (item.isLeaf) {
      groupArray.push({ value: item.id, label: item.name });
    }
  });
  return groupArray;
}

export function getNSIInvestigationTypeList(state) {
  return state.investigationType.map((x) => ({ label: x.name, value: x.id }));
}

// Возвращает список регионов, заменяя id кодом региона
export function getRegionsList(state) {
  return state.regions.map((x) => ({ ...x, id: x.regionCodes[0] }));
}

// Возвращает список регионов с id, которые приходят из бэка
export function getRegionsListWithId(state) {
  // Добавил в объект поле text, для корректной работы селекта Select2 в карточке создания пользователя
  return state.regions.map((region) => ({ ...region, text: region.name }));
}

export function getPartnerCountryList(state) {
  return state.partnerCountry;
}

export function getRegionsSearchIdList(state) {
  return state.regionsSearchId;
}

export function getPartnerCountrySearchIdList(state) {
  return state.partnerCountrySearchId;
}

export function getFederalDistrictList(state) {
  return state.federalDistrictList;
}
