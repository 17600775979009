import axios from 'axios';

import { authAxios } from '@/common/axios';

import getFormData from './formData';
/**
 * Обший универсальный запрос
 * @param {Vuex} context контекст vuex
 * @param {Object} settings настройки
 * @param {String} settings.url путь запроса
 * @param {String} settings.method тип запроса, по умолчанию GET
 * @param {String} settings.mutate название мутации, если есть то примениться
 * @param {String} settings.name название метода в котором был произведен вызов
 * @param {String[]} settings.pathParams параметры которые нужно включить в путь
 * @param {Boolean[]} settings.deleteParamsInPath если необходимо исключить перечисляемые параметры в pathParams из params
 * @param {Object} params параметры для запроса
 * @param {Boolean} settings.requestAuth меняет host запроса с admin на auth
 * @param {Object} isFormData создает обьект FormData, обращается к методу getFormData
 * @returns {Object} результат
 */
export default async function request(context, settings, params) {
  const { commit } = context;
  const {
    method = 'get',
    mutate,
    name = '',
    pathParams = [],
    requestAuth = false,
    isFormData = false,
    deleteParamsInPath = true,
  } = settings;
  let { url } = settings;
  const config = {};
  let result = null;
  let dataSend = null;
  const typeRequest = requestAuth ? authAxios : axios;

  if (pathParams.length) {
    url = pathParams.reduce((total, paramName) => {
      if (paramName in params) {
        total += `/${params[paramName]}`;
      }

      return total;
    }, url);

    if (deleteParamsInPath) {
      params = Object.keys(params)
        .filter((key) => !pathParams.includes(key))
        .reduce((total, key) => ({ ...total, [key]: params[key] }), {});
    }
  }

  if (isFormData) {
    config.headers = { 'Content-Type': 'multipart/form-data' };
    dataSend = getFormData(params);
  } else {
    dataSend = params;
  }

  try {
    if (method === 'post' || method === 'put' || method === 'patch') {
      result = await typeRequest[`${method}`](url, dataSend, config);
    } else {
      result = await typeRequest[`${method}`](url, { params });
    }

    if (mutate && commit) {
      commit(mutate, result);
    }

    return { ...result, success: true };
  } catch (error) {
    console.error(`Ошибка: Неполучилось выполнить метод: ${name}`);
    console.error('Формат данных который был получен: ', result);
    console.error(error);
    console.log('result: ', result);
    console.log('error: ', error);
    return {
      data: result,
      error,
      success: false,
    };
  }
}
