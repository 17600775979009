import constants from '@/common/constants';

import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_MUTATIONS_NAME } from '.';

import links from '@/utils/links';
import request from '@/utils/request';

export async function getCountries(context) {
  if (!context.state?.countries?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.countries,
        mutate: DIRECTIVES_MUTATIONS_NAME.setCountries,
        name: DIRECTIVES_ACTIONS_NAME.getCountries,
        requestAuth: true,
      },
      constants.paramsFullListDefault,
    );
  }
}

export async function getGroupsCountries(context) {
  if (!context.state?.groupsCountries?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.groupsCountries,
        mutate: DIRECTIVES_MUTATIONS_NAME.setGroupsCountries,
        name: DIRECTIVES_ACTIONS_NAME.getGroupsCountries,
        requestAuth: true,
      },
      constants.paramsFullListDefault,
    );
  }
}

export async function getTypeOfTradingOperation(context) {
  if (!context.state?.typeOfTradingOperation?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.typeOfTradingOperation,
        mutate: DIRECTIVES_MUTATIONS_NAME.setTypeOfTradingOperation,
        name: DIRECTIVES_ACTIONS_NAME.getTypeOfTradingOperation,
      },
      constants.paramsFullListDefault,
    );
  }
}

export async function getTransport(context) {
  if (!context.state?.transport?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.transport,
        mutate: DIRECTIVES_MUTATIONS_NAME.setTransport,
        name: DIRECTIVES_ACTIONS_NAME.getTransport,
      },
      constants.paramsFullListDefault,
    );
  }
}

export async function getTypeImport(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.directives.typeImport,
      mutate: DIRECTIVES_MUTATIONS_NAME.setTypeImport,
      name: DIRECTIVES_ACTIONS_NAME.getTypeImport,
    },
    {
      ...params,
      ...constants.paramsFullListDefault,
    },
  );
}

export async function getProcessType(context, params) {
  if (!context.state?.processType?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.processType,
        mutate: DIRECTIVES_MUTATIONS_NAME.setProcessType,
        name: DIRECTIVES_ACTIONS_NAME.getProcessType,
      },
      {
        ...params,
        ...constants.paramsFullListDefault,
      },
    );
  }
}

export async function getProcedure(context) {
  if (!context.state?.procedure?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.procedure,
        mutate: DIRECTIVES_MUTATIONS_NAME.setProcedure,
        name: DIRECTIVES_ACTIONS_NAME.getProcedure,
      },
      {
        pageNumber: 1,
        pageSize: 0,
        isTemplate: true,
      },
    );
  }
}

export async function getNsiCountry(context) {
  if (!context.state?.nsiCountryList?.length) {
    return await request.call(this, context, {
      url: links.directives.nsiCountry,
      mutate: DIRECTIVES_MUTATIONS_NAME.setNsiCountry,
      name: DIRECTIVES_ACTIONS_NAME.getNsiCountry,
    });
  }
}
export async function getNsiCountryWithData(context) {
  if (!context.state?.nsiCountryListWithData?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.nsiCountryWithData,
        mutate: DIRECTIVES_MUTATIONS_NAME.setNsiCountryWithData,
        name: DIRECTIVES_ACTIONS_NAME.getNsiCountryWithData,
      },
      {
        pageNumber: 1,
        pageSize: 0,
        isTemplate: true,
      },
    );
  }
}

export async function getOperations(context) {
  if (!context.state?.operations?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.operations,
        mutate: DIRECTIVES_MUTATIONS_NAME.setOperations,
        name: DIRECTIVES_ACTIONS_NAME.getOperations,
      },
      {
        ...constants.paramsFullListDefault,
        isTemplate: true,
      },
    );
  }
}

export async function getNSIMeasureOrganProtectedMarket(context, params) {
  if (!context.state?.nsiMeasureOrganProtectedMarket?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.nsiMeasureOrganProtectedMarketSearch,
        mutate: DIRECTIVES_MUTATIONS_NAME.setMeasureOrganProtectedMarket,
        name: DIRECTIVES_ACTIONS_NAME.getNSIMeasureOrganProtectedMarket,
      },
      {
        ...params,
        ...constants.paramsFullListDefault,
      },
    );
  }
}

export async function getNSIIndustry(context, params) {
  if (!context.state?.nsiIndustry?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.nsiIndustry,
        mutate: DIRECTIVES_MUTATIONS_NAME.setIndustry,
        name: DIRECTIVES_ACTIONS_NAME.getNSIIndustry,
      },
      {
        ...params,
        ...constants.paramsFullListDefault,
      },
    );
  }
}

export async function getNSIMoveDirections(context, params) {
  if (!context.state?.moveDirections?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.nsiMoveDirections,
        mutate: DIRECTIVES_MUTATIONS_NAME.setMoveDirections,
        name: DIRECTIVES_ACTIONS_NAME.getNSIMoveDirections,
      },
      {
        ...params,
        ...constants.paramsFullListDefault,
      },
    );
  }
}

export async function getNSICountryCoverages(context, params) {
  if (!context.state?.countryCoverages?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.nsiCountryCoverages,
        mutate: DIRECTIVES_MUTATIONS_NAME.setCountryCoverages,
        name: DIRECTIVES_ACTIONS_NAME.getNSICountryCoverages,
      },
      {
        ...params,
        ...constants.paramsFullListDefault,
      },
    );
  }
}

export async function getNSIMeasureTypes(context, params) {
  if (!context.state?.measureTypes?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.nsiMeasureTypes,
        mutate: DIRECTIVES_MUTATIONS_NAME.setMeasureTypes,
        name: DIRECTIVES_ACTIONS_NAME.getNSIMeasureTypes,
      },
      params,
    );
  }
}

export async function getNSITtrStatus(context, params) {
  if (!context.state?.ttrStatus?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.nsiRequestTtrStatus,
        mutate: DIRECTIVES_MUTATIONS_NAME.setTtrStatus,
        name: DIRECTIVES_ACTIONS_NAME.getNSITtrStatus,
      },
      {
        ...params,
        ...constants.paramsFullListDefault,
      },
    );
  }
}

export async function getNSIInvestigationType(context, params) {
  if (!context.state?.investigationType?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.nsiInvestigationType,
        mutate: DIRECTIVES_MUTATIONS_NAME.setInvestigationType,
        name: DIRECTIVES_ACTIONS_NAME.getNSIInvestigationType,
      },
      {
        ...params,
        ...constants.paramsFullListDefault,
      },
    );
  }
}

// если нет необходимости фильтровать регионы и партнеры страны
export async function getRegions(context, params) {
  if (!context.state?.regions?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.regions,
        mutate: DIRECTIVES_MUTATIONS_NAME.setRegions,
        name: DIRECTIVES_ACTIONS_NAME.getRegions,
      },
      {
        ...constants.paramsFullListDefault,
        ...params,
      },
    );
  }
}

export async function getPartnerCountry(context, params) {
  if (!context.state?.partnerCountry?.length) {
    return await request.call(
      this,
      context,
      {
        url: links.directives.partnerCountry,
        mutate: DIRECTIVES_MUTATIONS_NAME.setPartnerCountry,
        name: DIRECTIVES_ACTIONS_NAME.getPartnerCountry,
      },
      {
        ...constants.paramsFullListDefault,
        ...params,
      },
    );
  }
}

// если есть необходимость фильтровать регионы и партнеры страны
export async function getRegionsSearchId(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.directives.regions,
      mutate: DIRECTIVES_MUTATIONS_NAME.setRegionsSearchId,
      name: DIRECTIVES_ACTIONS_NAME.getRegionsSearchId,
    },
    {
      ...constants.paramsFullListDefault,
      ...params,
    },
  );
}

export async function getPartnerCountrySearchId(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.directives.partnerCountry,
      mutate: DIRECTIVES_MUTATIONS_NAME.setPartnerCountrySearchId,
      name: DIRECTIVES_ACTIONS_NAME.getPartnerCountrySearchId,
    },
    {
      ...constants.paramsFullListDefault,
      ...params,
    },
  );
}
export async function getFederalDistricts(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.directives.federalDistricts,
      mutate: DIRECTIVES_MUTATIONS_NAME.setFederalDistricts,
      name: DIRECTIVES_ACTIONS_NAME.getFederalDistricts,
    },
    {
      ...constants.paramsFullListDefault,
      ...params,
    },
  );
}
