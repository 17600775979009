<template>
  <div class="payment-indicators-item">
    <h2 class="title title--payments-analytics">{{ titleName }} — {{ countryName }}</h2>
    <div v-if="getIsShowingFilters" class="payment-indicators-item__wrapper-filter">
      <div v-if="optionList.length" class="payment-indicators-item__wrapper-filter-item">
        <div class="payment-indicators-item__select">
          <label class="label-field">{{ labelSelect }}</label>
          <SelectMultiComponent v-model="balance" :options="optionList" placeholder="Выберите" modifier="rectangular" />
        </div>
      </div>
      <div v-if="yearList.length" class="payment-indicators-item__select payment-indicators-item__select_period">
        <label class="label-field">Период</label>
        <div class="payment-indicators-item__wrapper-filter-item">
          <SelectMultiComponent
            v-model="filters.start"
            :options="yearList"
            placeholder="Выберите"
            modifier="rectangular"
            :classSelect="{ error: v$.filters.$error }"
          />
          <SelectMultiComponent
            v-model="filters.end"
            :options="yearList"
            placeholder="Выберите"
            modifier="rectangular"
            :classSelect="{ error: v$.filters.$error }"
          />
        </div>

        <div class="invalid-msg" v-if="v$.filters.$error && v$.filters.end.isAfterStartDate.$invalid">
          Некорректный период
        </div>
      </div>
      <ButtonComponent
        @click="applyParameters"
        mod="gradient-bg"
        :disabled="disabledSearch"
        class="button-redesign--mobile-full"
        >Показать</ButtonComponent
      >
    </div>

    <div class="payment-indicators-item__block-graph">
      <h3 class="title title--graph-payments">Млрд $</h3>
      <div class="payment-indicators-item__inner-wrap">
        <div v-if="load" class="payment-indicators-item__loader-wrap">
          <Loader />
        </div>
        <GroupGraphPayments
          v-else
          :seriesProps="seriesProps"
          :categories="categories"
          :strokeWidth="strokeWidth"
          :groupGraph="groupGraph"
          :lineGraph="lineGraph"
          :tooltipCategories="tooltipCategories"
          ref="graph"
        />
        <div class="payment-indicators-item__legend" v-if="legend.length">
          <p
            v-for="(item, index) in getLegend"
            :key="`legend-${index}`"
            :id="item.id"
            :class="['payment-indicators-item__legend-item', { hidden: item.hidden }, { cursor: groupGraph }]"
            @click="groupGraph && onClickLegend(item)"
          >
            <span class="payment-indicators-item__legend-circle" :style="`background: ${item.color}`"></span>
            <span class="payment-indicators-item__legend-name">{{ item.name }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';

  import Loader from '@/components/Loader';

  import { ID_ALL_MEANINGS_BALANCE, ID_LINE_GRAPH_BALANCE } from '../../utils/constants';
  import GroupGraphPayments from './GroupGraphPayments.vue';

  export default {
    name: 'PaymentIndicatorItem',
    components: {
      SelectMultiComponent,
      ButtonComponent,
      Loader,
      GroupGraphPayments,
    },
    props: {
      titleName: {
        type: String,
        default: '',
      },
      countryName: {
        type: String,
        default: '',
      },
      optionList: {
        type: Array,
        default: () => [],
      },
      yearList: {
        type: Array,
        default: () => [],
      },
      balanceProps: {
        type: [Number, String],
        default: 0,
      },
      oldBalanceProps: {
        type: [Number, String],
        default: 0,
      },
      load: {
        type: Boolean,
        default: true,
      },
      seriesProps: {
        type: Array,
        default: () => [],
      },
      categories: {
        type: Array,
        default: () => [],
      },
      tooltipCategories: {
        type: Array,
        default: () => [],
      },
      strokeWidth: {
        type: Array,
        default: () => [],
      },
      legend: {
        type: Array,
        default: () => [],
      },
      labelSelect: {
        type: String,
        default: 'Сальдо',
      },
    },
    data() {
      return {
        balance: '',
        oldBalance: '',
        filters: {
          start: '',
          end: '',
        },
        castFilters: {
          start: '',
          end: '',
        },
      };
    },
    computed: {
      disabledSearch() {
        return (!this.balance || this.balance === this.oldBalance) && !this.getIsChangedFilters;
      },
      getIsChangedFilters() {
        return JSON.stringify(this.castFilters) !== JSON.stringify(this.filters);
      },
      groupGraph() {
        return this.oldBalance === ID_ALL_MEANINGS_BALANCE;
      },
      lineGraph() {
        return this.oldBalance === ID_LINE_GRAPH_BALANCE;
      },

      getLegend() {
        if (!this.groupGraph) {
          return this.legend.filter((i) => i.id == this.oldBalance);
        }
        return this.legend;
      },

      getIsShowingFilters() {
        return !!this.optionList.length || !!this.yearList.length;
      },
    },
    watch: {
      balanceProps(val) {
        this.balance = val;
      },
      oldBalanceProps(val) {
        this.oldBalance = val;
      },
      groupGraph(val) {
        if (!val) this.$emit('resetStateLegend');
      },
    },
    mounted() {
      this.balance = this.balanceProps;
      this.oldBalance = this.oldBalanceProps;
      this.setCastFilters();
    },
    methods: {
      applyParameters() {
        this.v$.$touch();

        if (this.v$.$invalid) {
          return false;
        }

        const params = {
          balance: this.balance,
          ...this.filters,
        };
        this.$emit('applyParameters', params);
        this.setCastFilters();
        this.v$.$reset();
      },
      onClickLegend(item) {
        const { apexchart } = this.$refs?.graph?.$refs;
        if (!apexchart) return;
        apexchart.toggleSeries(item.name);
        this.$emit('onClickLegend', item);
      },
      setCastFilters() {
        this.castFilters = JSON.parse(JSON.stringify(this.filters));
      },
      isAfterStartDate(value) {
        if (!this.filters.start || !value) return true;
        return new Date(value) >= new Date(this.filters.start);
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        filters: {
          end: {
            isAfterStartDate: this.isAfterStartDate,
          },
        },
      };
    },
  };
</script>

<style lang="scss" scoped src="../../styles/payment-indicators-item.scss"></style>

<style lang="scss" scoped>
  .payment-indicators-item__select_period {
    .invalid-msg {
      position: absolute;
    }
  }
</style>
