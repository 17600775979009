<template>
  <div id="container" v-click-outside="closeModalMapInfo">
    <Transition>
      <PopupMap v-if="openModalMapInfo" @closePopup="closeModalMapInfo">
        <template v-slot:header>
          <div class="content-map-project">
            <span @click="onCountryClick(popup.id)" class="content-map-project__name">{{ popup.countryName }}</span>
            <ul class="content-map-project__description-list">
              <li>
                Всего проектов: <span>{{ popup.countProjects }}</span>
              </li>
              <li>
                Проектов по МПК: <span>{{ popup.countMpkProjects }}</span>
              </li>
            </ul>
          </div>
        </template>
        <template v-slot:body v-if="popup.projects.length">
          <ul class="map-project-list map-project-list_type_regions" v-if="popup.regionProjects">
            <li v-for="item in popup.regionProjects" :key="item.id">
              {{ item.regionOfRussia.name }}
              <span>{{ item.countProjects }}</span>
            </li>
          </ul>
          <ul class="map-project-list">
            <li v-for="item in popup.projects" :key="item.id" @click="onProjectClick(item.id)">
              {{ item.name }}
            </li>
          </ul>
        </template>
      </PopupMap>
    </Transition>
    <div id="mapContainerProjectMap"></div>
  </div>
</template>

<script>
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';

  import PopupMap from '@/common/components/redesigned/popups/MapPopup.vue';
  import API_COUNTRY from '@/common/utils';

  import constants from '../constants';
  import worldMap from './custom.geo.json';

  export default {
    name: 'mapComponent',
    components: { PopupMap },
    props: {
      projects: {
        type: Object,
        default: () => {},
      },
      maxProjects: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        center: [40, 0],
        world: worldMap,
        countries: [],
        projectsInner: this.$route.params.projects ?? {},
        mapDiv: null,
        geoJsonLayer: null,
        popup: {
          countryName: '',
          countProjects: '',
          countMpkProjects: '',
          id: '',
          projects: [],
          regionProjects: [],
        },
        openModalMapInfo: false,
      };
    },
    emits: ['onCountryHeaderClick'],
    watch: {
      projects: function (newValue) {
        this.projectsInner = newValue;
        this.restyleGeoJsonLayer();
      },
    },
    mounted() {
      this.loadCountries();
    },
    methods: {
      loadCountries() {
        API_COUNTRY.searchNsi('countryListWithData', true).then((response) => {
          this.countries = response.data;
          this.setupLeafletMap();
        });
      },

      styleMap(feature) {
        const colors = constants.COLORS_LEGEND;
        let color = constants.COLOR_DEFAULT;
        let code = Number(feature.properties.iso_n3).toString();
        let projectsCount = this.projects[code] ? this.projects[code].mpkProjects.length : 0;
        // projectsCount = Math.round(projectsCount / 10)
        feature.properties.countryStatus = projectsCount;
        const country = this.countries.find((x) => x.code == code);

        if (country) {
          feature.properties.rusName = country.name;
        }

        colors.forEach((i, index) => (+index === +projectsCount ? (color = i) : false));

        if (projectsCount !== 0 && +projectsCount >= this.maxProjects) {
          color = constants.COLOR_MAX;
        }

        if (code == constants.GREENLAND_CODE) {
          return { color: constants.COLOR_BORDER, fillColor: 'white', weight: 1, opacity: 0.5 };
        }

        return { color: constants.COLOR_BORDER, fillColor: color, weight: 1, opacity: 1 };
      },
      onEachFeature(feature, layer) {
        const self = this;
        if (feature.properties && feature.properties.rusName) {
          layer.on('mouseup', () => {
            const code = +feature.properties.iso_n3;
            const country = self.countries.find((c) => +c.code === code);
            if (country) {
              const countryData = self.countries.find((c) => c.code === Number(feature.properties.iso_n3));
              const countryName = (countryData?.nameFull || countryData?.name) ?? feature?.properties?.name;
              self.popup.countryName = countryName;

              console.log(self.popup.countryName);
              const index = self.countries.findIndex((i) => +i.code === +country.code);

              if (index !== -1) {
                const currentProject = self.projects[code];
                self.popup.countMpkProjects = currentProject?.countProjectsHasMpk
                  ? currentProject.countProjectsHasMpk
                  : 0;
                self.popup.projects = currentProject?.mpkProjects ? currentProject.mpkProjects : 0;
                self.popup.countProjects = currentProject?.mpkProjects ? currentProject.mpkProjects.length : 0;
                self.popup.id = country.id;
                self.popup.regionProjects = currentProject?.projectsByRegions ? currentProject.projectsByRegions : 0;
              }

              self.openModalMapInfo = true;
            }
          });
          layer.on('mouseover', function () {
            this.unbindTooltip();
            const country = self.countries.find((c) => c.code === Number(feature.properties.iso_n3));
            if (country) {
              const countryName = (country?.nameFull || country?.name) ?? feature?.properties?.name;

              this.bindTooltip(countryName).openTooltip();
            }
          });
          layer.on('mouseout', function () {
            this.unbindTooltip();
          });
        }
      },
      setupLeafletMap: function () {
        let mapContainer = window.document.getElementById('mapContainerProjectMap');
        while (mapContainer.firstChild) {
          mapContainer.removeChild(mapContainer.firstChild);
        }
        if (this.mapDiv) {
          this.mapDiv.remove();
          this.mapDiv = null;
        }
        this.mapDiv = L.map('mapContainerProjectMap').setView(this.center, 1.4);
        L.tileLayer('', {
          maxZoom: 5,
          minZoom: 1.4,
        }).addTo(this.mapDiv);
        this.geoJsonLayer = L.geoJSON(this.world, {
          weight: 0.5,
          fillOpacity: 1,
        }).addTo(this.mapDiv);
        this.restyleGeoJsonLayer();
      },
      restyleGeoJsonLayer() {
        if (this.geoJsonLayer) {
          this.geoJsonLayer.eachLayer(this.restyleLayer);
        }
      },
      restyleLayer(layer) {
        let style = this.styleMap(layer.feature);
        layer.setStyle(style);
        this.onEachFeature(layer.feature, layer);
      },
      onProjectClick(projectId) {
        this.$router.push({ name: 'ProjectCard', params: { id: projectId } });
      },
      onCountryClick(countryId) {
        this.$router.push({ name: 'Projects', params: { countryId: countryId } });
      },

      closeModalMapInfo() {
        this.openModalMapInfo = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  #mapContainerProjectMap {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
  }
</style>

<style lang="scss">
  .leaflet-container a.leaflet-popup-close-button {
    right: 8px;
  }
  .leaflet-popup-content-wrapper {
    .leaflet-popup-content {
      min-width: 320px;
      max-height: 320px;
      overflow: auto;
    }
    border-radius: 24px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    .leaflet-popup-tip-container {
      display: none;
    }
    padding: 0;
    .popup {
      &__head {
        background: #1d87ee;
        p,
        span {
          margin: 10px 32px 10px 24px;
          font-family: 'Fira Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 160%;
          color: #ffffff;
        }
      }
      &__body {
        li {
          margin: 8px 0;
        }
        p {
          margin: 10px 24px;
          font-family: 'Fira Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 160%;
          color: #015caf;
        }
      }
    }
    .leaflet-popup-content {
      margin: 0;
    }
  }

  .content-map-project {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    color: #000;

    @media (max-width: $laptop) {
      gap: 8px;
    }

    &__name {
      display: inline-block;
      font: $fira-14-B;
      cursor: pointer;
      transition: opacity ease 0.3s;

      @media (max-width: $laptop) {
        font: $fira-32-M;
        padding-right: 30px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    &__description-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      @media (max-width: $laptop) {
        gap: 8px;
      }

      & li {
        font: $fira-14;
      }

      & span {
        font: $fira-14-B;
      }
    }
  }

  .map-project-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding-right: 16px;

    @media (max-width: $laptop) {
      padding-right: 8px;
    }

    & li {
      font: $fira-12;
      color: #000;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    &_type_regions {
      gap: 8px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      & li {
        cursor: default;
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 8px;

        &:hover {
          text-decoration: none;
        }

        & span {
          text-align: right;
          font: $fira-12-B;
          color: $green;
        }
      }
    }
  }
</style>
