<template>
  <div v-if="!multiple" :style="{ width: width }" class="inner-file">
    <label :style="{ width: width, cursor: readonly ? 'default' : 'pointer' }" class="label-input" for="file-input">
      {{ fileName }}
      <input
        :style="{ cursor: readonly ? 'default' : 'pointer' }"
        type="file"
        id="file-input"
        class="input-file"
        v-on:change="fileChanged($event)"
        v-if="!readonly"
      />
    </label>
    <div class="invalid-msg" v-if="v$.value.$error">
      <template v-if="v$.value.required.$invalid">Загрузите файл</template>
    </div>

    <IconComponent v-if="validFile()" @click.prevent="downloadFile(getFile())" name="download_transparent" />

    <IconComponent v-if="!iconCloseBtn && !readonly" title="Удалить" @click="deleteFile" name="closeCross" />
  </div>
  <div v-if="multiple" :style="{ width: width }" class="inner-file">
    <div v-for="(file, index) in value" :key="file.id" class="multi-file-block">
      {{ file.originalName }}
      <IconComponent v-if="validFile(file)" @click.prevent="downloadFile(file)" name="download_transparent" />
      <IconComponent v-if="!iconCloseBtn && !readonly" title="Удалить" @click="deleteFile(index)" name="closeCross" />
    </div>
    <label :style="{ width: width, cursor: readonly ? 'default' : 'pointer' }" class="label-input" for="file-input">
      {{ label }}
      <input
        :style="{ cursor: readonly ? 'default' : 'pointer' }"
        type="file"
        id="file-input-multi"
        class="input-file"
        v-on:change="fileChanged($event)"
        v-if="!readonly"
        multiple="multiple"
      />
    </label>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { requiredIf } from '@vuelidate/validators';

  import FileAPI from '../api/file';
  import Constants from '../constants';
  import IconComponent from '../ui/IconComponent.vue';

  export default {
    components: { IconComponent },
    name: 'FileUpload',
    props: {
      label: {
        type: String,
        default: 'Выберите файл',
      },
      width: String,
      entity: Object,
      propName: String,
      entityIndex: Number,
      readonly: Boolean,
      required: {
        type: Boolean,
        default: false,
      },
      iconCloseBtn: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['uploaded', 'deleteFile'],
    data() {
      return {
        value: null,
      };
    },
    created() {
      this.value = this.entity ? this.entity[this.propName] : null;
    },
    methods: {
      getFile() {
        return this.entity[this.propName];
      },
      validFile(file) {
        if (file) return !!file.id;
        return this.entity[this.propName] && this.entity[this.propName].id;
      },
      downloadFile(file) {
        FileAPI.download(file.id).then((response) => {
          const blob = new Blob([response.data], { type: file.fileType });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = file.originalName;
          link.click();
          URL.revokeObjectURL(link.href);
        });
      },
      fileChanged(e) {
        if (!this.multiple) {
          const file = e.target.files[0];
          FileAPI.upload(file)
            .then((response) => {
              this.value = file;
              this.$emit('uploaded', {
                entityIndex: this.entityIndex,
                propName: this.propName,
                data: response.data,
              });
            })
            .catch(() => {
              Constants.alert.fire('Ошибка', 'Не удалось загрузить файл.', 'error');
            });
        } else {
          this.value = [];
          for (let i = 0; i < e.target.files.length; i++) {
            if (!e.target.files[i].name) continue;
            FileAPI.upload(e.target.files[i])
              .then((response) => {
                this.$emit('uploaded', {
                  entityIndex: this.entityIndex,
                  propName: this.propName,
                  data: response.data,
                });
              })
              .catch((err) => {
                console.error(err);
                Constants.alert.fire('Ошибка', 'Не удалось загрузить файл.', 'error');
              });
          }
        }
      },
      deleteFile(index) {
        this.$emit('deleteFile', {
          entityIndex: index,
          propName: this.propName,
          data: this.getFile(),
        });
      },
    },
    computed: {
      fileName() {
        return this.validFile() ? this.entity[this.propName].originalName : this.label;
      },
    },
    watch: {
      entity: {
        handler: function () {
          if (this.entity) this.value = this.entity[this.propName];
        },
        deep: true,
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        value: {
          required: requiredIf(function () {
            return this.required;
          }),
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/theme/default/colors';
  ::-webkit-file-upload-button {
    cursor: pointer;
  }
  input[type='file'] {
    cursor: pointer;
  }
  .input-file {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    border-radius: 6px;
    z-index: 2;
  }

  label {
    position: relative;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
    line-height: 140%;
    color: #333333;
    padding: 5px 16px;
    width: 320px;
    height: 40px;
    background: #f5f5fa;
    border-radius: 6px;
    z-index: 3;
  }
  a:focus {
    box-shadow: none !important;
    outline: none !important;
  }
  .inner-file {
    display: flex;
    align-items: center;
    min-width: 250px;
    position: relative;
    background: #f5f5fa;
    border-radius: 6px;
    z-index: 1;

    .btn {
      cursor: pointer;
      position: absolute;
      content: '';
      width: 24px;
      height: 24px;
      top: 0;
      right: 24px;
      z-index: 99;
    }
    .btn-d {
      cursor: pointer;
      position: absolute;
      content: '';
      width: 24px;
      height: 24px;
      top: 50%;
      right: 48px;
      z-index: 99;
      transform: translateY(-55%);
    }
  }

  .icon {
    opacity: 0.7;
    color: #787f8f;
    margin-left: 14px;
  }
  .multi-file-block {
    display: inherit;
    margin-right: 16px;
  }
</style>
