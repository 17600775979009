import Api from '@/modules/countryАnalytics/ExportPotential/api/exportpotential';

export default {
  state: {
    // данные для селекта карты потенциала
    exportPotentialMapSelect: null,
    // данные для карты потенциала
    exportPotentialMap: {},
  },
  getters: {
    getExportPotentialMapSelect(state) {
      return state.exportPotentialMapSelect;
    },
    getExportPotentialMap(state) {
      return state.exportPotentialMap;
    },
  },
  mutations: {
    updateExportPotentialMapSelect(state, selectData) {
      state.exportPotentialMapSelect = selectData;
    },
    updateExportPotentialMap(state, mapData) {
      state.exportPotentialMap = mapData;
    },
  },
  actions: {
    downloadExportPotentialMapSelect(ctx) {
      Api.getProductsListWithExportPotential().then((ret) => {
        let data = ret?.data
          .filter((item) => item.isActive)
          .map((item) => {
            if (item.name && item.code && item.id) {
              return {
                text: item.code + ' - ' + item.name,
                id: item.code,
                code: item.id,
                searchText: item.name,
                level: item.level,
              };
            }
          });
        const selectData = data.sort((a, b) => a.text.localeCompare(b.text));
        ctx.commit('updateExportPotentialMapSelect', selectData);
      });
    },
    downloadExportPotentialMap(ctx, request) {
      Api.search(request).then((response) => {
        let activeCountries = {};
        response.data.items.forEach((item) => {
          if (item.toCountry) {
            if (item.toCountry.code) {
              if (activeCountries[item.toCountry.code] !== undefined) {
                activeCountries[item.toCountry.code].exportValue += item.exportValue;
                activeCountries[item.toCountry.code].exportFact += item.unusedvalue;
                activeCountries[item.toCountry.code].value += item.value;
              } else {
                activeCountries[item.toCountry.code] = {
                  counter: 1,
                  label: item.toCountry.name,
                  exportValue: item.exportValue,
                  exportFact: item.exportFact,
                  value: item.value,
                  name: item.itemsNsiTnved[0].name,
                  code: item.itemsNsiTnved[0].code,
                };
              }
            }
          }
        });
        ctx.commit('updateExportPotentialMap', activeCountries);
      });
    },
  },
};
