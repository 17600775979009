<template>
  <div class="agreements-container">
    <!-- title -->
    <p class="list-agreements__title" id="market-access-data">{{ title }}</p>

    <div class="d-flex agreement-type-selector">
      <div class="country-analytics-new__selector country-analytics-new__filter-selector">
        <ComponentInput
          class="select2"
          :select2Options="agreementTypes"
          v-model="agreementTypeId"
          placeholder="Тип соглашения"
          type="select2"
          :select2Settings="select2SettingsNoSearch"
        />
      </div>
    </div>

    <!-- Results table -->
    <div class="list-agreements__results-table table-container js-table-arrow">
      <table class="stockTable">
        <thead>
          <tr>
            <th>№ п/п</th>
            <th>Название</th>
            <th>Тип</th>
            <th>Стороны</th>
            <th>Охват</th>
            <th>Активность</th>
            <th>Источник данных</th>
          </tr>
        </thead>

        <tbody v-if="page.items?.length && !isLoading">
          <tr v-for="(item, index) in page.items" :key="item.id" @dblclick="viewCard(item.id)">
            <td>{{ index + 1 + (request.pageNumber - 1) * request.pageSize }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.type.name }}</td>
            <td>{{ item.sidesStr }}</td>
            <td>{{ item.tradeItemTypesStr }}</td>
            <td>{{ item.isActive ? 'Да' : 'Нет' }}</td>
            <td>
              <a class="info-link" v-for="info in item.additionalInfo" :key="info.id" :href="info.url">{{
                info.name
              }}</a>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="isLoading">
          <tr>
            <td class="text-center" :colspan="7">Загрузка...</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" :colspan="7">Нет данных.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="page.pagesTotal > 1">
      <Pagination
        :page-count="page.pagesTotal"
        :current-page="page.pageNumber"
        @change="onPageChange($event, false)"
        @more="onPageChange($event, true)"
      />
    </div>
  </div>
</template>

<script>
  // Additional
  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';

  import API from '@/modules/freeTradeAgreement/api/index';
  import API_NSI from '@/modules/nsi/api/nsi';

  export default {
    name: 'MarketAccessAgreements',
    components: { ComponentInput },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
      title: {
        type: String,
        default: '',
      },
      isWithRussia: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        select2SettingsNoSearch: Object.assign({ minimumResultsForSearch: -1 }, Constants.select2Settings),
        page: {
          items: null,
          pageNumber: null,
          pagesTotal: null,
        },
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        filter: {
          sidesIds: [],
          typeIds: [],
          isActive: true,
          includeAllSides: true,
        },
        isLoading: false,
        agreementTypes: [],
        agreementTypeId: null,
      };
    },
    watch: {},
    created() {
      this.$watch(
        (vm) => [vm.countryId, vm.isWithRussia],
        () => {
          if (this.agreementTypeId) this.agreementTypeId = null;
          else this.reloadData();
        },
      );
      this.$watch(
        (vm) => [vm.agreementTypeId],
        () => {
          this.reloadData();
        },
      );

      let request = { pageNumber: 1, pageSize: 0 };
      API_NSI.search('NsiFreeTradeAgreementType', request).then((response) => {
        if (response.data) {
          this.agreementTypes = response.data.items.map((o) => {
            return { text: o.name, id: o.id };
          });
        }
      });
    },
    mounted() {
      this.reloadData();
    },
    methods: {
      reloadData() {
        this.request.pageNumber = 1;
        this.setupFilter();
        this.loadPage();
      },
      setupFilter() {
        this.filter.sidesIds = [];
        this.filter.typeIds = [];
        if (this.countryId) {
          this.filter.sidesIds = [this.countryId];
          if (this.agreementTypeId) this.filter.typeIds = [this.agreementTypeId];
          if (this.isWithRussia) this.filter.sidesIds = [...this.filter.sidesIds, Constants.countryRussiaId];
        }
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      loadPage(append) {
        this.isLoading = !append;
        const request = Object.assign(this.filter, this.request);

        API.search(request)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      },
      viewCard(id) {
        this.$router.push({ name: 'EditAgreement', params: { action: 'view', id: id } });
      },
    },
    computed: {},
  };
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
  .agreements-container {
    margin-top: 60px;
  }
  .info-link {
    display: block;
  }
  .list-agreements {
    &__title {
      font-weight: 500;
      font-size: 22px;
      line-height: 24px;
      color: #282b30;
    }
    &__results {
      &-table {
        margin-top: 30px;
        margin-bottom: 42px;
        thead {
          text-align: center;
          th:first-child {
            min-width: 50px;
          }
        }
      }
    }
  }
  .agreement-type-selector {
    margin-top: 20px;
  }
</style>
