import { UserManager } from 'oidc-client';

import runtimeConfig from '@/common/runtime-config';

import store from '@/store/index';

// Log.logger = console
const userManager = new UserManager(runtimeConfig.oidc);
userManager.events.addUserLoaded(function () {
  console.log('user loaded...');
  store.dispatch('auth/oidc/loadUser');
});
userManager.events.addAccessTokenExpiring(function () {
  console.log('token expiring...');
});
userManager.events.addAccessTokenExpired(function () {
  console.log('token expired...');
  store.commit('auth/oidc/setIsExpired', true);
});

export default userManager;
