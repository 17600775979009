export function setEventsHistory(state, res) {
  const { data = {} } = res;
  state.eventsHistoryData = data;
}

export function addEventsHistory(state, res) {
  const { data = {} } = res;
  state.eventsHistoryData.items.push(...data.items);
  state.eventsHistoryData.pageNumber = data.pageNumber;
}

export function setAgreementsHistory(state, res) {
  const { data = {} } = res;
  state.agreementsHistoryData = data;
}

export function addAgreementsHistory(state, res) {
  const { data = {} } = res;
  state.agreementsHistoryData.items.push(...data.items);
  state.agreementsHistoryData.pageNumber = data.pageNumber;
}
