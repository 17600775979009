<template>
  <NsiTable :pageTitle="pageTitle" :apiRoute="apiRoute" :cardName="cardName" :canEdit="canEdit" />
</template>

<script>
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';

  import NsiTable from '../components/NsiTable';

  export default {
    name: 'NsiCountryCoverage',
    components: {
      NsiTable,
    },
    data() {
      return {
        pageTitle: 'Страновой охват',
        canEdit: false,
        apiRoute: 'nsiCountryCoverage',
        cardName: 'NsiCountryCoverageCard',
      };
    },
    mixins: [security],
    created() {
      this.checkPermissionAndRedirect(Constants.Permissions.NsiAccess);
      this.checkPermission(Constants.Permissions.NsiEdit).then((response) => {
        this.canEdit = response.data;
      });
    },
    methods: {},
  };
</script>
