<template>
  <div class="marco">
    <div class="d-flex justify-content-between">
      <div class="country d-flex align-items-center">
        <IconComponent name="alertCircle" />
        <div class="d-flex align-items-center flex-wrap">
          <h3>{{ itemsData && itemsData[0]?.country?.name }}&nbsp;</h3>
          <span v-if="itemsData && itemsData[0]?.country?.countryGroupId === 7">- член союза ЕАЭС</span>
        </div>
      </div>
      <div class="analytics-source-text">Источник: Всемирный банк</div>
    </div>
    <div class="d-flex align-items-center">
      <div class="country d-flex align-items-center mr-4">
        <IconComponent name="userIcon" />
        <h3>Население&nbsp;</h3>
        <span v-if="population && population.length"
          >-
          {{ population && population[population.length - 1]?.value.toLocaleString() }}
          человек ({{ population && population[population.length - 1].year }} г.)
        </span>
      </div>
      <!--      <div class="country d-flex align-items-center">-->
      <!--        <icon-component name="award" />-->
      <!--        <h3>Позиция в рейтинге стран мира по размеру ВВП &nbsp;</h3>-->
      <!--        <span>- 1 место</span>-->
      <!--      </div>-->
    </div>
    <div class="key-indicators__inner">
      <h2 class="key-indicators__title">Ключевые показатели</h2>
      <ul class="key-indicators">
        <li
          @click="gdpMoreInfo ? selectIndicator(2, 'ВВП в текущих ценах, млрд $ США') : null"
          class="key-indicators__item"
          :class="{ 'more-info-hidden': !gdpMoreInfo }"
        >
          <p>ВВП в текущих ценах</p>
          <p>
            Источник:
            <br />
            Всемирный банк
          </p>
          <p>{{ gdp && gdp[gdp.length - 1]?.year }} г.</p>
          <div class="key-indicators__item-wrapper" :class="{ active: selectTypeId === 2 }">
            <i
              v-if="gdp && +gdp[gdp.length - 1]?.rawValue < +gdp[gdp.length - 2]?.rawValue"
              class="fas fa-solid mr-1 text-red fa-arrow-down"
            ></i>
            <i
              v-if="gdp && +gdp[gdp.length - 1]?.rawValue > +gdp[gdp.length - 2]?.rawValue"
              class="fas fa-solid mr-1 text-green fa-arrow-up"
            ></i>
            <strong v-if="gdp?.length > 0">
              {{ gdp && gdp[gdp.length - 1]?.value }}
            </strong>
            <strong v-else> - </strong>
            <p>млрд $ США</p>
          </div>
          <button class="btn-default" v-if="gdpMoreInfo"><IconComponent name="chartIcon" />Больше информации</button>
          <!--          <div v-if="item?.awards" class="award">-->
          <!--            <p>1 место - Италия</p>-->
          <!--            <p>8 место - Россия</p>-->
          <!--          </div>-->
        </li>
        <li
          @click="capitaGdpMoreInfo ? selectIndicator(5, 'ВВП на душу населения, $ США') : null"
          class="key-indicators__item"
          :class="{ 'more-info-hidden': !capitaGdpMoreInfo }"
        >
          <p>
            ВВП на душу населения
            <br />
            (в текущих ценах)
          </p>
          <p>
            Источник:
            <br />
            Всемирный банк
          </p>
          <p>{{ capitaGdp && capitaGdp[capitaGdp.length - 1]?.year }} г.</p>
          <div class="key-indicators__item-wrapper" :class="{ active: selectTypeId === 5 }">
            <i
              v-if="
                capitaGdp && +capitaGdp[capitaGdp.length - 1]?.rawValue < +capitaGdp[capitaGdp.length - 2]?.rawValue
              "
              class="fas fa-solid mr-1 text-red fa-arrow-down"
            ></i>
            <i
              v-if="
                capitaGdp && +capitaGdp[capitaGdp.length - 1]?.rawValue > +capitaGdp[capitaGdp.length - 2]?.rawValue
              "
              class="fas fa-solid mr-1 text-green fa-arrow-up"
            ></i>
            <strong v-if="capitaGdp?.length > 0">
              {{ capitaGdp && capitaGdp[capitaGdp.length - 1]?.value }}
            </strong>
            <strong v-else> - </strong>
            <p>$ США</p>
          </div>
          <button class="btn-default" v-if="capitaGdpMoreInfo">
            <IconComponent name="chartIcon" />Больше информации
          </button>
        </li>
        <li
          @click="
            gdpPPSMoreInfo
              ? selectIndicator(11, 'ВВП по ППС (в текущих ценах), млрд $ США, (международный доллар)')
              : gdpPPSMoreInfo
          "
          class="key-indicators__item"
          :class="{ 'more-info-hidden': !gdpPPSMoreInfo }"
        >
          <p>
            ВВП по ППС
            <br />
            (в текущих ценах)
          </p>
          <p>
            Источник:
            <br />
            Всемирный банк
          </p>
          <p>{{ gdpPPS && gdpPPS[gdpPPS.length - 1]?.year }} г.</p>
          <div class="key-indicators__item-wrapper" :class="{ active: selectTypeId === 11 }">
            <i
              v-if="gdpPPS && +gdpPPS[gdpPPS.length - 1]?.rawValue < +gdpPPS[gdpPPS.length - 2]?.rawValue"
              class="fas fa-solid mr-1 text-red fa-arrow-down"
            ></i>
            <i
              v-if="gdpPPS && +gdpPPS[gdpPPS.length - 1]?.rawValue > +gdpPPS[gdpPPS.length - 2]?.rawValue"
              class="fas fa-solid mr-1 text-green fa-arrow-up"
            ></i>
            <strong v-if="gdpPPS?.length > 0">
              {{ gdpPPS && gdpPPS[gdpPPS.length - 1]?.value }}
            </strong>
            <strong v-else> - </strong>
            <p>млрд $ США</p>
          </div>
          <button class="btn-default" v-if="gdpPPSMoreInfo"><IconComponent name="chartIcon" />Больше информации</button>
        </li>
        <li
          @click="gdpGrowMoreInfo ? selectIndicator(18, 'Темп прироста ВВП (в постоянных ценах), %') : null"
          class="key-indicators__item"
          :class="{ 'more-info-hidden': !gdpGrowMoreInfo }"
        >
          <p>
            Темп прироста ВВП
            <br />
            (в постоянных ценах)
          </p>
          <p>
            Источник:
            <br />
            Международный валютный фонд
          </p>
          <p>{{ gdpGrow && gdpGrow[gdpGrow.length - 1]?.year }} г.</p>
          <div class="key-indicators__item-wrapper" :class="{ active: selectTypeId === 7 }">
            <i
              v-if="gdpGrow && +gdpGrow[gdpGrow.length - 1]?.rawValue < +gdpGrow[gdpGrow.length - 2]?.rawValue"
              class="fas fa-solid mr-1 text-red fa-arrow-down"
            ></i>
            <i
              v-if="gdpGrow && +gdpGrow[gdpGrow.length - 1]?.rawValue > +gdpGrow[gdpGrow.length - 2]?.rawValue"
              class="fas fa-solid mr-1 text-green fa-arrow-up"
            ></i>
            <strong v-if="gdpGrow?.length > 0">
              {{ gdpGrow && gdpGrow[gdpGrow.length - 1]?.value }}
            </strong>
            <strong v-else> - </strong>
            <p>%</p>
          </div>
          <button class="btn-default" v-if="gdpGrowMoreInfo">
            <IconComponent name="chartIcon" />Больше информации
          </button>
        </li>
        <li
          @click="
            gdpGrowForecastMoreInfo ? selectIndicator(19, 'Прогноз темпа прироста (в постоянных ценах), %') : null
          "
          class="key-indicators__item"
          :class="{ 'more-info-hidden': !gdpGrowForecastMoreInfo }"
        >
          <p>
            Прогноз темпа прироста ВВП
            <br />
            (в постоянных ценах)
          </p>
          <p>
            Источник:
            <br />
            Международный валютный фонд
          </p>
          <p>{{ gdpGrowForecast && gdpGrowForecast[gdpGrowForecast.length - 1]?.year }} г.</p>
          <div class="key-indicators__item-wrapper" :class="{ active: selectTypeId === 19 }">
            <i
              v-if="
                gdpGrowForecast &&
                +gdpGrowForecast[gdpGrowForecast.length - 1]?.rawValue <
                  +gdpGrowForecast[gdpGrowForecast.length - 2]?.rawValue
              "
              class="fas fa-solid mr-1 text-red fa-arrow-down"
            ></i>
            <i
              v-if="
                gdpGrowForecast &&
                +gdpGrowForecast[gdpGrowForecast.length - 1]?.rawValue >
                  +gdpGrowForecast[gdpGrowForecast.length - 2]?.rawValue
              "
              class="fas fa-solid mr-1 text-green fa-arrow-up"
            ></i>
            <strong v-if="gdpGrowForecast?.length > 0">
              {{ gdpGrowForecast && gdpGrowForecast[gdpGrowForecast.length - 1]?.value }}
            </strong>
            <strong v-else> - </strong>
            <p>%</p>
          </div>
          <button class="btn-default" v-if="gdpGrowForecastMoreInfo">
            <IconComponent name="chartIcon" />Больше информации
          </button>
        </li>
      </ul>
    </div>
    <h2 class="mb-3 mt-5">{{ indicatorText }}</h2>
    <ColumnChart
      v-if="selectTypeId && itemsDataValue && itemsDataValue.length && selected"
      :format-label="formatLabel"
      :selectTypeId="selectTypeId"
      :chart-data="itemsDataValue && itemsDataValue"
      :labels="itemsDataYears && itemsDataYears"
      :colors="itemColors"
    />
    <h2 class="mb-3 mt-5">Другие показатели</h2>
    <div class="d-flex align-items-start">
      <div class="indicators">
        <IconComponent name="UserIcons" />
        <p>Безработица</p>
        <p>{{ unemploymentRate && unemploymentRate[unemploymentRate.length - 1]?.year }} г.</p>
        <p v-if="unemploymentRate?.length > 0">
          <strong>{{ unemploymentRate[unemploymentRate.length - 1]?.value }}%</strong>
        </p>
        <p v-else>
          <strong>-</strong>
        </p>
        <div class="key-indicators__item">
          <p>
            Источник:
            <br />
            Всемирный банк
          </p>
        </div>
      </div>
      <div class="indicators" v-if="realIncome">
        <IconComponent name="moneyIcon" />
        <p>Реальные располагаемые доходы населения</p>
        <p>Период: {{ realIncome.year }}</p>
        <p>
          <strong>{{ realIncome.value }}</strong>
        </p>
        <p>{{ realIncome.unit }}</p>
      </div>
    </div>
    <h2 class="mb-3 mt-5">Структура ВДС, % от ВВП</h2>
    <div class="key-indicators__item">
      <p class="text-align-left">
        Источник:
        <br />
        Всемирный банк
      </p>
      <p class="text-align-left" v-if="gdpYear > 0">Период: {{ gdpYear }} г.</p>
    </div>
    <template v-if="gdpStructure">
      <ColumnChartHorizontal
        :barPercentage="1"
        index-axis="y"
        :colors="['#0093BA', '#5bc6e3', '#337280', '#96d2e7']"
        :labels="gdpStructure.labels"
        :chart-data="gdpStructure.values"
      />
    </template>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';
  import IconComponent from '@/common/ui/IconComponent';

  import ColumnChartHorizontal from '@/components/column-chart-horizontal';
  import ColumnChart from '@/components/columnChart';

  import IndicatorApi from '@/modules/admin/api/indicatorVisibility';
  import Api from '@/modules/countryАnalytics/api';

  export default {
    name: 'MacroEcon',
    components: {
      ColumnChartHorizontal,
      //LineChart,
      ColumnChart,
      IconComponent,
    },
    mixins: [text],
    props: {
      country: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        selected: true,
        selectTypeId: null,
        indicatorText: '',
        itemsData: null,
        gdpStructure: null,
        gdpYear: 0,
        formatLabel: ' $ млрд',
        currYear: new Date().getFullYear(),
        realIncome: null,

        gdpMoreInfo: true,
        capitaGdpMoreInfo: true,
        industryGdpMoreInfo: true,
        importsGdpMoreInfo: true,
        influationGdpMoreInfo: true,
        gdpPPSMoreInfo: true,
        gdpGrowMoreInfo: true,
        gdpGrowForecastMoreInfo: true,
      };
    },
    created() {
      this.loadIndicatorVisibility();
      this.loadPage();
    },
    methods: {
      loadIndicatorVisibility() {
        IndicatorApi.search().then((response) => {
          this.gdpMoreInfo = response.data[0].moreInfoVisible;
          this.capitaGdpMoreInfo = response.data[1].moreInfoVisible;
          this.industryGdpMoreInfo = response.data[2].moreInfoVisible;
          this.importsGdpMoreInfo = response.data[3].moreInfoVisible;
          this.influationGdpMoreInfo = response.data[4].moreInfoVisible;
          this.gdpPPSMoreInfo = response.data[5].moreInfoVisible;
          this.gdpGrowMoreInfo = response.data[6].moreInfoVisible;
          this.gdpGrowForecastMoreInfo = response.data[7].moreInfoVisible;
        });
      },
      selectIndicator(id, text) {
        this.selectTypeId = id;
        this.indicatorText = text;
      },
      loadPage() {
        Api.searchMacro({
          countryId: this.country,
        }).then((res) => {
          this.itemsData = res.data.items;
          this.selected = false;
          setTimeout(() => {
            this.selected = true;
          });
          let gdpStruct = this.itemsData
            .filter((x) => x.macroeconomicIndicatorId >= 13 && x.macroeconomicIndicatorId <= 16)
            .sort(this.sortByYear)
            .sort((a, b) => a.value - b.value);

          this.gdpYear = Math.max.apply(
            Math,
            gdpStruct.map(function (o) {
              return o.year;
            }),
          );
          gdpStruct = gdpStruct.filter((x) => x.year == this.gdpYear);

          this.gdpStructure = null;
          this.$nextTick(() => {
            this.gdpStructure = {
              labels: gdpStruct.map((x) => x.macroeconomicIndicator.title),
              values: gdpStruct.map((x) => x.value.toFixed(1)),
            };
          });

          this.processGdpGrowForecast();
          this.processGdpGrow();
        });
        Api.getRealIncome(this.country).then((response) => {
          this.realIncome = response.data;
        });
      },
      processGdpGrowForecast() {
        let resultItems =
          this.itemsData &&
          this.itemsData
            ?.filter((item) => {
              return item?.macroeconomicIndicatorId === 17 && item.year > 2009;
            })
            .sort(this.sortByYear);

        if (resultItems) {
          for (let i = resultItems.length - 1; i > 0; i--) {
            resultItems[i].value = ((resultItems[i].value - resultItems[i - 1].value) * 100) / resultItems[i - 1].value;
          }
          resultItems[0].value = 0;
        }
      },
      processGdpGrow() {
        this.itemsData = this.itemsData?.filter((item) => {
          return item.macroeconomicIndicatorId != 7;
        });

        let resultItems =
          this.itemsData &&
          this.itemsData
            ?.filter((item) => {
              return item?.macroeconomicIndicatorId === 2 && item.year > 2009;
            })
            .sort(this.sortByYear)
            .map((item) => ({
              year: item.year,
              value: item.value,
              macroeconomicIndicatorId: 7,
              countryId: item.countryId,
              country: item.country,
              macroeconomicIndicator: item.macroeconomicIndicator,
            }));

        if (resultItems) {
          for (let i = resultItems.length - 1; i > 0; i--) {
            resultItems[i].value = ((resultItems[i].value - resultItems[i - 1].value) * 100) / resultItems[i - 1].value;
          }
          resultItems[0].value = 0;
        }

        this.itemsData = this.itemsData.concat(resultItems);
      },
      sortByYear(x, y) {
        if (x.year < y.year) {
          return -1;
        }
        if (x.year > y.year) {
          return 1;
        }
        return 0;
      },
    },
    computed: {
      // население
      population() {
        return (
          this.itemsData &&
          this.itemsData
            ?.filter((item) => item?.macroeconomicIndicatorId === 1 && item.year <= this.currYear)
            .sort(this.sortByYear)
        );
      },
      gdp() {
        return (
          this.itemsData &&
          this.itemsData
            ?.filter((item) => item?.macroeconomicIndicatorId === 2 && item.year > 2010 && item.year <= this.currYear)
            .sort(this.sortByYear)
            .map((item) => ({
              year: item.year,
              rawValue: item.value,
              value: this.formatNumber(item.value / 1000000000, 0),
            }))
        );
      },
      capitaGdp() {
        return (
          this.itemsData &&
          this.itemsData
            ?.filter((item) => item?.macroeconomicIndicatorId === 5 && item.year <= this.currYear)
            .sort(this.sortByYear)
            .map((item) => ({ year: item.year, rawValue: item.value, value: this.formatNumber(item.value, 0) }))
        );
      },
      gdpGrow() {
        return (
          this.itemsData &&
          this.itemsData
            ?.filter((item) => item?.macroeconomicIndicatorId === 18 && item.year < this.currYear)
            .sort(this.sortByYear)
            .map((item) => ({ year: item.year, rawValue: item.value, value: this.formatNumber(item.value) }))
        );
      },
      unemploymentRate() {
        return (
          this.itemsData &&
          this.itemsData
            ?.filter((item) => item?.macroeconomicIndicatorId === 10 && item.year <= this.currYear)
            .sort(this.sortByYear)
            .map((item) => ({ year: item.year, rawValue: item.value, value: this.formatNumber(item.value) }))
        );
      },
      gdpPPS() {
        return (
          this.itemsData &&
          this.itemsData
            ?.filter((item) => item?.macroeconomicIndicatorId === 11 && item.year <= this.currYear)
            .sort(this.sortByYear)
            .map((item) => ({
              year: item.year,
              rawValue: item.value,
              value: this.formatNumber(item.value / 1000000000, 0),
            }))
        );
      },
      gdpGrowForecast() {
        return (
          this.itemsData &&
          this.itemsData
            ?.filter((item) => item?.macroeconomicIndicatorId === 19 && item.year <= this.currYear)
            .sort(this.sortByYear)
            .map((item) => ({ year: item.year, rawValue: item.value, value: this.formatNumber(item.value) }))
        );
      },
      itemsDataValue() {
        const formatting = (item) => {
          switch (this.selectTypeId) {
            case 2:
              this.formatLabel = ' млрд $ США';
              return (item.value / 1000000000).toFixed(2);
            case 3:
              this.formatLabel = ' %';
              return item.value.toFixed(2);
            case 4:
              this.formatLabel = ' %';
              return item.value.toFixed(2);
            case 5:
              this.formatLabel = ' $ США';
              return item.value.toFixed(2);
            case 6:
              this.formatLabel = ' %';
              return item.value.toFixed(2);
            case 7:
              this.formatLabel = ' %';
              return item.value.toFixed(2);
            case 11:
              this.formatLabel = ' млрд $ США';
              return (item.value / 1000000000).toFixed(2);
            case 17:
              this.formatLabel = ' %';
              return item.value.toFixed(2);
            case 18:
              this.formatLabel = ' %';
              return item.value.toFixed(2);
            case 19:
              this.formatLabel = ' %';
              return item.value.toFixed(2);
            default:
              return item.value.toFixed(2);
          }
        };

        return (
          this.itemsData &&
          this.itemsData
            ?.filter((item) => {
              return (
                item?.macroeconomicIndicatorId === this.selectTypeId &&
                item.year > 2009 &&
                (this.selectTypeId != 18 || item.year <= this.currYear)
              );
            })
            .sort((x, y) => {
              if (x.year < y.year) {
                return -1;
              }
              if (x.year > y.year) {
                return 1;
              }
              return 0;
            })
            .map((item) => formatting(item))
        );
      },
      itemsDataYears() {
        return (
          this.itemsData &&
          this.itemsData
            ?.filter(
              (item) =>
                item?.macroeconomicIndicatorId === this.selectTypeId &&
                item.year > 2009 &&
                (this.selectTypeId != 18 || item.year <= this.currYear),
            )
            .sort((x, y) => {
              if (x.year < y.year) {
                return -1;
              }
              if (x.year > y.year) {
                return 1;
              }
              return 0;
            })
            .map((item) => item.year)
        );
      },
      itemColors() {
        return (
          this.itemsData &&
          this.itemsData
            .filter((item) => item?.macroeconomicIndicatorId === this.selectTypeId && item.year > 2009)
            .map((x) => (x.year <= this.currYear ? '#0093BA' : '#96d2e7'))
        );
      },
    },
    watch: {
      country() {
        this.loadPage();
      },
      selectTypeId(val) {
        if (val) {
          this.selected = false;
          setTimeout(() => {
            this.selected = true;
          });
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import './src/assets/theme/default/colors';
  .table-container {
    border-radius: 16px;
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
    overflow: auto;
    table {
      min-width: 1180px;
      &.stockTable {
        thead {
          th {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #787f8f;
            padding: 16px;
            border-bottom: 2px solid #01a39d;
            div {
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: #787f8f;
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid #d8d8d8;
          }
          td {
            padding: 16px;
            font-size: 12px;
            line-height: 18px;
            color: #535353;
            p {
              margin-bottom: 0px;
            }
          }
          button {
            margin-top: 12px;
          }
        }
      }
      &.blueGrayTable {
        thead {
          th {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #231f20;
            padding: 16px;
            background-color: #d3dfe5;
            border-bottom: 1px solid #787f8f;
            &:not(:last-of-type) {
              border-right: 1px solid #787f8f;
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid #787f8f;
            &:nth-of-type(even) {
              background-color: #f0f9ff;
            }
          }
          td {
            padding: 16px;
            font-size: 12px;
            line-height: 18px;
            color: #231f20;
            &:not(:last-of-type) {
              border-right: 1px solid #787f8f;
            }
            p {
              margin-bottom: 0px;
            }
          }
          .green {
            font-weight: 600;
            color: #01a39d;
            border-bottom: 1px dashed #01a39d;
            width: min-content;
          }
          .red {
            font-weight: 600;
            color: #eb5757;
          }
        }
      }
    }
  }
  .table-inner {
    min-width: 640px;
    width: 100%;
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $dark;
    .active {
      background-color: #ededed;
    }
    th {
      padding-bottom: 16px;
    }
    th:nth-child(1),
    td:nth-child(1) {
      padding-left: 12px;
    }
  }
  .table-custom {
    border: none;
    border-collapse: collapse;
    border: 0;
    th {
      padding: 13px 11px;
      background-color: #ffffff;
    }
    th,
    td {
      outline: var(--border);
      padding: 8px;
      text-align: center;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #000000;
    }
    th:first-child,
    td:first-child {
      padding: 8px 14px 8px 8px;
      text-align: left;
    }
    //tr:nth-child(odd) {
    //  background-color: #e5f5ff;
    //}
    th {
      border-bottom: 1px solid #01a39d !important;
    }
  }
  .table-custom-two {
    thead {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 111;
    }
  }
  .indicators {
    &:not(:last-child) {
      margin-right: 20%;
    }
    p {
      text-align: center;
      margin: 1rem 0;
      max-width: 200px;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 113.9%;

      color: #333333;
      strong {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 113.9%;
        text-align: center;
      }
    }
  }
  .key-indicators {
    padding: 0 15px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    // gap: 60px 14%;
    &__item {
      cursor: pointer;
      margin-bottom: 16px;
      .award {
        margin-top: 12px;
      }
      &-wrapper.active {
        background: #ffffff;
        box-shadow: 0 0 17px rgba(1, 120, 200, 0.5);
        border-radius: 5px;
        p {
          color: $dark;
        }
      }
      max-width: 160px;
      width: 100%;
      p {
        margin-bottom: 4px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 113.9%;
        text-align: center;
        color: #787f8f;
      }
      &-wrapper {
        justify-content: center;
        p {
          margin: 0;
          font-family: 'Fira Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          color: #787f8f;
        }
        strong {
          margin-right: 10px;
          font-family: 'Fira Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          color: #787f8f;
          white-space: nowrap;
        }
        padding: 16px;
        display: flex;
        align-items: center;
        background: #ffffff;
        box-shadow: 0 0 17px rgba(55, 55, 55, 0.08);
        border-radius: 5px;
      }
      button {
        .icon {
          margin-right: 8px;
        }
        max-width: 100px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 113.9%;
        text-align: center;
        color: #787f8f;
        display: flex;
        align-items: center;
        margin: -8px auto auto auto;
        padding: 8px 16px;
        border: none;
        background: #ffffff;
        box-shadow: 0 0 17px rgba(55, 55, 55, 0.08);
        border-radius: 5px;
      }
    }
    margin-top: 40px;
  }
  h2 {
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
  }
  h3 {
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }
  .country {
    margin-bottom: 20px;

    p {
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
    .icon {
      margin-right: 6px;
    }
  }
  .more-info-hidden {
    padding-bottom: 26px;
  }
</style>
