<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка анализ СМИ</p>
    </div>
    <div class="card-info">
      <div class="card-info__row row">
        <div class="mb-16">
          <div class="form-group" :class="[{ required: isInternal }]">
            <ComponentInput
              label="Категория"
              :select2Options="categoryList"
              v-model="item.categoryIds"
              :select2Settings="select2SettingsMulti"
              type="select2"
              required
              :multiple="true"
              v-if="!isReadonly"
            />
            <template v-else>
              <label class="form-label">Категория</label>
              <div v-if="isInternal">{{ item.categoryNames }}</div>
              <div v-else>{{ item.classes }}</div>
            </template>
          </div>
        </div>
        <div class="mb-16">
          <div class="form-group" :class="[{ required: isInternal }]">
            <ComponentInput
              label="Товар"
              :select2Options="smtkList"
              v-model="item.smtkIds"
              :select2Settings="select2SettingsMulti"
              type="select2"
              required
              :multiple="true"
              v-if="!isReadonly"
            />
            <template v-else>
              <label class="form-label">Товар</label>
              <div v-if="isInternal">{{ item.smtkNames }}</div>
              <div v-else>{{ item.itc_codes }}</div>
            </template>
          </div>
        </div>
        <div class="mb-16">
          <div class="form-group" :class="[{ required: isInternal }]">
            <ComponentInput
              label="Страна"
              :select2Options="countryList"
              v-model="item.countryIds"
              :select2Settings="select2SettingsMulti"
              type="select2"
              required
              :multiple="true"
              v-if="!isReadonly"
            />
            <template v-else>
              <label class="form-label">Страна</label>
              <div v-if="isInternal">{{ item.countryNames }}</div>
              <div v-else>{{ item.locations }}</div>
            </template>
          </div>
        </div>

        <div class="mb-16" v-if="isInternal">
          <div class="form-group required">
            <ComponentInput
              v-model="item.brief"
              type="textarea"
              label="Анонс"
              required
              placeholder="Введите анонс"
              v-if="!isReadonly"
            />
            <template v-else>
              <label class="form-label">Анонс</label>
              <div>{{ item.brief }}</div>
            </template>
          </div>
        </div>
        <div class="mb-16">
          <div class="form-group" :class="[{ required: isInternal }]">
            <ComponentInput
              v-model="item.description"
              type="textarea"
              label="Полный текст"
              required
              v-if="!isReadonly"
              placeholder="Введите текст"
            />
            <template v-else>
              <label class="form-label">Полный текст</label>
              <div v-if="isInternal" v-html="html"></div>
              <div v-else>{{ item.title }}</div>
            </template>
          </div>
        </div>
        <div class="mb-16">
          <div class="form-group" :class="[{ required: isInternal }]">
            <ComponentInput v-model="item.url" label="Источник" required v-if="!isReadonly" placeholder="http://" />
            <template v-else>
              <label class="form-label">Источник</label>
              <div>
                <a :href="item.url" target="_blank">{{ item.url }}</a>
              </div>
            </template>
          </div>
        </div>
        <div class="mb-16">
          <div class="form-group" :class="[{ required: isInternal }]">
            <DateComponent v-model="item.datePublish" label="Дата публикации" required v-if="!isReadonly" />
            <template v-else>
              <label class="form-label">Дата публикации</label>
              <div v-if="isInternal">{{ $momentFormat(item.datePublish, 'DD MMMM YYYY') }}</div>
              <div v-else>{{ $momentFormat(item.dates, 'DD MMMM YYYY') }}</div>
            </template>
          </div>
        </div>
        <div class="mb-16" v-if="isInternal">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              v-model="item.isActive"
              id="chActive"
              :disabled="isReadonly"
            />
            <label for="chActive" class="form-check-label control-label">Выводить на главной</label>
          </div>
        </div>
      </div>

      <div class="card-info__row">
        <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" v-if="isInternal" />
        <ButtonStock btn-type="secondary" :title="isInternal ? 'Отмена' : 'Закрыть'" v-on:click="close" />
      </div>
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import DateComponent from '@/common/components/dateComponent';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock';

  import Api from '../api/massmedia';

  export default {
    name: 'MassMediaCard',
    components: {
      DateComponent,
      ComponentInput,
      ButtonStock,
    },
    mixins: [security],
    data() {
      return {
        /**
         * @public
         * Используется в миксине security
         */
        isReadonly: this.$route.params.action === 'view',
        id: this.$route.params.id,
        canEdit: false,
        item: {
          countryIds: [],
          categoryIds: [],
          smtkIds: [],
        },

        select2SettingsMulti: Constants.select2SettingsMultiple,
        categoryList: [],
        smtkList: [],
        countryList: [],
        isInternal: true,
      };
    },
    created() {
      if (this.id == 'external') {
        if (!this.$route.params.item) this.$router.push({ name: 'News', query: { tab: 3 } });
        else {
          this.isInternal = false;
          this.canEdit = false;
          this.item = JSON.parse(this.$route.params.item);
        }
      } else {
        this.checkPermissions([Constants.Permissions.MassMediaEdit, Constants.Permissions.MassMediaAccess]);
        this.checkPermission(Constants.Permissions.MassMediaEdit).then((response) => {
          this.canEdit = response.data;
        });

        this.loadDictionaries();
        this.loadCard();
      }
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          Api.find(this.id).then((response) => {
            this.item = response.data;
            this.item.description = this.item.description.replaceAll('\\n', '\r\n');
          });
        }
      },
      loadDictionaries() {
        Utils.loadSelectOptions('countryList', this.countryList);
        Utils.loadSelectOptions('massMediaCategory', this.categoryList);
        Utils.loadSelectOptions('massMediaSmtk', this.smtkList);
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', Constants.validationError, 'error');
          return;
        }

        Api.update(this.item)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.$router.push({ name: 'News', query: { tab: 3 } });
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'News', query: { tab: 3 } });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
    computed: {
      html: {
        get() {
          return this.item.description.replaceAll('\r\n', '<br>');
        },
      },
    },
  };
</script>

<style lang="scss"></style>
