import moment from 'moment';

import Constants from '@/common/constants';

export default {
  data() {
    return {
      DATE_FORMATS: Constants.DATE_FORMATS,
      upperLimitEnd: new Date(),
    };
  },
  computed: {
    upperLimitCreationStart() {
      if (this.filter.createdEnd) {
        return moment(this.filter.createdEnd, this.DATE_FORMATS['YYYY-MM-DD']).toDate();
      }
      return new Date();
    },
    lowerLimitCreationEnd() {
      return moment(this.filter.createdStart, this.DATE_FORMATS['YYYY-MM-DD']).toDate();
    },
    upperLimitModifiedStart() {
      if (this.filter.modifiedEnd) {
        return moment(this.filter.modifiedEnd, this.DATE_FORMATS['YYYY-MM-DD']).toDate();
      }
      return new Date();
    },
    lowerLimitModifiedEnd() {
      return moment(this.filter.modifiedStart, this.DATE_FORMATS['YYYY-MM-DD']).toDate();
    },
  },
};
