import constants from '@/common/constants';

import { NSITNVED_ACTIONS_NAME, NSITNVED_MUTATIONS_NAME } from '.';

import links from '@/utils/links';
import request from '@/utils/request';

export async function getNSITnvedByIDs(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.nsitnved.byIDs,
      mutate: NSITNVED_MUTATIONS_NAME.setNSITnvedByIDs,
      name: NSITNVED_ACTIONS_NAME.getNSITnvedByIDs,
    },
    params,
  );
}

export async function searchNSITnved(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.nsitnved.search,
      mutate: NSITNVED_MUTATIONS_NAME.setNSITnved,
      name: NSITNVED_ACTIONS_NAME.searchNSITnved,
    },
    {
      ...params,
      ...constants.paramsFullListDefault,
    },
  );
}
