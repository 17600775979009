import { createStore } from 'vuex';

import auth from '@/modules/auth/store/index';
import restriction from '@/modules/countryАnalytics/AccessConditions/state/restriction';
import exportPotentialMap from '@/modules/countryАnalytics/ExportPotential/state/map';
import countryAnalytics from '@/modules/countryАnalytics/store/analytics/index';
import countryAnalyticsPayments from '@/modules/countryАnalytics/store/payments/index';
import importAnalytics from '@/modules/importanalytics/store/importAnalytics/index';
import priorityImportAndImportDestination from '@/modules/importanalytics/store/priorityImportAndImportDestination/index';
import investmentAgreement from '@/modules/investmentAgreement/store/investmentAgreement/index';
import measure from '@/modules/measure/store';
import messenger from '@/modules/messenger/store/index';
import mpkProject from '@/modules/mpkProject/store/index';
import navigatorProductGroups from '@/modules/navigator/store/productGroups/index';
import navigatorScenarios from '@/modules/navigator/store/scenarios/index';
import navigatorStepCard from '@/modules/navigator/store/stepCards/index';
import ves from '@/modules/ves/store';

import directives from './directives';
import nsitnved from './nsitnved';

export default createStore({
  state: {
    adminAccess: null,
  },
  mutations: {
    setAccess(state, val) {
      state.adminAccess = !!val;
    },
  },
  actions: {
    setAdminAccess(context, value) {
      context.commit('setAccess', value);
    },
  },
  getters: {
    hasAdminAccess: (state) => {
      return state.adminAccess;
    },
  },
  modules: {
    auth,
    messenger,
    exportPotentialMap,
    restriction,
    investmentAgreement,
    directives,
    nsitnved,
    navigatorProductGroups,
    navigatorScenarios,
    navigatorStepCard,
    priorityImportAndImportDestination,
    countryAnalyticsPayments,
    countryAnalytics,
    importAnalytics,
    ...ves,
    ...measure,
    mpkProject,
  },
});
