export const routes = [
  {
    path: '/ves/heat-map-foreign-economic-relations',
    name: 'HeatMapForeignEconomicRelations',
    component: () => import('../view/HeatMapForeignEconomicRelations.vue'),
  },
  {
    path: '/ves/events',
    name: 'EventsVES',
    component: () => import('../view/EventsVES.vue'),
  },
  {
    path: '/ves/events/:id',
    name: 'EventsCardVES',
    component: () => import('../view/EventsCardVES.vue'),
  },
  {
    path: '/ves/implementation',
    name: 'Implementation',
    component: () => import('../view/Implementation.vue'),
  },
  {
    path: '/ves/passport-foreign-economic-relations',
    name: 'PassportForeignEconomicRelations',
    component: () => import('../view/PassportForeignEconomicRelations'),
  },
];
