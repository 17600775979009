<template>
  <div v-if="!country">
    <div class="button-list">
      <ul class="button-list__tabs">
        <li v-for="tab in tabMaps" :key="tab.id">
          <ButtonComponent
            mod="grey"
            :class="['button-redesign--tabs-analytics', { active: activeTabMapId === tab.id }]"
            @click="selectTabMap(tab)"
          >
            {{ tab.text }}
          </ButtonComponent>
        </li>
      </ul>
    </div>

    <div class="container-page big country-page">
      <div v-if="mapCreateStatuses[0] || activeTabMapId === 1" v-show="activeTabMapId === 1">
        <WorldHotIndicator />
        <div>
          <h2 class="title title--h3 title--select-country">Выберите страну</h2>
        </div>
        <div class="position-relative">
          <MapSelectCountry
            class="map-select-country"
            :countries="countries"
            :countryNames="countryNames"
            @selectMapCountry="selectMapCountry($event)"
          />

          <div class="list-of-summons-countries w-100">
            <div v-if="activeTabMapId === 1" class="mb-8">
              <Multiselect
                placeholder="Выберите страну..."
                label="text"
                :searchable="true"
                v-model="country"
                valueProp="id"
                track-by="text"
                noResultsText="Ничего не найдено."
                noOptionsText="Список пуст."
                :options="countries"
                @select="onCountryFilter"
              />
            </div>
            <template v-for="summonCountryItem in listOfSummonsCountries" :key="summonCountryItem.id">
              <!-- eslint-disable-next-line -->
              <button class="btn-presentation" @click="summonCountry(summonCountryItem.id)">
                {{ summonCountryItem.name }}
              </button>
            </template>
          </div>
        </div>
      </div>
      <div v-if="mapCreateStatuses[1] || activeTabMapId === 2" v-show="activeTabMapId === 2">
        <ProjectsMap />
      </div>
      <div v-if="mapCreateStatuses[2] || activeTabMapId === 3" v-show="activeTabMapId === 3">
        <ExportPotentialMap />
      </div>
      <div v-if="mapCreateStatuses[3] || activeTabMapId === 4" v-show="activeTabMapId === 4">
        <TradeInvestmentMap />
      </div>
      <div v-if="mapCreateStatuses[4] || activeTabMapId === 5" v-show="activeTabMapId === 5">
        <RestrictionStatisticTab />
      </div>
    </div>
  </div>
  <div v-if="country">
    <HeaderBlock
      :selectOptions="countries"
      :countryExternal="country"
      :tabData="tabData"
      :tabDataTwo="tabDataTwo"
      :tabDataFour="tabDataFour"
      :isUnfriendlyCountry="isUnfriendlyCountry"
      :activeTab="activeTab"
      :activeTabId="activeTabId"
      :selectTypeTab="selectTypeTab"
      @changeCountry="changeCountry"
      @onOpenPresentationDialog="onOpenPresentationDialog"
      @switchTab="setActiveTypeTab"
      @switchTabLVLTwo="selectTab"
    />
    <div class="container-page big country-analytics">
      <div v-if="activeTab === activeTabLabel.CREDIT_POLICY">
        <MonetaryPolicy :country-id="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.COMMON_PROJECTS">
        <JointProjects :country-id="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.FINANCE">
        <FinancialSector :country-id="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.INTERNATIONAL_TRADE">
        <InternationalTrade :country-id="country" v-if="selectTypeTab == 1" />
        <InternationalTradeRussia :country-id="country" v-else />
      </div>
      <div v-else-if="activeTab === activeTabLabel.CONNECTIONS_WITH_REGIONS">
        <ConnectionsWithRegions :country-id="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.PAYMENT_BALANCE">
        <PaymentBalance :currentCountry="currentCountry" />
      </div>

      <div v-else-if="activeTab === activeTabLabel.INTEGRATION_INDICATORS">
        <IntegrationIndicators :country="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.HEALTHCARE">
        <Healthcare :country="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.MACROECONOMIC">
        <MacroEcon :country="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.INVESTMENTS && activeTabId == 33">
        <InvestmentsWithRussia :country="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.INVESTMENTS">
        <Investments :country-id="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.ADDITIONAL_INFORMATION">
        <Reference :country-id="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.RATING">
        <CountryRanking :country-id="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.LIFE_INDEX">
        <LifeIndex :country-id="country" :indicator-values="lifeIndexSummary" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.FISCAL_POLICY">
        <Budget :country-id="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.GREEN_ECONOMY">
        <GreenEconomy :country-id="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.INSTITUTIONAL_COOPERATION">
        <InstitutionalForms :country-id="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.ACCESS_CONDITIONS" class="accessConditions">
        <AccessConditions :country-id="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.ECONOMIC_OVERVIEW">
        <TecRussia :country-id="country" :unfriendlyCountryInfo="getInfoAboutUnfriendlyCountry" />
        <div v-if="isLoading" class="loader">
          <Loader></Loader>
        </div>
        <template v-else>
          <div class="gdp-growth-chart">
            <p>Рост ВВП в сравнении с другими странами (%, г/г)</p>

            <div class="gdp-growth-chart__wrapper">
              <TopCountryStatisticChart
                :seriesProp="gdpSeriesData"
                :categories="gdpCategories"
                :options="gdpOption"
                :isShowLegend="true"
              ></TopCountryStatisticChart>
            </div>
          </div>

          <div class="top-statistic-countries">
            <div class="top-statistic-countries__index">
              <TopCountryStatistic title="Топ-5 отраслей импорта" :content="getIndustriesOfTopImport" />
            </div>

            <div class="top-statistic-countries__index">
              <TopCountryStatistic title="Топ-5 отраслей экспорта" :content="getIndustriesOfTopExport" />
            </div>

            <div class="top-statistic-countries__index">
              <TopCountryStatisticChart
                title="Топ-5 стран импорта"
                :seriesProp="importCountryData.data"
                :categories="importCountryData.categories"
                height="250"
              ></TopCountryStatisticChart>
            </div>

            <div class="top-statistic-countries__index">
              <TopCountryStatisticChart
                title="Топ-5 стран экспорта"
                :seriesProp="exportCountryData.data"
                :categories="exportCountryData.categories"
                height="250"
              ></TopCountryStatisticChart>
            </div>
          </div>
        </template>

        <EconomicsReview :country-id="country" />
        <!-- </div>
      <div v-else-if="activeTab === 'ТЭС с Россией'"> -->
      </div>
      <div v-else-if="activeTab === activeTabLabel.REFERENCE_INFORMATION_LIST">
        <ReferenceInfoList :country-id="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.REFERENCE_INFORMATION_ARCHIVE">
        <ReferenceInfoArchive :country-id="country" />
      </div>
      <div v-else-if="activeTab === activeTabLabel.REFERENCE_INFORMATION_HISTORY">
        <ReferenceInfoHistory :country-id="country" />
      </div>
    </div>
  </div>

  <ModalComponent
    title="Скачать презентацию"
    @close="presentationDialogState = false"
    v-model="presentationDialogState"
  >
    <div class="form__body">
      <div class="form__body-inputBlock mb-3 pb-3 form-group required">
        <p class="form-label">Страна</p>
        <Multiselect
          placeholder="Укажите страну"
          label="text"
          :searchable="true"
          v-model="presentationCountryId"
          valueProp="id"
          track-by="text"
          noResultsText="Ничего не найдено."
          noOptionsText="Список пуст."
          :options="countries"
        />
      </div>
      <div class="country-analytic-modal-input form__body-inputBlock mb-3 pb-3 form-group required">
        <p class="form-label">Год</p>
        <ComponentInput placeholder="Укажите год" type="number" v-model="presentationYear" required />
      </div>
      <div class="form__body-inputBlock">
        <ButtonStock title="Скачать" @click="createPresentation" />
      </div>
    </div>
  </ModalComponent>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import Multiselect from '@vueform/multiselect';

  import ComponentInput from '@/common/components/ComponentInput';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import text from '@/common/mixins/text';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import TopCountryStatisticChart from '@/components/ChartComponents/TopCountryStatisticChart';
  import Loader from '@/components/Loader';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import AccessConditions from '@/modules/countryАnalytics/AccessConditions/AccessConditions';
  import Budget from '@/modules/countryАnalytics/components/Budget';
  import CountryRanking from '@/modules/countryАnalytics/components/countryRanking/countryRanking';
  import EconomicsReview from '@/modules/countryАnalytics/components/EconomicsReview/EconomicsReview';
  import TecRussia from '@/modules/countryАnalytics/components/EconomicsReview/TecRussia';
  import FinancialSector from '@/modules/countryАnalytics/components/FinancialSector/FinancialSector';
  import GreenEconomy from '@/modules/countryАnalytics/components/GreenEconomy';
  import Healthcare from '@/modules/countryАnalytics/components/healthcare/healthcare';
  import InstitutionalForms from '@/modules/countryАnalytics/components/InstitutionalForms/InstitutionalForms';
  import IntegrationIndicators from '@/modules/countryАnalytics/components/integrationIndicators/IntegrationIndicators';
  import InternationalTrade from '@/modules/countryАnalytics/components/InternationalTrade/InternationalTrade';
  import InternationalTradeRussia from '@/modules/countryАnalytics/components/InternationalTrade/InternationalTradeRussia';
  import Investments from '@/modules/countryАnalytics/components/investments/investments';
  import LifeIndex from '@/modules/countryАnalytics/components/LifeIndex/lifeIndex';
  import MacroEcon from '@/modules/countryАnalytics/components/MacroEcon/MacroEcon';
  import MapSelectCountry from '@/modules/countryАnalytics/components/mapSelectCountry';
  import MonetaryPolicy from '@/modules/countryАnalytics/components/monetaryPolicy';
  import Reference from '@/modules/countryАnalytics/components/reference/reference';
  import ReferenceInfoArchive from '@/modules/countryАnalytics/components/referenceInfo/ReferenceInfoArchive.vue';
  import ReferenceInfoHistory from '@/modules/countryАnalytics/components/referenceInfo/referenceInfoHistory/ReferenceInfoHistory.vue';
  import ReferenceInfoList from '@/modules/countryАnalytics/components/referenceInfo/ReferenceInfoList.vue';
  import ExportPotentialMap from '@/modules/countryАnalytics/ExportPotential/component/exportPotentialMap';
  import TradeInvestmentMap from '@/modules/countryАnalytics/tradeInvestmentMap/tradeInvestmentMap';
  import ProjectsMap from '@/modules/mpkProject/views/ProjectsMap';

  import RestrictionStatisticTab from '../foreignMarket/components/analytics/statisticTab/RestrictionStatisticTab.vue';
  import Api from './api';
  import ConnectionsWithRegions from './components/connectionsWithRegions/ConnectionsWithRegions.vue';
  import TopCountryStatistic from './components/countryStatistic/TopCountryStatistic.vue';
  import HeaderBlock from './components/HeaderBlock.vue';
  import InvestmentsWithRussia from './components/investments/investmentsWithRussia.vue';
  import JointProjects from './components/jointProjects/JointProjects';
  import PaymentBalance from './components/payments/PaymentBalance.vue';
  import WorldHotIndicator from './components/WorldHotIndicator.vue';
  import { COUNTRY_ANALYTICS_ACTIONS_NAME, COUNTRY_ANALYTICS_GETTERS_NAME } from './store/analytics/directoryNames';
  import {
    ActiveTabLabel,
    DataTabs,
    FourthDataTabs,
    GdpOption,
    ListOfSummonsCountries,
    MapTabs,
    SecondDataTabs,
    SELECT_TYPE_TAB,
    ThirdDataTabs,
  } from './utils/constants';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    name: 'countryAnalytics',
    components: {
      ReferenceInfoHistory,
      ReferenceInfoArchive,
      ReferenceInfoList,
      JointProjects,
      LifeIndex,
      TradeInvestmentMap,
      ExportPotentialMap,
      ProjectsMap,
      AccessConditions,
      InstitutionalForms,
      MapSelectCountry,
      Budget,
      FinancialSector,
      InternationalTrade,
      InternationalTradeRussia,
      GreenEconomy,
      CountryRanking,
      Reference,
      Investments,
      MacroEcon,
      Healthcare,
      IntegrationIndicators,
      MonetaryPolicy,
      InvestmentsWithRussia,
      ModalComponent,
      ButtonStock,
      WorldHotIndicator,
      EconomicsReview,
      TecRussia,
      RestrictionStatisticTab,
      ButtonComponent,
      HeaderBlock,
      Multiselect,
      ComponentInput,
      PaymentBalance,
      TopCountryStatistic,
      TopCountryStatisticChart,
      Loader,
      ConnectionsWithRegions,
    },
    mixins: [security, text],
    data() {
      return {
        country: this.$route.query && this.$route.query.countryId > 0 ? this.$route.query.countryId : null,
        selectTypeTab: SELECT_TYPE_TAB,
        countries: [],
        countryNames: [],
        currentCountry: null,
        activeTab: ActiveTabLabel.ECONOMIC_OVERVIEW,
        activeTabId: 1,
        activeTabLabel: ActiveTabLabel,
        dataTabs: DataTabs,
        tabDataTwo: SecondDataTabs,
        tabDataThree: ThirdDataTabs,
        tabDataFour: FourthDataTabs,
        tabMaps: MapTabs,
        activeTabMap: '',
        activeTabMapId: 1,
        presentationDialogState: false,
        presentationCountryId: null,
        presentationYear: null,
        lifeIndexSummary: null,
        integrationIndicatorsHidden: true,
        lifeIndexHidden: true,
        mapCreateStatuses: [true, false, false, false, false],
        listOfSummonsCountries: ListOfSummonsCountries,
        isUnfriendlyCountry: false,
        isLoading: false,
        gdpOption: GdpOption,

        gdpSeries: [
          {
            name: '',
            data: [],
          },
          {
            name: '',
            data: [],
          },
        ],
        gdpCategories: [],

        importCountryData: {
          data: [],
          categories: [],
        },
        exportCountryData: {
          data: [],
          categories: [],
        },
      };
    },
    computed: {
      ...mapGetters({
        getSummaryLifeIndex: COUNTRY_ANALYTICS_GETTERS_NAME.getSummaryLifeIndex,
        getGdpValuesForeignTradeTurnoverWord: COUNTRY_ANALYTICS_GETTERS_NAME.getGdpValuesForeignTradeTurnoverWord,
        getIndustriesOfTopImport: COUNTRY_ANALYTICS_GETTERS_NAME.getIndustriesOfTopImport,
        getIndustriesOfTopExport: COUNTRY_ANALYTICS_GETTERS_NAME.getIndustriesOfTopExport,
        getCountriesOfTopImport: COUNTRY_ANALYTICS_GETTERS_NAME.getCountriesOfTopImport,
        getCountriesOfTopExport: COUNTRY_ANALYTICS_GETTERS_NAME.getCountriesOfTopExport,
        getNsiCountryListWithData: DIRECTIVES_GETTERS_NAME.getNsiCountryListWithData,
        getLoadingTopStatisticCountries: COUNTRY_ANALYTICS_GETTERS_NAME.getLoadingTopStatisticCountries,
        getInfoAboutUnfriendlyCountry: COUNTRY_ANALYTICS_GETTERS_NAME.getInfoAboutUnfriendlyCountry,
      }),
      gdpSeriesData() {
        return {
          datasets: [
            {
              data: this.gdpSeries[0].data,
              backgroundColor: '#01A39D',
              label: this.gdpSeries[0].name,
              barPercentage: '1',
            },
            {
              data: this.gdpSeries[1].data,
              backgroundColor: '#D9D9D9',
              label: this.gdpSeries[1].name,
              barPercentage: '1',
            },
          ],
          labels: ['', '', '', '', '', ''],
        };
      },
      tabData() {
        if (Number(this.country) === Constants.countryRussiaId) {
          return this.dataTabs.filter(({ text }) => text !== this.activeTabLabel.CONNECTIONS_WITH_REGIONS);
        }
        return this.dataTabs;
      },
    },
    methods: {
      ...mapActions({
        getLifeIndexSummary: COUNTRY_ANALYTICS_ACTIONS_NAME.getLifeIndexSummary,
        getForeignTradeTurnoverWordGdpValues: COUNTRY_ANALYTICS_ACTIONS_NAME.getForeignTradeTurnoverWordGdpValues,
        getUnfriendlyCountryInfo: COUNTRY_ANALYTICS_ACTIONS_NAME.getUnfriendlyCountryInfo,
        getTopImportIndustries: COUNTRY_ANALYTICS_ACTIONS_NAME.getTopImportIndustries,
        getTopExportIndustries: COUNTRY_ANALYTICS_ACTIONS_NAME.getTopExportIndustries,
        getTopImportCountries: COUNTRY_ANALYTICS_ACTIONS_NAME.getTopImportCountries,
        getTopExportCountries: COUNTRY_ANALYTICS_ACTIONS_NAME.getTopExportCountries,
        getNsiCountryWithData: DIRECTIVES_ACTIONS_NAME.getNsiCountryWithData,
      }),
      setActiveTypeTab(id) {
        this.selectTypeTab = id;
        switch (id) {
          case 1:
            this.selectFirstTab(this.tabData);
            break;
          case 2:
            this.selectFirstTab(this.tabDataTwo);
            break;
          case 3:
            this.selectFirstTab(this.tabDataThree);
            break;
          case 4:
            this.selectFirstTab(this.tabDataFour);
            break;
          default:
            break;
        }
      },
      selectTab(tab) {
        this.activeTab = tab?.text;
        this.activeTabId = tab?.id;
      },
      selectTabMap(tab) {
        this.activeTabMap = tab.text;
        this.activeTabMapId = tab.id;
        this.mapCreateStatuses[tab.id - 1] = true;
      },
      selectFirstTab(items) {
        const visibleTabs = items.filter((x) => !x.hidden);
        if (visibleTabs && visibleTabs.length > 0) this.selectTab(visibleTabs[0]);
        else this.selectTab(null);
      },
      selectMapCountry({ code, isUnfriendly }) {
        this.isUnfriendlyCountry = isUnfriendly;
        this.setActiveTypeTab(3);
        this.country = this.countries.find((c) => c.code === Number(code))?.id;
        this.currentCountry = this.countries.find((c) => c.id === Number(this.country));
      },
      onCountryFilter() {
        if (this.country === 0) this.country = null;
        if (this.country != null) {
          this.fetchUnfriendlyCountryInfo(this.country);
        }
        this.currentCountry = this.countries.find((c) => c.id === Number(this.country));
        if (
          this.activeTab === this.activeTabLabel.INTEGRATION_INDICATORS &&
          this.currentCountry?.groupId !== Constants.countryGroupEAEC
        ) {
          this.setActiveTypeTab(1);
        }
      },
      onOpenPresentationDialog() {
        this.presentationCountryId = this.country;
        this.presentationYear = null;
        this.presentationDialogState = true;
      },
      createPresentation() {
        if (this.presentationCountryId > 0 && this.presentationYear) {
          Api.createPresentation({
            countryId: this.presentationCountryId,
            year: this.presentationYear,
          }).then(() => {
            this.presentationDialogState = false;
          });
        } else {
          Constants.alert.fire('Презентация', 'Заполните поля', 'error');
        }
      },
      setTabVisibility() {
        const permissions = Array(
          Constants.Permissions.CountryAnalyticsEconomicsRussia - Constants.Permissions.CountryAnalyticsMacroEcon + 1,
        )
          .fill()
          .map((_, idx) => Constants.Permissions.CountryAnalyticsMacroEcon + idx);
        this.getAccessList(permissions).then((response) => {
          for (let i = 0; i < this.tabData.length; i++) {
            if (!this.tabData[i].hidden)
              this.tabData[i].hidden = !response.data[i + Constants.Permissions.CountryAnalyticsMacroEcon];
            if (this.tabData[i].id == 2)
              this.integrationIndicatorsHidden = !response.data[i + Constants.Permissions.CountryAnalyticsMacroEcon];
            else if (this.tabData[i].id == 96)
              this.lifeIndexHidden = !response.data[i + Constants.Permissions.CountryAnalyticsMacroEcon];
          }

          for (let i = 0; i < this.tabDataTwo.length; i++) {
            if (!this.tabDataTwo[i].hidden)
              this.tabDataTwo[i].hidden = !response.data[i + Constants.Permissions.CountryAnalyticsRussiaForeignTrade];
          }

          for (let i = 0; i < this.tabDataThree.length; i++) {
            if (!this.tabDataThree[i].hidden)
              this.tabDataThree[i].hidden = !response.data[i + Constants.Permissions.CountryAnalyticsEconomicsReview];
          }
        });

        this.prepareTabDataFour();
      },
      async prepareTabDataFour() {
        this.tabDataFour = await Promise.all(
          this.tabDataFour.map(async (tab) => {
            try {
              const response = await this.checkPermission(tab.permissionId);
              return { ...tab, hidden: !response.data };
            } catch (err) {
              console.error(err);
              return { ...tab, hidden: true };
            }
          }),
        );
      },
      summonCountry(country) {
        this.country = country;
        this.onCountryFilter();
      },
      changeCountry(country) {
        this.country = country;
        this.onCountryFilter();
      },

      async loadData() {
        let tab = this.tabData.find((t) => t.id === 96);
        tab.hidden = true;
        if (this.country > 0) {
          this.isLoading = true;

          this.lifeIndexSummary = await this.fetchLifeIndexSummary(this.country);
          if (this.lifeIndexSummary) tab.hidden = this.lifeIndexHidden;
          else if (this.activeTabId == 96) {
            this.setActiveTypeTab(1);
          }

          await this.getForeignTradeTurnoverWordGdpValues({ countryId: this.country });
          this.buildGdpValuesForeignTradeTurnoverWord();

          await this.getTopImportIndustries({ countryId: this.country });

          await this.getTopExportIndustries({ countryId: this.country });

          await this.getTopImportCountries({ countryId: this.country });

          this.importCountryData = this.buildCountrySeries('getCountriesOfTopImport');

          await this.getTopExportCountries({ countryId: this.country });

          this.exportCountryData = this.buildCountrySeries('getCountriesOfTopExport');

          if (this.getLoadingTopStatisticCountries) {
            this.isLoading = false;
          }
        }
      },
      async fetchUnfriendlyCountryInfo(countryId) {
        this.isUnfriendlyCountry = false;

        const res = await this.getUnfriendlyCountryInfo({ id: countryId });

        if (res?.status === 200) {
          this.isUnfriendlyCountry = true;
        }
      },
      async fetchLifeIndexSummary(countryId) {
        await this.getLifeIndexSummary({ id: countryId });
        return this.getSummaryLifeIndex;
      },
      buildGdpValuesForeignTradeTurnoverWord() {
        this.gdpCategories = [];

        const currentYears = [];
        const previousYears = [];
        const currentDate = new Date();
        if (this.getGdpValuesForeignTradeTurnoverWord[0] == null) {
          this.gdpSeries[0].data = this.gdpSeries[1].data = [];
          return;
        }
        this.getGdpValuesForeignTradeTurnoverWord.forEach(({ countryName, currentYearValue, previousYearValue }) => {
          this.gdpCategories.push(countryName);

          currentYears.push(this.formatNumber(currentYearValue, 1, ' ', '.'));
          previousYears.push(this.formatNumber(previousYearValue, 1, ' ', '.'));
        });

        this.gdpSeries = [
          { name: `${currentDate.getFullYear() - 1} г.`, data: previousYears },
          { name: `${currentDate.getFullYear()} г. (прогноз)`, data: currentYears },
        ];
      },
      buildCountrySeries(getter) {
        const countrySeries = [];
        const countryCategory = [];
        this[getter].forEach(({ key, value }) => {
          countryCategory.push(key);
          countrySeries.push(this.formatNumber(value, 1, ' ', '.'));
        });
        return {
          data: {
            datasets: [
              {
                data: countrySeries,
                backgroundColor: '#01A39D',
                label: '',
                barPercentage: '0.6',
              },
            ],
            labels: ['', '', '', '', ''],
          },
          categories: countryCategory,
        };
      },
    },
    async created() {
      const countryId = this.$route?.query?.countryId;
      if (countryId) {
        this.country = countryId;
        await this.loadData();
      }

      await this.getNsiCountryWithData();

      this.countries = this.getNsiCountryListWithData.map((c) => ({
        id: c.id,
        text: c.name,
        fullName: c.nameFull || c.name,
        code: c.code,
        groupId: c.countryGroupId,
      }));

      this.currentCountry = this.countries.find((c) => c.id === Number(this.country));

      this.countryNames = [
        ...this.getNsiCountryListWithData.map((item) => {
          return {
            nameFull: item.nameFull || item.name,
            id: item.id,
            code: item.code,
          };
        }),
      ];

      this.setTabVisibility();
    },
    watch: {
      currentCountry() {
        let tab = this.tabData.find((t) => t.id === 2);
        tab.hidden = this.integrationIndicatorsHidden || this.currentCountry?.groupId !== Constants.countryGroupEAEC;
      },
      country: function (val) {
        if (Number(val) === Constants.countryRussiaId && this.activeTabId === 8) {
          this.activeTabId = this.dataTabs[11].id;
          this.activeTab = this.dataTabs[11].text;
        }
        this.$router.replace({
          query: this.country ? { countryId: this.country } : null,
        });
        this.loadData();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './styles/countryAnalytics.scss';
  @import './styles/buttonList.scss';
  @import '@/assets/theme/default/title.scss';
</style>

<style lang="scss">
  .accessConditions .restriction .container {
    padding: 0px !important;
    max-width: initial !important;
  }
  .country-analytic-modal-input {
    input {
      border-radius: 4px;
    }
  }
  .gdp-growth-chart {
    .gdp-growth-chart__wrapper {
      .bar-chart-tooltip__category {
        height: 18% !important;
      }
    }

    .bar-chart-tooltip__category {
      &:first-of-type > span {
        font-weight: 700 !important;
      }
    }
  }
</style>
