<template>
  <div>
    <apexchart class="chartLine" type="line" :options="chartOptions" :series="chartSeries"></apexchart>
  </div>
</template>

<script>
  import chartConstants from './chartConstants';

  export default {
    props: {
      xData: {
        type: Array,
        default: () => chartConstants.defaultXData,
      },
      yData: {
        type: Array,
        default: () => [30, 40, 45, 50, 30, 40, 45, 50, 30, 40, 45, 50],
      },
    },
    data() {
      return {
        chartOptions: {
          chart: {
            fontFamily: 'Fira Sans',
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
          },
          colors: ['#73EA48'],
          xaxis: {
            categories: chartConstants.defaultXData,
            labels: {
              style: {
                fontSize: '15px',
                lineHeight: '16px',
                letterSpacing: '-0.3px',
                colors: '#989898',
              },
            },
            offsetY: 16,
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: false,
              color: '#FF5733', // Цвет оси Y для количества
            },
            labels: {
              style: {
                fontSize: '15px',
                lineHeigth: '16px',
                colors: '#989898',
              },
            },
          },
        },
        chartSeries: [
          {
            name: 'Сумма ущерба, млрд $',
            type: 'line', // Тип столбца
            data: chartConstants.defaultYData,
          },
        ],
      };
    },
    mounted() {
      this.createChart();
    },
    watch: {
      xData() {
        this.createChart();
      },
      yData() {
        this.createChart();
      },
    },
    methods: {
      createChart() {
        this.chartOptions = {
          chart: {
            fontFamily: 'Fira Sans',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            offsetY: 20,
            height: 45,
          },
          colors: ['#73EA48'],
          xaxis: {
            categories: this.xData, // Замените данными года
            labels: {
              style: {
                fontSize: '15px',
                lineHeight: '16px',
                letterSpacing: '-0.3px',
                colors: '#989898',
              },
            },
            trim: true,
            offsetY: 16,
          },
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: false,
              color: '#FF5733', // Цвет оси Y для количества
            },
            labels: {
              style: {
                fontSize: '15px',
                lineHeigth: '16px',
                colors: '#989898',
              },
            },
          },
          noData: {
            text: 'Данные отсутствуют',
            verticalAlign: 'top',
            style: {
              color: '#231F20',
              fontSize: '32px',
              lineHeight: '40px',
            },
          },
          responsive: [
            {
              breakpoint: 768,
              options: {
                chart: {
                  width: 768,
                },
              },
            },
          ],
        };

        this.chartSeries = [
          {
            name: 'Сумма ущерба, млрд $',
            type: 'line', // Тип столбца
            data: this.yData,
          },
        ];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chartLine {
    width: 700px;
    height: 300px;
    @media (max-width: $laptop) {
      width: auto;
    }
  }
</style>
