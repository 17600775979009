<template>
  <div class="d-flex align-items-center">
    <div class="country-analytics-new__selector country-analytics-new__map-selector mr-3" id="div-map-selector">
      <ComponentInput
        class="select2"
        :select2Options="maps"
        v-model="selectedMapId"
        placeholder="Выберите карту"
        type="select2"
        :select2Settings="select2SettingsNoClear"
      />
    </div>
    <div class="country-analytics-new__selector country-analytics-new__filter-selector mr-3" v-if="selectedMapId == 1">
      <ComponentInput
        class="select2"
        :select2Options="projectMap.industries"
        v-model="projectMap.industryId"
        placeholder="Отрасль"
        type="select2"
        :select2Settings="select2Settings"
      />
    </div>
    <div class="country-analytics-new__selector country-analytics-new__filter-selector" v-if="selectedMapId == 1">
      <ComponentInput
        class="select2"
        :select2Options="projectMap.purposes"
        v-model="projectMap.purposeId"
        placeholder="Цель проекта"
        type="select2"
        :select2Settings="select2Settings"
      />
    </div>
    <div
      class="country-analytics-new__selector country-analytics-new__filter-selector limit-width-selector"
      v-if="selectedMapId == 3"
    >
      <ComponentInput
        class="select2"
        :select2Options="exportPotentialMap.products"
        v-model="exportPotentialMap.productCode"
        placeholder="Наименование или код ТН ВЭД"
        type="select2"
        :select2Settings="select2SettingsWithAjax"
      />
    </div>
    <div class="country-analytics-new__selector country-analytics-new__filter-selector" v-if="selectedMapId == 4">
      <ComponentInput
        class="select2"
        :select2Options="tradeInvestmentMap.countries"
        v-model="tradeInvestmentMap.countryId"
        placeholder="Страна"
        type="select2"
        :select2Settings="select2Settings"
      />
    </div>
    <div class="country-analytics-new__selector country-analytics-new__filter-selector" v-if="selectedMapId == 4">
      <ComponentInput
        class="select2"
        :select2Options="tradeInvestmentMap.agreementTypes"
        v-model="tradeInvestmentMap.agreementTypeId"
        placeholder="Тип соглашения"
        type="select2"
        :select2Settings="select2SettingsNoSearch"
      />
    </div>
  </div>
  <ProjectTagControl v-if="selectedMapId == 1" :data="projectMap.projectSummary" @tagClicked="onTagClicked" />
  <MapControl
    :countries="countries"
    :items="currentMapDataSource"
    :colors="currentColorSource"
    :mouseUpFunction="onMouseUp"
  />
  <MapLegendControl :colors="currentColorSource" :title="currentTitle" />
  <section>
    <template>
      <ProjectPopup
        ref="projectPopup"
        v-if="selectedMapId == 1"
        :countryId="clickedCountryId"
        :industryId="projectMap.industryId"
        :purposeId="projectMap.purposeId"
        :statusId="projectMap.currentTag"
        @allProjectClick="onAllProjectClick"
      />
      <AnalyticsPopup
        ref="analyticsPopup"
        v-if="selectedMapId == 2"
        :countryId="clickedCountryId"
        @detailsClick="onDetailsClick"
      />
      <ExportPotentialPopup
        ref="exportPotentialPopup"
        v-if="selectedMapId == 3"
        :countryId="clickedCountryId"
        :productCode="exportPotentialMap.productCode"
      />
      <TradeInvestmentPopup
        ref="tradeInvestmentPopup"
        v-if="selectedMapId == 4"
        :sourceCountryId="tradeInvestmentMap.countryId"
        :countryId="clickedCountryId"
        :agreementTypeId="tradeInvestmentMap.agreementTypeId"
        @agreementDetailsClick="tradeInvestmentDetailClick"
        @tradeInvestmentCountryClick="tradeInvestmentCountryClick"
      />
    </template>
  </section>
  <section>
    <ModalComponent
      @close="tradeInvestmentMap.dialogWindow = false"
      v-model="tradeInvestmentMap.dialogWindow"
      :width="800"
    >
      <TradeInvestmentModal
        :selectedAgreementId="tradeInvestmentMap.selectedAgreementId"
        :countryName="tradeInvestmentMap.selectedCountryName"
        @agreementsButtonClick="onAgreementsButtonClick"
      />
    </ModalComponent>
  </section>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';
  import runtimeConfig from '@/common/runtime-config';

  import ModalComponent from '@/components/modals/ModalComponent';

  import Api from '@/modules/countryAnalyticsNew/api/api';
  import Colors from '@/modules/countryAnalyticsNew/api/colors';
  import TradeInvestmentModal from '@/modules/countryAnalyticsNew/components/modals/TradeInvestmentModal';
  import MapControl from '@/modules/countryAnalyticsNew/components/nested/MapControl';
  import MapLegendControl from '@/modules/countryAnalyticsNew/components/nested/MapLegendControl';
  import ProjectTagControl from '@/modules/countryAnalyticsNew/components/nested/ProjectTagControl';
  import AnalyticsPopup from '@/modules/countryAnalyticsNew/components/popups/AnalyticsPopup';
  import ExportPotentialPopup from '@/modules/countryAnalyticsNew/components/popups/ExportPotentialPopup';
  import ProjectPopup from '@/modules/countryAnalyticsNew/components/popups/ProjectPopup';
  import TradeInvestmentPopup from '@/modules/countryAnalyticsNew/components/popups/TradeInvestmentPopup';
  import API_Country from '@/modules/nsi/api/country';
  import API_NSI from '@/modules/nsi/api/nsi';

  export default {
    name: 'MapContainer',
    emits: ['mapChanged', 'pageChangeRequest'],
    components: {
      ComponentInput,
      MapControl,
      ProjectTagControl,
      MapLegendControl,
      ModalComponent,
      ProjectPopup,
      AnalyticsPopup,
      ExportPotentialPopup,
      TradeInvestmentPopup,
      TradeInvestmentModal,
    },
    props: {
      countries: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        select2SettingsNoClear: Object.assign({ minimumResultsForSearch: -1 }, Constants.select2SettingsNoClear),
        select2SettingsNoSearch: Object.assign({ minimumResultsForSearch: -1 }, Constants.select2Settings),
        select2Settings: Constants.select2Settings,
        select2SettingsWithAjax: {
          language: Constants.langRu,
          allowClear: true,
          ajax: {
            delay: 500,
            url: runtimeConfig.apiBaseUrl + '/api/exportpotential/getProductListDynamic',
            dataType: 'json',
            data: function (params) {
              var query = {
                term: params.term,
                isAdmin: true,
              };
              return query;
            },
            processResults: function (responseData) {
              let data = responseData
                .filter((item) => item.isActive)
                .map((item) => {
                  if (item.name && item.code && item.id) {
                    return {
                      text: `${item.code} - ${item.name}`,
                      id: item.code,
                    };
                  }
                });
              const selectData = data.sort((a, b) => a.text.localeCompare(b.text));
              return {
                results: selectData,
              };
            },
            cache: true,
          },
        },
        maps: [
          { id: 1, text: 'Карта проектов' },
          { id: 2, text: 'Аналитическая карта мира' },
          { id: 3, text: 'Карта перспективности страновых рынков' },
          { id: 4, text: 'Тепловая карта соглашений' },
        ],
        selectedMapId: 1,
        clickedCountryId: null,
        projectMap: {
          industries: [],
          industryId: null,
          purposes: [],
          purposeId: null,
          projectSummary: null,
          currentTag: 0,
        },
        analyticsMap: {
          analyticsSummary: null,
        },
        exportPotentialMap: {
          potentialSummary: null,
          products: [],
          productCode: '',
        },
        tradeInvestmentMap: {
          tradeInvestmentSummary: null,
          countries: [],
          countryId: null,
          agreementTypes: [],
          agreementTypeId: null,
          dialogWindow: false,
          selectedAgreementId: null,
          selectedCountryId: null,
          selectedCountryName: null,
        },
      };
    },
    created() {
      this.loadDictionariesByMapType();
      this.loadDataByMapType();
      this.$emit(
        'mapChanged',
        this.maps.find((x) => x.id == this.selectedMapId),
      );
    },
    methods: {
      loadDictionariesByMapType() {
        switch (this.selectedMapId.toString()) {
          case '1':
            this.loadProjectDictionaries();
            break;
          case '2':
          case '3':
            break;
          case '4':
            this.loadTradeInvestmentDictionaries();
            break;
          default:
            break;
        }
      },
      loadDataByMapType() {
        switch (this.selectedMapId.toString()) {
          case '1':
            this.loadProjectSummary();
            break;
          case '2':
            this.loadAnalyticsSummary();
            break;
          case '3':
            this.loadPotentialSummary();
            break;
          case '4':
            this.loadTradeInvestmentSummary();
            break;
          default:
            break;
        }
      },
      loadProjectDictionaries() {
        let request = { pageNumber: 1, pageSize: 0 };
        if (this.projectMap.industries.length == 0)
          API_NSI.search('NsiIndustry', request).then((response) => {
            if (response.data) {
              this.projectMap.industries = response.data.items.map((o) => {
                return { text: o.name, id: o.id };
              });
            }
            this.projectMap.industries.sort((a, b) => (a.text > b.text ? 1 : -1));
          });

        if (this.projectMap.purposes.length == 0)
          API_NSI.search('MpkProjectPurpose', request).then((response) => {
            if (response.data) {
              this.projectMap.purposes = response.data.items.map((o) => {
                return { text: o.name, id: o.id };
              });
            }
          });
      },
      loadTradeInvestmentDictionaries() {
        let request = { pageNumber: 1, pageSize: 0 };
        if (this.tradeInvestmentMap.agreementTypes.length == 0) {
          API_NSI.search('NsiFreeTradeAgreementType', request).then((response) => {
            if (response.data) {
              this.tradeInvestmentMap.agreementTypes = response.data.items.map((o) => {
                return { text: o.name, id: o.id };
              });
            }
          });
        }
        if (this.tradeInvestmentMap.countries.length == 0) {
          API_Country.search().then((response) => {
            this.tradeInvestmentMap.countries = response.data.items.map((item) => {
              return { id: item.id, code: item.code, text: item.name };
            });
          });
        }
      },
      loadProjectSummary() {
        //TODO Loader
        let params = { pageNumber: 1, pageSize: 0 };
        if (this.projectMap.industryId) params.industryIds = [this.projectMap.industryId];
        if (this.projectMap.purposeId) params.purposeIds = [this.projectMap.purposeId];

        Api.projectSummaryByCountry(params).then((response) => {
          this.projectMap.currentTag = 0;
          this.projectMap.projectSummary = response.data;
        });
      },
      loadAnalyticsSummary() {
        Api.getAnalyticsSummaryByCountries().then((response) => {
          this.analyticsMap.analyticsSummary = response.data;
        });
      },
      loadPotentialSummary() {
        if (this.exportPotentialMap.productCode) {
          Api.getExportPotentialsByCountry(this.exportPotentialMap.productCode).then((response) => {
            this.exportPotentialMap.potentialSummary = response.data;
          });
        } else this.exportPotentialMap.potentialSummary = {};
      },
      loadTradeInvestmentSummary() {
        if (this.tradeInvestmentMap.countryId) {
          Api.getAgreementSummaryByCountry(
            this.tradeInvestmentMap.countryId,
            this.tradeInvestmentMap.agreementTypeId,
          ).then((response) => {
            this.tradeInvestmentMap.tradeInvestmentSummary = response.data;
            let country = this.tradeInvestmentMap.countries.find((x) => x.id == this.tradeInvestmentMap.countryId);
            if (country) this.tradeInvestmentMap.tradeInvestmentSummary[country.code] = -1;
          });
        } else this.tradeInvestmentMap.tradeInvestmentSummary = {};
      },
      onTagClicked(item) {
        this.projectMap.currentTag = item.statusId;
      },
      onMouseUp(layer) {
        layer.unbindPopup();
        if (layer.feature.properties.countryStatus == 0) return;

        let countryCode = Number(layer.feature.properties.iso_n3);
        this.clickedCountryId = this.countries.find((x) => x.code == countryCode)?.id;
        switch (this.selectedMapId.toString()) {
          case '1':
            layer.bindPopup(this.$refs.projectPopup.$el);
            break;
          case '2':
            layer.bindPopup(this.$refs.analyticsPopup.$el);
            break;
          case '3':
            layer.bindPopup(this.$refs.exportPotentialPopup.$el);
            break;
          case '4':
            layer.bindPopup(this.$refs.tradeInvestmentPopup.$el);
            break;
          default:
            break;
        }
        layer.openPopup();
      },
      onAllProjectClick(countryId) {
        this.$emit('pageChangeRequest', { typeId: 3, countryId: countryId });
      },
      onDetailsClick(countryId) {
        this.$emit('pageChangeRequest', { typeId: 2, countryId: countryId });
      },
      tradeInvestmentDetailClick(agreementId, countryId, countryName) {
        this.tradeInvestmentMap.dialogWindow = false;
        this.tradeInvestmentMap.selectedAgreementId = agreementId;
        this.tradeInvestmentMap.selectedCountryId = countryId;
        this.tradeInvestmentMap.selectedCountryName = countryName;
        this.$nextTick(() => {
          this.tradeInvestmentMap.dialogWindow = true;
        });
      },
      onAgreementsButtonClick() {
        this.tradeInvestmentMap.countryId = this.tradeInvestmentMap.selectedCountryId;
        this.tradeInvestmentMap.dialogWindow = false;
      },
      tradeInvestmentCountryClick(id) {
        this.$emit('pageChangeRequest', { typeId: 5, countryId: id });
      },
    },
    watch: {
      selectedMapId: function () {
        this.projectMap.currentTag = 0;
        this.loadDictionariesByMapType();
        this.loadDataByMapType();
        this.$emit(
          'mapChanged',
          this.maps.find((x) => x.id == this.selectedMapId),
        );
      },
      'projectMap.industryId': function () {
        this.loadDataByMapType();
      },
      'projectMap.purposeId': function () {
        this.loadDataByMapType();
      },
      'exportPotentialMap.productCode': function () {
        if (this.exportPotentialMap.productCode) this.loadDataByMapType();
        else this.exportPotentialMap.potentialSummary = {};
      },
      'tradeInvestmentMap.countryId': function () {
        if (this.tradeInvestmentMap.countryId) this.loadDataByMapType();
        else this.tradeInvestmentMap.tradeInvestmentSummary = {};
      },
      'tradeInvestmentMap.agreementTypeId': function () {
        if (this.tradeInvestmentMap.countryId) this.loadDataByMapType();
        else this.tradeInvestmentMap.tradeInvestmentSummary = {};
      },
    },
    computed: {
      currentMapDataSource() {
        switch (this.selectedMapId.toString()) {
          case '1':
            return this.projectMap.projectSummary
              ? this.projectMap.projectSummary.summary.find((x) => x.statusId == this.projectMap.currentTag)
                  ?.itemsByCountry ?? {}
              : {};
          case '2':
            return this.analyticsMap.analyticsSummary ? this.analyticsMap.analyticsSummary : {};
          case '3':
            return this.exportPotentialMap.potentialSummary ? this.exportPotentialMap.potentialSummary : {};
          case '4':
            return this.tradeInvestmentMap.tradeInvestmentSummary ? this.tradeInvestmentMap.tradeInvestmentSummary : {};
          default:
            return {};
        }
      },
      currentColorSource() {
        switch (this.selectedMapId.toString()) {
          case '1':
            return Colors.projectColors.map((x) => {
              return {
                value: x.value,
                minValue: x.minValue,
                maxValue: x.maxValue,
                color: x.color[this.projectMap.currentTag],
              };
            });
          case '2':
            return Colors.analyticsColors;
          case '3':
            return Colors.potentialColors;
          case '4':
            return Colors.tradeInvestmentColors;
          default:
            return [];
        }
      },
      currentTitle() {
        switch (this.selectedMapId.toString()) {
          case '1':
            return 'Количество проектов';
          default:
            return '';
        }
      },
    },
  };
</script>

<style lang="scss">
  .country-analytics-new__map-selector {
    .select2 .select2-container .select2-selection__rendered {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      height: 100%;
      overflow: visible;
      padding-top: 6px;
    }

    .select2 .select2-container--default .select2-selection {
      min-height: 36px !important;
      padding-left: 0;
    }
  }
</style>

<style lang="scss" scoped>
  .project-tag {
    padding: 10px;
    gap: 4px;
    background: #edf4f9;
    border-radius: 4px;
    font-size: 16px;
    line-height: 14px;
    text-transform: uppercase;
    color: #000000;
  }
  .limit-width-selector {
    max-width: 400px;
  }
</style>
