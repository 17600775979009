<template>
  <div>
    <div
      class="filter-trigger"
      :class="{ active: openAdditional, 'filter-have-value': filterNotEmpty }"
      @click="openAdditional = !openAdditional"
    >
      <p>Фильтр</p>
    </div>

    <div v-if="openAdditional" class="list-projects__search-additional">
      <div class="list-projects__search-additional-col">
        <div class="list-projects__search-additional-inputBlock">
          <p class="label">Выберите отрасли</p>
          <FilterInput
            :select2Options="industries"
            placeholder="Выберите"
            type="select2"
            :multiple="true"
            v-model="filter.industryIds"
            :select2Settings="select2SettingsMultiple"
            v-on:valueChangedDebounced="changeFilter"
          />
        </div>

        <div class="list-projects__search-additional-inputBlock">
          <p class="label">Введите название</p>
          <FilterInput placeholder="Введите" v-model="filter.name" v-on:valueChangedDebounced="changeFilter" />
        </div>

        <div class="list-projects__search-additional-inputBlock">
          <p class="label">Выберите статусы</p>
          <FilterInput
            :select2Options="statuses"
            placeholder="Выберите"
            type="select2"
            :multiple="true"
            v-model="filter.statusIds"
            :select2Settings="select2SettingsMultiple"
            v-on:valueChangedDebounced="changeFilter"
          />
        </div>

        <div class="list-projects__search-additional-inputBlock">
          <p class="label">Выберите участников Российская сторона</p>
          <FilterInput
            :select2Options="participantsDomestic"
            placeholder="Выберите"
            type="select2"
            :multiple="true"
            v-model="filter.participantsDomesticIds"
            :select2Settings="select2SettingsMultiple"
            v-on:valueChangedDebounced="changeFilter"
          />
        </div>

        <div class="list-projects__search-additional-inputBlock">
          <p class="label">Введите участника Иностранная сторона</p>
          <FilterInput
            placeholder="Введите"
            v-model="filter.participantsForeign"
            v-on:valueChangedDebounced="changeFilter"
          />
        </div>

        <div class="list-projects__search-additional-inputBlock">
          <p class="label">Ключевые слова</p>
          <FilterInput placeholder="Введите" v-model="filter.description" v-on:valueChangedDebounced="changeFilter" />
        </div>
      </div>

      <div class="list-projects__search-additional-col">
        <div class="list-projects__search-additional-inputBlock">
          <p class="label">Выберите цели</p>
          <FilterInput
            :select2Options="purposes"
            placeholder="Выберите"
            type="select2"
            :multiple="true"
            v-model="filter.purposeIds"
            :select2Settings="select2SettingsMultiple"
            v-on:valueChangedDebounced="changeFilter"
          />
        </div>

        <div class="list-projects__search-additional-inputBlock date">
          <p class="label">Введите срок реализации начало</p>
          <FilterInput
            class="mb-6"
            v-model="filter.startMonthYearFrom"
            type="month"
            v-on:valueChangedDebounced="changeFilter"
          />
          <FilterInput
            class="mb-6"
            v-model="filter.startMonthYearTo"
            type="month"
            v-on:valueChangedDebounced="changeFilter"
          />
        </div>

        <div class="list-projects__search-additional-inputBlock date">
          <p class="label">Введите срок реализации конец</p>
          <FilterInput
            class="mb-6"
            v-model="filter.endMonthYearFrom"
            type="month"
            v-on:valueChangedDebounced="changeFilter"
          />
          <FilterInput
            class="mb-6"
            v-model="filter.endMonthYearTo"
            type="month"
            v-on:valueChangedDebounced="changeFilter"
          />
        </div>

        <div class="list-projects__search-additional-inputBlock">
          <p class="label">Введите объем инвестиций</p>
          <FilterInput
            class="mb-6"
            placeholder="От..."
            v-model="filter.investmentsAmountFrom"
            type="integer"
            v-on:valueChangedDebounced="changeFilter"
          />
          <FilterInput
            class="mb-6"
            placeholder="До..."
            v-model="filter.investmentsAmountTo"
            type="integer"
            v-on:valueChangedDebounced="changeFilter"
          />
        </div>
      </div>
      <DefaultFilter :filterValue="filter" @loadFilter="onLoadFilter" />
    </div>
    <div class="table-container list-projects__results-table js-table-arrow">
      <table class="stockTable">
        <thead>
          <tr>
            <th class="col-1">Статус</th>
            <th class="col-2">Название</th>
            <th class="col-2">Российская<br />сторона</th>
            <th class="col-1">Иностранная<br />сторона</th>
            <th class="col-1">Начало</th>
            <th class="col-1">Конец</th>
            <th class="col-1 pad-lr0">
              <div>Объем <br />инвестиций</div>
              <div>(млн $ США)</div>
            </th>
            <!-- <th class="col-1 pad-lr0">Возможный эконом. эффект</th> -->
            <th class="col-1">Отрасль</th>
            <th class="col-2">Описание</th>
          </tr>
        </thead>
        <tbody v-if="page?.items?.length && !isLoading">
          <tr v-for="item in page.items" :key="item.id" :class="getColor(item.statusId)" @dblclick="viewCard(item.id)">
            <td>{{ item.status.name }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.participantsDomesticStr }}</td>
            <td>{{ item.participantsForeign }}</td>
            <td>
              {{ $momentFormat(item.startMonthYear, 'MMMM YYYY') }}
            </td>
            <td>{{ $momentFormat(item.endMonthYear, 'MMMM YYYY') }}</td>
            <td align="center">{{ item.investmentsAmount }}</td>
            <!-- <td align="center">{{ item.potencialEffect }}</td> -->
            <td class="wrdbreak">{{ item.industry.name }}</td>
            <td>
              <p v-snip="3">{{ item.description }}</p>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="isLoading">
          <tr>
            <td class="text-center" colspan="11">Загрузка...</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="11">Нет данных.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="page.pagesTotal > 1">
      <Pagination
        :page-count="page.pagesTotal"
        :current-page="page.pageNumber"
        @change="onPageChange($event, false)"
        @more="onPageChange($event, true)"
      />
    </div>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import Utils from '@/common/utils';

  import API_ORG from '@/modules/admin/api/organization';
  import Api from '@/modules/mpkProject/api/index';
  import API_NSI from '@/modules/nsi/api/nsi';

  export default {
    name: 'JointProjects',
    components: { FilterInput, DefaultFilter },
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    mixins: [filtermanager],
    data() {
      return {
        request: {
          pageNumber: 1,
          pageSize: Constants.pageSize,
        },
        page: {},
        isLoading: false,
        filter: {
          countryIds: [],
          name: '',
          purposeIds: [],
          statusIds: [],
          industryIds: [],
          participantsDomesticIds: [],
        },
        filterNotEmpty: false,
        statuses: [],
        industries: [],
        purposes: [],
        select2SettingsMultiple: Constants.select2SettingsMultiple,

        openAdditional: false,
        participantsDomestic: [],
      };
    },
    created() {
      this.loadDictionaries();
    },
    mounted() {
      if (this.countryId) {
        this.loadFilter().then((response) => {
          let filter = response;
          if (filter) {
            this.filter = filter;
          }
          this.filterNotEmpty = this.isFilterNotEmpty(this.filter, ['countryIds']);
          this.loadPage();
        });
      }
    },
    methods: {
      loadPage(append) {
        this.filter.countryIds = [this.countryId];
        var params = Object.assign({}, this.filter, this.request);
        this.isLoading = true;
        Api.search(params)
          .then((response) => {
            if (!append) {
              this.page = {};
              this.$nextTick(() => {
                this.page = response.data;
              });
            } else if (response.data) {
              this.page.pageNumber = response.data.pageNumber;
              this.page.items = this.page.items.concat(response.data.items);
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error?.response?.data);
          });
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      getColor(statusId) {
        if (statusId === 1) return 'implemented';
        if (statusId === 2) return 'realize';
        return 'perspective';
      },
      loadDictionaries() {
        var request = { pageNumber: 1, pageSize: 0 };
        // API_NSI.search("MpkProjectStatus", request).then((response) => {
        //   if (response.data) {
        //     this.statuses = response.data.items.map((o) => {
        //       return { text: o.name, id: o.id };
        //     });
        //   }
        // });
        Utils.loadSelectOptions('mpkProjectStatusList', this.statuses);

        API_NSI.search('NsiIndustry', request).then((response) => {
          if (response.data) {
            this.industries = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
        API_NSI.search('MpkProjectPurpose', request).then((response) => {
          if (response.data) {
            this.purposes = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
        API_ORG.search(request).then((response) => {
          if (response.data) {
            this.participantsDomestic = response.data.items.map((o) => {
              return { text: o.name, id: o.id };
            });
          }
        });
      },
      changeFilter() {
        this.request.pageNumber = 1;
        this.saveFilter(this.filter);
        this.filterNotEmpty = this.isFilterNotEmpty(this.filter, ['countryIds']);
        this.loadPage(false);
      },
      viewCard(id) {
        // this.$router.push({ name: "ProjectCard", params: { id: id } });
        let routeData = this.$router.resolve({ name: 'ProjectCard', params: { id: id } });
        window.open(routeData.href, '_blank');
      },
      onLoadFilter(item) {
        this.filter = item;
        this.changeFilter();
      },
    },
    watch: {
      countryId: function () {
        this.loadPage();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .filter-trigger {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 16px;
    cursor: pointer;
    &:after {
      content: ' ';
      display: block;
      width: 8px;
      height: 8px;
      border-left: 2.5px solid black;
      border-bottom: 2.5px solid black;
      transform: rotate(315deg);
      transition: 0.3s;
      margin-left: 8px;
    }
    &.active {
      &:after {
        transform: rotate(135deg);
      }
    }
    &.filter-have-value {
      color: red;
    }
  }
  .list-projects {
    &__search {
      &-visible {
        display: flex;
        margin-bottom: 16px;
        &-block {
          max-width: 130px;
          width: 100%;
          &:not(:last-of-type) {
            margin-right: 16px;
          }
          &.component {
            max-width: 400px;
          }
          &.download {
            margin-left: auto;
          }
        }
        button {
          width: 100%;
        }
      }
      &-additional {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &-col {
          max-width: 450px;
          width: 100%;
        }
        &-inputBlock {
          margin-bottom: 16px;
          .label {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
    &__results {
      &-table {
        margin: 0 8px 42px 8px;
        max-height: 60vh;
        width: 100%;
        thead {
          &:nth-of-type(1) {
            text-align: center;
          }
        }
        tbody {
          tr {
            &.implemented {
              td {
                &:nth-of-type(1) {
                  color: #7de7a9;
                }
              }
            }
            &.realize {
              td {
                &:nth-of-type(1) {
                  color: #f1c507;
                }
              }
            }
            &.perspective {
              td {
                &:nth-of-type(1) {
                  color: #84c5eb;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
