<template>
  <div class="table-component notif">
    <table>
      <thead>
        <tr>
          <th v-for="th in optionsThead" :key="th.id">{{ th.title }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="option in options" :key="option.id">
          <td>{{ option.name }}</td>
          <td v-if="option.date">{{ option.date }}</td>
          <td v-if="option.init">{{ option.init }}</td>
          <td class="text-center" v-if="option.industry">
            <i class="d-block text-center fas fa-random"></i>
          </td>
          <td>
            <IconComponent v-if="option.status == 1" name="attentionSigh" />
            <IconComponent v-if="option.status == 2" name="banSigh" />
            <IconComponent v-if="option.status == 3" name="goodSigh" />
            <IconComponent v-if="option.status == 4" name="refreshSigh" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent.vue';
  export default {
    name: 'tableProject',
    components: {
      IconComponent,
    },
    props: {
      options: {
        type: Object,
        default: () => {},
      },
      optionsThead: {
        type: Array,
        default: () => [],
      },
    },
  };
</script>

<style scoped lang="scss">
  .table-component {
    max-height: 480px;
    height: 100%;
    tbody {
      max-height: 450px !important;
    }
    th,
    td {
      text-align: left !important;
    }
    th:nth-child(1) {
      width: 35%;
      padding: 10px 0 10px 10px !important;
    }
    th:nth-child(2) {
      width: 35%;
    }
    th:nth-child(3) {
      width: 15%;
      padding-left: 0 !important;
    }
    th:nth-child(4) {
      width: 15%;
    }
    td:nth-child(1) {
      width: 35%;
      padding: 10px 0 10px 10px;
    }
    td:nth-child(2) {
      width: 35%;
    }
    td:nth-child(3) {
      width: 15%;
    }
    td:nth-child(4) {
      width: 15%;
    }
  }
</style>
