const TYPE_SECTION_PHD = 'portfolio-change-dynamics';
const TYPE_SECTION_DQSIE = 'dynamics-quantity-status-inclusion-exclusion';
const TYPE_SECTION_NJC = 'number-jobs-created';
const TYPE_SECTION_SGP = 'share-green-projects';
const TYPE_SECTION_DOP = 'distribution-of-projects';

const DEFAULT_COUNTRY = 235; // Россия
const DEFAULT_DYNAMIC = 1;

const DONUT_PROJECTS_MPK_PORTFOLIO_NAME = ['Реализовано проектов', 'Не реализовано проектов'];

const DONUT_PROJECTS_MPK_PORTFOLIO_COLOR = ['#0078C8', '#D1D5DB'];

const DONUT_PROJECTS_MPK_PORTFOLIO_INFO = [
  {
    id: 'implemented-projects',
    name: 'Реализовано проектов',
    color: '#0078C8',
    count: 0,
  },
  {
    id: 'total-projects',
    name: 'Всего проектов',
    color: '#D1D5DB',
    count: 0,
  },
];

const NUMBER_OF_TEMPORARY_JOBS = 'Количество временных рабочих мест';

const NUMBER_OF_PERMANENT_JOBS = 'Количество постоянных рабочих мест';

const COLORS = ['#DC3545', '#0078C8', '#FFD966', '#28A745', '#7A61FF', '#43EDDA', '#8f4800', '#008f7e', '#113806'];

const CURRENT_PERIOD = 'Текущий период';

const PREVIOUS_PERIOD = 'Предыдущий период';

// цвета для легенды карты проектов
const COLORS_LEGEND = [
  '#DBE1E6',
  '#D6DFE7',
  '#CEDDEA',
  '#C9DBEC',
  '#C5DAEE',
  '#BFD9F0',
  '#B7D6F3',
  '#AED4F7',
  '#96CCFF',
  '#96CCFF',
  '#82C3FF',
  '#82C3FF',
  '#67B6FF',
  '#67B6FF',
  '#2995F7',
  '#2995F7',
  '#0D70CD',
  '#0D70CD',
  '#01519B',
  '#01519B',
];

const COLOR_DEFAULT = '#E3E3E3';
const COLOR_MAX = '#00386B';
const COLOR_BORDER = '#60a7ee';
const GREENLAND_CODE = 304;

const MPK_PROJECT_STATUS_ID = {
  EXPLOITATION: 1,
  INVEST: 2,
  PRED_INVEST: 3,
  STOPPED: 4,
};

const FileFormats = [
  { id: 1, text: 'Файл Excel (XLSX)' },
  { id: 2, text: 'Файл Word (DOCX)' },
  { id: 3, text: 'Презентация PowerPoint (PPTX)' },
  { id: 4, text: 'Файл PDF (PDF)' },
];

export default {
  TYPE_SECTION_PHD,
  TYPE_SECTION_DQSIE,
  TYPE_SECTION_NJC,
  TYPE_SECTION_SGP,
  TYPE_SECTION_DOP,
  DEFAULT_COUNTRY,
  DEFAULT_DYNAMIC,
  DONUT_PROJECTS_MPK_PORTFOLIO_NAME,
  DONUT_PROJECTS_MPK_PORTFOLIO_COLOR,
  DONUT_PROJECTS_MPK_PORTFOLIO_INFO,
  NUMBER_OF_TEMPORARY_JOBS,
  NUMBER_OF_PERMANENT_JOBS,
  COLORS,
  CURRENT_PERIOD,
  PREVIOUS_PERIOD,
  COLORS_LEGEND,
  COLOR_MAX,
  COLOR_DEFAULT,
  COLOR_BORDER,
  GREENLAND_CODE,
  MPK_PROJECT_STATUS_ID,
  FileFormats,
};
