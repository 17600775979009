export const BASE_VES = 'baseVES';

export const BASE_VES_ACTIONS_NAME = {
  getYearLast: `${BASE_VES}/getYearLast`,
};

export const BASE_VES_MUTATIONS_NAME = {
  setYearFirst: 'setYearFirst',
};

export const BASE_VES_GETTERS_NAME = {
  getYearOptions: `${BASE_VES}/getYearOptions`,
  getYearAndMonthOptions: `${BASE_VES}/getYearAndMonthOptions`,
  getYearOptionsLoad: `${BASE_VES}/getYearOptionsLoad`,
  getYearAndMonthOptionsLoad: `${BASE_VES}/getYearAndMonthOptionsLoad`,
};
