<template>
  <button
    v-bind:disabled="disabled"
    :class="['btn-' + btnType, { ' btn-container': mode === 'icon' || mode === 'iconRight' }]"
  >
    <IconComponent v-if="mode == 'icon'" :name="iconName" />
    <span v-if="title.length > 0">{{ title }}</span>
    <slot />
    <IconComponent v-if="mode == 'iconRight'" :name="iconName" />
  </button>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent.vue';

  export default {
    name: 'ButtonStock',
    components: {
      IconComponent,
    },
    props: {
      iconName: {
        default: '',
        type: String,
      },
      btnType: {
        default: 'primary',
        type: String,
      },
      title: {
        default: '',
        type: String,
      },
      disabled: {
        default: false,
        type: Boolean,
      },
      mode: {
        default: 'default',
        type: String,
        validator: (value) => ['default', 'icon', 'iconRight'].includes(value),
      },
    },
  };
</script>

<style scoped lang="scss"></style>
