<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка страны</p>
    </div>

    <tabs>
      <tab name="Основная информация" :selected="true">
        <div class="row">
          <div class="mt-4 col-6">
            <PictureUpload v-on:pictureSelected="pictureSelected" :initialPhoto="item.picture" :readOnly="isReadonly" />
          </div>
          <div class="col-6">
            <div class="form-group required">
              <ComponentInput
                v-model="item.name"
                label="Название"
                required
                maxLength.number="250"
                placeholder="Введите название"
                :disabled="isReadonly"
              />
            </div>
            <div class="form-group required">
              <ComponentInput
                v-model="item.nameFull"
                label="Полное название"
                required
                placeholder="Введите полное название"
                :disabled="isReadonly"
              />
            </div>
            <div class="form-group">
              <ComponentInput
                v-model="item.nameEng"
                label="Название на английском"
                placeholder="Введите название"
                :disabled="isReadonly"
              />
            </div>
          </div>
        </div>
        <div class="row align-items-end">
          <div class="col-3">
            <div class="form-group required">
              <ComponentInput v-model="item.code" label="Цифровой код страны" required :disabled="isReadonly" />
              <div class="invalid-msg" v-if="v$.item.code.$error">Введите корректный код - только цифры</div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <ComponentInput
                v-model="item.codeForDuties"
                label="Цифровой код страны (для пошлин)"
                :disabled="isReadonly"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <ComponentInput v-model="item.codeAlpha2" label="Буквенный код альфа-2" :disabled="isReadonly" />
              <div class="invalid-msg" v-if="v$.item.codeAlpha3.$error">
                Введите корректный код - 2 буквенных символа
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <ComponentInput v-model="item.codeAlpha3" label="Буквенный код альфа-3" :disabled="isReadonly" />
              <div class="invalid-msg" v-if="v$.item.codeAlpha3.$error">
                Введите корректный код - 3 буквенных символа
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <ComponentInput v-model="item.currency" label="Валюта" :disabled="isReadonly" />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <ComponentInput
                v-model="item.territory"
                label="Территория (квадратных километров)"
                type="number"
                :disabled="isReadonly"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <ComponentInput
                v-model="item.usdExchangeRate"
                label="Валютный курс (к доллару США)"
                :disabled="isReadonly"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <DateComponent v-model="item.usdExchangeRateDate" label="на дату" :disabled="isReadonly" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <ComponentInput
                v-model="item.countryGroupId"
                label="Группа стран"
                :select2Options="countryGroupList"
                type="select2"
                :select2Settings="select2Settings"
                :disabled="isReadonly"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <ComponentInput
                v-model="item.transitCountryIds"
                label="Страны транзита"
                :select2Options="countries"
                type="select2"
                multiple
                :select2Settings="select2SettingsMulti"
                :disabled="isReadonly"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <ComponentInput
                v-model="item.gdpRating"
                type="integer"
                :min="0"
                label="Рейтинг по размеру ВВП"
                :disabled="isReadonly"
              />
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <ComponentInput
                v-model="item.egdiRating"
                type="integer"
                :min="0"
                label="Рейтинг по индексу развития эл. правительства"
                :disabled="isReadonly"
              />
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <ComponentInput
                v-model="item.gfcRating"
                type="integer"
                :min="0"
                label="Рейтинг по индексу глобальных финансовых центров"
                :disabled="isReadonly"
              />
            </div>
          </div>
        </div>
        <div class="row mt-10">
          <div class="checkboxInput">
            <input type="checkbox" v-model="item.displayInAnalytics" id="chAnalytics" :disabled="isReadonly" />
            <label for="chAnalytics">Отображать в аналитике по странам</label>
          </div>
          <div class="checkboxInput">
            <input type="checkbox" v-model="item.isActive" id="chActive" :disabled="isReadonly" />
            <label for="chActive">Запись активна</label>
          </div>
        </div>
      </tab>
      <tab name="Дополнительно">
        <div class="form-group">
          <label class="form-label">Политическая обстановка</label>
          <!-- <editor
              api-key="no-api-key"
              v-model="item.politicalSituation"
              :init="tinyOptions"
              :value="item.politicalSituation"
              output-format="html"
              :disabled="isReadonly"
          /> -->
          <TinyMceEditor v-model="item.politicalSituation" />
          <!-- <component-input
              v-model="item.politicalSituation"
              type="textarea"
              label="Политическая обстановка"
            /> -->
        </div>
        <!-- <div class="form-group">
          <component-input
              v-model="item.countryMembershipIds"
              label="Членство в региональных и международных организациях"
              :select2Options="organizations"
              type="select2"
              multiple
              :select2Settings="select2SettingsMulti"
              :disabled="isReadonly"
          />
        </div> -->
        <div class="row">
          <div class="form-group col col-lg-12">
            <label class="form-label"
              >Членство страны в региональных блоках, международных организациях/объединениях</label
            >
          </div>
          <div class="form-group col col-lg-10">
            <div v-for="(block, idx) in item.countryBlocks" :key="idx" class="row">
              <div class="form-group col col-lg-5">
                <ComponentInput v-model="block.name" placeholder="Название организации" />
                <div class="mt-4">
                  <ComponentInput v-model="block.url" placeholder="Ссылка" />
                </div>
              </div>
              <div class="country-block-picture form-group col col-lg-6">
                <PictureUpload
                  v-on:pictureSelected="blockPictureSelected"
                  :initialPhoto="block.picture"
                  :additionalData="block"
                  :isRound="false"
                />
              </div>
              <div class="form-group col col-lg-1">
                <IconComponent @click="deleteBlock(idx)" name="delete" />
              </div>
            </div>
          </div>
          <div class="form-group col col-lg-2">
            <ButtonStock @click="addBlock()" btnType="blue" title="Добавить" />
          </div>
        </div>
      </tab>
    </tabs>

    <div class="mb-16">
      <ButtonStock v-if="!isReadonly" class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock :title="isReadonly ? 'Закрыть' : 'Отмена'" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { alpha, maxLength, minLength, numeric, required } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput';
  import DateComponent from '@/common/components/dateComponent';
  import PictureUpload from '@/common/components/PictureUpload';
  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';
  //import Editor from '@tinymce/tinymce-vue'
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import TinyMceEditor from '@/components/tinymceeditor';

  import API from '@/modules/nsi/api/country';

  import OrgApi from '../../navigator/api/organization';
  import CountryApi from '../api/country';

  export default {
    name: 'CountryCard',
    components: {
      ComponentInput,
      ButtonStock,
      PictureUpload,
      tabs,
      tab,
      TinyMceEditor,
      IconComponent,
      DateComponent,
    },
    data() {
      return {
        id: this.$route.params.id,
        isReadonly: this.$route.params.action === 'view',
        item: {
          countryBlocks: [{ name: '', url: '' }],
        },
        select2Settings: Constants.select2Settings,
        select2SettingsMulti: Constants.select2SettingsMultiple,
        countryGroupList: [],
        countries: [],
        organizations: [],
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadCard() {
        API.find(this.id).then((response) => {
          this.item = response.data;
          if (!this.item.countryBlocks || this.item.countryBlocks.length === 0) {
            this.item.countryBlocks = [];
            this.item.countryBlocks.push({ name: '', url: '', picture: '' });
          }
        });
      },
      loadDictionaries() {
        Utils.loadSelectOptions('CountiesGroupList', this.countryGroupList);
        CountryApi.search({ pageNumber: 1, pageSize: 0, isActive: true })
          .then((response) => {
            this.countries = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error.response.data);
          });
        const request = {
          pageNumber: 1,
          pageSize: 0,
          typeIds: [Constants.organizationType.regionalOrInternational],
          isActive: true,
        };
        OrgApi.search(request)
          .then((response) => {
            this.organizations = response.data.items.map((item) => ({ id: item.id, text: item.name }));
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        this.item.CountryGroup = null;
        if (this.item.CountryGroupId == 0) {
          this.item.CountryGroupId = null;
        }
        if (!this.item.codeForDuties) {
          this.item.codeForDuties = null;
        }
        API.update(this.item)
          .then((response) => {
            Constants.alert.fire(
              'Сохранение',
              response.data.success ? Constants.commonSuccess : Constants.commonError,
              response.data.success ? 'success' : 'error',
            );
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Countries' });
      },
      pictureSelected(data) {
        this.item.picture = data;
      },
      blockPictureSelected(data, block) {
        block.picture = data;
      },
      addBlock() {
        this.item.countryBlocks.unshift({ name: '', url: '', picture: '' });
      },
      deleteBlock(idx) {
        this.item.countryBlocks.splice(idx, 1);
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {
          code: { required, maxLength: maxLength(10), numeric },
          codeAlpha2: { maxLength: maxLength(2), minLength: minLength(2), alpha },
          codeAlpha3: { maxLength: maxLength(3), minLength: minLength(3), alpha },
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  .country-block-picture::v-deep {
    div:first-child {
      display: flex;
      flex-direction: row-reverse;
      align-items: start;
    }
    .imagePreviewWrapper {
      margin: 0 0 0 15px;
      border: 1px solid #d6e4ec;
      width: 120px;
      height: 120px;
    }
    .col {
      padding: 7px;
    }
  }
</style>
