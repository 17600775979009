import axios from 'axios';

export default {
  loadDialogs: async function (searchText) {
    return await axios.get('api/messenger/chats', { params: { searchText: searchText } });
  },
  loadMessages: async function (dialogId, params) {
    return await axios.get(`api/messenger/chats/${dialogId}/messages`, { params: params });
  },
  loadEmployers: async function (request) {
    return await axios.get(`api/messenger/contacts`, { params: request });
  },
  postMessage: function (data) {
    return axios.post(`/api/messenger/chats/${data.chatId}/messages`, data);
  },
  getUnreadMessageCount: function (dialogId) {
    let url = `/api/messenger/messages/unreadcount`;
    if (dialogId) {
      url += `?chatId=${dialogId}`;
    }
    return axios.get(url, {});
  },
  getUnreadMessagesCount: function (chatIds) {
    return axios.post(`/api/messenger/messages/unreadcounts`, { ids: chatIds });
  },
  readMessage: function (id) {
    return axios.post(`/api/messenger/messages/${id}/read`, {});
  },
  readMessages: function (ids) {
    return axios.post(`/api/messenger/messages/bulkread`, { ids: ids });
  },
  async findChatByEmployeeId(employeeId) {
    return await axios.get(`/api/messenger/chatbyemployee/${employeeId}`, {});
  },
  async findUserChatByEmployeeId(employeeId) {
    return await axios.get(`/api/messenger/userchatbyemployee/${employeeId}`, {});
  },
  async updateChatSettings(data) {
    return await axios.post(`/api/messenger/chats/${data.id}/usersettings`, data);
  },
  async createChat(chatDto) {
    return await axios.post(`/api/messenger/chats`, chatDto);
  },

  async addChatMembers(id, request) {
    return await axios.post(`/api/messenger/chats/${id}/members`, request);
  },
};
