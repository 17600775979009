<template>
  <div class="Tes">
    <h3 class="mb-3">Инвестиции в рамках ЕАЭС (млн $ США)</h3>
    <LineChart
      :chart-data="[200, 500, 400, -100, 150]"
      format-label=" млн $"
      :labels="['2018', '2019', '2020', '2021', '2022']"
      :displayDataLabels="false"
    />
    <h3 class="mb-3 mt-5 d-flex align-items-center">
      <IconComponent class="mr-1" name="database" />
      Прямые инвестиции из других государств - 123 млн $ США
    </h3>
    <h4 class="mb-3 mt-5">Прямые инвестиции в процентах ВВП</h4>
    <LineChart
      format-label="%"
      :labels="['2018', '2019', '2020', '2021', '2022']"
      :chart-data="[100, 50, 40, -100, 20]"
      :displayDataLabels="false"
    />
    <h4 class="mb-3 mt-5">Взаимная торговля товарами (млн руб)</h4>
    <LineChart
      format-label=" млн ₽"
      :labels="['2018', '2019', '2020', '2021', '2022']"
      :chart-data="[100, 500, 400, 10, 20]"
      :displayDataLabels="false"
    />
    <h3 class="mb-3 mt-5">Таблица со странами партнерами - членами ЕАЭС</h3>
    <div class="table-container js-table-arrow">
      <table class="stockTable">
        <thead>
          <th>Страна</th>
          <th colspan="5" style="text-align: center">Взаимная торговля товарами</th>
        </thead>
        <tbody>
          <tr style="background-color: #ade0ff">
            <td class="font-weight-bold">ЕАЭС</td>
            <td class="font-weight-bold">92,2</td>
            <td class="font-weight-bold">127,4</td>
            <td class="font-weight-bold">110,1</td>
            <td class="font-weight-bold">102,2</td>
            <td class="font-weight-bold">89,0</td>
          </tr>
          <tr>
            <td>Армения</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Беларусь</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Казахстан</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Киргизия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
        </tbody>
      </table>
    </div>
    <h3 class="mb-3 mt-5">Таблица взаимной торговли стран ЕАЭС</h3>
    <div class="table-container js-table-arrow">
      <table class="stockTable">
        <thead>
          <th>Страна</th>
          <th colspan="5" style="text-align: center">Взаимная торговля товарами</th>
        </thead>
        <tbody>
          <tr style="background-color: #ade0ff">
            <td class="font-weight-bold">ЕАЭС</td>
            <td class="font-weight-bold">92,2</td>
            <td class="font-weight-bold">127,4</td>
            <td class="font-weight-bold">110,1</td>
            <td class="font-weight-bold">102,2</td>
            <td class="font-weight-bold">89,0</td>
          </tr>
          <tr>
            <td>Армения</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Беларусь</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Казахстан</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Киргизия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
        </tbody>
      </table>
    </div>
    <h3 class="my-5 d-flex align-items-center">
      <IconComponent class="mr-1" name="database" />
      Доля от общего объема торговли - 12%
    </h3>
    <h3 class="mb-3">Внешняя торговля товарами с третьими странами (млрд $ США)</h3>
    <LineChart
      format-label=" млрд"
      :labels="['2018', '2019', '2020', '2021', '2022']"
      :chart-data="[50, 50, 100, 0, 20]"
      :displayDataLabels="false"
    />
    <h3 class="mb-3 mt-5">Таблица стран-членов ЕАЭС в разрезе стран-партнеров</h3>
    <div class="table-container js-table-arrow">
      <table class="stockTable">
        <thead>
          <tr>
            <th style="font-size: 14px" rowspan="2">Страна</th>
            <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">Экспорт</th>
            <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">Импорт</th>
            <th colspan="1" style="text-align: center; font-size: 14px; border-bottom: none !important">Сальдо</th>
          </tr>
          <tr>
            <th style="text-align: center">млн $</th>
            <th>в % к 2019 г</th>
            <th>млн $</th>
            <th>в % к 2019 г</th>
            <th>млн $</th>
          </tr>
        </thead>
        <tbody>
          <tr style="background-color: #ade0ff">
            <td class="font-weight-bold">Всего</td>
            <td class="font-weight-bold">92,2</td>
            <td class="font-weight-bold">127,4</td>
            <td class="font-weight-bold">110,1</td>
            <td class="font-weight-bold">102,2</td>
            <td class="font-weight-bold">89,0</td>
          </tr>
          <tr>
            <td>Армения</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Беларусь</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Казахстан</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Киргизия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
        </tbody>
      </table>
    </div>
    <h3 class="mb-3 mt-5">Таблица со структурой групп товаров со странами-членами ЕАЭС</h3>
    <div class="table-container js-table-arrow">
      <table class="stockTable">
        <thead>
          <tr>
            <th style="font-size: 14px" rowspan="2">Страна</th>
            <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">Экспорт</th>
            <th colspan="2" style="text-align: center; font-size: 14px; border-bottom: none !important">Импорт</th>
            <th colspan="1" style="text-align: center; font-size: 14px; border-bottom: none !important">Сальдо</th>
          </tr>
          <tr>
            <th style="text-align: center">млн $</th>
            <th>в % к 2019 г</th>
            <th>млн $</th>
            <th>в % к 2019 г</th>
            <th>млн $</th>
          </tr>
        </thead>
        <tbody>
          <tr style="background-color: #ade0ff">
            <td class="font-weight-bold">Всего</td>
            <td class="font-weight-bold">92,2</td>
            <td class="font-weight-bold">127,4</td>
            <td class="font-weight-bold">110,1</td>
            <td class="font-weight-bold">102,2</td>
            <td class="font-weight-bold">89,0</td>
          </tr>
          <tr>
            <td>Армения</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Беларусь</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Казахстан</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Киргизия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>Россия</td>
            <td>92,2</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
          </tr>
        </tbody>
      </table>
    </div>
    <h3 class="mb-3 mt-5">Таблица с группой товаров со странами-членами ЕАЭС</h3>
    <div class="table-container js-table-arrow">
      <table class="stockTable">
        <thead>
          <tr>
            <th width="96px" style="font-size: 14px" rowspan="2">Код ТН ВЭД ЕАЭС</th>
            <th width="142px" style="font-size: 14px" rowspan="2">Наименование</th>
            <th colspan="3" style="text-align: center; font-size: 14px; border-bottom: none !important">Экспорт</th>
            <th colspan="3" style="text-align: center; font-size: 14px; border-bottom: none !important">Импорт</th>
          </tr>
          <tr>
            <th style="text-align: center">млн $</th>
            <th>в % к 2019 г</th>
            <th>в % к итогу по экспорту</th>
            <th>млн $</th>
            <th>в % к 2019 г</th>
            <th>в % к итогу по экспорту</th>
          </tr>
        </thead>
        <tbody>
          <tr style="background-color: #ade0ff">
            <td class="font-weight-bold">Всего</td>
            <td class="font-weight-bold">92,2</td>
            <td class="font-weight-bold">127,4</td>
            <td class="font-weight-bold">110,1</td>
            <td class="font-weight-bold">102,2</td>
            <td class="font-weight-bold">89,0</td>
            <td class="font-weight-bold">89,0</td>
            <td class="font-weight-bold">89,0</td>
          </tr>
          <tr>
            <td>1</td>
            <td class="text-left">Живые животные; продукты животного происхождения</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
            <td>89,0</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>01</td>
            <td class="text-left">Живые животные</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
            <td>89,0</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>02</td>
            <td class="text-left">Мясо и пищевые мясные субпродукты</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
            <td>89,0</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>03</td>
            <td class="text-left">Рыба и ракообразные моллюски и прочие водные беспозвоночные</td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
            <td>89,0</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>04</td>
            <td class="text-left">
              Молочная продукция; яйца птиц; мед натуральный; пищевые продукты животного происхождения, в другом месте
              не поименованные или включенные
            </td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
            <td>89,0</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>06</td>
            <td class="text-left">
              Продукты животного происхождения, в другом месте не поименованные или не включенные
            </td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
            <td>89,0</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>07</td>
            <td class="text-left">
              Продукты животного происхождения, в другом месте не поименованные или не включенные
            </td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
            <td>89,0</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>08</td>
            <td class="text-left">
              Продукты животного происхождения, в другом месте не поименованные или не включенные
            </td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
            <td>89,0</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>09</td>
            <td class="text-left">
              Продукты животного происхождения, в другом месте не поименованные или не включенные
            </td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
            <td>89,0</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>10</td>
            <td class="text-left">
              Продукты животного происхождения, в другом месте не поименованные или не включенные
            </td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
            <td>89,0</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>11</td>
            <td class="text-left">
              Продукты животного происхождения, в другом месте не поименованные или не включенные
            </td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
            <td>89,0</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>12</td>
            <td class="text-left">
              Продукты животного происхождения, в другом месте не поименованные или не включенные
            </td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
            <td>89,0</td>
            <td>89,0</td>
          </tr>
          <tr>
            <td>13</td>
            <td class="text-left">
              Продукты животного происхождения, в другом месте не поименованные или не включенные
            </td>
            <td>127,4</td>
            <td>110,1</td>
            <td>102,2</td>
            <td>89,0</td>
            <td>89,0</td>
            <td>89,0</td>
          </tr>
        </tbody>
      </table>
    </div>
    <h3 class="mb-3 mt-5 d-flex align-items-center">
      <IconComponent class="mr-1" name="database" />
      Экспорт товаров - 12345 млрд $
    </h3>
    <h3 class="mt-5 d-flex align-items-center">
      <IconComponent class="mr-1" name="database" />
      Доля от общего объема торговли - 34 %
    </h3>
    <h3 class="mb-3 mt-5">Торговля услугами в ЕАЭС (Доля услуг в ВВП государств)</h3>
    <ColumnChart
      format-label="%"
      :labels="['Россия', 'Армения', 'Беларусь', 'Казахстан', 'Киргизия']"
      :chart-data="[100, 500, 400, 10, 20]"
    />
    <h3 class="mb-3 mt-5">Взаимные услуги государств – членов ЕАЭС</h3>
    <LineChart
      format-label=" млд $"
      :labels="['2018', '2019', '2020', '2021', '2022']"
      :chart-data="[100, 500, 400, 10, 20]"
      :displayDataLabels="false"
    />
  </div>
</template>

<script>
  import ColumnChart from '@/components/columnChart';

  import IconComponent from '../../../../common/ui/IconComponent';
  import LineChart from '../../../../components/lineChart';
  export default {
    name: 'Tes',
    components: { ColumnChart, IconComponent, LineChart },
  };
</script>

<style scoped lang="scss">
  h3 {
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
  }
  h4 {
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 113.9%;
    display: flex;
    align-items: center;
    color: #333333;
  }
  .table-custom {
    border-collapse: separate;
    border-spacing: var(--border-width);
    th {
      padding: 13px 11px;
      background-color: #ffffff;
    }
    th,
    td {
      outline: var(--border);
      padding: 8px;
      text-align: center;
      font-family: 'Fira Sans', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: #000000;
    }
    th:first-child,
    td:first-child {
      padding: 8px 14px 8px 8px;
      text-align: left;
    }
    tr:nth-child(odd) {
      background-color: #e5f5ff;
    }
    th {
      border-bottom: 1px solid #01a39d !important;
    }
  }
  .table-custom-two {
    thead {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 111;
    }
  }
</style>
