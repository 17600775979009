export const SINGLE_TTR_REGISTRY = 'singleTtrRegistry';

export const SINGLE_TTR_REGISTRY_ACTIONS_NAME = {
  changeParametersMulti: `${SINGLE_TTR_REGISTRY}/changeParametersMulti`,
  findAuditLog: `${SINGLE_TTR_REGISTRY}/findAuditLog`,
  update: `${SINGLE_TTR_REGISTRY}/update`,
};

export const SINGLE_TTR_REGISTRY_MUTATIONS_NAME = {
  updateListTableHeaderSingle: `${SINGLE_TTR_REGISTRY}/updateListTableHeaderSingle`,
  updateListTableHeaderSingleOdnostoron: `${SINGLE_TTR_REGISTRY}/updateListTableHeaderSingleOdnostoron`,
};

export const SINGLE_TTR_REGISTRY_GETTERS_NAME = {
  getTableHeaderSingle: `${SINGLE_TTR_REGISTRY}/getTableHeaderSingle`,
  getTableHeaderSingleOdnostoron: `${SINGLE_TTR_REGISTRY}/getTableHeaderSingleOdnostoron`,
};
