<template>
  <div class="fileUpload-container">
    <div class="fileUpload-bordered">
      <div v-if="!!description" class="mb-2">
        {{ description }}
      </div>
      <div class="inner-file head" v-if="!readonly">
        <label :style="{ cursor: readonly ? 'default' : 'pointer' }" class="label-input" for="file-input">
          {{ label }}
          <IconComponent name="plus_transparent" />
          <input
            :style="{ cursor: readonly ? 'default' : 'pointer' }"
            type="file"
            multiple
            id="file-input"
            class="input-file"
            :accept="accept"
            v-on:change="fileChanged($event)"
          />
        </label>
        <div class="invalid-msg" v-if="v$.value.$error">
          <template v-if="v$.value.required.$invalid">Загрузите файл</template>
        </div>
      </div>
      <template v-for="el in value" :key="el.id">
        <div class="inner-file">
          <template v-if="showPreview">
            <div class="file-preview">
              <a href="javascript:" @click.prevent="downloadFile(el)">
                <img :src="getFileUrl(el.id)" alt="" />
              </a>
            </div>
          </template>
          <p v-if="useCheckboxes">
            <input type="checkbox" v-model="el.checked" :disabled="readonly" class="mr-2" @click="itemChecked(el)" />
          </p>
          <p class="file-name">{{ el.originalName }}</p>

          <div class="icons-container">
            <IconComponent @click.prevent="downloadFile(el)" name="download_transparent" />

            <IconComponent
              v-if="!iconCloseBtn && !readonly"
              title="Удалить"
              @click="$emit('deleteFile', el)"
              name="closeCross"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { requiredIf } from '@vuelidate/validators';

  import FileAPI from '../api/file';
  import Constants from '../constants';
  import IconComponent from '../ui/IconComponent.vue';
  import Utils from '../utils';

  export default {
    components: { IconComponent },
    name: 'FileMultiUpload',
    props: {
      label: {
        type: String,
        default: 'Выберите файл(ы)',
      },
      entity: Object,
      propName: String,
      readonly: Boolean,
      required: {
        type: Boolean,
        default: false,
      },
      iconCloseBtn: {
        type: Boolean,
        default: false,
      },
      accept: String,
      userData: [Object, String, Number],
      showPreview: {
        type: Boolean,
        default: false,
      },
      useCheckboxes: {
        type: Boolean,
        default: false,
      },
      description: String,
    },
    watch: {
      entity: function (newValue) {
        this.value = newValue ? newValue[this.propName] : [];
      },
    },
    emits: ['uploaded', 'deleteFile', 'checked'],
    data() {
      return {
        value: [],
      };
    },
    created() {
      this.value = this.entity ? this.entity[this.propName] : [];
    },
    methods: {
      downloadFile(file) {
        FileAPI.download(file.id).then((response) => {
          const blob = new Blob([response.data], { type: file.contentType });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = file.originalName;
          link.click();
          URL.revokeObjectURL(link.href);
        });
      },
      fileChanged(e) {
        e.target.files.forEach((file) => {
          FileAPI.upload(file)
            .then((response) => {
              // if(!this.value)
              //     this.value = [];
              // this.value.push(response.data);
              this.$emit('uploaded', {
                propName: this.propName,
                data: response.data,
                userData: this.userData,
              });
            })
            .catch((e) => {
              console.log(e);
              Constants.alert.fire('Ошибка', 'Не удалось загрузить файл.', 'error');
            });
        });
      },
      itemChecked(item) {
        this.$emit('checked', item);
      },
      getFileUrl(id) {
        return Utils.getFileUrl(id);
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        value: {
          required: requiredIf(function () {
            return this.required;
          }),
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/theme/default/colors';
  ::-webkit-file-upload-button {
    cursor: pointer;
  }
  input[type='file'] {
    cursor: pointer;
  }
  .input-file {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    border-radius: 6px;
    z-index: 2;
  }
  label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 400 !important;
    font-size: 1rem;
    color: #787f8f;
    border-radius: 6px;
    z-index: 3;
  }
  a:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  .fileUpload {
    &-bordered {
      border: 1px dashed #cecfd2e5;
      padding: 8px 10px;
    }
    &-container {
      background-color: #fbfbfb;
      letter-spacing: 0.15px;
      padding: 2px 4px;
      width: 100%;
    }
  }
  .inner-file {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    &.head {
      .icon {
        margin-left: 6px;
      }
    }
    .icon {
      opacity: 0.7;
      color: #787f8f;
      margin-left: 14px;
    }
    .file-name {
      word-break: break-all;
    }
  }
  .icons-container {
    display: flex;
    margin-left: auto;
  }
  .file-preview {
    margin-right: 16px;
    img {
      width: 120px;
      height: auto;
    }
  }
</style>
