import { authAxios } from '@/common/axios';

export default {
  search: function (request) {
    return authAxios.get('/api/department/search', { params: request });
  },
  find: function (id) {
    return authAxios.get(`/api/department/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return authAxios.put(`/api/department/${data.id}`, data);
    else return authAxios.post(`/api/department/`, data);
  },
  delete: function (id) {
    return authAxios.delete('/api/department/' + id);
  },
};
