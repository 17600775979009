export const INITIATIVE_REGISTRY = 'initiativeRegistry';

export const INITIATIVE_REGISTRY_ACTIONS_NAME = {
  setUser: `${INITIATIVE_REGISTRY}/setUser`,
  createInitiative: `${INITIATIVE_REGISTRY}/createInitiative`,
  updateInitiative: `${INITIATIVE_REGISTRY}/updateInitiative`,
  findInitiative: `${INITIATIVE_REGISTRY}/findInitiative`,
  changeParametersMulti: `${INITIATIVE_REGISTRY}/changeParametersMulti`,
};

export const INITIATIVE_REGISTRY_MUTATIONS_NAME = {
  setProposedMeasureJustificationDto: `${INITIATIVE_REGISTRY}/setProposedMeasureJustificationDto`,
  setInitiative: `${INITIATIVE_REGISTRY}/setInitiative`,
  setMainInfo: `${INITIATIVE_REGISTRY}/setMainInfo`,
  setEmployee: `${INITIATIVE_REGISTRY}/setEmployee`,
  setOrganization: `${INITIATIVE_REGISTRY}/setOrganization`,
  setRates: `${INITIATIVE_REGISTRY}/setRates`,
  setRequestFiles: `${INITIATIVE_REGISTRY}/setRequestFiles`,
  setDataFiles: `${INITIATIVE_REGISTRY}/setDataFiles`,
  resetState: `${INITIATIVE_REGISTRY}/resetState`,
  setTimeline: `${INITIATIVE_REGISTRY}/setTimeline`,
  setComments: `${INITIATIVE_REGISTRY}/setComments`,
  updateListTableHeaderInitiative: `${INITIATIVE_REGISTRY}/updateListTableHeaderInitiative`,
};

export const INITIATIVE_REGISTRY_GETTERS_NAME = {
  getInitiative: `${INITIATIVE_REGISTRY}/getInitiative`,
  getTableHeaderInitiative: `${INITIATIVE_REGISTRY}/getTableHeaderInitiative`,
};
