<template>
  <div class="table-component notif">
    <div class="table-vidjet">
      <table>
        <thead>
          <colgroup width="60%"></colgroup>
          <colgroup width="20%"></colgroup>
          <colgroup width="20%"></colgroup>
          <tr>
            <th v-for="th in optionsThead" :key="th.id">{{ th.title }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="option in options" :key="option.id">
            <td width="60%">{{ option.name }}</td>
            <td width="20%" v-if="option.date">{{ option.date }}</td>
            <td width="20%" v-if="option.industry">{{ option.industry }}</td>
            <td width="20%" v-if="option.init">{{ option.init }}</td>
            <td>
              <IconComponent v-if="option.status == 1" name="attentionSigh" />
              <IconComponent v-if="option.status == 2" name="banSigh" />
              <IconComponent v-if="option.status == 3" name="goodSigh" />
              <IconComponent v-if="option.status == 4" name="refreshSigh" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import IconComponent from '@/common/ui/IconComponent.vue';
  export default {
    components: {
      IconComponent,
    },
    props: {
      options: {
        type: Object,
        default: () => {},
      },
      optionsThead: {
        type: Array,
        default: () => [],
      },
    },
  };
</script>

<style lang="scss">
  @import './tableWidgets.scss';
</style>
