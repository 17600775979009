import Agreements from '../views/Agreements';
import EditAgreement from '../views/EditAgreement';

export const routes = [
  {
    path: '/freetradeagreement',
    name: 'Agreements',
    component: Agreements,
  },
  {
    path: '/freetradeagreement/:action/:id',
    name: 'EditAgreement',
    component: EditAgreement,
  },
];
