<template>
  <FilterInput
    :select2Options="activeList"
    :placeholder="placeholder"
    type="select2"
    v-model="value"
    :select2Settings="select2Settings"
    v-on:valueChangedDebounced="changeFilter"
  />
</template>

<script>
  import FilterInput from '@/common/components/FilterInput';
  import Constants from '@/common/constants';

  export default {
    name: 'FilterActiveInput',
    components: {
      FilterInput,
    },
    props: {
      modelValue: [Boolean],
      placeholder: {
        type: String,
        default: 'Выберите статус...',
      },
      textTrue: {
        type: String,
        default: 'действ.',
      },
      textFalse: {
        type: String,
        default: 'не действ.',
      },
    },
    emits: ['valueChangedDebounced'],
    data() {
      return {
        select2Settings: Constants.select2Settings,
        activeList: [
          { id: 1, text: 'действ.' },
          { id: 0, text: 'не действ.' },
        ],
      };
    },
    watch: {
      textTrue: function (newValue) {
        console.log(this.activeList);
        this.activeList[0].text = newValue;
      },
      textFalse: function (newValue) {
        this.activeList[1].text = newValue;
      },
    },
    computed: {
      value: {
        get() {
          var returnValue = null;
          if (this.modelValue === false) returnValue = 0;
          else if (this.modelValue === true) returnValue = 1;

          return returnValue;
        },
        set(value) {
          var returnValue = null;
          if (value == 0) returnValue = false;
          else if (value == 1) returnValue = true;

          this.$emit('update:modelValue', returnValue);
        },
      },
    },
    mounted() {
      this.activeList = [
        { id: 1, text: this.textTrue },
        { id: 0, text: this.textFalse },
      ];
    },
    methods: {
      changeFilter(value) {
        var returnValue = null;
        if (value == 0) returnValue = false;
        else if (value == 1) returnValue = true;
        this.$emit('valueChangedDebounced', returnValue);
      },
    },
  };
</script>
