import axios from 'axios';

export default {
  searchHistory: function (tnvedId, request) {
    return axios.get('/api/nsi/nsitnved/History/' + tnvedId, { params: request });
  },
  searchNonEnergy: function () {
    return axios.get('/api/nsi/nsitnved/getNonEnergy');
  },
  updateNonEnergy: function (ids) {
    return axios.put('/api/nsi/nsitnved/updateNonEnergy', { ids: ids });
  },
};
