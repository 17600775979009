<template>
  <div class="container-page">
    <p class="title-inner">Новости и анализ СМИ</p>
    <div class="mb-16">
      <ButtonStock title="Создать" @click="editCard(0)" v-if="canEdit[tabIndex]" />
      <ButtonStock title="Экспорт" class="btn-small btn-export" @click="exportWord(tabIndex)" v-if="tabIndex < 3" />
    </div>
    <DefaultFilter :filterValue="currentFilter" @loadFilter="onLoadFilter" v-if="tabIndex < 3" />
    <tabs v-if="newsTypes.length" v-on:select="tabSelected">
      <tab v-for="(type, index) in newsTypes" :key="type.id" :name="type.name" :selected="tabIndex === index">
        <div>
          <div class="table-container mb-16 js-table-arrow">
            <table class="stockTable">
              <thead>
                <tr>
                  <th>Номер публикации</th>
                  <th>Заголовок</th>
                  <th>Статус</th>
                  <th>Дата и время публикации</th>
                  <th>Анонс</th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th class="filter"></th>
                  <th class="filter">
                    <FilterInput
                      placeholder="Заголовок"
                      v-model="newsBag[index].filter.name"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </th>
                  <th class="filter">
                    <FilterInput
                      :select2Options="entityStatuses"
                      placeholder="Статус"
                      type="select2"
                      v-model="newsBag[index].filter.statusId"
                      :select2Settings="select2Settings"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </th>
                  <th class="filter">
                    <FilterInput
                      class="mb-6"
                      placeholder="от"
                      type="date"
                      v-model="newsBag[index].filter.publicationDateFrom"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                    <FilterInput
                      class="mb-6"
                      placeholder="до"
                      type="date"
                      v-model="newsBag[index].filter.publicationDateTo"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </th>
                  <th class="filter">
                    <FilterInput
                      placeholder="Анонс"
                      v-model="newsBag[index].filter.previewText"
                      v-on:valueChangedDebounced="changeFilter(index)"
                    />
                  </th>
                  <th class="filter"></th>
                  <th class="filter"></th>
                </tr>
              </thead>
              <tbody v-if="newsBag[index]?.page?.items?.length && !newsBag[index]?.isLoading">
                <tr v-for="item in newsBag[index].page.items" :key="item.id" @dblclick="editCard(item.id)">
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.entityStatus.title }}</td>
                  <td>{{ item.activeFrom ? $momentFormat(item.activeFrom, 'DD.MM.YYYY HH:mm') : '' }}</td>
                  <td>{{ item.previewText }}</td>
                  <td>
                    <IconComponent @click="editCard(item.id)" name="mode" />
                  </td>
                  <td>
                    <IconComponent @click="deleteCard(type.id, item.id)" name="delete" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="newsBag[index]?.isLoading">
                <tr>
                  <td class="text-center" colspan="4">Загрузка...</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="text-center" colspan="5">Нет данных.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="newsBag[index].page.pagesTotal > 1">
            <Pagination
              :page-count="newsBag[index].page.pagesTotal"
              :current-page="newsBag[index].page.pageNumber"
              @change="onPageChange($event, false, index)"
              @more="onPageChange($event, true, index)"
            />
          </div>
        </div>
      </tab>
      <tab name="Анализ СМИ" v-if="canView[3]" :selected="tabIndex === 3">
        <MassMedia :nn="loadSmi" />
      </tab>
    </tabs>
  </div>
</template>

<script>
  import DefaultFilter from '@/common/components/DefaultFilter';
  import FilterInput from '@/common/components/FilterInput';
  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';
  import filtermanager from '@/common/mixins/filtermanager';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock';

  import NsiApi from '../../nsi/api/nsi';
  import Api from '../api/news';
  import MassMedia from '../components/MassMedia';

  export default {
    name: 'News',
    components: { IconComponent, ButtonStock, tabs, tab, FilterInput, MassMedia, DefaultFilter },
    mixins: [security, filtermanager],
    data() {
      return {
        newsBag: [],
        newsTypes: [],
        entityStatuses: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        select2Settings: Constants.select2Settings,
        currentNewsType: null,
        defaultRequest: {
          pageNumber: 1,
          pageSize: 0,
          isActive: true,
        },
        tabIndex: this.$route.query && this.$route.query.tab * 1 > 0 ? this.$route.query.tab * 1 : 0,
        loadSmi: 0,
        canView: [true, true, true, false],
        canEdit: [true, true, true, false],
      };
    },
    watch: {
      newsTypes: function () {
        if (this.tabIndex !== 3) {
          this.currentNewsType = this.newsTypes[0];
          this.loadPage(0);
        }
      },
    },
    created() {
      //console.log(this.$route.query.tab);
      this.checkPermission(Constants.Permissions.MassMediaAccess).then((response) => {
        this.canView[3] = response.data;
      });
      this.checkPermission(Constants.Permissions.MassMediaEdit).then((response) => {
        this.canEdit[3] = response.data;
      });
      this.loadFilter().then((response) => {
        let filters = response;
        this.newsBag = [];
        NsiApi.search('nsinewstype', this.defaultRequest)
          .then((response) => {
            this.newsTypes = response.data.items;
            this.newsTypes.forEach((type) => {
              this.newsBag.push({
                page: {},
                request: {
                  pageNumber: 1,
                  pageSize: Constants.pageSize,
                  typeId: type.id,
                },
                filter: {},
                isLoading: false,
              });
            });

            if (filters)
              for (let i = 0; i < this.newsBag.length; i++) if (filters[i]) this.newsBag[i].filter = filters[i];
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        this.loadDictionaries();

        console.log(this.tabIndex);
        if (this.tabIndex === 3) {
          setTimeout(() => {
            this.loadSmi++;
          }, 500);
        }
      });
    },
    methods: {
      loadPage(index, append) {
        this.newsBag[index].isLoading = true;
        const request = Object.assign(this.newsBag[index].filter, this.newsBag[index].request);
        Api.search(request)
          .then((response) => {
            if (!append) {
              this.newsBag[index].page = {};
              this.newsBag[index].page = response.data;
            } else if (response.data) {
              this.newsBag[index].page.pageNumber = response.data.pageNumber;
              this.newsBag[index].page.items = this.newsBag[index].page.items.concat(response.data.items);
            }
            this.newsBag[index].isLoading = false;
          })
          .catch((error) => {
            this.newsBag[index].isLoading = false;
            console.error(error?.response?.data);
          });
      },
      loadDictionaries() {
        Utils.loadSelectOptions('entityStatusList', this.entityStatuses, true);
      },
      editCard(id) {
        if (this.tabIndex == 3)
          this.$router.push({
            name: 'MassMediaCard',
            params: { id: id, action: this.canEdit[this.tabIndex] ? 'edit' : 'view' },
          });
        else
          this.$router.push({ name: 'NewsCard', params: { typeId: this.currentNewsType.id, id: id, action: 'edit' } });
      },
      deleteCard(typeId, id) {
        Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            Api.delete(typeId, id)
              .then((response) => {
                console.log(response);
                Constants.alert.fire('Удаление', 'Запись удалена', 'success');
                this.loadPage(this.tabIndex, false);
              })
              .catch((error) => {
                Constants.alert.fire(
                  'Удаление',
                  error.response && error.response.data ? error.response.data : 'Запись не удалена.',
                  'error',
                );
              });
          }
        });
      },
      onPageChange(pageNumber, addToExist, index) {
        this.newsBag[index].request.pageNumber = pageNumber;
        this.loadPage(index, addToExist);
      },
      tabSelected(index) {
        var isChange = this.tabIndex != index;
        if (isChange) {
          this.tabIndex = index;

          if (this.tabIndex < 3) {
            this.currentNewsType = this.newsTypes[index];
            this.loadPage(index);
          } else {
            this.loadSmi++;
          }
        }
      },
      changeFilter(index) {
        this.newsBag[index].request.pageNumber = 1;

        this.saveFilter(this.currentFilter);

        this.loadPage(index, false);
      },
      exportWord(index) {
        Api.exportWord(this.newsBag[index]?.filter);
      },
      onLoadFilter(item) {
        if (item) for (let i = 0; i < this.newsBag.length; i++) if (item[i]) this.newsBag[i].filter = item[i];
        this.changeFilter(this.tabIndex);
      },
    },
    computed: {
      currentFilter() {
        let filters = [];
        for (let i = 0; i < this.newsBag.length; i++) filters.push(this.newsBag[i].filter);
        return filters;
      },
    },
  };
</script>

<style scoped>
  .btn-export {
    margin-left: 15px;
  }
</style>

<style lang="scss" scoped>
  .table-container.analysisTable table.stockTable thead th {
    width: 10%;
    &:nth-of-type(4),
    &:nth-of-type(5) {
      min-width: 390px;
    }
  }
</style>
