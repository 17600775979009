export function setPassportKeyData(state, res) {
  const { data = {} } = res;
  state.passportKeys = data;
}

export function setPassportPartnerCountriesData(state, res) {
  const { data = {} } = res;
  state.passportPartnerCountries = data;
}

export function setPassportTradeTurnover(state, res) {
  const { data = {} } = res;
  state.passportTradeTurnovers = data;
}
export function setPassportMainData(state, res) {
  const { data = {} } = res;
  state.passportMainInformation = data;
}

export function setPassportPartnerCountriesCount(state, res) {
  const { data = {} } = res;
  state.passportPartnersCount = data;
}
export function setPassportCurrentEvents(state, res) {
  const { data = [] } = res;
  state.passportCurrentEvents = data;
}

export function setPassportPreviousEvents(state, res) {
  const { data = [] } = res;
  state.passportPreviousEvents = data;
}

export function setPassportCurrentEventsCount(state, res) {
  const { data = {} } = res;
  state.passportCurrentEventsCount = data;
}

export function setPassportPreviousEventsCount(state, res) {
  const { data = {} } = res;
  state.passportPreviousEventsCount = data;
}

export function setPassportAgreements(state, res) {
  const { data = [] } = res;
  state.passportListOfAgreements = data.items;
}

export function addPassportAgreements(state, res) {
  const { data = [] } = res;
  state.passportListOfAgreements.push(...data.items);
}

export function setTopRegionsAgreements(state, res) {
  const { data = {} } = res;
  state.topRegionsAgreements = data;
}

export function setTopEffectiveRegions(state, res) {
  const { data = [] } = res;
  state.topEffectiveRegions = data.map((i) => ({
    id: i.regionId,
    name: i.regionName,
    value: i.effectivenessIndex,
    code: i.regionCodes[0],
  }));
}

export function setResponsibleAuthority(state, res) {
  const { data = {} } = res;
  state.responsibleAuthority = data;
}
