import { authAxios } from '@/common/axios';

export default {
  search: function (request) {
    return authAxios.get('/api/employee/search', { params: request });
  },
  searchUsers: function (request) {
    return authAxios.get('/api/user/search', { params: request });
  },
  find: function (id) {
    return authAxios.get(`/api/employee/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return authAxios.put(`/api/employee/${data.id}`, data);
    else return authAxios.post(`/api/employee/`, data);
  },
  delete: function (id) {
    return authAxios.delete('/api/employee/' + id);
  },
  undelete: function (id) {
    return authAxios.put('/api/employee/' + id + '/Undelete');
  },
  getUserTypes: function () {
    return authAxios.get('/api/user/usertypes', {});
  },
  updateUser: function (data) {
    return authAxios.put(`/api/user/${data.id}`, data);
  },
  saveNotifications: function (data) {
    return authAxios.put(`/api/user/savenotifications`, data);
  },
  unlock: function (userId) {
    return authAxios.post(`/api/user/unlock/${userId}`);
  },
  getShowFilters: function () {
    return authAxios.get(`/api/user/showFilters`);
  },
};
