<template>
  <div class="green-economy">
    <div class="d-flex justify-content-between flex-wrap">
      <h2>
        Структура первичного энергопотребления по видам топлива, ЭДж
        <span v-if="byFuelSummary.length">({{ byFuelSummary[0].year }} г.)</span>
      </h2>
      <p class="hint">Источник: BP</p>
    </div>
    <template v-if="byFuelSummary.length">
      <!--      <pie-chart-->
      <!--        :chart-data="byFuelSummary.map((x) => x.value)"-->
      <!--        :colors="byFuelSummary.map((x) => x.color)"-->
      <!--        :labels="byFuelSummary.map((x) => x.title + ', ЭДж')"-->
      <!--      />-->
      <ColumnChartHorizontal
        index-axis="y"
        :bar-percentage="1"
        :chart-data="byFuelSummary.map((x) => x.value.toFixed(2))"
        :colors="['#309CFF', '#3C8CD7', '#177ada', '#0264BE', '#004687', '#002D56']"
        :labels="byFuelSummary.map((x) => x.title + ', ЭДж')"
        label-color="#fff"
      />
    </template>
    <template v-else><div class="mb-3">Нет данных.</div></template>
    <h2 class="mt-4">Выбросы СО2, млн.т <span>(2021 г.)</span></h2>
    <template v-if="co2Summary.length">
      <!-- <line-chart
        :tension="0.4"
        :background-color="'#2F84EB'"
        :fill="true"
        :point-color="'#0e39ab'"
        :border-color="'#2F84EB'"
        format-label=" млн. т"
        :labels="co2Summary.map((x) => x.title)"
        :chart-data="co2Summary.map((x) => x.value.toFixed(0))"
      /> -->
      <ColumnChart
        :key="chartUpdater"
        :colors="['#2F84EB']"
        :layoutPaddingTop="1 * 25"
        :chart-data="co2Summary.map((x) => x.value.toFixed(0))"
        :labels="co2Summary.map((x) => x.title)"
      />
    </template>
    <template v-else><div>Нет данных.</div></template>
  </div>
</template>

<script>
  // import LineChart from "@/components/lineChart";
  import ColumnChartHorizontal from '@/components/column-chart-horizontal';
  import ColumnChart from '@/components/columnChart';

  import Api from '../api';
  export default {
    name: 'GreenEconomy',
    components: {
      ColumnChartHorizontal,
      // LineChart,
      ColumnChart,
    },
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        byFuelSummary: [],
        co2Summary: [],
        chartUpdater: 1,
      };
    },
    mounted() {
      this.loadSummary();
    },
    methods: {
      loadSummary() {
        this.byFuelSummary = [];
        this.co2Summary = [];
        Api.getGreenEconomyByFuelSummry(this.countryId).then((response) => {
          this.byFuelSummary = response.data.sort((a, b) => a.value - b.value);
        });
        Api.getGreenEconomyCo2Summry(this.countryId).then((response) => {
          this.co2Summary = response.data;
        });
      },
    },
    watch: {
      countryId: function () {
        this.loadSummary();
      },
    },
  };
</script>

<style scoped lang="scss">
  .hint {
    font-size: 12px;
    color: #787f8f;
  }
  h2 {
    margin-bottom: 20px;
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 113.9%;
    color: #333333;
  }
</style>
