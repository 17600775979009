import axios from 'axios';

import { authAxios } from '@/common/axios';

export default {
  getUser: function () {
    return authAxios.get('/api/user/profile');
  },
  updateUser: function (data) {
    return authAxios.put(`/api/user/updateProfile/?uuid=${data.uuid}`, data);
  },
  loadWidgets: function () {
    return axios.get('/api/widget/widgetTypes');
  },
  saveWidgets: function (data) {
    return axios.put('/api/widget/saveWidgets', data);
  },
};
