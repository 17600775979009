import { createRouter, createWebHashHistory } from 'vue-router';

import { routes as adminRoutes } from '@/modules/admin/router';
import { routes as analyticsForCrossCuttingTasks } from '@/modules/analyticsForCrossCuttingTasks/router';
import { registerGuards as registerAuthGuards, routes as authRoutes } from '@/modules/auth/router';
import ContactsPage from '@/modules/contacts/views/ContactsPage';
import { routes as countryAnalyticsNew } from '@/modules/countryAnalyticsNew/routes';
import { routes as countryAnalytics } from '@/modules/countryАnalytics/routes';
import { routes as crossCuttingTask } from '@/modules/crossCuttingTask/router';
import { routes as eap } from '@/modules/eap/router';
import { routes as foreignMarket } from '@/modules/foreignMarket/router';
import { routes as freetradeRoutes } from '@/modules/freeTradeAgreement/router';
import { routes as home } from '@/modules/home/router';
import { routes as importAnalyticsRoutes } from '@/modules/importanalytics/router';
import { routes as investmentAgreementRoutes } from '@/modules/investmentAgreement/router';
import { routes as library } from '@/modules/library/router';
import { routes as measureRoutes } from '@/modules/measure/router';
import { routes as media } from '@/modules/media/router';
import Messenger from '@/modules/messenger/Messenger.vue';
import { routes as mpkRoutes } from '@/modules/mpk/router';
import { routes as mpkEffectiveRating } from '@/modules/mpkEffectiveRating/router';
import { routes as projectsRoutes } from '@/modules/mpkProject/router';
import { routes as navigatorRoutes } from '@/modules/navigator/router';
import notifications from '@/modules/notifications/router';
import { routes as nsiRoutes } from '@/modules/nsi/router';
import SettingsPage from '@/modules/settings/views/SettingsPage';
import { routes as taskRoutes } from '@/modules/task/router';
import { routes as vesRoutes } from '@/modules/ves/router';

import Search from '../modules/search/Search';
import workScreenPage from '../modules/workScreenMpk/workScreenPage';
// import userManager from "../modules/auth/user-manager";
// import store from "../store";

const routes = [
  {
    path: '/work-screen-mpk',
    name: 'work-screen-mpk',
    component: workScreenPage,
    meta: { isPublic: true },
  },
  {
    path: '/messenger/:employeeid?',
    name: 'Messenger',
    component: Messenger,
    props: true,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: ContactsPage,
  },
  {
    path: '/settings',
    name: 'SettingsPage',
    component: SettingsPage,
  },
  {
    path: '/search/:term?',
    name: 'Search',
    component: Search,
  },
  ...authRoutes,
  ...adminRoutes,
  ...mpkRoutes,
  ...nsiRoutes,
  ...projectsRoutes,
  ...taskRoutes,
  ...navigatorRoutes,
  ...measureRoutes,
  ...countryAnalytics,
  ...importAnalyticsRoutes,
  ...foreignMarket,
  ...home,
  ...library,
  ...media,
  ...eap,
  ...freetradeRoutes,
  ...investmentAgreementRoutes,
  ...countryAnalyticsNew,
  ...analyticsForCrossCuttingTasks,
  ...mpkEffectiveRating,
  ...crossCuttingTask,
  ...notifications,
  ...vesRoutes,
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    } else {
      window.scrollTo(0, 0);
    }
  },
});

registerAuthGuards(router);

export default router;
