import { MPK_PROJECT_ACTIONS_NAME, MPK_PROJECT_MUTATIONS_NAME } from './name';

import links from '@/utils/links';
import request from '@/utils/request';

export async function getMpkProjectDepartment(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.mpkProject.getMpkProjectDepartment,
      mutate: MPK_PROJECT_MUTATIONS_NAME.setMpkProjectDepartment,
      name: MPK_PROJECT_ACTIONS_NAME.getMpkProjectDepartment,
      requestAuth: true,
    },
    params,
  );
}

export async function getMpkProjectMinRole(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.mpkProject.getMpkProjectMinRole,
      mutate: MPK_PROJECT_MUTATIONS_NAME.setMpkProjectMinRole,
      name: MPK_PROJECT_ACTIONS_NAME.getMpkProjectMinRole,
    },
    params,
  );
}

export async function getMpkProjectList(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.mpkProject.getMpkProjectList,
      mutate: MPK_PROJECT_MUTATIONS_NAME.setMpkProjectList,
      name: MPK_PROJECT_ACTIONS_NAME.getMpkProjectList,
    },
    params,
  );
}

export async function getMpkProjectSupportMeasure(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.mpkProject.getMpkProjectSupportMeasure,
      mutate: MPK_PROJECT_MUTATIONS_NAME.setMpkProjectSupportMeasure,
      name: MPK_PROJECT_ACTIONS_NAME.getMpkProjectSupportMeasure,
    },
    params,
  );
}

export async function getMpkProjectCategoryList(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.mpkProject.getMpkProjectCategoryList,
      mutate: MPK_PROJECT_MUTATIONS_NAME.setMpkProjectCategoryList,
      name: MPK_PROJECT_ACTIONS_NAME.getMpkProjectCategoryList,
    },
    params,
  );
}

export async function getMpkProjectTypeEntry(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.mpkProject.getMpkProjectTypeEntry,
      mutate: MPK_PROJECT_MUTATIONS_NAME.setMpkProjectTypeEntry,
      name: MPK_PROJECT_ACTIONS_NAME.getMpkProjectTypeEntry,
    },
    params,
  );
}

export async function getMpkProjectSearchLight(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.mpkProject.getMpkProjectSearchLight,
      mutate: MPK_PROJECT_MUTATIONS_NAME.setMpkProjectSearchLight,
      name: MPK_PROJECT_ACTIONS_NAME.getMpkProjectSearchLight,
    },
    params,
  );
}

export async function getMpkProjectSearch(context, params) {
  return await request.call(
    this,
    context,
    {
      url: links.mpkProject.getMpkProjectSearch,
      mutate: MPK_PROJECT_MUTATIONS_NAME.setMpkProjectSearch,
      name: MPK_PROJECT_ACTIONS_NAME.getMpkProjectSearch,
    },
    params,
  );
}
