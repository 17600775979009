<template>
  <div class="economicsReview-container" v-if="visibleIndicatorList.length != 0">
    <div class="indicatorsFromMap">
      <p class="economicsTypeTitle mb-20" id="econ-rev-indicators">Ключевые показатели</p>
    </div>

    <div class="economicsTypeBlock-new mb-20">
      <p class="economicsTypeBlock-new-title">Реальный сектор</p>
      <ul class="indicators__list align-center justify-content-between">
        <li v-for="item in visibleIndicatorList" :key="item.id" class="indicators__itemCustom">
          <p class="text-align-left fs14 fw600 mb-6">{{ item.title }}</p>
          <!-- <div v-if="item.dateUpdate" class="analytics-source-text">
            Дата обновления: {{ item.dateUpdate }}
          </div> -->
          <template v-if="item.value">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center mr-24">
                <div class="analytics-source-text fs12 mr-10" v-if="item.place">
                  <template v-if="item.value != '0,00'">
                    <p
                      :class="[{ 'placeTypeBlock-yellow': item.id == 1 }, { 'placeTypeBlock-green': item.id != 1 }]"
                      class="d-flex"
                    >
                      {{ item.place }} <IconComponent class="ml-4 white" name="threeStripes" />
                    </p>
                  </template>
                </div>
                <strong class="fs22 fw700">{{ item.value == '0' ? '-' : item.value }}</strong>
              </div>
              <div class="awards fs12" v-if="item.awards">
                <p class="d-flex mb-8">
                  1 <IconComponent class="ml-10 mr-16 gray" name="threeStripes" /> {{ item.awards.firstPlace }}
                </p>
                <p class="d-flex">
                  {{ item.awards.russiaPlace }}
                  <IconComponent class="ml-4 mr-16 gray" name="threeStripes" /> Россия&nbsp;
                  <span v-if="item.awards.russiaValue"
                    >({{ formatNumber(item.awards.russiaValue, 2, ' ', ',', true) }})</span
                  >
                </p>
              </div>
            </div>
            <div v-if="item.period" class="analytics-source-text fs10">Период: {{ item.period }}</div>
          </template>
        </li>
      </ul>
    </div>

    <div class="economicsTypeBlock-new">
      <p class="economicsTypeBlock-new-title">Финансовый сектор</p>
      <ul class="indicators__list align-center">
        <li v-for="item in visibleIndicatorListFinancial" :key="item.id" class="indicators__itemCustom">
          <p class="text-align-left fs14 fw600 mb-6">{{ item.title }}</p>

          <template v-if="item.value">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center mr-24">
                <div class="analytics-source-text fs12 mr-10" v-if="item.place">
                  <template v-if="item.value != '0,00'">
                    <p class="d-flex placeTypeBlock-blue">
                      {{ item.place }} <IconComponent class="ml-4 white" name="threeStripes" />
                    </p>
                  </template>
                </div>
                <strong class="fs22 fw700">{{ item.value == '0,00' ? '-' : item.value }}</strong>
              </div>
              <div class="awards fs12" v-if="item.awards">
                <p class="d-flex mb-8">
                  1 <IconComponent class="ml-10 mr-16 gray" name="threeStripes" /> {{ item.awards.firstPlace }}
                </p>
                <p class="d-flex">
                  {{ item.awards.russiaPlace }} <IconComponent class="ml-4 mr-16 gray" name="threeStripes" /> Россия
                  <span v-if="item.awards.russiaValue">({{ item.awards.russiaValue.toFixed(2) }})</span>
                </p>
              </div>
            </div>
            <div v-if="item.period" class="analytics-source-text fs10">Период: {{ item.period }} год</div>
            <div v-if="item.dateUpdate" class="analytics-source-text">Дата обновления: {{ item.dateUpdate }}</div>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import text from '@/common/mixins/text';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ApiFromCountryMap from '@/modules/countryAnalyticsNew/api/api.js';
  import Api from '@/modules/countryAnalyticsNew/api/economicsReview.js';

  export default {
    name: 'EconomicsReview',
    mixins: [text],
    components: {
      IconComponent,
    },
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        year: new Date().getFullYear(),
        indicatorsList: [],
        indicatorsListFinancial: [],
        loading: false,
        indicatorsFromMap: null,
      };
    },
    created() {
      this.loadData();
    },
    methods: {
      loadData() {
        this.loading = true;
        Api.getSummary(this.year, this.countryId).then((response) => {
          this.indicatorsList = [];
          this.indicatorsListFinancial = [];
          this.updateIndicators(response.data);
          console.log(response.data);
          this.loading = false;
        });
        if (this.countryId != Constants.countryRussiaId) {
          ApiFromCountryMap.getExportProductLast(this.countryId).then((response) => {
            this.indicatorsFromMap = response.data;
          });
        }
      },
      updateIndicators(summary) {
        //console.log(summary);
        this.indicatorsList = [
          {
            id: 1,
            title: 'Население страны,\n' + 'млн чел.',
            period: summary ? summary.populationYear + ' год' : '',
            value: summary ? this.formatNumber(summary.population / 1000000, 0) : '',
            place: summary ? summary.populationPlace : 'Загрузка...',
            awards: summary ? summary.populationAward : null,
          },
          {
            id: 2,
            title: 'ВВП в текущих ценах,\n' + 'млрд $ США',
            period: summary ? summary.gdpYear + ' год' : '',
            value: summary ? this.formatNumber(summary.gdp / 1000000000, 0) : '',
            place: summary ? summary.gdpPlace : 'Загрузка...',
            awards: summary ? summary.gdpAward : null,
          },
          {
            id: 3,
            title: 'ВВП на душу населения (в текущих ценах),\n' + 'тыс. $ США',
            period: summary ? summary.gdpPerCapitaYear + ' год' : '',
            value: summary ? this.formatNumber(summary.gdpPerCapita / 1000) : '',
            place: summary ? summary.gdpPerCapitaPlace : 'Загрузка...',
            awards: summary ? summary.gdpPerCapitaAward : null,
          },
          {
            id: 4,
            title: 'Темп прироста ВВП (в постоянных ценах),\n' + 'в % к предыдущему году',
            period: summary ? summary.gdpGrowYear + ' год' : '',
            place: summary ? summary.gdpGrowPlace : 'Загрузка...',
            chartData: summary?.gdpGrowValues,
            value: summary?.gdpGrowValues
              ? this.formatNumber(summary?.gdpGrowValues[summary?.gdpGrowValues?.length - 1]?.currentValue)
              : '',
            awards: summary ? summary.gdpGrowAward : null,
          },
          {
            id: 8,
            title: 'Уровень безработицы, %',
            period: summary ? summary.unemploymentRateYear + ' год' : '',
            value: summary ? this.formatNumber(summary.unemploymentRate) : '',
            place: summary ? summary.unemploymentRatePlace : 'Загрузка...',
            awards: summary ? summary.unemploymentRateAward : null,
          },
          {
            id: 9,
            title: 'Инфляция (ИПЦ), %',
            period: summary ? this.$momentFormat(summary.inflationPeriod, 'MMMM YYYY') : '',
            value: summary ? this.formatNumber(summary.inflation) : '',
            place: summary ? summary.inflationPlace : 'Загрузка...',
            awards: summary ? summary.inflationAward : null,
          },
          {
            id: 10,
            title: 'Учетная ставка ЦБ',
            period: summary ? this.$momentFormat(summary.bankRatePeriod, 'MMMM YYYY') : '',
            value: summary ? this.formatNumber(summary.bankRate) : '',
            place: summary ? summary.bankRatePlace : 'Загрузка...',
            awards: summary ? summary.bankRateAward : null,
          },
        ];
        this.indicatorsListFinancial = [
          {
            id: 5,
            title: 'Суверенные рейтинги\n' + '(S&P, Moody`s, Fitch)',
            dateUpdate: summary ? this.$momentFormat(summary.sovereignRatingUpdateDate, 'DD.MM.YYYY') : '09.12.2022',
            value: summary ? summary.sovereignRating : '',
          },
          {
            id: 6,
            title: 'Государственный долг,\n' + '% ВВП',
            period: summary ? summary.governmentDebtYear : null,
            value: summary ? this.formatNumber(summary.governmentDebt, 0) : '',
            place: summary ? summary.governmentDebtPlace : 'Загрузка...',
            awards: summary ? summary.governmentDebtAward : null,
          },
          {
            id: 7,
            title: 'Золотовалютные резервы,\n' + 'млрд $ США',
            period: summary ? summary.reservesYear : null,
            value: summary ? this.formatNumber(summary.reserves / 1000000000, 0) : '',
            place: summary ? summary.reservesPlace : 'Загрузка...',
            awards: summary ? summary.reservesAward : null,
          },
        ];
      },
    },
    watch: {
      countryId: function () {
        this.loadData();
      },
    },
    computed: {
      visibleIndicatorList() {
        return this.indicatorsList.filter((x) => (x.value || x.place) && x.value != '0,00');
      },
      visibleIndicatorListFinancial() {
        return this.indicatorsListFinancial.filter((x) => (x.value || x.place) && x.value != '0');
      },
    },
  };
</script>

<style scoped lang="scss">
  .indicators {
    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 36px 16px;
      @media (max-width: 1150px) {
        gap: 36px;
        justify-content: center !important;
      }
      @media (max-width: 892px) {
        justify-content: space-between !important;
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 180px;
      max-width: 180px;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      border: 1px solid #06a0d8a9;
      p {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 113.9%;
        text-align: center;
        color: #333333;
      }
      strong {
        margin-top: 20px;
        margin-bottom: 9px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 22px;
        text-align: center;
        color: #333333;
      }
      span {
        margin-bottom: 4px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #333333;
      }
    }
  }
  h2 {
    margin-bottom: 20px;
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 113.9%;
    color: #000000;
  }

  .indicators__item .analytics-source-text {
    font-size: 10px;
    text-align: center;
  }
</style>

<style scoped lang="scss">
  .indicatorsFromMap {
    &__list {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    &__item {
      max-width: 150px;
      width: 100%;
      position: relative;
      margin-bottom: 42px;
      &:hover,
      &.active {
        .indicatorsFromMap__wrapper {
          background: #ffffff;
          box-shadow: 0px 0px 17px rgba(1, 120, 200, 0.5);
          border-radius: 5px;
        }
      }
      .name {
        position: absolute;
        top: -5px;
        right: -10px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 113.9%;
        text-align: center;
        box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
        border-radius: 5px;
        background: #ffffff;
        padding: 10px;
        max-width: 100px;
        color: rgba(58, 137, 190, 0.834);
        &.exportNonEnergyValue {
          top: -30px;
        }
      }
    }
    &__wrapper {
      transition: 0.2s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      strong {
        display: block;
        margin-right: 10px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #787f8f;
        white-space: nowrap;
      }
      span {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #787f8f;
      }
    }
  }
  .indicators__itemCustom {
    min-width: 150px;
    max-width: 350px;
    width: 100%;
  }
</style>
