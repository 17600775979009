import axios from 'axios';

export default {
  getAgreements: function (countryId, typeId) {
    return axios.get('/api/freeTradeAgreement/listGroupedByCountryCode', {
      params: { countryId: countryId, agreementType: typeId },
    });
  },
  getAgreement: function (id) {
    return axios.get('/api/freeTradeAgreement/find', { params: { id: id } });
  },
};
