<template>
  <div class="tab-pane__content">
    <div class="btn-group mb-3">
      <ComponentInput
        placeholder="Укажите модуль"
        :select2Settings="select2Settings"
        :select2Options="modules"
        v-model="moduleId"
        type="select2"
        :disabled="isSaving"
      />
      <ButtonStock title="Редактировать" @click="edit" v-if="!isEditMode" class="ml-2" />
      <ButtonStock title="Сохранить" @click="save" v-if="isEditMode" :disabled="isSaving" class="ml-2" />
      <ButtonStock
        btnType="secondary"
        title="Отмена"
        v-on:click="reload"
        v-if="isEditMode"
        :disabled="isSaving"
        class="ml-2"
      />
    </div>
    <table
      ref="table"
      class="scrolling"
      :class="{ scrolly: scrollVertical, scrollx: scrollHorizontal }"
      v-if="!loading"
    >
      <thead
        name="thead"
        ref="thead"
        style="oveflow-y: none"
        :class="{ scrollsync: syncHeaderScroll }"
        @dragenter="onDragEnterHeader"
        @dragover.prevent="onDragOverHeader"
        @drop="onDropHeader"
      >
        <tr v-if="rolesInner">
          <th class="table__sticky-ceil" scope="col"></th>
          <th class="table__ceil" v-for="item in rolesInner" :key="item" scope="col">
            <span v-if="item.isGroupRole">Группа - </span>
            {{ item.title }}
          </th>
        </tr>
        <tr v-if="rolesInner">
          <th class="table__sticky-ceil" scope="col" style="min-width: 136px">Выбрать всё</th>
          <th class="scrolling__ceil" v-for="item in rolesInner" :key="item" scope="col">
            <!-- <a href="javascript:" class="select-all__link" @click="onSelectAllClick(item)">Выбрать всё</a>           -->
            <button
              @click="onSelectAllClick(item)"
              class="m-auto scrolling__indicator dis-btn-css"
              :class="getIndicatorClass(item)"
            ></button>
          </th>
        </tr>
      </thead>
      <tbody name="tbody" ref="tbody" @scroll.passive="updateSyncedScroll">
        <tr v-for="perm in permissionsInner" :key="perm">
          <th class="table__sticky-ceil" scope="row">
            <span v-if="perm.parentId > 0">&nbsp;&nbsp;&nbsp;&nbsp;{{ perm.id }}. {{ perm.name }}</span>
            <b v-else>{{ perm.id }}. {{ perm.name }}</b>
          </th>
          <td class="scrolling__ceil" v-for="role in rolesInner" :key="role">
            <button
              @click="changeRolePermission(role, perm)"
              class="m-auto scrolling__indicator dis-btn-css"
              :class="{
                'scrolling__indicator--dis-pseudo': accessMatrixInner && accessMatrixInner[role.id][perm.id],
                editing: isEditMode,
              }"
            ></button>
            <div v-if="!isEditMode" class="table__overlay"></div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-center mt-32">
      <Loader v-if="loading"></Loader>
    </div>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import Constants from '@/common/constants';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import Loader from '@/components/Loader';

  import Api from '@/modules/admin/api/rolesPermissions.js';

  export default {
    props: {
      deadAreaColor: { type: String, required: false, default: '#CCC' },
      includeFooter: { type: Boolean, required: false, default: false },
      syncHeaderScroll: { type: Boolean, required: false, default: true },
      syncFooterScroll: { type: Boolean, required: false, default: true },
      scrollHorizontal: { type: Boolean, required: false, default: true },
      scrollVertical: { type: Boolean, required: false, default: true },
    },
    components: {
      ButtonStock,
      Loader,
      ComponentInput,
    },
    data() {
      return {
        rolesInner: null,
        permissionsInner: null,
        accessMatrixInner: null,
        isEditMode: false,
        isSaving: false,
        loading: false,
        moduleId: 1,
        modules: [],
        select2Settings: Constants.select2SettingsNoClear,
      };
    },
    watch: {
      deadAreaColor() {
        this.setColors();
      },
      moduleId() {
        this.load();
      },
    },
    mounted: function () {
      this.setColors();
      this.updateSyncedScroll();
      if (this.rolesInner === null || this.permissionsInner === null || this.accessMatrixInner === null) {
        this.load();
      }
    },
    created() {
      this.loadModules();
    },
    methods: {
      loadModules() {
        Api.getModules().then((response) => {
          this.modules = response.data;
        });
      },
      load() {
        this.loading = true;
        Api.loadRoles().then((r1) => {
          this.rolesInner = r1.data.items;
          console.log(this.rolesInner);
        });
        Api.loadPermissionsForMatrix(this.moduleId).then((r2) => {
          this.permissionsInner = r2.data.filter((x) => !x.isSystem);
        });
        Api.loadPermissionAccessMatrix(this.moduleId).then((r3) => {
          this.accessMatrixInner = r3.data;
          this.loading = false;
        });
      },
      changeRolePermission(it, item) {
        if (!it.parentId || this.accessMatrixInner[it.parentId][item.id]) {
          let newValue = !this.accessMatrixInner[it.id][item.id];
          this.accessMatrixInner[it.id][item.id] = newValue;
          if (it.isGroupRole && newValue === false) {
            let childRoles = this.rolesInner.filter((x) => x.parentId == it.id);
            childRoles.forEach((cr) => (this.accessMatrixInner[cr.id][item.id] = newValue));
          }
        }
      },
      edit() {
        this.isEditMode = true;
      },
      reload() {
        this.isEditMode = false;
        this.load();
      },
      save() {
        this.isSaving = true;
        Api.updatePermissionAccessMatrix(this.moduleId, this.accessMatrixInner)
          .catch((error) => {
            console.log(error);
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
            this.load();
          })
          .then((result) => {
            console.log(result);
            this.load();
          })
          .finally(() => {
            this.isEditMode = false;
            this.isSaving = false;
          });
      },
      updateSyncedScroll() {
        const b = this.$refs.tbody;
        const l = b.scrollLeft;
        if (this.scrollHorizontal) {
          if (this.syncHeaderScroll) {
            const h = this.$refs.thead;
            if (h.scrollLeft !== l) {
              h.scrollLeft = l;
            }
          }
          if (this.includeFooter && this.syncFooterScroll) {
            const f = this.$refs.tfoot;
            if (f.scrollLeft !== l) {
              f.scrollLeft = l;
            }
          }
        }
        this.$emit('scroll', b.scrollTop, l, b.scrollHeight, b.scrollWidth);
      },
      setColors() {
        const s = this.$refs.table.style;
        s.setProperty('--dead-area-color', this.deadAreaColor);
      },
      onDragEnterHeader(e) {
        this.$emit('header-dragenter', e);
      },
      onDragOverHeader(e) {
        this.$emit('header-dragover', e);
      },
      onDropHeader(e) {
        this.$emit('header-drop', e);
      },
      onSelectAllClick(role) {
        if (this.isEditMode) {
          if (!role.parentId) {
            let selected = this.getAllSelected(role.id);
            Object.keys(this.accessMatrixInner[role.id]).forEach(
              (x) => (this.accessMatrixInner[role.id][x] = !selected),
            );
            if (selected)
              this.rolesInner
                .filter((x) => x.parentId == role.id)
                .forEach((x) =>
                  Object.keys(this.accessMatrixInner[x.id]).forEach((p) => (this.accessMatrixInner[x.id][p] = false)),
                );
          } else {
            let groupSelected = this.getAllGroupSelected(role);
            Object.keys(this.accessMatrixInner[role.id])
              .filter((x) => this.accessMatrixInner[role.parentId][x] == true)
              .forEach((x) => (this.accessMatrixInner[role.id][x] = !groupSelected));
          }
        }
      },
      getAllSelected(roleId) {
        if (this.accessMatrixInner)
          return Object.keys(this.accessMatrixInner[roleId]).every((x) => this.accessMatrixInner[roleId][x] == true);
        else return false;
      },
      getAllGroupSelected(role) {
        if (!role.parentId) return false;

        if (this.accessMatrixInner)
          return (
            Object.keys(this.accessMatrixInner[role.id]).every(
              (x) => this.accessMatrixInner[role.id][x] == this.accessMatrixInner[role.parentId][x],
            ) && Object.keys(this.accessMatrixInner[role.id]).some((x) => this.accessMatrixInner[role.id][x] == true)
          );
        else return false;
      },
      getIndicatorClass(role) {
        let resultClass = '';
        if (this.getAllSelected(role.id)) resultClass = 'scrolling__indicator--dis-pseudo';
        else if (this.getAllGroupSelected(role)) {
          resultClass = 'scrolling__indicator--dis-pseudo_group';
        }

        if (this.isEditMode && resultClass) resultClass = resultClass + ' editing';

        return resultClass;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default//global/variables.scss';
  @import '@/assets/theme/default//global/mixin.scss';

  .btn-group {
    i {
      font-size: 20px;
      color: $yellow;
    }
  }

  table.scrolling {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    border-collapse: collapse !important;
    overflow: hidden;
    font-size: 10px;
    position: relative;
    overflow-y: auto;
    max-height: 600px;
    scrollbar-width: thin;
    thead {
      th {
        font-weight: bold;
        span {
          font-weight: bold;
        }
      }
    }
  }

  .scrolling__ceil {
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    height: 4vw;
  }

  .scrolling__indicator {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background: lightgray;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      display: none;
    }

    &--dis-pseudo::before {
      display: block;
    }
    &.editing::before {
      background: #6c757d !important;
    }

    &--dis-pseudo_group::before {
      display: block;
      background: #e9e9e9 !important;
    }

    &--dis-pseudo_group {
      &.editing::before {
        background: #b6babe !important;
      }
    }
  }

  table.scrolling thead,
  table.scrolling tfoot {
    /* Grow automatically to fit content, don't shrink it proportionately to the body. */
    flex: 0 0 auto;
    display: block;
    /* Horizontal scrolling, when allowed, is controlled by JS, not a scroll bar. */
    overflow: hidden;
  }

  table.scrolling tbody {
    display: block;
    flex: 1 1 auto;
    /* Disable all scrolling by default */
    overflow: hidden;
    scrollbar-width: thin;
  }

  /* Turn on vertical scrolling for all elements so scroll bars take up the same space */
  table.scrolling.scrolly thead,
  table.scrolling.scrolly tbody,
  table.scrolling.scrolly table.scrolling.scrolly tfoot.scrollsync {
    overflow-y: scroll;
  }

  /* Turn on horizontal scrolling for the body only */
  table.scrolling.scrollx tbody {
    overflow-x: scroll;
  }

  /*
For Webkit, use "dead area" color to hide vertical scrollbar functions in the header and footer.
Since WebKit supports CSS variables and style attributes don't support pseudo-classes, use variables.
Display is set because otherwise Chrome ignores the other styling.
TODO: on Chrome/Safari for Mac, scrollbars are not shown anyway and this creates an extra block. No impact on iOS Safari.
*/
  table.scrolling.scrolly thead.scrollsync::-webkit-scrollbar {
    display: block;
    /* background-color: var(--dead-area-color); */
  }

  /* IE11 adds an extra tbody, have to hide it. */
  table.scrolling tbody:nth-child(3) {
    display: none;
  }

  /* The one caveat to scrolling this way: a hard-set width is required. Can override in thead/tbody slot. */
  table.scrolling td,
  table.scrolling th {
    border: 1px solid #ddd;

    /* Important in case your data is too long for your cell */
    overflow: hidden;
    word-wrap: break-word;
    padding: 8px;
  }

  table.scrolling td {
    background-color: #fff;
  }

  table.scrolling th {
    background-color: white;
  }

  .table {
    &__sticky-ceil {
      position: sticky;
      position: -webkit-sticky;
      left: 0;
      z-index: 1;
      min-width: 136px;
      max-width: 136px;
      border-left: none !important;
      border-right: none !important;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
      }
    }

    &__overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .table__ceil,
  .scrolling__ceil {
    /* These must all be set the same in your overriding CSS */
    width: 10%;
    min-width: 100px;
    max-width: 100px;
  }
</style>
