export const UNILATERAL_NON_TTR_REGISTRY = 'unilateralNonTrRegistry';

export const UNILATERAL_NON_TTR_REGISTRY_ACTIONS_NAME = {
  changeParametersMulti: `${UNILATERAL_NON_TTR_REGISTRY}/changeParametersMulti`,
  findAuditLog: `${UNILATERAL_NON_TTR_REGISTRY}/findAuditLog`,
  update: `${UNILATERAL_NON_TTR_REGISTRY}/update`,
};

export const UNILATERAL_NON_TTR_REGISTRY_MUTATIONS_NAME = {
  updateListTableHeaderUnilateral: `${UNILATERAL_NON_TTR_REGISTRY}/updateListTableHeaderUnilateral`,
};

export const UNILATERAL_NON_TTR_REGISTRY_GETTERS_NAME = {
  getTableHeaderUnilateral: `${UNILATERAL_NON_TTR_REGISTRY}/getTableHeaderUnilateral`,
};
