export const IMPORT_ANALYTICS = 'importAnalytics';

export const IMPORT_ANALYTICS_ACTIONS_NAME = {
  getUnfriendlyCountriesSearch: `${IMPORT_ANALYTICS}/getUnfriendlyCountriesSearch`,
  loadUnfriendlyCountriesReport: `${IMPORT_ANALYTICS}/loadUnfriendlyCountriesReport`,
};

export const IMPORT_ANALYTICS_MUTATIONS_NAME = {
  setUnfriendlyCountriesSearch: 'setUnfriendlyCountriesSearch',
};

export const IMPORT_ANALYTICS_GETTERS_NAME = {
  getUnfriendlyCountries: `${IMPORT_ANALYTICS}/getUnfriendlyCountries`,
};
