import axios from 'axios';

import { authAxios } from '@/common/axios';

export default {
  getProfileData: async function () {
    return await authAxios.get(`api/user/profile`);
  },
  saveFcmToken: async function (token) {
    return await axios.post(`api/fcm/savetoken?token=${token}`);
  },
};
